import { CustomText2DComponent } from "../componentsystems/NodeComponents/text2dcomponent";
import { CustomText2DRenderer } from "./customtext2drenderer";

export class CustomSavedCO2Renderer implements Vertex.UI.ICustomInspectorRenderer {

    constructor(targetNode: Vertex.NodeComponentModel.VertexNode) {
        this.targetNode = targetNode;
        this.customText2dRenderer = new CustomText2DRenderer(this.targetNode);
    }

    customText2dRenderer: CustomText2DRenderer;
    targetNode: Vertex.NodeComponentModel.VertexNode;
    target: CustomText2DComponent;
    property: string;
    row = document.createElement("div");

    //Target appears to be the node but we also have just targetNode so not sure why it's needed.
    RenderProperty(property: string, target: any): HTMLDivElement {

        this.target = target;
        this.property = property;
        let outer = document.createElement('div');

        let textComp = this.targetNode.getComponent("Text2D") as CustomText2DComponent;
        let text2dPropCard = document.createElement("div"); text2dPropCard.classList.add("card", "pointer-enable", "scroll-card");

        this.renderText2dPropertiesSection(textComp, text2dPropCard);
        outer.appendChild(text2dPropCard);

        return outer;
    }

    renderText2dPropertiesSection(textComp: CustomText2DComponent, outer : HTMLElement) {
        // let header = document.createElement("div"); header.classList.add("nav-link", "dark-text", "card-header-closable");
        // let headerText = document.createElement("div"); headerText.classList.add("card-header-text"); headerText.innerText = "Text 2D";
        // header.appendChild(headerText);
        // outer.appendChild(header);

        let body = document.createElement("div"); body.classList.add("card-body");
        let container = document.createElement("div"); //container.classList.add("");
        body.appendChild(container);

        const val = this.customText2dRenderer.RenderProperty("color", textComp);
        val.id = "textPropertyRenderer";
        val.querySelectorAll<HTMLElement>('#container-text').forEach(el => el.hidden = true);
        
        container.appendChild(val);

        outer.appendChild(body);
    }

   
}
import { AugmentedStoreAssembly } from '../../../../AugmentedStoreAssembly';

export class ScenePropertiesComponent extends AugmentedStoreAssembly.ScenePropertiesComponent { }

export class ScenePropertiesComponentView extends Vertex.NodeComponentModel.ComponentViewBase {
    constructor() {
        super();
    }

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        const comp = component as ScenePropertiesComponent;

        comp.onChanged.on(async (cmp) => Vertex.Globals.event.fire("sceneproperties:changed", comp.modelCount));
        Vertex.Globals.event.on("hevolus:beforeSaveSpace",() =>{
            comp.modelCount = this.getGltfModelCount();
            comp.mediaCount = this.getMediaNodesCount();
            comp.textCount = this.getTextNodesCount();
            comp.triggerOnChanged();
        })
    }

    getGltfModelCount() {
        const runtime = Vertex.Globals.runtime;
        const space = runtime.space as Vertex.Space;
        let comps = 0;

        space.nodes.forEach(n => {
            if(!n.getComponent('GltfModel')) 
                return;

            comps += 1;
        });

        return comps;
    }

    getMediaNodesCount() {
        const runtime = Vertex.Globals.runtime;
        const space = runtime.space as Vertex.Space;
        let comps = 0;

        space.nodes.forEach(n => {
            if(!n.getComponent('MediaTexture')) 
                return;

            comps += 1;
        });

        return comps;
    }

    getTextNodesCount() {
        const runtime = Vertex.Globals.runtime;
        const space = runtime.space as Vertex.Space;
        let comps = 0;

        space.nodes.forEach(n => {
            if(!n.getComponent('Text2D')) 
                return;

            comps += 1;
        });

        return comps;
    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {

    }
}

export class ScenePropertiesComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new ScenePropertiesComponent();
    }
    constructor() {
        super("SceneProperties", new ScenePropertiesComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}
import { IQRItem } from "./qr-interfaces";
import { QRUtils } from "./qrutils";
import { v4 as uuidv4 } from 'uuid';
import { ICatalogItem, IModal } from "../catalog.interfaces";
import { Utils } from "../../../utilities/utils";

interface IModalConfig {
    saveCallback?: (item: IQRItem) => void;
}

export class QRModal implements IModal{

    constructor(private qrs: IQRItem[], private _item?: IQRItem) {
        
    }

    get modalRootEl() {
        return document.body.querySelector<HTMLElement>('#qr-modal');
    }

    get formEl(){
        return this.modalRootEl.querySelector<HTMLFormElement>('#qr-create-form');
    }

    get saveButton() {
        return this.modalRootEl.querySelector<HTMLElement>('.modal-footer #modal-save-button');
    }

    get closeButton(){
        return this.modalRootEl.querySelector<HTMLElement>('.modal-header .close');
    }

    get cancelButton() {
        return this.modalRootEl.querySelector<HTMLElement>('.modal-footer #modal-cancel-button');
    }

    get alertEl(){
        return this.modalRootEl.querySelector<HTMLDivElement>('#qr-alert');
    }

    isEditing() {
        return Boolean(this._item) && Boolean(this._item.id);
    }

    private _config: IModalConfig;

    show(callback?: (item: IQRItem) => void) {
        if(this.modalRootEl)
            return;

        this._createModal();
        ($(this.modalRootEl) as any).modal("show");
        this.saveButton.onclick = () => this._onSave(callback);
        this.closeButton.onclick = () => this.close();
        this.cancelButton.onclick = () => this.close();

    }

    close(){
        ($(this.modalRootEl) as any).modal("hide");
        this.modalRootEl.remove();
    }

    private _setTitle(title: string) {
        const titleEl = this.modalRootEl.querySelector<HTMLElement>('.modal-title');
        titleEl.innerText = title;
    }

    private _createModal() {
        const modalEl = QRUtils.loadTemplate<HTMLDivElement>("#qr-modal-template");

        document.body.appendChild(modalEl);
        this._setTitle(this.isEditing() ? "Edit QR Info" : "Add QR Info");

        if (!this.isEditing()) {
            return;
        }

        const formEl = this.modalRootEl.querySelector<HTMLFormElement>('#qr-create-form');

        for (let index = 0; index < formEl.elements.length; index++) {
            const element = formEl.elements[index] as HTMLInputElement;

            element.value = this._item[element.name];
        }
    }

    private validateParams(formEl: HTMLFormElement) {
        if (!formEl) {
            return false;
        }

        let name = Utils.sanitizeString(formEl.elements['name'].value?.trim());

        if(!name){
            return false;
        }

        let description = Utils.sanitizeString(formEl.elements['description'].value?.trim());

        formEl.elements['name'].value = name;
        formEl.elements['description'].value = description;

        return true;
    }

    private _onSave(callback?: (item: IQRItem) => void) { 
        const formEl = this.formEl;
        
        if(!this.validateParams(formEl)) {
            this.alertEl.classList.remove('d-none');
            return;
        }
        
        let qrItem = {};
        this.alertEl.classList.add('d-none');
        formEl.classList.add('invalid-form-control');

        if (this.isEditing()) {
            qrItem = { 
                ...this._item, 
                name: formEl.elements['name'].value, 
                description: formEl.elements['description'].value
            };
        }
        else {
            for (let i = 0; i < formEl.elements.length; i++) {
                const element = formEl.elements[i] as HTMLInputElement;
                qrItem[element.name] = element.value;
            }

            qrItem['id'] = uuidv4();
            qrItem["dateTimeCreated"] = new Date().toISOString();
        }

        const newItem: IQRItem = qrItem as IQRItem;
        
        if(!this.isEditing() && QRUtils.existItem(this.qrs, newItem)) {
            this.alertEl.classList.remove('d-none');
            return;
        }        
        
        if(!this._isValid()){   
            return;
        }

        if(!this.isEditing()){
            newItem.url = QRUtils.generateUrl(newItem);
        }

        if (!callback) {
            return;
        }
        
        callback(newItem);
    }

    private _isValid(){
        const formEl = this.formEl;

        for (let i = 0; i < formEl.elements.length; i++) {
            const element = formEl.elements[i] as HTMLInputElement;

            if(!element.checkValidity()){
                return false;
            }
        }

        return true;
    }
}
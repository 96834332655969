import { AugmentedStoreAssembly } from '../../../../AugmentedStoreAssembly';
import { DEFAULT_MEDIA_VOLUME } from '../../../utilities/constants';
import { ConversionUtils, Status } from '../../../utilities/conversion-utilities';
import { FileMediaType, ResourceUtils } from '../../../utilities/resource-utilities';
import { DeviceType, getComponentJsonFromResource, postComponentJsonToResource, Utils } from '../../../utilities/utils';
import { IAwaitableComponent } from './gltfLoadingHandlerComponent';
import { Media } from './mediaTexture';

export class CustomVideoTextureComponent extends AugmentedStoreAssembly.VideoTextureComponent implements IAwaitableComponent {
    isAwaitableComponent: boolean = true;
    funcOnLoad: (customComp: CustomVideoTextureComponent) => Promise<void>;

    isReady: Promise<void>;
    videos: Media[] = [];
    index: number = 0;
    previewMediaName: string = "";
    isWaitingConversion: boolean = false;
    
    files: string[] = [];
    dataMedias: string[] = [];

    image = new Image();
    imageTexture: BABYLON.DynamicTexture;
    videoTexture: BABYLON.VideoTexture;
    imageMaterial: BABYLON.StandardMaterial;
    videoMaterial: BABYLON.StandardMaterial;

    previewVolume: number = DEFAULT_MEDIA_VOLUME;

    gltfModelComp: Vertex.NodeComponentModel.GltfModelComponent = null;
    defaultMaterials: BABYLON.Material[] = [];

    async updateResources() {
        try {
            const resource = await ResourceUtils.getResourceAsync(this.gltfModelComp.id, false);

            if (resource) {
                this.files = resource.resourceKeys;

                if (this.files) {
                    this.files.forEach((file: string) => {
                        const extension = file.substring(file.lastIndexOf('.') + 1, file.length).toLocaleLowerCase();

                        if (extension === "webm") {
                            this.dataMedias.push(file);
                        }
                    });
                }
            }
        }
        catch {
            console.log("Failed to retrieve resource data for resource: " + this.gltfModelComp.id);
        }
    }

    async onLoad(): Promise<void> {
        return await this.funcOnLoad(this);
    }

    postComponentJsonToResource = (async (isEditorVersion: boolean = true) => {
        await postComponentJsonToResource(Vertex.Globals.spaceId, "VideoTexture", this.node.id, isEditorVersion, JSON.stringify(this.videos));
    }).bind(this);
}

export class VideoTextureComponentView extends Vertex.NodeComponentModel.ComponentViewBase {
    private cachedBlobs: Map<string, string> = new Map<string, string>();
    private texSize = 1024;

    constructor() {
        super();
    }

    incompatibleComponents = ["ModelAlternative", "NavMesh", "LightMaps"];


    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {        
        const customComp = component as CustomVideoTextureComponent;

        let isCompatible = Utils.checkComponentsCompatibility("VideoTexture", node, this.incompatibleComponents);

        if (!isCompatible) {
            node.removeComponent("VideoTexture");
            // Vertex.Globals.event.fire("editor:selectNode", node);
            return;
        }

        customComp.gltfModelComp = node.getComponent("GltfModel") as Vertex.NodeComponentModel.GltfModelComponent;
        
        customComp.isReady = new Promise<void>(async (resolve, reject) => {
            await customComp.updateResources();

            let res = await getComponentJsonFromResource(Vertex.Globals.spaceId, "VideoTexture", node.id, true);
            if (res.ok) {
                customComp.videos = await res.json() as Media[];
                if (customComp.videos.length <= 0) {
                    customComp.videos = customComp.dataMedias.map(availableMediaName => { return { mediaName: availableMediaName, volume: DEFAULT_MEDIA_VOLUME } });
                }
            }
            else {
                res = await getComponentJsonFromResource(Vertex.Globals.spaceId, "VideoTexture", node.id, false);
                if (res.ok) {
                    customComp.videos = await res.json() as Media[];
                    await customComp.postComponentJsonToResource(true);
                } else {
                    customComp.videos = customComp.dataMedias.map(availableMediaName => { return { mediaName: availableMediaName, volume: DEFAULT_MEDIA_VOLUME } });
                    await customComp.postComponentJsonToResource(true);
                }
            }
            
            const beforeSaveSpace = async () => {
                await customComp.postComponentJsonToResource(false);
            }

            customComp.onRemoved.on(async() => {
                Vertex.Globals.event.off("hevolus:beforeSaveSpace", beforeSaveSpace);
                customComp.videos = [];
                await customComp.postComponentJsonToResource(true);
            });

            Vertex.Globals.event.on("hevolus:beforeSaveSpace", beforeSaveSpace);
            
            resolve();
        });
        
        customComp.funcOnLoad = this.onLoad;

        const scene = Vertex.Globals.runtime.scene;
        this.createImageMaterial(customComp, scene);
        this.createVideoMaterial(customComp, scene);
    }

    onLoad = (async (customComp: CustomVideoTextureComponent) => {
        await customComp.isReady;

        customComp.onChanged.on(this.onChange);

        customComp.previewMediaName = "";
        
        customComp.triggerOnChanged();

                // setInterval(() => {
                //     if (component.videoTex.video.paused) {
                //         console.log("[vins]Video in pause");
                //         component.videoTex.video.play();
                //     }
                // }, 1000);

                // customComp.videoTex.video.onended = async () => {
                //     videoIndex++;

                //     if (videoIndex >= customComp.videos.length) {
                //         videoIndex = 0;
                //     }

                //     let uri = this.getVideoUriFromMap(customComp.gltfModelComp.id, customComp.videos[videoIndex].mediaName);

                //     if (!uri) {
                //         uri = await Utils.getAssetBlobUrl(customComp.gltfModelComp.id, customComp.videos[videoIndex].mediaName);
                //         this.putVideoUriInMap(customComp.gltfModelComp.id, customComp.videos[videoIndex].mediaName, uri);
                //     }

                //     if (uri) {
                //         console.log("[vins]Video play");
                //         customComp.videoTex.video.src = uri;
                //         customComp.videoTex.video.muted = true;
                //         await customComp.videoTex.video.play();
                //     }

                // };

    }).bind(this);

    onChange = (async (customComp: CustomVideoTextureComponent) => {
        if(customComp.videos.length > 0){
            const media = customComp.videos[customComp.index];
            customComp.previewVolume = media.volume;

            if(customComp.isWaitingConversion){
                const isConverting = await ConversionUtils.isConverting(media.mediaName, customComp.gltfModelComp.id);

                if(!isConverting){
                    customComp.isWaitingConversion = false;
                    customComp.previewMediaName = media.mediaName
                    await this.updateMedia(customComp);
                }
            }
            else if (customComp.previewMediaName != media.mediaName) {
                customComp.previewMediaName = media.mediaName
                await this.updateMedia(customComp);
            }
            
            customComp.videoTexture.video.volume = customComp.previewVolume / 100;
        }
        else{
            customComp.previewMediaName = "";
            await this.updateMedia(customComp);
        }
    }).bind(this);

    private createImageMaterial(customComp: CustomVideoTextureComponent, scene: BABYLON.Scene) {
        customComp.imageTexture = new BABYLON.DynamicTexture('texture', this.texSize, scene, false);
        customComp.imageTexture.hasAlpha = true;

        customComp.image.onload = () => {
            let ctx = customComp.imageTexture.getContext();
            
            ctx.fillStyle = "#000000";
            ctx.fillRect(0, 0, this.texSize, this.texSize);

            if (customComp.image.width > customComp.image.height) {
                let height = customComp.image.height * this.texSize / customComp.image.width;
                ctx.drawImage(customComp.image, 0, 0, customComp.image.width, customComp.image.height, 0, (this.texSize - height) / 2, this.texSize, height);
            }
            else {
                let width = customComp.image.width * this.texSize / customComp.image.height;
                ctx.drawImage(customComp.image, 0, 0, customComp.image.width, customComp.image.height, (this.texSize - width) / 2, 0, width, this.texSize);
            }

            customComp.imageTexture.update();

            const meshes = customComp.node.viewNode.getChildMeshes(false);

            if(!customComp.defaultMaterials || customComp.defaultMaterials.length !== meshes.length){
                customComp.defaultMaterials = [];
                for (let index = 0; index < meshes.length; index++) {
                    customComp.defaultMaterials.push(meshes[index].material);
                }
            }

            for (let index = 0; index < meshes.length; index++) {
                meshes[index].material = customComp.imageMaterial;
            }
        };

        let mat = new BABYLON.StandardMaterial("mat", scene);
        mat.sideOrientation = BABYLON.Material.ClockWiseSideOrientation;

        mat.diffuseTexture = customComp.imageTexture;
        mat.diffuseTexture.hasAlpha = true;
        mat.emissiveColor = BABYLON.Color3.White();
        customComp.imageMaterial = mat;
    }

    private createVideoMaterial(customComp: CustomVideoTextureComponent, scene: BABYLON.Scene) {
        var videoMat = new BABYLON.StandardMaterial("m", scene);
        videoMat.sideOrientation = BABYLON.Material.ClockWiseSideOrientation;

        try {
            customComp.videoTexture = new BABYLON.VideoTexture("vidtex", [], scene);
            customComp.videoTexture.video.onloadeddata = () => {
                customComp.videoTexture.video.muted = true;
                customComp.videoTexture.video.play();

                const meshes = customComp.node.viewNode.getChildMeshes(false);

                if(!customComp.defaultMaterials || customComp.defaultMaterials.length !== meshes.length){
                    customComp.defaultMaterials = [];
                    for (let index = 0; index < meshes.length; index++) {
                        customComp.defaultMaterials.push(meshes[index].material);
                    }
                }

                for (let index = 0; index < meshes.length; index++) {
                    meshes[index].material = videoMat;
                }
            }
            videoMat.diffuseTexture = customComp.videoTexture;
            videoMat.roughness = 1;
            videoMat.emissiveColor = BABYLON.Color3.White();

            customComp.videoTexture.vScale = -1;
            customComp.videoTexture.uScale = 1;

            customComp.videoTexture.uOffset = 1;
            customComp.videoTexture.vOffset = 1;

            customComp.videoTexture.video.loop = true;
            customComp.videoMaterial = videoMat;
        }
        catch (e) {
            console.error(e);
            customComp.videoTexture?.dispose();
            customComp.videoTexture = null;
            videoMat.dispose();
        }
    }

    async updateMedia(customComp: CustomVideoTextureComponent) {
        if (customComp.videoTexture == null) {
            let scene = Vertex.Globals.runtime.scene;

            this.createVideoMaterial(customComp, scene);
        }
        
        if (customComp.previewMediaName.length > 0) {
            let mediaName = customComp.previewMediaName;

            const fileName = Utils.getFileBaseName(mediaName);

            if(Utils.getDeviceType() == DeviceType.iPhone || Utils.getDeviceType() == DeviceType.iPad){
                mediaName = fileName + ".mp4";
            }
            else{
                mediaName = fileName + ".webm";
            }

            const conversionStatus = await ConversionUtils.getMediaConversionStatus(customComp.previewMediaName, customComp.gltfModelComp.id);
            if(conversionStatus.status === Status.Failed){
                const mediaIndex = customComp.dataMedias.findIndex((media) => media == customComp.previewMediaName);
                customComp.dataMedias.splice(mediaIndex, 1);
                
                customComp.videos.splice(customComp.index, 1);

                if(customComp.dataMedias.length > 0){
                    this.onChange(customComp);
                }

                return;
            }

            if(conversionStatus.status === Status.Pending){
                customComp.isWaitingConversion = true;
                customComp.videoTexture?.video?.pause();
                customComp.image.src = "/img/converting.svg";

                Utils.waitForConditionAsync(async _ => {
                    const mediaName = customComp.videos[customComp.index].mediaName;

                    const isConverting = await ConversionUtils.isConverting(mediaName, customComp.gltfModelComp.id);
                    
                    return !isConverting;
                }, 5000).then(async () => {
                    customComp.isWaitingConversion = false;
                    customComp.previewMediaName = "";
                    this.onChange(customComp);
                });

                return;
            }

            let blobUrl = this.getBlobFromMap(customComp.gltfModelComp.id, mediaName);

            if (blobUrl) {
                customComp.videoTexture.video.src = blobUrl;
            }
            else {
                blobUrl = await ResourceUtils.getAssetBlobUrl(customComp.gltfModelComp.id, mediaName);

                if (blobUrl?.length) {
                    this.putBlobInMap(customComp.gltfModelComp.id, mediaName, blobUrl);
                    customComp.videoTexture.video.src = blobUrl;
                }
                else {
                    let meshes = customComp.node.viewNode.getChildMeshes(false);
                    
                    if(customComp.defaultMaterials && customComp.defaultMaterials.length === meshes.length){
                        for (let index = 0; index < meshes.length; index++) {
                            meshes[index].material = customComp.defaultMaterials[index];
                        }
                    }
                }
            }
        }
        else {
            customComp.videoTexture?.video?.pause();

            let meshes = customComp.node.viewNode.getChildMeshes(false);
            if(customComp.defaultMaterials && customComp.defaultMaterials.length === meshes.length){
                for (let index = 0; index < meshes.length; index++) {
                    meshes[index].material = customComp.defaultMaterials[index];
                }
            }

        }
    }

    private getBlobFromMap(id: string, fileName: string): string {
        return this.cachedBlobs.get(id + ":" + fileName);
    }

    private putBlobInMap(id: string, fileName: string, blob: string) {
        this.cachedBlobs.set(id + ":" + fileName, blob);
    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        const customComp = component as CustomVideoTextureComponent;

        let meshes = node.viewNode?.getChildMeshes(false);

        if (meshes && customComp.defaultMaterials?.length === meshes.length){
            if (customComp.videoTexture)
            {
                customComp.videoTexture.video.src = "";
            }

            for (let index = 0; index < meshes.length; index++) {
                meshes[index].material = customComp.defaultMaterials[index];
            }
        }
    }
}



export class VideoTextureComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {

    public create(): Vertex.NodeComponentModel.Component {
        return new CustomVideoTextureComponent;
    }

    constructor() {
        super("VideoTexture", new VideoTextureComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}
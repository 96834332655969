import { UserProfileUtils } from "../../utilities/user-profile-utilities";
import { CODE, HEVO_HUB_API_DOMAIN } from "./common";

export const CHECK_LICENSE_API_ENDPOINT = "api/CheckLicense";

export class LicensingApi {
    static async checkLicense(scopes: string[], token?: string, companyId?: string){
        if(!scopes){
            return;
        }
        
        token = token ?? Vertex.Globals.bearerToken;
        companyId = companyId ?? await UserProfileUtils.getTenantId(token);

        if(!token || !companyId){
            return;
        }

        const HevoHubTokenProviderClient = require('@hevolusinnovation/hevohub.tokenprovider.client.js');
        
        try {
            let client = new HevoHubTokenProviderClient('tyCKWZi85xFUVrx2-WDoeB8AAIoaN0nh9iKP7P5Y-J2OAzFu9RtDHA==');     
            return await client.checkLicenseAsync(companyId, scopes, token);
        } catch (e) {
            console.log(`Failed to check license for scopes ${scopes} in company ${companyId}`, e);
        }
    }
}
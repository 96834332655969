class HevolusConfig {
    static VERTEX_URL_BASE = "hevolus.vertx.cloud";
    static VERTEX_IDENTITY_URL = "https://hevolus-id.vertx.cloud";
    static VERTEX_CLIENT_ID = "3a14062f-c1b0-4cf7-a1bb-029ced455384";
    static VERTEX_SCOPE = "openid profile api_vertex_core";
    static readonly DEFAULT_BASEURL_AS_LINK = "https://augmentedstore.azurewebsites.net/";
    static readonly DEFAULT_NAVMESH_ID = "93a58098-2ff0-4046-b2b5-38c0472d961e";
    static readonly SPAWNPOINT_ID = "4a9af02c-4a14-49e9-b7e6-e4a53ef1cd59";
    static readonly DEFAULT_BASE_ID = "d451efd0-f29d-4c94-98e9-fa94fa2ea5df";
    static readonly AVATAR_RIG_RESOURCE_ID = "80f3dfce-f25f-4864-97fe-0395df2fb18d";
    static readonly DEFAULT_AVATARS_CATALOG_ID = "f9f8263f-4f90-4e21-af1f-64d4c9f1c481";
    static readonly AMAZ3D_USER = "user@email.com";
    static readonly AMAZ3D_PWD = "Userpassword1";
    static readonly AMAZ3D_ENDPOINT = "https://amaz3d-backend.hevolus.it/graphql";
    static readonly AMAZ3D_VERIFY_SSL = true;

    static get AUTH_CALLBACK_URI() {
        return `${window.location.protocol}//${window.location.host}/auth-callback/`;  // note: the trailing slash is very important or Caddy interferes with the login process
    };
}

class FerrariConfig {
    static VERTEX_URL_BASE = "hengine-devtest.ferlan.it";
    static VERTEX_IDENTITY_URL = "https://hevolus-id.vertx.cloud";
    static VERTEX_CLIENT_ID = "58050ce7-0d57-4e14-b0a4-c70ca9e11a24";
    static VERTEX_SCOPE = "openid profile api_vertex_core";
    static get AUTH_CALLBACK_URI() {
        return `${window.location.protocol}//${window.location.host}/auth-callback/`;  // note: the trailing slash is very important or Caddy interferes with the login process
    }
    static readonly DEFAULT_BASEURL_AS_LINK = "https://hportal-devtest.ferlan.it/";
    static readonly DEFAULT_NAVMESH_ID = "854bcc37-4d6b-4d5d-8bd5-7013c9b7bf03";
    static readonly SPAWNPOINT_ID = "d46c98ff-cab0-4628-a4cb-aab5373beb68";
    static readonly DEFAULT_BASE_ID = "c5cad788-b37e-44e3-8669-99611cc95986";
    static readonly AVATAR_RIG_RESOURCE_ID = "80f3dfce-f25f-4864-97fe-0395df2fb18d"; //to update
    static readonly DEFAULT_AVATARS_CATALOG_ID = "f9f8263f-4f90-4e21-af1f-64d4c9f1c481"; //to update
    static readonly AMAZ3D_USER = "user@email.com";
    static readonly AMAZ3D_PWD = "H3v0lus@2023";
    static readonly AMAZ3D_ENDPOINT = "https://hdecimator-devtest.ferlan.it/graphql";
    static readonly AMAZ3D_VERIFY_SSL = false;
}

class FerrariProdConfig {
    static VERTEX_URL_BASE = "hengine-prod.ferlan.it";
    static VERTEX_IDENTITY_URL = "https://hevolus-id.vertx.cloud";
    static VERTEX_CLIENT_ID = "cad45d47-11d0-48b4-bd12-e08e5b487725";
    static VERTEX_SCOPE = "openid profile api_vertex_core";
    static get AUTH_CALLBACK_URI() {
        return `${window.location.protocol}//${window.location.host}/auth-callback/`;  // note: the trailing slash is very important or Caddy interferes with the login process
    }
    static readonly DEFAULT_BASEURL_AS_LINK = "https://hportal.ferlan.it/";
    static readonly DEFAULT_NAVMESH_ID = "854bcc37-4d6b-4d5d-8bd5-7013c9b7bf03";
    static readonly SPAWNPOINT_ID = "d46c98ff-cab0-4628-a4cb-aab5373beb68";
    static readonly DEFAULT_BASE_ID = "db7eeda1-fb41-4333-96d0-fde6ab99d239";
    static readonly AVATAR_RIG_RESOURCE_ID = "80f3dfce-f25f-4864-97fe-0395df2fb18d"; //to update
    static readonly DEFAULT_AVATARS_CATALOG_ID = "f9f8263f-4f90-4e21-af1f-64d4c9f1c481"; //to update
    static readonly AMAZ3D_USER = "user@email.com";
    static readonly AMAZ3D_PWD = "H3v0lus@2023";
    static readonly AMAZ3D_ENDPOINT = "https://hdecimator-prod.ferlan.it/graphql";
    static readonly AMAZ3D_VERIFY_SSL = false;
}

export const Config = HevolusConfig;

import { AugmentedStoreAssembly } from '../../../../AugmentedStoreAssembly';


export class CustomPostProcessPropertiesComponent extends AugmentedStoreAssembly.PostProcessPropertiesComponent {
    camera: BABYLON.ArcRotateCamera;

    init(){
        this.fxaaEnabled = true;
        this.antiAliasingSamples = 4;

        this.bloomEnabled = false;
        this.bloomWeight = 0.1;
        this.bloomKernel = 32;
        this.bloomScale = 0.5;
        this.bloomThreshold = 0.5;

        this.toneMappingEnabled = true;

        this.screenSpaceReflectionSample = 32;
        this.screenSpaceReflectionStrength = 0;

        this.screenSpaceAmbientOcclusionEnabled = false;
        this.screenSpaceAmbientOcclusionTotalStrength = 1;

        this.vignetteEnabled = false;
        this.vignetteRounded = true;
        this.vignetteWeight = 10;
        this.vignetteColorR = 0;
        this.vignetteColorG = 0;
        this.vignetteColorB = 0;
        this.vignetteColorA = 1;
        
        this.exposure = 1;
        this.contrast = 1;
    }
}
export class PostProcessPropertiesComponentView extends Vertex.NodeComponentModel.ComponentViewBase {
    constructor() {
        super();
    }

    private defaultPipeline : BABYLON.DefaultRenderingPipeline;
    private ssaoPipeline : BABYLON.SSAORenderingPipeline;

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        const customComp = component as CustomPostProcessPropertiesComponent;

        const scene : BABYLON.Scene = Vertex.Globals.runtime.scene;

        customComp.onChanged.on(() => {
            
            if (customComp.camera) {
                if (!this.defaultPipeline) {
                    this.defaultPipeline = new BABYLON.DefaultRenderingPipeline("default", true, scene);
                    scene.postProcessRenderPipelineManager.attachCamerasToRenderPipeline("default", [customComp.camera]);
                    //scene.postProcessRenderPipelineManager.addPipeline(this.defaultPipeline);
                }

                if (!this.ssaoPipeline) {
                    this.ssaoPipeline = new BABYLON.SSAORenderingPipeline('ssaopipeline', scene, 0.75);
                    //scene.postProcessRenderPipelineManager.addPipeline(this.ssaoPipeline);
                }
    
                //Default settings
                this.defaultPipeline.fxaaEnabled = true;
                this.defaultPipeline.samples = 4;
    
                this.defaultPipeline.bloomEnabled = false;
                this.defaultPipeline.bloomWeight = 0.1;
                this.defaultPipeline.bloomKernel = 32;
                this.defaultPipeline.bloomScale = 0.5;
                this.defaultPipeline.bloomThreshold = 0.5;
    
                this.defaultPipeline.imageProcessing.toneMappingEnabled = true;
                this.defaultPipeline.imageProcessing.toneMappingType = BABYLON.ImageProcessingConfiguration.TONEMAPPING_ACES;
    
                //this.defaultPipeline screenSpaceReflectionSample = 32;
                //this.defaultPipeline screenSpaceReflectionStrength = 0;
    
                scene.postProcessRenderPipelineManager.detachCamerasFromRenderPipeline("ssaopipeline", [customComp.camera]);
                this.ssaoPipeline.totalStrength = 1;
    
                this.defaultPipeline.imageProcessing.vignetteEnabled = false;
                this.defaultPipeline.imageProcessing.vignetteStretch = 0;
                this.defaultPipeline.imageProcessing.vignetteWeight = 10;
                this.defaultPipeline.imageProcessing.vignetteColor = new BABYLON.Color4();//BLACK
                
                this.defaultPipeline.imageProcessing.contrast = 1;
                this.defaultPipeline.imageProcessing.exposure = 1;

    
    
                //CustomComp settings
                if (customComp.bloomEnabled != null) this.defaultPipeline.bloomEnabled = customComp.bloomEnabled;
                if (customComp.bloomWeight != null) this.defaultPipeline.bloomWeight = customComp.bloomWeight;
                if (customComp.bloomKernel != null) this.defaultPipeline.bloomKernel = customComp.bloomKernel;
                if (customComp.bloomScale != null) this.defaultPipeline.bloomScale = customComp.bloomScale;
                if (customComp.bloomThreshold != null) this.defaultPipeline.bloomThreshold = customComp.bloomThreshold;
    
                if (customComp.fxaaEnabled != null) this.defaultPipeline.fxaaEnabled = customComp.fxaaEnabled;
                if (customComp.antiAliasingSamples != null) this.defaultPipeline.samples = customComp.antiAliasingSamples;
    
                if (customComp.toneMappingEnabled != null) this.defaultPipeline.imageProcessing.toneMappingEnabled = customComp.toneMappingEnabled;
    
                if (customComp.screenSpaceAmbientOcclusionEnabled) scene.postProcessRenderPipelineManager.attachCamerasToRenderPipeline("ssaopipeline", [customComp.camera]);
                if (customComp.screenSpaceAmbientOcclusionTotalStrength != null) this.ssaoPipeline.totalStrength = customComp.screenSpaceAmbientOcclusionTotalStrength;
    
                if (customComp.vignetteEnabled != null) this.defaultPipeline.imageProcessing.vignetteEnabled = customComp.vignetteEnabled;
                if (customComp.vignetteRounded != null) this.defaultPipeline.imageProcessing.vignetteStretch = customComp.vignetteRounded? 0 : 1;
                if (customComp.vignetteWeight != null) this.defaultPipeline.imageProcessing.vignetteWeight = customComp.vignetteWeight;
                if (customComp.vignetteColorR != null &&
                    customComp.vignetteColorG != null &&
                    customComp.vignetteColorB != null &&
                    customComp.vignetteColorA != null)
                    this.defaultPipeline.imageProcessing.vignetteColor = new BABYLON.Color4(customComp.vignetteColorR, customComp.vignetteColorG, customComp.vignetteColorB, customComp.vignetteColorG);
    
                if (customComp.contrast === 0) customComp.contrast = 1;
                if (customComp.exposure === 0) customComp.exposure = 1;

                if (customComp.contrast != null) this.defaultPipeline.imageProcessing.contrast = customComp.contrast;
                if (customComp.exposure != null) this.defaultPipeline.imageProcessing.exposure = customComp.exposure;

                //this.defaultPipeline.cameraFov = camera.fov;
            }
            
            Vertex.Globals.event.fire("hevolus:PostProcessPropertiesChanged", customComp);
        });
    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {

    }
}

export class PostProcessComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new CustomPostProcessPropertiesComponent();
    }
    constructor() {
        super("PostProcessProperties", new PostProcessPropertiesComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}
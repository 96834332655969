import { AugmentedStoreAssembly } from '../../../../AugmentedStoreAssembly';

export class NodeLockableComponentView extends Vertex.NodeComponentModel.ComponentViewBase {

    constructor() {
        super();
    }

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {

        // Vertex.Globals.event.fire('hevolus:UnselectableAdded', node);

        // Vertex.Globals.event.on('hevolus:environment-vis', (enabled) => {
        //     var gltfcomp = node.getComponent('GltfModel') as Vertex.NodeComponentModel.GltfModelComponent;

        //     if (gltfcomp) {
        //         gltfcomp.visualNode.setEnabled(enabled);
        //     }
        // });

    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
    }

}

export class NodeLockableComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new AugmentedStoreAssembly.NodeLockableComponent();
    }

    constructor() {
        
        super("NodeLockable", new NodeLockableComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}

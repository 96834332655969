import { Utils } from "../../../utilities/utils";
import { ICatalogItem } from "../catalog.interfaces";
import { v4 as uuidv4 } from 'uuid';
import { CatalogBase } from "../catalog-base";
import { TagModal } from "./tag-modal";
import { ResourceUtils, VERTEXResource } from "../../../utilities/resource-utilities";
import { TAGS_CATALOG_FILENAME } from "../../../utilities/constants";

export class TagCatalog extends CatalogBase {   
    protected cls: string[] = ["catalog-item-ex"];

    constructor(catalog:VERTEXResource) {
        super(catalog, TAGS_CATALOG_FILENAME, TagModal);
        this.catalog_item_template = "#catalog-item-tag-block-template";
        this.containerEl.classList.add(...this.cls);
    }

    protected convertItemToSave(items: ICatalogItem[]): any[] {
        return items.map(i => i.name);
    }

    protected convertToObject(items: any[]): ICatalogItem[] {
        return items.map(i => {
            return {
                id: uuidv4(),
                name: i
            }
        });
    }
}
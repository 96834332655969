import { CustomActionsRenderer } from "./customactionsrenderer";
import { CustomActionSettingsComponent } from "../componentsystems/NodeComponents/actionsettingscomponent";
import { CustomTriggerableComponentItemRenderer } from "./customtriggerablecomponentItemrenderer";
import { CustomNodeTogglerComponent } from "../componentsystems/NodeComponents/nodetogglercomponent";
import { CustomBooleanInspector } from "./custombooleanrenderer";

export class CustomNodeTogglerRenderer implements Vertex.UI.ICustomInspectorRenderer {
    triggerableComponentItemRenderers: CustomTriggerableComponentItemRenderer[] = [];
    
    constructor(targetNode: Vertex.NodeComponentModel.VertexNode) {
        this.targetNode = targetNode;
        this.customFieldEnabled = new CustomBooleanInspector(this.targetNode);
    }

    customFieldEnabled : CustomBooleanInspector;

    targetNode: Vertex.NodeComponentModel.VertexNode;
    target: CustomNodeTogglerComponent;
    property: string;
    nodeTogglerPanel: HTMLDivElement;
    actionsRenderer: CustomActionsRenderer;
    actionSettingsComp: CustomActionSettingsComponent;

    static currentRenderer: CustomNodeTogglerRenderer;

    get actionSettings(): CustomActionSettingsComponent {
        if (this.actionSettingsComp) {
            return this.actionSettingsComp;
        }

        let runtime = Vertex.Globals.runtime as Vertex.VertexRuntime;
        runtime.space.nodes.forEach(node => {
            const comp = node.getComponent("ActionSettings") as CustomActionSettingsComponent;
            
            if (comp) {
                this.actionSettingsComp = comp;
            }
        });

        return this.actionSettingsComp;
    }

    //Target appears to be the node but we also have just targetNode so not sure why it's needed.
    RenderProperty(property: string, target: any): HTMLDivElement {
        this.target = target;
        this.property = property;

        if (CustomNodeTogglerRenderer.currentRenderer) {
            Vertex.Globals.event.off("ActionSettings:ActionAdded", CustomNodeTogglerRenderer.currentRenderer.renderActionListeners);
            Vertex.Globals.event.off("ActionSettings:ActionUpdated", CustomNodeTogglerRenderer.currentRenderer.renderActionListeners);
            CustomNodeTogglerRenderer.currentRenderer.target.onChanged.off(CustomNodeTogglerRenderer.currentRenderer.renderActionListeners);
        }

        this.nodeTogglerPanel = document.createElement('div');

        this.renderEnabledPropertyWithTriggers();
        this.renderActionListeners();

        CustomNodeTogglerRenderer.currentRenderer = this;

        Vertex.Globals.event.on("ActionSettings:ActionAdded", this.renderActionListeners);
        Vertex.Globals.event.on("ActionSettings:ActionUpdated", this.renderActionListeners);

        this.target.onChanged.on(this.renderActionListeners);

        return this.nodeTogglerPanel;
    }

    renderEnabledPropertyWithTriggers = (() => {
        const self = this;

        const states = ["Disabled", "Enabled"];

        self.nodeTogglerPanel.innerText = "";

        this.triggerableComponentItemRenderers = [];

        for (let i = 0; i < states.length; i++) {
            const state = states[i];
            const stateProm = Promise.resolve(state);
            const isStarred = self.target.enabled == Boolean(i);

            const onClickStar = (() => {
               self.target.enabled = Boolean(i);
               self.target.triggerOnChanged();           
            });

            const triggerableRenderer = new CustomTriggerableComponentItemRenderer(self.target, self.actionSettings, i, stateProm, null, isStarred, onClickStar, null);
            self.triggerableComponentItemRenderers.push(triggerableRenderer);
            
            const triggerableRendererElement = triggerableRenderer.RenderItem();
            self.nodeTogglerPanel.appendChild(triggerableRendererElement);
        }

    }).bind(this);

    renderActionListeners = (async ()=> {
        await this.actionSettings.isReady;

        if(this.actionsRenderer == null) {
            this.actionsRenderer = new CustomActionsRenderer();
        }

        const states = ["Disabled", "Enabled"];

        let actionListCardElement = this.actionsRenderer.RenderActions(this.target, this.actionSettings, states);
        this.nodeTogglerPanel.append(actionListCardElement);

        this.triggerableComponentItemRenderers.forEach((triggerableComponentItemRenderer, i) => {
            const isStarred = this.target.enabled == Boolean(i);
            triggerableComponentItemRenderer.RenderItem(isStarred);
        });
    }).bind(this);

    renderActionTriggers = (async ()=> {
        await this.actionSettings.isReady;
    }).bind(this);
}
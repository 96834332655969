import { AugmentedStoreAssembly } from "../../../../AugmentedStoreAssembly";
import { Utils } from "../../../utilities/utils";
import { ITriggerActionComponent } from "./actionsettingscomponent";
import { CustomGltfLoadingHandlerComponent, IAwaitableComponent } from "./gltfLoadingHandlerComponent";
import { CustomMediaTextureComponent } from "./mediaTexture";
import { CustomText2DComponent } from "./text2dcomponent";
export class CustomNodeTogglerComponent extends AugmentedStoreAssembly.NodeTogglerComponent implements IAwaitableComponent, ITriggerActionComponent{
    isTriggerActionComponent: boolean = true;

    constructor() {
        super();
    }

    actionSettingsComp: AugmentedStoreAssembly.ActionSettingsComponent;

    get actionSettings(): AugmentedStoreAssembly.ActionSettingsComponent {
        if (this.actionSettingsComp) {
            return this.actionSettingsComp;
        }

        let runtime = Vertex.Globals.runtime as Vertex.VertexRuntime;
        runtime.space.nodes.forEach(node => {
            const comp = node.getComponent("ActionSettings") as AugmentedStoreAssembly.ActionSettingsComponent;
            if (comp) {
                this.actionSettingsComp = comp;
            }
        });

        return this.actionSettingsComp;
    }

    isAwaitableComponent: boolean = true;
    funcOnLoad;

    public renderingComp: Vertex.NodeComponentModel.GltfModelComponent | CustomMediaTextureComponent | CustomText2DComponent;
    public renderingMesh: BABYLON.AbstractMesh;

    gltfLoadingHandlerComp: CustomGltfLoadingHandlerComponent;
    
    async onLoad(): Promise<void> {
        return await this.funcOnLoad();
    }

    public onActionTriggered = ((actionData: AugmentedStoreAssembly.ActionMessage) => {
        const actionIndex = this.actionIndexes.indexOf(actionData.actionIndex);
        
        if (actionIndex !== -1) {
            const state = this.actionValues[actionIndex];
            this.enabled = Boolean(state);
            this.triggerOnChanged();
        }
    }).bind(this);

    async getComponentTriggerableValueNames(): Promise<string[]> {
        return ["enabled"];
    }
}

export class NodeTogglerComponentView extends Vertex.NodeComponentModel.ComponentViewBase{
    constructor() {
        super();
    }
    
    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        let comp = component as CustomNodeTogglerComponent;
        let canLinkEvents = false;

        if(node.components.includes("GltfModel")){
            Utils.waitForCondition(_ => comp.gltfLoadingHandlerComp?.isLoaded).then(() => {
                comp.renderingComp = node.getComponent("GltfModel") as Vertex.NodeComponentModel.GltfModelComponent;
                comp.renderingMesh = comp.renderingComp.visualNode;

                canLinkEvents = true;
            });
        }
        else if (node.components.includes("MediaTexture")){
            comp.renderingComp = node.getComponent("MediaTexture") as CustomMediaTextureComponent;

            comp.renderingComp.isReady.then(() => {
                comp.renderingMesh = (comp.renderingComp as CustomMediaTextureComponent).plane;
                canLinkEvents = true;
            });
        }
        else if(node.components.includes("Text2D")){
            comp.renderingComp = node.getComponent("Text2D") as CustomText2DComponent;
            comp.renderingMesh = comp.renderingComp.plane;
            canLinkEvents = true;
        }

        Utils.waitForCondition(_ => canLinkEvents).then(() => {
            comp.actionSettings.onActionTriggered.on(comp.onActionTriggered);
            comp.onRemoved.on(() => comp.actionSettings.onActionTriggered.off(comp.onActionTriggered));

            comp.onChanged.on(() => {
                comp.renderingMesh.setEnabled(comp.enabled);
            });

            comp.triggerOnChanged();
        });

        let onActionDeleted = (deletedActionIndex) => {
            if (comp.actionIndexes.includes(deletedActionIndex)) {
                let actionIndex = comp.actionIndexes.indexOf(deletedActionIndex);
                comp.actionIndexes.splice(actionIndex, 1);
                comp.actionValues.splice(actionIndex, 1);
            }

            for(let i = 0; i < comp.actionIndexes.length; i++) {
                if(comp.actionIndexes[i] > deletedActionIndex) {
                    comp.actionIndexes[i] = comp.actionIndexes[i]-1;
                }
            }

            comp.triggerActionValues = comp.triggerActionValues.filter((val, idx) => comp.triggerActionIndexes[idx] != deletedActionIndex);
            comp.triggerActionIndexes = comp.triggerActionIndexes.filter(idx => idx != deletedActionIndex);

            for(let i = 0; i < comp.triggerActionIndexes.length; i++) {
                if(comp.triggerActionIndexes[i] > deletedActionIndex) {
                    comp.triggerActionIndexes[i] = comp.triggerActionIndexes[i]-1;
                }
            }

            comp.triggerOnChanged();
        };

        Vertex.Globals.event.on("ActionSettings:ActionDeleted", onActionDeleted);

        component.onRemoved.on(() => Vertex.Globals.event.off("ActionSettings:ActionDeleted", onActionDeleted));

        comp.funcOnLoad = async () => await this.OnLoad(comp, node);
    }

    OnLoad(comp: CustomNodeTogglerComponent, node){
        comp.funcOnLoad = () => { };

        comp.gltfLoadingHandlerComp = comp.node.getComponent("GltfLoadingHandler") as CustomGltfLoadingHandlerComponent;

        if(comp.gltfLoadingHandlerComp.isLoaded){
            this.onLoaded(comp.gltfLoadingHandlerComp);
        }
        else{
            comp.gltfLoadingHandlerComp.onLoaded.on(this.onLoaded);
        }
    }
    
    onLoaded = (async (gltfLoadingHandlerComp: CustomGltfLoadingHandlerComponent) => {
        const comp = gltfLoadingHandlerComp.node.getComponent("NodeToggler") as CustomNodeTogglerComponent;
        comp.gltfLoadingHandlerComp.onLoaded.off(this.onLoaded);
    }).bind(this);

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
    }
}

export class NodeTogglerComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new CustomNodeTogglerComponent();
    }

    constructor() {        
        super("NodeToggler", new NodeTogglerComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}
import { AugmentedStoreAssembly } from "../../../AugmentedStoreAssembly";
import { SPACEPRIVACY_PRIVATE_TAG, SPACEPRIVACY_PUBLISHED_TAG } from "../../utilities/constants";
import { ResourceUtils, TagMode, HevolusResourceType, VERTEXResource } from "../../utilities/resource-utilities";

export enum WarpType {
    Verse = 'verse',
    Public = 'public',
    PublicCatalog = 'public_catalog',
    PrivateCatalog = 'private_catalog',
}
export class CustomWarpRenderer implements Vertex.UI.ICustomInspectorRenderer {

    panelContainer: HTMLDivElement;
    privateRow: HTMLDivElement;
    privateDropdown: HTMLSelectElement;
    privateHiddenCheckbox: HTMLInputElement;
    privateHiddenToggle: HTMLSpanElement;
    publicHostRow: HTMLDivElement;
    publicHostDropdown: HTMLSelectElement;
    publicHostHiddenCheckbox: HTMLInputElement;
    publicHiddenToggle: HTMLSpanElement;
    publicLinkRow: HTMLDivElement;
    publicLinkDropdown: HTMLSelectElement;
    publicLinkHiddenCheckbox: HTMLInputElement;
    publicLinkHiddenToggle: HTMLSpanElement;
    
    static privateSpaces:    VERTEXResource[] = [];
    static publicHostSpaces: VERTEXResource[] = [];
    static publicLinkSpaces: VERTEXResource[] = [];

    constructor(targetNode: Vertex.NodeComponentModel.VertexNode) {
        this.targetNode = targetNode;
    }

    targetNode: Vertex.NodeComponentModel.VertexNode;
    warp: AugmentedStoreAssembly.WarpComponent;

    RenderProperty(property: string, target: any): HTMLDivElement {
        let outer = document.createElement('div');

        const panelTemplate = document.getElementById("warp-panel-template") as HTMLTemplateElement;
        this.panelContainer = panelTemplate.content.firstElementChild.cloneNode(true) as HTMLDivElement;

        this.warp = target as AugmentedStoreAssembly.WarpComponent;

        this.privateRow = this.panelContainer.querySelector("#warp-panel-row-private") as HTMLDivElement;
        this.privateDropdown = this.privateRow.querySelector("select") as HTMLSelectElement;
        this.privateHiddenCheckbox = this.privateRow.querySelector("input") as HTMLInputElement;
        this.privateHiddenToggle = this.privateRow.querySelector("span") as HTMLSpanElement;

        this.publicHostRow = this.panelContainer.querySelector("#warp-panel-row-public-host") as HTMLDivElement;
        this.publicHostDropdown = this.publicHostRow.querySelector("select") as HTMLSelectElement;
        this.publicHostHiddenCheckbox = this.publicHostRow.querySelector("input") as HTMLInputElement;
        this.publicHiddenToggle = this.publicHostRow.querySelector("span") as HTMLSpanElement;

        this.publicLinkRow = this.panelContainer.querySelector("#warp-panel-row-public-link") as HTMLDivElement;
        this.publicLinkDropdown = this.publicLinkRow.querySelector("select") as HTMLSelectElement;
        this.publicLinkHiddenCheckbox = this.publicLinkRow.querySelector("input") as HTMLInputElement;
        this.publicLinkHiddenToggle = this.publicLinkRow.querySelector("span") as HTMLSpanElement;

        this.renderWarpSections(outer)

        outer.appendChild(this.panelContainer);

        return outer;
    }

    private async renderWarpSections(outer: HTMLDivElement) {
        this.privateHiddenCheckbox.checked = this.warp.hidePrivateHost;
        this.privateHiddenToggle.onclick = (() => {
            this.privateHiddenCheckbox.checked = !this.privateHiddenCheckbox.checked;
            this.warp.hidePrivateHost = this.privateHiddenCheckbox.checked;
            this.warp.triggerOnChanged();
        });

        this.publicHostHiddenCheckbox.checked = this.warp.hidePublicHost;
        this.publicHiddenToggle.onclick = (() => {
            this.publicHostHiddenCheckbox.checked = !this.publicHostHiddenCheckbox.checked;
            this.warp.hidePublicHost = this.publicHostHiddenCheckbox.checked;
            this.warp.triggerOnChanged();
        });

        this.publicLinkHiddenCheckbox.checked = this.warp.hide;
        this.publicLinkHiddenToggle.onclick = (() => {
            this.publicLinkHiddenCheckbox.checked = !this.publicLinkHiddenCheckbox.checked;
            this.warp.hide = this.publicLinkHiddenCheckbox.checked;
            this.warp.triggerOnChanged();
        });

        await this.popolatePrivateDropdown();
        await this.popolatePublicHostDropdown();
        await this.populatePublicLinkDropdown();
    }

    private async popolatePrivateDropdown(){
        if(!CustomWarpRenderer.privateSpaces?.length){
            CustomWarpRenderer.privateSpaces = await ResourceUtils.getResourcesWithParamsAsync([SPACEPRIVACY_PRIVATE_TAG], TagMode.Any, ResourceUtils.getVertexResourceType(HevolusResourceType.Space), false, false);
            
            const currentSpaceIndex = CustomWarpRenderer.privateSpaces.findIndex(s => s.id === Vertex.Globals.spaceId);

            if(currentSpaceIndex !== -1){
                CustomWarpRenderer.privateSpaces.splice(currentSpaceIndex, 1);
            }
        }
        
        this.privateDropdown.options[0].value = "";
        this.privateDropdown.options[0].text = "None";
        this.privateDropdown.options[0].hidden = false;
        this.privateDropdown.options[0].disabled = false;

        this.privateDropdown.disabled = false;
        this.privateDropdown.selectedIndex = 0;

        for (let i = 0; i < CustomWarpRenderer.privateSpaces.length; i++) {
            const space = CustomWarpRenderer.privateSpaces[i];

            const option = document.createElement("option");
            option.value = space.id;
            option.text = space.name;
            this.privateDropdown.appendChild(option);

            if (this.warp.privateHost === option.value) {
                this.privateDropdown.selectedIndex = i+1;
            }           
        }

        this.privateDropdown.onchange = (() => {
            this.warp.privateHost = this.privateDropdown.value;
            this.warp.triggerOnChanged();
        });
    }

    private async popolatePublicHostDropdown(){
        if(!CustomWarpRenderer.publicHostSpaces?.length){
            CustomWarpRenderer.publicHostSpaces = await ResourceUtils.getResourcesWithParamsAsync([SPACEPRIVACY_PUBLISHED_TAG], TagMode.Any, ResourceUtils.getVertexResourceType(HevolusResourceType.Space), false, true);
            
            const currentSpaceIndex = CustomWarpRenderer.publicHostSpaces.findIndex(s => s.publishParent === Vertex.Globals.spaceId);

            if(currentSpaceIndex !== -1){
                CustomWarpRenderer.publicHostSpaces.splice(currentSpaceIndex, 1);
            }
        }
        
        this.publicHostDropdown.options[0].value = "";
        this.publicHostDropdown.options[0].text = "None";
        this.publicHostDropdown.options[0].hidden = false;
        this.publicHostDropdown.options[0].disabled = false;

        this.publicHostDropdown.disabled = false;
        this.publicHostDropdown.selectedIndex = 0;

        for (let i = 0; i < CustomWarpRenderer.publicHostSpaces.length; i++) {
            const space = CustomWarpRenderer.publicHostSpaces[i];

            const option = document.createElement("option");
            option.value = space.id;
            option.text = space.name;
            this.publicHostDropdown.appendChild(option);

            if (this.warp.publicHost === option.value) {
                this.publicHostDropdown.selectedIndex = i+1;
            }           
        }

        this.publicHostDropdown.onchange = (() => {
            this.warp.publicHost = this.publicHostDropdown.value;
            this.warp.triggerOnChanged();
        });
    }

    private async populatePublicLinkDropdown() {
        if (!CustomWarpRenderer.publicLinkSpaces?.length) {
            const publicLinkCatalog = await ResourceUtils.getPublicLinks();
            let tasks = [];

            for(let i = 0; i < publicLinkCatalog.length; i++){
                const id = publicLinkCatalog[i];
                const promise = new Promise((resolve, reject) => {
                    if(id){
                        ResourceUtils.getResourceData(id).then(res => {
                            resolve(res);
                        });
                    }
                    else{
                        resolve(null);
                    }
                });

                tasks.push(promise);
            }

            await Promise.all(tasks);

            for(let i = 0; i < tasks.length; i++){
                const res = await tasks[i];
                
                if(res){
                    res["publicLinkCatalogIndex"] = i;
                    CustomWarpRenderer.publicLinkSpaces.push(res);                    
                }
            }
        }

        this.publicLinkDropdown.options[0].value = "-1";
        this.publicLinkDropdown.options[0].text = "None";
        this.publicLinkDropdown.options[0].hidden = false;
        this.publicLinkDropdown.options[0].disabled = false;

        this.publicLinkDropdown.disabled = false;
        this.publicLinkDropdown.selectedIndex = 0;

        for (let i = 0; i < CustomWarpRenderer.publicLinkSpaces.length; i++) {
            const space = CustomWarpRenderer.publicLinkSpaces[i];

            const option = document.createElement("option");
            option.value = space["publicLinkCatalogIndex"];
            option.text = space.name;
            this.publicLinkDropdown.appendChild(option);

            if (this.warp.data === option.value) {
                this.publicLinkDropdown.selectedIndex = i+1;
            }           
        }

        this.publicLinkDropdown.onchange = (() => {
            this.warp.data = this.publicLinkDropdown.value;
            this.warp.triggerOnChanged();
        });
    }
}
import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { ICatalogItem, IModal } from "../catalog.interfaces";
import { QRUtils } from '../qr/qrutils';
import { DELETE_MODAL_TEMPLATE_SELECTOR } from '../qr/qr-interfaces';

export class TagModal implements IModal{

    constructor(private items: ICatalogItem[], private item?: ICatalogItem) {
        
    }

    get modalRootEl() {
        return document.body.querySelector<HTMLElement>('#tags-modal');
    }

    get formEl(){
        return this.modalRootEl.querySelector<HTMLFormElement>('form');
    }

    get saveButton() {
        return this.modalRootEl.querySelector<HTMLElement>('.modal-footer #create-button');
    }

    get cancelButton() {
        return this.modalRootEl.querySelector<HTMLElement>('.modal-footer #cancel-button');
    }

    get alertEl(){
        return this.modalRootEl.querySelector<HTMLDivElement>('#tag-alert');
    }

    private isEditing = !!this.item && !!this.item.id;

    show(callback?: (item: ICatalogItem) => void) {
        if(this.modalRootEl)
            return;

        this._createModal();
        ($(this.modalRootEl) as any).modal("show");
        this.saveButton.onclick = () => this._onSave(callback);
        this.cancelButton.onclick = () => this.close();

        /*
            If the user presses the Enter key on the
            tag creation modal, the tag is saved
        */
        this.modalRootEl.addEventListener('keydown', (e) => {
            if (e.key === "Enter") {
                this._onSave(callback);
            }
        });
    }

    close(){
        ($(this.modalRootEl) as any).modal("hide");
        this.modalRootEl.remove();
    }

    private _setTitle(title: string) {
        const titleEl = this.modalRootEl.querySelector<HTMLElement>('.modal-title');
        titleEl.innerText = title;
    }

    private _createModal() {
        const modalEl = QRUtils.loadTemplate<HTMLDivElement>("#tags-modal-template");

        document.body.appendChild(modalEl);

        if (!this.isEditing) {
            return;
        }

        const formEl = this.formEl;

        for (let index = 0; index < formEl.elements.length; index++) {
            const element = formEl.elements[index] as HTMLInputElement;

            element.value = this.item[element.name];
        }
    }


    private _onSave(callback?: (item: ICatalogItem) => void) { 
        const formEl = this.formEl;
        let tagItem = {};
        this.alertEl.classList.add('d-none');
        formEl.classList.add('invalid-form-control');

        for (let i = 0; i < formEl.elements.length; i++) {
            const element = formEl.elements[i] as HTMLInputElement;
            tagItem[element.name] = element.value.trim();
        }

        tagItem['id'] = uuidv4();

        const newItem: ICatalogItem = tagItem as ICatalogItem;
        const findEl = this.items.find(i => i.name === newItem.name);
        
        if(!!findEl) {
            this.alertEl.classList.remove('d-none');
            return;
        }        
        
        if(!this._isValid()){   
            return;
        }
        
        if (!callback) {
            return;
        }
        
        callback(newItem);
    }

    private _isValid(){
        const formEl = this.formEl;

        for (let i = 0; i < formEl.elements.length; i++) {
            const element = formEl.elements[i] as HTMLInputElement;

            if(!element.checkValidity() || isEmpty(element.value.trim()) || element.value.includes(',')){
                return false;
            }

        }

        return true;
    }
}
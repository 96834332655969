import { AugmentedStoreAssembly } from '../../../../AugmentedStoreAssembly';


export class UnselectableComponentView extends Vertex.NodeComponentModel.ComponentViewBase {

    constructor() {
        super();
    }

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {

    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
    }

}

export class UnselectableComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new AugmentedStoreAssembly.UnselectableComponent();
    }

    constructor() {
        
        super("Unselectable", new UnselectableComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}

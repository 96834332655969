import { Utils } from "../../utilities/utils";

export class CustomBooleanInspector implements Vertex.UI.ICustomInspectorRenderer {

    constructor(targetNode: Vertex.NodeComponentModel.VertexNode) {
        this.targetNode = targetNode;
    }
    targetNode: Vertex.NodeComponentModel.VertexNode;
    target: Vertex.NodeComponentModel.Component;
    property: string;

    row = document.createElement("div");

    //Target appears to be the node but we also have just targetNode so not sure why it's needed.
    RenderProperty(property: string, target: any): HTMLDivElement {
        this.target = target;
        //Assuming property is "shouldPlay"
        this.property = property;

        //Get GLTF
        let animComp = null;// target as CustomAnimationComponent;
        
        let row = document.createElement("div"); row.classList.add("row", "control-group","input-row","align-items-center","pad-border-bottom");
        let nameCol = document.createElement("div"); nameCol.classList.add("col", "control-label"); nameCol.innerText = Utils.getHumanReadableString(property); row.appendChild(nameCol);
        let inputCol = document.createElement("div"); inputCol.classList.add("col-3", "control-value", "switch-custom");
        let inputField = document.createElement("input") as HTMLInputElement; inputField.type = "checkbox"; 
        inputField.classList.add("small-checkbox");
        let span = document.createElement("span");
        let currentValue = target[property];
        inputField.checked = currentValue;
        inputField.appendChild(span);
        //inputField.value =  
        inputField.addEventListener("change", ()=>{
            target[property] = inputField.checked; 
            target.triggerOnChanged();
        });
        
        inputCol.appendChild(inputField);
        row.appendChild(inputCol);

        //Removing Outer wrapper for now.
        //outer.appendChild(row); 
        
        //animComp.onChanged.on(()=>{this.renderAnimationList(outer, animComp)});

        
        
        return row;
    }
}
import { AugmentedStoreAssembly } from "../../../../AugmentedStoreAssembly";

class MyTextBlock extends BABYLON.GUI.TextBlock {
    strokeColor = "black";
    _applyStates(context) {
        super._applyStates(context);
        context.strokeStyle = this.strokeColor;
    }
}

export enum TextAlignment {
    TOP_LEFT,
    TOP_CENTER,
    TOP_RIGHT,
    MIDDLE_LEFT,
    MIDDLE_CENTER,
    MIDDLE_RIGHT,
    BOTTOM_LEFT,
    BOTTOM_CENTER,
    BOTTOM_RIGHT
}

export class CustomText2DComponent extends AugmentedStoreAssembly.Text2DComponent {
    public plane: BABYLON.Mesh;
    public textBlock: MyTextBlock;
    //public align: TextAlignment = TextAlignment.MIDDLE_CENTER;

    public textPxPerMeter = 12;
    public textQualityMultiplier = 15;
    public widthPaddingPerc = 0.5;
    public resolution = this.textPxPerMeter * this.textQualityMultiplier;

    public setText(text: string) {
        this.text = text;
        this.triggerOnChanged();
    }

    public setStyle(options: {
        color?: string,
        size?: number,
        font?: string,
        bold?: boolean,
        italic?: boolean,
        underlined?: boolean,
        strikethrough?: boolean
    }) {
        if (options.color) {
            this.color = options.color;
        }
        if (options.size) {
            this.size = options.size;
        }
        if (options.font) {
            this.font = options.font;
        }
        if (options.bold === true || options.bold === false) {
            this.bold = options.bold;
        }
        if (options.italic === true || options.italic === false) {
            this.italic = options.italic;
        }
        if (options.underlined === true || options.underlined === false) {
            this.underlined = options.underlined;
        }
        if (options.strikethrough === true || options.strikethrough === false) {
            this.strikethrough = options.strikethrough;
        }
        this.triggerOnChanged();
    }

    public setTextAlignment(textAlignment: TextAlignment) {
        this.textAlignment = textAlignment;
        this.triggerOnChanged();
    }

    funcOnChanged() {
        this.textBlock.text = this.text;

        if (this.color) {
            this.textBlock.color = this.color;
            this.textBlock.strokeColor = this.color;
        }
        if (this.size) {
            this.textBlock.fontSize = this.size * this.textQualityMultiplier;
        }
        if (this.font) {
            this.textBlock.fontFamily = ""; //HACK
            this.textBlock.fontFamily = this.font;
        }

        if (this.bold === true && !this.textBlock.fontStyle.includes("bold")) {
            this.textBlock.fontStyle += "bold ";
        }
        else if (this.bold === false) {
            this.textBlock.fontStyle = this.textBlock.fontStyle.replace("bold ", "");
        }

        if (this.italic === true && !this.textBlock.fontStyle.includes("italic")) {
            this.textBlock.fontStyle += "italic ";
        }
        else if (this.italic === false) {
            this.textBlock.fontStyle = this.textBlock.fontStyle.replace("italic ", "");
        }

        if (this.underlined !== undefined) {
            this.textBlock.underline = this.underlined;
        }

        if (this.strikethrough !== undefined) {
            this.textBlock.lineThrough = this.strikethrough;
        }
    }
}
export class Text2DComponentView extends Vertex.NodeComponentModel.ComponentViewBase {
    constructor() {
        super();
    }



    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        const comp = component as CustomText2DComponent;
        const itemProp = node.getComponent("ItemProperties") as AugmentedStoreAssembly.ItemPropertiesComponent;

        if (itemProp) {
            itemProp.isMovable = false;
            itemProp.isSelectable = false;
            itemProp.isWishlistable = false;
            itemProp.triggerOnChanged();
        }

        comp.text = comp.text || "Text";
        comp.color = comp.color || "#FFFFFF";
        comp.size = comp.size || comp.textPxPerMeter;
        comp.font = comp.font || "Arial";

        comp.plane = BABYLON.MeshBuilder.CreatePlane("Text2Dplane", { size: 1 });
        const parent = new BABYLON.TransformNode("Text2Dparent");
        comp.plane.parent = parent;
        parent.parent = node.viewNode;

        const advancedTexture = BABYLON.GUI.AdvancedDynamicTexture.CreateForMesh(comp.plane, comp.resolution, comp.resolution);

        comp.textBlock = new MyTextBlock();
        comp.textBlock.fontStyle = "";

        comp.textBlock.onLinesReadyObservable.add(() => {
            const widthPadding = comp.textBlock.fontSizeInPixels * comp.widthPaddingPerc;
            const lineWidth = comp.textBlock.lines[0].width + widthPadding;

            comp.textBlock.widthInPixels = lineWidth + widthPadding;
            const expectedHeight = comp.textBlock.computeExpectedHeight();

            comp.plane.scaling = new BABYLON.Vector3(lineWidth / comp.resolution, expectedHeight / comp.resolution, 1);
            advancedTexture.scaleTo(lineWidth, expectedHeight);
            this.alignText(comp.textAlignment, comp.plane);
        });

        comp.textBlock.onAfterDrawObservable.add(
            () => {
                const widthPadding = comp.textBlock.fontSizeInPixels * comp.widthPaddingPerc;
                const lineWidth = comp.textBlock.lines[0].width + widthPadding;
                comp.textBlock.widthInPixels = lineWidth;

                const expectedHeight = comp.textBlock.computeExpectedHeight();
                comp.textBlock.heightInPixels = expectedHeight;
            }
        );

        advancedTexture.addControl(comp.textBlock);

        comp.onChanged.on(comp.funcOnChanged.bind(comp));
    }

    alignText(textAlignment: TextAlignment, plane: BABYLON.Mesh) {
        switch (textAlignment) {
            case TextAlignment.TOP_LEFT:
                plane.position = new BABYLON.Vector3(plane.scaling.x / 2, -plane.scaling.y / 2, 0);
                break;
            case TextAlignment.TOP_CENTER:
                plane.position = new BABYLON.Vector3(0, -plane.scaling.y / 2, 0);
                break;
            case TextAlignment.TOP_RIGHT:
                plane.position = new BABYLON.Vector3(-plane.scaling.x / 2, -plane.scaling.y / 2, 0);
                break;
            case TextAlignment.MIDDLE_LEFT:
                plane.position = new BABYLON.Vector3(plane.scaling.x / 2, 0, 0);
                break;
            case TextAlignment.MIDDLE_CENTER:
                plane.position = new BABYLON.Vector3(0, 0, 0);
                break;
            case TextAlignment.MIDDLE_RIGHT:
                plane.position = new BABYLON.Vector3(-plane.scaling.x / 2, 0, 0);
                break;
            case TextAlignment.BOTTOM_LEFT:
                plane.position = new BABYLON.Vector3(plane.scaling.x / 2, plane.scaling.y / 2, 0);
                break;
            case TextAlignment.BOTTOM_CENTER:
                plane.position = new BABYLON.Vector3(0, plane.scaling.y / 2, 0);
                break;
            case TextAlignment.BOTTOM_RIGHT:
                plane.position = new BABYLON.Vector3(-plane.scaling.x / 2, plane.scaling.y / 2, 0);
                break;
        }
    }


    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
    }

    update(): void {
    }
}

export class Text2DComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new CustomText2DComponent();
    }

    constructor() {
        super("Text2D", new Text2DComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}
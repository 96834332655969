import Swal, { SweetAlertOptions } from 'sweetalert2';
import { getComponentJsonFromResource, postComponentJsonToResource, Utils } from '../../../utilities/utils';
import { AugmentedStoreAssembly } from '../../../../AugmentedStoreAssembly';
import { CustomLightMapsComponent } from '../NodeComponents/lightmapscomponent';
import { IValidable } from './scenevalidator';
import { indexOf } from 'lodash';
import { ResourceUtils, VERTEXResource } from '../../../utilities/resource-utilities';
import { UploadUtils, UploadingAssetType } from '../../../utilities/upload-utilities';
import { ALLOWED_LIGHTMAPS_TYPES } from '../../../utilities/constants';


export class LightMap {
    constructor(name, level = 1) {
        this.fileName = name;
        this.level = level;
    }

    fileName: string;
    level: number = 1;
}

export class CustomLightMapsHandlerComponent extends AugmentedStoreAssembly.LightMapsHandlerComponent implements IValidable {
    isValidableComponent: boolean = true;

    isReady: Promise<void>;
    lightMaps: LightMap[] = null;


    postComponentJsonToResource = (async (isEditorVersion: boolean = true) => {
        await postComponentJsonToResource(Vertex.Globals.spaceId, "LightMapsHandler", this.node.id, isEditorVersion, JSON.stringify(this.lightMaps));
    }).bind(this);

    async getUsedFiles(): Promise<string[]> {
        await this.isReady;
        return this.lightMaps.map((lightMap) => lightMap.fileName);
    }

    public removeMissingFile = (async (fileName: string) => {
        await this.isReady;

        const usedFiles = await this.getUsedFiles();

        if(usedFiles.includes(fileName)){
            this.lightmapsToDelete.push(fileName);
            await this.removeDeletedLightmaps();
        }
    }).bind(this);

    public saveLightmaps = (async () =>{
        await this.postComponentJsonToResource(true);

        this.triggerOnChanged();
    }).bind(this);

    public removeDeletedLightmaps = (async () => {
        let lightmapComponents: CustomLightMapsComponent[] = [];
        const vertexRuntime = Vertex.Globals.runtime as Vertex.VertexRuntime;
        const nodesWithLightmap =  Array.from(vertexRuntime.space.nodes.values()).filter(node => node.components.includes("LightMaps"));

        nodesWithLightmap.forEach(node => {
            let lightMapsComp = node.getComponent("LightMaps") as CustomLightMapsComponent;
            
            if (lightMapsComp) {
                lightmapComponents.push(lightMapsComp);
            }
        });

        for (const lightmapToDelete of this.lightmapsToDelete) {
            const deleteResponse = await ResourceUtils.deleteAssetFromResource(Vertex.Globals.spaceId, lightmapToDelete);
            const lightmapIndex = this.lightMaps.findIndex((lightmap) => lightmap.fileName == lightmapToDelete);
            
            if (lightmapIndex > -1) {
                this.lightMaps.splice(lightmapIndex, 1);
                
                for (const lightMapsComp of lightmapComponents) {
                    for (let index = 0; index < lightMapsComp.lightMapsData.length; index++) {
                        if (lightMapsComp.lightMapsData[index] > lightmapIndex) {
                            lightMapsComp.lightMapsData[index]--;
                        }
                        else if(lightMapsComp.lightMapsData[index] == lightmapIndex){
                            lightMapsComp.lightMapsData[index] = -1;
                        }
                    }
                }
            }
        }

        this.lightmapsToDelete = [];

        await this.saveLightmaps();

        for (const lightMapsComp of lightmapComponents) {
            lightMapsComp.triggerOnChanged();
        }

        Vertex.Globals.event.fire("LightMaps:LightMapsUpdated");
    }).bind(this);

    private static _lightMapsHandlerComp: CustomLightMapsHandlerComponent;

    static get lightMapsHandlerComp(): CustomLightMapsHandlerComponent {
        if (this._lightMapsHandlerComp) {
            return this._lightMapsHandlerComp;
        }

        let runtime = Vertex.Globals.runtime as Vertex.VertexRuntime;

        runtime.space.nodes.forEach(node => {
            if (node.components.includes("LightMapsHandler")) {
                this._lightMapsHandlerComp = node.getComponent("LightMapsHandler") as CustomLightMapsHandlerComponent;
            }
        });

        return this._lightMapsHandlerComp;
    }

    static async getLightmaps(): Promise<LightMap[]> {
        if(this.lightMapsHandlerComp){
            await this.lightMapsHandlerComp.isReady;
            return this.lightMapsHandlerComp.lightMaps;
        }

        return null; 
    }

    lightmapsToDelete: string[] = [];

    static async getLightmapsToDelete(): Promise<string[]> {
        if(this.lightMapsHandlerComp){
            await this.lightMapsHandlerComp.isReady;
            return this.lightMapsHandlerComp.lightmapsToDelete;        
        }

        return null; 
    }

    writeData(writer: Vertex.BinaryWriter): void {
    }
    readData(reader: Vertex.BinaryReader): void {
    }
}

export class LightMapsHandlerComponentView extends Vertex.NodeComponentModel.ComponentViewBase {

    constructor() {
        super();
    }

    panelBody: HTMLDivElement;
    panel: HTMLDivElement;
    comp: CustomLightMapsHandlerComponent;
    scrollableListView: HTMLUListElement;
    //lightmapListEmpty: HTMLSpanElement;

    selectedLightmapWrapper: HTMLElement;

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        this.comp = component as CustomLightMapsHandlerComponent;

        this.comp.isReady = new Promise<void>(async (resolve, reject) => {

            let res = await getComponentJsonFromResource(Vertex.Globals.spaceId, "LightMapsHandler", node.id, true);
            if (res.ok) {
                this.comp.lightMaps = await res.json() as LightMap[];
            }
            else {
                res = await getComponentJsonFromResource(Vertex.Globals.spaceId, "LightMapsHandler", node.id, false);
                if (res.ok) {
                    this.comp.lightMaps = await res.json() as LightMap[];
                    await this.comp.postComponentJsonToResource(true);
                } else {
                    this.comp.lightMaps = [];
                    await this.comp.postComponentJsonToResource(true);
                }
            }

            const beforeSaveSpace = (async () => {
                await this.comp.removeDeletedLightmaps();
                await this.comp.postComponentJsonToResource(false);
            }).bind(this);

            this.comp.onRemoved.on((async () => {
                Vertex.Globals.event.off("hevolus:beforeSaveSpace", beforeSaveSpace);
                this.comp.lightMaps = [];
                await this.comp.postComponentJsonToResource(true);
            }).bind(this));

            Vertex.Globals.event.on("hevolus:beforeSaveSpace", beforeSaveSpace);


            resolve();
        });

        this.comp.isReady.then(()=>{

            this.panel = document.querySelector(".container-overlay-left");
            this.panel?.classList.remove("hidden");
    
            // Create Hierarchy Panel
    
            let card = document.createElement("div");
            card.classList.add("card", "pointer-enable");
            card.id = "light-maps-panel";
    
            let header = document.createElement("div");
            header.classList.add("nav-link", "card-header-minimizable", "dark-text", "max");
    
            let headerIcon = document.createElement("img");
            headerIcon.src = "/img/scene-icon-active.svg";
            headerIcon.classList.add("card-header-icon");
    
            let headerText = document.createElement("div");
            headerText.classList.add("card-header-text");
            headerText.innerText = "Lightmaps list:";
    
            let body = document.createElement("div");
            body.classList.add("card-body", "p-0");
    
            let panel = document.createElement("div");
            panel.classList.add("lightmaps-handler-panel");
    
    
            let leftSidebar = document.querySelector(".left-sidebar-grid");
    
            //dropzone
            const dropzone = document.createElement("div") as HTMLDivElement;
            dropzone.innerText = "Drag and drop or click to upload files.";
            dropzone.classList.add("dropzone-wide");
            dropzone.style.margin = "28px 10px";
            dropzone.style.padding = "20px 2px";
            dropzone.addEventListener("drop", this.handleFilesDropped);
            dropzone.addEventListener("dragenter", this.handleDragEnterEvent(dropzone));
            dropzone.addEventListener("dragleave", this.handleDragLeaveEvent(dropzone));
            dropzone.addEventListener("dragover", (event) => event.preventDefault());
            dropzone.addEventListener("click", () => uploader.click());
    
            //<input type="file" multiple />
            let uploader = document.createElement("input");
            uploader.setAttribute("multiple", "multiple");
            uploader.setAttribute("type", "file");
            uploader.setAttribute("accept", "." + ALLOWED_LIGHTMAPS_TYPES.join(",."));
            uploader.setAttribute("style", "display: none");
            uploader.style.display = "none";
            uploader.addEventListener('change', async () => {
                await this.uploadLightMaps(...uploader.files);
    
                uploader.value = "";
            });
    
            dropzone.appendChild(uploader);
            panel.appendChild(dropzone);
    
    
            //List of Lightmaps
            let scrollableListLabel = document.createElement("div");
            scrollableListLabel.classList.add("skybox-text");
            scrollableListLabel.innerText = "Lightmaps";
            panel.appendChild(scrollableListLabel);
    
            this.scrollableListView = document.createElement("ul") as HTMLUListElement;
            this.scrollableListView.style.maxHeight = "250px";
            this.scrollableListView.style.height = "auto";
    
            this.scrollableListView.classList.add("skybox-list-group");
            panel.appendChild(this.scrollableListView);
    
            // this.lightmapListEmpty = document.createElement("span");
            // this.lightmapListEmpty.classList.add("empty-list-message");
            // this.lightmapListEmpty.innerText = "Empty list";
    
            // panel.appendChild(this.lightmapListEmpty);
    
            leftSidebar?.appendChild(card);
            card.appendChild(header);
            header.appendChild(headerIcon);
            header.appendChild(headerText);
            card.appendChild(body);
            body.appendChild(panel);
    
            // Sidebar
    
            Utils.setupSidebarButton("LightMapsHandler", "light-maps-panel");
    
            this.comp.lightMaps.forEach((lightmap) => {
                this.listLightmap(lightmap);
            });
    
            this.updateNodesWithLightmaps();
        })

    }

    private handleDragEnterEvent(dropzone: HTMLDivElement): (this: HTMLDivElement, ev: DragEvent) => any {
        return (event) => {
            event.preventDefault();
            dropzone.className = "dropzone-wide-drag";
        };
    }

    private handleDragLeaveEvent(dropzone: HTMLDivElement): (this: HTMLDivElement, ev: DragEvent) => any {
        return (event) => {
            event.preventDefault();
            dropzone.className = "dropzone-wide";
        };
    }

    handleFilesDropped = async (event: DragEvent) => {
        event.preventDefault();
        const droppedFiles: File[] = this.getFilesDropped(event) as File[];
        await this.uploadLightMaps(...droppedFiles);
    }

    private async uploadLightMaps(...files: File[]) {
        // we upload the image, replacing existing one with same name in case
        const uploadResult = await UploadUtils.uploadFiles(files, Vertex.Globals.spaceId, UploadingAssetType.SpaceLightmap);

        for (let i = 0; i < uploadResult.successfulUploads.length; i++) {
            const uploadedFile = uploadResult.successfulUploads[i];
            
            // if the lightmap already exists, we do not create a new entry for it
            // the existing entry will use the overwritten image file
            if(this.comp.lightMaps.findIndex(l => l.fileName === uploadedFile) === -1){
                const lightMap = new LightMap(uploadedFile);
                this.comp.lightMaps.push(lightMap);
                this.listLightmap(lightMap);
            }
            
            // if the user during this session deleted a lightmap and now uploaded again that specific image file,
            // we need to remove it from the images marked as to be deleted
            if(this.comp.lightmapsToDelete.includes(uploadedFile)){
                this.comp.lightmapsToDelete.splice(indexOf(this.comp.lightmapsToDelete, uploadedFile), 1);
            }
        }

        await this.comp.saveLightmaps();
        await this.updateNodesWithLightmaps();
    }

    getFilesDropped = (event: DragEvent) => {
        let files: File[] | DataTransferItem[] = [];

        if (event.dataTransfer) {
            const dataTransfer = event.dataTransfer;
            if (dataTransfer.files && dataTransfer.files.length > 0) {
                files = [...dataTransfer.files];
            } else if (dataTransfer.items && dataTransfer.items.length > 0) {
                files = [...dataTransfer.items];
            } else {
                throw new Error("No Data Transfer items or files found.");
            }
        }

        return files;
    }



   

    // /**
    // *  Fetches lightmaps json from resource. If unsuccessful, creates an empty one.
    // */
    // async getLightmapsJson(): Promise<boolean> {
    //     let res = await Utils.getAssetFromResource(Vertex.Globals.spaceId, Utils.LIGHTMAPS_FILENAME)
    //     if (res.ok) {
    //         this.comp.lightmaps = await res.json();
    //         return true;
    //     }
    //     else {
    //         this.comp.lightmaps = [];
    //         await this.saveLightmaps();
    //         // console.error("error getting lightmaps file: " + res.status + res.statusText);
    //         return false;
    //     }
    // }

    private listLightmap(lightmap: LightMap) {

        // if(this.comp.sceneLightMaps.length > 0){
        //     this.lightmapListEmpty.style.display = "none";
        // }
        // else{
        //     this.lightmapListEmpty.style.removeProperty("display");
        // }

        let singleElementWrapper = document.createElement("li");
        singleElementWrapper.dataset["lightmapname"] = lightmap.fileName;
        singleElementWrapper.style.display = "block";
        singleElementWrapper.classList.add("scene-list", "skybox-holder");
        singleElementWrapper.style.backgroundColor = "#dddddd";

        // singleElementWrapper.id = "TEST" + i;
        this.scrollableListView.appendChild(singleElementWrapper);

        let singleElement = document.createElement("div");

        let labelElement = document.createElement("div");
        labelElement.id = "lightmap-label";
        labelElement.classList.add("scene-list-text", "align-self-center");
        labelElement.innerText = `${lightmap.fileName} (level: ${lightmap.level})`;

        //Add the DELETE buttons to each lightmap panel in the scrollable list view
        let lightmapDeleteButton = document.createElement("button");
        lightmapDeleteButton.classList.add("float-right", "btn-sm", "icon-button", "btn", "btn-danger", "delete-button");

        singleElement.appendChild(labelElement);
        singleElement.appendChild(lightmapDeleteButton);

        singleElement.style.display = "flex";

        singleElementWrapper.appendChild(singleElement);

        lightmapDeleteButton.addEventListener("click", async (event) => {
            event.stopPropagation();

            await Swal.fire({
                title: `Are you sure you want to delete <br>${lightmap.fileName}<br>?`,
                text: `Deleting a lightmap from the Space is permanent`,
                showCancelButton: true,
                showConfirmButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                confirmButtonColor: "red",
                heightAuto: false,
                width: "auto"
            }).then((result) => {
                if (result.isConfirmed) {
                    this.comp.lightmapsToDelete.push(lightmap.fileName);

                    Vertex.Globals.event.fire("LightMaps:LightMapDeleted", lightmap.fileName);

                    if (this.selectedLightmapWrapper == singleElementWrapper) {
                        this.selectedLightmapWrapper = null;
                    }

                    singleElementWrapper.remove();

                    const lightmapIndex = this.comp.lightMaps.findIndex(l => l == lightmap);
                    
                    this.comp.lightMaps.splice(lightmapIndex, 1);

                    LightMapsHandlerComponentView.updateLightmapReferences(lightmapIndex);
                }
            });
        });

        let lightmapDeleteIcon = document.createElement("img");
        lightmapDeleteIcon.classList.add("node-icon");
        lightmapDeleteIcon.src = "/img/bin.svg";
        lightmapDeleteButton.appendChild(lightmapDeleteIcon);


        singleElementWrapper.addEventListener("click", () => {
            if (this.selectedLightmapWrapper == singleElementWrapper && singleElementWrapper.classList.contains("selected-list-item")) {
                return;
            }

            if (this.selectedLightmapWrapper) {
                this.selectedLightmapWrapper.classList.remove("selected-list-item");
                let slider = this.selectedLightmapWrapper.querySelector("#level-slider");
                let label = this.selectedLightmapWrapper.querySelector("#lightmap-label") as HTMLDivElement;
                label.style.color = "";
                let oldLightmap = this.comp.lightMaps.find((lightmap) => lightmap.fileName == this.selectedLightmapWrapper.dataset["lightmapname"]);

                if (oldLightmap) {
                    label.innerText = `${oldLightmap.fileName} (level: ${oldLightmap.level})`;
                }

                if (slider) {
                    slider.remove();
                }
            }

            singleElementWrapper.classList.add("selected-list-item");

            let onInputChanged = (e) => {
                lightmap.level = Number.parseFloat(e.target.value);
                labelElement.innerText = `${lightmap.fileName} (level: ${lightmap.level})`;
                Vertex.Globals.event.fire("LightMaps:LightMapLevelChanged", lightmap);
            }

            //labelElement.style.color = "white";

            let slider = this.setupPropertySlider("level", 0, 2, 0.1, "level", lightmap.level, onInputChanged);
            slider.id = "level-slider";
            singleElementWrapper.append(slider);

            this.selectedLightmapWrapper = singleElementWrapper;
        });

    }

    public static updateLightmapReferences(deletedLightmapIndex: number) {
        const vertexRuntime = Vertex.Globals.runtime as Vertex.VertexRuntime;
        const nodesWithLightmap =  Array.from(vertexRuntime.space.nodes.values()).filter(node => node.components.includes("LightMaps"));

        nodesWithLightmap.forEach(node => {
            let lightMapsComp = node.getComponent("LightMaps") as CustomLightMapsComponent;

            if (lightMapsComp) {
                for (let index = 0; index < lightMapsComp.lightMapsData.length; index++) {
                    if (lightMapsComp.lightMapsData[index] > deletedLightmapIndex){
                        --lightMapsComp.lightMapsData[index];
                    }
                    else if (lightMapsComp.lightMapsData[index] == deletedLightmapIndex) {
                        lightMapsComp.lightMapsData[index] = -1;
                    }
                }

                lightMapsComp.triggerOnChanged();
            }
        });

        Vertex.Globals.event.fire("LightMaps:LightMapsUpdated");
    }

    private updateNodesWithLightmaps() {
        const vertexRuntime = Vertex.Globals.runtime as Vertex.VertexRuntime;
        const nodesWithLightmap =  Array.from(vertexRuntime.space.nodes.values()).filter(node => node.components.includes("LightMaps"));

        nodesWithLightmap.forEach(node => {
            let lightMapsComp = node.getComponent("LightMaps") as CustomLightMapsComponent;
            if (lightMapsComp) {
                for (let index = 0; index < lightMapsComp.lightMapsData.length; index++) {
                    if (lightMapsComp.lightMapsData[index] >= this.comp.lightMaps.length) {
                        lightMapsComp.lightMapsData[index] = -1;
                    }
                }

                lightMapsComp.triggerOnChanged();
            }
        });

        Vertex.Globals.event.fire("LightMaps:LightMapsUpdated");
    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
    }

    setupPropertySlider(label: string, min: number, max: number, step: number, tooltip: string = null, defaultValue: number, onInputChanged: EventListener): HTMLDivElement {
        let sliderWrapper = document.createElement("div");
        sliderWrapper.classList.add("slider-wrapper", "lightmaps-slider-wrapper");
        sliderWrapper.style.setProperty("background-color", "transparent", "important");

        let sliderTitle = document.createElement("div");
        sliderTitle.classList.add("skybox-text");
        sliderTitle.innerText = label;
        
        if (tooltip) {
            sliderTitle.dataset.toggle = "tooltip";
            sliderTitle.dataset.placement = "bottom";
            sliderTitle.dataset.title = tooltip;
        }
        
        sliderWrapper.appendChild(sliderTitle);

        let slider = document.createElement("input");
        slider.classList.add("pbr-slider");
        slider.setAttribute("type", "range");
        slider.setAttribute("min", min.toString());
        slider.setAttribute("max", max.toString());
        slider.setAttribute("step", step.toString());

        slider.setAttribute("value", defaultValue.toString());

        sliderWrapper.appendChild(slider);

        let sliderValue = document.createElement("div");
        sliderValue.classList.add("skybox-text");
        sliderValue.innerText = slider.value.toString();
        sliderWrapper.appendChild(sliderValue);

        slider.addEventListener("input", () => {
            sliderValue.innerText = slider.value;
        });

        if (onInputChanged != null) {
            slider.addEventListener("input", onInputChanged);
        }

        return sliderWrapper;
    }
}


export class LightMapsHandlerComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new CustomLightMapsHandlerComponent();
    }
    constructor() {
        super("LightMapsHandler", new LightMapsHandlerComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}



import { Config } from '../../config';
import { UserManager, User } from 'oidc-client';
import { ResourceExplorer } from "./resourceexplorer"
import { ContactAdmin } from './contactadmin';
import { AppBase } from '../admin/common/appmodel/appbase';
import Swal from 'sweetalert2';
import { SidebarElement } from '../components/sidebar/sidebar';
import { ButtonType, IButton, ICatalogData, IRedirectData } from '../components/sidebar/sidebar.interfaces';
import { TagCatalog } from '../components/catalog/tag/tag-catalog';
import { QRCatalog } from '../components/catalog/qr/qr-catalog';
import { CATALOG_CONTAINER, ICatalogItem } from '../components/catalog/catalog.interfaces';
import { CatalogBase } from '../components/catalog/catalog-base';
import { Avatar } from '../components/avatar/avatar';
import { LicenseUtils, LicenseValidity } from '../utilities/license-utilities';
import { VERTEXResource, HevolusResourceType, ResourceUtils } from '../utilities/resource-utilities';
import { Utils } from '../utilities/utils';
import { VERTXCLOUD_TENANTADMIN_LICENSE, AUGMENTEDSTORE_ADMIN_LICENSE, HOLOMUSEUM_ADMIN_LICENSE, REMOTESELLING_ADMIN_LICENSE, HOLOPROTOTYPE_ADMIN_LICENSE, HEVOCOLLABORATION_ADMIN_LICENSE, REALISTIC_AVATAR_LICENSE, HEVOCOLLABORATION_CONTENTCREATOR_LICENSE, DECK_EDITOR_URL, SURFACEHUB_DOMAIN, AUGMENTEDSTORE_CONTENTCREATOR_LICENSE, AUGMENTEDSTORE_HOST_LICENSE, HOLOMUSEUM_CONTENTCREATOR_LICENSE, HOLOMUSEUM_HOST_LICENSE, REMOTESELLING_CONTENTCREATOR_LICENSE, REMOTESELLING_HOST_LICENSE, HOLOPROTOTYPE_CONTENTCREATOR_LICENSE, HOLOPROTOTYPE_HOST_LICENSE, HEVOCOLLABORATION_HOST_LICENSE, APP_REMOTESELLING_TAG, CONTENT_TAG_TAG, APP_HOLOMUSEUM_TAG, APP_HOLOPROTOTYPE_TAG, CONTENT_QR_TAG, SERIALIZED_SPACE_FILENAME } from '../utilities/constants';

export enum ViewType {
    Resources = 0,
    Catalog,
    Avatar
}
export class App extends AppBase {

    protected configureServices() {
        this.registerSingleton(App, this);
    }

    static VERTEX_URL = "https://" + Config.VERTEX_URL_BASE

    OAUTH_ROOT = `${Config.VERTEX_IDENTITY_URL}`;
    AUTH_ENDPOINT = "/authorize";

    userManager: UserManager;
    user: User;

    //id: string;
    editorNode: Vertex.NodeComponentModel.VertexNode;
    gltfNode: Vertex.NodeComponentModel.VertexNode;

    sidebar: SidebarElement;
    _openedCatalog: CatalogBase;
    _resourceExplorer: ResourceExplorer;
    _avatarComponent: Avatar;
    _viewSelected: ViewType = ViewType.Resources;

    set viewSelect(view:ViewType){        
        this._resetCatalog();
        // this._resetAvatar();
        this._viewSelected = view;

        const catalogContainerEl = document.querySelector<HTMLElement>(CATALOG_CONTAINER);
        const explorerContainerEl = document.querySelector<HTMLElement>("#explorer-container");
        const searchContainerEl = document.querySelector<HTMLElement>("#search-container");
        const avatarContainerEl = document.querySelector<HTMLElement>("#avatar-container");
        
        catalogContainerEl.hidden = true;
        explorerContainerEl.hidden = true;
        searchContainerEl.hidden = true;  
        avatarContainerEl.hidden = true;   
        

        switch(view){
            case ViewType.Resources:{
                explorerContainerEl.hidden = false;
                searchContainerEl.hidden = false;   
                break;
            }
            case ViewType.Catalog:{
                catalogContainerEl.hidden = false;
                break;
            }
            case ViewType.Avatar:{
                avatarContainerEl.hidden = false;
                break;
            }
        }
    }

    get viewSelect(){
        return this._viewSelected;
    }

    /**
     * called every time page is loaded
     */
    protected async start() {
        const welcomeContainer = document.querySelector(".welcomeContainer");
        const navEl = document.querySelector("nav");

        this.sidebar = document.querySelector<SidebarElement>('side-bar');
        this.sidebar.onclickitem = async(e, button) => await this._onClickItemSidebar(e, button);
        this.sidebar.onlogout = (e: Event) => {
            this.userManager.signoutRedirect({
                id_token_hint: this.user && this.user.id_token
            });
        }

        this.sidebar.activeButtonId = 'resources-button';

        this.sidebar.add({
            text: 'Resources',
            type: ButtonType.Resources,
            icon: 'apps',
            id: 'resources-button',
        });


        //setup Auth
        //this.authManager = this.getService(AuthManager);
        this.userManager = new UserManager({
            client_id: Config.VERTEX_CLIENT_ID,
            authority: this.OAUTH_ROOT,
            redirect_uri: Config.AUTH_CALLBACK_URI,
            post_logout_redirect_uri: Config.AUTH_CALLBACK_URI,
            response_type: "code",
            automaticSilentRenew: false,
            monitorSession: false,
            scope: Config.VERTEX_SCOPE,
            // prompt: "none"
        });

        this.userManager.clearStaleState();
        //this.userManager.clearStaleState();

        //setup main signIn/signOut buttons
        await this.setupBigLoginButton();

        //Sign In if auth code present in URL
        const params = Utils.getURLParams(window.location.search);
        if (params.has("code")) {
            //app makes a request to vertex-identity directly presenting auth-code with other params, which then returns the token
            this.user = await this.userManager.signinRedirectCallback();
            history.replaceState({}, "", "/");
        }

        //setup main signIn/signOut buttons
        await this.setupLoginButtons();

        // show welcome container
        welcomeContainer.classList.remove("hidden");
        navEl.hidden = true;
        this.sidebar.hidden = true;

        //if Signed In
        if (this.user || (this.user = await this.userManager.getUser())) {

            console.log("User signed in")

            //Signs in silently if session becomes inactive
            this.watchSession(() => {
                this.userManager.signinRedirect();
            });

            this.sidebar.userProfile = this.user.profile;

            //set icon with user data and Signout button event
            const userIconElContainer = document.querySelector<HTMLDivElement>(".user-icon-container");
            const imageIconEl = userIconElContainer.firstElementChild as HTMLSpanElement;
            imageIconEl.onclick = () => this.sidebar.toggle();

            //hide welcome container
            welcomeContainer.classList.add("hidden");
            this.setupLoginButtons();


            //Set VERTX stack, and bearer tokens to get resourceid if one exists in URL
            Vertex.Globals.vertexStackUrl = Config.VERTEX_URL_BASE;
            Vertex.Globals.bearerToken = this.user.access_token;
            //await VertexBabylon.InitVertexAsync();

            // enable admin button
            try {
                // todo: also check for stackadmin
                let stackAdminCheckPromise = LicenseUtils.validateStackAdmin();
                let licenseCheck = await LicenseUtils.validateLicenses(
                    VERTXCLOUD_TENANTADMIN_LICENSE,
                    AUGMENTEDSTORE_ADMIN_LICENSE,
                    HOLOMUSEUM_ADMIN_LICENSE,
                    REMOTESELLING_ADMIN_LICENSE,
                    HOLOPROTOTYPE_ADMIN_LICENSE,
                    HEVOCOLLABORATION_ADMIN_LICENSE,
                    REALISTIC_AVATAR_LICENSE);

                if(licenseCheck[REALISTIC_AVATAR_LICENSE] === LicenseValidity.Valid){                    
                    this.sidebar.add({
                        id: 'avatar-button',
                        text: 'Avatar',
                        icon: 'face',
                        type: ButtonType.Avatar
                    });
                }

                let isStackAdmin = await stackAdminCheckPromise;

                let isAdmin = isStackAdmin ||
                    (licenseCheck[VERTXCLOUD_TENANTADMIN_LICENSE] === LicenseValidity.Valid &&
                        (
                            licenseCheck[AUGMENTEDSTORE_ADMIN_LICENSE] === LicenseValidity.Valid ||
                            licenseCheck[HOLOMUSEUM_ADMIN_LICENSE] === LicenseValidity.Valid ||
                            licenseCheck[REMOTESELLING_ADMIN_LICENSE] === LicenseValidity.Valid ||
                            licenseCheck[HOLOPROTOTYPE_ADMIN_LICENSE] === LicenseValidity.Valid ||
                            licenseCheck[HEVOCOLLABORATION_ADMIN_LICENSE] === LicenseValidity.Valid)
                        );

                if (isAdmin) {
                    this.sidebar.add({
                        id: 'admin-button',
                        text: 'Admin',
                        icon: 'admin_panel_settings',
                        order: 0,
                        type: ButtonType.Redirect,
                        data: {
                            url: "/admin",
                            blank: false
                        }
                    });
                }
            }
            catch { }

            // enable hevo collaboration related buttons
            try {
                let licenseCheck = await LicenseUtils.validateLicenses(HEVOCOLLABORATION_CONTENTCREATOR_LICENSE, HEVOCOLLABORATION_HOST_LICENSE);
                let isHCContentCreator = licenseCheck[HEVOCOLLABORATION_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid;
                let isHCHost = licenseCheck[HEVOCOLLABORATION_HOST_LICENSE] === LicenseValidity.Valid;
                const buttons: IButton[] = [];
                
                if(isHCContentCreator || isHCHost){
                    buttons.push(
                        {
                            id: 'deck-button',
                            text: 'Deck Editor',
                            icon: 'dashboard',
                            type: ButtonType.Redirect,
                            data: {
                                url: DECK_EDITOR_URL,
                                blank: true
                            }
                        }
                    );
                
                    buttons.push(
                        {
                            id: 'surface-hub-button',
                            text: 'Stream Hub',
                            icon: 'desktop_windows',
                            type: ButtonType.Redirect,
                            data: {
                                url: SURFACEHUB_DOMAIN,
                                blank: true
                            }
                        }
                    );

                    this.sidebar.add({
                        id: 'hevo-collaboration',
                        text: 'H-Collaboration',
                        icon: 'people_alt',
                        order: 1,
                        type: ButtonType.Group,
                        child: [...buttons]
                    });
                }
            }
            catch (e)
            {
                console.log("Failed to add hevo collaboration related sidebar buttons", e);
            }

            let licenseCheck = await LicenseUtils.validateLicenses(VERTXCLOUD_TENANTADMIN_LICENSE,
                AUGMENTEDSTORE_ADMIN_LICENSE,
                AUGMENTEDSTORE_CONTENTCREATOR_LICENSE,
                AUGMENTEDSTORE_HOST_LICENSE,
                HOLOMUSEUM_ADMIN_LICENSE,
                HOLOMUSEUM_CONTENTCREATOR_LICENSE,
                HOLOMUSEUM_HOST_LICENSE,
                REMOTESELLING_ADMIN_LICENSE,
                REMOTESELLING_CONTENTCREATOR_LICENSE,
                REMOTESELLING_HOST_LICENSE,
                HOLOPROTOTYPE_ADMIN_LICENSE,
                HOLOPROTOTYPE_CONTENTCREATOR_LICENSE,
                HOLOPROTOTYPE_HOST_LICENSE,
                HEVOCOLLABORATION_ADMIN_LICENSE,
                HEVOCOLLABORATION_CONTENTCREATOR_LICENSE,
                HEVOCOLLABORATION_HOST_LICENSE
            );
            //TODO: re-enable prod admin check after sorting out marketplace stuff
            let isAdminOnly = /*(*/licenseCheck[VERTXCLOUD_TENANTADMIN_LICENSE] === LicenseValidity.Valid &&
                // (licenseCheck[Utils.AUGMENTEDSTORE_ADMIN_LICENSE] === LicenseValidity.Valid || 
                //  licenseCheck[Utils.REMOTESELLING_ADMIN_LICENSE] === LicenseValidity.Valid ||
                //  licenseCheck[Utils.HOLOMUSEUM_ADMIN_LICENSE] === LicenseValidity.Valid ||
                //  licenseCheck[Utils.HOLOPROTOTYPE_ADMIN_LICENSE] === LicenseValidity.Valid)) &&
                //  licenseCheck[Utils.HEVOCOLLABORATION_ADMIN_LICENSE] === LicenseValidity.Valid)) &&
                licenseCheck[REMOTESELLING_CONTENTCREATOR_LICENSE] === LicenseValidity.Invalid &&
                licenseCheck[AUGMENTEDSTORE_CONTENTCREATOR_LICENSE] === LicenseValidity.Invalid &&
                licenseCheck[HOLOMUSEUM_CONTENTCREATOR_LICENSE] === LicenseValidity.Invalid &&
                licenseCheck[HOLOPROTOTYPE_CONTENTCREATOR_LICENSE] === LicenseValidity.Invalid &&
                licenseCheck[HEVOCOLLABORATION_CONTENTCREATOR_LICENSE] === LicenseValidity.Invalid &&
                licenseCheck[HOLOMUSEUM_HOST_LICENSE] === LicenseValidity.Invalid &&
                licenseCheck[AUGMENTEDSTORE_HOST_LICENSE] === LicenseValidity.Invalid &&
                licenseCheck[REMOTESELLING_HOST_LICENSE] === LicenseValidity.Invalid &&
                licenseCheck[HOLOPROTOTYPE_HOST_LICENSE] === LicenseValidity.Invalid &&
                licenseCheck[HEVOCOLLABORATION_HOST_LICENSE] === LicenseValidity.Invalid;

            let isHostOnly = (
                licenseCheck[HOLOMUSEUM_HOST_LICENSE] === LicenseValidity.Valid ||
                licenseCheck[REMOTESELLING_HOST_LICENSE] === LicenseValidity.Valid ||
                licenseCheck[AUGMENTEDSTORE_HOST_LICENSE] === LicenseValidity.Valid ||
                licenseCheck[HOLOPROTOTYPE_HOST_LICENSE] === LicenseValidity.Valid ||
                licenseCheck[HEVOCOLLABORATION_HOST_LICENSE] === LicenseValidity.Valid) &&
                licenseCheck[VERTXCLOUD_TENANTADMIN_LICENSE] === LicenseValidity.Invalid &&
                licenseCheck[AUGMENTEDSTORE_ADMIN_LICENSE] === LicenseValidity.Invalid &&
                licenseCheck[REMOTESELLING_ADMIN_LICENSE] === LicenseValidity.Invalid &&
                licenseCheck[HOLOMUSEUM_ADMIN_LICENSE] === LicenseValidity.Invalid &&
                licenseCheck[HEVOCOLLABORATION_ADMIN_LICENSE] === LicenseValidity.Invalid &&
                licenseCheck[REMOTESELLING_CONTENTCREATOR_LICENSE] === LicenseValidity.Invalid &&
                licenseCheck[AUGMENTEDSTORE_CONTENTCREATOR_LICENSE] === LicenseValidity.Invalid &&
                licenseCheck[HEVOCOLLABORATION_CONTENTCREATOR_LICENSE] === LicenseValidity.Invalid &&
                licenseCheck[HOLOMUSEUM_CONTENTCREATOR_LICENSE] === LicenseValidity.Invalid;

            let noLicenses = licenseCheck[HOLOMUSEUM_HOST_LICENSE] === LicenseValidity.Invalid &&
                licenseCheck[REMOTESELLING_HOST_LICENSE] === LicenseValidity.Invalid &&
                licenseCheck[AUGMENTEDSTORE_HOST_LICENSE] === LicenseValidity.Invalid &&
                licenseCheck[HOLOPROTOTYPE_HOST_LICENSE] === LicenseValidity.Invalid &&
                licenseCheck[VERTXCLOUD_TENANTADMIN_LICENSE] === LicenseValidity.Invalid &&
                licenseCheck[AUGMENTEDSTORE_ADMIN_LICENSE] === LicenseValidity.Invalid &&
                licenseCheck[REMOTESELLING_ADMIN_LICENSE] === LicenseValidity.Invalid &&
                licenseCheck[HOLOMUSEUM_ADMIN_LICENSE] === LicenseValidity.Invalid &&
                licenseCheck[HOLOPROTOTYPE_ADMIN_LICENSE] === LicenseValidity.Invalid &&
                licenseCheck[REMOTESELLING_CONTENTCREATOR_LICENSE] === LicenseValidity.Invalid &&
                licenseCheck[AUGMENTEDSTORE_CONTENTCREATOR_LICENSE] === LicenseValidity.Invalid &&
                licenseCheck[HOLOMUSEUM_CONTENTCREATOR_LICENSE] === LicenseValidity.Invalid &&
                licenseCheck[HOLOPROTOTYPE_CONTENTCREATOR_LICENSE] === LicenseValidity.Invalid &&
                licenseCheck[HEVOCOLLABORATION_HOST_LICENSE] === LicenseValidity.Invalid &&
                licenseCheck[HEVOCOLLABORATION_CONTENTCREATOR_LICENSE] === LicenseValidity.Invalid &&
                licenseCheck[HEVOCOLLABORATION_ADMIN_LICENSE] === LicenseValidity.Invalid;



            if (isAdminOnly) {
                await Swal.fire({
                    title: 'Do you want to switch to the administration portal?',
                    showCancelButton: true,
                    showConfirmButton: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    cancelButtonText: "Sign out",
                    cancelButtonColor: "red",
                    heightAuto: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.href = "/admin";
                    }
                    else {
                        this.userManager.signoutRedirect({
                            id_token_hint: this.user && this.user.id_token
                        });
                    }
                });
            } else if (noLicenses) {
                let contactAdmin = new ContactAdmin(this);
                contactAdmin.createContactAdmin();
            } else {
                this._resourceExplorer = new ResourceExplorer();
                this._resourceExplorer.createExplorer();
            }
            
            //catalogue tag management button
            await this.setupCatalogButtons();

            navEl.hidden = false;
            this.sidebar.hidden = false;
        }

    }

   async _onClickItemSidebar(e: Event, button: IButton) {

       if(button.type === ButtonType.TagCatalog || button.type === ButtonType.QRCatalog){
           this.setTitle(button);
                       
            await this._openCatalog(button);
            return;
        }

        if(button.type === ButtonType.Redirect){
            const data = button.data as IRedirectData;

            if(!data.url){
                return;
            }

            if(data.blank){
                window.open(data.url, "_blank").focus();
            }
            else{
                location.href = data.url;
            }

            return;
        }

        this.setTitle(button);
        
        if(button.type === ButtonType.Resources && this.viewSelect !== ViewType.Resources) {
            this.viewSelect = ViewType.Resources;
        }

        if(button.type === ButtonType.Avatar && this.viewSelect !== ViewType.Avatar){
            this.viewSelect = ViewType.Avatar;
            if(!this._avatarComponent){
                this._avatarComponent = new Avatar(this.user.access_token, this.user.profile.sub);
            }
        }
    }
    
    // private _resetAvatar(){
    //     if(!this._avatarComponent){
    //         return;
    //     }

    //     this._avatarComponent.destroy();
    //     this._avatarComponent = null;
    // }

    private _resetCatalog(){
        if(!this._openedCatalog){
            return;
        }

        this._openedCatalog.destroy();
        this._openedCatalog = null;
    }

    private async _openCatalog(button: IButton){
        const data = button.data as ICatalogData;
            
        if(!data || (!data.appTag && !data.contentTag)){
            return;
        }            
        
        Swal.fire({
            icon: "info",
            title: "Opening catalog...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            heightAuto: false
        });
        
        this.viewSelect = ViewType.Catalog;
        const catalog: VERTEXResource = await ResourceUtils.getOrCreateCatalog(data.appTag, data.contentTag);
        const hevoResourceType = ResourceUtils.getHevolusResourceType(catalog);

        if(hevoResourceType !== HevolusResourceType.Catalog){
            Swal.close();
            return;
        }

        this._openedCatalog = this.factoryCatalog(catalog, button.type);
        this._openedCatalog.onLoaded = (items: ICatalogItem[]) => Swal.close();
    }

    private factoryCatalog(catalog: VERTEXResource, buttonType: ButtonType){
        if(buttonType === ButtonType.QRCatalog){
            return new QRCatalog(catalog);
        }

        if(buttonType === ButtonType.TagCatalog){
            return new TagCatalog(catalog);
        }

        throw new Error('Catalog not supported!');
    }

    setTitle(button:IButton){
        const title = (button.type === ButtonType.QRCatalog || button.type === ButtonType.TagCatalog) ? `${button.text} Catalog` : button.text;

        document.querySelector<HTMLElement>('#title-resource').innerText = title;
    }
    
    async setupCatalogButtons() {
        let licenseCheck = await LicenseUtils.validateLicenses(
                                    REMOTESELLING_CONTENTCREATOR_LICENSE,
                                    HOLOMUSEUM_CONTENTCREATOR_LICENSE,
                                    HOLOPROTOTYPE_CONTENTCREATOR_LICENSE);


        const isRemoteSellingCreator =  licenseCheck[REMOTESELLING_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid;
        const isHoloMuseumCreator =     licenseCheck[HOLOMUSEUM_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid;
        const isHoloPrototypeCreator =  licenseCheck[HOLOPROTOTYPE_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid;

        const buttons: IButton[] = [];

        if (isRemoteSellingCreator) {
            buttons.push({
                id: 'remote-selling',
                text: 'Remote Selling',
                icon: 'sell',
                type: ButtonType.TagCatalog,
                data:{
                    appTag: APP_REMOTESELLING_TAG,
                    contentTag: CONTENT_TAG_TAG
                }
            });
        }

        if (isHoloMuseumCreator) {
            buttons.push({
                id: 'holo-museum',
                text: 'Holo Museum',
                icon: 'sell',
                type: ButtonType.TagCatalog,
                data:{
                    appTag: APP_HOLOMUSEUM_TAG,
                    contentTag: CONTENT_TAG_TAG
                }
            });
        }
        
        if (isHoloPrototypeCreator) {
            buttons.push({
                id: 'holo-prototype',
                text: 'Holo Prototype',
                icon: 'sell',
                type: ButtonType.TagCatalog,
                data:{
                    appTag: APP_HOLOPROTOTYPE_TAG,
                    contentTag: CONTENT_TAG_TAG
                }
            });

            buttons.push({
                id: 'qr-catalogs',
                text: 'QR',
                icon: 'qr_code',
                type: ButtonType.QRCatalog,
                data:{
                    appTag: undefined,
                    contentTag: CONTENT_QR_TAG
                }
            });
        }

        if(buttons.length > 0){
            this.sidebar.add({
                id: 'catalogs',
                text: 'Catalogs',
                icon: 'library_books',
                type: ButtonType.Group,
                child: [...buttons],
                order: 2
            });
        }
    }

    async setupLoginButtons() {
        const signInButton = document.getElementById("sign_in");
        if (signInButton) {
            if (this.user || (this.user = await this.userManager.getUser())) {
                signInButton.classList.remove("hidden");
                signInButton.innerHTML = "Sign Out";
                signInButton.addEventListener("click", () => {
                    this.userManager.signoutRedirect({
                        id_token_hint: this.user && this.user.id_token
                    })
                });
            } else {
                signInButton.addEventListener("click", () => {
                    this.userManager.signinRedirect({
                        "prompt": "login" // always check account when you click sign in 
                    })
                });
            }
        }
    }

    async setupBigLoginButton() {
        const bigSignInButton = document.getElementById("big-sign-in");
        if (bigSignInButton) {
            if (this.user || (this.user = await this.userManager.getUser())) {
                bigSignInButton.innerHTML = "Sign Out";
                bigSignInButton.addEventListener("click", () => {
                    this.userManager.signoutRedirect({
                        id_token_hint: this.user && this.user.id_token
                    })
                });
            } else {
                bigSignInButton.addEventListener("click", () => {
                    this.userManager.signinRedirect({
                        "prompt": "login" // always check account when you click sign in 
                    })
                });
            }
        }
    }

    async HideLandingPage() {
        try {
            document.querySelector(".consoleContainer").classList.remove("hidden");
        } catch { }
        const renderCanvas = document.querySelector("#RenderCanvas") as HTMLCanvasElement;
        document.querySelector("#editor-container").classList.remove("hidden");
        renderCanvas.classList.remove("hidden");
        renderCanvas.height = window.innerHeight;
        renderCanvas.width = window.innerWidth;

        document.querySelector(".welcomeContainer").classList.add("hidden");;
        document.getElementById("explorer-container").hidden = true;
        document.querySelector("nav").classList.remove("hidden");
    }

    public watchSession(onExpired: () => void) {
        let self = this;
        setInterval(async () => {
            console.log("[VERTX:Auth] Checking session state...")
            let isActive = await self.checkTokenActive.call(self);
            if (!isActive) {
                console.warn("[VERTX:Auth] Session expired, invoking expired callback");
                onExpired();
            }
        }, 10 * 1000 /* 10 seconds */);
    }

    async checkTokenActive() {
        let user = await this.userManager.getUser();
        if (!user || user.expired || user.expires_in < 300)
            return false;
        // todo: make a test request to see if the token is still valid
        return true;
    }


}
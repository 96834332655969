import ServiceBase from "../service-base";
import { AvatarStyles } from "../service.interfaces";

export class AvatarApi extends ServiceBase {

    constructor(token: string, baseUrl: string) {
        super(token, `${baseUrl}/core/avatars/`);
    }

    async getStyles(): Promise<string[]> {
        const response = await this.makeRequestExtended('styles');        

        if(response.ok){
            return await response.json() as string[];
        }

        if(response.status === 404){
            return [];
        }

        throw new Error(response.statusText);
    }

    async getByStyle(style:AvatarStyles = AvatarStyles.Realistic, fallback:boolean = false): Promise<string> {
        const response = await this.makeRequestExtended(`style/${style}?fallback=${fallback}`);        

        if(response.ok){
            return await response.json() as string;
        }
        else{
            console.log(`Failed to get avatar pub res id. ${response.statusText}`);
            return "";
        }
    }

    async createStyle(style:AvatarStyles, fallback:boolean = false): Promise<string> {

        const response = await this.makeRequestExtended(`style/${style}?fallback=${fallback}`, 'POST');        

        if(response.ok){
            return await response.json() as string;
        }

        if(response.status === 404){
            return "";
        }

        throw new Error(response.statusText);
    }

    async publish(id:string, style:AvatarStyles){
        const response = await this.makeRequestExtended(`style/${style}/${id}?publish=true`, 'PUT');        

        if(response.ok){
            return;
        }

        throw new Error(response.statusText);
    }
}
import QRCode from "qrcode";
import { IQRItem, IQROptions, QRCODE_HEIGHT, QRCODE_WIDHT, QR_HOST, QR_PLATFORM, QR_SERVICE } from "./qr-interfaces";

export class QRUtils {
    static existItem(qrs: IQRItem[], item: IQRItem) {
        if (typeof (item) === 'string') {
            return qrs.findIndex((q) => (q.id === item)) !== -1;
        }

        return qrs.findIndex((q) => q.id === item.id || q.name === item.name) !== -1;
    }

    static existItemById = (qrs: IQRItem[], id: string) => qrs.findIndex((q) => (q.id === id)) !== -1;

    static generateUrl(item: IQRItem) {
        if (!item.id) {
            return null;
        }

        return `${QR_PLATFORM}://${QR_HOST}/${QR_SERVICE}/${item.id}`;
    }

    static drawQRCode(canvas: HTMLCanvasElement, item: IQRItem, options:IQROptions) {
        const url = QRUtils.generateUrl(item);

        if (!url) {
            return;
        }

        QRCode.toCanvas(canvas, url, options, (error) => {
            if (error) {
                console.error(error);
                return;
            }
        })
    }

    static dataURLtoFile(dataurl, filename) { 
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], filename, {type:mime});
    }

    static downloadQRCode(item: IQRItem, options:IQROptions, callback: (url:string, urlBase64:string) => void){
        const url = QRUtils.generateUrl(item);

        if (!url) {
            return;
        }

        QRCode.toDataURL(url, options, (err, urlBase64) =>{
            if (err) throw err;

            if(!callback){
                return;
            }

            const file = this.dataURLtoFile(urlBase64, `qrcode_${item.name}.png`);
            const fileUrl = URL.createObjectURL(file);
            
            callback(fileUrl, urlBase64);
        });
    }

    static loadTemplate<T extends HTMLElement>(selector: string) {
        let template = document.querySelector(selector) as HTMLTemplateElement;
        let contents = template.content.cloneNode(true) as DocumentFragment;
        return contents.firstElementChild as T;
    }
}
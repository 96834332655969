import Swal, { SweetAlertOptions } from "sweetalert2";
import { ObjectModel, OptimizationFilter, Project } from "../api/amaz3d/amaz3d-types";
import { HevolusResourceType, ResourceUtils, VERTEXResource } from "./resource-utilities";
import { LicenseUtils, LicenseValidity } from "./license-utilities";
import { Amaz3dApi } from "../api/amaz3d/amaz3d-api";
import { GltfStructure, GltfUtils } from "./gltf-utilities";
import { Utils } from "./utils";
import { difference, isEmpty } from "lodash";
import { MODEL_INFO_FILENAME, MODEL_3D_VALID_EXTENSIONS, HEVOLUS_HVERSE_3DCONVERTER_LICENSE, HEVOLUS_HVERSE_3DOPTIMIZER_LICENSE, MAX_ALLOWED_MODEL_FILES, STEP2GLTF_CONVERTER_LICENSE, ADVANCED_3D_CONVERTER_EXTENSIONS, ALLOWED_LIGHTMAPS_TYPES, ALLOWED_SKYBOX_TYPES, DEFAULT_3D_CONVERTER_EXTENSIONS, MEDIA_VALID_EXTENSIONS, MODEL_VALID_EXTENSIONS, PLATFORM_NATIVE_MODEL_BUFFER_TYPES, PLATFORM_NATIVE_MODEL_TYPES, SKYBOX_MAX_SIZE_BYTES, SUPPORTED_INTROSPLASH_TYPES, VERTX_STP_CONVERTER_EXTENSIONS, RESOURCE_THUMB_FILENAME, HDR_TEXTURE_EXTENSION, ALLOWED_PDF_TYPES, RESOURCE_API_URI, B_TO_MB_FACTOR, MAXIMUM_VIDEO_SIZE_IN_BYTES, SUPPORTED_AUDIO_FORMATS, SUPPORTED_VIDEO_FORMATS, CUSTOM_ENVIRONMENT_TEXTURE_EXTENSION, MAXIMUM_SKYBOX_LOAD_TIME_IN_MS, ORIGINAL_OPTIMIZATION_NAME, ADAPTA_BLOB_URL_SIGNATURE_PARAM as ADAPTA_BLOB_URL_SIGNATURE_EXPIRATION_PARAM, MeasurementUnit, ModelInfo } from "./constants";

export enum UploadingAssetType {
    SpaceSkybox,
    SpaceLightmap,
    SpaceSplash,
    ModelAttached,
    Media,
    Generic,
    Thumb
}

export interface UploadResult {
    successfulUploads: string[],
    failedUploads: string[],
    needConversion: boolean,
    needOptimization: boolean
}


export class UploadUtils {
    



    






    
    static async deleteModelInfoJson(resourceId?: string){
        const resp = await ResourceUtils.deleteAssetFromResource(resourceId ?? Vertex.Globals.spaceId, MODEL_INFO_FILENAME);

        if(resp.ok){
            UploadUtils._modelInfo = null;
        }
        
        return resp.ok;
    }

    
    static async postModelInfoJson(modelInfoJson: ModelInfo, resourceId?: string){
        UploadUtils._modelInfo = modelInfoJson;
        const resp = await ResourceUtils.postAssetToResource(MODEL_INFO_FILENAME, resourceId ?? Vertex.Globals.spaceId, JSON.stringify(modelInfoJson, null, 2));

        return resp.ok;
    }
    
    private static _modelInfo: ModelInfo;

    public static get modelInfo () : Promise<ModelInfo>{
        let modelInfo = async () => {
            if(!this._modelInfo){
                this._modelInfo = await this.getModelInfoJson();
            }            
            
            return this._modelInfo;
        }

        return modelInfo();
    }



    private static async getModelInfoJson(resourceId?: string): Promise<ModelInfo>{
        const infoResponse = await ResourceUtils.getAssetFromResource(resourceId ?? Vertex.Globals.spaceId, MODEL_INFO_FILENAME);

        if(infoResponse.ok){
            return await infoResponse.json() as ModelInfo;
        }
    }

    static async hasModelFile(resource: VERTEXResource): Promise<boolean> {
        if(resource?.resourceKeys?.length){
            for(let i = 0; i < resource.resourceKeys.length; i++){
                for(let j = 0; j < MODEL_3D_VALID_EXTENSIONS.length; j++){
                    if(resource.resourceKeys[i].endsWith(MODEL_3D_VALID_EXTENSIONS[j])){
                        return true;
                    }
                }
            }
        }

        const modelInfo = await UploadUtils.modelInfo;

        if(modelInfo?.objectModel){
            return true;
        }

        return false;
    }

    //utility to check if an array of files has ${max} occurrences of ${base} file extensions
    static hasMaxOccurrences(files: File[], base: string[], max: number): boolean {
        let result = true;
        let extensions = [];

        for (let i = 0; i < files.length; i++) {
            let file = files[i].name;

            extensions.push(file.substring(file.lastIndexOf('.') + 1, file.length).toLowerCase());
        }

        extensions = extensions.filter(ext => base.includes(ext));

        if (extensions.length > max) {
            result = false;
        }

        return result;
    }


    public static async uploadModelAndAdditionalsFromVertexResource(modelFileName: string): Promise<ObjectModel> {
        const resourceId = Vertex.Globals.spaceId;
        const loginOutput = await Amaz3dApi.login(Amaz3dApi.LoginInput);
        
        if(loginOutput == null || loginOutput.token == null){
            return null;
        }

        const modelResponse = await ResourceUtils.getAssetFromResource(resourceId, modelFileName);
        
        if (!modelResponse.ok) { return }
                    
        const modelfBlob = await modelResponse.blob();

        const mainFileExtension = Utils.getFileExtension(modelFileName) 

        const objectModel = await Amaz3dApi.uploadProjectFile({ file: modelfBlob }, `mesh.${mainFileExtension}`, loginOutput.token);

        if (objectModel == null) { return }

        const objectModelId = objectModel.id;

        if(mainFileExtension == PLATFORM_NATIVE_MODEL_TYPES[0]){

            const gltf = await modelfBlob.text().then((text) => JSON.parse(text));

            const isGltfEmbedded = GltfUtils.isGltfEmbedded(gltf);

            if (!isGltfEmbedded) {

                objectModel.additionals = [];

                const gltfBinResponse = await ResourceUtils.getAssetFromResource(resourceId, gltf.buffers[0].uri);
                if (!gltfBinResponse.ok) { return }
                const gltfBinBlob = await gltfBinResponse.blob();
                const gltfBinObjectModel = await Amaz3dApi.uploadProjectFile({ file: gltfBinBlob, relatedTo: objectModelId }, gltf.buffers[0].uri, loginOutput.token);
                if (gltfBinObjectModel == null) { return }
        
                objectModel.additionals.push(gltfBinObjectModel);

                for (let i = 0; i < gltf.images?.length; i++) {
                    const gltfImageResponse = await ResourceUtils.getAssetFromResource(resourceId, gltf.images[i].uri);
                    if (!gltfImageResponse.ok) { return }
        
                    const gltfImageBlob = await gltfImageResponse.blob();
                    const gltfImageObjectModel = await Amaz3dApi.uploadProjectFile({ file: gltfImageBlob, relatedTo: objectModelId }, gltf.images[i].uri, loginOutput.token);
                    if (gltfBinObjectModel == null) { return }

                    objectModel.additionals.push(gltfImageObjectModel);
                }
            }
        }

        return objectModel;
    }


    static async uploadFiles(files: File[], id?: string, type: UploadingAssetType = UploadingAssetType.Generic): Promise<UploadResult> {
        Swal.fire({
            icon: "info",
            title: "Preparing upload...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            heightAuto: false
        });

        Swal.showLoading();

        id = id || Vertex.Globals.spaceId;
        const resource = await ResourceUtils.getResourceAsync(id, false);

        if(resource == null){
            await Swal.fire({
                icon: "error",
                title: "Upload failed :(",
                html: "Try again later",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: true,
                heightAuto: false
            });

            return;
        }
        
        const hevoResType = ResourceUtils.getHevolusResourceType(resource);
        const succesfulUploads: string[] = [];
        const failedUploads: string[] = [];
        let needConversion = false;
        let needOptimization = false;

        if (hevoResType != HevolusResourceType.None) {
            const validFileIndexes: number[] = [];

            let alertMsg = "";
            let okMsg = "";

            let isUploading3Dmodel = false;
            let isUploadingMax3DModelFiles = false;

            let need3DConversion = false;
            let needMediaConverion = false;
            let needPdfConversion = false;

            let has3DConverterLicense = false;
            let has3DOptimizerLicense = false;
            let hasStpConverterLicense = false;

            let suggest3DConverterLicense = false;

            let is3dFile = false;
            let isGltfEmbedded = false;

            let amaz3dUploadFailed = false;
            const missing3dAdditionalFileNames: string[] = [];
            const missingGltfFilesFromResource: string[] = [];

            //check if the user is uploading 1 and only 1 model file
            if (hevoResType === HevolusResourceType.Model || hevoResType === HevolusResourceType.Base) {
                const licenses = await LicenseUtils.validateLicenses(HEVOLUS_HVERSE_3DCONVERTER_LICENSE, HEVOLUS_HVERSE_3DOPTIMIZER_LICENSE, STEP2GLTF_CONVERTER_LICENSE);
                has3DConverterLicense = licenses[HEVOLUS_HVERSE_3DCONVERTER_LICENSE] === LicenseValidity.Valid;
                has3DOptimizerLicense = licenses[HEVOLUS_HVERSE_3DOPTIMIZER_LICENSE] === LicenseValidity.Valid;
                hasStpConverterLicense = licenses[STEP2GLTF_CONVERTER_LICENSE] === LicenseValidity.Valid;

                isUploadingMax3DModelFiles = UploadUtils.hasMaxOccurrences(files, MODEL_3D_VALID_EXTENSIONS, MAX_ALLOWED_MODEL_FILES);
            
                //this is useful here to have info when uploading a non model file
                if(has3DConverterLicense || has3DOptimizerLicense){
                    missing3dAdditionalFileNames.push(...await UploadUtils.getMissing3dAdditionalFiles());
                }

                const modelFileIndex = files.findIndex(f => MODEL_3D_VALID_EXTENSIONS.includes(Utils.getFileExtension(f.name, true)));

                if(modelFileIndex !== -1){
                    files[modelFileIndex] = files.splice(0, 1, files[modelFileIndex])[0];
                }
            }

            let has3d;

            if (hevoResType === HevolusResourceType.Model || hevoResType === HevolusResourceType.Base){
                has3d = await UploadUtils.hasModelFile(resource);
            }

            for (let i = 0; i < files.length; i++) {
                let isValid = true;
                let file = files[i].name;
                let extension = Utils.getFileExtension(file);
                is3dFile = MODEL_3D_VALID_EXTENSIONS.includes(extension);
                let isMediaSizeOverThreshold = false; 

                if (hevoResType === HevolusResourceType.Media) {
                    isValid = MEDIA_VALID_EXTENSIONS.includes(extension);
                } else if (hevoResType === HevolusResourceType.Space) {
                    if (type === UploadingAssetType.SpaceSkybox) {
                        isValid = ALLOWED_SKYBOX_TYPES.includes(extension) && files[i].size <= SKYBOX_MAX_SIZE_BYTES;
                    }
                    else if (type === UploadingAssetType.SpaceLightmap) {
                        isValid = ALLOWED_LIGHTMAPS_TYPES.includes(extension);
                    }
                    else if (type === UploadingAssetType.SpaceSplash) {
                        isValid = SUPPORTED_INTROSPLASH_TYPES.includes(extension);
                    }
                } else if (hevoResType === HevolusResourceType.Model || hevoResType === HevolusResourceType.Base) {
                    // let hasFile = resource.resourceKeys.includes(file);
                    let isValidExtension = false;

                    if (hevoResType === HevolusResourceType.Model) {
                        if(has3DConverterLicense){
                            isValidExtension = MODEL_VALID_EXTENSIONS.includes(extension);
                        }
                        else if(hasStpConverterLicense){
                            if(is3dFile && difference(ADVANCED_3D_CONVERTER_EXTENSIONS, VERTX_STP_CONVERTER_EXTENSIONS.concat(DEFAULT_3D_CONVERTER_EXTENSIONS)).includes(extension)){
                                suggest3DConverterLicense = true;
                            }

                            isValidExtension = PLATFORM_NATIVE_MODEL_TYPES.concat(PLATFORM_NATIVE_MODEL_BUFFER_TYPES).concat(DEFAULT_3D_CONVERTER_EXTENSIONS).concat(VERTX_STP_CONVERTER_EXTENSIONS).concat(MEDIA_VALID_EXTENSIONS).includes(extension);
                        }
                        else{
                            if(is3dFile && difference(ADVANCED_3D_CONVERTER_EXTENSIONS, DEFAULT_3D_CONVERTER_EXTENSIONS).includes(extension)){
                                suggest3DConverterLicense = true;
                            }

                            isValidExtension = PLATFORM_NATIVE_MODEL_TYPES.concat(PLATFORM_NATIVE_MODEL_BUFFER_TYPES).concat(DEFAULT_3D_CONVERTER_EXTENSIONS).concat(MEDIA_VALID_EXTENSIONS).includes(extension);
                        }
                    }
                    else if (hevoResType === HevolusResourceType.Base) {
                        if(has3DConverterLicense){
                            isValidExtension = MODEL_VALID_EXTENSIONS.includes(extension);
                        }
                        else if(hasStpConverterLicense){
                            if(is3dFile && difference(ADVANCED_3D_CONVERTER_EXTENSIONS, VERTX_STP_CONVERTER_EXTENSIONS.concat(DEFAULT_3D_CONVERTER_EXTENSIONS)).includes(extension)){
                                suggest3DConverterLicense = true;
                            }

                            isValidExtension = PLATFORM_NATIVE_MODEL_TYPES.concat(PLATFORM_NATIVE_MODEL_BUFFER_TYPES).concat(DEFAULT_3D_CONVERTER_EXTENSIONS).concat(VERTX_STP_CONVERTER_EXTENSIONS).includes(extension);
                        }
                        else{
                            if(is3dFile && difference(ADVANCED_3D_CONVERTER_EXTENSIONS, DEFAULT_3D_CONVERTER_EXTENSIONS).includes(extension)){
                                suggest3DConverterLicense = true;
                            }

                            isValidExtension = PLATFORM_NATIVE_MODEL_TYPES.concat(PLATFORM_NATIVE_MODEL_BUFFER_TYPES).concat(DEFAULT_3D_CONVERTER_EXTENSIONS).includes(extension);
                        }

                        //Since Media files are not allowed into Base resources, specifically allow thumb loading
                        if(file === RESOURCE_THUMB_FILENAME){
                            isValidExtension = true;
                        }
                    }
                    
                    if(file.endsWith(PLATFORM_NATIVE_MODEL_BUFFER_TYPES[0])){
                        if(resource.resourceKeys.includes(file)){
                            isValid = false;
                        }
                    }

                    // file is valid if it is a valid extension, if it is not a 3D file,
                    // if the resource does not have a 3D file,
                    // or if the resource already have a 3D file and the file is a 3D file with same name
                    if (isUploadingMax3DModelFiles) {
                        if (is3dFile && has3d) {
                            isValid = false;
                        }

                        //check if the user is uploading a valid 3D model
                        if(is3dFile){
                            isUploading3Dmodel = true;
                        }
                    }
                    else {
                        isValid = false;
                    }

                    if(isValid && is3dFile){
                        if(PLATFORM_NATIVE_MODEL_TYPES.includes(extension)){
                            const gltf = JSON.parse(await files[i].text()) as GltfStructure;
                            isGltfEmbedded = GltfUtils.isGltfEmbedded(gltf);
                            
                            if(isGltfEmbedded){
                                need3DConversion = true;
                                
                                if(!has3DConverterLicense){
                                    isValid = false;
                                }
                            }
                        }
                        else{
                            need3DConversion = true
                        }
                    }
                    
                    if(!isValidExtension){
                        isValid = false;
                    }
                }

                //check if audio/video files size is below the threshold
                if(isValid && (hevoResType === HevolusResourceType.Model || hevoResType === HevolusResourceType.Media)){                    
                    if ((SUPPORTED_VIDEO_FORMATS.includes(extension) || SUPPORTED_AUDIO_FORMATS.includes(extension)) && files[i].size >= MAXIMUM_VIDEO_SIZE_IN_BYTES) {
                        isMediaSizeOverThreshold = true;
                        isValid = false;
                    }
                }

                if (isValid) {
                    validFileIndexes.push(i);
                    okMsg += `<div title="${file}" class="upload-list-item">${file}</div>`
                }
                else {
                    if(isMediaSizeOverThreshold || (is3dFile && need3DConversion && suggest3DConverterLicense)){
                        let infoTooltipContainer = document.createElement("div");
                        infoTooltipContainer.classList.add("converter-tooltip-container");

                        let infoTooltip = document.createElement("div");
                        infoTooltip.classList.add("optimize-tooltip");
            
                        let tooltipText = document.createElement("div");
                        tooltipText.classList.add("optimize-tooltip-text")
                        
                        if(isMediaSizeOverThreshold){
                            tooltipText.textContent = `Media file is too big, maximum allowed size is ${(MAXIMUM_VIDEO_SIZE_IN_BYTES / B_TO_MB_FACTOR).toFixed(2)} MB`;
                        }

                        if(is3dFile && need3DConversion && suggest3DConverterLicense){
                            tooltipText.textContent = "Upgrade to 3D Converter Pro to upload and convert more formats!";
                        }

                        tooltipText = infoTooltip.appendChild(tooltipText);
                        infoTooltip = infoTooltipContainer.appendChild(infoTooltip);

                        alertMsg += `<div class="upload-unconvertible-list-item"><div title="${file}" style="width:auto" class="upload-list-item">${file}</div>${infoTooltipContainer.outerHTML}</div>`;
                    }
                    else{
                        alertMsg += `<div title="${file}" class="upload-list-item">${file}</div>`;
                    }
                }
            }

            const footer =
                type === UploadingAssetType.Generic ? `Note: you can upload one 3D model only.<br>Here follow valid file type examples: GLTF + BIN, JPG, PNG, TXT, MP3, MP4...` :
                type === UploadingAssetType.SpaceSkybox ? `Note: you can upload a skybox as HDR file.` :
                type === UploadingAssetType.SpaceLightmap ? `Note: you can upload a lightmap as HDR, PNG or JPG file.` :
                type === UploadingAssetType.SpaceSplash ? `Note: you can upload a splash as Video or Image file.` :
                type === UploadingAssetType.ModelAttached ? `Note: you can upload attachments in the following formats: JPG, PNG, TXT, PDF or any valid Audio and Video format.` :
                type === UploadingAssetType.Media ? `Note: you can upload Media files in the following formats: JPG, PNG, TXT, PDF or any valid Audio and Video format.` : "";

            let optimizePromptContainer: HTMLDivElement;
            let wantOptimize = false;

            if(isUploading3Dmodel && okMsg.length > 0){
                let optimizeCheckbox: HTMLInputElement;
                optimizeCheckbox = document.createElement("input");
                optimizeCheckbox.id = "optimize-checkbox";
                optimizeCheckbox.setAttribute("type", "checkbox");
                optimizeCheckbox.classList.add("optimize-checkbox");
                optimizeCheckbox.checked = false;

                let optimizeLabel = document.createElement("label");
                optimizeLabel.setAttribute("for", optimizeCheckbox.id);
                optimizeLabel.innerHTML = "<b>Optimize 3D Model<b>";
                optimizeLabel.classList.add("optimize-tooltip-label");

                let optimizeInfoTooltipContainer = document.createElement("div");
                optimizeInfoTooltipContainer.classList.add("optimize-tooltip");

                let optimizeTooltipText = document.createElement("div");
                optimizeTooltipText.classList.add("optimize-tooltip-text")
                optimizeTooltipText.textContent = "You can also optimize later in the Model Editor";

                optimizeTooltipText = optimizeInfoTooltipContainer.appendChild(optimizeTooltipText);

                optimizePromptContainer = document.createElement("div");
                optimizePromptContainer.classList.add("optimize-prompt-container");
                optimizeCheckbox = optimizePromptContainer.appendChild(optimizeCheckbox);
                optimizeLabel = optimizePromptContainer.appendChild(optimizeLabel);
                optimizeInfoTooltipContainer = optimizePromptContainer.appendChild(optimizeInfoTooltipContainer);

                if(!has3DOptimizerLicense){
                    optimizeCheckbox.disabled = true;
                    optimizeLabel.style.color = "#B3B3B3";
                    optimizeTooltipText.textContent = "Buy 3D Optimizer License to unlock this feature!";
                }
            }

            let swalDidOpen = () => {
                const checkbox = Swal.getHtmlContainer().querySelector("input");
                checkbox?.addEventListener("change", () => {
                    wantOptimize = checkbox.checked;
                });
            }

            let swalHtml = `${okMsg.length > 0 ? `<b>Following files will be uploaded:</b><br>${okMsg}<br>` : ``}${alertMsg.length > 0 ? `<b>Following files will NOT be uploaded:</b><br>${alertMsg}<br>` : ``}`;
            
            if(isUploading3Dmodel && optimizePromptContainer){
                swalHtml += `${optimizePromptContainer.outerHTML}`;
            }

            let swalOptions: SweetAlertOptions<any, any> = {
                title: "Uploading files:<br>",
                html: swalHtml,
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: okMsg.length > 0 ? true : false,
                confirmButtonText: "Upload",
                showCancelButton: true,
                cancelButtonText: okMsg.length > 0 ? "Cancel" : "Close",
                icon: "question",
                footer: `${footer}`,
                didOpen: swalDidOpen
            }

            await Swal.fire(swalOptions).then(async (result) => {
                if (result.isConfirmed) {
                    let uploadedAmount = 0;

                    Swal.fire({
                        icon: 'info',
                        title: 'Uploading...',
                        html: `Please wait while validating and uploading files<br><br>${uploadedAmount} / ${validFileIndexes.length}`,
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        showCancelButton: false,
                        showConfirmButton: false,
                        heightAuto: false
                    });

                    Swal.showLoading();

                    let alreadyUploadedFiles = [];

                    try {
                        let vertxRequests: Promise<Response>[] = [];
                        let amaz3dFiles: File[] = [];
                        let okFiles = ``;
                        let failedFiles = ``;
                        
                        for (let i = 0; i < validFileIndexes.length; i++) {
                            let file = files[validFileIndexes[i]] = Utils.toLowerCaseExtension(files[validFileIndexes[i]]);

                            if(type === UploadingAssetType.Thumb){
                                file = files[validFileIndexes[i]] = Utils.toThumbFilename(files[validFileIndexes[i]]);
                            }

                            const fileExtension = Utils.getFileExtension(file.name);

                            if (PLATFORM_NATIVE_MODEL_TYPES.includes(fileExtension)) {
                                file = await GltfUtils.cleanGltf(file);
                                files[validFileIndexes[i]] = file;
                            }

                            if (SUPPORTED_VIDEO_FORMATS.includes(fileExtension) || SUPPORTED_AUDIO_FORMATS.includes(fileExtension)) {
                                file = new File([file], Utils.cleanMediaName(file.name), { type: file.type, lastModified: file.lastModified });
                                files[validFileIndexes[i]] = file;
                            }

                            let request: Promise<Response>;
                            let useExtended3DService = ( need3DConversion && has3DConverterLicense ) || ( wantOptimize && has3DOptimizerLicense );

                            //the first file is always the 3d one (if any), so we can check now all its related files and drive it to adapta or vertx based on licenses etc.
                            if(MODEL_3D_VALID_EXTENSIONS.includes(fileExtension) && useExtended3DService){
                                //upload su adapta
                                amaz3dFiles.push(file);

                                if(PLATFORM_NATIVE_MODEL_TYPES.includes(fileExtension)){
                                    const gltf = JSON.parse(await files[i].text()) as GltfStructure;
                                    const isGltfEmbedded = GltfUtils.isGltfEmbedded(gltf);

                                    if(!isGltfEmbedded){
                                        //add all additionals files to amaz3d files array
                                        const bufferUris = gltf.buffers?.map(b => b.uri).filter(u => !u?.startsWith("data:"));
                                        const imageUris = gltf.images?.map(i => i.uri).filter(u => !u?.startsWith("data:"));

                                        if(bufferUris?.length){
                                            amaz3dFiles.push(...files.filter(f => bufferUris.includes(f.name)));
                                        }
                                        
                                        if(imageUris?.length){
                                            amaz3dFiles.push(...files.filter(f => imageUris.includes(f.name)));
                                        }

                                        //check which additional file is already on vertx
                                        alreadyUploadedFiles = resource.resourceKeys.filter(file => bufferUris?.includes(file) || imageUris?.includes(file));
                                        
                                        //for each of them, check if it is included in this upload, so in the amaz3d files array, otherwise download it and push it into amaz3d files array (this means it was uploaded previously without the gltf file)
                                        for (const filename of alreadyUploadedFiles) {
                                            if(!amaz3dFiles.some(amaz3dFile => amaz3dFile.name == filename)){
                                                const result = await ResourceUtils.getAssetFromResource(id, filename);

                                                if(result.ok){
                                                    const alreadyUploadedFile = new File([await result.blob()], filename);
                                                    files.push(alreadyUploadedFile);

                                                    validFileIndexes.push(files.length - 1);
                                                    amaz3dFiles.push(alreadyUploadedFile);

                                                    //commentato aggiornamento del totale dei file perchè per l'utente quei file sono già caricati nel sistema
                                                    //let html = Swal.getHtmlContainer().innerHTML.replace(`${uploadedAmount} / ${validFileIndexes.length - 1}`, `${uploadedAmount} / ${validFileIndexes.length}`);
                                                    
                                                    // Swal.update({
                                                    //     html: `${html}`
                                                    // });

                                                    // Swal.showLoading();
                                                }
                                            }
                                        }

                                        //keep track of the missing additional files (tex, bin)
                                        missing3dAdditionalFileNames.push(...await UploadUtils.getMissing3dAdditionalFiles());
                                        missingGltfFilesFromResource.push(...await GltfUtils.getMissingGltfFiles(resource, gltf));

                                    }
                                }
                            }
                            else{
                                //check if it is a file related to a previously uploaded 3d model on Adapta, in case it goes to adapta
                                if (missing3dAdditionalFileNames.includes(file.name) || missingGltfFilesFromResource.includes(file.name)){
                                    if (missing3dAdditionalFileNames.includes(file.name)){
                                        amaz3dFiles.push(file);
                                        missing3dAdditionalFileNames.splice(missing3dAdditionalFileNames.indexOf(file.name), 1);
                                    }

                                    if (missingGltfFilesFromResource.includes(file.name)){
                                        missingGltfFilesFromResource.splice(missingGltfFilesFromResource.indexOf(file.name), 1);
                                    }
                                }
                                else{
                                    //check if it is not a gltf extended related file (in which case it goes to vertx)
                                    if(amaz3dFiles.find(f => f.name == file.name) == null){
                                        request = ResourceUtils.postAssetToResource(file.name, id, file);

                                        request.then((resp) => {
                                            if(Swal.isLoading()){
                                                let html = Swal.getHtmlContainer().innerHTML.replace(`${uploadedAmount} / ${validFileIndexes.length - alreadyUploadedFiles.length}`, `${++uploadedAmount} / ${validFileIndexes.length - alreadyUploadedFiles.length}`);

                                                Swal.update({
                                                    html: `${html}`
                                                });

                                                Swal.showLoading();
                                            }
                                        });
                                        
                                        vertxRequests.push(request);  
                                    }
                                }
                            }
                        }

                        if(amaz3dFiles.length > 0){
                            const loginOutput = await Amaz3dApi.login(Amaz3dApi.LoginInput);

                            if(loginOutput == null || loginOutput.token == null){
                                amaz3dFiles.forEach(f => failedFiles += `<div title="${f.name}" class="upload-list-item">${f.name}</div>`);
                            }
                            else{
                                //check if a mian object already exists, otherwise upload it first
                                const modelInfo = await UploadUtils.modelInfo;

                                let mainObjModel: ObjectModel = null;

                                if(modelInfo?.objectModel){
                                    mainObjModel = modelInfo.objectModel;
                                }
                                else{
                                    const mainFile = amaz3dFiles.find(f => ADVANCED_3D_CONVERTER_EXTENSIONS.includes(Utils.getFileExtension(f.name, true)));
                                    const mainFileExtension = Utils.getFileExtension(mainFile.name, true);

                                    mainObjModel = await Amaz3dApi.uploadProjectFile({ file: mainFile }, `mesh.${mainFileExtension}`, loginOutput.token);

                                    if(!alreadyUploadedFiles.includes(mainFile.name)){
                                        let html = Swal.getHtmlContainer().innerHTML.replace(`${uploadedAmount} / ${validFileIndexes.length - alreadyUploadedFiles.length}`, `${++uploadedAmount} / ${validFileIndexes.length - alreadyUploadedFiles.length}`);

                                        Swal.update({
                                            html: `${html}`
                                        });

                                        Swal.showLoading();
                                    }

                                    if(mainObjModel){
                                        mainObjModel.fileSizeBytes = mainFile.size.toString();
                                        await UploadUtils.postModelInfoJson({ objectModel: mainObjModel, gltf: {}, modelProperties: { name: mainFile.name } }, id);
                                        
                                        okFiles += `<div title="${mainFile.name}" class="upload-list-item">${mainFile.name}</div>`
                                        succesfulUploads.push(mainFile.name);
                                    }
                                }
                                
                                //now if main model is ok, link additional files to it
                                if(mainObjModel){
                                    const additionalsObjectModels: ObjectModel[] = [];
                                    //let's start from 1 if the 3d file is present (because it has already been uploaded on adapta), 0 if the 3d file is in upload right now (it is always the first file)
                                    const startIndex = ADVANCED_3D_CONVERTER_EXTENSIONS.includes(Utils.getFileExtension(amaz3dFiles[0].name, true)) ? 1 : 0;

                                    for(let i = startIndex; i < amaz3dFiles.length; i++){
                                        const file = amaz3dFiles[i];
                                        const objModel = await Amaz3dApi.uploadProjectFile({ file: file, relatedTo: mainObjModel.id }, file.name, loginOutput.token);
                                        
                                        if(!alreadyUploadedFiles.includes(file.name)){
                                            let html = Swal.getHtmlContainer().innerHTML.replace(`${uploadedAmount} / ${validFileIndexes.length - alreadyUploadedFiles.length}`, `${++uploadedAmount} / ${validFileIndexes.length - alreadyUploadedFiles.length}`);

                                            Swal.update({
                                                html: `${html}`
                                            });

                                            Swal.showLoading();
                                        }

                                        if(objModel){
                                            // the file was already uploaded to Adapta, but we missed to track it bcs of an exception on previous upload sessions for the mainObjModel.
                                            // this can happen bcs the model-info.json is updated once (to improve performance) at the end of the upload of all additional files
                                            // we push in the json the additional objectModel already on Adapta
                                            if(isEmpty(objModel)){
                                                additionalsObjectModels.push(mainObjModel.additionals.find((a) => a.name === file.name));
                                            }
                                            else{
                                                additionalsObjectModels.push(objModel);
                                            }
                                            
                                            mainObjModel.fileSizeBytes = (parseInt(mainObjModel.fileSizeBytes) + file.size).toString();
                                            succesfulUploads.push(file.name);

                                            // do not show feedback for files that the users already uplaoded (they do not know about adapta or vertex)
                                            if(!alreadyUploadedFiles.includes(file.name)){
                                                okFiles += `<div title="${file.name}" class="upload-list-item">${file.name}</div>`;
                                            }
                                        }
                                        else{
                                            failedUploads.push(file.name);
                                            
                                            // do not show feedback for files that the users already uplaoded (they do not know about adapta or vertex)
                                            if(!alreadyUploadedFiles.includes(file.name)){
                                                failedFiles += `<div title="${file.name}" class="upload-list-item">${file.name}</div>`;
                                            }
                                        }
                                    }

                                    const modelInfo = await UploadUtils.modelInfo;

                                    if(modelInfo.objectModel.additionals == null){
                                        modelInfo.objectModel.additionals = additionalsObjectModels;
                                    }
                                    else{
                                        modelInfo.objectModel.additionals.push(...additionalsObjectModels);
                                    }

                                    await UploadUtils.postModelInfoJson(modelInfo, id);
                                }
                                else{
                                    amaz3dFiles.forEach(f => {
                                        failedUploads.push(f.name);
                                        failedFiles += `<div title="${f.name}" class="upload-list-item">${f.name}</div>`;
                                    });
                                }
                            }

                            //at least one of the 3D related files was not uploaded. 
                            //We can not proceed either in converting or optimizing
                            if(amaz3dFiles.some(f => failedFiles.includes(`title="${f.name}"`))){
                                amaz3dUploadFailed = true;
                            }
                        }

                        for (let i = 0; i < vertxRequests.length; i++) {
                            const resp = await vertxRequests[i];
                            const resUrl = decodeURI(resp.url);
                            const file = files.find(f => f.name.toLowerCase() == resUrl.substring(resUrl.lastIndexOf(`/`) + 1, resUrl.length).toLowerCase());
                            const fileBaseName = Utils.getFileBaseName(file.name);
                            const fileExtension = Utils.getFileExtension(file.name);

                            if (resp.ok) {
                                let isValid = true;

                                if (SUPPORTED_VIDEO_FORMATS.includes(fileExtension) || SUPPORTED_AUDIO_FORMATS.includes(fileExtension)) {
                                    needMediaConverion = true;
                                }

                                if (ALLOWED_PDF_TYPES.includes(fileExtension)) {
                                    //TODO: evaulate if keep pdf conversion a thing. HevoCollaboration still needs it, but other apps don't.
                                    //needPdfConversion = true;
                                }

                                if (type === UploadingAssetType.SpaceSkybox && ALLOWED_SKYBOX_TYPES.includes(fileExtension)) {
                                    if (fileExtension === HDR_TEXTURE_EXTENSION) {
                                        const url = `${RESOURCE_API_URI}${Vertex.Globals.spaceId}/${file.name}`;
                                        const scene = Vertex.Globals.runtime.scene as BABYLON.Scene;
                                        const engine: any = scene.getEngine();

                                        //BABYLON.CubeTexture.CreateFromPrefilteredData does things in the background after returning
                                        //waiting for a second seems to be the only way to make sure hdrTexture.internalTexture exists
                                        const hdrTexture = new BABYLON.HDRCubeTexture(url, scene, 1024, false, true, false, true);                                     
                                    
                                        isValid = await UploadUtils.validateSkyboxTexture(hdrTexture);

                                        if(isValid) {
                                            const premultipliedAlpha = engine.premultipliedAlpha;
                                            engine.premultipliedAlpha = false;

                                            try{
                                                const buffer: ArrayBuffer = await BABYLON.EnvironmentTextureTools.CreateEnvTextureAsync(hdrTexture);
                                                const res = await ResourceUtils.postAssetToResource(`${fileBaseName}.${CUSTOM_ENVIRONMENT_TEXTURE_EXTENSION}`, Vertex.Globals.spaceId, buffer);
                                                
                                                if (!res.ok) {
                                                    console.error(`Failed to upload .${CUSTOM_ENVIRONMENT_TEXTURE_EXTENSION} texture!`)
                                                }
                                            }
                                            catch(error){
                                                console.error(error);
                                            }

                                            engine.premultipliedAlpha = premultipliedAlpha;
                                        }

                                        hdrTexture.dispose();

                                        if (!isValid) {
                                            const res = await ResourceUtils.deleteAssetFromResource(Vertex.Globals.spaceId, file.name)

                                            console.log(`invalid .${HDR_TEXTURE_EXTENSION}${res.ok ? ' ' : ' not '}deleted`);
                                        }
                                    }
                                }

                                if (isValid) {
                                    succesfulUploads.push(file.name);
                                    okFiles += `<div title="${file.name}" class="upload-list-item">${file.name}</div>`
                                }
                                else {
                                    failedUploads.push(file.name);
                                    failedFiles += `<div title="${file.name}" class="upload-list-item">${file.name}</div>`
                                }
                            }
                            else {
                                failedUploads.push(file.name);
                                failedFiles += `<div title="${file.name}" class="upload-list-item">${file.name}</div>`
                            }
                        }

                        let uploadFeedback = "";

                        if (okFiles.length > 0) {
                            uploadFeedback = `<b>Succesfully uploaded:</b><br>${okFiles}`;
                        }

                        if (failedFiles.length > 0) {
                            uploadFeedback += `<br><b>Upload failed for:</b><br>${failedFiles}`;
                        }

                        // check if everything went good and all needed files are in the right place
                        if (!amaz3dUploadFailed && (isEmpty(missing3dAdditionalFileNames) && isEmpty(missingGltfFilesFromResource))) {
                            if (need3DConversion || needMediaConverion || needPdfConversion){
                                needConversion = true;
                            }
    
                            if(wantOptimize){
                                needOptimization = true;
                            }
                        }

                        await Swal.fire({
                            icon: "info",
                            title: "File Upload Result",
                            html: `${uploadFeedback}`,
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            showConfirmButton: true,
                            confirmButtonText: "OK",
                            showCancelButton: false,
                            heightAuto: false
                        });
                    } catch (error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Upload Failed',
                            text: 'An error ocurred, please try again.',
                            heightAuto: false
                        });

                        console.error(error);
                    }
                }
            });            
        }

        const result: UploadResult = {
            successfulUploads: succesfulUploads,
            failedUploads: failedUploads,
            needConversion: needConversion,
            needOptimization: needOptimization
        }

        return result;
    }
    
    static async getMissing3dAdditionalFiles (id?: string): Promise<string[]> {
        id = id ?? Vertex.Globals.spaceId;
        const modelInfo = await UploadUtils.modelInfo;
        const missingFiles: string[] = [];

        //check if model files have been uploaded to adapta and if a project hasn't been created (in which case it means all needed files were already uploaded)
        if(modelInfo){
            let isMainModelConverted = false;
            let gltfUri = "";

            if(modelInfo.objectModel?.additionals?.length){
                gltfUri = modelInfo.objectModel.publicUrl;

                //We gonna use the public url so we first check if it is expired, and in case, renew it, than we use it

                const expireDate = Utils.getUrlParam(gltfUri, ADAPTA_BLOB_URL_SIGNATURE_EXPIRATION_PARAM);
                const isExpired = Utils.isExpired(expireDate);

                if(isExpired){
                    if(modelInfo.project){
                        const loginOutput = await Amaz3dApi.login(Amaz3dApi.LoginInput);

                        if(loginOutput?.token){
                            const project = await Amaz3dApi.project(modelInfo.project.id, loginOutput.token);

                            if(project?.objectModel?.publicUrl){
                                modelInfo.objectModel.publicUrl = project.objectModel.publicUrl;
                                gltfUri = project.objectModel.publicUrl;

                                const modelInfoUpdated = await UploadUtils.postModelInfoJson(modelInfo, id);

                                if(!modelInfoUpdated){
                                    console.log(`Failed to update model info for resource ${id}`);
                                }
                            }
                        }
                    }
                }
            }
            else if(modelInfo.project?.optimizations?.length){
                const originalOptIndex = modelInfo.project.optimizations.findIndex(o => o.name === ORIGINAL_OPTIMIZATION_NAME);

                if( originalOptIndex !== -1 && modelInfo.project.optimizations[originalOptIndex]?.objectModelResultConverted?.additionals?.length){
                    isMainModelConverted = true;
                    gltfUri = modelInfo.project.optimizations[originalOptIndex].objectModelResultConverted.publicUrl;

                    //We gonna use the public url so we first check if it is expired, and in case, renew it, than we use it
                    const expireDate = Utils.getUrlParam(gltfUri, ADAPTA_BLOB_URL_SIGNATURE_EXPIRATION_PARAM);
                    const isExpired = Utils.isExpired(expireDate);
    
                    if(isExpired){
                        if(modelInfo.project){
                            const loginOutput = await Amaz3dApi.login(Amaz3dApi.LoginInput);
    
                            if(loginOutput?.token){
                                const optimizationFilter: OptimizationFilter = {id: {eq: modelInfo.project.optimizations[originalOptIndex].id }, project: {id: {eq: modelInfo.project.id}}};
                                const optimizations = await Amaz3dApi.optimizations(loginOutput.token, optimizationFilter);
                                const renewedOptimization = optimizations?.find(o => o.id === modelInfo.project.optimizations[originalOptIndex].id);
                                
                                if(renewedOptimization?.objectModelResultConverted?.publicUrl){ 
                                    modelInfo.project.optimizations[originalOptIndex].objectModelResultConverted.publicUrl = renewedOptimization.objectModelResultConverted.publicUrl;
                                    gltfUri = renewedOptimization.objectModelResultConverted.publicUrl;

                                    const modelInfoUpdated = await UploadUtils.postModelInfoJson(modelInfo);
    
                                    if(!modelInfoUpdated){
                                        console.log(`Failed to update model info for resource ${Vertex.Globals.spaceId}`);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            if(gltfUri){
                const loginOutput = await Amaz3dApi.login(Amaz3dApi.LoginInput);

                if(loginOutput == null || loginOutput.token == null){
                    console.log(`Failed to check unfinished upload`);
                }
                else{
                    try{
                        const gltfResponse = await fetch(gltfUri);

                        if(gltfResponse.ok){
                            const gltf = await gltfResponse.json() as GltfStructure;

                            if(gltf && !GltfUtils.isGltfEmbedded(gltf)){
                                const bufferUris = gltf.buffers?.map(b => decodeURI(b.uri)).filter(u => !u?.startsWith("data:"));
                                const imageUris = gltf.images?.map(i => decodeURI(i.uri)).filter(u => !u?.startsWith("data:"));
                                const additionalFiles = [];
                                
                                if(bufferUris?.length){
                                    additionalFiles.push(...bufferUris.filter((b): b is string => Boolean(b)));
                                }
                                
                                if(imageUris?.length){
                                    additionalFiles.push(...imageUris.filter((i): i is string => Boolean(i)));
                                }

                                if(isMainModelConverted){
                                    missingFiles.push(...additionalFiles.filter(a => !modelInfo.project.optimizations[0].objectModelResultConverted.additionals?.map(add => add.name).includes(a)));
                                }
                                else{
                                    missingFiles.push(...additionalFiles.filter(a => !modelInfo.objectModel.additionals?.map(add => add.name).includes(a)));
                                }
                            }                             
                        }                        
                    }
                    catch(e){
                        console.error(`Failed to get gltf info`, e);
                        console.error(`Failed to get missing files info`);
                    }
                }
            }
        }

        return missingFiles;
    }
    
    static validateSkyboxTexture(hdrTexture: any): Promise<boolean> {
        let promise = new Promise<boolean>((resolve, reject) => {
            const timeout = setTimeout(async function () {
                // todo: reconsider this. maybe checking for isReady isn't the best?
                if (!hdrTexture.getInternalTexture().isReady) {

                    resolve(false);
                    clearTimeout(timeout);
                }
                else {
                    resolve(true);
                    clearTimeout(timeout);
                }
            }, MAXIMUM_SKYBOX_LOAD_TIME_IN_MS);
        });

        return promise;
    }


}
import { AppView } from "../admin/common/appmodel/appview";
import { IDependencyProvider } from "../admin/common/appmodel/dependency-provider";
import { AuthManager } from "../auth-manager";
import "../explorer/css/contactadmin.scss";

export class ContactAdmin extends AppView {
    
    private mainFluid : HTMLDivElement;
    private authManager: AuthManager;

    constructor(services?: IDependencyProvider) {
        super(services);

        this.authManager = services.getService(AuthManager);

        this.mainFluid = document.getElementById("main-fluid") as HTMLDivElement;
    }

    async createContactAdmin() {
        let user = await this.authManager.getUserAsync();
        const contactAdminDiv = document.createElement("div") as HTMLDivElement;
        contactAdminDiv.className = "contact-admin-div";
        contactAdminDiv.innerText = `Hi ${user.profile.name}, you haven't any valid license, please contact your tenant admin.`;

        this.mainFluid.appendChild(contactAdminDiv);

        super.init();
    }
}
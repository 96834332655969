 import Swal from "sweetalert2";
import { AugmentedStoreAssembly } from "../../../../AugmentedStoreAssembly";
import { Utils } from "../../../utilities/utils";
import { LightMap } from "../EditorComponents/lighthierarchypanel";
import { cloneDeep } from "lodash";
import { CustomLightMapsHandlerComponent } from "../EditorComponents/lightmapshandler";
import { ChangeMaterialModel, ChangeMaterialModelUtils } from "../../../utilities/change-material-model-utilities";
import { IAwaitableComponent } from "./gltfLoadingHandlerComponent";
import { GltfStructure, findGltfMeshIdsForBabylonMesh, getGltfMaterialIndex, findMaterialForGltfIndex, GltfUtils } from "../../../utilities/gltf-utilities";
import { ResourceUtils } from "../../../utilities/resource-utilities";
import { MESH_GLTF_FILENAME, CHANGE_MATERIAL_MODEL_FILENAME } from "../../../utilities/constants";

export class CustomLightMapsComponent extends AugmentedStoreAssembly.LightMapsComponent implements IAwaitableComponent{
    cachedClonedMaterials: BABYLON.PBRMaterial[] = [];
    meshes: BABYLON.AbstractMesh[] = [];
    gltfJson: GltfStructure;

    isAwaitableComponent: boolean = true;
    funcOnLoad;

    async onLoad(): Promise<void> {
        return await this.funcOnLoad();
    }

    onSkinUpdatedBinded: (comp: Vertex.NodeComponentModel.Component) => void = this.onSkinUpdated.bind(this);

    private onSkinUpdated(comp: Vertex.NodeComponentModel.Component) {
        if (comp.node === this.node) {
            this.triggerOnChanged();
        }
    }
}

export class LightMapsComponentView extends Vertex.NodeComponentModel.ComponentViewBase {

    babylonTextures: Map<string, BABYLON.Texture> = new Map<string, BABYLON.Texture>();
    
    constructor() {
        super();

        Vertex.Globals.event.on("LightMaps:LightMapDeleted", (lightmapName) => {
            if (this.babylonTextures){
                let key = Vertex.Globals.spaceId + ":" + lightmapName;
                let texture = this.babylonTextures.get(key);

                if (texture){
                    texture.dispose();
                    this.babylonTextures.delete(key);
                }
            }
        });


        Vertex.Globals.event.on("LightMaps:LightMapLevelChanged", (lightmap : LightMap) => {
            if (this.babylonTextures){
                let key = Vertex.Globals.spaceId + ":" + lightmap.fileName;
                let texture = this.babylonTextures.get(key);

                if (texture) {
                    texture.level = lightmap.level;
                }
            }
        });

    }

    incompatibleComponents = ["ModelAlternative", "RotationComponent", "VideoTexture", "NavMesh"];

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        let isCompatible = Utils.checkComponentsCompatibility("LightMaps", node, this.incompatibleComponents);
        let self = this;

        const customComp = component as CustomLightMapsComponent;
        customComp.funcOnLoad = () => {};

         if (!isCompatible) {
            node.removeComponent("LightMaps");
            // Vertex.Globals.event.fire("editor:selectNode", node);
            return;
        }

        let itemPropertiesComp = node.getComponent("ItemProperties") as AugmentedStoreAssembly.ItemPropertiesComponent;
        
        if (itemPropertiesComp) {
            if (itemPropertiesComp.isMovable) {
                node.removeComponent("LightMaps");
                Vertex.Globals.event.fire("editor:selectNode", node);

                Swal.fire({
                    icon: 'warning',
                    title: `Invalid component selection!`,
                    html: `If you wish to use LightMaps please uncheck Movable property in the node Properties panel`,
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    heightAuto: false
                });

                return;
            }

            let isMovableInput = document.querySelector("#isMovablePropertyRenderer")?.querySelector("input");
            
            if (isMovableInput) {
                isMovableInput.setAttribute("disabled", "");
            }
        }
        
        customComp.funcOnLoad = async () => await self.onLoad(customComp, node);


    }

    async onLoad(customComp, node){

        await CustomLightMapsHandlerComponent.lightMapsHandlerComp.isReady;

        Vertex.Globals.event.off("ChangeMaterial:skinChanged", customComp.onSkinUpdatedBinded);

        let gltfComp = node.getComponent("GltfModel") as Vertex.NodeComponentModel.GltfModelComponent;
        customComp.gltfJson = await GltfUtils.getGltfStructure(gltfComp.id);

        try{
            let skinsResponse = await ResourceUtils.getAssetFromResource(gltfComp.id, CHANGE_MATERIAL_MODEL_FILENAME);
            if(!skinsResponse.ok){
                node.removeComponent("LightMaps");
                console.error(`[LightMaps]SkinJson from ${gltfComp.id} is null`);
                Vertex.Globals.event.fire("editor:selectNode", node);
                return;
            }

            const changeMaterialModel = await skinsResponse.json() as ChangeMaterialModel;

            if (customComp.lightMapsData.length != changeMaterialModel.subMeshes.length) {
                customComp.lightMapsData = new Array<number>(changeMaterialModel.subMeshes.length);
                customComp.lightMapsData.fill(-1, 0, changeMaterialModel.subMeshes.length);
            }

            Vertex.Globals.event.on("ChangeMaterial:skinChanged", customComp.onSkinUpdatedBinded);

        }
        catch(e){
            console.log(`Failed to get skins info from ${gltfComp.id}.`);
            node.removeComponent("LightMaps");

            return;
        }

        const rootMesh = node.viewNode as BABYLON.Mesh;
        customComp.meshes = rootMesh.getChildMeshes(false);

        customComp.onChanged.on(async () => {
            let updatedClonedMaterial = [];

            for (const mesh of customComp.meshes) {
                if (mesh.material && mesh.material instanceof BABYLON.PBRMaterial) {
                    let meshMaterial = mesh.material as BABYLON.PBRMaterial;
                    let [meshId, primitiveId] = findGltfMeshIdsForBabylonMesh(mesh);
                    let lightmapTexture: BABYLON.Texture = null;
                    let lightmapIndex = customComp.lightMapsData[meshId];

                    const lightmaps = await CustomLightMapsHandlerComponent.getLightmaps();

                    if (lightmapIndex > -1 && lightmapIndex < lightmaps.length) {
                        let lightmap = lightmaps[lightmapIndex];

                        const filesToDelete = await CustomLightMapsHandlerComponent.getLightmapsToDelete();

                        if (!filesToDelete.includes(lightmap.fileName)) {
                            lightmapTexture = await this.getLightmapTexture(lightmap);
                        }
                    }

                    if (customComp.cachedClonedMaterials.includes(meshMaterial)) {
                        const cachedMaterial = meshMaterial;
                        cachedMaterial.lightmapTexture = lightmapTexture;
                        cachedMaterial.useLightmapAsShadowmap = true;

                        if(cachedMaterial.lightmapTexture){
                            cachedMaterial.ambientColor = BABYLON.Color3.White();
                        }
                        else{
                            cachedMaterial.ambientColor = BABYLON.Color3.Black();
                        }

                        updatedClonedMaterial.push(cachedMaterial);
                    }
                    else{
                        if (lightmapTexture == null) {
                            meshMaterial.lightmapTexture = null;
                        }
                        else {
                            let clonedMaterial = meshMaterial.clone(meshMaterial.name + "-Clone");
                            clonedMaterial.metadata = cloneDeep(meshMaterial.metadata);
                            clonedMaterial.lightmapTexture = lightmapTexture;
                            clonedMaterial.useLightmapAsShadowmap = true;

                            clonedMaterial.ambientColor = BABYLON.Color3.White();

                            updatedClonedMaterial.push(clonedMaterial);
                            mesh.material = clonedMaterial;
                        }
                    }
                }
            }

            customComp.cachedClonedMaterials.filter((mat) => !updatedClonedMaterial.includes(mat)).forEach((oldMat) => oldMat.dispose());
            customComp.cachedClonedMaterials = updatedClonedMaterial;
        });

        customComp.triggerOnChanged();
    }


    async getLightmapTexture(lightMap : LightMap): Promise<BABYLON.Texture>{
        let key = Vertex.Globals.spaceId + ":" + lightMap.fileName;
        let lightmapTexture = this.babylonTextures.get(key); 

        if(lightmapTexture == null){
            let downloadedTexture = await ResourceUtils.getAssetBlobUrl(Vertex.Globals.spaceId, lightMap.fileName);

            if (downloadedTexture) {

                if (lightMap.fileName.endsWith(".hdr")) {
                    let texture = await fetch(downloadedTexture);
                    var uint8array = new Uint8Array(await texture.arrayBuffer());
                    var hdrInfo = BABYLON.HDRTools.RGBE_ReadHeader(uint8array);
                    var data = BABYLON.HDRTools.RGBE_ReadPixels(uint8array, hdrInfo);
                    lightmapTexture = BABYLON.RawTexture.CreateRGBTexture(data, hdrInfo.width, hdrInfo.height, Vertex.Globals.runtime.scene, false, true,
                        BABYLON.Texture.TRILINEAR_SAMPLINGMODE, BABYLON.Constants.TEXTURETYPE_FLOAT);
                    lightmapTexture.coordinatesIndex = 1;
                    lightmapTexture.vScale = -1;
                    lightmapTexture.vOffset = 1;

                    lightmapTexture.gammaSpace = false;

                }
                else {
                    lightmapTexture = new BABYLON.Texture(downloadedTexture, Vertex.Globals.runtime.scene, null, false);
                    lightmapTexture.isRGBD = true;
                    lightmapTexture.coordinatesIndex = 1;

                    //console.log("Lightmap texture gammaSpace: ", lightmapTexture.gammaSpace);
                    lightmapTexture.gammaSpace = false;

                }
                
                lightmapTexture.level = lightMap.level;

                this.babylonTextures.set(key, lightmapTexture);
            }
        }

        return lightmapTexture;
    }



    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        const customComp = component as CustomLightMapsComponent;
        Vertex.Globals.event.off("ChangeMaterial:skinChanged", customComp.onSkinUpdatedBinded);

        for (const mesh of customComp.meshes) {
            if (mesh.material && mesh.material instanceof BABYLON.PBRMaterial) {
                let meshMaterial = mesh.material as BABYLON.PBRMaterial;

                if (customComp.cachedClonedMaterials.includes(meshMaterial)) {
                    let matIndex = getGltfMaterialIndex(meshMaterial);

                    let originalMaterial = findMaterialForGltfIndex(node.viewNode, matIndex);

                    if (originalMaterial) {
                        mesh.material = originalMaterial;
                    }
                    else {
                        console.error("[Lightmaps]Origianl material not found");
                    }
                }
            }
        }

        for (let material of customComp.cachedClonedMaterials) {
            material.dispose();
        }
    }
}

export class LightMapsComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new CustomLightMapsComponent();
    }

    constructor() {
        super("LightMaps", new LightMapsComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}

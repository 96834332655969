import { DEFAULT_DOCUMENT_LANGUAGE, SYSTEM_DATA_MAX_LENGTH, DEFAULT_MAX_TOKENS, DEFAULT_TEMPERATURE, DEFAULT_VOICE_GENDER, LanguageCode, DEFAULT_MIN_RANKING_SCORE, DEFAULT_MIN_WORDS_VALIDATION, DEFAULT_NUMBER_OF_INTERACTIONS, CHANGE_MATERIAL_MODEL_FILENAME } from "../../utilities/constants";
import { UserProfileUtils } from "../../utilities/user-profile-utilities";
import { CODE, XR_COPILOT_API_DOMAIN } from "./common";
import { v4 as uuidv4 } from 'uuid';
import { ResourceInfo } from "./storage";
import { SkinNode, Skins } from "./mesh-utils/skins";
import { ResourceUtils } from "../../utilities/resource-utilities";
import { ChangeMaterialModel } from "../../utilities/change-material-model-utilities";


const ASSISTANT_API_ENDPOINT = "api/assistant";
const ACTIVE_RESOURCES_ENDPOINT = "activeresources";
const ACTIVE_INDEXES_COUNT_ENDPOINT = "activeindexescount";
const HAS_AI_ENDPOINT = "hasai";
const HAS_DATA_ENDPOINT = "hasdata";
const CHAT_API_ENDPOINT = "chat";
const WELCOME_MSG_API_ENDPOINT = "welcome";
const SYSTEM_API_ENDPOINT = "system";
const ENDPOINT_API_ENDPOINT = "endpoint";
const ENDPOINTS_API_ENDPOINT = "endpoints";

export interface SystemData {
    resourceId: string;
    systemMessage: string;
    documentsLanguage: string;
    maxTokens?: number;
    temperature?: number;
    minRankingScore?: number;
    minWordsValidation?: number;
    numberOfInteractions?: number;
}

export interface EndpointConfiguration {
    endpoints: Endpoint[];
}

export interface DeleteEndpointConfigurationResult {
    deletedResources: ResourceInfo[];
    notDeletedResources: ResourceInfo[];
    statusCode: number;
}

export interface Endpoint {
    endpointId: number; //0 for new record, value for existing record
    companyId?: string;
    endpointType: EndpointType;
    endpointUrl: string;
    apiVersion?: string;
    apiKey?: string;
    deploymentName?: string;
}

export interface ChatRequest {
    Message: string;
    TenantId?: string;
    ResourceId?: string;
    WithVoice?: boolean;
    VoiceGender?: string;
    AnswerLanguage?: string;
    ConversationId?: string;
    Presets?: SkinNode[]; //this class is from mesh-utils (NOT from change-material-model-utilities)
}

export interface ChatResponse
{
    ResponseType: ResponseType;
    Message: string;
    Intent?: Intent;
    ConversationId: string;
    VoiceData?: string;
}

export interface Intent{
    Type: IntentType;
    Entities: UserIntentEntity[];
}
export interface UserIntentEntity
{
    Name: string;
    Value: string;
}

export enum IntentType
{
    None = "None",
    ChangePreset = "ChangePreset",
    ChangeLanguage = "ChangeLanguage",
    AskForDetails = "AskForDetails",
    MoveObject = "MoveObject",
    RotateObject = "RotateObject",
    ScaleObject = "ScaleObject",
    ListPresets = "ListPresets",
}

export enum ResponseType {
    Error = "Error",
    Action = "Action",
    Message = "Message",
    System = "System",
}

export enum EndpointType {
    AzureSearch = 0,
    AzureOpenAi = 1
}

export class AssistantApi {
    static async chat(request: ChatRequest){
        if(!request || !request.Message){
            return;
        }

        request.ResourceId = request.ResourceId ?? Vertex.Globals.spaceId;
        request.TenantId = request.TenantId ?? await UserProfileUtils.getTenantId(Vertex.Globals.bearerToken);
        request.WithVoice = request.WithVoice ?? false;
        request.VoiceGender = request.VoiceGender ?? DEFAULT_VOICE_GENDER;
        request.AnswerLanguage = request.AnswerLanguage ?? LanguageCode[navigator?.language?.substring(0, 2)] ?? null;
        request.ConversationId = request.ConversationId ?? uuidv4();

        if(!request.Presets){
            const resp = await ResourceUtils.getAssetFromResource(request.ResourceId, CHANGE_MATERIAL_MODEL_FILENAME);

            if(resp.ok){
                const skins = await resp.json() as ChangeMaterialModel;

                request.Presets = Skins.skinsJsonForAI(skins);
            }
        }

        let uri = `${XR_COPILOT_API_DOMAIN}/${ASSISTANT_API_ENDPOINT}/${CHAT_API_ENDPOINT}`;

        //TODO: now we add auth token reading a const value; will be replaced with actual token later.
        uri += `?code=${CODE}`;

        try{
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request)
            });

            if(response.ok){
                return await response.json() as ChatResponse;
            }
            else{
                console.log(`Failed to chat with assistant`, response.status + response.statusText);
            }
        }
        catch(e){
            console.log(`Failed to chat with assistant`, e);
        }
    }

    static async getWelcomeMessage(language?: LanguageCode){
        language = language ?? LanguageCode[navigator?.language?.substring(0, 2)] ?? DEFAULT_DOCUMENT_LANGUAGE;

        let uri = `${XR_COPILOT_API_DOMAIN}/${ASSISTANT_API_ENDPOINT}/${WELCOME_MSG_API_ENDPOINT}/${language}`;

        //TODO: now we add auth token reading a const value; will be replaced with actual token later.
        uri += `?code=${CODE}`;

        try{
            const response = await fetch(uri);

            if(response.ok){
                const chatResponse = await response.json() as ChatResponse;

                if(chatResponse?.ResponseType == ResponseType.Message){
                    return chatResponse.Message;
                }
            }
            else{
                console.log(`Failed to fetch welcome message for language ${language}`, response.status + response.statusText);
            }
        }
        catch(e){
            console.log(`Failed to fetch welcome message for language ${language}`, e);
        }
    }

    static async getSystemData(resourceId?: string){
        resourceId = resourceId ?? Vertex.Globals.spaceId;
        
        if(!resourceId){
            return;
        }

        let uri = `${XR_COPILOT_API_DOMAIN}/${ASSISTANT_API_ENDPOINT}/${SYSTEM_API_ENDPOINT}/${resourceId}`;
        
        //TODO: now we add auth token reading a const value; will be replaced with actual token later.
        uri += `?code=${CODE}`;

        try{
            const response = await fetch(uri);

            if(response.ok){
                return await response.json() as SystemData;
            }
            else{
                console.log(`Failed to fetch system data from resource ${resourceId}`, response.status+response.statusText);
            }
        }
        catch(e){
            console.log(`Failed to fetch system data from resource ${resourceId}`, e);
        }        
    }

    static async setSystemData(data: SystemData){
        if(!data){
            return;
        }

        data.resourceId = data.resourceId ?? Vertex.Globals.spaceId;;
        data.documentsLanguage = data.documentsLanguage ?? DEFAULT_DOCUMENT_LANGUAGE;
        data.systemMessage = data.systemMessage?.substring(0, SYSTEM_DATA_MAX_LENGTH).trim();
        data.maxTokens = data.maxTokens ?? DEFAULT_MAX_TOKENS;
        data.temperature = data.temperature ?? DEFAULT_TEMPERATURE;
        data.minRankingScore = data.minRankingScore ?? DEFAULT_MIN_RANKING_SCORE;
        data.minWordsValidation = data.minWordsValidation ?? DEFAULT_MIN_WORDS_VALIDATION;
        data.numberOfInteractions = data.numberOfInteractions ?? DEFAULT_NUMBER_OF_INTERACTIONS;

        if(!data.resourceId){
            return;
        }

        let uri = `${XR_COPILOT_API_DOMAIN}/${ASSISTANT_API_ENDPOINT}/${SYSTEM_API_ENDPOINT}`;
                
        //TODO: now we add auth token reading a const value; will be replaced with actual token later.
        uri += `?code=${CODE}`;

        try{
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if(response.ok){
                return true;
            }
            else{
                console.log(`Failed to set system data to resource ${data.resourceId}`, response.status+response.statusText);
            }
        }
        catch(e){
            console.log(`Failed to set system data to resource ${data.resourceId}`, e);
        }
    }

    static async getEndpointsConfig(token?: string){
        token = token ?? Vertex.Globals.bearerToken;
        const companyId = await UserProfileUtils.getTenantId(token);

        if(!companyId){
            return;
        }

        let uri = `${XR_COPILOT_API_DOMAIN}/${ASSISTANT_API_ENDPOINT}/${ENDPOINTS_API_ENDPOINT}/${companyId}`;
        //TODO: now we add auth token reading a const value; will be replaced with actual token later.
        uri += `?code=${CODE}`;

        try{
            const response = await fetch(uri);

            if(response.ok){
                return await response.json() as EndpointConfiguration;
            }
            else{
                console.log(`Failed to fetch endpoints config for company ${companyId}`, response.status+response.statusText);
            }
        }
        catch(e){
            console.log(`Failed to fetch endpoints config for company ${companyId}`, e);
        }
    }

    static async setEndpointConfig(config: Endpoint, token?: string){
        if(!config || config.endpointId == null || !config.endpointUrl || config.endpointType == null || !config.apiKey || (!config.companyId && !token)){
            return;
        }

        if(!config.companyId){
            config.companyId = await UserProfileUtils.getTenantId(token);
        }

        let uri = `${XR_COPILOT_API_DOMAIN}/${ASSISTANT_API_ENDPOINT}/${ENDPOINT_API_ENDPOINT}`;
        //TODO: now we add auth token reading a const value; will be replaced with actual token later.
        uri += `?code=${CODE}`;

        const body: Endpoint = { companyId: config.companyId, endpointId: config.endpointId, endpointUrl: config.endpointUrl, endpointType: config.endpointType, apiKey: config.apiKey, apiVersion: config.apiVersion, deploymentName: config.deploymentName};

        try{
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                        'Content-Type': 'application/json'
                    },
                body: JSON.stringify(body)
            });
    
            if(response.ok){
                return await response.json() as Endpoint[];
            }
            else{
                console.log(`Failed to set endpoint config for company ${config.companyId}`, response.status + response.statusText);
            }
        }
        catch(e){
            console.log(`Failed to set endpoint config for company ${config.companyId}`, e);
        }
    }

    static async deleteEndpointConfig(endpointId: number){
        if(!endpointId){
            return;
        }

        let uri = `${XR_COPILOT_API_DOMAIN}/${ASSISTANT_API_ENDPOINT}/${ENDPOINT_API_ENDPOINT}/${endpointId}`;

        //TODO: now we add auth token reading a const value; will be replaced with actual token later.
        uri += `?code=${CODE}`;

        try{
            const response = await fetch(uri, {
                method: "DELETE"
            });

            const result = {} as DeleteEndpointConfigurationResult;
            result.statusCode = response.status;

            if(response.ok){
                const body = await response.json() as DeleteEndpointConfigurationResult;
                result.deletedResources = body.deletedResources;
                result.notDeletedResources = body.notDeletedResources;

                return result;
            }
            else{
                console.log(`Failed to delete endpoint config ${endpointId}`, response.status + response.statusText);
                
                return result;
            }
        }
        catch(e){
            console.log(`Failed to delete endpoint config ${endpointId}`, e);
        }
    }

    static async hasAI(resourceId?: string){
        resourceId = resourceId ?? Vertex.Globals.spaceId;

        if(!resourceId){
            return;
        }

        let uri = `${XR_COPILOT_API_DOMAIN}/${ASSISTANT_API_ENDPOINT}/${HAS_AI_ENDPOINT}/${resourceId}`;

        //TODO: now we add auth token reading a const value; will be replaced with actual token later.
        uri += `?code=${CODE}`;

        try{
            const response = await fetch(uri);

            return response.ok;
        }
        catch(e){
            console.log(`Failed to fetch system data from resource ${resourceId}`, e);
        }        
    }

    static async hasData(resourceId?: string){
        resourceId = resourceId ?? Vertex.Globals.spaceId;

        if(!resourceId){
            return;
        }

        let uri = `${XR_COPILOT_API_DOMAIN}/${ASSISTANT_API_ENDPOINT}/${HAS_DATA_ENDPOINT}/${resourceId}`;

        //TODO: now we add auth token reading a const value; will be replaced with actual token later.
        uri += `?code=${CODE}`;

        try{
            const response = await fetch(uri);

            return response.ok;
        }
        catch(e){
            console.log(`Failed to fetch system data from resource ${resourceId}`, e);
        }        
    }

    static async getActiveResources(token?: string){
        token = token ?? Vertex.Globals.bearerToken;

        if(!token){
            return;
        }

        const companyId = await UserProfileUtils.getTenantId(token);

        if(!companyId){
            return;
        }

        let uri = `${ XR_COPILOT_API_DOMAIN }/${ ASSISTANT_API_ENDPOINT }/${ ACTIVE_RESOURCES_ENDPOINT }/${ companyId }`;

        //TODO: now we add auth token reading a const value; will be replaced with actual token later.
        uri += `?code=${CODE}`;

        try{
            const response = await fetch(uri, {
                method: 'GET',
                headers: {
                        'vertx-token': token
                    }
                });

            if(response.ok){
                return await response.json() as ResourceInfo[];
            }
            else{
                console.log(`Failed to fetch active resources for company ${companyId}`, response.status+response.statusText);
            }
        }
        catch(e){
            console.log(`Failed to fetch active resources for company ${companyId}`, e);
        }
    }

    static async getActiveIndexesCount(token?: string){
        token = token ?? Vertex.Globals.bearerToken;

        if(!token){
            return;
        }

        const companyId = await UserProfileUtils.getTenantId(token);

        if(!companyId){
            return;
        }

        let uri = `${ XR_COPILOT_API_DOMAIN }/${ ASSISTANT_API_ENDPOINT }/${ ACTIVE_INDEXES_COUNT_ENDPOINT }/${ companyId }`;

        //TODO: now we add auth token reading a const value; will be replaced with actual token later.
        uri += `?code=${CODE}`;

        try{
            const response = await fetch(uri, {
                method: 'GET',
                headers: {
                        'vertx-token': token
                    }
                });

            if(response.ok){
                return await response.json() as number;
            }
            else{
                console.log(`Failed to fetch active indexes count for company ${companyId}`, response.status+response.statusText);
            }
        }
        catch(e){
            console.log(`Failed to fetch active indexes count for company ${companyId}`, e);
        }
    }
}
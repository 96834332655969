import Swal from "sweetalert2";
import { importTemplate, SpacePrivacy, Utils } from "../../../utilities/utils";
import { HevolusApp, isMajorGte } from "../../../utilities/versioning";
import { CustomSpawnPointComponent } from "../NodeComponents/spawnpointcomponent";
import { ResourceUtils, VERTEXResource } from "../../../utilities/resource-utilities";
import { UploadUtils, UploadingAssetType } from "../../../utilities/upload-utilities";
import { ALLOWED_IMAGE_TYPES, DEFAULT_SWAL_LOADING_MESSAGE, RESOURCE_THUMB_FILENAME, SPACE_EDITOR_VERSION_PREFIX_TAG, SPACEPRIVACY_OFFLINE_TAG, SPACEPRIVACY_PRIVATE_TAG, SPACEPRIVACY_PUBLIC_TAG, SPACEPRIVACY_PUBLISHED_TAG } from "../../../utilities/constants";
import { CustomPointsOfInterestComponent } from "../NodeComponents/pointsofinterestcomponent";

export class SaveSettingsPanelComponent extends Vertex.NodeComponentModel
	.Component {
	writeData(writer: Vertex.BinaryWriter): void { }
	readData(reader: Vertex.BinaryReader): void { }
}

export class SaveSettingsPanelComponentView extends Vertex.NodeComponentModel
	.ComponentViewBase {

	selectedPrivacies: SpacePrivacy = SpacePrivacy.None;
	dropzone: HTMLDivElement;
	dropzoneImage: HTMLImageElement;
	cachedDropzoneImageSrc: string;
	uploader: HTMLInputElement;
	resourceNameInput: HTMLInputElement;
	resource: VERTEXResource;
	saveButton: HTMLButtonElement;
	saveCopyButton: HTMLButtonElement;


	constructor() {
		super();
	}

	readonly defaultDropzoneImageUri = `/img/upload-thumbnail-icon.svg`;
	readonly defaultDropzoneDeleteImageUri = `/img/bin.svg`;

	addComponent(
		component: Vertex.NodeComponentModel.Component,
		node: Vertex.NodeComponentModel.VertexNode
	) {
		ResourceUtils.getResourceData(Vertex.Globals.spaceId).then((res) => {
			this.resource = res;
			const spaceEditorVersionTag = this.resource?.tags?.find(t => t.startsWith(SPACE_EDITOR_VERSION_PREFIX_TAG));
			
			if(spaceEditorVersionTag == null){
				console.log(`Save Settings Panel not available because of missing Space Editor version info.`);

				return;
			}

			isMajorGte(spaceEditorVersionTag, HevolusApp.SpaceEditor).then(isValid => {
				if(!isValid){
					console.log(`Save Settings Panel not available because of Space Editor version incompatibility.`);

					return;
				}

				let card = document.createElement("div");
				card.classList.add("card", "pointer-enable");
				card.id = "save-panel";
	
				let header = document.createElement("div");
				header.classList.add(
					"nav-link",
					"card-header-minimizable",
					"dark-text",
					"max"
				);
	
				let headerText = document.createElement("div");
				headerText.classList.add("card-header-text");
				headerText.innerText = "Save Settings";
	
				let body = document.createElement("div");
				body.classList.add("card-body", "p-0");
	
				const panel = importTemplate("#save-settings-panel-template") as HTMLDivElement;
				this.dropzone = panel.querySelector(".drop-zone") as HTMLDivElement;
				this.uploader = this.dropzone.querySelector("input");
	
				this.dropzone.addEventListener("drop", this.handleFilesDropped);
				this.dropzone.addEventListener("dragenter", this.handleDragEnterEvent(this.dropzone));
				this.dropzone.addEventListener("dragleave", this.handleDragLeaveEvent(this.dropzone));
				this.dropzone.addEventListener("dragover", (event) => event.preventDefault());
				this.dropzone.addEventListener("mouseover", (event) => this.onMouseEnterDropzone(event));
				this.dropzone.addEventListener("mouseleave", () => this.onMouseExitDropzone());
				this.dropzone.onclick = () => this.uploader.click();
	
				this.uploader.setAttribute(
					"accept",
					"." + ALLOWED_IMAGE_TYPES.join(",.")
				);
	
				this.uploader.style.display = "none";
				this.uploader.addEventListener("change", async () => {
					await this.uploadThumb(Array.from(this.uploader.files));
	
					this.uploader.value = "";
				});
	
				this.dropzoneImage = this.dropzone.querySelector("img");
				this.updateDropzoneImage(false);
	
				this.resourceNameInput = panel.querySelector("#resource-name-input") as HTMLInputElement;
				this.resourceNameInput.value = `${this.resource.name}`;
	
				this.resourceNameInput.addEventListener("focusout", async (event) => {
					if(this.resource.name === this.resourceNameInput.value){
						return;
					}

					// If the focus is lost because the user clicked on the save button, don't update the resource name (it will be updated by the save button handler)
					if(event.relatedTarget == this.saveButton || event.relatedTarget == this.saveCopyButton){
						return;
					}

					Swal.fire({
						icon: "info",
						title: `Updating Space name to ${this.resourceNameInput.value}`,
						heightAuto: false,
					});

					Swal.showLoading();

					const updated = await this.updateResourceName();

					if(updated){
						Swal.close();
					}
					else{
						Swal.fire({
							icon: "error",
							title: "Failed to update Space name",
							html: "The provided name could be used by another Resource. Please try again with a different name.",
							heightAuto: false,
						});
					}
				});
	
				this.resourceNameInput.addEventListener("keydown", async (ev: KeyboardEvent) => {
					this.resourceNameInput.value = Utils.sanitizeString(this.resourceNameInput.value);
					
					if (ev.key == "Enter"){
						// Remove focus from the input, so that the focusout event is triggered
						(ev.currentTarget as HTMLElement)?.blur();
					}
				});

					
				const inputOffline = panel.querySelector("#offline-checkbox");
	
				inputOffline.addEventListener("click", () => {
					if (this.selectedPrivacies & SpacePrivacy.Offline) {
						this.selectedPrivacies &= ~SpacePrivacy.Offline;
					} else {
						this.selectedPrivacies |= SpacePrivacy.Offline;
					}
				});	
	
				const inputPrivate = panel.querySelector("#private-checkbox");
	
				inputPrivate.addEventListener("click", () => {
					if (this.selectedPrivacies & SpacePrivacy.Private) {
						this.selectedPrivacies &= ~SpacePrivacy.Private;
					} else {
						this.selectedPrivacies |= SpacePrivacy.Private;
					}
				});
	
				const publicInput = panel.querySelector("#public-checkbox");
	
				publicInput.addEventListener("click", () => {
					if (this.selectedPrivacies & SpacePrivacy.Public) {
						this.selectedPrivacies &= ~SpacePrivacy.Public;
					} else {
						this.selectedPrivacies |= SpacePrivacy.Public;
					}
				});
	
				const publicLinkInput = panel.querySelector("#public-link-checkbox");
	
				publicLinkInput.addEventListener("click", () => {
					if (this.selectedPrivacies & SpacePrivacy.PublicLink) {
						this.selectedPrivacies &= ~SpacePrivacy.PublicLink;
					} else {
						this.selectedPrivacies |= SpacePrivacy.PublicLink;
					}
				});
	
				if (this.resource.tags.includes(SPACEPRIVACY_OFFLINE_TAG)) {
					this.selectedPrivacies |= SpacePrivacy.Offline;
					inputOffline.setAttribute("checked", "true");
				}	

				if (this.resource.tags.includes(SPACEPRIVACY_PRIVATE_TAG)) {
					this.selectedPrivacies |= SpacePrivacy.Private;
					inputPrivate.setAttribute("checked", "true");
				}
	
				if (this.resource.tags.includes(SPACEPRIVACY_PUBLISHED_TAG)) {
					this.selectedPrivacies |= SpacePrivacy.Public;
					publicInput.setAttribute("checked", "true");
				}
	
				if (this.resource.tags.includes(SPACEPRIVACY_PUBLIC_TAG)) {
					this.selectedPrivacies |= SpacePrivacy.PublicLink;
					publicLinkInput.setAttribute("checked", "true");
				}
	
				//save button
				this.saveButton = panel.querySelector("#save-button");

				this.saveButton.addEventListener("click", async () => {
					Swal.fire({
						icon: "info",
						title: DEFAULT_SWAL_LOADING_MESSAGE,
						allowEscapeKey: false,
						allowOutsideClick: false,
						showConfirmButton: false,
						heightAuto: false,
					});

					Swal.showLoading();

					//SAVE METHOD
					let spawnpointNode: Vertex.NodeComponentModel.VertexNode = Array.from(
						Vertex.Globals.runtime.space.nodes.values()
					).find((node: Vertex.NodeComponentModel.VertexNode) =>
						node.components.includes("SpawnPoint")
					) as Vertex.NodeComponentModel.VertexNode;

					if (spawnpointNode) {
						let spawnpointComp = spawnpointNode.getComponent(
							"SpawnPoint"
						) as CustomSpawnPointComponent;

						if (!spawnpointComp.isPlacedOnNavmesh()) {
							await Swal.fire({
								icon: "warning",
								title: "The Spawn Point is not above the Navmesh",
								html: "The Space will not be saved.<br>Please reposition it first, and then save.",
								footer: "The Spawn Point sets the exact position where the user avatars will be spawned.",
								allowEscapeKey: false,
								allowOutsideClick: false,
								showConfirmButton: true,
								heightAuto: false,
							});
							return;
						}

						if(spawnpointComp.isPlacedInWarpArea())
						{
							await Swal.fire({
								icon: "warning",
								title: "The Spawn Point is placed in a Warp area",
								html: "The Space will not be saved.<br>Please reposition it first, and then save again.",
								footer: "The Spawn Point sets the exact position where the user avatars will be spawned.",
								allowEscapeKey: false,
								allowOutsideClick: false,
								showConfirmButton: true,
								heightAuto: false,
							});
							return;
						}

					}

					let pointsOfInterestNode: Vertex.NodeComponentModel.VertexNode = Array.from(
						Vertex.Globals.runtime.space.nodes.values()
					).find((node: Vertex.NodeComponentModel.VertexNode) =>
						node.components.includes("PointsOfInterest")
					) as Vertex.NodeComponentModel.VertexNode;

					if (pointsOfInterestNode) {
						let pointsOfInterestComp = pointsOfInterestNode.getComponent(
							"PointsOfInterest"
						) as CustomPointsOfInterestComponent;

						let poiNames = [];
						pointsOfInterestComp.pointsOfInterest.forEach(async (poi, index) => {
							const isPlacedOnNavmesh = pointsOfInterestComp.isPlacedOnNavmesh(index);
							if(!isPlacedOnNavmesh){
								poiNames.push(poi.name);
							}
						});

						if (poiNames.length > 0) {
							await Swal.fire({
								icon: "warning",
								title: "One or more Point of Interest is not above the Navmesh",
								html: "The Space will not be saved.<br>Please reposition PoI first, and then save. <br><br> Points of interest: " + poiNames.join(", "),
								allowEscapeKey: false,
								allowOutsideClick: false,
								showConfirmButton: true,
								heightAuto: false,
							});
							return;
						}
					}

					await this.updateResourceName();

					Vertex.Globals.event.fire("editor:saveSpace", this.selectedPrivacies);
				});
	
				//save copy
				this.saveCopyButton = panel.querySelector("#save-copy-button");
				
				const saveCopyImg = this.saveCopyButton.querySelector("img");
				Utils.injectSvg(saveCopyImg, { afterLoad: (svg, svgString) => {
					svg.setAttribute("fill", "#005cc8");
				} });

				this.saveCopyButton.addEventListener("click", async () => {
					Swal.fire({
						icon: "info",
						title: DEFAULT_SWAL_LOADING_MESSAGE,
						allowEscapeKey: false,
						allowOutsideClick: false,
						showConfirmButton: false,
						heightAuto: false,
					});

					Swal.showLoading();
					await this.updateResourceName();

					//SAVE AND COPY METHOD
					Vertex.Globals.event.fire("editor:saveSpaceCopy", this.selectedPrivacies);
				});

				Utils.populateEditTags(panel, this.resource);
	
				let leftSidebar = document.querySelector(".left-sidebar-grid");
	
				header.appendChild(headerText);
				card.appendChild(header);
				body.appendChild(panel);
				card.appendChild(body);
				leftSidebar.appendChild(card);
	
				Utils.setupSidebarButton("SaveSettingsPanel","save-panel");
			});           
		});
	}

	async updateResourceName() {
		let updated = false;

		this.resourceNameInput.value = Utils.sanitizeString(this.resourceNameInput.value.trim());

		// If the name is the same as the current one, don't update and return
		if(this.resourceNameInput.value === this.resource.name){
			return true;
		};

		if(this.resourceNameInput.value) {
			updated = await ResourceUtils.updateResourceName(this.resource, this.resourceNameInput.value);
			
			if(updated){
				this.resource.name = this.resourceNameInput.value;
			}
		}

		if(!updated){
			this.resourceNameInput.value = this.resource.name;
		}

		return updated;
	}

	onMouseEnterDropzone(event: MouseEvent) {
		event.preventDefault();

		if(this.dropzoneImage.src.includes(this.defaultDropzoneImageUri)){
			this.dropzone.removeAttribute("onclick");
			this.dropzone.onclick = () => this.uploader.click();
		}
		else{
			this.dropzoneImage.src = "";
			this.dropzoneImage.src = this.defaultDropzoneDeleteImageUri;
			this.dropzoneImage.classList.add("drop-zone__thumb-delete");

			this.dropzone.removeAttribute("onclick");
			this.dropzone.onclick = async () => {
                Swal.fire({
                    icon: 'info',
                    title: 'Are you sure you want to delete the thumbnail?',
                    confirmButtonText: 'Delete',
					confirmButtonColor: "red",
                    showCancelButton: true,
                    heightAuto: false
                }).then(async response => {
                    if (response.isConfirmed) {
						await ResourceUtils.deleteAssetFromResource(Vertex.Globals.spaceId, RESOURCE_THUMB_FILENAME);
						this.updateDropzoneImage(false);
                    }
                });
			}
		}
	}

	onMouseExitDropzone() {
		if(this.dropzoneImage.src.includes(this.defaultDropzoneDeleteImageUri)){
			this.updateDropzoneImage();
		}
		else{
			this.dropzoneImage.src = this.defaultDropzoneImageUri;
			this.dropzoneImage.classList.remove("drop-zone__thumb-delete");

			// this.dropzoneImage.style.removeProperty("padding");
			// this.dropzoneImage.style.removeProperty("background-color");

			this.dropzone.removeAttribute("onclick");
			this.dropzone.onclick = () => this.uploader.click();
		}
	}


	updateDropzoneImage(useCached = true) {
		if (this.dropzoneImage) {
			this.dropzoneImage.classList.remove("drop-zone__thumb-delete");

			if(useCached){
				this.dropzoneImage.src = this.cachedDropzoneImageSrc;
			}
			else{
				ResourceUtils.getThumbnailBlobUrl(Vertex.Globals.spaceId).then((url) => {	
					if (url) {
						this.cachedDropzoneImageSrc = url;
						this.dropzoneImage.src = url;
					}
					else{
						this.dropzoneImage.src = this.defaultDropzoneImageUri;
					}
				});
			}
		}
	}

	uploadThumb(droppedFiles: File[]) {
		UploadUtils.uploadFiles(droppedFiles, Vertex.Globals.spaceId, UploadingAssetType.Thumb).then(_ => {
			this.updateDropzoneImage(false);
		});
	}

	private handleDragEnterEvent(dropzone: HTMLDivElement): (this: HTMLDivElement, ev: DragEvent) => any {
		return (event) => {
			event.preventDefault();
			dropzone.focus();
		};
	}

	private handleDragLeaveEvent(dropzone: HTMLDivElement): (this: HTMLDivElement, ev: DragEvent) => any {
		return (event) => {
			event.preventDefault();
			dropzone.blur();
		};
	}

	handleFilesDropped = async (event: DragEvent) => {
		event.preventDefault();
		const droppedFiles: File[] = this.getFilesDropped(event) as File[];

		await this.uploadThumb(droppedFiles);
		this.updateDropzoneImage(false);
	};

	getFilesDropped = (event: DragEvent) => {
		let files: File[] | DataTransferItem[] = [];

		if (event.dataTransfer) {
			const dataTransfer = event.dataTransfer;
			if (dataTransfer.files && dataTransfer.files.length > 0) {
				files = [...dataTransfer.files];
			} else if (dataTransfer.items && dataTransfer.items.length > 0) {
				files = [...dataTransfer.items];
			} else {
				throw new Error("No Data Transfer items or files found.");
			}
		}

		return files;
	};
	removeComponent(
		component: Vertex.NodeComponentModel.Component,
		node: Vertex.NodeComponentModel.VertexNode
	) {
		throw new Error("Method not implemented.");
	}
}

export class SaveSettingsPanelComponentSystem extends Vertex.NodeComponentModel
	.ComponentSystemBase {
	public create(): Vertex.NodeComponentModel.Component {
		return new SaveSettingsPanelComponent();
	}

	constructor() {
		super(
			"SaveSettingsPanel",
			new SaveSettingsPanelComponentView(),
			new Vertex.NodeComponentModel.EmptyComponentController()
		);
	}
}

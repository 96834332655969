import { quaternionToEuler } from "../../utilities/utils";

export class CustomRotationRenderer implements Vertex.UI.ICustomInspectorRenderer {

    constructor(targetNode: Vertex.NodeComponentModel.VertexNode) {
        this.targetNode = targetNode;
    }

    targetNode: Vertex.NodeComponentModel.VertexNode;

    row = document.createElement("div");

    roll = document.createElement("input");
    pitch = document.createElement("input");
    yaw = document.createElement("input");
    rollInputContainer = document.createElement("div");
    pitchInputContainer = document.createElement("div");
    yawInputContainer = document.createElement("div");
    rollInputLabel = document.createElement("div");
    pitchInputLabel = document.createElement("div");
    yawInputLabel = document.createElement("div");

    target: Vertex.NodeComponentModel.Component;
    property: string;

    RenderProperty(property: string, target: any): HTMLDivElement {
        this.target = target;
        this.property = property;

        this.row.classList.add("row","input-row","align-items-center");
        this.row.classList.add("control-group","xyzr-text");

        for (var i of [this.roll, this.pitch, this.yaw]) {
            i.addEventListener("change", this.inputChanged.bind(this));
            i.classList.add("mr-1");
            this.rollInputContainer.classList.add("input-container");
            this.pitchInputContainer.classList.add("input-container");
            this.yawInputContainer.classList.add("input-container");
            this.rollInputLabel.classList.add("small-input-text");
            this.pitchInputLabel.classList.add("small-input-text");
            this.yawInputLabel.classList.add("small-input-text");
            this.rollInputLabel.innerText = "R";
            this.pitchInputLabel.innerText = "P";
            this.yawInputLabel.innerText = "Y";
            i.style.minWidth = "0";
        }

        let a = target[property];
        let euler = quaternionToEuler(a[3], a[0], a[1], a[2]);

        this.roll.value = euler.roll.toFixed(3);
        this.pitch.value = euler.pitch.toFixed(3);
        this.yaw.value = euler.yaw.toFixed(3);

        this.row.innerHTML = `<div class="col-3  control-label">rotation</div>`;

        var controlValue = document.createElement("div");
        controlValue.classList.add("col");
        controlValue.classList.add("control-value");
        this.row.appendChild(controlValue);

        this.roll.classList.add("form-control","xyzr-text");
        this.roll.spellcheck = false;
        this.roll.autocomplete = "off";
        this.roll.type = "text";

        this.pitch.classList.add("form-control","xyzr-text");
        this.pitch.spellcheck = false;
        this.pitch.autocomplete = "off";
        this.pitch.type = "text";

        this.yaw.classList.add("form-control","xyzr-text");
        this.yaw.spellcheck = false;
        this.yaw.autocomplete = "off";
        this.yaw.type = "text";

        this.rollInputContainer.appendChild(this.rollInputLabel);
        this.rollInputContainer.appendChild(this.roll);
        this.pitchInputContainer.appendChild(this.pitchInputLabel);
        this.pitchInputContainer.appendChild(this.pitch);
        this.yawInputContainer.appendChild(this.yawInputLabel);
        this.yawInputContainer.appendChild(this.yaw);
        controlValue.appendChild(this.rollInputContainer);
        controlValue.appendChild(this.pitchInputContainer);
        controlValue.appendChild(this.yawInputContainer);

        this.target.onChanged.on((data: Vertex.NodeComponentModel.Component) => {
            let a = data[property];
            let euler = quaternionToEuler(a[3], a[0], a[1], a[2]);

            if(document.activeElement !== this.roll)
            {
                this.roll.value = euler.roll.toFixed(3);
            }
            if(document.activeElement !== this.pitch)
            {
                this.pitch.value = euler.pitch.toFixed(3);
            }
            if(document.activeElement !== this.yaw)
            {
                this.yaw.value = euler.yaw.toFixed(3);
            }
        });


        return this.row;
    }

    inputChanged(e: UIEvent) {
        let x = +this.roll.value;
        let y = +this.pitch.value;
        let z = +this.yaw.value;

        let q = this.EulerToQuaternion(x, y, z);

        if (!this.targetNode.HasToken) {
            console.log("Alert!", "You can't change a node you don't own!");
            return;
        }

        if (!isNaN(q.x) && !isNaN(q.y) && !isNaN(q.z) && !isNaN(q.w)) {
            this.target[this.property][0] = q.x;
            this.target[this.property][1] = q.y;
            this.target[this.property][2] = q.z;
            this.target[this.property][3] = q.w;
        }

        this.target.triggerOnChanged();    
    }

    EulerToQuaternion(roll: number, pitch: number, yaw: number): { x: number, y: number, z: number, w: number } {

        yaw = yaw * (Math.PI / 180);
        pitch = pitch * (Math.PI / 180);
        roll = roll * (Math.PI / 180);

        let cy = Math.cos(yaw * 0.5);
        let sy = Math.sin(yaw * 0.5);
        let cp = Math.cos(pitch * 0.5);
        let sp = Math.sin(pitch * 0.5);
        let cr = Math.cos(roll * 0.5);
        let sr = Math.sin(roll * 0.5);

        let x = cy * cp * sr - sy * sp * cr;
        let y = sy * cp * sr + cy * sp * cr;
        let z = sy * cp * cr - cy * sp * sr;
        let w = cy * cp * cr + sy * sp * sr;

        return { x, y, z, w };
    }


}
const HIPE_XR_APIM_DOMAIN_PROD = "https://apim-hipexr-prod-italynorth.azure-api.net/api";
const CURRENT_API_VERSION = "2024-09-09";
const DEVELOPMENT_API_VERSION = "development";

export const HIPE_XR_API_VERSION_PARAM = "api-version";
export const HIPE_XR_API_VERSION = CURRENT_API_VERSION;

export const HIPE_XR_API_DOMAIN = HIPE_XR_APIM_DOMAIN_PROD;
export const HIPE_XR_API_KEY = "234db28135254e56add705548394c4a9";

export const SYSTEM_API_ENDPOINT = "system";
export const ITEMS_API_ENDPOINT = "items";

export enum ItemType {
    System = 0,
    Setting = 1,
    UI = 2,
    Info = 3
}
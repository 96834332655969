import { TYPE_SYSTEM_TAG, PLATFORM_VERSIONING_TAG } from "./constants";
import { HevolusResourceType, ResourceUtils, TagMode } from "./resource-utilities";

const VERSIONING_JSON_FILENAME = "versioning.json";

interface IApp {
	name: string;
	version: string;
	description?: string;
}

interface IPlatform {
	name: string;
	description?: string;
	apps: IApp[];
}

interface IEngine {
	name: string;
	description?: string;
	platforms: IPlatform[];
}

export interface ISolution {
	name: string;
	version?: string;
	description?: string;
	engines: IEngine[];
}

export enum HevolusApp {
	ResourceExplorer = "resourceexplorer",
	ModelEditor = "modeleditor",
	SpaceEditor = "spaceeditor",
	AdminPortal = "adminportal",
	AugmentedStoreWeb = "augmentedstoreweb",
	AugmentedStoreUWP = "augmentedstoreuwp",
	AugmentedStoreLenovo = "augmentedstorelenovo",
	AugmentedStoreVarjo = "augmentedstorevarjo",
	RemoteSellingUwp = "remotesellinguwp",
	RemoteSellingStreaming = "remotesellingstreaming",
	HoloMuseumUwp = "holomuseumuwp",
	HoloMuseumStreaming = "holomuseumstreaming",
	HoloPrototypeUwp = "holoprototypeuwp",
	HoloPrototypeStreaming = "holoprototypestreaming",
	DeckEditor = "deckeditor",
	HevoCollaborationHub = "hevocollaborationhub",
	HevoCollaborationStreaming = "hevocollaborationstreaming",
	HevoCollaborationUwp = "hevocollaborationuwp"
}

enum HevolusSolution {
	HevolusPortal = "hevolusportal",
	AugmentedStore = "augmentedstore",
	HoloMuseum = "holomuseum",
	RemoteSelling = "remoteselling",
	HoloPrototype = "holoprototype",
	HevoCollaboration = "hevocollaboration"
}

enum BuildEngine {
	Babylon = "babylon",
	Unity = "unity"
}

enum BuildPlatform {
	Web = "web",
	UWP = "uwp",
	Android = "android",
	iOS = "ios",
	Standalone = "standalone"
}

/**
 * This method checks if provided major @version is greater than or equal to @app major version
 * @returns true if is greater than or equal, false otherwise
 */
export async function isMajorGte(version: string, app: HevolusApp, token?: string): Promise<boolean> {
	const semVerCoerce = require('semver/functions/coerce');
	const semVerMajor = require('semver/functions/major');

	version = semVerCoerce(version)?.version;
	const appVersion = await getCurrentVersion(app, token);

	return semVerMajor(version) >= semVerMajor(appVersion);
}

/**
 * This method checks if provided @version is less than the @app version
 * @returns true if is less than, false otherwise
 */
export async function isMajorLt(version: string, app: HevolusApp, token?: string): Promise<boolean> {
	const semVerCoerce = require('semver/functions/coerce');
	const semVerMajor = require('semver/functions/major');

	version = semVerCoerce(version)?.version;
	const appVersion = await getCurrentVersion(app, token);

	return semVerMajor(version) < semVerMajor(appVersion);
}

/**
 * This method access the versioning file on the backend to inspect @app current version.
 * Currently supports Space Editor only.
 * @returns current version of @app 
 */
export async function getCurrentVersion(app: HevolusApp, token?: string): Promise<string> {
	token = token || Vertex.Globals.bearerToken;
	const versioningResources = await ResourceUtils.getResourcesWithParamsAsync([TYPE_SYSTEM_TAG, PLATFORM_VERSIONING_TAG], TagMode.All, ResourceUtils.getVertexResourceType(HevolusResourceType.Versioning), false, true, false, token);
	
	if(versioningResources?.length){
		if(versioningResources.length > 1){
			console.log(`Found more than 1 versioning res.`);
		}

		const res = await ResourceUtils.getAssetFromResource(versioningResources[0].id, VERSIONING_JSON_FILENAME, token);

		if (!res.ok) {
			console.error(`Failed to fetch versioning info`, res.statusText);
			return undefined;
		}
	
		const json = await res.json() as ISolution[];
	
		switch (app) {
			case HevolusApp.SpaceEditor: {
				return json.find(s => s.name === HevolusSolution.HevolusPortal).engines.find(e => e.name === BuildEngine.Babylon).platforms.find(p => p.name === BuildPlatform.Web).apps.find(a => a.name === app).version;
			}
		}
	}
	else{
		console.error(`Failed to fetch versioning info`);
		return undefined;
	}
}
import { Utils } from "../../utilities/utils";
import { CustomActionSettingsComponent } from "../componentsystems/NodeComponents/actionsettingscomponent";
import { CustomTriggerActionsRenderer } from "./customtriggeractionsrenderer";

export class CustomTriggerableComponentItemRenderer {

    target: Vertex.NodeComponentModel.Component & { triggerActionIndexes: number[], triggerActionValues: number[] };
    actionSettings: CustomActionSettingsComponent;
    valueIndex: number;
    itemNamePromise: Promise<string>;
    iconSrcPromise: Promise<string>;
    isStarred: boolean;
    onClickStar: () => void;
    onClickDelete: () => void;
    settings?: HTMLElement

    triggerActionsRenderer: CustomTriggerActionsRenderer;

    outer = document.createElement('div');

    constructor(
        target: Vertex.NodeComponentModel.Component & { triggerActionIndexes: number[], triggerActionValues: number[] },
        actionSettings: CustomActionSettingsComponent,
        valueIndex: number,
        itemNamePromise: Promise<string>,
        iconSrcPromise: Promise<string>,
        isStarred?: boolean,
        onClickStar?: () => void,
        onClickDelete?: () => void,
        settings?: HTMLElement) {

        this.target = target;
        this.actionSettings = actionSettings;
        this.valueIndex = valueIndex;
        this.itemNamePromise = itemNamePromise;
        this.iconSrcPromise = iconSrcPromise;
        this.isStarred = isStarred;
        this.onClickStar = onClickStar;
        this.onClickDelete = onClickDelete;
        this.settings = settings;

        const itemTemplate = document.getElementById("triggerable-item-template") as HTMLTemplateElement;
        const item = itemTemplate.content.firstElementChild.cloneNode(true) as HTMLDivElement;
        
        const injectables = Array.from(item.querySelectorAll<HTMLImageElement>("img.injectable"));
        Utils.injectSvg(injectables);

        this.outer.appendChild(item);

        const label = item.querySelector(".triggerable-item-label") as HTMLParagraphElement;
        const img = item.querySelector(".triggerable-item-icon") as HTMLImageElement;
        const settingsButton = item.querySelector(".triggerable-item-settings-button") as HTMLButtonElement;
        const deleteButton = item.querySelector(".triggerable-item-delete-button") as HTMLButtonElement;
        const settingsContainer = item.querySelector(".triggerable-item-settings-container") as HTMLDivElement;
        const starButton = item.querySelector(".triggerable-item-star-button") as HTMLButtonElement;

        this.itemNamePromise.then((name) => {
            label.textContent = name;
            label.dataset.toggle = "tooltip";
            label.dataset.placement = "bottom";
            label.dataset.title = name;
            ($(label) as any).tooltip();
        });

        if (this.iconSrcPromise == null) {
            img.style.display = "none";
        }
        else {
            img.style.visibility = "hidden";
            
            this.iconSrcPromise.then((src) => {
                if (src == null) {
                    img.style.display = "none";
                }
                else {
                    img.src = src;
                    img.style.visibility = "visible";
                }
            });
        }


        if (this.settings) {
            settingsContainer.appendChild(this.settings);
        }

        settingsButton.classList.toggle("active", settingsContainer.style.display !== "none");
        settingsButton.addEventListener("click", () => {
            settingsContainer.style.display = settingsContainer.style.display === "none" ? "block" : "none";
            settingsButton.classList.toggle("active", settingsContainer.style.display !== "none");
        });

        if(onClickStar != null){
            starButton.addEventListener("click", onClickStar);
        }
        else {
            starButton.style.display = "none";
        }

        if(onClickDelete != null){
            deleteButton.addEventListener("click", onClickDelete);
        }        
        else {
            deleteButton.style.display = "none";
        }

        this.triggerActionsRenderer = new CustomTriggerActionsRenderer();
    }

    SetEnabled(enabled: boolean, message?: string) {
        this.triggerActionsRenderer.SetEnabled(enabled, message);
    }

    //Target appears to be the node but we also have just targetNode so not sure why it's needed.
    RenderItem(isStarred = null): HTMLDivElement {
        const settingsContainer = this.outer.querySelector(".triggerable-item-settings-container") as HTMLDivElement;
        const starButton = this.outer.querySelector(".triggerable-item-star-button") as HTMLButtonElement;
        const triggerActionsButton = this.outer.querySelector(".triggerable-item-trigger-actions-button") as HTMLButtonElement;
        const deleteButton = this.outer.querySelector(".triggerable-item-delete-button") as HTMLButtonElement;

        this.isStarred = isStarred ?? this.isStarred;
        starButton.classList.toggle("active", this.isStarred);

        deleteButton.disabled = this.isStarred || this.onClickDelete == null;

        const triggerActionListCardElement = this.triggerActionsRenderer.RenderActions(this.target, this.actionSettings, this.valueIndex);
        settingsContainer.appendChild(triggerActionListCardElement);

        const triggerActions = this.target.triggerActionIndexes.filter((actionIndex, index) => this.target.triggerActionValues[index] == this.valueIndex);
        const hasTriggerActions = triggerActions.length > 0;

        triggerActionsButton.classList.toggle("text-secondary", !hasTriggerActions);
        triggerActionsButton.classList.toggle("text-info", hasTriggerActions);
        triggerActionsButton.style.backgroundColor = hasTriggerActions ? "#e8f6f8" : "lightgrey";

        Utils.waitForCondition(() => triggerActionsButton.querySelector("svg") != null).then(() => {
            const actionsIconSvg = triggerActionsButton.querySelector("svg");
            const path = actionsIconSvg.querySelector("path");
            path.style.fill = "none";
            path.style.stroke = hasTriggerActions ? "#17A2B8" : "#646c77";
        });

        return this.outer;
    }

}
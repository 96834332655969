import { clone, difference } from "lodash";
import Swal from "sweetalert2";
import { HoloPrototypeModelsTabComponent } from "./holoprototypemodelstab";
import { LicenseDictionary, LicenseUtils, LicenseValidity } from "../../utilities/license-utilities";
import { VERTEXResource, HevolusResourceType, OrderMode, ResourceUtils } from "../../utilities/resource-utilities";
import { Utils, importTemplate } from "../../utilities/utils";
import { GltfUtils } from "../../utilities/gltf-utilities";
import { HOLOPROTOTYPE_CONTENTCREATOR_LICENSE, GENERIC_THUMBNAIL_IMG_URI, AUGMENTEDSTORE_CONTENTCREATOR_LICENSE, BASE_THUMB_URI, DECK_THUMB_URI, MEDIA_THUMB_URI, MODEL_THUMB_URI, ROOM_THUMB_URI, SPACE_THUMB_URI } from "../../utilities/constants";

export class ResourceExplorerPanelComponent extends Vertex.NodeComponentModel.Component {
    writeData(writer: Vertex.BinaryWriter): void {
    }
    readData(reader: Vertex.BinaryReader): void {
    }
}

interface ICachedThumb {
    url: string;
}

export class ResourceExplorerPanelComponentComponentView extends Vertex.NodeComponentModel.ComponentViewBase {

    constructor() {
        super();
    }

    readonly PRELOAD_AMOUNT: number = 30;

    allResources: VERTEXResource[] = [];
    searchedResources: VERTEXResource[] = [];
    typeFilteredResources: VERTEXResource[] = [];
    filteredResources: VERTEXResource[] = [];

    panel: HTMLDivElement;
    card: HTMLDivElement;
    id: string;

    typeFilters: HevolusResourceType[] = [HevolusResourceType.Model];
    sortFilter: boolean = false;
    
    loadedResources: number;
    searchForPublished: boolean = false;

    searchValue: string = "";

    //isPopulating: boolean = false;
    licenses: LicenseDictionary;

    editorNode: Vertex.NodeComponentModel.VertexNode;
    thumbs: Map<string, ICachedThumb> = new Map<string, ICachedThumb>();
    canvas: HTMLCanvasElement = Vertex.Globals.canvas;

    async addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        this.licenses = await LicenseUtils.validateLicenses(
                                // LicenseUtils.AUGMENTEDSTORE_CONTENTCREATOR_LICENSE, 
                                // LicenseUtils.AUGMENTEDSTORE_HOST_LICENSE, 
                                // LicenseUtils.HOLOMUSEUM_CONTENTCREATOR_LICENSE, 
                                // LicenseUtils.HOLOMUSEUM_HOST_LICENSE, 
                                // LicenseUtils.REMOTESELLING_CONTENTCREATOR_LICENSE, 
                                // LicenseUtils.REMOTESELLING_HOST_LICENSE, 
                                HOLOPROTOTYPE_CONTENTCREATOR_LICENSE); 
                                // LicenseUtils.HOLOPROTOTYPE_HOST_LICENSE,
                                // LicenseUtils.HEVOCOLLABORATION_CONTENTCREATOR_LICENSE, 
                                // LicenseUtils.HEVOCOLLABORATION_HOST_LICENSE);
        
        let runtime = Vertex.Globals.runtime;
        let space = runtime.space;

        this.editorNode = node;

        this.panel = document.querySelector(".container-overlay-left");
        this.panel.classList.remove("hidden");
        this.panel.classList.add("h-100");

        // Create Panel
        this.card = document.createElement("div");
        this.card.classList.add("card", "pointer-enable","h-55vh");
        this.card.id="resources-panel";

        let header = document.createElement("div");
        header.classList.add("nav-link", "card-header-minimizable", "dark-text","max");

        let headerIcon = document.createElement("img");
        headerIcon.src = "/img/resources-icon-active.svg";
        headerIcon.classList.add("card-header-icon");

        let headerText = document.createElement("div");
        headerText.classList.add("card-header-text");

        let searchBar = document.createElement("div");
        //add pointer blocker class to disable searching until res are loaded (kinda less than 1 sec)
        searchBar.classList.add("search-bar");

        let searchIcon = document.createElement("img");
        searchIcon.classList.add("search-bar-icon");
        searchIcon.src = "/img/search-icon.svg";
        searchBar.appendChild(searchIcon);

        let searchText = document.createElement("input");
        searchText.id = "search-text";
        searchText.addEventListener("keyup", () => {
            searchText.value = Utils.sanitizeString(searchText.value);
            this.searchValue = searchText.value;
            
            this.searchAndRenderCells();
        });

        searchText.classList.add("search-text");
        searchText.type = "text";
        searchText.placeholder = "Search Resources";

        searchBar.appendChild(searchText);

        headerText.innerText = "Resources";
        
        let body = document.createElement("div");
        body.classList.add("card-body", "p-0", "h-300px");

        let panel = document.createElement("div");
        panel.classList.add("resource-explorer-panel", "explorerContainer","h-100");
        panel.id="resource-explorer-panel";

        // Sidebar
        let leftSidebar = document.querySelector(".left-sidebar-grid");

        // Append
        leftSidebar.appendChild(this.card);
        this.card.appendChild(header);
        header.appendChild(headerIcon);
        header.appendChild(headerText);
        header.appendChild(searchBar);
        this.card.appendChild(body);
        body.appendChild(panel);   

        // Sidebar
        Utils.setupSidebarButton("ResourceExplorerPanel", "resources-panel");

        //setup drag-drop dropping from resource explorer 
        this.canvas.addEventListener("dragover", (e: DragEvent) => {
            e.preventDefault();
        });

        this.canvas.addEventListener("drop", async (e: DragEvent) => {
            e.preventDefault();

            const resource = JSON.parse(e.dataTransfer.getData("text/plain")) as VERTEXResource;
            await this.addModel(resource);
            
        });
        
        Utils.getFilteredResources(this.licenses, OrderMode.Date, false, ...this.typeFilters).then(resources => {
            //filter out audio-only media reosurces
            this.allResources = resources;

            this.searchedResources = clone(this.allResources);
            this.filteredResources = difference(this.searchedResources, this.typeFilteredResources);

            this.renderCells(true);
        });
    }

    private searchAndRenderCells() {
        this.searchedResources = this.allResources.filter(
            res => res.name.toLowerCase().includes(this.searchValue.toLowerCase()) ||
                res.tags.some(tag => tag.toLowerCase() == this.searchValue.toLowerCase()));

        this.filteredResources = difference(this.searchedResources, this.typeFilteredResources);

        this.renderCells(true);
    }

    private renderCells(reset = false) {
        let resExplorerPanel = document.querySelector("#resource-explorer-panel") as HTMLDivElement;
        
        if(this.filteredResources == null || this.filteredResources.length === 0){
            resExplorerPanel.innerHTML = "";

            return;
        }

        if(reset){
            this.loadedResources = 0;
            resExplorerPanel.innerHTML = "";
            resExplorerPanel.scrollTop = 0;
        }

        const startIndex = this.loadedResources;
        let amount = this.loadedResources + Math.min((this.filteredResources.length - startIndex), this.PRELOAD_AMOUNT);
        let moreResIndex = amount - this.loadedResources < this.PRELOAD_AMOUNT ? -1 : amount - Math.floor(this.PRELOAD_AMOUNT / 2);

        if(this.filteredResources.length >  startIndex + (amount - startIndex - 1)){
            for(let i = startIndex; i < amount; i++){
                this.drawResourceButton(this.filteredResources[i], i, resExplorerPanel, moreResIndex);
                this.loadedResources++;
            }
        }
    }

    async drawResourceButton(resource: VERTEXResource, index: number, explorerContainer: HTMLDivElement, moreResIndex: number) {
        let cellTemp = importTemplate("#temp-resource-cell");
        let cell = cellTemp.querySelector(".explorerCell") as HTMLDivElement;
        let hevoResourceType = ResourceUtils.getHevolusResourceType(resource);

        cell.dataset["type"] = hevoResourceType;

        let tagString = "";

        const img = document.createElement("img");
        img.classList.add("cell-img", "div-loading");
        img.src = GENERIC_THUMBNAIL_IMG_URI; //empty image to not show no-image error 
        
        cell.appendChild(img);

        resource.tags.forEach(tag => {
            tagString += `${tag},`;
        });

        cell.dataset["resourceId"] = resource.id;
        cell.dataset["modified"] = resource.modified;
        cell.dataset["tags"] = tagString;
        cell.dataset["name"] = resource.name;
        cell.dataset["published"] = (resource.publishedResources?.length).toString();

        cell.style.order = index.toString();

        //let publishTypeCellTab = cell.querySelector(".published-cell-tab") as HTMLDivElement;

        if (cell.dataset["published"] === "true" && this.licenses[AUGMENTEDSTORE_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid) {
            ResourceUtils.getResourceData(resource.publishedResources[0]).then(publishedRes => {
                if (publishedRes?.modified && resource.modified) {
                    let updated = false;

                    let publishedModifiedDate = new Date(publishedRes.modified);
                    let sourceModifiedDate = new Date(resource.modified);

                    if (publishedModifiedDate > sourceModifiedDate) {
                        updated = true;
                    }

                    cell.dataset.updated = updated.toString();
                }
                else {
                    console.log(`Failed to get published res info. Resource id: ${resource.id} - Published id: ${resource.publishedResources[0]}.`);
                }

                let publishedResourceIndicator = cell.querySelector(".published-resource-icon");

                // publishTypeCellTab.classList.add("cell-tab-low", "hide-tab");
                // publishTypeCellTab.innerText = "Published";
                // publishTypeCellTab.classList.add("cell-tab-gray");

                if (cell.dataset.updated === "true") {
                    //publishTypeCellTab.classList.add("cell-tab-green");
                    publishedResourceIndicator.classList.add("cell-tab-green");
                } else {
                    //publishTypeCellTab.classList.add("cell-tab-red");
                    publishedResourceIndicator.classList.add("cell-tab-red", "slow-clap");
                }
            })
        }
        else {
            cell.dataset.published = "false";
            cell.dataset.updated = "false";
        }

        let title = cell.querySelector(".cell-info-wrapper");
        let titleText = cell.querySelector(".cell-text");
        titleText.innerHTML = resource.name;

        // resource-type cell-tab and border
        const resTypeCellTab = cell.querySelector(".cell-tab") as HTMLDivElement;

        let borderClass = `${hevoResourceType.toLowerCase()}-border`;

        title.classList.add(borderClass);
        resTypeCellTab.innerText = hevoResourceType;
        
        cell.draggable = true;

        let contextMenu = this.createContextMenu(resource, cell, hevoResourceType, explorerContainer);

        // context menu
        cell.addEventListener("contextmenu", (e) => {
            e.preventDefault();
            var x = e.pageX;
            var y = e.pageY;
            this.spawnContextMenu(explorerContainer, contextMenu, resource.id, x, y);
        });

        //resource hover effects
        cell.addEventListener('mouseenter', () => {
            resTypeCellTab.classList.remove("hide-tab");
            resTypeCellTab.classList.add("show-tab");
            //publishTypeCellTab.classList.remove("hide-tab");
            //publishTypeCellTab.classList.add("show-tab");
            titleText.classList.remove("whitespace-nowrap");
            titleText.classList.add("whitespace-normal");
        });

        cell.addEventListener('mouseleave', () => {
            resTypeCellTab.classList.add("hide-tab");
            resTypeCellTab.classList.remove("show-tab");
            //publishTypeCellTab.classList.add("hide-tab");
            //publishTypeCellTab.classList.remove("show-tab");
            titleText.classList.remove("whitespace-normal");
            titleText.classList.add("whitespace-nowrap");
        });

        cell.addEventListener("click", async () => {
            await this.addModel(resource);          
        });     

        cell.addEventListener("dragstart", (e: DragEvent) => {
            e.dataTransfer.setData("text/plain", JSON.stringify(resource));
        });

        const appendedCell = explorerContainer.appendChild(cell);

        let addResourceLoadObservable = false;

        if(index == moreResIndex){
            addResourceLoadObservable = true;
        }

        this.lazyLoaderObservable(explorerContainer, appendedCell, addResourceLoadObservable);
    }

    private async addModel(resource: VERTEXResource) {
        const hasValidGltf = (await GltfUtils.validateGltf(resource, true, true)).hasValidGltf;

        if (hasValidGltf) {
            const prototypeTabComponent = this.editorNode.getComponent("HoloPrototypeModelsTab") as HoloPrototypeModelsTabComponent;

            if (prototypeTabComponent?.prototype?.models) {
                if (!prototypeTabComponent.prototype.models.includes(resource.id)) {
                    prototypeTabComponent.prototype.models.push(resource.id);
                    prototypeTabComponent.triggerOnChanged();
                }
                else{
                    Swal.fire({
                        icon: 'info',
                        title: `The model \`${resource.name}\` is already in the models list`,
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        showConfirmButton: false,
                        timer: 3000,
                        heightAuto: false,
                        width: "auto",
                        didOpen: () => {
                            Swal.getTitle().style.display = null;
                        }
                    });
                }                
            }
        }
        else{
            Swal.fire({
                icon: "warning",
                title: `The resource '${resource.name}' does not contain a valid glTF model.`,
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: true,
                heightAuto: false,
                didOpen: () => {
                    Swal.getTitle().style.display = null;
                }
            });
        }
    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
    }

    private createContextMenu(resource: VERTEXResource, cell: HTMLDivElement, hevoResourceType: HevolusResourceType,  container: HTMLDivElement) {
        let resourceContext = document.createElement("div");
        resourceContext.classList.add("right-click-menu");

        // open in new tab
        let newTabButton = document.createElement("button");
        newTabButton.classList.add("btn", "btn-secondary", "right-click-button");
        newTabButton.innerText = "Open in New Tab";
        newTabButton.addEventListener('click', async () => {
            await ResourceUtils.openResource(resource, true);
            resourceContext.remove();
        });

        resourceContext.appendChild(newTabButton);

        return resourceContext;
    }

    async spawnContextMenu(container: HTMLDivElement, resourceContext: HTMLDivElement, id: string, x, y) {
        // get existing material picker window and remove if it exists
        if (document.querySelector("#resource-context") !== null) {
            document.querySelector("#resource-context").remove();
        }

        resourceContext.id = "resource-context";

        document.body.insertBefore(resourceContext, document.body.firstChild);

        //this.editingId = id;

        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const xPosRight = windowWidth - x;
        const yPosBottom = windowHeight - y;

        const panelXPos = x + resourceContext.offsetWidth;
        const panelYPos = y + resourceContext.offsetHeight;

        if (panelXPos >= windowWidth) {
            resourceContext.style.right = xPosRight + "px";
        } else {
            resourceContext.style.left = x + "px";
        }

        if (panelYPos >= windowHeight) {
            resourceContext.style.bottom = yPosBottom + "px";
        } else {
            resourceContext.style.top = y + "px";
        }

        window.addEventListener('click', (e) => this.handleWindowClick(e, resourceContext, container));
    }

    private handleWindowClick(e: MouseEvent, resourceContext: HTMLDivElement,  container: HTMLDivElement) {
        if (!resourceContext.contains((<HTMLElement>e.target))) {
            resourceContext.remove();
        }

        container.classList.remove("disabled");
    }

    private lazyLoaderObservable(explorerContainer: HTMLDivElement, appendedCell: HTMLDivElement, addLoadResourceObservable: boolean) {
        let observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.intersectionRatio <= 0) {
                    return;
                }

                const resId = (entry.target as HTMLDivElement).dataset.resourceId;

                if (resId) {
                    this.loadResourceThumb(resId, (entry.target as HTMLDivElement));
                }

                if(addLoadResourceObservable){
                    this.renderCells();
                }

                observer.unobserve(appendedCell);
            });
        }, { root: explorerContainer, threshold: [1] });

        observer.observe(appendedCell);
    }
    
    loadResourceThumb(resourceId: string, cell: HTMLDivElement) {
        if (this.thumbs.has(resourceId)) {
            const thumb = this.thumbs.get(resourceId);

            this.setThumbnail(resourceId, cell, thumb.url);
        }
        else{
            ResourceUtils.getThumbnailBlobUrl(resourceId).then(blobUri => {
                this.setThumbnail(resourceId, cell, blobUri);
            });
        }
    }

    private setThumbnail(resourceId: string, cell: HTMLDivElement, blobUri: string) {
        let thumbUri = blobUri;

        if(!thumbUri){
            const hevoResourceType = cell.dataset["type"];

            switch(hevoResourceType){
                case HevolusResourceType.Base:{
                    thumbUri = BASE_THUMB_URI;
                    break;
                }
                case HevolusResourceType.Deck:{
                    thumbUri = DECK_THUMB_URI;
                    break;
                }
                case HevolusResourceType.Media:{
                    thumbUri = MEDIA_THUMB_URI;
                    break;
                }
                case HevolusResourceType.Model:{
                    thumbUri = MODEL_THUMB_URI;
                    break;
                }
                case HevolusResourceType.Room:{
                    thumbUri = ROOM_THUMB_URI;
                    break;
                }
                case HevolusResourceType.Space:{
                    thumbUri = SPACE_THUMB_URI;
                    break;
                }
            }
        }

        this.thumbs.set(resourceId, { url: thumbUri });

        let img = cell.querySelector(".cell-img") as HTMLImageElement;
        img.style.backgroundImage = "url(" + thumbUri + ")";
        img.style.backgroundColor =  "#a9b2c0";
        img.classList.remove("div-loading");
        img.classList.add("div-loaded");
    }
}

export class ResourceExplorerPanelComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new ResourceExplorerPanelComponent();
    }
    constructor() {
        super("ResourceExplorerPanel", new ResourceExplorerPanelComponentComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}
import { AuthManager } from "../../../auth-manager";
import { AppView } from "./appview";
import { ConstructorOf, IDependencyProvider } from "./dependency-provider";
import { className } from "../utility";

export abstract class AppBase implements IDependencyProvider {
    /** the auth manager */
    auth: AuthManager;

    private _rootNode: Element;
    private _rootView: AppView;

    /** the root HTML node that the root View will be loaded in */
    get rootNode(): Element {
        return this._rootNode;
    }

    /** the root HTML node that the root View will be loaded in */
    set rootNode(value: Element) {
        this._rootNode = value;

        if (this._rootView) {
            this._rootView.parent = this._rootNode;
        }
    }

    /** the view that will be rendered into the rootNode */
    get rootView(): AppView {
        return this._rootView;
    }

    /** the view that will be rendered into the rootNode */
    set rootView(value: AppView) {
        if (this._rootView === value)
            return;

        if (this._rootView) {
            this._rootView.destroy();
            this._rootView = null;
        }

        if (value instanceof AppView) {
            value.init();
            value.parent = this._rootNode;
            this._rootView = value;
        }
    }

    private _services: Map<ConstructorOf<object>, object> = new Map();

    /** 
     * starts the app.
     * inheriters should not override this method.
     * this method internally calls configureServices, then start,
     * then initializes the 'appView'.
     */
    async runAsync() {
        this.auth = new AuthManager();

        window.addEventListener('hashchange', this.onHashChange.bind(this));

        // register services that all apps should use
        this.registerSingleton(AuthManager, this.auth);

        // register services that the app needs
        this.configureServices();

        // run the custom app code
        this.start();

        // it is implied that start will set rootNode and rootView
        if (!this.rootNode || !this.rootView) {
            let name = className(this);
            console.warn(`[AppBase] The view '${name}' has not set the rootNode or rootView in it's start method`);
        }
    }

    /**
     * code to run when the app is ready to start.
     * this should be used to set the rootView and rootNode.
     */
    protected abstract start();

    /**
     * invoked before 'start'. this should be used to register services for dependency injection, 
     * via the 'registerSingleton' method.
     */
    protected abstract configureServices();

    getService<TService extends object>(serviceType: ConstructorOf<TService>, optional?: boolean): TService {
        let service = this._services.get(serviceType);
        if (service instanceof serviceType === false) {
            if (!optional) {
                console.error("Failed to load non-optional dependency", {
                    typeName: (serviceType && serviceType.name) || "<unknown or not a class>",
                    expectedType: serviceType,
                });

                throw new Error(`Failed to load non-optional dependency`);
            }
            else
                return null;
        }
        else {
            return service as TService;
        }
    }

    registerSingleton<TService extends object>(serviceType: ConstructorOf<TService>, instance?: TService) {
        instance = instance || new serviceType();
        this._services.set(serviceType, instance);
    }

    private onHashChange(event: HashChangeEvent) {
        let hash = location.hash;
        if (!hash)
            return;
        hash = hash.substr(1);

    }
}
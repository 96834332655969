import { UserProfileUtils } from "../../utilities/user-profile-utilities";
import { CODE, XR_COPILOT_API_DOMAIN } from "./common";

export const STORAGE_API_ENDPOINT = "api/storage";
export const INDEXING_API_ENDPOINT = "indexing";

export interface IndexingOptions{
    regenerateIndex: boolean;
}

export interface ResourceInfo{
    resourceId: string;
    companyId: string;
    hasData: boolean;
    hasAI: boolean;
    name?: string;
}

export class StorageApi {

    static async getFileUrl(fileName: string, resourceId?: string){
        if(!fileName){
            return;
        }
        
        resourceId = resourceId ?? Vertex.Globals.spaceId;

        if(!resourceId){
            return;
        }

        let uri = `${XR_COPILOT_API_DOMAIN}/${STORAGE_API_ENDPOINT}/${resourceId}/${fileName}/url`;

        //TODO: now we add auth token reading a const value; will be replaced with actual token later.
        uri += `?code=${CODE}`;

        try{
            const response = await fetch(uri);

            if(response.ok){
                return await response.text();
            }
            else{
                console.log(`Failed to fetch ${fileName} url from resource ${resourceId}`, response.status + response.statusText);
            }
        }
        catch(e){
            console.log(`Failed to fetch ${fileName} url from resource ${resourceId}`, e);
        }
    }

    static async getFile(fileName: string, resourceId?: string){
        if(!fileName){
            return;
        }
        
        resourceId = resourceId ?? Vertex.Globals.spaceId;

        if(!resourceId){
            return;
        }

        let uri = `${XR_COPILOT_API_DOMAIN}/${STORAGE_API_ENDPOINT}/${resourceId}/${fileName}`;

        //TODO: now we add auth token reading a const value; will be replaced with actual token later.
        uri += `?code=${CODE}`;
        
        try{
            const response = await fetch(uri);

            if(response.ok){
                return await response.blob();
            }
            else{
                console.log(`Failed to fetch ${fileName} from resource ${resourceId}`, response.status + response.statusText);
            }
        }
        catch(e){
            console.log(`Failed to fetch ${fileName} from resource ${resourceId}`, e);
        }
    }

    static async listFiles(resourceId?: string){
        resourceId = resourceId ?? Vertex.Globals.spaceId;

        if(!resourceId){
            return;
        }

        let uri = `${XR_COPILOT_API_DOMAIN}/${STORAGE_API_ENDPOINT}/${resourceId}`;

        //TODO: now we add auth token reading a const value; will be replaced with actual token later.
        uri += `?code=${CODE}`;

        try{
            const response = await fetch(uri);

            if(response.ok){
                return await response.json() as string[];                    
            }
            else{
                console.log(`Failed to fetch file list for resource ${resourceId}`, response.status+response.statusText);
            }
        }
        catch(e){
            console.log(`Failed to fetch file list for resource ${resourceId}`, e);
        }
    }

    static async uploadFile(fileName: string, bodyContent: BodyInit, resourceId?: string){
        if(!fileName || !bodyContent){
            return;
        }
        
        resourceId = resourceId ?? Vertex.Globals.spaceId;

        if(!resourceId){
            return;
        }

        let uri = `${XR_COPILOT_API_DOMAIN}/${STORAGE_API_ENDPOINT}/${resourceId}/${fileName}`;

        //TODO: now we add auth token reading a const value; will be replaced with actual token later.
        uri += `?code=${CODE}`;
        
        try{
            const response = await fetch(uri, {
                method: "POST",
                headers: {
                    "Content-Type": "application/octet-stream"
                },
                body: bodyContent
            });

            if(response.ok){
                return true;
            }
            else{
                console.log(`Failed to upload ${fileName} to resource ${resourceId}`, response.status + response.statusText);
            }
        }
        catch(e){
            console.log(`Failed to upload ${fileName} to resource ${resourceId}`, e);
        }
    }

    static async deleteFile(fileName: string, resourceId?: string){
        if(!fileName){
            return;
        }
        
        resourceId = resourceId ?? Vertex.Globals.spaceId;

        if(!resourceId){
            return;
        }

        let uri = `${XR_COPILOT_API_DOMAIN}/${STORAGE_API_ENDPOINT}/${resourceId}/${fileName}`;

        //TODO: now we add auth token reading a const value; will be replaced with actual token later.
        uri += `?code=${CODE}`;
        
        try{
            const response = await fetch(uri, {
                method: "DELETE"
            });

            if(response.ok){
                return true;
            }
            else{
                console.log(`Failed to delete ${fileName} from resource ${resourceId}`, response.status + response.statusText);
            }
        }
        catch(e){
            console.log(`Failed to delete ${fileName} from resource ${resourceId}`, e);
        }
    }

    static async deleteResource(resourceId?: string){
        resourceId = resourceId ?? Vertex.Globals.spaceId;

        if(!resourceId){
            return;
        }

        let uri = `${XR_COPILOT_API_DOMAIN}/${STORAGE_API_ENDPOINT}/${resourceId}`;

        //TODO: now we add auth token reading a const value; will be replaced with actual token later.
        uri += `?code=${CODE}`;

        try{
            const response = await fetch(uri, {
                method: "DELETE"
            });

            if(response.ok){
                return true;
            }
            else{
                console.log(`Failed to delete resource ${resourceId}`, response.status + response.statusText);
            }
        }
        catch(e){
            console.log(`Failed to delete resource ${resourceId}`, e);
        }
    }

    static async launchIndexing(options: IndexingOptions, resourceId?: string, companyId?: string, token?: string){
        if(!options){
            return;
        }

        resourceId = resourceId ?? Vertex.Globals.spaceId;
        token = token ?? Vertex.Globals.bearerToken;
        companyId = companyId ?? await UserProfileUtils.getTenantId(token);

        if(!resourceId || !companyId){
            return;
        }

        let uri = `${XR_COPILOT_API_DOMAIN}/${STORAGE_API_ENDPOINT}/${INDEXING_API_ENDPOINT}/${resourceId}/${companyId}`;

        //TODO: now we add auth token reading a const value; will be replaced with actual token later.
        uri += `?code=${CODE}`;

        try{
            const response = await fetch(uri, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(options)
            });

            if(response.ok){
                return true;
            }
            else{
                console.log(`Failed to launch indexing for resource ${resourceId}`, response.status + response.statusText);
            }
        }
        catch(e){
            console.log(`Failed to launch indexing for resource ${resourceId}`, e);
        }
    }

    static async deleteIndexing(resourceId?: string, companyId?: string, token?: string){
        resourceId = resourceId ?? Vertex.Globals.spaceId;
        token = token ?? Vertex.Globals.bearerToken;
        companyId = companyId ?? await UserProfileUtils.getTenantId(token);

        if(!resourceId || !companyId){
            return;
        }

        let uri = `${XR_COPILOT_API_DOMAIN}/${STORAGE_API_ENDPOINT}/${INDEXING_API_ENDPOINT}/${resourceId}/${companyId}`;

        //TODO: now we add auth token reading a const value; will be replaced with actual token later.
        uri += `?code=${CODE}`;

        try{
            const response = await fetch(uri, {
                method: "DELETE"
            });

            if(response.ok || (response.status === 400 && (await response.text()).includes("not found"))){
                return true;
            }
            else{
                console.log(`Failed to delete indexing for resource ${resourceId}`, response.status + response.statusText);
            }
        }
        catch(e){
            console.log(`Failed to delete indexing for resource ${resourceId}`, e);
        }
    }
}

const XR_COPILOT_API_DOMAIN_DEV = "https://func-xrcopilot-dev-swedencentral.azurewebsites.net";
const XR_COPILOT_API_DOMAIN_PROD = "https://func-xrcopilot-prod-swedencentral.azurewebsites.net";

const XR_COPILOT_APIM_DOMAIN_PROD = "https://apim-hipexr-prod-italynorth.azure-api.net/api";
const CURRENT_API_VERSION = "2024-09-16";
const DEVELOPMENT_API_VERSION = "development";

const CODE_DEV = "xF8T1JE7z_pUNS3GYKkPpB0KZmhzV2AGOjSs_LiYa_amAzFuSl4oDQ==";
const CODE_PROD = "aBpkvuy7rm_Sj4OLo4kPrefFkywlCdsaYpZVTi-le7MIAzFuQDC14w==";

export const XR_COPILOT_API_DOMAIN = XR_COPILOT_APIM_DOMAIN_PROD;
export const CODE = CODE_PROD;

export const XR_COPILOT_API_VERSION_PARAM = "api-version";

export const XR_COPILOT_API_VERSION = CURRENT_API_VERSION;
export const XR_COPILOT_API_KEY = "a9aa49a3f2544daea172e5c279876fce";
import { AugmentedStoreAssembly } from "../../../../AugmentedStoreAssembly";
import { ColliderEvent } from "../../../utilities/constants";
import { Utils } from "../../../utilities/utils";
import { ITriggerActionComponent } from "./actionsettingscomponent";
import { IAwaitableComponent, CustomGltfLoadingHandlerComponent } from "./gltfLoadingHandlerComponent";
import { CustomMediaTextureComponent } from "./mediaTexture";
import { CustomText2DComponent } from "./text2dcomponent";


export class CustomVolumeTriggerComponent extends AugmentedStoreAssembly.VolumeTriggerComponent implements /*IAwaitableComponent,*/ ITriggerActionComponent{
    isTriggerActionComponent: boolean = true;

    constructor() {
        super();
    }

    actionSettingsComp: AugmentedStoreAssembly.ActionSettingsComponent;

    get actionSettings(): AugmentedStoreAssembly.ActionSettingsComponent {
        if (this.actionSettingsComp) {
            return this.actionSettingsComp;
        }

        let runtime = Vertex.Globals.runtime as Vertex.VertexRuntime;
        runtime.space.nodes.forEach(node => {
            const comp = node.getComponent("ActionSettings") as AugmentedStoreAssembly.ActionSettingsComponent;
            if (comp) {
                this.actionSettingsComp = comp;
            }
        });

        return this.actionSettingsComp;
    }

    // isAwaitableComponent: boolean = true;
    // funcOnLoad;

    // public renderingComp: Vertex.NodeComponentModel.GltfModelComponent | CustomMediaTextureComponent | CustomText2DComponent;
    // public renderingMesh: BABYLON.AbstractMesh;

    // gltfLoadingHandlerComp: CustomGltfLoadingHandlerComponent;
    
    // async onLoad(): Promise<void> {
    //     return await this.funcOnLoad();
    // }

    public onActionTriggered = ((actionData: AugmentedStoreAssembly.ActionMessage) => {
        const actionIndex = this.actionIndexes.indexOf(actionData.actionIndex);
        
        if (actionIndex !== -1) {
            const state = this.actionValues[actionIndex];
            this.enabled = Boolean(state);
            this.triggerOnChanged();
        }
    }).bind(this);

    async getComponentTriggerableValueNames(): Promise<string[]> {
        return [ Utils.getHumanReadableString(ColliderEvent[ColliderEvent.OnTriggerEnter]) ];
    }
}

export class VolumeTriggerComponentView extends Vertex.NodeComponentModel.ComponentViewBase {
    constructor() {
        super();
    }

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        let comp = component as CustomVolumeTriggerComponent;

        let box = BABYLON.MeshBuilder.CreateBox("volumeTrigger", {size: 1}, Vertex.Globals.runtime.scene);

        //create the material for the box, and set its color to be a transparent green (like Collider bounds in Unity)
        let material = new BABYLON.PBRMaterial("volumeTriggerMaterial", Vertex.Globals.runtime.scene);
        material.alpha = 0.375;
        material.albedoColor = new BABYLON.Color3(0, 1, 0);
        material.metallic = 0;
        material.roughness = 0.5;

        box.material = material;

        box.parent = node.viewNode;

        Utils.waitForCondition(_ => Array.from<Vertex.NodeComponentModel.VertexNode>(Vertex.Globals.runtime.space.nodes.values()).find((node) => node.components.includes("ActionSettings")) != null).then(_ => {
            comp.actionSettings.onActionTriggered.on(comp.onActionTriggered);
            comp.onRemoved.on(() => comp.actionSettings.onActionTriggered.off(comp.onActionTriggered));

            comp.onChanged.on(() => {
                
            });

            comp.triggerOnChanged();

            let onActionDeleted = (deletedActionIndex) => {
                if (comp.actionIndexes.includes(deletedActionIndex)) {
                    let actionIndex = comp.actionIndexes.indexOf(deletedActionIndex);
                    comp.actionIndexes.splice(actionIndex, 1);
                    comp.actionValues.splice(actionIndex, 1);
                }

                for(let i = 0; i < comp.actionIndexes.length; i++) {
                    if(comp.actionIndexes[i] > deletedActionIndex) {
                        comp.actionIndexes[i] = comp.actionIndexes[i]-1;
                    }
                }

                comp.triggerActionValues = comp.triggerActionValues.filter((val, idx) => comp.triggerActionIndexes[idx] != deletedActionIndex);
                comp.triggerActionIndexes = comp.triggerActionIndexes.filter(idx => idx != deletedActionIndex);

                for(let i = 0; i < comp.triggerActionIndexes.length; i++) {
                    if(comp.triggerActionIndexes[i] > deletedActionIndex) {
                        comp.triggerActionIndexes[i] = comp.triggerActionIndexes[i]-1;
                    }
                }

                comp.triggerOnChanged();
            };

            Vertex.Globals.event.on("ActionSettings:ActionDeleted", onActionDeleted);


            component.onRemoved.on(() => Vertex.Globals.event.off("ActionSettings:ActionDeleted", onActionDeleted));
        });
    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {

    }
}

export class VolumeTriggerComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new CustomVolumeTriggerComponent();
    }

    constructor() {        
        super("VolumeTrigger", new VolumeTriggerComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}
import Swal from "sweetalert2";
import { DEFAULT_STP2GLTF_TOLERANCE_IN_MM, DEFAULT_STP2GLTF_ANGULAR_PRECISION_IN_DEG, MEDIA_CONVERTER_LOGIC_APP_URL, ADVANCED_3D_CONVERTER_EXTENSIONS, MEDIA_VALID_EXTENSIONS, HEVOLUS_HVERSE_3DCONVERTER_LICENSE, HEVOLUS_HVERSE_3DOPTIMIZER_LICENSE, STEP2GLTF_CONVERTER_LICENSE, SUPPORTED_AUDIO_FORMATS, SUPPORTED_VIDEO_FORMATS, VERTX_STP_CONVERTER_EXTENSIONS, DEFAULT_3D_CONVERTER_EXTENSIONS, MEDIA_CONVERSION_STATUS_FILENAME, MEDIA_CONVERSION_STATUS_POLLING_TIME_IN_MS, MODEL_VALID_EXTENSIONS, MODEL_3D_VALID_EXTENSIONS } from "./constants";
import { OptimizationUtils } from "./optimization-utilities";
import { LicenseUtils, LicenseValidity } from "./license-utilities";
import { Utils } from "./utils";
import { FileMediaType, ResourceUtils } from "./resource-utilities";

export interface MediaConversionParams {
    token: string,
    assetId: string,
    fileName: string
}

export interface MediaConversionResult {
    fileName: string,
    response: MediaConversionResponse
}

export enum MediaConversionResponse {
    Started,
    Invalid,
    Unavailable,
    None
}

export interface GltfConversionParams {
    tolerance?: number,
    angularPrecision?: number,
    normalAngle?: number
}

export interface ArrResultJson {
    conversionId: string;
    result: string;
}

export interface Error {
    message: string;
}

export interface ArrStatusRespose {
    Status: string; // Cancelled | Failed | NotStarted | Running ! Succeeded
    Error: Error;
}

export enum ArrStatus { //not sure yet in use

    Cancelled = 'Cancelled',
    Failed = 'Failed',
    NotStarted = 'NotStarted',
    Running = 'Running',
    Succeeded = 'Succeeded'
}
export enum ArrStatusPulling {
    Created = 'Created',
    Failure = 'Failure',
    NotStarted = 'NotStarted',
    Running = 'Running',
    Succeeded = 'Succeeded'
}

export class FileConversionStatus
{
    public fileName: string;
    public status : Status;
}

export enum Status
{
    Pending = 0,
    Completed = 1,
    Failed = 2,
    Canceled = 3,
    None = 4
}

export class ConversionUtils {
    
    static async convert2Arr(sourceResourceId: string): Promise<Response> {

        let bodyContent = null;// JSON.stringify({ });

        let res = await fetch(`https://${Vertex.Globals.vertexStackUrl}/azureremoting/api/content/${sourceResourceId}/convert`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${Vertex.Globals.bearerToken}`,
                "Content-Type": "application/json"
            },
            body: bodyContent
        })

        return res;
    }

    static async getArrConvertionStatus(sourceResourceId: string): Promise<Response> {

        let res = await fetch(`https://${Vertex.Globals.vertexStackUrl}/azureremoting/api/content/${sourceResourceId}/status`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${Vertex.Globals.bearerToken}`,
                "Content-Type": "application/json"
            }
        })

        return res;
    }

    static async convert2Aoa(resourceId: string, extension: string, token?: string): Promise<Response> {
        let response: Promise<Response> = null;

        if (resourceId && extension) {
            switch (extension) {
                case "gltf" || "glb": {
                    response = ConversionUtils.convertGltf2Aoa(resourceId, token);
                    break;
                }
                case "fbx": {
                    //To be implemented
                    break;
                }
                case "obj": {
                    //To be implemented
                    break;
                }
                case "ply": {
                    //To be implemented
                    break;
                }
            }
        }

        return response;
    }

    static async convertGltf2Aoa(resourceId: string, token?: string): Promise<Response> {
        token = token || Vertex.Globals.bearerToken;

        let res = await fetch(`https://${Vertex.Globals.vertexStackUrl}/task/gltftoaoa/convert/${resourceId}`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })

        return res;
    }

    static async convert2Gltf(resourceId: string, extension: string, params?: GltfConversionParams): Promise<Response> {
        let response: Promise<Response> = null;

        if (resourceId && extension) {

            switch (extension) {
                case "fbx": {
                    response = ConversionUtils.convertFbx2Gltf(resourceId);
                    break;
                }
                case "obj": {
                    response = ConversionUtils.convertObj2Gltf(resourceId);
                    break;
                }
                case "stp": {
                    response = ConversionUtils.convertStp2Gltf(resourceId, params);
                    break;
                }
            }
        }

        return response;
    }

    static async convertObj2Gltf(resourceId: string): Promise<Response> {

        let res = await fetch(`https://${Vertex.Globals.vertexStackUrl}/task/obj2gltf/convert/${resourceId}`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${Vertex.Globals.bearerToken}`
            }
        })

        return res;
    }

    static async convertFbx2Gltf(resourceId: string): Promise<Response> {

        let res = await fetch(`https://${Vertex.Globals.vertexStackUrl}/task/fbx2gltf/convert/${resourceId}`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${Vertex.Globals.bearerToken}`
            }
        })

        return res;
    }

    static async convertStp2Gltf(resourceId: string, params?: GltfConversionParams): Promise<Response> { 
        const bodyContent =
        {
            Tolerance: params?.tolerance ?? DEFAULT_STP2GLTF_TOLERANCE_IN_MM,
            AngularPrecision: params?.angularPrecision ?? DEFAULT_STP2GLTF_ANGULAR_PRECISION_IN_DEG
        };

        let response = await fetch(`https://${Vertex.Globals.vertexStackUrl}/task/steptogltf/convert/${resourceId}`, {  
            method: "POST",
            headers: {
                "Authorization": `Bearer ${Vertex.Globals.bearerToken}`,
                "Content-type": "application/json"
            },
            body: JSON.stringify(bodyContent)
        });

        return response;
    }

    static async convertPdfFiles(resourceId: string): Promise<Response> {
        let res = await fetch(`https://${Vertex.Globals.vertexStackUrl}/task/pdftopng/convert/${resourceId}`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${Vertex.Globals.bearerToken}`
            }
        });

        return res;
    }


    static async convertMedias(data: MediaConversionParams[], notify: boolean = false): Promise<MediaConversionResult[]> {
        let results: MediaConversionResult[] = [];

        for (let i = 0; i < data.length; i++) {
            results.push(await this.convertMedia(data[i], notify));
        }

        const assetId = data[0]?.assetId ?? Vertex.Globals.spaceId;
        const files = data.map(d => d.fileName);
        const statuses = new Array(data.length).fill(Status.Pending);

        await ConversionUtils.updateMediaConversionStatuses(assetId, files, statuses);

        return results;
    }

    /**
     * Utility to add media in Pending status (only) in the media conversion status json 
     * @param assetId 
     * @param files 
     * @param statuses 
     * @returns true if the json is succesfully updated, false otherwise
     */
    static async updateMediaConversionStatuses(assetId: string, files: string[], statuses: Status[]): Promise<boolean>
    {
        let result = true;

        assetId = assetId ?? Vertex.Globals.spaceId;

        let mediaConversionStatus: FileConversionStatus[] = [];
        let mediaConversionStatusResponse: Response;

        mediaConversionStatusResponse = await ResourceUtils.getAssetFromResource(assetId, MEDIA_CONVERSION_STATUS_FILENAME);

        if(mediaConversionStatusResponse?.ok){
            mediaConversionStatus = await mediaConversionStatusResponse.json();

            if(mediaConversionStatus){
                for(let i = 0; i < files.length; i++){
                    const fileName = Utils.getFileBaseName(files[i]);
                    const fileExt = Utils.getFileExtension(files[i], true);
                    const fileType = ResourceUtils.getMediaType(fileExt);

                    const index = mediaConversionStatus.findIndex(c => {
                        const name = Utils.getFileBaseName(c.fileName);
                        const ext = Utils.getFileExtension(c.fileName);
                        
                        if(fileName === name){
                            if(fileType === FileMediaType.Audio){
                                return SUPPORTED_AUDIO_FORMATS.includes(ext);
                            }
                            
                            if(fileType === FileMediaType.Video){
                                return SUPPORTED_VIDEO_FORMATS.includes(ext);
                            }                            
                        }
                    });

                    if(index !== -1){
                        mediaConversionStatus[index].status = statuses[i];
                    }
                    else{
                        mediaConversionStatus.push({fileName: files[i], status: statuses[i]});
                    }
                }
            }
        }
        else{
            for(let i = 0; i < files.length; i++){
                mediaConversionStatus.push({fileName: files[i], status: statuses[i]});
            }
        }
        
        const response = await ResourceUtils.postAssetToResource(MEDIA_CONVERSION_STATUS_FILENAME, assetId, JSON.stringify(mediaConversionStatus));

        if(!response.ok){
            console.log(`Failed to update ${MEDIA_CONVERSION_STATUS_FILENAME}`);
            result = false;
        }

        return result;
    }

    static async convertMedia(data: MediaConversionParams, notify: boolean = false): Promise<MediaConversionResult> {
        let result: MediaConversionResult = { fileName: data.fileName, response: MediaConversionResponse.None };

        const invalidCharRegex = new RegExp(/[^- ._a-zA-Z0-9]/g);
        const hasInvalidChar = invalidCharRegex.test(data.fileName);

        if (!hasInvalidChar) {
            const response = await fetch(MEDIA_CONVERTER_LOGIC_APP_URL, {
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                result.response = MediaConversionResponse.Started;

                if (notify) {
                    await Swal.fire({
                        icon: "success",
                        title: `Media conversion started!`,
                        text: `<b>Please check this resource again in 5 minutes. You will find converted media if succeded.</b>`,
                        showConfirmButton: true,
                        heightAuto: false
                    });
                }
            } else {
                result.response = MediaConversionResponse.Unavailable;

                if (notify) {
                    Swal.fire({
                        icon: "error",
                        title: `Media conversion not available at the moment. Please try again later.`,
                        showConfirmButton: true,
                        heightAuto: false
                    });
                }
            }
        } else {
            result.response = MediaConversionResponse.Invalid;

            if (notify) {
                await Swal.fire({
                    icon: 'error',
                    title: 'Conversion Error',
                    text: "Media files name must contain only alpha-numerical characters and hyphens or underscores, please rename it and upload it again!",
                    showConfirmButton: true,
                    heightAuto: false
                });
            }
        }

        return result;
    }

    static async isConverting(file: string, resourceId?: string){
        let result = false;

        resourceId = resourceId ?? Vertex.Globals.spaceId;

        const extension = Utils.getFileExtension(file, true);
        const mediaType = ResourceUtils.getMediaType(extension);

        if(mediaType === FileMediaType.Audio || mediaType === FileMediaType.Video){
            const conversionStatus = await this.getMediaConversionStatus(file, resourceId);
            return conversionStatus.status === Status.Pending;
        }
        else if(MODEL_3D_VALID_EXTENSIONS.includes(extension)){
            //TODO:
        }

        return result;
    }

    static async getMediaConversionStatus(file: string, resourceId?: string): Promise<FileConversionStatus> {
        resourceId = resourceId ?? Vertex.Globals.spaceId;
        
        const extension = Utils.getFileExtension(file, true);
        const fileName = Utils.getFileBaseName(file);
        const mediaType = ResourceUtils.getMediaType(extension);

        let mediaConversionStatus: FileConversionStatus[];
        let mediaConversionStatusResponse: Response;

        mediaConversionStatusResponse = await ResourceUtils.getAssetFromResource(resourceId, MEDIA_CONVERSION_STATUS_FILENAME);

        if(mediaConversionStatusResponse?.ok){
            mediaConversionStatus = await mediaConversionStatusResponse.json();

            const conversionStatus = mediaConversionStatus.find(c => {
                const name = Utils.getFileBaseName(c.fileName);
                const ext = Utils.getFileExtension(c.fileName);

                if(fileName === name){
                    if(mediaType === FileMediaType.Audio){
                        return SUPPORTED_AUDIO_FORMATS.includes(ext);
                    }
                    
                    if(mediaType === FileMediaType.Video){
                        return SUPPORTED_VIDEO_FORMATS.includes(ext);
                    }                            
                }
            });

            if(conversionStatus){
                return conversionStatus;
            }
        }

        return {fileName: file, status: Status.None};
    }

    /**
     * Wait that all conversions completed and give back the result for all of them.
     * You must call this method ASAP from when you launch the conversion
     * @param mediaConversions 
     */
    static async waitMediaConversionCompletion(mediaFiles: string[], resourceId?: string): Promise<FileConversionStatus[]>{
        let result: FileConversionStatus[] = [];
        let mediaConversionStatus: FileConversionStatus[];
        let mediaConversionStatusResponse: Response;

        resourceId = resourceId ?? Vertex.Globals.spaceId;

        //here we wait that all started media conversions complete
        await Utils.waitForConditionAsync(async _ => {
            mediaConversionStatusResponse = await ResourceUtils.getAssetFromResource(resourceId, MEDIA_CONVERSION_STATUS_FILENAME);

            if(mediaConversionStatusResponse?.ok){
                mediaConversionStatus = await mediaConversionStatusResponse.json();

                if(mediaConversionStatus?.length){
                    return mediaConversionStatus.filter(s => { 
                        if(mediaFiles.includes(s.fileName) && s.status === Status.Pending){
                            return s;
                        }
                    }).length === 0;
                }
                else{
                    return false;
                }
            }
            else{
                return false;
            }
        }, MEDIA_CONVERSION_STATUS_POLLING_TIME_IN_MS);

        for(let i = 0; i < mediaFiles.length; i++){
            let status = mediaConversionStatus?.find(s => s.fileName === mediaFiles[i])?.status ?? Status.Failed;       

            result.push({fileName: mediaFiles[i], status: status});
        }

        return result;
    }    

    static async convertFiles(files: string[], waitForCompletion: boolean = false, isOptimizationEnabled: boolean = false,  uploadOptimizationResultToVertexResource: boolean = false): Promise<FileConversionStatus[]> {
        const licenses = await LicenseUtils.validateLicenses(HEVOLUS_HVERSE_3DCONVERTER_LICENSE, HEVOLUS_HVERSE_3DOPTIMIZER_LICENSE, STEP2GLTF_CONVERTER_LICENSE);
        const has3DConverterLicense = licenses[HEVOLUS_HVERSE_3DCONVERTER_LICENSE] === LicenseValidity.Valid;
        const has3DOptimizerLicense = licenses[HEVOLUS_HVERSE_3DOPTIMIZER_LICENSE] === LicenseValidity.Valid;
        const hasStpConverterLicense = licenses[STEP2GLTF_CONVERTER_LICENSE] === LicenseValidity.Valid;

        const modelFile = files.find(file => ADVANCED_3D_CONVERTER_EXTENSIONS.includes(Utils.getFileExtension(file)));
        const mediaFiles = files.filter(file => SUPPORTED_AUDIO_FORMATS.includes(Utils.getFileExtension(file)) || SUPPORTED_VIDEO_FORMATS.includes(Utils.getFileExtension(file)));
        
        isOptimizationEnabled = isOptimizationEnabled && has3DOptimizerLicense && modelFile != null;
        
        const result: FileConversionStatus[] = [];

        if(mediaFiles?.length) {
            const mediaConversionParams: MediaConversionParams[] = [];
            mediaFiles.forEach(file => mediaConversionParams.push({ token: Vertex.Globals.bearerToken, assetId: Vertex.Globals.spaceId, fileName: file })); 

            const mediaConversionResults = await ConversionUtils.convertMedias(mediaConversionParams, false);

            for(let i = 0; i < mediaConversionResults.length; i++){
                const mediaResult = mediaConversionResults[i];
                result.push({ fileName: mediaResult.fileName, status: mediaResult.response === MediaConversionResponse.Started ? Status.Pending : Status.Failed });
            }

            const startedConversions = mediaConversionResults.filter(vcr => vcr.response === MediaConversionResponse.Started);
            const unavailableConversions = mediaConversionResults.filter(vcr => vcr.response === MediaConversionResponse.Unavailable);
            const invalidConversions = mediaConversionResults.filter(vcr => vcr.response === MediaConversionResponse.Invalid);

            let startedMsg = "";
            let unavailableMsg = "";
            let invalidMsg = "";

            for (let i = 0; i < startedConversions.length; i++) {
                startedMsg += `"${startedConversions[i].fileName}"<br>`;
            }

            for (let i = 0; i < unavailableConversions.length; i++) {
                unavailableMsg += `"${unavailableConversions[i].fileName}"<br>`;
            }

            for (let i = 0; i < invalidConversions.length; i++) {
                invalidMsg += `"${invalidConversions[i].fileName}"<br>`;
            }

            let feedbackMsg = "";

            if (startedMsg.length > 0) {
                //feedbackMsg += `->->->->->->->->->->->->->->->->->->->->->->->-><br>`;
                feedbackMsg += `<b>Conversion started for:</b><br><br>${startedMsg}<br>
                    <b>You will be notified when convertion ends</b><br>`;
            }

            if (invalidMsg.length > 0) {
                //feedbackMsg += `<br>->->->->->->->->->->->->->->->->->->->->->->->-><br>`;
                feedbackMsg += `<b>Invalid char in file name(s) below:</b><br><br>${invalidMsg}<br>
                    <b>File names must contain only alpha-numerical characters (az AZ 09), hyphens or underscores</b><br>`;
            }

            if (unavailableMsg.length > 0) {
                //feedbackMsg += `<br>->->->->->->->->->->->->->->->->->->->->->->->-><br>`;
                feedbackMsg += `<b>Media conversion not available at the moment for:</b><br><br>
                    ${unavailableMsg}<br><br>
                    <b>Please try again later</b>`;
            }

            await Swal.fire({
                icon: "info",
                title: "Media Conversion",
                html: feedbackMsg,
                heightAuto: false
            });
        }

        if(modelFile){
            if(has3DConverterLicense || has3DOptimizerLicense) {
                const status = await OptimizationUtils.openOptimizationPanel(true, isOptimizationEnabled, modelFile, waitForCompletion, uploadOptimizationResultToVertexResource);
                
                result.push({ fileName: modelFile, status: status });
            }
            else{
                const extension = Utils.getFileExtension(modelFile)
                let title = '';
                let html = '';
                let icon;

                if((VERTX_STP_CONVERTER_EXTENSIONS.includes(extension) && !hasStpConverterLicense) || (ADVANCED_3D_CONVERTER_EXTENSIONS.includes(extension) && !DEFAULT_3D_CONVERTER_EXTENSIONS.includes(extension))){
                    title = "Upgrade to 3D Converter Pro to upload and convert more formats!";
                    html = '';
                    icon = 'warning';

                    result.push({ fileName: modelFile, status: Status.Failed });
                }
                else{
                    let convertTask = ConversionUtils.convert2Gltf(Vertex.Globals.spaceId, extension);
                    
                    if(convertTask){
                        const response = await convertTask;

                        if (response.ok) {
                            title = "The model has been converted";
                            html = '';
                            icon = 'success';

                            result.push({ fileName: modelFile, status: Status.Completed });
                        }
                        else{
                            title = "The model has not been converted";
                            html = '';
                            icon = 'error';

                            result.push({ fileName: modelFile, status: Status.Failed });
                        }
                    }
                    else{
                        title = "The model has not been converted";
                        html = '';
                        icon = 'error';

                        result.push({ fileName: modelFile, status: Status.Failed });
                    }
                }

                await Swal.fire({
                    icon: icon,
                    title: title,
                    html: html,
                    showConfirmButton: true,
                    heightAuto: false
                });
            }
        }

        return result;
    }
}

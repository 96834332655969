import { VERTEXResource } from "../../utilities/resource-utilities";
import ServiceBase from "../service-base";

export class ResourceApi extends ServiceBase {

    constructor(token: string, baseUrl: string) {
        super(token, `${baseUrl}/core/resource/`);
    }

    async uploadFile(id:string, fileName: string, file: File): Promise<boolean> {
        const response = await this.makeRequestExtended(`${id}/${fileName}`, 'POST',file, {
            "Content-Type": "application/octet-stream"
        });        

        if(response.ok){
            return response.ok;
        }

        throw new Error(response.statusText);
    }

    
    async getResource(id: string): Promise<VERTEXResource> {
        const response = await this.makeRequestExtended(`${id}`);

        if (response.ok) {
            const res = await response.json() as VERTEXResource;
            return res;
        }

        throw new Error(response.statusText);
    }

    async resetResourceAssets(id: string): Promise<void> {
        const resource = await this.getResource(id);

        if (!resource.resourceKeys) {
            console.warn("No assets within ", id);
            return;
        }

        const filesName = this._getAssetsNameFromResource(resource);

        for (let i = 0; i < filesName.length; i++) {
            await this.deleteAsset(id, filesName[i]);
        }
    }

    async deleteAsset(id: string, assetName: string): Promise<void> {
        const response = await this.makeRequestExtended(`${id}/${assetName}`, 'DELETE');

        if (response.ok) {
            return;
        }

        throw new Error(response.statusText);
    }

    private _getAssetsNameFromResource(resource: VERTEXResource, supportedFileTypes?: string[], bannedFilenames?: string[]) {
        const fileCandidates: string[] = [];

        for (let i = 0; i < resource.resourceKeys.length; i++) {
            let assetName = resource.resourceKeys[i];

            if (supportedFileTypes) {
                let fileTypeMatch = supportedFileTypes.find(fileType => assetName.toLowerCase().endsWith(fileType));
                if (!fileTypeMatch) {
                    continue;
                }
            }

            if (bannedFilenames) {
                if (bannedFilenames.find(bannedName => assetName === bannedName)) {
                    console.debug("Found Banned name, skipping", assetName);
                    continue;
                }
            }

            fileCandidates.push(resource.resourceKeys[i]);
        }

        return fileCandidates;
    }
}

import ServiceBase from "../service-base";
import { RealisticBodyType, IConvertBody, CartoonBodyType } from "../service.interfaces";

export class TaskApi extends ServiceBase {

    constructor(token: string, baseUrl: string) {
        super(token, `${baseUrl}/task/`);
    }

    async convertFaceToAvatarRealistic(resourceId:string, fileName:string, bodyType: RealisticBodyType): Promise<boolean> {
        const uri = `facetoavatar/convert/createHead/${resourceId}/${fileName}/${bodyType}`;
        const response = await this.makeRequestExtended(uri, 'POST');        

        if(response.ok){
            return true;
        }

        throw new Error(response.statusText);
    }

    async convertFaceToAvatarCartoon(resourceId:string, fileUrl : string): Promise<CartoonBodyType> {
        const uri = `facetoavatar/readyplayerme/downloadFile/${resourceId}/${encodeURIComponent(fileUrl)}`;
        const response = await this.makeRequestExtended(uri, 'POST');        

        if(response.ok){
            const json = await response.json();
            if(json.bodyType)
            {
                return json.bodyType;
            }
        }

        throw new Error(response.statusText);
    }


    async convertImgToDds(resourceId:string, body?: IConvertBody): Promise<boolean> {
        const uri = `imgtodds/convert/${resourceId}`;

        const response = await this.makeRequestExtended(uri, 'POST', body ? JSON.stringify(body) : "");        

        if(response.ok){
            return true;
        }

        throw new Error(response.statusText);
    }
}
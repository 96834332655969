import Swal from "sweetalert2";
import { ActionIncompatibilityReason, CustomActionSettingsComponent } from "../componentsystems/NodeComponents/actionsettingscomponent";
import { Utils } from "../../utilities/utils";

export class CustomActionsRenderer {

    actionListCardElement: HTMLDivElement;
    isItemAdded: boolean = false;
    newItemElement: HTMLDivElement;
    isEnabled: boolean = true;
    availableValues: string[];

    SetEnabled(enabled: boolean, message?: string) {
        if (enabled) {
            this.actionListCardElement?.querySelector("#action-list-add-button")?.removeAttribute("disabled");

            const listElement: HTMLUListElement = this.actionListCardElement?.querySelector(".action-list");
            
            if (listElement?.childElementCount > 0) {
                listElement.style.removeProperty("display");
            }

            const emptyMessageElement: HTMLUListElement = this.actionListCardElement?.querySelector(".empty-list-message");
            
            if (emptyMessageElement?.childElementCount <= 0) {
                emptyMessageElement.style.removeProperty("display");
            }

            const diasbledMessageElement: HTMLSpanElement = this.actionListCardElement?.querySelector(".disabled-action-list-message");
            
            if (diasbledMessageElement) {
                diasbledMessageElement.style.display = "none";
            }
        }
        else {
            this.actionListCardElement?.querySelector("#action-list-add-button")?.setAttribute("disabled", "true");

            const listElement: HTMLUListElement = this.actionListCardElement?.querySelector(".action-list");
            
            if (listElement) {
                listElement.style.display = "none";
            }

            const emptyMessageElement: HTMLUListElement = this.actionListCardElement?.querySelector(".empty-list-message");
            
            if (emptyMessageElement) {
                emptyMessageElement.style.display = "none";
            }

            const diasbledMessageElement: HTMLSpanElement = this.actionListCardElement?.querySelector(".disabled-action-list-message");
            
            if (diasbledMessageElement) {
                diasbledMessageElement.style.removeProperty("display");
                if(message){
                    diasbledMessageElement.innerText = message;
                }
            }
        }
        this.isEnabled = enabled;
    }

    RenderActions(component: Vertex.NodeComponentModel.Component & { actionIndexes: number[], actionValues: number[] }, actionSettings: CustomActionSettingsComponent, availableValues: string[]): HTMLDivElement {
        this.availableValues = availableValues;
        
        if (this.actionListCardElement == null) {
            const cardTemplate = document.getElementById("action-card-template") as HTMLTemplateElement;
            this.actionListCardElement = document.createElement('div') as HTMLDivElement;
            this.actionListCardElement.appendChild(cardTemplate.content.cloneNode(true));

            const addButton = this.actionListCardElement.querySelector("#action-list-add-button") as HTMLButtonElement;
            addButton.addEventListener("click", () => {
                if (actionSettings.actions.length <= 0) {
                    Swal.fire({
                        icon: 'warning',
                        title: `No action available!`,
                        html: `You need to add at least one action to the <b>Actions Settings Panel</b> before you can add actions to this component.`,
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                        heightAuto: false
                    });
                }
                else {
                    const unusedActions = actionSettings.actions.filter((actionName, actionIndex) => !component.actionIndexes.includes(actionIndex));
                    if (unusedActions.length <= 0) {
                        Swal.fire({
                            icon: 'info',
                            title: `No more actions available!`,
                            html: `You have already added all the available actions to this component.`,
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            showConfirmButton: true,
                            heightAuto: false
                        });
                    }
                    else if (this.newItemElement == null) {
                        const itemTemplate = document.getElementById("action-item-template") as HTMLTemplateElement;

                        this.newItemElement = ((itemTemplate.content.cloneNode(true) as HTMLElement).children[0] as HTMLDivElement);
                        
                        const injectables = this.newItemElement.querySelectorAll<HTMLImageElement>("img.injectable");
                        Utils.injectSvg(injectables);
                        
                        this.newItemElement.style.order = "1";

                        const keyDropdownElement = this.newItemElement.querySelector(".key-dropdown-holder") as HTMLSelectElement;
                        const valueDropdownElement = this.newItemElement.querySelector(".value-dropdown-holder") as HTMLSelectElement;
                        this.RenderDropdownElements(keyDropdownElement, valueDropdownElement, -1, component, this.availableValues, actionSettings);
                        
                        const deleteButton = this.newItemElement.querySelector(".action-list-delete-button") as HTMLButtonElement;

                        deleteButton.addEventListener("click", () => {
                            if(component.actionIndexes.length <= 0) {
                                actionListElement.style.display = "none";
                                emptyMessageElement.style.removeProperty("display");
                            }

                            this.newItemElement.remove();
                            this.newItemElement = null;
                        });

                        actionListElement.style.removeProperty("display");
                        emptyMessageElement.style.display = "none";
                        actionListElement.appendChild(this.newItemElement);
                        this.isItemAdded = false;
                    }
                }
            });
        }
        const actionListElement = this.actionListCardElement.querySelector(".action-list") as HTMLElement;
        const emptyMessageElement: HTMLUListElement = this.actionListCardElement?.querySelector(".empty-list-message");

        actionListElement.innerHTML = "";

        if (actionListElement && component.actionIndexes.length <= 0 && !this.newItemElement) {
            actionListElement.style.display = "none";
            
            if(this.isEnabled){
                emptyMessageElement.style.removeProperty("display");
            }
            else{
                emptyMessageElement.style.display = "none";
            }
        }
        else {
            if(this.isEnabled){
                actionListElement.style.removeProperty("display");
            }
            else{
                actionListElement.style.display = "none";
            }

            emptyMessageElement.style.display = "none";

            if (this.newItemElement != null) {
                if(this.isItemAdded){
                    this.newItemElement.remove();
                    this.newItemElement = null;
                }
                else{
                    actionListElement.appendChild(this.newItemElement);
                    const keyDropdownElement = this.newItemElement.querySelector(".key-dropdown-holder") as HTMLSelectElement;
                    const valueDropdownElement = this.newItemElement.querySelector(".value-dropdown-holder") as HTMLSelectElement;
                    this.RenderDropdownElements(keyDropdownElement, valueDropdownElement, -1, component, availableValues, actionSettings);
                }
            }

            const itemTemplate = document.getElementById("action-item-template") as HTMLTemplateElement;
            
            for (let i = 0; i < component.actionIndexes.length; i++) {
                const item = itemTemplate.content.cloneNode(true) as HTMLElement;
                
                const injectables = item.querySelectorAll<HTMLImageElement>("img.injectable");
                Utils.injectSvg(injectables);

                const keyDropdownElement = item.querySelector(".key-dropdown-holder") as HTMLSelectElement;
                const valueDropdownElement = item.querySelector(".value-dropdown-holder") as HTMLSelectElement;
                this.RenderDropdownElements(keyDropdownElement, valueDropdownElement, i, component, availableValues, actionSettings);

                const deleteButton = item.querySelector(".action-list-delete-button") as HTMLButtonElement;

                deleteButton.addEventListener("click", () => {
                    component.actionIndexes.splice(i, 1);
                    component.actionValues.splice(i, 1);
                    component.triggerOnChanged();
                    Vertex.Globals.event.fire("ActionSettings:ActionAssignmentUpdated");
                });


                
                actionListElement.appendChild(item);
            }
        }
        
        return this.actionListCardElement;
    }

    RenderDropdownElements(keyDropdownElement: HTMLSelectElement, valueDropdownElement: HTMLSelectElement, currentIndex: number, component: Vertex.NodeComponentModel.Component & { actionIndexes: number[], actionValues: number[] }, availableValues: string[], actionSettings: CustomActionSettingsComponent) {
        
        // Key dropdown
        keyDropdownElement.innerHTML = "";
        const availableActions = actionSettings.actions.filter((action, actionIndex) => !component.actionIndexes.includes(actionIndex) || actionIndex == component.actionIndexes[currentIndex]);

        if (currentIndex == -1) {
            let option = document.createElement("option") as HTMLOptionElement;
            option.classList.add("dropdown-item");
            option.innerText = "Select action...";
            option.value = "";
            keyDropdownElement.appendChild(option);

            option.selected = true;
            option.hidden = true;
            keyDropdownElement.title = option.innerText;
            keyDropdownElement.dataset.toggle = "tooltip";
            keyDropdownElement.dataset.placement = "top";
        }

        for (let i = 0; i < availableActions.length; i++) {
            const actionIndex = actionSettings.actions.indexOf(availableActions[i]);
            const actionName = availableActions[i].actionName;

            let option = document.createElement("option") as HTMLOptionElement;
            option.classList.add("dropdown-item");
            option.innerText = actionName;
            keyDropdownElement.appendChild(option);

            if (actionIndex == component.actionIndexes[currentIndex]) {
                option.selected = true;
                keyDropdownElement.title = option.innerText;
                keyDropdownElement.dataset.toggle = "tooltip";
                keyDropdownElement.dataset.placement = "top";
            }
        }

        // Value dropdown
        valueDropdownElement.innerHTML = "";

        if (currentIndex == -1) {
            let option = document.createElement("option") as HTMLOptionElement;
            option.classList.add("dropdown-item");
            option.innerText = "Select value...";
            valueDropdownElement.appendChild(option);

            option.selected = true;
            option.hidden = true;
            option.value = "-1";
            valueDropdownElement.title = option.innerText;
            valueDropdownElement.dataset.toggle = "tooltip";
            valueDropdownElement.dataset.placement = "top";
        }

        for (let i = 0; i < availableValues.length; i++) {
            const valueIndex = i;
            const valueName = availableValues[valueIndex];

            let option = document.createElement("option") as HTMLOptionElement;
            option.classList.add("dropdown-item");
            option.innerText = valueName;
            option.value = valueIndex.toString();

            valueDropdownElement.appendChild(option);

            if (valueIndex == component.actionValues[currentIndex]) {
                option.selected = true;
                valueDropdownElement.title = option.innerText;
                valueDropdownElement.dataset.toggle = "tooltip";
                valueDropdownElement.dataset.placement = "top";
            }
        }

        const onDropdownChanged = async (event: Event) => {
            const selectedActionName = keyDropdownElement.value;
            const selectedValueIndex = valueDropdownElement.value;
            if (selectedActionName.length > 0 && selectedValueIndex != "-1") {
                const selectedActionIndex = actionSettings.actions.findIndex(action => action.actionName == selectedActionName);

                if (currentIndex != -1) {
                    const previousActionIndex = component.actionIndexes[currentIndex];
                    const previousValueIndex = component.actionValues[currentIndex];

                    component.actionIndexes[currentIndex] = selectedActionIndex;
                    component.actionValues[currentIndex] = parseInt(selectedValueIndex);

                    const incompatibility = actionSettings.checkSpaceActionsCompatibility();
                    if (incompatibility.reason != ActionIncompatibilityReason.None) {
                        await Swal.fire({
                            icon: 'warning',
                            title: `Incompatible actions!`,
                            html: `The action you selected is incompatible with the other actions you have selected. Please select a different action. <br><br> <b>Reason:</b> ${ActionIncompatibilityReason[incompatibility.reason]} <br> The selection will be reverted to the previous value.`,
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            showConfirmButton: true,
                            heightAuto: false
                        });

                        component.actionIndexes[currentIndex] = previousActionIndex;
                        component.actionValues[currentIndex] = previousValueIndex;
                        keyDropdownElement["value"] = actionSettings.actions[previousActionIndex].actionName;
                        valueDropdownElement["value"] = previousValueIndex.toString();
                    }
                    else {
                        component.triggerOnChanged();
                        Vertex.Globals.event.fire("ActionSettings:ActionAssignmentUpdated");
                    }
                }
                else{
                    component.actionIndexes.push(selectedActionIndex);
                    component.actionValues.push(parseInt(selectedValueIndex));

                    const incompatibility = actionSettings.checkSpaceActionsCompatibility();
                    if (incompatibility.reason != ActionIncompatibilityReason.None) {
                        await Swal.fire({
                            icon: 'warning',
                            title: `Incompatible actions!`,
                            html: `The action you selected is incompatible with the other actions you have selected. Please select a different action. <br><br> <b>Reason:</b> ${ActionIncompatibilityReason[incompatibility.reason]} <br> The selection will be reverted to the previous value.`,
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            showConfirmButton: true,
                            heightAuto: false
                        });

                        component.actionIndexes.pop();
                        component.actionValues.pop();
                        if(event.target == keyDropdownElement){
                            keyDropdownElement["value"] = "";
                        }
                        else{
                            valueDropdownElement["value"] = "-1";
                        }
                    }
                    else {
                        component.triggerOnChanged();
                        this.isItemAdded = true;
                        Vertex.Globals.event.fire("ActionSettings:ActionAssignmentUpdated");
                    }
                }
            }
        }

        keyDropdownElement.onchange = onDropdownChanged;
        valueDropdownElement.onchange = onDropdownChanged;
    }
}
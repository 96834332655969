import { AugmentedStoreAssembly } from "../../../../AugmentedStoreAssembly";

export class WarpComponentView extends Vertex.NodeComponentModel.ComponentViewBase {
    constructor() {
        super();
    }

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
    }

    update(): void {
    }
}

export class WarpComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new AugmentedStoreAssembly.WarpComponent();
    }

    constructor() {        
        super("Warp", new WarpComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}
import { clone, cloneDeep, isEmpty, isEqual } from "lodash";
import Swal from "sweetalert2";
import { isMajorGte, HevolusApp } from "./versioning";
import { Guid } from "../admin/common/types";
import { DeviceType, HoloPrototypeBaseMetadata, NotificationStatus, Utils } from "./utils";
import { Config } from "../../config";
import { IQRItem } from "../components/catalog/qr/qr-interfaces";
import { v4 as uuidv4 } from 'uuid';
import { QRUtils } from "../components/catalog/qr/qrutils";
import { ConversionUtils } from "./conversion-utilities";
import { UserProfileUtils } from "./user-profile-utilities";
import { GltfImage, GltfStructure, GltfUtils } from "./gltf-utilities";
import { MESH_GLTF_FILENAME, PLATFORM_NATIVE_COMPRESSED_IMAGE_SUFFIXES, KHRONOS_TEXTURE_EXTENSION, RESOURCE_THUMB_FILENAME, MEDIA_VALID_EXTENSIONS, SUPPORTED_ATTACHED_TYPES, RESOURCE_THUMB_ALL_PLATFORM_FILENAMES, ORIGINAL_RESOURCE_GUID_PREFIX_TAG, CLONED_RESOURCE_TAG, MODEL_INFO_FILENAME, MEDIA_CONVERSION_STATUS_FILENAME, HOLOPROTOTYPE_BASE_METADATA_FILENAME, VERTEX_AOA_PLATFORM, VERTEX_QR_PLATFORM, CONTENT_QR_TAG, QRS_CATALOG_FILENAME, AOA_MODEL_FILENAME, PLATFORM_NATIVE_MODEL_TYPES, ALLOWED_AUDIO_TYPES, SUPPORTED_VIDEO_FORMATS, SUPPORTED_AUDIO_FORMATS, ALLOWED_IMAGE_TYPES, ALLOWED_DOC_TYPES, ALLOWED_PDF_TYPES, SYSTEM_TAGS, SYSTEM_TAG_PREFIXES, GENERATED_ZIP_FILENAME, H_SHARE_DOMAIN, APP_AUGMENTEDSTORE_TAG, APP_REMOTESELLING_TAG, REMOTESELLING_DOMAIN, APP_HOLOMUSEUM_TAG, HOLOMUSEUM_DOMAIN, APP_HOLOPROTOTYPE_TAG, HOLOPROTOTYPE_DOMAIN, SPACE_EDITOR_VERSION_PREFIX_TAG, APP_HEVOCOLLABORATION_TAG, TENANT_INFO_TAG, PLATFORM_VERSIONING_TAG, TYPE_MODEL_TAG, TYPE_BASE_TAG, TYPE_AVATAR_TAG, TYPE_MEDIA_TAG, TYPE_DECK_TAG, TYPE_CATALOG_TAG, TYPE_SPACE_TAG, TYPE_ROOM_TAG, RESOURCE_API_URI, AS_PUBLIC_CATALOG_FILENAME, COMPRESSED_IMAGE_TYPES, SPACEPRIVACY_PUBLIC_TAG, TENANT_INFO_RESOURCE_NAME, TYPE_SYSTEM_TAG, HIDDEN_RESOURCE_TAG, CONTENT_TAG_TAG, TAGS_CATALOG_FILENAME, QR_CATALOG_RESOURCE_NAME, CONTENT_AVATAR_TAG, DEFAULT_AVATARS_CATALOG_FILENAME, SUPPORTED_AI_DOC_TYPES, H_SHARE_SETTINGS_FILENAME, HShareResourceSettings, HShareTenantSettings, SYSTEM_RESOURCE_FILENAMES, CHANGE_MATERIAL_MODEL_FILENAME, SUPPORTED_AI_AUDIO_TYPES, HSHARE_ITEM_GROUP, HSHARE_COMPANY_SYSTEMMESSAGE_ITEM_ID, HSHARE_COMPANY_DOCUMENTLANGUAGE_ITEM_ID, HSTORE_COMPANY_PUBLIC_LINKS_ITEM_ID, DEFAULT_HSHARE_TENANT_SETTINGS } from "./constants";
import { StorageApi } from "../api/xr-copilot-api/storage";
import { QrUrlShortenerApi } from "../api/hevls-api/qr-url-shortener";
import { ChangeMaterialModel, ChangeMaterialModelUtils } from "./change-material-model-utilities";
import { CompaniesApi } from "../api/hipe-xr-api/system/companies";
import { ItemType } from "../api/hipe-xr-api/common";

export interface XrCopilotModel {
    id: string,
    name: string,
    hasData: boolean,
    hasAI: boolean
}

export interface VERTEXResource {
    name: string,
    id: string,
    type: string,
    tags?: string[],
    locks?: ResourceLock[],
    created?: string,
    modified?: string,
    resourceKeys?: string[],
    resourceMD5Hashes?: string[],
    publishedResources?: string[],
    publishParent?: string
}

export interface ResourceLock {
    id: string,
    type: ResourceLockType,
    reason: string
}

export enum ResourceLockType{
    Write = "Write",
    Delete = "Delete",
    Publish = "Publish"
}

// Resource types logically created by Hevolus, based on the backend ones.
export enum HevolusResourceType {
    Base = "Base",
    Model = "Model",
    Media = "Media",
    Space = "Space",
    Room = "Room",
    Deck = "Deck",
    Catalog = "Catalog",
    Avatar = 'Avatar',
    TenantInfo = 'TenantInfo',
    Versioning = 'Versioning',
    System = 'System',
    All = "All",
    None = "None"
}

// Resource types provided by VERTX backend
export enum VertexResourceType {
    Data = "Data",
    MeshAsset = "MeshAsset",
    MaterialAsset = "MaterialAsset",
    SceneAsset = "SceneAsset",
    Service = "Service",
    Assembly = "Assembly",
    None = ""
}

export enum TagMode {
    All = "All",
    Any = "Any"
}

export enum OrderMode {
    Date = "Date",
    Name = "Name"
}

export interface AsTenantPublicSpace {
    name: string,
    id: string
}

export enum FileMediaType{
    Audio = "audio",
    Video = "video",
    Image = "image", 
    Doc = "doc",
    Pdf = "pdf",
    None = "none"
}

export interface DetailedResult {
    result: Result,
    message?: string,
    value?: any
}

export enum Result {
    Success = "success",
    Failed = "failed",
    Canceled = "canceled",
    None = "none"
}

export class ResourceUtils {

    /**
     * Utility to download a zip file containing all AI Documents.
     */
    static async downloadAiDocumentsZip(id?: string): Promise<HTMLAnchorElement> {
        id = id || Vertex.Globals.spaceId;

        let aiFiles = await StorageApi.listFiles();
        aiFiles = aiFiles.filter(a => !SYSTEM_RESOURCE_FILENAMES.includes(a));

        async function *lazyFetcher() {
            for(let i = 0; i < aiFiles.length; i++){
                const file = aiFiles[i];
    
                yield new File([await StorageApi.getFile(file)], file);
            }
        }

        const clientZip = require("client-zip");
        const blob = await clientZip.downloadZip(lazyFetcher()).blob();

        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "AI_Documents.zip";
        
        return link;
    }

    /**
     * Utility to download a zip file, named as the Resource, containing all the assets of the Resource, except the system ones.
     * For a Model, it will contain the .glTF file, the .bin file, the textures, the thumbnail, the attached files, but not the .json files.
     * For a Base, it will contain the .glTF file, the .bin file, the textures and the thumbnail.
     * For a Media, it will contain all media files and the thumbnail.
     * @param id The GUID of the resource you want to download
     * @param token The Token of a valid user
     * @returns A link to download the zip file or null. You have to click it and then remove it from the DOM.
     */
    static async downloadResourceZip(id: string, token?: string): Promise<HTMLAnchorElement> {        
        if(!id){
            return null;
        }

        token = token || Vertex.Globals.bearerToken;

        const resource = await ResourceUtils.getResourceAsync(id, false, token);

        if(!resource){
            return null;
        }

        const resourceType = ResourceUtils.getHevolusResourceType(resource);

        if(resourceType === HevolusResourceType.None){
            return null;
        }

        const zipName = `${resource.name}.zip`;
        const fileResponses: Response[] = [];

        if(resourceType === HevolusResourceType.Model || resourceType === HevolusResourceType.Base){
            const gltf = await GltfUtils.getGltfStructure(id);

            if(gltf){
                fileResponses.push(await ResourceUtils.getAssetFromResource(id, MESH_GLTF_FILENAME, token));
                
                if(gltf.buffers?.length){
                    for(let i = 0; i < gltf.buffers.length; i++){
                        fileResponses.push(await ResourceUtils.getAssetFromResource(id, gltf.buffers[i].uri, token));
                    }
                }

                if(gltf.images?.length){
                    for(let i = 0; i < gltf.images.length; i++){
                        const uri = gltf.images[i].uri;
                        const isCompressed = gltf.images[i].extensions?.VERTXCLOUD_img2dds_source?.source >= 0;

                        fileResponses.push(await ResourceUtils.getAssetFromResource(id, uri, token));

                        if(isCompressed){
                            for(let j = 0; j < PLATFORM_NATIVE_COMPRESSED_IMAGE_SUFFIXES.length; j++){
                                const ext = Utils.getFileExtension(uri);
                                const compressedUri = uri.replace(`.${ext}`, `${PLATFORM_NATIVE_COMPRESSED_IMAGE_SUFFIXES[j]}.${KHRONOS_TEXTURE_EXTENSION}`);

                                fileResponses.push(await ResourceUtils.getAssetFromResource(id, compressedUri, token));
                            }
                        }
                    }
                }
            }

            fileResponses.push(await ResourceUtils.getAssetFromResource(id, RESOURCE_THUMB_FILENAME, token));

            if(resourceType === HevolusResourceType.Model){
                const icons = await ChangeMaterialModelUtils.getIcons(id, token);

                if(icons?.length){
                    for(let i = 0; i < icons.length; i++){
                        fileResponses.push(await ResourceUtils.getAssetFromResource(id, icons[i], token));
                    }
                }

                const attached = await ResourceUtils.getAttachedList(resource, gltf);

                if(attached?.length){
                    for(let i = 0; i < attached.length; i++){
                        fileResponses.push(await ResourceUtils.getAssetFromResource(id, attached[i], token));
                    }
                }
            }
        }
        else if(resourceType === HevolusResourceType.Media){
            const media = resource.resourceKeys.filter(fileName => MEDIA_VALID_EXTENSIONS.includes(Utils.getFileExtension(fileName)));

            if(media?.length){
                for(let i = 0; i < media.length; i++){
                    fileResponses.push(await ResourceUtils.getAssetFromResource(id, media[i], token));
                }
            }
        }

        const clientZip = require("client-zip");
        const blob = await clientZip.downloadZip(fileResponses).blob();

        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = zipName;
        
        return link;
    }

    static async getAttachedList(resource: VERTEXResource, gltf: GltfStructure): Promise<string[]>{
        let gltfImages = [];
        
        gltf?.images?.forEach((image: GltfImage) => {
            gltfImages.push(image.uri);
        });

        let icons = await ChangeMaterialModelUtils.getIcons(resource.id);

        let supportedTypes = cloneDeep(SUPPORTED_ATTACHED_TYPES);
        
        const attacheds = resource.resourceKeys.filter(fileName => {
            const extension = Utils.getFileExtension(fileName);
            const isSupportedType = supportedTypes.includes(extension);
            const isUsedInGltf = gltfImages.includes(fileName);
            const isThumb = RESOURCE_THUMB_ALL_PLATFORM_FILENAMES.includes(fileName);
            const isIcon = icons.includes(fileName);

            return isSupportedType && !isUsedInGltf && !isThumb && !isIcon;
        });

        return attacheds;
    }

    /**
     * Utiliity to clone a Model or Media resource into a different tenant.
     * If you pass a published resource GUID as id, it will publish the cloned resource on the destination Tenant for you.
     * If you already cloned that exact resource, it will ask you if you want to overwrite the resource or keep it still.
     * @param id The GUID of the resource you want to clone
     * @param token The Token of a valid user on the destination Tenant
     * @returns The state of the process and eventually the GUID of the brand new cloned resource. The GUID will be the private resource GUID if
     * the provided id is the GUID of a private resource, published otherwise.
     */
    static async cloneResource(id: string, token: string): Promise<DetailedResult>{
        const originToken = Vertex.Globals.bearerToken;//"dfa313a2469d03159bd7d32e6f083f36e093b661f232f69b3ecdc4f100e8dd7f";
        let returnPublished = false;

        //check input variables
        if(id === null || token === null){
            return { result: Result.Failed, message: `Invalid id or token` };
        }

        if(originToken === token){
            return { result: Result.Failed, message: `Origin and Destination Token are the same` };
        }

        const originUser = await UserProfileUtils.getUserInfo(originToken);
        const destUser = await UserProfileUtils.getUserInfo(token);

        if(!originUser || !destUser){
            return { result: Result.Failed, message: `Invalid user` };
        }

        if(originUser.vertex_tid === destUser.vertex_tid){
            return { result: Result.Failed, message: `Origin and Destination Tenant are the same` };
        }

        //get resource corresponding to that exact GUID
        let resource = await ResourceUtils.getResourceData(id, originToken);
        
        //check if the resource exists and it's valid
        if(resource == null || resource.resourceKeys == null){
            console.log(`Failed to get resource info for GUID: ${id}`);

            return { result: Result.Failed, message: `Invalid resource` };
        }
        
        //if the resource is a published one, let's take the private to work with, but we will return the published id at the end
        if(resource != null && resource.publishParent != null){
            returnPublished = true;

            resource = await ResourceUtils.getResourceAsync(id, false, originToken);

            //check if the resource exists and it's valid
            if(resource == null || resource.resourceKeys == null){
                console.log(`Failed to get resource info for GUID: ${id}`);

                return { result: Result.Failed, message: `Invalid resource` };
            }
        }

        if(Swal.isLoading()){
            if(!Swal.getTitle().textContent.includes(resource.name)){
                Swal.update({
                    title: `Cloning<br>${resource.name}`,
                });

                Swal.showLoading();
            }
        }
        
        const resourceType = ResourceUtils.getHevolusResourceType(resource);

        //check if the resource has a published version and keep this info for later
        const isPublished = resource.publishedResources != null && resource.publishedResources[0] != null;

        //copy resource tags for later
        const tags = clone(resource.tags);

        let clonedResourceId = "";
        let clonedResource: VERTEXResource = null;

        //let's see if the resource has been cloned already. We query the database with the exact tag, so we expect zero or one match only
        const alreadyClonedRes = await ResourceUtils.getResourcesWithParamsAsync([`${ORIGINAL_RESOURCE_GUID_PREFIX_TAG}${resource.id}`], TagMode.All, ResourceUtils.getVertexResourceType(resourceType), true, false, true, token);
        
        if(alreadyClonedRes.length === 0){
            //if it's the first time we clone this resource, let's add the system tags
            tags.push(`${CLONED_RESOURCE_TAG}`);
            tags.push(`${ORIGINAL_RESOURCE_GUID_PREFIX_TAG}${resource.id}`);

            clonedResourceId = await ResourceUtils.createNewResource(resource.name, resource.type, tags, false, null, token);

            //check if the new resource has been created
            if(!clonedResourceId){
                console.log(`Failed to create destination resource for GUID: ${id}`);

                return { result: Result.Failed, message: `Failed to create the resource in the destination Tenant` };
            }
        }
        else{            
            clonedResource = alreadyClonedRes[0];
            clonedResourceId = clonedResource.id;

            //ask user if overrwrite the resource or keep it as it is
            const result = await Swal.fire({
                title: `Cloning<br>${resource.name}`,
                icon: 'question',
                html: `The resource has already been cloned in the past.<br><br>Do you want to update it?`,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Update',
                cancelButtonText: 'Skip',
                showCloseButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                heightAuto: false
            });

            if(result.isConfirmed){ //Overwrite the resource
                Swal.fire({
                    icon: 'info',
                    title: `Cloning<br>${resource.name}`,
                    showCancelButton: false,
                    showCloseButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: false,
                    heightAuto: false,
                    width: "auto",
                });
                
                Swal.showLoading();

            }
            else{ //keep it as it is
                return { result: Result.Canceled, value: clonedResourceId };
            }
        }

        if(clonedResource != null){ //here means it was already cloned 
            //let's delete everything in the resource
            const deleteTasks: Promise<Response>[] = [];

            for(let i = 0; i < clonedResource.resourceKeys.length; i++){
                const asset = clonedResource.resourceKeys[i];

                deleteTasks.push(ResourceUtils.deleteAssetFromResource(clonedResourceId, asset, token));
            }

            await Promise.all(deleteTasks);
        }

        const NOT_TO_COPY = [MODEL_INFO_FILENAME, MEDIA_CONVERSION_STATUS_FILENAME, H_SHARE_SETTINGS_FILENAME];

        //now it's time to copy all files
        for(let i = 0; i < resource.resourceKeys.length; i++){
            const asset = resource.resourceKeys[i];

            if(!NOT_TO_COPY.includes(asset)){
                const assetResponse = await ResourceUtils.getAssetFromResource(resource.id, asset, originToken);

                if(!assetResponse.ok){
                    console.log(`Failed to copy asset "${asset}" from "${resource.name}"}`);

                    const deleted = await ResourceUtils.deleteResource(clonedResourceId, true, token);
                
                    if(!deleted){
                        console.log(`Failed to delete ${clonedResourceId} on the destination Tenant.`);
                    }
    
                    return { result: Result.Failed, message: `Failed to copy files from the resource` };
                }
                
                const blob = await assetResponse.blob();

                if(!blob){
                    console.log(`Failed to copy asset "${asset}" from "${resource.name}"}`);

                    const deleted = await ResourceUtils.deleteResource(clonedResourceId, true, token);
                
                    if(!deleted){
                        console.log(`Failed to delete ${clonedResourceId} on the destination Tenant.`);
                    }

                    return { result: Result.Failed, message: `Failed to copy files from the resource` };
                }

                const postRes = await ResourceUtils.postAssetToResource(asset, clonedResourceId, blob, token);

                if(!postRes.ok){
                    console.log(`Failed to copy asset "${asset}" to "${resource.name}"}`);

                    const deleted = await ResourceUtils.deleteResource(clonedResourceId, true, token);
                
                    if(!deleted){
                        console.log(`Failed to delete ${clonedResourceId} on the destination Tenant.`);
                    }

                    return { result: Result.Failed, message: `Failed to copy files to the resource` };
                }
            }
        }

        //if the original resource was published, let'do the same for the clone one
        if(isPublished){
            let publishResp: Response = null;

            //also, in case the (existing) clone is already published, republish it
            if(clonedResource != null && clonedResource.publishedResources?.length > 0){
                publishResp = await ResourceUtils.republishResource(clonedResourceId, token);
            }
            else{
                publishResp = await ResourceUtils.publishResource(clonedResourceId, token);
            }
            
            if(publishResp == null || !publishResp.ok){
                console.log(`Failed to publish the space`);
            }
        }        

        //let's return the correct private or published resource GUID
        if (returnPublished) {
            if (clonedResource == null || clonedResource.publishedResources == null || clonedResource.publishedResources.length === 0) {
                clonedResource = await ResourceUtils.getResourceAsync(clonedResourceId, false, token);
            }
        
            if (clonedResource && clonedResource.publishedResources && clonedResource.publishedResources.length > 0) {
                return { result: Result.Success, value: clonedResource.publishedResources[0] };
            }
            else{
                const deleted = await ResourceUtils.deleteResource(clonedResourceId, true, token);
                
                if(!deleted){
                    console.log(`Failed to delete ${clonedResourceId} on the destination Tenant.`);
                }

                return { result: Result.Failed };
            }
        }
        else{
            return { result: Result.Success, value: clonedResourceId };
        }
    }

    /**
     * Utiliity to clone a Base resource into a different tenant.
     * If you pass a published resource GUID as id, it will publish the cloned resource on the destination Tenant for you.
     * If you already cloned that exact resource, it will ask you if you want to overwrite the resource or keep it still.
     * @param id The GUID of the resource you want to clone
     * @param token The Token of a valid user on the destination Tenant
     * @returns The state of the process and eventually the GUID of the brand new cloned resource. The GUID will be the private resource GUID if
     * the provided id is the GUID of a private resource, published otherwise.
     */
    static async cloneBaseResource(id: string, token: string): Promise<DetailedResult>{
        const originToken = Vertex.Globals.bearerToken;

        //check input variables
        if(id === null || token === null){
            return { result: Result.Failed, message: `Invalid id or token` };
        }

        if(originToken === token){
            return { result: Result.Failed, message: `Origin and Destination Token are the same` };
        }

        const originUser = await UserProfileUtils.getUserInfo(originToken);
        const destUser = await UserProfileUtils.getUserInfo(token);

        if(!originUser || !destUser){
            return { result: Result.Failed, message: `Invalid user` };
        }

        if(originUser.vertex_tid === destUser.vertex_tid){
            return { result: Result.Failed, message: `Origin and Destination Tenant are the same` };
        }

        //get the base resource 
        const resource = await ResourceUtils.getResourceAsync(id, false, originToken);

        if(resource == null || resource.resourceKeys == null){
            console.log(`Failed to get resource info for GUID: ${id}`);

            return { result: Result.Failed, message: `Invalid resource` };
        }

        const hevoResourceType = ResourceUtils.getHevolusResourceType(resource);

        if(hevoResourceType !== HevolusResourceType.Base){
            return { result: Result.Failed, message: `Invalid resource` };
        }

        const cloneResult = await ResourceUtils.cloneResource(id, token);

        if(cloneResult.result === Result.Canceled){
            console.log(`Canceled clone space ${resource.name} ${id}`);

            return { result: Result.Canceled };
        }
        else if(cloneResult.result === Result.Failed || !cloneResult.value){
            console.log(`Failed to clone space ${resource.name} ${id}`);

            return { result: Result.Failed, message: `Failed to create the new resource` };
        }

        let clonedResourceId = cloneResult.value;
        let clonedAssociatedResourceIds = [];

        if(resource.resourceKeys.includes(HOLOPROTOTYPE_BASE_METADATA_FILENAME)){
            const metadataResp = await ResourceUtils.getAssetFromResource(resource.id, HOLOPROTOTYPE_BASE_METADATA_FILENAME);

            if(!metadataResp?.ok){
                console.log(`Failed to get Base metadata`);
                
                const deleted = await ResourceUtils.deleteResource(clonedResourceId, true, token);
                    
                if(!deleted){
                    console.log(`Failed to delete ${clonedResourceId} on the destination Tenant.`);
                }
                
                return { result: Result.Failed, value: clonedResourceId, message: `Failed to get origin Base metadata` };
            }

            const metadata = await metadataResp.json() as HoloPrototypeBaseMetadata;
            let metadataUpdated = false;

            //clone all prototype models
            if(metadata.models?.length){
                for(let i = 0; i < metadata.models.length; i++){
                    const resId = metadata.models[i];

                    const cloneResult = await ResourceUtils.cloneResource(resId, token);

                    if(Swal.isLoading()){
                        if(!Swal.getTitle().textContent.includes(resource.name)){
                            Swal.update({
                                title: `Cloning<br>${resource.name}`,
                            });
            
                            Swal.showLoading();
                        }
                    }
                    else{
                        //let's update the loader ui with the space name
                        Swal.fire({
                            icon: 'info',
                            title: `Cloning<br>${resource.name}`,
                            showCancelButton: false,
                            showCloseButton: false,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                            showConfirmButton: false,
                            heightAuto: false,
                            width: "auto",
                        });
                        
                        Swal.showLoading();
                    }
                    
                    if(cloneResult.value && (cloneResult.result === Result.Success || cloneResult.result === Result.Canceled)){
                        clonedAssociatedResourceIds.push(cloneResult.value);

                        metadata.models[i] = cloneResult.value;
                        metadataUpdated = true;
                    }
                    else{
                        console.log(`Failed to create clone for associated resource with GUID: ${resId}}`);

                        for(let j = 0; j < clonedAssociatedResourceIds.length; j++){
                            const deleted = await ResourceUtils.deleteResource(clonedAssociatedResourceIds[j], true, token);
                    
                            if(!deleted){
                                console.log(`Failed to delete ${clonedAssociatedResourceIds[j]} on the destination Tenant.`);
                            }
                        }
                        const deleted = await ResourceUtils.deleteResource(clonedResourceId, true, token);
                    
                        if(!deleted){
                            console.log(`Failed to delete ${clonedResourceId} on the destination Tenant.`);
                        }
                        
                        return { result: Result.Failed, message: `Failed to clone Base associated resource ${resId}` };
                    }
                }
            }

            let hasAOA = false;
            let hasQR = false;

            if(metadata.anchors?.length){
                const qrAnchorIds = [];
                const qrAnchorOffsets = [];

                for(let i = 0; i < metadata.anchors.length; i++){
                    const anchor = metadata.anchors[i];

                    if(anchor.includes(VERTEX_AOA_PLATFORM)){
                        hasAOA = true;
                    }

                    if(anchor.includes(VERTEX_QR_PLATFORM)){
                        hasQR = true;

                        const guid = anchor.match("[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}")[0];

                        if(guid){
                            qrAnchorIds.push(guid);
                        }

                        qrAnchorOffsets.push(anchor.substring(anchor.indexOf('?')));
                    }
                }

                if(hasQR && qrAnchorIds.length){
                    Swal.update({
                        title: `Preparing QRs`,
                    });
    
                    Swal.showLoading();

                    let qrItems: IQRItem[] = [];

                    let originQRCatalog: IQRItem[] = [];
                    let originQRCatalogResource = await ResourceUtils.getOrCreateCatalog("", CONTENT_QR_TAG);

                    const originQRCatalogResp = await ResourceUtils.getAssetFromResource(originQRCatalogResource.id, QRS_CATALOG_FILENAME);

                    if(originQRCatalogResp?.ok){
                        originQRCatalog = await originQRCatalogResp.json() as IQRItem[];

                        if(originQRCatalog?.length){
                            qrItems.push(...originQRCatalog.filter(qr => qrAnchorIds.includes(qr.id)));
                        }
                    }

                    //we have something to copy here :D
                    if(qrItems.length){
                        let destQRCatalog: IQRItem[] = [];
                        let destQRCatalogResource = await ResourceUtils.getOrCreateCatalog("", CONTENT_QR_TAG, token);

                        const destQRCatalogResp =  await ResourceUtils.getAssetFromResource(destQRCatalogResource.id, QRS_CATALOG_FILENAME, token);

                        if(destQRCatalogResp?.ok){
                            let destCatalogUpdated = false;

                            destQRCatalog = await destQRCatalogResp.json() as IQRItem[];

                            for(let i = 0; i < qrItems.length; i++){
                                const qr = cloneDeep(qrItems[i]);
                                const existingIndex = destQRCatalog.findIndex(item => item.name === qr.name)
                                const anchorIndex = metadata.anchors.findIndex(a => a.includes(qr.id));

                                if(existingIndex === -1){
                                    const newGuid = uuidv4();
                                    
                                    qr.id = newGuid;
                                    qr.dateTimeCreated = new Date().toISOString();
                                    qr.url = QRUtils.generateUrl(qr);

                                    destQRCatalog.push(qr);
                                    destCatalogUpdated = true;

                                    if(anchorIndex !== -1){
                                        metadata.anchors[anchorIndex] = metadata.anchors[anchorIndex].replace(qrItems[i].id, qr.id);
                                        metadataUpdated = true;
                                    }
                                }
                                else{
                                    //keep existent qr with same name                                    
                                    if(anchorIndex !== -1){
                                        metadata.anchors[anchorIndex] = metadata.anchors[anchorIndex].replace(qr.id, destQRCatalog[existingIndex].id);
                                        metadataUpdated = true;
                                    }
                                }
                            }

                            if(destCatalogUpdated){
                                const updateCatalogResp = await ResourceUtils.postAssetToResource(QRS_CATALOG_FILENAME, destQRCatalogResource.id, JSON.stringify(destQRCatalog, null, 2), token);

                                if(!updateCatalogResp?.ok){
                                    console.log("Failed to update destination QR Catalog.");

                                    for(let j = 0; j < clonedAssociatedResourceIds.length; j++){
                                        const deleted = await ResourceUtils.deleteResource(clonedAssociatedResourceIds[j], true, token);
                                
                                        if(!deleted){
                                            console.log(`Failed to delete ${clonedAssociatedResourceIds[j]} on the destination Tenant.`);
                                        }
                                    }
                                    const deleted = await ResourceUtils.deleteResource(clonedResourceId, true, token);
                                
                                    if(!deleted){
                                        console.log(`Failed to delete ${clonedResourceId} on the destination Tenant.`);
                                    }

                                    return { result: Result.Failed, value: clonedResourceId, message: `Failed to update destination QR Catalog.` };
                                }                                
                            }
                        }                        
                    }
                    
                    Swal.update({
                        title: `Cloning<br>${resource.name}`,
                    });
    
                    Swal.showLoading();
                }

                if(hasAOA){
                    if(resource.resourceKeys.includes(AOA_MODEL_FILENAME)){
                        Swal.update({
                            title: `Preparing AOA`,
                            html: `This operation may take a couple of minutes`
                        });
        
                        Swal.showLoading();

                        const deleteResp = await ResourceUtils.deleteAssetFromResource(clonedResourceId, AOA_MODEL_FILENAME, token);

                        if(!deleteResp?.ok){
                            console.log("Failed to delete origin AOA file in cloned resource. It will be overwritten by new AOA file anyway.");
                        }

                        const aoaResp = await ConversionUtils.convert2Aoa(clonedResourceId, PLATFORM_NATIVE_MODEL_TYPES[0], token);

                        Swal.update({
                            title: `Cloning<br>${resource.name}`,
                            html: ``
                        });
        
                        Swal.showLoading();

                        if(aoaResp?.ok){
                            const anchorIndex = metadata.anchors.findIndex(a => a.includes(resource.id));

                            if(anchorIndex !== -1){
                                metadata.anchors[anchorIndex] = metadata.anchors[anchorIndex].replace(resource.id, clonedResourceId);
                                metadataUpdated = true;
                            }
                        }
                        else{
                            console.log(`Failed to create AOA for the cloned Base. Access the Base in the Base Editor to try again.`);

                            if(!deleteResp?.ok){
                                for(let j = 0; j < clonedAssociatedResourceIds.length; j++){
                                    const deleted = await ResourceUtils.deleteResource(clonedAssociatedResourceIds[j], true, token);
                            
                                    if(!deleted){
                                        console.log(`Failed to delete ${clonedAssociatedResourceIds[j]} on the destination Tenant.`);
                                    }
                                }

                                const deleted = await ResourceUtils.deleteResource(clonedResourceId, true, token);
                            
                                if(!deleted){
                                    console.log(`Failed to delete ${clonedResourceId} on the destination Tenant.`);
                                }

                                return { result: Result.Failed, value: clonedResourceId, message: `Failed to delete origin AOA file and failed to create new AOA file` };
                            }
                        }
                    }
                }
            }

            if(metadataUpdated){
                const resp = await ResourceUtils.postAssetToResource(HOLOPROTOTYPE_BASE_METADATA_FILENAME, clonedResourceId, JSON.stringify(metadata, null, 2), token);

                if(!resp?.ok){
                    console.log(`Failed to update Base metadata`);

                    for(let j = 0; j < clonedAssociatedResourceIds.length; j++){
                        const deleted = await ResourceUtils.deleteResource(clonedAssociatedResourceIds[j], true, token);
                
                        if(!deleted){
                            console.log(`Failed to delete ${clonedAssociatedResourceIds[j]} on the destination Tenant.`);
                        }
                    }

                    const deleted = await ResourceUtils.deleteResource(clonedResourceId, true, token);
                
                    if(!deleted){
                        console.log(`Failed to delete ${clonedResourceId} on the destination Tenant.`);
                    }

                    return { result: Result.Failed, value: clonedResourceId, message: `Failed to update cloned Base metadata.` };
                }
            }
        }

        return { result: Result.Success, value: clonedResourceId };
    }
    
    static async openMediaPreview(assetName: string, resourceId?: string){
        resourceId = resourceId ?? Vertex.Globals.spaceId;
        
        const extension = Utils.getFileExtension(assetName);
        const fileName = Utils.getFileBaseName(assetName);
        const mediaType = ResourceUtils.getMediaType(extension);

        if(mediaType === FileMediaType.Pdf){
            ResourceUtils.getAssetBlobUrl(Vertex.Globals.spaceId, assetName).then((blobUri) => window.open(blobUri, "_blank"));
        }
        else{
            const swalTitle = document.createElement('p') as HTMLParagraphElement;
            swalTitle.style.overflow = "auto";
            swalTitle.innerHTML = `Loading<br>${assetName}`;

            Swal.fire({
                icon: "info",
                title: swalTitle,
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                heightAuto: false,
                width: "auto"
            });

            Swal.showLoading();

            try{            
                const swalTitle = document.createElement('p') as HTMLParagraphElement;
                swalTitle.style.overflow = "auto";
                swalTitle.textContent = assetName;

                if(mediaType === FileMediaType.Video){
                    if(Utils.getDeviceType() == DeviceType.iPhone || Utils.getDeviceType() == DeviceType.iPad){
                        assetName = fileName + ".mp4";
                    }
                    else{
                        assetName = fileName + ".webm";
                    }

                    ResourceUtils.getAssetBlobUrl(Vertex.Globals.spaceId, assetName).then((blobUrl) => {
                        const video = document.createElement("video") as HTMLVideoElement;
                        video.style.maxWidth = "100%";
                        video.style.maxHeight = "85vh";
                        video.src = blobUrl;
                        video.autoplay = true;
                        video.controls = true;

                        Swal.fire({
                            title: swalTitle,
                            html: video,
                            allowEscapeKey: true,
                            allowOutsideClick: true,
                            showCloseButton: true,
                            showConfirmButton: false,
                            heightAuto: false,
                            width: "auto",
                        });
                    });
                }
                else if(mediaType === FileMediaType.Audio){
                    assetName = `${fileName}.${ALLOWED_AUDIO_TYPES[0]}`;

                    ResourceUtils.getAssetBlobUrl(Vertex.Globals.spaceId, assetName).then((blobUrl) => {
                        const audio = document.createElement("audio") as HTMLAudioElement;
                        audio.src = blobUrl;
                        audio.autoplay = true;
                        audio.controls = true;

                        Swal.fire({
                            title: assetName,
                            html: audio,
                            allowEscapeKey: true,
                            allowOutsideClick: true,
                            showCloseButton: true,
                            showConfirmButton: false,
                            heightAuto: false,
                            width: "auto",
                        });
                    });
                }
                else if(mediaType === FileMediaType.Image){
                    ResourceUtils.getAssetBlobUrl(Vertex.Globals.spaceId, assetName).then((blobUrl) => {
                        const img = document.createElement("img") as HTMLImageElement;
                        img.src = blobUrl;
                        img.style.maxWidth = "100%";
                        img.style.maxHeight = "85vh";

                        Swal.fire({
                            title: swalTitle,
                            html: img,
                            allowEscapeKey: true,
                            allowOutsideClick: true,
                            showConfirmButton: false,
                            showCloseButton: true,
                            heightAuto: false,
                            width: "auto"
                        });
                    });
                }
                else if(mediaType === FileMediaType.Doc){
                    const reader = new FileReader();

                    reader.onloadend = () => {
                        const paragrph = document.createElement("p") as HTMLParagraphElement;
                        paragrph.innerHTML = reader.result as string;
                        paragrph.style.whiteSpace = "pre-wrap";
                        paragrph.style.textAlign = "initial";

                        Swal.fire({
                            title: swalTitle,
                            html: paragrph,
                            allowEscapeKey: true,
                            allowOutsideClick: true,
                            showCloseButton: true,
                            showConfirmButton: false,
                            heightAuto: false,
                            width: "70%",
                        });
                    }

                    ResourceUtils.getAssetFromResource(Vertex.Globals.spaceId, assetName).then((response) => {
                        if(response.ok){
                            response.blob().then(blob => reader.readAsText(blob));
                        }
                    });
                }
                else{
                    if(Swal.isVisible()){
                        Swal.close();
                    }
                }
            }
            catch(e){
                if(Swal.isVisible()){
                    Swal.close();
                }
            }
        }
    }

    static getMediaType(extension){
        let result: FileMediaType = FileMediaType.None;

        if(SUPPORTED_VIDEO_FORMATS.includes(extension)){
            result = FileMediaType.Video;
        }
        else if(SUPPORTED_AUDIO_FORMATS.includes(extension)){
            result = FileMediaType.Audio
        }
        else if(ALLOWED_IMAGE_TYPES.includes(extension)){
            result = FileMediaType.Image;
        }
        else if(ALLOWED_DOC_TYPES.includes(extension)){
            result = FileMediaType.Doc;
        }
        else if(ALLOWED_PDF_TYPES.includes(extension)){
            result = FileMediaType.Pdf;
        }

        return result;
    }

    static getAiDocumentType(extension): FileMediaType{
        let result = FileMediaType.None;

        if(SUPPORTED_AI_DOC_TYPES.includes(extension)){
            result = FileMediaType.Doc;
        }
        else if(SUPPORTED_AI_AUDIO_TYPES.includes(extension)){
            result = FileMediaType.Audio;
        }

        return result;
    }

    /**
     * Returns all system tags that are included in the provided input array
     */
    static getSystemTags(tags: string[]){
        return tags.filter(tag => SYSTEM_TAGS.includes(tag) || SYSTEM_TAG_PREFIXES.some(prefix => tag.startsWith(prefix)));
    }

    static async createResourceZip(resourceId: Guid, token?: string): Promise<Response> {
        try {
            const res = await fetch(`https://${Config.VERTEX_URL_BASE}/task/zipper/zipper/${resourceId}`, {
                headers: {
                    "Authorization": `Bearer ${token || Vertex.Globals.bearerToken}`
                },
                method: "POST"
            });

            if(res.ok) {
                return res;
            } else {
                console.log(`Failed to create ZIP for ${resourceId}, error ${await res.text()}`);
            }
        } catch {
            console.log(`Failed to create ZIP for ${resourceId}`);
            return null;
        }
    }
    
    static async createXRCopilotUrl(resource: VERTEXResource, isUpdated: boolean, token?: string): Promise<DetailedResult> {
        let publishedResourceId: Guid;
        token = token || Vertex.Globals.bearerToken;
        const result: DetailedResult = { result: Result.Failed, value: "" };    
        
        //get the full resource if not already available
        if(!resource.resourceKeys) {
            resource = await ResourceUtils.getResourceAsync(resource.id, false, token);
        }

        //check if the resource exists and it's valid
        if(resource == null || resource.resourceKeys == null){
            result.message = `Invalid resource`;
            return result;
        }

        //check if the resource has a valid gltf
        const hasValidGltf = (await GltfUtils.validateGltf(resource, true, false)).hasValidGltf;

        if(!hasValidGltf){
            result.message = `Invalid glTF Model`;
            return result;
        }

        //check if the resource already contains zip and settings
        let hasZip: boolean = resource.resourceKeys.indexOf(GENERATED_ZIP_FILENAME) != -1;
        let hasSettings: boolean = resource.resourceKeys.indexOf(H_SHARE_SETTINGS_FILENAME) != -1;
        let settingsUpdated = hasSettings; //if the settings are already there, we assume they are updated
        let defaultPresets = await ChangeMaterialModelUtils.getDefaultPresetIndexes(resource.id);
        let settings: HShareResourceSettings = null;

        //check if the settings are updated
        if(hasSettings){
            const settingsResp = await ResourceUtils.getAssetFromResource(resource.id, H_SHARE_SETTINGS_FILENAME, token);

            if(settingsResp.ok){
                settings = await settingsResp.json() as HShareResourceSettings;

                //we assume the settings are updated if they contain all the required fields, since they do not change over time
                if(!settings.companyId || !settings.companyName || !settings.resourceName || !settings.publishParent){
                    settingsUpdated = false;
                }

                if(!isEqual(settings.defaultPresets, defaultPresets)){
                    settingsUpdated = false;
                }

                //we would check also consistency of settings like openChat and audioEnabled between the published and private resource,
                //but we have the isUpdated param that will be false if ANY mod has been made to the resource (including settings, presets, files, etc).
                //So, if isUpdated is false, we will republish the resource anyway.
            }
        }

        //if the settings are not updated, we update them
        if(!settingsUpdated){
            const user = await UserProfileUtils.getUserInfo(token);
            const companyId = user.vertex_tid;
            const companyName = user.vertex_tname;
            const resourceName = resource.name;
            const updatedSettings = {
                companyId: companyId,
                companyName: companyName,
                resourceName: resourceName,
                publishParent: resource.id,
                defaultPresets: defaultPresets,
                openChat: settings?.openChat ?? false,
                audioEnabled: settings?.audioEnabled ?? false
            } as HShareResourceSettings;

            const resp = await ResourceUtils.postAssetToResource(H_SHARE_SETTINGS_FILENAME, resource.id, JSON.stringify(updatedSettings, null, 2), token);
                            
            if(!resp.ok){
                console.log(`Failed to upload h share settings for ${resource.id}`);

                result.message = 'Failed to setup XR settings';//`Failed to upload h share settings`;
                return result;
            }
        }

        //if the resource is already updated and has zip and settings, we can return the link
        if(isUpdated && hasZip && settingsUpdated && resource.publishedResources?.length && resource.publishedResources[0] != null) {
            result.result = Result.Success;

            //return the link
            const link = await QrUrlShortenerApi.getLink(resource.publishedResources[0]);

            if(link && link.shortUrl){
                result.value = link.shortUrl;
                return result;
            }

            result.value = `${H_SHARE_DOMAIN}${resource.publishedResources[0]}`;

            return result;
        }

        //since something is missing, we need to re-create the zip and republish the resource, so we delete the old zip first, just in case
        if(hasZip) {
            ResourceUtils.deleteAssetFromResource(resource.id, GENERATED_ZIP_FILENAME, token).then((resp) => {
                if(!resp.ok) {
                    console.log(`Failed to delete older .zip file from resource ${resource.id}`);
                }
            });
        }

        try {
            //create the zip
            const zipperResp = await ResourceUtils.createResourceZip(resource.id, token);

            //check if the zip has been created and if not, return null
            if(zipperResp == null || !zipperResp.ok){
                console.log(`Failed to create ZIP for ${resource.id}}`);

                result.message = `Failed to prepare assets`;
                return result;
            }

            //if the resource is already published, we republish it, otherwise we publish it
            if (resource.publishedResources?.length) {
                const resp = await ResourceUtils.republishResource(resource.id, token);

                if(resp.ok) {
                    publishedResourceId = await resp.text();
                } else {
                    console.log(`Failed to republish resource ${resource.id}, error ${await resp.text()}`);
                    
                    result.message = `Failed to republish resource`;
                    return result;
                }
            } else {
                const resp = await ResourceUtils.publishResource(resource.id, token);

                if(resp.ok) {
                    publishedResourceId = await resp.text();
                } else {
                    console.log(`Failed to publish ${resource.id}, error ${await resp.text()}`);
                    
                    result.message = `Failed to publish resource`;
                    return result;
                }
            }
        } catch {
            console.log(`Failed to create ZIP for ${resource.id}`);
            
            result.message = `Failed to prepare assets`;
            return result;
        }

        //Regular expression to clean the GUID
        publishedResourceId = publishedResourceId.match(/[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/i)[0];

        if(!publishedResourceId){
            console.log(`Failed to get published resource GUID for ${resource.id}`);

            result.message = `Failed to get published resource info`;
            return result;
        }

        result.result = Result.Success;

        //return the link
        const link = await QrUrlShortenerApi.getLink(publishedResourceId);

        if(link && link.shortUrl){
            result.value = link.shortUrl;
            return result;
        }

        result.value = `${H_SHARE_DOMAIN}${publishedResourceId}`;
        return result;
    }
    
    static async createHShareResourceSettings(resource: VERTEXResource, token?: string): Promise<Response> {
        try {
            const user = await UserProfileUtils.getUserInfo(token);
            const companyId = user.vertex_tid;
            const companyName = user.vertex_tname;
            const resourceName = resource.name;
            const defaultPresets = await ChangeMaterialModelUtils.getDefaultPresetIndexes(resource.id);

            const newSettings = {
                companyId: companyId,
                companyName: companyName,
                resourceName: resourceName,
                publishParent: resource.id,
                defaultPresets: defaultPresets
            } as HShareResourceSettings;
    
            const resp = await ResourceUtils.postAssetToResource(H_SHARE_SETTINGS_FILENAME, resource.id, JSON.stringify(newSettings, null, 2), token);
                            
            if(!resp.ok){
                console.log(`Failed to upload h share settings for ${resource.id}`);
            }

            return resp;
        } catch {
            console.log(`Failed to upload h share settings for ${resource.id}`);
            return null;
        }
    }

    static async openResource(resource: VERTEXResource, newTab: boolean = false) {
        const hevoResourceType = ResourceUtils.getHevolusResourceType(resource);

        if (hevoResourceType === HevolusResourceType.Space && !resource.tags.includes(APP_AUGMENTEDSTORE_TAG)) {
            Swal.fire({
                icon: "info",
                title: "Generating link...",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                heightAuto: false
            });

            Swal.showLoading();

            const code = await Utils.generateCode(resource.id);
            let domain = "";

            if (resource.tags.includes(APP_REMOTESELLING_TAG)) {
                domain = REMOTESELLING_DOMAIN;
            }
            else if (resource.tags.includes(APP_HOLOMUSEUM_TAG)) {
                domain = HOLOMUSEUM_DOMAIN;
            }
            else if (resource.tags.includes(APP_HOLOPROTOTYPE_TAG)) {
                domain = HOLOPROTOTYPE_DOMAIN;
            }

            Swal.fire({
                icon: 'success',
                title: 'Link generated',
                text: `${domain}?room=${code}`,
                confirmButtonText: "Copy",
                heightAuto: false
            }).then((result) => {
                if (result.value) {
                    navigator.clipboard.writeText(`${domain}?room=${code}`);
                }
            });
        } else if (hevoResourceType === HevolusResourceType.Model ||
            hevoResourceType === HevolusResourceType.Media ||
            hevoResourceType === HevolusResourceType.Base ||
            hevoResourceType === HevolusResourceType.Catalog) {
            Utils.launchModelEditor(resource.id, newTab);
        } else if (hevoResourceType === HevolusResourceType.Space && resource.tags.includes(APP_AUGMENTEDSTORE_TAG)) {
            const spaceEditorVersionTag = resource.tags.find(t => t.startsWith(SPACE_EDITOR_VERSION_PREFIX_TAG));

            if(spaceEditorVersionTag){
                let isValid = await isMajorGte(spaceEditorVersionTag, HevolusApp.SpaceEditor);

                if(isValid){
                    Utils.launchSpaceEditor(resource.id, newTab);
                }
                else{
                    Swal.fire({
                        icon: 'warning',
                        title: 'You can not open this space',
                        text: 'This space was generated with an older version of the Space Editor which is not compatible with the current one',
                        //footer: `Created with version v${spaceEditorVersion}\nCurrent version is v${Utils.CURRENT_SPACE_EDITOR_VERSION}`,
                        timer: 5000,
                        heightAuto: false
                    });
                }
            }
            else{
                await Swal.fire({
                    icon: 'info',
                    title: 'Space editor version not specified',
                    text:   `This space was generated with an older version of the Space Editor.
                            You can inspect the space, but you will not be able to save it. It is suggested to recreate the space.`,
                    //footer: `Current Space Editor version is v${Utils.CURRENT_SPACE_EDITOR_VERSION}`,
                    showConfirmButton: true,
                    showCancelButton: true,
                    heightAuto: false
                }).then((result) => {
                    if (result.value) {
                        Utils.launchSpaceEditor(resource.id, newTab);
                    }
                });
            }
        } else if (hevoResourceType === HevolusResourceType.Deck) {
            Utils.launchDeckEditor(resource.id, newTab);
        } else if (hevoResourceType === HevolusResourceType.Room) {
            if (resource.tags.includes(APP_HEVOCOLLABORATION_TAG)) {
                const code = await Utils.generateCode(resource.id);
                Utils.launchHevoCollabWebViewer(code, true);
            }
        }
    }

    static getHevolusResourceTypeByString(type: string){
        let result = HevolusResourceType.None;

        switch(type){
            case"Base":{
                result = HevolusResourceType.Base;
                break;
            }
            case"Model":{
                result = HevolusResourceType.Model;
                break;
            }
            case"Media":{
                result = HevolusResourceType.Media;
                break;
            }
            case"Space":{
                result = HevolusResourceType.Space;
                break;
            }
            case"Room":{
                result = HevolusResourceType.Room;
                break;
            }
            case"Deck":{
                result = HevolusResourceType.Deck;
                break;
            }
            case"Catalog":{
                result = HevolusResourceType.Catalog;
                break;
            }
            case"TenantInfo":{
                result = HevolusResourceType.TenantInfo;
                break;
            }
            case"All":{
                result = HevolusResourceType.All;
                break;
            }
        }

        return result;
    }

    static getHevolusResourceType(resource: VERTEXResource): HevolusResourceType {
        let result = HevolusResourceType.None;

        //system resources can be of any type, thus we check this in advance
        //TODO: [Franz] wait that sys-type-system is added and then decomment the if clause and remove the current one.
        // if(resource.tags.includes(Utils.TYPE_SYSTEM_TAG)){
        //     result = HevolusResourceType.System;
        if(resource){
            if(resource.tags.includes(TENANT_INFO_TAG) || resource.tags.includes(PLATFORM_VERSIONING_TAG)){
                if (resource.tags.includes(TENANT_INFO_TAG)) {
                    result = HevolusResourceType.TenantInfo;
                }
                else if(resource.tags.includes(PLATFORM_VERSIONING_TAG)){
                    result = HevolusResourceType.Versioning;
                }
            }
            else{
                if (resource.type === VertexResourceType.MeshAsset) {
                    if (resource.tags.includes(TYPE_MODEL_TAG)) {
                        result = HevolusResourceType.Model;
                    }
                    else if (resource.tags.includes(TYPE_BASE_TAG)) {
                        result = HevolusResourceType.Base;
                    }
                    else if (resource.tags.includes(TYPE_AVATAR_TAG)){
                        result = HevolusResourceType.Avatar;
                    }
                }
                else if (resource.type === VertexResourceType.Data) {
                    if (resource.tags.includes(TYPE_MEDIA_TAG)) {
                        result = HevolusResourceType.Media;
                    }
                    else if (resource.tags.includes(TYPE_DECK_TAG)) {
                        result = HevolusResourceType.Deck;
                    }
                    else if (resource.tags.includes(TYPE_CATALOG_TAG)) {
                        result = HevolusResourceType.Catalog;
                    }
                }
                else if (resource.type === VertexResourceType.SceneAsset) {
                    if (resource.tags.includes(TYPE_SPACE_TAG)) {
                        result = HevolusResourceType.Space;
                    }
                    else if (resource.tags.includes(TYPE_ROOM_TAG)) {
                        result = HevolusResourceType.Room;
                    }
                }
            }
        }

        return result;
    }

    static getVertexResourceType(hevolusType: HevolusResourceType): VertexResourceType {
        let result = VertexResourceType.None;

        if (hevolusType === HevolusResourceType.Base || hevolusType === HevolusResourceType.Model ||
            hevolusType === HevolusResourceType.Avatar) {
            result = VertexResourceType.MeshAsset;
        }
        else if (hevolusType === HevolusResourceType.Media || hevolusType === HevolusResourceType.Deck ||
            hevolusType === HevolusResourceType.Catalog || hevolusType === HevolusResourceType.TenantInfo ||
            hevolusType === HevolusResourceType.Versioning) {
            result = VertexResourceType.Data;
        }
        else if (hevolusType === HevolusResourceType.Space || hevolusType === HevolusResourceType.Room) {
            result = VertexResourceType.SceneAsset;
        }

        return result;
    }

    static async getResourceData(id: string, token?: string): Promise<VERTEXResource> {
        try {
            const res = await fetch(`${RESOURCE_API_URI}${id}`, {
                headers: {
                    "Authorization": `Bearer ${token || Vertex.Globals.bearerToken}`
                }
            });

            if (res.ok) {
                return await res.json();
            } else {
                console.log(`error getting resource ${id} data: status: ${res.status}, status text: ${res.statusText}`);
                return null;
            }
        }
        catch {
            console.log(`Failed to fetch resource data for ${id}`);
            return null;
        }
    }

    static async createNewResource(name: string, type: string, tags: string[], filterTags: boolean = true, id?: string, token?: string): Promise<string> {

        //filters out tags which are hidden, HM, RS, AS tags
        try {
            token = token || Vertex.Globals.bearerToken;        

            let filteredTags = tags.filter(tag => !isEmpty(tag));

            if (filterTags) {
                filteredTags = Utils.filterSystemTags(tags);
            }

            const res = await fetch(`${RESOURCE_API_URI}`, {
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                },
                method: "POST",
                body: `{${id ? `"id": "${id}", ` : ""}"name": "${name}", "type": "${type}", "tags": ${JSON.stringify(filteredTags)}}`
            })

            if (res.ok) {
                return (await res.json()).id;
            } else {
                console.log("error creating resource: " + res.status + res.statusText);
            }
        } catch (error) {
            console.log(`Error while creating resource: ${error}`);
        }

    }
    
    /**
     * Fetch resource data
     * @param id resource id to fetch from
     * @param published indicate to look for published or private resource data
     * @param token auth bearer token to use
     * @returns the resource data or null
     */
    static async getResourceAsync(id: string, published = true, token?: string): Promise<VERTEXResource> {
        token = token || Vertex.Globals.bearerToken;
        let result: VERTEXResource = null;
        
        const resource = await ResourceUtils.getResourceData(id, token);

        if(resource){             
            let isPrivateRes = !resource.publishParent;

            if (published) {
                if (isPrivateRes) {
                    let publishedId = resource.publishedResources[0];

                    if (publishedId) {
                        const publishedRes =  await ResourceUtils.getResourceData(publishedId, token);
                        
                        if (publishedRes) {
                            result = publishedRes;
                        }
                        else {
                            console.warn("Error fetching published version with id: " + publishedId);
                            result = null;
                        }
                    }
                    else {
                        console.warn("The resource " + resource.id + " does not have a published version.");
                        result = null;
                    }
                } else {
                    result = resource;
                }
            }
            else {
                if(isPrivateRes){
                    result = resource;
                }
                else{
                    const privateRes = await ResourceUtils.getResourceData(resource.publishParent, token);

                    if(privateRes){
                        result = privateRes;
                    }
                    else{
                        console.warn(`Failed to get private resource data for published id: ${resource.id}`);
                        result = null;
                    }
                }
            }
        } else {
            console.warn(`error getting resource ${id} data.`);
            result = null;
        }

        return result;
    }

    static async getResourcesWithParamsAsync(tags: string[] = [], tagMode: TagMode = TagMode.Any, type: VertexResourceType = VertexResourceType.None, fullResources = false, published = false, tenantWise = true, token?: string): Promise<VERTEXResource[]> {
        token = token || Vertex.Globals.bearerToken;
        let result: VERTEXResource[] = [];

        if (published && tenantWise) {
            const privateResources = await this.getResourcesWithParamsAsync(tags, tagMode, type, false, false, true, token);

            if (privateResources) {
                for (let i = 0; i < privateResources.length; i++) {
                    if (privateResources[i].publishedResources.length > 0) {

                        //else {
                            let resource = {
                                id: privateResources[i].publishedResources[0],
                                name: privateResources[i].name,
                                publishParent: privateResources[i].id,
                                type: privateResources[i].type,
                                tags: privateResources[i].tags,
                                created: null,
                                modified: null,
                                publishedResources: null,
                                resourceKeys: null
                            };

                            result.push(resource);
                        //}
                    }
                }
            }
        }
        else {
            let uri = RESOURCE_API_URI;

            if (type !== VertexResourceType.None) {
                uri += "?type=" + type;
            }

            if (tags?.length) {
                if (uri.indexOf('?') > 0) {
                    uri += "&tags=" + tags[0];
                }
                else {
                    uri += "?tags=" + tags[0];
                }

                for (let i = 1; i < tags.length; i++) {
                    uri += "&tags=" + tags[i];
                }
            }

            uri += "&tagmode=" + tagMode;
            uri += "&publishedOnly=" + published;

            try {
                const response = await fetch(uri, {
                    headers: {
                        "Authorization": `Bearer ${token || Vertex.Globals.bearerToken}`
                    }
                });

                if (response.ok) {
                    try {
                        result = await response.json();
                    }
                    catch (ex) {
                        console.log("Failed to parse respone for " + uri);
                        console.error("Get res with params failed to deserialize json response", { respone: response, uri: uri, tags: tags, published: published, tagMode: tagMode, type: type });
                    }
                }
                else {
                    console.log(response.status + " " + response.statusText);
                    console.error("Get res with params response not ok", { respone: response, uri: uri, tags: tags, published: published, tagMode: tagMode, type: type });
                }
            }
            catch (e) {
                console.log("Failed to fetch: " + uri);
                console.error("Get res with params completely failed", { error: e, tags: tags, published: published, tagMode: tagMode, type: type });
            }
        }

        if(fullResources){
            for(let i = result.length - 1; i >= 0; i--){
                const fullRes = await ResourceUtils.getResourceData(result[i].id, token);

                if(fullRes){
                    result[i] = fullRes;
                }
                else{
                    result.splice(i, 1);
                }
            }                          
        }

        return result;
    }

    static async getAllTenantResourcesAsync(): Promise<VERTEXResource[]> {
        const resources = await fetch(`${RESOURCE_API_URI}`, {
            headers: {
                "Authorization": `Bearer ${Vertex.Globals.bearerToken}`
            }
        })

        if (resources.ok) {
            return await resources.json();
        } else {
            console.log("error getting resource data: " + resources.status + resources.statusText);
            return null;
        }
    }

    static async getPublicLinks(token?: string): Promise<string[]> {
        let result: string[] = null;

        //try hipexr first
        try{
            const publicLinksItem = await CompaniesApi.getCompanyItemAsync(HSTORE_COMPANY_PUBLIC_LINKS_ITEM_ID, token);

            if(publicLinksItem && publicLinksItem.itemValue){
                result = JSON.parse(publicLinksItem.itemValue) as string[];
            }            
        }
        catch(e){
            console.log("Failed to retrieve Public Link list from Hipexr", e);
        }


        if(result == null){
            try{
                const tenantInfoResources = await ResourceUtils.getResourcesWithParamsAsync([TENANT_INFO_TAG], TagMode.All, ResourceUtils.getVertexResourceType(HevolusResourceType.TenantInfo), false, true, true);
    
                if(tenantInfoResources?.length && tenantInfoResources[0]){
                    const publicLinksRes = await ResourceUtils.getAssetFromResource(tenantInfoResources[0].id, AS_PUBLIC_CATALOG_FILENAME);
    
                    if(publicLinksRes.ok){
                        result = await publicLinksRes.json() as string[];
                    }
                }
            }
            catch(e){
                console.log("Failed to retrieve Public Link list", e);
            }
        }

        return result ?? [];
    }

    static async isPublicLink(id: string){
        if(!id){
            return false;   
        }
        
        return (await ResourceUtils.getPublicLinks()).includes(id);
    }

    static async copyAssetsToResource(sourceResourceId: string, destResourceId: string, files: string[], token?: string): Promise<any>{
        if(!sourceResourceId || !destResourceId || !files?.length){
            return;
        }

        token = token || Vertex.Globals.bearerToken;

        const asyncTasks: Promise<void>[] = [];
        let success = true;

        for(let i = 0; i < files.length; i++){
            const task = (async () => {
                try{
                    const file = files[i];
                    const getProm = ResourceUtils.getAssetFromResource(sourceResourceId, file, token);

                    const blob = getProm.then(resp => {
                        if(resp.ok){
                            return resp.blob();
                        }
                        else{
                            success = false;
                        }
                    });

                    const postProm = blob.then(b => {
                        if(b){
                            return ResourceUtils.postAssetToResource(file, destResourceId, b, token);
                        }
                        else{
                            success = false;
                        }
                    });

                    await postProm;
                }
                catch(e){
                    console.error("An error occurred while copying assets:", e);
                    success = false; // Task failed
                }
            })();

            asyncTasks.push(task);    
        }

        await Promise.all(asyncTasks);

        return success;
    }

    static async getThumbnailBlobUrl(resourceId: string, token?: string): Promise<string> {
        return await this.getAssetBlobUrl(resourceId, RESOURCE_THUMB_FILENAME, token);
    }

    static async getAssetBlobUrl(resourceId: string, fileName: string, token? : string): Promise<string> {
        if (resourceId == null || fileName == null) {
            return "";
        }

        const url = `${RESOURCE_API_URI}${resourceId}/${fileName}`;

        token = token || Vertex.Globals.bearerToken;

        try {
            let res = await fetch(url, {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                }
            });

            if (res.ok) {
                let blob = await res.blob();
                return URL.createObjectURL(blob);
            } else {
                // console.error("error getting file: " + url + " " + res.status + res.statusText);
                return null;
            }
        }
        catch {
            return null;
        }

    }

    static async getAssetSizeFromResource(resourceId: string, assetName: string, token?: string): Promise<number> {

        const res = await fetch(`${RESOURCE_API_URI}${resourceId}/${assetName}`, {
            headers: {
                "Authorization": `Bearer ${token || Vertex.Globals.bearerToken}`
            },
            method: "HEAD"
        })

        if (res.ok) {
            return Number(res.headers.get("Content-Length"));
        } else {
            console.log(`Error getting asset size for ${assetName}`);
            return 0;
        }
    }
    
    static async getAssetFromResource(resourceId: string, assetName: string, token?: string): Promise<Response> {

        const res = await fetch(`${RESOURCE_API_URI}${resourceId}/${assetName}`, {
            headers: {
                "Authorization": `Bearer ${token || Vertex.Globals.bearerToken}`
            }
        })

        return res;
    }

    static async deleteMediaFromResource(resourceId: string, assetName: string, token?: string): Promise<Response[]>{
        const res = await this.getResourceAsync(resourceId, false, token) as VERTEXResource;
        
        if(res == null || res.resourceKeys == null){
            console.error(`Failed to retrieve resource ${resourceId}`);
            
            return;
        }

        const assetBaseName = Utils.getFileBaseName(assetName);
        const assetExtension = Utils.getFileExtension(assetName);
        const filesToDelete = [];

        const addAssetToDeleteList = (ext) => {
            const fileName = `${assetBaseName}.${ext}`;
            
            if(res.resourceKeys.includes(fileName) && !filesToDelete.includes(fileName)){
                filesToDelete.push(fileName);
            }
        }

        if(SUPPORTED_AUDIO_FORMATS.includes(assetExtension)){
            SUPPORTED_AUDIO_FORMATS.forEach(addAssetToDeleteList);
        }
        else if(ALLOWED_IMAGE_TYPES.includes(assetExtension) || COMPRESSED_IMAGE_TYPES.includes(assetExtension)){
            ALLOWED_IMAGE_TYPES.forEach(addAssetToDeleteList);
            COMPRESSED_IMAGE_TYPES.forEach(addAssetToDeleteList);
        }
        else if(SUPPORTED_VIDEO_FORMATS.includes(assetExtension)){
            SUPPORTED_VIDEO_FORMATS.forEach(addAssetToDeleteList);
        }
        else if(ALLOWED_DOC_TYPES.includes(assetExtension)){
            ALLOWED_DOC_TYPES.forEach(addAssetToDeleteList);
        }
        else if(ALLOWED_PDF_TYPES.includes(assetExtension)){
            ALLOWED_PDF_TYPES.forEach(addAssetToDeleteList);
        }

        if(filesToDelete.length > 0){
            const deleteTasks = filesToDelete.map(fileName => this.deleteAssetFromResource(resourceId, fileName, token));
            
            return await Promise.all(deleteTasks);
        }
    }

    static async deleteAssetFromResource(resourceId: string, assetName: string, token?: string, deleteVariants: boolean = false): Promise<Response> {
        const res = await fetch(`${RESOURCE_API_URI}${resourceId}/${assetName}`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${token || Vertex.Globals.bearerToken}`
            }
        });

        return res;
    }

    static async deleteAllAssetsFromResource(resourceId?: string, token?: string) {
        resourceId = resourceId ?? Vertex.Globals.spaceId;
        token = token || Vertex.Globals.bearerToken;

        if(!resourceId || !token){
            return;
        }

        const resource = await ResourceUtils.getResourceAsync(resourceId, false, token);

        if(resource?.resourceKeys){
            const tasks = [];

            for(let i = 0; i < resource.resourceKeys.length; i++){
                const file = resource.resourceKeys[i];
                const promise = new Promise((resolve, reject) => {
                    if(file){
                        ResourceUtils.deleteAssetFromResource(resourceId, file, token).then(resp => {
                            resolve(resp);
                        });
                    }
                    else{
                        resolve(null);
                    }
                });

                tasks.push(promise);
            }

            return await Promise.all(tasks);
        }
    }

    static async deleteResource(resourceId: string, unpublishFirst: boolean = true, token?: string): Promise<boolean> {
        let result: boolean = false;
        let unpublished: boolean = true;

        if (unpublishFirst) {
            unpublished = await ResourceUtils.unpublishResource(resourceId);
        }

        if (unpublished) {
            let res = await fetch(`${RESOURCE_API_URI}${resourceId}?force=true`, {
                method: "DELETE",
                headers: {
                    "Authorization": `Bearer ${token || Vertex.Globals.bearerToken}`
                }
            });

            if (res.ok) {
                result = true;
            }
        }

        return result;
    }

    static async postAssetToResource(assetName: string, resourceId: string, bodyContent: BodyInit, token?: string): Promise<Response> {
            let res = await fetch(`${RESOURCE_API_URI}${resourceId}/${assetName}`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${token || Vertex.Globals.bearerToken}`,
                    "Content-Type": "application/octet-stream"
                },
                body: bodyContent
            });

            return res;
    }

    static async publishResource(resourceId: string, token?: string): Promise<Response> {

        let bodyContent = {
            sourceId: resourceId,
            publishId: null
        }

        let resp = await fetch(`${RESOURCE_API_URI}publish/${resourceId}`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${token || Vertex.Globals.bearerToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(bodyContent)
        })

        return resp;
    }

    static async republishResource(sourceResourceId: string, token?: string): Promise<Response> {

        let resData = await ResourceUtils.getResourceData(sourceResourceId, token);
        let publishedResources = resData.publishedResources;

        if (!publishedResources && publishedResources.length <= 0) {
            return null;
        }

        //takes as true that there is only one published resource
        let publishedResourceId = resData.publishedResources[0];

        let bodyContent = {
            sourceId: sourceResourceId,
            publishId: publishedResourceId
        }

        let res = await fetch(`${RESOURCE_API_URI}publish/${sourceResourceId}`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${token || Vertex.Globals.bearerToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(bodyContent)
        })

        return res;
    }

    static async unpublishResource(sourceResourceId: string, token?: string): Promise<boolean> {
        token = token || Vertex.Globals.bearerToken;
        
        let resData = await ResourceUtils.getResourceData(sourceResourceId, token);

        if(!resData || !resData.publishedResources){
            return false;
        }

        if (resData.publishedResources.length === 0) {
            return true;
        }

        return await this.deleteResource(resData.publishedResources[0], true, token);
    }

    static async getTenantResource(published = true, token?: string): Promise<VERTEXResource>{
        token = token || Vertex.Globals.bearerToken;

        const tenantInfoResources = await ResourceUtils.getResourcesWithParamsAsync([TENANT_INFO_TAG], TagMode.All, ResourceUtils.getVertexResourceType(HevolusResourceType.TenantInfo), false, published, true, token);

        if(tenantInfoResources?.length && tenantInfoResources[0]){
            return tenantInfoResources[0];
        }	
    }

    /**
     * Get or create the tenant info resource
     * @param published 
     * @param token 
     * @returns the tenant info resource or null if error
     */
    static async getOrCreateTenantResource(published = true, token?: string): Promise<VERTEXResource>{
        token = token || Vertex.Globals.bearerToken;

        const tenantInfoResources = await ResourceUtils.getResourcesWithParamsAsync([TENANT_INFO_TAG], TagMode.All, ResourceUtils.getVertexResourceType(HevolusResourceType.TenantInfo), false, false, true, token);

        let tenantInfoResource = tenantInfoResources?.[0] as VERTEXResource;

        if(!tenantInfoResource){
            const res = {
                name: TENANT_INFO_RESOURCE_NAME,
                type: ResourceUtils.getVertexResourceType(HevolusResourceType.TenantInfo),
                tags: [TYPE_SYSTEM_TAG, TENANT_INFO_TAG, HIDDEN_RESOURCE_TAG]
            }
    
            const id = await ResourceUtils.createNewResource(res.name, res.type, res.tags, false, null, token);
        
            if(id){
                const resp = await ResourceUtils.publishResource(id, token);

                if(resp.ok){
                    if(published){
                        tenantInfoResource = await ResourceUtils.getTenantResource(published, token);
                    }
                    else{
                        tenantInfoResource = await ResourceUtils.getResourceData(id, token);
                    }
                }
                else{
                    console.error("Failed to publish Tenant Info resource");
                }
            }
            else{
                console.error("Failed to create Tenant Info resource");
            }
        }
        else{
            if(published){
                if(!tenantInfoResource.publishedResources?.length){
                    const resp = await ResourceUtils.publishResource(tenantInfoResource.id, token);

                    if(resp.ok){
                        tenantInfoResource = await ResourceUtils.getTenantResource(published, token);
                    }
                    else{
                        console.error("Failed to publish Tenant Info resource");
                    }
                }
                else{
                    tenantInfoResource = await ResourceUtils.getResourceData(tenantInfoResource.publishedResources[0], token);
                }
            }
        }

        return tenantInfoResource;
    }

    static async getHShareTenantSettings(token?: string): Promise<HShareTenantSettings>{
        token = token || Vertex.Globals.bearerToken;
        
        let settings: HShareTenantSettings = null;

        const company = await CompaniesApi.getCompanyAsync(token, HSHARE_ITEM_GROUP, ItemType.Setting);

        if(company){
            const systemMessageItem = company.items.find(x => x.itemId === HSHARE_COMPANY_SYSTEMMESSAGE_ITEM_ID);
            const documentLanguageItem = company.items.find(x => x.itemId === HSHARE_COMPANY_DOCUMENTLANGUAGE_ITEM_ID);

            if(systemMessageItem && documentLanguageItem){
                settings = {
                    systemMessage: systemMessageItem?.itemValue,
                    documentLanguage: documentLanguageItem?.itemValue
                };
            }
        }

        if(settings == null){
            const tenantInfoResource = await ResourceUtils.getTenantResource(true, token);

            if(tenantInfoResource){
                const settingsResp = await ResourceUtils.getAssetFromResource(tenantInfoResource.id, H_SHARE_SETTINGS_FILENAME, token);
    
                if(settingsResp.ok){
                    settings = await settingsResp.json() as HShareTenantSettings;
                }
            }
        }

        return settings ?? DEFAULT_HSHARE_TENANT_SETTINGS;
    }

    static openInTexteditor(resourceId: string) {
        window.open(`https://${Vertex.Globals.vertexStackUrl}/editor/Index/${resourceId}`);
    }

    static editResourceName(resource: VERTEXResource) {

        let nameWrapper = document.getElementById("name-wrapper");
        let nameInput = document.getElementById("name-input") as HTMLInputElement;
        nameInput.spellcheck = false;
        nameInput.autocomplete = "off";
        let editNameButton = document.getElementById("edit-name");

        nameInput.removeAttribute("disabled");
        nameInput.classList.remove("input-disabled");
        editNameButton.classList.add("d-none");

        //save button
        let saveName = document.createElement("button");
        saveName.id = "save-name";
        saveName.classList.add("btn", "btn-small", "btn-success", "edit-button");
        let imgSave = document.createElement("img");
        imgSave.classList.add("button-icon");
        imgSave.src = "/img/save-name-icon.svg";
        saveName.appendChild(imgSave);
        nameWrapper.appendChild(saveName);

        saveName.addEventListener("click", async () =>{
            editNameButton.classList.remove("d-none");
            saveName.remove();
            nameInput.setAttribute("disabled","disabled");
            nameInput.classList.add("input-disabled");

            if(nameInput.value === resource.name){
                return;
            }

            Swal.fire({
                icon: "info",
                title: `Updating Resource name...`,
                heightAuto: false,
            });

            Swal.showLoading();

            let updated = false;

            nameInput.value = Utils.sanitizeString(nameInput.value.trim());
            
            if(nameInput.value && nameInput.value !== resource.name) {
                updated = await ResourceUtils.updateResourceName(resource, nameInput.value);

                //manually update local res object (just in case it can save a fetch elsewhere)
                if(updated){
                    resource.name = nameInput.value;
                }
            }

            if(updated){
                Swal.fire({
                    icon: "success",
                    title: `Resource name updated`,
                    heightAuto: false,
                });
            }
            else{
                nameInput.value = resource.name;

                Swal.fire({
                    icon: "error",
                    title: `Failed to update Resource name`,
                    heightAuto: false,
                });
            }
        });
    }

    static async updateResourceName(resource: VERTEXResource, newName: string) {
        const resourcesList = await ResourceUtils.getAllTenantResourcesAsync();

        if(!resourcesList){
            return false;
        }

        if (resourcesList) {
            const valid = resourcesList.find(x => x["name"] === newName && x["id"] !== resource.id) == null;

            if(!valid){
                return false;
            }
        }

        //setup post body for stringify
        let postBody = {
            id: resource.id,
            name: newName,
            type: resource.type,
        }

        const resp = await ResourceUtils.postResourceData(resource.id, JSON.stringify(postBody));

        return resp?.ok;
    }

    

    static getTagList(resources: VERTEXResource[]) {
        let tagList: string[] = [];

        //extracts tags from actual resources and removes duplicates 
        resources.forEach((resource) => {
            resource.tags.forEach(tag => {
                tagList.push(tag);
            });
            tagList.sort();
            // Use set to remove duplicates
            let filteredTags = new Set(tagList);
            tagList = [...filteredTags];
        });

        return tagList;
    }

    static async saveTagsWithId(tagArray: string[], resourceId?: string, notify: boolean = true, filterTags: boolean = true, mandatorySysTags: string[] = []) {
        const res = await ResourceUtils.getResourceData(resourceId);

        await ResourceUtils.saveTagsWithRes(tagArray, res, notify, filterTags, mandatorySysTags);
    }

    static async saveTagsWithRes(tagArray: string[], resourceToUpdate?: VERTEXResource, notify: boolean = true, filterTags: boolean = true, mandatorySysTags: string[] = [], token?: string): Promise<string[]> {
        token = token || Vertex.Globals.bearerToken;

        let filteredTags = tagArray;
        let resource = resourceToUpdate;

        if (resource == null && Vertex.Globals.spaceId) {
            resource = await ResourceUtils.getResourceData(Vertex.Globals.spaceId, token);
        }

        if (resource) {
            if (filterTags) {
                filteredTags = Utils.filterSystemTags(filteredTags);
            }

            //trim whitespace from array items
            filteredTags.forEach(item => {
                item.trim();
            });

            filteredTags = filteredTags.filter(tag => tag !== "");

            //add wanted sys tags
            for (let i = 0; i < mandatorySysTags.length; i++) {
                if (!filteredTags.includes(mandatorySysTags[i])) {
                    filteredTags.push(mandatorySysTags[i]);
                }
            }

            //setup postbody for stringify
            let postBody = {
                id: resource.id,
                name: resource.name,
                type: resource.type,
                tags: filteredTags
            }

            //post with new tags
            let res = await fetch(`${RESOURCE_API_URI}${resource.id}`, {
                method: "POST",
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(postBody)
            });

            //alert user
            if (res.ok) {
                console.log("Tags updated")
                //Vertex.Globals.event.fire("searchResources", { resourceId: passedId, tags: filteredTags });

                if (notify) {
                    //return for alert popup
                    let message = "Tags have been updated";
                    //await Utils.infoPopup("success", message);
                    Utils.notify(message, NotificationStatus.Success);
                }
            }
            //degug
            else {
                let text = await res.text();
                console.error(`Request failed:`, text);
            }
        }
        else {
            console.error(`Failed to update tags. Missing resource.`);
            return;
        }

        return filteredTags;
    }
        
    static async updateOrCreateAsTenantInfoResource() {
        //check public spaces
        const publicSpaces = await ResourceUtils.getResourcesWithParamsAsync([TYPE_SPACE_TAG, APP_AUGMENTEDSTORE_TAG, SPACEPRIVACY_PUBLIC_TAG], TagMode.All, ResourceUtils.getVertexResourceType(HevolusResourceType.Space), false, true);
        const asTenantPublicSpaces: AsTenantPublicSpace[] = [];

        if (publicSpaces?.length) {
            for (let i = 0; i < publicSpaces.length; i++) {
                asTenantPublicSpaces.push({ name: publicSpaces[i].name, id: publicSpaces[i].id });
            }
        }

        //we have public spaces, now check tenant info resource
        let tenantInfoRes: VERTEXResource = null;
        const tenantInfoResources = await ResourceUtils.getResourcesWithParamsAsync([TENANT_INFO_TAG], TagMode.All, ResourceUtils.getVertexResourceType(HevolusResourceType.TenantInfo));

        if (tenantInfoResources) {
            //if it does not exist
            if (tenantInfoResources.length === 0) {
                const newTenantInfoResId = await ResourceUtils.createNewResource(TENANT_INFO_RESOURCE_NAME, ResourceUtils.getVertexResourceType(HevolusResourceType.TenantInfo), [TYPE_SYSTEM_TAG, TENANT_INFO_TAG, HIDDEN_RESOURCE_TAG], false);

                if (newTenantInfoResId) {
                    tenantInfoRes = await ResourceUtils.getResourceAsync(newTenantInfoResId, false);
                }
            }
            //if it already exists
            else {
                //query public spaces and check against json file
                if (tenantInfoResources.length > 0) {
                    tenantInfoRes = tenantInfoResources[0];

                    //there are more than 1 tenant info resource, keep 1 and delete the others
                    for (let i = 1; i < tenantInfoResources.length; i++) {
                        await ResourceUtils.deleteResource(tenantInfoResources[i].id, true);
                    }
                }
            }

            if(tenantInfoRes){
                //post public space json file
                await ResourceUtils.postAssetToResource(AS_PUBLIC_CATALOG_FILENAME, tenantInfoRes.id, JSON.stringify(asTenantPublicSpaces, null, 2));

                //publish or republish resource
                if (tenantInfoRes.publishedResources?.length) {
                    const response = await ResourceUtils.republishResource(tenantInfoRes.id);

                    if (!response.ok) {
                        console.log(`Failed to republish tenant info resource with id: ${tenantInfoRes.id}`);
                    }
                }
                else {
                    const response = await ResourceUtils.publishResource(tenantInfoRes.id);

                    if (!response.ok) {
                        console.log(`Failed to publish tenant info resource with id: ${tenantInfoRes.id}`);
                    }
                }
            }
        }
        else {
            console.log(`Error while retrieving tenant info resources.`);
        }
    }

    static async getOrCreateCatalog (appTag: string, contentTag: string, token?: string) {
        token = token ?? Vertex.Globals.bearerToken;
        let result: VERTEXResource = null;
        let availableCatalogs: VERTEXResource[];
        let catalog: VERTEXResource;
        let jsonCatalogName = "";
        let hasJsonCatalog = true;
        let isReady = false;
    
        const tags = [
            TYPE_CATALOG_TAG,
            appTag,
            contentTag,
            HIDDEN_RESOURCE_TAG
        ].filter((t) => t);
    
        if (contentTag === CONTENT_TAG_TAG) {
            jsonCatalogName = TAGS_CATALOG_FILENAME;
        }
        else if (contentTag === CONTENT_QR_TAG) {
            jsonCatalogName = QRS_CATALOG_FILENAME;
        }
    
        availableCatalogs = await ResourceUtils.getResourcesWithParamsAsync(tags, TagMode.All, ResourceUtils.getVertexResourceType(HevolusResourceType.Catalog), false, false, true, token);
    
        if (availableCatalogs?.length) {
            if (availableCatalogs.length > 1) {
                console.log(`Found more than one catalog with tags ${tags}`);
            }
    
            catalog = await ResourceUtils.getResourceData(availableCatalogs[availableCatalogs.length - 1].id, token);
    
            if (catalog && (!catalog.resourceKeys?.length || !catalog.resourceKeys.includes(jsonCatalogName))) {
                console.log(`No catalog found for tag ${appTag}. Creating catalog now...`);
                hasJsonCatalog = false;
            }
            else {
                isReady = true;
            }
        } else {
            console.log(`No catalog resource found for tag ${appTag}. Creating catalog resource and catalog now...`);
    
            let catalogName = "";
    
            if (contentTag === CONTENT_TAG_TAG) {
                catalogName = appTag.slice(appTag.lastIndexOf("-") + 1) + " tags catalog";
            }
            else if (contentTag === CONTENT_QR_TAG) {
                catalogName = QR_CATALOG_RESOURCE_NAME;
            }
    
            const catalogResourceId = await ResourceUtils.createNewResource(catalogName, ResourceUtils.getVertexResourceType(HevolusResourceType.Catalog), tags, false, null, token);
            catalog = await ResourceUtils.getResourceData(catalogResourceId, token);
    
            hasJsonCatalog = false;
        }
    
        if (!hasJsonCatalog) {
            let resp = await ResourceUtils.postAssetToResource(jsonCatalogName, catalog.id, "[]", token);
    
            if (!resp.ok) {
                console.log(`Unable to upload catalog to resource "${catalog.id}".`);
            } else {
                isReady = true;
            }
        }
    
        if (isReady) {
            result = catalog;
        }
        else {
            console.log("Failed to get catalog.");
        }
    
        return result;
    }

    static async postResourceData(id: string, bodyContent: BodyInit, token? : string): Promise<Response> {
        try {
            let resp = await fetch(`${RESOURCE_API_URI}${id}`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${token || Vertex.Globals.bearerToken}`,
                    "Content-Type": "application/json"
                },
                body: bodyContent
            });

            return resp;

        } catch (error) {
            console.error(`Error while posting resource data: ${error}`);
        }
    }

    /**
     * 
     * @returns default avatars id array or undefined
     */
    static async getDefaultAvatarsCatalog(): Promise<string[]>{
        const catalogResources = await ResourceUtils.getResourcesWithParamsAsync([TYPE_CATALOG_TAG, CONTENT_AVATAR_TAG], TagMode.All, this.getVertexResourceType(HevolusResourceType.Catalog), false, true, false);

        if(catalogResources?.length){
            const catalogResp = await ResourceUtils.getAssetFromResource(catalogResources[0].id, DEFAULT_AVATARS_CATALOG_FILENAME);

            if(catalogResp?.ok){
                const json = await catalogResp.json() as string[];

                if(json){
                    return json;
                }
            }
        }
    }
}
import { blurPixelShader } from "babylonjs/Shaders/blur.fragment";
import { cloneDeep, difference } from "lodash";
import { NotificationStatus, Utils } from "../../utilities/utils";
import { CustomMediaTextureComponent, Media } from "../componentsystems/NodeComponents/mediaTexture";
import { CustomBooleanInspector } from "./custombooleanrenderer";
import { CustomNumberfieldInspector } from "./customnumberfieldrenderer";
import { UploadUtils } from "../../utilities/upload-utilities";
import { ALLOWED_IMAGE_TYPES, ALLOWED_VIDEO_TYPES, ALLOWED_AUDIO_TYPES, ALLOWED_DOC_TYPES, DEFAULT_MEDIA_VOLUME, MEDIA_CONVERSION_STATUS_FILENAME, SUPPORTED_AUDIO_FORMATS, SUPPORTED_VIDEO_FORMATS } from "../../utilities/constants";
import { FileMediaType, ResourceUtils, VERTEXResource } from "../../utilities/resource-utilities";
import { Status, FileConversionStatus, ConversionUtils } from "../../utilities/conversion-utilities";
import Swal from "sweetalert2";

export class CustomMediaTextureRenderer implements Vertex.UI.ICustomInspectorRenderer {
    mediaListContainer: HTMLDivElement;
    includedMediaLabel: HTMLDivElement;
    includedMediaEmptyListMsg: HTMLSpanElement;
    mediaListView: HTMLUListElement;
    excludedMediaLabel: HTMLDivElement;
    excludedMediaEmptyListMsg: HTMLSpanElement;

    constructor(targetNode: Vertex.NodeComponentModel.VertexNode) {
        this.targetNode = targetNode;
    }

	medias: Media[] = [];

    targetNode: Vertex.NodeComponentModel.VertexNode;
    target: CustomMediaTextureComponent;
    property: string;

    static currentRenderer: CustomMediaTextureRenderer;
    static awaitedMediaConversionItems: HTMLElement[] = [];
    onChanged;

    RenderProperty(property: string, target: CustomMediaTextureComponent): HTMLDivElement {
        this.target = target;
        this.property = property;

        let outer = document.createElement('div');
        outer.classList.add('w-100', 'd-flex', 'flex-column', 'align-items-stretch');
        outer.style.order = '-10'; // order -10 to pull this to the top, but not above the heading

        //remove padding from media texture 
        Utils.waitForCondition(_ => outer.parentElement != null, 500).then( _ => {
            outer.parentElement.style.padding = "0";
        })

        // let mediaListContainer = document.createElement('div');


        let isSlideshowInspector = new CustomBooleanInspector(this.targetNode).RenderProperty("isSlideshow", this.target);
        let slideshowSecondsInspector = new CustomNumberfieldInspector(this.targetNode, 1, null, true).RenderProperty("slideshowSeconds", this.target);

        if (!this.target.isSlideshow) {
            slideshowSecondsInspector.classList.add("hidden");
        }

        let toggleSecondsProperty = () => {
            if (this.target.isSlideshow) {
                slideshowSecondsInspector.classList.remove("hidden");
            }
            else {
                slideshowSecondsInspector.classList.add("hidden");
            }
        }

        if(this.target.dataMedias.length <= 1){
            isSlideshowInspector.hidden = true;
            slideshowSecondsInspector.hidden = true;
        }

        if (CustomMediaTextureRenderer.currentRenderer) {
            CustomMediaTextureRenderer.currentRenderer.target.onChanged.off(toggleSecondsProperty)
        }

        CustomMediaTextureRenderer.currentRenderer = this;

        let container = document.createElement('div');
        container.classList.add("container");
        container.appendChild(isSlideshowInspector);
        container.appendChild(slideshowSecondsInspector);
        outer.appendChild(container);

        const listTemplate = document.getElementById("draggable-media-list-template") as HTMLTemplateElement;
        this.mediaListContainer = listTemplate.content.firstElementChild.cloneNode(true) as HTMLDivElement;

        this.includedMediaLabel = this.mediaListContainer.querySelector("#included-media-label") as HTMLDivElement;
        this.includedMediaEmptyListMsg = this.mediaListContainer.querySelector("#included-media-empty-list-message") as HTMLSpanElement;

        this.mediaListView = this.mediaListContainer.querySelector("#media-list") as HTMLUListElement;
        this.excludedMediaLabel = this.mediaListContainer.querySelector("#excluded-media-label") as HTMLDivElement;
        this.excludedMediaEmptyListMsg = this.mediaListContainer.querySelector("#excluded-media-empty-list-message") as HTMLSpanElement;
                
        outer.appendChild(this.mediaListContainer);

        this.onChanged = () => {
            this.renderMediaList(this.mediaListContainer);
        }

        target.onChanged.on(toggleSecondsProperty)

        this.renderMediaList(this.mediaListContainer);

        return outer;
    }

    private async renderMediaList(mediaListContainer: HTMLDivElement) {

        Array.from(this.mediaListView.querySelectorAll(".draggable-media")).forEach(element => {
			this.mediaListView.removeChild(element);
		});

        const includedMedias = this.target.medias.map(media => media.mediaName);
        const excludedMedias = difference(this.target.dataMedias, includedMedias);

        if(includedMedias.length === 0){
            this.includedMediaEmptyListMsg.style.removeProperty("display");
        }
        else{
            this.includedMediaEmptyListMsg.style.display = "none";
        }

        
        if(excludedMedias.length === 0){
            this.excludedMediaEmptyListMsg.style.removeProperty("display");
        }
        else{
            this.excludedMediaEmptyListMsg.style.display = "none";
        }

        await this.renderListElements(includedMedias, true);

		this.mediaListView.appendChild(this.excludedMediaLabel);
		this.mediaListContainer.appendChild(this.excludedMediaEmptyListMsg);

		await this.renderListElements(excludedMedias, false);

        let draggableElements = Array.from(this.mediaListView.children) as HTMLDivElement[];
        const labelIndex = draggableElements.indexOf(this.excludedMediaLabel);

        if(labelIndex <= 1)
        {
            draggableElements.forEach(element => {
                const elementIndex = draggableElements.indexOf(element);
                const handle = element.querySelector("img.draggable") as HTMLDivElement;

                handle?.classList.toggle("disabled", elementIndex < labelIndex);
            });
        }
        else
        {
            draggableElements.forEach(element => element.querySelector("img.draggable")?.classList.remove("disabled"));
        }
    }

    private async renderListElements(mediaUris: string[], isIncluded: boolean) : Promise<void>{
        const self = this;
        const itemTemplate = document.getElementById("draggable-media-list-item-template") as HTMLTemplateElement;
        const mediaConversionStatusResponse = await ResourceUtils.getAssetFromResource(this.target.id, MEDIA_CONVERSION_STATUS_FILENAME);
        let mediaConversionStatus: FileConversionStatus[] = [];

        if(mediaConversionStatusResponse.ok){
            mediaConversionStatus = await mediaConversionStatusResponse.json();
        }

        for(let i = 0; i < mediaUris.length; i++){
            const media = mediaUris[i];
            const fileName = Utils.getFileBaseName(media);
            const extension = Utils.getFileExtension(media);
            const mediaType = ResourceUtils.getMediaType(extension);
            const needMediaConversion = mediaType === FileMediaType.Video;

            let needVolume = false;
            //let isConverted = false;

            let item = itemTemplate.content.firstElementChild.cloneNode(true) as HTMLDivElement;
            item.dataset.media = media;
            item.id = `${this.target.dataMedias.indexOf(media)}-media-list-item-${this.target.node.id}`;

            const typeImg = item.querySelector(".type-img") as HTMLImageElement;
            let typeImgSrc = "";

            if(mediaType === FileMediaType.Image){
                typeImgSrc = "/img/image-icon.svg";
            }
            else if(mediaType === FileMediaType.Video){
                typeImgSrc = "/img/video-icon.svg";
                needVolume = true;
            } else if(mediaType === FileMediaType.Pdf){
                typeImgSrc = "/img/pdf-icon.svg";
            }

            typeImg.src = typeImgSrc;

            const mediaNameDiv = item.querySelector(".draggable-media-name") as HTMLDivElement;
			mediaNameDiv.title = mediaNameDiv.innerText = `${Utils.getFileBaseName(media)}`;
			mediaNameDiv["extension"] = `${Utils.getFileExtension(media, true)}`;

            const volumeIconImg = item.querySelector(".list-item-icon") as HTMLImageElement;
            volumeIconImg.style.filter = "invert(1)";

            const volumeSliderWrapper = item.querySelector(".draggable-media-slider-wrapper") as HTMLDivElement;
            const volumeSlider = volumeSliderWrapper.querySelector(".draggable-media-slider") as HTMLInputElement;
            const volumeSliderTextValue = volumeSliderWrapper.querySelector("div") as HTMLDivElement;
            
            if(isIncluded){
                volumeSliderTextValue.innerText = this.target.medias[i].volume.toString();
            }

            if(!needVolume){
                volumeIconImg.style.display = "none";
                volumeSliderWrapper.style.display = "none";
            }  
            else{
                volumeSlider.setAttribute("value", isIncluded ? this.target.medias[i].volume.toString() : DEFAULT_MEDIA_VOLUME.toString());

                volumeSlider.addEventListener("input", () => {
                    const mediaIndex = this.target.medias.findIndex(m => m.mediaName == media);
                    
                    if (mediaIndex != -1) {
                        const volume = parseFloat(volumeSlider.value);
        
                        if(isIncluded){
                            this.target.medias[mediaIndex].volume = volume;
                        }
        
                        // if (this.target.previewIndex == mediaIndex) {
                        //     this.target.previewVolume = volume;
                        // }
                    }

                    volumeSliderTextValue.innerText = volumeSlider.value;
                    
                    this.target.triggerOnChanged();
                });

                volumeSlider.addEventListener("change", async () => { 
                    await this.target.postComponentJsonToResource();
                    this.target.triggerOnChanged();
                });
            }
            
			//dragging logic -------------------------------------------------
			let placeholder;
			let isDraggingStarted = false;

			// The current position of mouse relative to the dragging element
			let x = 0;
			let y = 0;

			// Swap two nodes
			const swap = function (nodeA, nodeB) {
				const parentA = nodeA.parentNode;
				const siblingA = nodeA.nextSibling === nodeB ? nodeA : nodeA.nextSibling;

				// Move `nodeA` to before the `nodeB`
				nodeB.parentNode.insertBefore(nodeA, nodeB);

				// Move `nodeB` to before the sibling of `nodeA`
				parentA.insertBefore(nodeB, siblingA);
			};

			// Check if `nodeA` is above `nodeB`
			const isAbove = function (nodeA, nodeB) {
				// Get the bounding rectangle of nodes
				const rectA = nodeA.getBoundingClientRect();
				const rectB = nodeB.getBoundingClientRect();

				return rectA.top + rectA.height / 2 < rectB.top + rectB.height / 2;
			};

			const mouseDownHandler = function (e) {
                let draggableElements = Array.from(self.mediaListView.children) as HTMLDivElement[];
                const labelIndex = draggableElements.indexOf(self.excludedMediaLabel);
                const selecedIndex = draggableElements.indexOf(e.currentTarget);

				if(!e.target.classList.contains("draggable") || (labelIndex <= 1 && selecedIndex < labelIndex)){
					return;
				}

				//listElement = e.target.parentElement;

				// Calculate the mouse position
				const rect = item.getBoundingClientRect();
				x = e.pageX - rect.left;
				y = e.pageY - rect.top;

				// Attach the listeners to `document`
				document.addEventListener('mousemove', mouseMoveHandler);
				document.addEventListener('mouseup', mouseUpHandler);
			};

			const mouseMoveHandler = function (e) {
				const draggingRect = item.getBoundingClientRect();

				if (!isDraggingStarted) {
					isDraggingStarted = true;

					// Let the placeholder take the height of dragging element
					// So the next element won't move up
					placeholder = document.createElement('div');
					placeholder.classList.add('placeholder');
					item.parentNode.insertBefore(placeholder, item.nextSibling);
					placeholder.style.height = `${draggingRect.height}px`;
				}

				// Set position for dragging element
				item.style.position = 'fixed';
				item.style.top = `${e.pageY - y}px`;
				item.style.left = `${e.pageX - x}px`;

				// The current order
				// prevEle
				// draggingEle
				// placeholder
				// nextEle
				const prevEle = item.previousElementSibling;
				const nextEle = placeholder.nextElementSibling;

				// The dragging element is above the previous element
				// User moves the dragging element to the top
				if (prevEle && isAbove(item, prevEle)) {
					// The current order    -> The new order
					// prevEle              -> placeholder
					// draggingEle          -> draggingEle
					// placeholder          -> prevEle
					swap(placeholder, item);
					swap(placeholder, prevEle);

					if(self.excludedMediaLabel.nextElementSibling == null){
						self.excludedMediaEmptyListMsg.style.removeProperty("display");
					}
					else{
						self.excludedMediaEmptyListMsg.style.display = "none";
					}

					if(self.excludedMediaLabel.previousElementSibling == null){
						self.includedMediaEmptyListMsg.style.removeProperty("display");
					}
					else{
						self.includedMediaEmptyListMsg.style.display = "none";
					}

					return;
				}

				// The dragging element is below the next element
				// User moves the dragging element to the bottom
				if (nextEle && isAbove(nextEle, item)) {
					// The current order    -> The new order
					// draggingEle          -> nextEle
					// placeholder          -> placeholder
					// nextEle              -> draggingEle
					swap(nextEle, placeholder);
					swap(nextEle, item);

					if(self.excludedMediaLabel.nextElementSibling == null){
						self.excludedMediaEmptyListMsg.style.removeProperty("display");
					}
					else{
						self.excludedMediaEmptyListMsg.style.display = "none";
					}

					if(self.excludedMediaLabel.previousElementSibling == null){
						self.includedMediaEmptyListMsg.style.removeProperty("display");
					}
					else{
						self.includedMediaEmptyListMsg.style.display = "none";
					}
				}
			};

			const mouseUpHandler = async function () {
				// Remove the placeholder
				placeholder && placeholder.parentNode?.removeChild(placeholder);

				item.style.removeProperty('top');
				item.style.removeProperty('left');
				item.style.removeProperty('position');

				x = null;
				y = null;
				//item = null;
				isDraggingStarted = false;

				// Remove the handlers of `mousemove` and `mouseup`
				document.removeEventListener('mousemove', mouseMoveHandler);
				document.removeEventListener('mouseup', mouseUpHandler);

				await self.reorderMedias();

                let draggableElements = Array.from(self.mediaListView.children) as HTMLDivElement[];
                const labelIndex = draggableElements.indexOf(self.excludedMediaLabel);

                if(labelIndex <= 1)
                {
                    draggableElements.forEach(element => {
                        const elementIndex = draggableElements.indexOf(element);
                        const handle = element.querySelector("img.draggable") as HTMLDivElement;

                        handle?.classList.toggle("disabled", elementIndex < labelIndex);
                    });
                }
                else
                {
                    draggableElements.forEach(element => element.querySelector("img.draggable")?.classList.remove("disabled"));
                }
			};
			// end dragging logic ------------------------------------------------------------------

			item.addEventListener("mousedown", mouseDownHandler);

			this.mediaListView.appendChild(item);


            if(needMediaConversion){
                const overlay = item.querySelector(".draggable-media-list-item-overlay") as HTMLDivElement;

                if(overlay?.hidden){
                    if(mediaConversionStatus?.length){
                        const conversionStatus = mediaConversionStatus.find(c => {
                            const name = Utils.getFileBaseName(c.fileName);
                            const ext = Utils.getFileExtension(c.fileName);

                            if(fileName === name)
                            {
                                if(mediaType === FileMediaType.Video){
                                    return SUPPORTED_VIDEO_FORMATS.includes(ext);
                                }
                            }
                        });

                        if(conversionStatus){
                            if(conversionStatus.status === Status.Pending){
                                overlay.hidden = false;

                                const awaitedItemIndex = CustomMediaTextureRenderer.awaitedMediaConversionItems.findIndex(i => i.dataset.media === media);
                                
                                if(awaitedItemIndex !== -1){
                                    CustomMediaTextureRenderer.awaitedMediaConversionItems.splice(awaitedItemIndex, 1);
                                }

                                CustomMediaTextureRenderer.awaitedMediaConversionItems.push(item);
                                    
                                ConversionUtils.waitMediaConversionCompletion([conversionStatus.fileName], this.target.id).then(async result => {
                                    const awaitedItem = CustomMediaTextureRenderer.awaitedMediaConversionItems.find(i => i.dataset.media === media);

                                    if(awaitedItem != item){
                                        return;
                                    }

                                    const mediaConversionStatus = result[0];
                
                                    if(mediaConversionStatus){
                                        let res: VERTEXResource = null;
        
                                        //get the resource to check converted files in resourceKeys. here we use wait just to be more confident in getting the resource
                                        await Utils.waitForConditionAsync(async _ => {
                                            res = await ResourceUtils.getResourceData(this.target.id);
                                            
                                            return res != null;
                                        }, 500, 5000);

                                        let hasConvertedFiles = true;
                                        
                                        if(mediaType === FileMediaType.Video){
                                            ALLOWED_VIDEO_TYPES.forEach(t => {
                                                hasConvertedFiles = hasConvertedFiles && res.resourceKeys.includes(`${fileName}.${t}`);
                                            });
                                        }
                    
                                        if(mediaConversionStatus.status === Status.Completed && hasConvertedFiles){                              
                                            Utils.notify(`${mediaConversionStatus.fileName} conversion completed`, NotificationStatus.Success);
                
                                            overlay.hidden = true;

                                            this.target.triggerOnChanged();
                                        }
                                        else {
                                            Swal.fire({
                                                icon: 'error',
                                                title: "Media Conversion",
                                                html: `Media conversion failed for:<br><b>${mediaConversionStatus.fileName}</b><br><br>It will be deleted`,
                                                showConfirmButton: true,
                                                showCancelButton: false,
                                                showCloseButton: false,
                                                showDenyButton: false,
                                                allowEscapeKey: false,
                                                heightAuto: false
                                            }).then(async swal => {
                                                if(swal.isConfirmed){
                                                    Swal.showLoading();

                                                    const deleteResponses = await ResourceUtils.deleteMediaFromResource(this.target.id, media);

                                                    if(deleteResponses.every(r => r.ok)){
                                                        item.remove();

                                                        Swal.update({
                                                            icon: "success",
                                                            title: `The file ${media} has been deleted`,
                                                            showConfirmButton: true,
                                                            showCancelButton: false,
                                                            showCloseButton: false,
                                                            showDenyButton: false,
                                                            allowEscapeKey: false
                                                        });
                                                    }
                                                    else{
                                                        Swal.update({
                                                            icon: "error",
                                                            title: `The file ${media} has <b>NOT</b> been deleted, try again later.`,
                                                            showConfirmButton: true,
                                                            showCancelButton: false,
                                                            showCloseButton: false,
                                                            showDenyButton: false,
                                                            allowEscapeKey: false
                                                        });
                                                    }      
                                                    
                                                    this.reorderMedias();
                                                }
                                            });
                                        }
                        
                                        const mediaConversionFileIndex = CustomMediaTextureRenderer.awaitedMediaConversionItems.indexOf(awaitedItem);
            
                                        if(mediaConversionFileIndex !== -1){
                                            CustomMediaTextureRenderer.awaitedMediaConversionItems.splice(mediaConversionFileIndex, 1);
                                        }
                                    }
                                });
                            }
                            //it's not pending, so let's check if it is completed and files are ok
                            else if(conversionStatus.status === Status.Completed){
                                let res: VERTEXResource = null;
        
                                //get the resource to check converted files in resourceKeys. here we use wait just to be more confident in getting the resource
                                await Utils.waitForConditionAsync(async _ => {
                                    res = await ResourceUtils.getResourceData(this.target.id);
                                    
                                    return res != null;
                                }, 500, 5000);

                                let hasConvertedFiles = true;
                                
                                if(mediaType === FileMediaType.Video){
                                    ALLOWED_VIDEO_TYPES.forEach(t => {
                                        hasConvertedFiles = hasConvertedFiles && res.resourceKeys.includes(`${fileName}.${t}`);
                                    });
                                }

                                if(hasConvertedFiles){
                                    overlay.hidden = true;
                                }
                                else{
                                    item.remove();
        
                                    const deleteResponses = await ResourceUtils.deleteMediaFromResource(this.target.id, media);
        
                                    this.reorderMedias();
                                }
                            }
                        }
                        else{
                            item.remove();

                            const deleteResponses = await ResourceUtils.deleteMediaFromResource(this.target.id, media);

                            this.reorderMedias();
                        }
                    }
                    else{
                        overlay.hidden = true;
                    }
                }
            }
        }
    }

	private async reorderMedias(){
        const elements = Array.from(this.mediaListView.querySelectorAll('.draggable-media-list-item')) as HTMLDivElement[];

		this.medias = [];

		const labelIndex = Array.from(this.mediaListView.children).indexOf(this.excludedMediaLabel);

		for(let i = 0; i < labelIndex; i++){
            const temp : Media = new Media();

            const mediaNameDiv = elements[i].querySelector(".draggable-media-name") as HTMLDivElement;
            temp.mediaName = `${mediaNameDiv.innerText}.${mediaNameDiv["extension"]}`;
            temp.volume = parseFloat((elements[i].querySelector(".draggable-media-slider") as HTMLInputElement).value);

            this.medias.push(temp);
		}

		this.target.medias = cloneDeep(this.medias);

		await this.target.postComponentJsonToResource(true);

        this.target.triggerOnChanged();
    }
}


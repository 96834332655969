import { AugmentedStoreAssembly } from "../../../../AugmentedStoreAssembly";

export class GizmosComponent extends Vertex.NodeComponentModel.Component {
    writeData(writer: Vertex.BinaryWriter): void {
    }

    readData(reader: Vertex.BinaryReader): void {
    }
}

export enum EditorGizmoMode {
    None,
    Translate,
    Rotate,
    Scale,
    Bounds
}

export class GizmosComponentView extends Vertex.NodeComponentModel.ComponentViewBase {

    selectedMesh: BABYLON.AbstractMesh = null;
    selectedNode : Vertex.NodeComponentModel.VertexNode;
    boundingBoxGizmo: BABYLON.BoundingBoxGizmo = null;
    rotationGizmo: BABYLON.RotationGizmo = null;
    positionGizmo: BABYLON.PositionGizmo = null;
    scaleGizmo: BABYLON.ScaleGizmo = null;
    gizmoMode: EditorGizmoMode = EditorGizmoMode.Translate;

    updateGizmoState(): void {

        if (this.selectedMesh == null) {
            this.positionGizmo.attachedMesh = null;
            this.rotationGizmo.attachedMesh = null;
            this.boundingBoxGizmo.attachedMesh = null;
            this.scaleGizmo.attachedMesh = null;
        }

        switch (this.gizmoMode) {

            case EditorGizmoMode.None:

                this.positionGizmo.attachedMesh = null;
                this.rotationGizmo.attachedMesh = null;
                this.boundingBoxGizmo.attachedMesh = null;
                this.scaleGizmo.attachedMesh = null;

                break;
            case EditorGizmoMode.Translate:

                this.positionGizmo.attachedMesh = this.selectedMesh;
                this.rotationGizmo.attachedMesh = null;
                this.boundingBoxGizmo.attachedMesh = null;
                this.scaleGizmo.attachedMesh = null;

                break;
            case EditorGizmoMode.Rotate:

                this.positionGizmo.attachedMesh = null;
                this.rotationGizmo.attachedMesh = this.selectedMesh;
                this.boundingBoxGizmo.attachedMesh = null;
                this.scaleGizmo.attachedMesh = null;

                let spawnpoint = this.selectedNode.getComponent("SpawnPoint");
                let rotationComp = this.selectedNode.getComponent("RotationComponent");

                this.rotationGizmo.yGizmo.isEnabled = rotationComp ? false : true;
                this.rotationGizmo.xGizmo.isEnabled = spawnpoint || rotationComp ? false : true;
                this.rotationGizmo.zGizmo.isEnabled = spawnpoint || rotationComp ? false : true;

                break;

            case EditorGizmoMode.Scale:

                this.positionGizmo.attachedMesh = null;
                this.rotationGizmo.attachedMesh = null;
                this.boundingBoxGizmo.attachedMesh = null;
                this.scaleGizmo.attachedMesh = this.selectedMesh;

                break;
            case EditorGizmoMode.Bounds:

                this.positionGizmo.attachedMesh = null;
                this.rotationGizmo.attachedMesh = null;
                this.boundingBoxGizmo.attachedMesh = this.selectedMesh;
                this.scaleGizmo.attachedMesh = null;
                break;

            default:
        }
    }

    attachVertexEvents(node: Vertex.NodeComponentModel.VertexNode): boolean{
        let t = node.getComponent("Transform") as Vertex.NodeComponentModel.TransformComponent;

        if (!t){
            this.selectedMesh = null;
            return false;
        }

        const nodeLockableComp = node.getComponent("NodeLockable") as AugmentedStoreAssembly.NodeLockableComponent;
        
        const spawnpointComp = node.getComponent("SpawnPoint") as AugmentedStoreAssembly.SpawnPointComponent;

        if((nodeLockableComp == null && spawnpointComp == null ) || nodeLockableComp?.isLocked){
            // Vertex.Globals.event.fire("editor:clearSelection");
            return false;
        }

        node.viewNode.computeWorldMatrix(true);

        this.boundingBoxGizmo.scalePivot = new BABYLON.Vector3(0.5, 0.5, 0.5);
        this.boundingBoxGizmo.onScaleBoxDragObservable.clear();
        this.boundingBoxGizmo.onScaleBoxDragObservable.add(() => {
            try {
                if (node.HasToken === false)
                    return;

                t.scale = node.viewNode.scaling.asArray();
                t.position = node.viewNode.position.asArray();
                t.triggerOnChanged();
            } catch  { }
        })


        let updateRotation = () => {
            try {
                if (node.HasToken === false)
                    return;
                let r = node.viewNode.rotationQuaternion;
                t.rotation = [r.x, r.y, r.z, r.w];
                t.position = node.viewNode.position.asArray();
                t.triggerOnChanged();
            } catch { }
        };

        this.boundingBoxGizmo.onRotationSphereDragObservable.clear();

        this.boundingBoxGizmo.onRotationSphereDragObservable.add(updateRotation);
        this.rotationGizmo.xGizmo.dragBehavior.onDragObservable.add(updateRotation);
        this.rotationGizmo.yGizmo.dragBehavior.onDragObservable.add(updateRotation);
        this.rotationGizmo.zGizmo.dragBehavior.onDragObservable.add(updateRotation);


        let updatePosition = () => {
            try {
                if (node.HasToken === false)
                    return;
                t.position = node.viewNode.position.asArray();
                t.triggerOnChanged();
            } catch{ }
        }

        this.positionGizmo.xGizmo.dragBehavior.onDragObservable.add(updatePosition);
        this.positionGizmo.yGizmo.dragBehavior.onDragObservable.add(updatePosition);
        this.positionGizmo.zGizmo.dragBehavior.onDragObservable.add(updatePosition);

        let updateScale = () => {
            try {
                if (node.HasToken === false)
                    return;
                t.scale = node.viewNode.scaling.asArray();
                t.triggerOnChanged();
            } catch{ }
        }

        this.scaleGizmo.onDragEndObservable.add(updateScale);
        this.scaleGizmo.xGizmo.dragBehavior.onDragObservable.add(updateScale);
        this.scaleGizmo.yGizmo.dragBehavior.onDragObservable.add(updateScale);
        this.scaleGizmo.zGizmo.dragBehavior.onDragObservable.add(updateScale);
        this.scaleGizmo.uniformScaleGizmo.dragBehavior.onDragObservable.add(updateScale);

        
        return true;
    }

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        if (node.HasToken) {

            let scene = Vertex.Globals.runtime.scene;
            let utilityRenderer = new BABYLON.UtilityLayerRenderer(scene);
            this.boundingBoxGizmo = new BABYLON.BoundingBoxGizmo(new BABYLON.Color3(255 / 255, 94 / 255, 35 / 255), utilityRenderer);
            this.positionGizmo = new BABYLON.PositionGizmo(utilityRenderer);
            this.rotationGizmo = new BABYLON.RotationGizmo(utilityRenderer);
            this.scaleGizmo = new BABYLON.ScaleGizmo(utilityRenderer);
            this.boundingBoxGizmo.attachedMesh = null;

            let self = this;

            Vertex.Globals.event.on("editor:setgizmomode", (mode: EditorGizmoMode) => {
                this.gizmoMode = mode;

                if (self.selectedMesh === null){
                    return;
                }

                this.updateGizmoState();
            });

            Vertex.Globals.event.on("editor:selectNode", (node: Vertex.NodeComponentModel.VertexNode) => {
                this.changeTarget(node);
            });

            Vertex.Globals.event.on("editor:clearSelection", () => {
                this.selectedMesh = null;
                this.updateGizmoState();
            });

            // Vertex.Globals.event.on("editor:changeGizmoTarget", (node: Vertex.NodeComponentModel.VertexNode) => {
            //     this.changeTarget(node);
            // });
        }
    }

    changeTarget(node: Vertex.NodeComponentModel.VertexNode) {
        if(node){
            const result = this.attachVertexEvents(node);
            this.selectedMesh = result ? node.viewNode : null;
            this.selectedNode = node;
            this.updateGizmoState();
        }
    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
    }

    update(): void {
    }
}

export class GizmosComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new GizmosComponent();
    }

    constructor() {
        super("Gizmos", new GizmosComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}

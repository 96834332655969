import { AugmentedStoreAssembly } from "../../../AugmentedStoreAssembly";
import { CustomRotationRenderer } from "./customrotationrenderer";
import { CustomXYZRenderer } from "./customxyzrenderer";

export class CustomTransformRenderer implements Vertex.UI.ICustomInspectorRenderer {
    constructor(targetNode: Vertex.NodeComponentModel.VertexNode) {
        this.targetNode = targetNode;
        this.customXYZRenderer = new CustomXYZRenderer(this.targetNode);
        this.customScaleRenderer = new CustomXYZRenderer(this.targetNode);
        this.customRotationRenderer = new CustomRotationRenderer(this.targetNode);        
    }

    customXYZRenderer : CustomXYZRenderer;
    customScaleRenderer : CustomXYZRenderer;
    customRotationRenderer : CustomRotationRenderer;

    targetNode: Vertex.NodeComponentModel.VertexNode;
    target: Vertex.NodeComponentModel.Component;
    property: string;
    row = document.createElement("div");

    //Target appears to be the node but we also have just targetNode so not sure why it's needed.
    RenderProperty(property: string, target: any): HTMLDivElement {
        this.target = target;
        //Assuming property is "shouldPlay"
        this.property = property;
        //Get GLTF
        let outer = document.createElement('div');

        //this.renderAlternativeModelList(outer, this.target[property], property);
        let tComp = this.targetNode.getComponent("Transform") as Vertex.NodeComponentModel.TransformComponent;
        let transformCard = document.createElement("div"); transformCard.classList.add("card", "pointer-enable", "scroll-card");

        this.renderTransformSection(tComp, transformCard)
        outer.appendChild(transformCard);

        return outer;
    }

    renderTransformSection(tComp : Vertex.NodeComponentModel.TransformComponent, outer : HTMLElement)
    {
        let header = document.createElement("div"); header.classList.add("nav-link", "dark-text", "card-header-closable");
        let headerText = document.createElement("div"); headerText.classList.add("card-header-text"); headerText.innerText = "Transform";
        

        header.appendChild(headerText);
        outer.appendChild(header);

        let body = document.createElement("div"); 
        body.classList.add("card-body");

        let val = this.customXYZRenderer.RenderProperty("position", tComp);
        val.id = "positionPropertyRenderer";
        body.appendChild(val);
        
        val = this.customRotationRenderer.RenderProperty("rotation", tComp);
        val.id = "rotationPropertyRenderer";
        //Disable the rotation inputs if the node has a rotation component
        if(tComp.node.components.includes("RotationComponent"))
        {
            let inputs = val.querySelectorAll("input");        
    
            inputs[0].disabled = true;
            inputs[1].disabled = true;
            inputs[2].disabled = true;
        }
        body.appendChild(val);
        
        val = this.customScaleRenderer.RenderProperty("scale", tComp);
        val.id = "scalePropertyRenderer";
        body.appendChild(val);   

        let nodeLockableComponent = this.targetNode.getComponent("NodeLockable") as AugmentedStoreAssembly.NodeLockableComponent; 

        if (nodeLockableComponent.isLocked){
            const inputs = body.getElementsByTagName("input");
            for(let i = 0; i < inputs.length; i++){
                inputs[i].setAttribute("readonly", "");
            }
        }

        outer.appendChild(body);   
    }
}


import { AugmentedStoreAssembly } from "../../../../AugmentedStoreAssembly";

export class CustomHiddenMeshComponent extends AugmentedStoreAssembly.HiddenMeshComponent { 
    meshesVisibility: number;
}
export class HiddenMeshComponentView extends Vertex.NodeComponentModel.ComponentViewBase {

    constructor() {
        super();
    }

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        let comp = component as CustomHiddenMeshComponent;
        let gltfModelComp = node.getComponent("GltfModel") as Vertex.NodeComponentModel.GltfModelComponent;
        
        if (gltfModelComp.IsReady) {
            comp.meshesVisibility = gltfModelComp.visualNode.visibility;
            this.toggleMeshes(false, gltfModelComp, node, comp);
        }

        gltfModelComp.Ready.on(() => {
            comp.meshesVisibility = gltfModelComp.visualNode.visibility;
            this.toggleMeshes(false, gltfModelComp, node, comp);
        });
    }

    private toggleMeshes(visible: boolean, gltfModelComp: Vertex.NodeComponentModel.GltfModelComponent, node: Vertex.NodeComponentModel.VertexNode, comp: CustomHiddenMeshComponent) {
        gltfModelComp.visualNode.visibility = visible ? comp.meshesVisibility : 0.00001;
        if(visible){
            node.viewNode.getChildMeshes().forEach(c => c.visibility = comp.meshesVisibility);
        }
        else{
            node.viewNode.getChildMeshes().forEach(c => c.visibility = 0.00001);
        }
        
    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        let gltfModelComp = node.getComponent("GltfModel") as Vertex.NodeComponentModel.GltfModelComponent;

        if (gltfModelComp.IsReady) {
            this.toggleMeshes(true, gltfModelComp, node, component as CustomHiddenMeshComponent);
        }

        gltfModelComp.Ready.on(() => {
            this.toggleMeshes(true, gltfModelComp, node, component as CustomHiddenMeshComponent);
        });
    }

    update(): void {
    }
}

export class HiddenMeshComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new AugmentedStoreAssembly.HiddenMeshComponent();
    }

    constructor() {

        super("HiddenMesh", new HiddenMeshComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}

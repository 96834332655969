import { NavMeshComponentView } from "../../NodeComponents/navmeshcomponent";

export class TeleportComponent extends Vertex.NodeComponentModel.Component {
    writeData(writer: Vertex.BinaryWriter): void {
    }

    readData(reader: Vertex.BinaryReader): void {
    }
}

export class TeleportComponentView extends Vertex.NodeComponentModel.ComponentViewBase {

    private visualIndicator: BABYLON.Mesh;

    constructor() {
        super();
    }

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        let comp = component as TeleportComponent;
       
        const scene = node.viewNode.getScene() as BABYLON.Scene;

        //on mouse move gets ray from cursor and checks against navmesh
        Vertex.Globals.event.on("togglePreviewMode", (avatarMode: boolean) => {
            if (avatarMode) {
                this.drawVisualIndicator();
                scene.onPointerObservable.add(this.handleTeleport);
            }
            else {
                scene.onPointerObservable.removeCallback(this.handleTeleport);
                this.visualIndicator.dispose();
                this.visualIndicator = null;
            }
        });
    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
    }

    update(): void {
    }

    private handleTeleport = (pointerInfo: BABYLON.PointerInfo, event) => {
        if (pointerInfo.type === BABYLON.PointerEventTypes.POINTERDOUBLETAP) {
            if (pointerInfo && pointerInfo.pickInfo) {
                let hitInfo = pointerInfo.pickInfo.ray.intersectsMeshes(NavMeshComponentView.navMeshes as BABYLON.DeepImmutableObject<BABYLON.AbstractMesh>[]);

                if(hitInfo?.length){
                    if (hitInfo[0].hit) {
                        Vertex.Globals.event.fire("hevolus:teleport", hitInfo[0].pickedPoint);
                    }
                }
            }
        }

        if (pointerInfo.type === BABYLON.PointerEventTypes.POINTERMOVE) {
            if (pointerInfo && pointerInfo.pickInfo) {
                let hitInfo = pointerInfo.pickInfo.ray.intersectsMeshes(NavMeshComponentView.navMeshes as BABYLON.DeepImmutableObject<BABYLON.AbstractMesh>[]);

                if(hitInfo?.length){
                    if (hitInfo[0].hit) {
                        this.visualIndicator.visibility = 0.7;
                        this.visualIndicator.position.copyFrom(hitInfo[0].pickedPoint);
                    }
                    else{
                        this.visualIndicator.visibility = 0.000001;
                    }
                }
                else
                {
                    this.visualIndicator.visibility = 0.000001;
                }
            }
        }
    }

    drawVisualIndicator(){
        const scene = Vertex.Globals.runtime.scene as BABYLON.Scene;

        const blueMaterial = new BABYLON.StandardMaterial("mat1", scene);
        blueMaterial.emissiveColor = new BABYLON.Color3(0.3, 0.65, 0.9);
        blueMaterial.disableLighting = true;

        this.visualIndicator = BABYLON.MeshBuilder.CreateTorus("hoop", { thickness: 0.02, diameter: 0.3, tessellation: 32 }, scene);
        this.visualIndicator.scaling = new BABYLON.Vector3(1, 1, 1);
        this.visualIndicator.isPickable = false;
        this.visualIndicator.position = new BABYLON.Vector3(1000, 1000, 100);
        this.visualIndicator.visibility = 0.7;
        this.visualIndicator.material = blueMaterial;
    }
}

export class TeleportComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new TeleportComponent();
    }

    constructor() {
        super("Teleport", new TeleportComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}
//export class SpotLightComponent extends Vertex.NodeComponentModel.Component {

import { SpotLight } from "babylonjs/Lights/spotLight";
import { AugmentedStoreAssembly } from "../../../../AugmentedStoreAssembly";

export class SpotLightComponentView extends Vertex.NodeComponentModel.ComponentViewBase {
    //light: BABYLON.SpotLight = null;

    constructor() {
        super();
    }

    lightCount = 0;
    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        var comp = component as AugmentedStoreAssembly.SpotLightComponent;

        var scene = node.viewNode.getScene();

        
        var light = new BABYLON.SpotLight("SpotLight" + (this.lightCount++).toString(), BABYLON.Vector3.Zero(), BABYLON.Vector3.Forward(), 1.5708, 0, scene);//("SpotLight", BABYLON.Vector3.Zero(), scene);
        light.parent = node.viewNode;

        this.createDirectionalGizmo(node.viewNode);

        /*if (node.HasToken) {
            comp.range = light.range;
            comp.intensity = light.intensity;
            comp.radius = light.radius;
            comp.diffuseR = light.diffuse.r;
            comp.diffuseG = light.diffuse.g;
            comp.diffuseB = light.diffuse.b;
            comp.specularR = light.specular.r;
            comp.specularG = light.specular.g;
            comp.specularB = light.specular.b;
            comp.angle = light.angle;
        }*/

        var sphere = BABYLON.MeshBuilder.CreateSphere("Name", { diameter: .05}, scene);
        sphere.parent = node.viewNode;
    
        component.onChanged.on(() => {
            light.range = comp.range;
            light.intensity = comp.intensity;
            light.radius = comp.radius;
            light.diffuse.r = comp.diffuseR;
            light.diffuse.g = comp.diffuseG;
            light.diffuse.b = comp.diffuseB;
            light.specular.r = comp.diffuseR;
            light.specular.g = comp.diffuseG;
            light.specular.b = comp.diffuseB;
            light.angle = comp.angle;
        });
       
        node.event.on("Lighting:DiffuseChanged", (val: string) => {
            comp.diffuseR = BABYLON.Color3.FromHexString(val).r;
            comp.diffuseG = BABYLON.Color3.FromHexString(val).g;
            comp.diffuseB = BABYLON.Color3.FromHexString(val).b;
            comp.triggerOnChanged();
        });
        node.event.on("Lighting:SpecularChanged", (val: string) => {
            comp.specularR = BABYLON.Color3.FromHexString(val).r;
            comp.specularG = BABYLON.Color3.FromHexString(val).g;
            comp.specularB = BABYLON.Color3.FromHexString(val).b;
            comp.triggerOnChanged();
        });

        node.event.on("Lighting:IntensityChanged", (val: number) => { comp.intensity = val; comp.triggerOnChanged(); });
        node.event.on("Lighting:RangeChanged", (val: number) => { comp.range = val; comp.triggerOnChanged(); });
        node.event.on("Lighting:RadiusChanged", (val: number) => { comp.radius = val; comp.triggerOnChanged(); });
        node.event.on("Lighting:AngleChanged", (val: number) => { comp.angle = val; comp.triggerOnChanged(); });
        comp.triggerOnChanged();
    }

    createDirectionalGizmo(viewNode: any)
    {
        var scene = Vertex.Globals.runtime.scene;
        var thickness = 0.5;
        var cylinder = BABYLON.CylinderBuilder.CreateCylinder("cylinder", { diameterTop: 0, height: 0.075, diameterBottom: 0.0375 * (1 + (thickness - 1) / 4), tessellation: 96 }, scene);
        var line = BABYLON.CylinderBuilder.CreateCylinder("cylinder", { diameterTop: 0.005 * thickness, height: 0.2, diameterBottom: 0.005 * thickness, tessellation: 96 }, scene);

        var sphere = BABYLON.MeshBuilder.CreateSphere("Name", {diameter : .05}, scene);
        sphere.parent = viewNode;

        cylinder.parent = viewNode;
        cylinder.rotation.x = Math.PI / 2;
        cylinder.position.z += 0.2;

        line.parent = viewNode;
        line.position.z += 0.2 / 2;
        line.rotation.x = Math.PI / 2;


    }

    private loadTemplate(selector: string) {
        let template = document.querySelector(selector) as HTMLTemplateElement;
        let contents = template.content.cloneNode(true) as DocumentFragment;
        return contents;
    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {

    }

    update(): void {
    }
}

export class SpotLightComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new AugmentedStoreAssembly.SpotLightComponent();
    }

    constructor() {
        super("SpotLight", new SpotLightComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
        Vertex.Globals.event.on("Vertx:CreateSpotLight", ()=>{
            
            let space = Vertex.Globals.runtime.space as Vertex.Space;

            let lightNode = space.createNode("Spot Light");
            lightNode.addComponent("NodeLockable");
            space.addNode(lightNode);

            lightNode.addComponent("Transform") as Vertex.NodeComponentModel.TransformComponent;

            let lightComp = new AugmentedStoreAssembly.SpotLightComponent();
            lightComp.diffuseR = lightComp.diffuseG = lightComp.diffuseB = lightComp.specularR = lightComp.specularG = lightComp.specularB = 1;
            lightComp.angle = 1.45;
            lightComp.range = 10;
            lightComp.intensity = 1;
            lightComp.radius = 1.45;
            lightNode.addComponent("SpotLight", lightComp);
        })
    }
}

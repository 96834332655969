import { AugmentedStoreAssembly } from '../../../../AugmentedStoreAssembly';
import { PREVIEW_MODE_CAMERA_LOWER_RADIUS_LIMIT, PREVIEW_MODE_CAMERA_UPPER_RADIUS_LIMIT, CAMERA_PANNING_SENSIBILITY, CAMERA_INERTIA, CAMERA_PANNING_INERTIA, CAMERA_WHEEL_PRECISION, CAMERA_PINCH_PRECISION, CAMERA_MIN_Z, CAMERA_MAX_Z, CAMERA_FOV, CAMERA_UPPER_BETA_LIMIT, BetaLimitDeg, BetaLimitRad } from '../../../utilities/constants';

export class CustomCameraPropertiesComponent extends AugmentedStoreAssembly.CameraPropertiesComponent {

    camera: BABYLON.ArcRotateCamera;
}
export class CameraPropertiesComponentView extends Vertex.NodeComponentModel.ComponentViewBase {
    constructor() {
        super();
    }

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        const customComp = component as CustomCameraPropertiesComponent;

        this.init(customComp);
        
        customComp.onChanged.on(async () => {
            if(customComp.camera)
            {
                if(customComp.camera.minZ !== customComp.minZ || customComp.camera.maxZ !== customComp.maxZ){
                    customComp.camera.minZ = customComp.minZ;
                    customComp.camera.maxZ = customComp.maxZ;

                    Vertex.Globals.event.fire("camera-properties:clipping-planes-changed", [customComp.minZ, customComp.maxZ]);
                }

                customComp.camera.lowerRadiusLimit = customComp.lowerRadiusLimit;
                customComp.camera.upperRadiusLimit = customComp.upperRadiusLimit;
                customComp.camera.panningSensibility = customComp.panningSensibility;
                customComp.camera.inertia = customComp.inertia;
                customComp.camera.panningInertia = customComp.panningInertia;
                customComp.camera.wheelPrecision = customComp.wheelPrecision;
                customComp.camera.pinchPrecision = customComp.pinchPrecision;
                customComp.camera.fov = customComp.fov;

                if(customComp.betaLimitMode === BetaLimitDeg.Quarter)//90° mode
                {
                    customComp.camera.upperBetaLimit = BetaLimitRad.Quarter;
                    customComp.camera.lowerBetaLimit = BetaLimitRad.Zero;
                }
                else if(customComp.betaLimitMode === BetaLimitDeg.Half)//180° mode
                {
                    customComp.camera.upperBetaLimit = BetaLimitRad.Half;
                    customComp.camera.lowerBetaLimit = BetaLimitRad.Zero;
                }
                else if(customComp.betaLimitMode === BetaLimitDeg.Zero)//Free mode
                {
                    customComp.camera.upperBetaLimit = BetaLimitRad.Free;
                    customComp.camera.lowerBetaLimit = BetaLimitRad.Free;
                }
                else //Default 90°
                {
                    customComp.camera.upperBetaLimit = BetaLimitRad.Quarter;
                    customComp.camera.lowerBetaLimit = BetaLimitRad.Zero;
                }
            }
            
            Vertex.Globals.event.fire("hevolus:CameraPropertiesChanged", customComp);
        });
    }

    init(comp: CustomCameraPropertiesComponent){
        if(comp.lowerRadiusLimit === 0 && comp.upperRadiusLimit === 0 &&
        comp.inertia === 0 && comp.panningInertia === 0 && comp.panningSensibility === 0 &&
        comp.wheelPrecision === 0 && comp.pinchPrecision === 0 &&
        comp.fov === 0 && comp.betaLimitMode === 0 &&
        comp.minZ === 0 && comp.maxZ === 0){
            comp.minZ = CAMERA_MIN_Z;
            comp.maxZ = CAMERA_MAX_Z;
            comp.lowerRadiusLimit = PREVIEW_MODE_CAMERA_LOWER_RADIUS_LIMIT;
            comp.upperRadiusLimit = PREVIEW_MODE_CAMERA_UPPER_RADIUS_LIMIT;
            comp.panningSensibility = CAMERA_PANNING_SENSIBILITY;
            comp.inertia = CAMERA_INERTIA;
            comp.panningInertia = CAMERA_PANNING_INERTIA;
            comp.wheelPrecision = CAMERA_WHEEL_PRECISION;
            comp.pinchPrecision = CAMERA_PINCH_PRECISION;
            comp.fov = CAMERA_FOV;
            comp.betaLimitMode = CAMERA_UPPER_BETA_LIMIT; //Default 90° mode                
        }
    }


    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {

    }
}

export class CameraPropertiesComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new CustomCameraPropertiesComponent();
    }
    constructor() {
        super("CameraProperties", new CameraPropertiesComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}
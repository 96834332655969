import { AugmentedStoreAssembly } from "../../../../AugmentedStoreAssembly";
import { getComponentJsonFromResource, postComponentJsonToResource } from "../../../utilities/utils";

export class Action {
    actionName: string;
}

export interface ITriggerActionComponent {
    isTriggerActionComponent: boolean;
    actionIndexes: number[];
    actionValues: number[];
    triggerActionIndexes: number[];
    triggerActionValues: number[];
}

export class ActionValue {
    nodeId: string;
    component: string;
    value: number;
}

export enum ActionIncompatibilityReason {
    None,                  //No incompatibility found
    IncompatibleComponent, //Two incompatible components have been set on the same node in the action chain
    IncompatibleValue,     //The same component has been set with two different values on the same node in the action chain
    ActionLoop             //The action chain contains a loop (an action that triggers itself)
}

export class ActionIncompatibility {
    index: number;  //The index of the action or action value that is incompatible
    reason: ActionIncompatibilityReason;
}

export class CustomActionSettingsComponent extends AugmentedStoreAssembly.ActionSettingsComponent {
    actions: Action[] = [];
    isReady: Promise<void>;

    postComponentJsonToResource = (async (isEditorVersion: boolean = true) => {
        await postComponentJsonToResource(Vertex.Globals.spaceId, "ActionSettings", this.node.id, isEditorVersion, JSON.stringify(this.actions));
    }).bind(this);


    instanceOfITriggerActionComponent(object: any): object is ITriggerActionComponent {
        return object && object.isTriggerActionComponent;
    }

    public checkSpaceActionsCompatibility(): ActionIncompatibility {
        //Map that associates an action index to the action values that are triggered by the action
        const mapActionToValues: Map<number, ActionValue[]> = new Map<number, ActionValue[]>();

        //Map that associates an action value(action trigger) to the actions that are triggered by the action value(action trigger)
        const mapValueToActions: Map<ActionValue, number[]> = new Map<ActionValue, number[]>();

        const runtime = Vertex.Globals.runtime as Vertex.VertexRuntime;
        const space = runtime.space as Vertex.Space;
        const nodes = Array.from(space.nodes.values());
        const triggerActionComponents = [];

        nodes.forEach((node) => {
            node.components.forEach((componentName) => {
                const component = node.getComponent(componentName);
                if (this.instanceOfITriggerActionComponent(component)) {
                    triggerActionComponents.push(component);
                }
            });
        });

        triggerActionComponents.forEach((component) => {
            component.actionIndexes.forEach((actionIndex, index) => {
                const actionValue: ActionValue = {
                    nodeId: component.node.id,
                    component: component.name,
                    value: component.actionValues[index]
                }

                if (mapActionToValues.has(actionIndex)) {
                    mapActionToValues.get(actionIndex).push(actionValue);
                } else {
                    mapActionToValues.set(actionIndex, [actionValue]);
                }
            });

            const distinctTriggerActionValues = component.triggerActionValues.filter((triggerActionValue, index, self) => self.indexOf(triggerActionValue) === index);
            distinctTriggerActionValues.forEach((triggerActionValue) => {
                const triggerActionsIndexes = component.triggerActionIndexes.filter((actionIndex, index) => component.triggerActionValues[index] === triggerActionValue);

                const actionValue: ActionValue = {
                    nodeId: component.node.id,
                    component: component.name,
                    value: triggerActionValue
                }

                if (mapValueToActions.has(actionValue)) {
                    mapValueToActions.get(actionValue).concat(triggerActionsIndexes);
                } else {
                    mapValueToActions.set(actionValue, triggerActionsIndexes);
                }
            });
        });

        //Check compatibility for each action in the space
        for (let [actionIndex, actionValues] of mapActionToValues) {
            const incompatibility = this.checkActionCompatibility(mapActionToValues, mapValueToActions, actionIndex);
            if (incompatibility.reason !== ActionIncompatibilityReason.None) {
                console.error("Incompatibility found for action " + actionIndex + " because of " + ActionIncompatibilityReason[incompatibility.reason]);
                return incompatibility;
            }
        }

        //Check compatibility for each action value(action trigger) in the space
        for (let [actionValue, actionIndexes] of mapValueToActions) {

            let exploredActionValues: ActionValue[] = [];

            for (let i = 0; i < actionIndexes.length; i++) {
                const actionIndex = actionIndexes[i];
                exploredActionValues.push(actionValue);

                const incompatibility = this.checkActionCompatibility(mapActionToValues, mapValueToActions, actionIndex, exploredActionValues);
                if (incompatibility.reason !== ActionIncompatibilityReason.None) {
                    console.error("Incompatibility found for action " + actionIndex + " for node " + actionValue.nodeId + " because of " + ActionIncompatibilityReason[incompatibility.reason]);
                    return incompatibility;
                }
            }

        }
        return { index: -1, reason: ActionIncompatibilityReason.None };
    }

    checkActionCompatibility(mapActionToValues: Map<number, ActionValue[]>, mapValueToActions: Map<ActionValue, number[]>, actionIndex: number, exploredActionValues: ActionValue[] = [], exploredActions: number[] = []): ActionIncompatibility {
        exploredActions.push(actionIndex);

        //Find the action values that are triggered by the action
        const actionValues = mapActionToValues.get(actionIndex) ?? [];

        for (let i = 0; i < actionValues.length; i++) {
            const actionValue = actionValues[i];

            //Check if the action value is compatible with the action chain
            const incompatibleActionValue = this.checkActionValueCompatibility(exploredActionValues, actionValue);
            if (incompatibleActionValue.reason !== ActionIncompatibilityReason.None) {
                //The action value contains a component or value that is incompatible with the action chain
                return incompatibleActionValue;
            }

            //Find the actions that are triggered by the action value
            const valueToActionsEntry = Array.from(mapValueToActions.entries()).find(entry => entry[0].component === actionValue.component && entry[0].nodeId === actionValue.nodeId && entry[0].value === actionValue.value);
            const actions = valueToActionsEntry ? valueToActionsEntry[1] : [];

            for (let i = 0; i < actions.length; i++) {
                const actionIndex = actions[i];

                if (exploredActions.includes(actionIndex)) {
                    //The action chain contains a loop (an action that triggers itself)
                    return { index: actionIndex, reason: ActionIncompatibilityReason.ActionLoop };

                } else {
                    //The action has not been explored yet so it must be explored to check if it contains an incompatibility
                    const incompatibility = this.checkActionCompatibility(mapActionToValues, mapValueToActions, actionIndex, exploredActionValues, [...exploredActions]);

                    if (incompatibility.reason !== ActionIncompatibilityReason.None) {
                        //The action contains an incompatibliity
                        return incompatibility;
                    }
                }
            }
        }

        //No incompatibility found
        return { index: -1, reason: ActionIncompatibilityReason.None };
    }

    checkActionValueCompatibility(exploredActionValues: ActionValue[], actionValue: ActionValue): ActionIncompatibility {
        const incompatibleComponentsMap = new Map<string, string[]>();
        // incompatibleComponentsMap.set("ModelAlternative", ["Skin"]);

        for (let i = 0; i < exploredActionValues.length; i++) {
            const exploredActionValue = exploredActionValues[i];

            if (exploredActionValue.nodeId === actionValue.nodeId) {

                if (exploredActionValue.component !== actionValue.component) {

                    if (incompatibleComponentsMap.get(exploredActionValue.component)?.includes(actionValue.component)) {
                        //Two incompatible components have been set on the same node in the action chain
                        return { index: i, reason: ActionIncompatibilityReason.IncompatibleComponent };
                    }
                }
                else if (exploredActionValue.value !== actionValue.value) {
                    //The same component has been set with two different values on the same node in the action chain
                    return { index: i, reason: ActionIncompatibilityReason.IncompatibleValue };
                }
            }
        }

        //No incompatibility found
        return  { index: -1, reason: ActionIncompatibilityReason.None };
    }
}
export class ActionSettingsComponentView extends Vertex.NodeComponentModel.ComponentViewBase {

    constructor() {
        super();
    }

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        const customComp = component as CustomActionSettingsComponent;

        customComp.isReady = new Promise<void>(async (resolve, reject) => {
            let res = await getComponentJsonFromResource(Vertex.Globals.spaceId, "ActionSettings", node.id, true);
            if (res.ok) {
                customComp.actions = await res.json() as Action[];
            }
            else {
                res = await getComponentJsonFromResource(Vertex.Globals.spaceId, "ActionSettings", node.id, false);
                if (res.ok) {
                    customComp.actions = await res.json() as Action[];
                    await customComp.postComponentJsonToResource(true);
                } else {
                    customComp.actions = [];
                    await customComp.postComponentJsonToResource(true);
                }
            }

            const beforeSaveSpace = async () => {
                await customComp.postComponentJsonToResource(false);
            }

            customComp.onRemoved.on(async () => {
                Vertex.Globals.event.off("hevolus:beforeSaveSpace", beforeSaveSpace);
                customComp.actions = [];
                await customComp.postComponentJsonToResource(true);
            });

            Vertex.Globals.event.on("hevolus:beforeSaveSpace", beforeSaveSpace);

            resolve();
        });
    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {

    }
}

export class ActionSettingsComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new CustomActionSettingsComponent();
    }

    constructor() {
        super("ActionSettings", new ActionSettingsComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}
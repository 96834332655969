import Swal from "sweetalert2";
import { CallToAction, CustomCallToActionComponent } from "../componentsystems/NodeComponents/calltoaction";
import { ResourceUtils } from "../../utilities/resource-utilities";
import { UploadUtils } from "../../utilities/upload-utilities";
import { GltfUtils } from "../../utilities/gltf-utilities";
import { ALLOWED_IMAGE_TYPES } from "../../utilities/constants";
import { Utils } from "../../utilities/utils";

export class CustomCallToActionInspector implements Vertex.UI.ICustomInspectorRenderer {

    static readonly CTA_FILE_PREFIX = "CTA_";

    constructor(targetNode: Vertex.NodeComponentModel.VertexNode) {
        this.targetNode = targetNode;
    }
    targetNode: Vertex.NodeComponentModel.VertexNode;
    target: CustomCallToActionComponent;
    property: string;
    container: HTMLDivElement;
    firstElement: HTMLDivElement;
    placeholderElement : HTMLDivElement;
    list: HTMLUListElement;


    //Target appears to be the node but we also have just targetNode so not sure why it's needed.
    RenderProperty(property: string, target: any): HTMLDivElement {
        const self = this;

        self.target = target;
        self.property = property;

        let card = document.createElement("div");
        card.id="cta-panel";

        let body = document.createElement("div");

        this.buildUI(body, self.target);

        card.appendChild(body);

        //HACK HACK HACK
        Utils.delay(100).then(() => { card.parentElement.setAttribute("style", "padding: 0"); });

        return card;
    }

    private buildUI(body : HTMLDivElement, component : CustomCallToActionComponent){
        body.innerHTML = "";
        
        const self = this;

        component.isReady.then(()=>{
            component.actions.forEach(action => {
                const itemTemplate = document.getElementById("calltoaction-list-item-template") as HTMLTemplateElement;
                let el = itemTemplate.content.firstElementChild.cloneNode(true) as HTMLDivElement;

                el['action'] = action;

                let inputs = el.querySelectorAll("input");

                const actionInput = inputs[0];
                const urlInput = inputs[1];

                actionInput.value = action.actionName;
                urlInput.value = action.url;

                let buttons = el.querySelectorAll("button");
                
                const iconButton = buttons[0];
                const deleteButton = buttons[1];
                
                const iconButtonImg = iconButton.querySelector("img");
                iconButtonImg["fileName"] = action.icon;

                ResourceUtils.getAssetBlobUrl(Vertex.Globals.spaceId, action.icon).then((result)=>{
                    if(result){
                        iconButtonImg.src = result;
                    }
                });
                
                iconButton.onclick = async (e) => {
                    var x = e.pageX;
                    var y = e.pageY;
    
                    self.changeIconModal(x, y, iconButtonImg["fileName"], (selectedIcon: string) => {
                        iconButtonImg["fileName"] = selectedIcon;
                        ResourceUtils.getAssetBlobUrl(Vertex.Globals.spaceId, selectedIcon).then((result)=>{
                            if(result){
                                iconButtonImg.src = result;
                            }
                        });
                        action.icon = selectedIcon;
                        component.postComponentJsonToResource(true);
                    });
                }

                actionInput.onchange = async () => {
                    actionInput.value = Utils.sanitizeString(actionInput.value.trim());
                    const indx = component.actions.findIndex(a => a.actionName == actionInput.value);

                    if(indx == -1){
                        if(actionInput.checkValidity() && actionInput.value.length > 0){
                            action.actionName = actionInput.value;
                            await component.postComponentJsonToResource(true);
                        }
                        else{
                            actionInput.value = action.actionName;

                            Swal.fire({
                                icon: "warning",
                                title: `The CTA name can NOT be empty`,
                                allowEscapeKey: false,
                                allowOutsideClick: false,
                                showConfirmButton: true,
                                heightAuto: false,
                            });
                        }
                    }
                    else{
                        Swal.fire({
                            icon: "warning",
                            title: `A CTA named<br>${actionInput.value}<br>already exists`,
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            showConfirmButton: true,
                            heightAuto: false,
                            width: "auto"
                        });

                        actionInput.value = action.actionName;
                    }
                }

                urlInput.onchange = async () => {
                    urlInput.value = Utils.sanitizeString(urlInput.value.trim());

                    if(urlInput.value.length > 0){
                        if (Utils.isValidUrl(urlInput.value)) {
                            action.url = urlInput.value;
                            
                            await component.postComponentJsonToResource(true);
                        }
                        else {
                            urlInput.value = action.url;

                            Swal.fire({
                                icon: "warning",
                                title: `Invalid CTA URL`,
                                html: `Please enter a valid format for the URL<br>(e.g. https://www.hevolus.com)`,
                                allowEscapeKey: false,
                                allowOutsideClick: false,
                                showConfirmButton: true,
                                heightAuto: false,
                            });
                        }
                    }
                    else{
                        urlInput.value = action.url;

                        Swal.fire({
                            icon: "warning",
                            title: `The CTA URL must NOT be empty`,
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            showConfirmButton: true,
                            heightAuto: false,
                        });
                    }
                }

                deleteButton.onclick = () => {
                    Swal.fire({
                        icon: 'warning',
                        title: `Are you sure you want to delete the Call To Action<br>${action.actionName}<br>?`,
                        confirmButtonText: 'Delete',
                        confirmButtonColor: 'red',
                        showCancelButton: true,
                        heightAuto: false,
                        width: "auto"
                    }).then(async response => {
                        if (response.isConfirmed) {
                            const indx = component.actions.findIndex(a => a == action);
                            
                            if (indx >= 0) {
                                component.actions.splice(indx, 1);
                            }

                            component.postComponentJsonToResource(true);
                            el.remove();
                        }
                    });
                };

                const mouseDownHandler = function (e) {
                    if(!e.target.id.includes("drag-img")){
                        return;
                    }

                    // Attach the listeners to `document`
                    document.addEventListener('mousemove', mouseMoveHandler);
                    document.addEventListener('mouseup', mouseUpHandler);
                };

                let isDraggingStarted = false;

                const mouseUpHandler = async function () {
                    // Remove the placeholder
                    self.placeholderElement.hidden = true;
    
                    el.style.removeProperty('top');
                    el.style.removeProperty('left');
                    el.style.removeProperty('position');

                    self.placeholderElement.insertAdjacentElement("beforebegin", el);

                    let listElements = Array.from(body.querySelectorAll(".cta-list-item").values());
                    listElements.splice(listElements.length-1,1);

                    component.actions = [];
                    
                    for(let element of listElements){
                        component.actions.push(element['action']);
                    }
                    
                    component.postComponentJsonToResource(true);
                    // listElement = null;
                    isDraggingStarted = false;
    
                    // Remove the handlers of `mousemove` and `mouseup`
                    document.removeEventListener('mousemove', mouseMoveHandler);
                    document.removeEventListener('mouseup', mouseUpHandler);
                };

                const mouseMoveHandler = function (e) {
                    if (!isDraggingStarted) {
                        isDraggingStarted = true;
                        self.placeholderElement.hidden = false;
                    }

                    el.style.position = 'fixed';
                    el.style.top = `${e.pageY}px`;
                    el.style.left = `${e.pageX}px`;

                    const listElements = Array.from(body.querySelectorAll(".cta-list-item").values()).filter(v => v != el);

                    const draggedElementY = el.getBoundingClientRect().y;

                    if(listElements.length > 0){
                        let firstElementUnderDraggabledItem = listElements.find(element=> element.getBoundingClientRect().y > draggedElementY);

                        if(firstElementUnderDraggabledItem){
                            firstElementUnderDraggabledItem.insertAdjacentElement("beforebegin", self.placeholderElement);
                        }
                        else{
                            listElements[listElements.length - 1].insertAdjacentElement("beforebegin", self.placeholderElement);
                        }
                    }
                }

                el.addEventListener("mousedown", mouseDownHandler);

                body.appendChild(el);
            });

            const itemPlaceholderTemplate = document.getElementById("calltoaction-list-item-placeholder-template") as HTMLTemplateElement;
            self.placeholderElement =  itemPlaceholderTemplate.content.firstElementChild.cloneNode(true) as HTMLDivElement;
            self.placeholderElement.hidden = true;

            body.appendChild(self.placeholderElement);

            const itemTemplate = document.getElementById("calltoaction-list-item-template") as HTMLTemplateElement;
            self.firstElement = itemTemplate.content.firstElementChild.cloneNode(true) as HTMLDivElement;

            const draggable = self.firstElement.querySelector(".draggable") as HTMLElement;
            draggable.hidden = true;

            const deleteButton = self.firstElement.querySelector(".btn-danger") as HTMLElement;
            deleteButton.hidden = true;

            const confirmButton = self.firstElement.querySelector(".btn-primary") as HTMLButtonElement;
            confirmButton.hidden = false;

            let inputs = self.firstElement.querySelectorAll("input");
            const actionInput = inputs[0];
            const urlInput = inputs[1];

            actionInput.onchange = async () => {
                actionInput.value = Utils.sanitizeString(actionInput.value.trim());
            }

            urlInput.onchange = async () => {
                urlInput.value = Utils.sanitizeString(urlInput.value.trim());
            }

            let buttons = self.firstElement.querySelectorAll("button");

            const iconButton = buttons[0];
            const iconButtonImg = iconButton.querySelector("img");

            iconButton.onclick = async (e) => {
                var x = e.pageX;
                var y = e.pageY;

                self.changeIconModal(x, y, iconButtonImg["fileName"], (selectedIcon: string) => {
                    iconButtonImg["fileName"] = selectedIcon;
                    ResourceUtils.getAssetBlobUrl(Vertex.Globals.spaceId, selectedIcon).then((result)=>{
                        if(result){
                            iconButtonImg.src = result;
                        }
                    });
                });
            }

            confirmButton.onclick = async () => {
                actionInput.value = Utils.sanitizeString(actionInput.value.trim());
                urlInput.value = Utils.sanitizeString(urlInput.value.trim());

                const indx = component.actions.findIndex(a => a.actionName == actionInput.value);

                if(indx == -1){
                    if(actionInput.checkValidity() && actionInput.value.length > 0 && urlInput.value.trim().length > 0/* && iconButtonImg["fileName"]?.length > 0*/){

                        if (Utils.isValidUrl(urlInput.value)) {
                            let action = new CallToAction();
                            action.actionName = actionInput.value;
                            action.url = urlInput.value;
                            action.icon = iconButtonImg["fileName"];
                            component.actions.push(action);
        
                            await component.postComponentJsonToResource(true);
                            self.buildUI(body, component);
                        }
                        else{
                            Swal.fire({
                                icon: "warning",
                                title: `Invalid CTA URL`,
                                html: `Please enter a valid format for the URL<br>(e.g. https://www.hevolus.com)`,
                                allowEscapeKey: false,
                                allowOutsideClick: false,
                                showConfirmButton: true,
                                heightAuto: false,
                            });
                        }

                    }
                    else{
                        Swal.fire({
                            icon: "warning",
                            title: `Name and URL params must be populated`,
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            showConfirmButton: true,
                            heightAuto: false
                        });
                    }
                }
                else{
                    Swal.fire({
                        icon: "warning",
                        title: `A CTA named<br>${actionInput.value}<br>already exists`,
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                        heightAuto: false,
                        width: "auto"
                    });
                }
            }

            body.appendChild(self.firstElement);
        });
    }

    closeIconModal() {
        const modal = document.querySelector("#icon-picker");

        if (modal) {
            modal.remove();
        }
    }

    async changeIconModal(x, y, currentIconName, onIconSelectedCallback) {
        // Get mouse position
        var windowWidth = window.innerWidth;
        var xPos = windowWidth - x;
        var yPos = y;

        this.closeIconModal();
        
        // setup main elements
        let listContainer = document.createElement("div");
        listContainer.id = "icon-picker";
        listContainer.style.top = yPos + "px";
        listContainer.style.right = xPos + "px";

        let containerHeader = document.createElement("div");
        containerHeader.classList.add("list-texture-header");

        let containerTitle = document.createElement("h4");
        containerTitle.classList.add("list-texture-header-title");
        containerTitle.innerText = "Replacing icon";

        let containerClose = document.createElement("div");
        containerClose.classList.add("texture-close-wrapper");
        containerClose.addEventListener('click', async (event) => {
            listContainer.remove();
        });

        let containerCloseIcon = document.createElement("img");
        containerCloseIcon.classList.add("texture-close-icon");
        containerCloseIcon.src = "/img/cross.svg"

        let iconListGroup = document.createElement("div");
        iconListGroup.classList.add("texture-list-group");

        //insert elements
        document.body.insertBefore(listContainer, document.body.firstChild);
        listContainer.appendChild(containerHeader);
        containerHeader.appendChild(containerTitle);
        containerHeader.appendChild(containerClose);
        containerClose.appendChild(containerCloseIcon);
        listContainer.appendChild(iconListGroup);

        {
            //setup items
            let listItem = document.createElement("div");
            listItem.classList.add("list-texture-item", "list-group-item-dark");
            listItem.style.backgroundColor = "rgba(0,0,0,0.05)";

            let listTitle = document.createElement("h4");
            listTitle.classList.add("list-texture-title", "font-italic");
            listTitle.innerText = "(New Icon)";

            const fileExplorer = document.createElement("input");
            fileExplorer.type = "file";
            fileExplorer.accept = "image/*";
            fileExplorer.classList.add("d-none");
            fileExplorer.addEventListener("change", async (e) => {
                const input = e.target as HTMLInputElement;

                const listUseButtons = document.querySelectorAll(".texture-button");
                for (let useButton of listUseButtons) {
                    useButton.classList.remove("disable");
                }

                const file = new File([input.files[0]], CustomCallToActionInspector.CTA_FILE_PREFIX + input.files[0].name);
                const res = await UploadUtils.uploadFiles([file]);

        
                if(res && res.successfulUploads.length > 0){
                    await onIconSelectedCallback(res[0]);
                }

                this.closeIconModal();
            });

            listContainer.appendChild(fileExplorer);

            let listUseButton = document.createElement("button");
            listUseButton.classList.add("btn", "btn-secondary", "float-right", "btn-sm", "texture-button");
            listUseButton.innerText = ". . .";
            listUseButton.addEventListener('click', async (event) => {
                fileExplorer.click();
            });

            //insert items
            iconListGroup.appendChild(listItem);
            listItem.appendChild(listTitle);
            listItem.appendChild(listUseButton);
        }

        //get icons (files with .jpg or .png etc.. ) from resource
        let items = await GltfUtils.getTextureNamesOnResource();
        items = items.filter(filename => filename.startsWith(CustomCallToActionInspector.CTA_FILE_PREFIX));
        items = items.filter(image => ALLOWED_IMAGE_TYPES.includes(image.substring(image.lastIndexOf(".") + 1)));

        for (let i = 0; i < items.length; i++) {
            let newIconName = items[i];

            //setup items
            let listItem = document.createElement("div");
            listItem.classList.add("list-texture-item", "list-group-item-dark");

            let listTitle = document.createElement("h4");
            listTitle.classList.add("list-texture-title");
            listTitle.innerText = newIconName.substring(CustomCallToActionInspector.CTA_FILE_PREFIX.length, newIconName.lastIndexOf("."));

            let listUseButton = document.createElement("button");
            listUseButton.classList.add("btn", "btn-secondary", "float-right", "btn-sm", "texture-button");
            listUseButton.innerText = "Use";
            listUseButton.disabled = currentIconName == newIconName;

            listUseButton.addEventListener('click', async (event) => {
                onIconSelectedCallback(newIconName);
                this.closeIconModal();
            });

            //insert items
            iconListGroup.appendChild(listItem);
            listItem.appendChild(listTitle);
            listItem.appendChild(listUseButton);
        }

        let onWindowClick = (e) => {
            if (!listContainer.contains((<HTMLElement>e.target))) {
                listContainer.remove();
                window.removeEventListener('click', onWindowClick);
            }
        }

        window.addEventListener('click', onWindowClick, true);
    }
}
import { _BabylonLoaderRegistered } from 'babylonjs/Loading/Plugins/babylonFileLoader';
import Swal from 'sweetalert2';
import { Utils } from '../../../utilities/utils';
import { CustomSkyboxComponent, Skybox } from '../NodeComponents/skyboxcomponent';
import { CustomActionsRenderer } from '../../CustomInspectorRenderers/customactionsrenderer';
import { CustomActionSettingsComponent } from '../NodeComponents/actionsettingscomponent';
import { UploadUtils, UploadingAssetType } from '../../../utilities/upload-utilities';
import { ResourceUtils, VERTEXResource } from '../../../utilities/resource-utilities';
import { ALLOWED_SKYBOX_TYPES } from '../../../utilities/constants';
import { CustomTriggerableComponentItemRenderer } from '../../CustomInspectorRenderers/customtriggerablecomponentItemrenderer';


export class SkyboxSettingsPanelComponent extends Vertex.NodeComponentModel.Component {
    writeData(writer: Vertex.BinaryWriter): void {
    }
    readData(reader: Vertex.BinaryReader): void {
    }
}

export class SkyboxSettingsPanelComponentView extends Vertex.NodeComponentModel.ComponentViewBase {


    constructor() {
        super();
    }


    panel: HTMLDivElement;
    skyboxComp: CustomSkyboxComponent;

    actionsRenderer: CustomActionsRenderer;
    triggerableComponentItemRenderers: CustomTriggerableComponentItemRenderer[] = [];

    triggerActionsDivElement: HTMLDivElement;

    actionSettingsComp: CustomActionSettingsComponent;

    get actionSettings(): CustomActionSettingsComponent {
        if (this.actionSettingsComp) {
            return this.actionSettingsComp;
        }

        let runtime = Vertex.Globals.runtime as Vertex.VertexRuntime;
        runtime.space.nodes.forEach(node => {
            const comp = node.getComponent("ActionSettings") as CustomActionSettingsComponent;
            
            if (comp) {
                this.actionSettingsComp = comp;
            }
        });

        return this.actionSettingsComp;
    }


    async addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {

        //html setup
        this.panel = document.querySelector(".container-overlay-left");
        this.panel.classList.remove("hidden");

        let card = document.createElement("div");
        card.classList.add("card", "pointer-enable");
        card.id = "skybox-panel";

        let header = document.createElement("div");
        header.classList.add("nav-link", "card-header-minimizable", "dark-text", "max");
        card.appendChild(header);

        let headerText = document.createElement("div");
        headerText.classList.add("card-header-text");
        headerText.innerText = "Skybox Settings";
        header.appendChild(headerText);

        let body = document.createElement("div");
        body.id = "skybox-card-body";
        body.classList.add("card-body", "p-0");
        card.appendChild(body);

        let panel = document.createElement("div");
        panel.classList.add("skybox-panel");
        body.appendChild(panel);

        //List of Skyboxes
        let scrollableListLabel = document.createElement("div");
        scrollableListLabel.id = "skybox-list-label";
        scrollableListLabel.classList.add("skybox-text");
        scrollableListLabel.innerText = "HDR Maps:";
        panel.appendChild(scrollableListLabel);

        let scrollableListContainer = document.createElement("div");
        scrollableListContainer.id = "skybox-list-container";
        panel.appendChild(scrollableListContainer);

        const scrollableListView = document.createElement("ul") as HTMLUListElement;
        scrollableListView.id = "skybox-list";
        scrollableListView.classList.add("scrollable-list-group", "skybox-list-group");
        scrollableListContainer.appendChild(scrollableListView);


        //dropzone
        const dropzone = document.createElement("div") as HTMLDivElement;
        dropzone.id = "skybox-dropzone";
        dropzone.innerText = "Drag and drop or click to upload files.";
		dropzone.style.margin = "28px 10px";
        dropzone.style.padding = "20px 2px";
        dropzone.className = "dropzone-wide";
        dropzone.addEventListener("drop", this.handleFilesDropped);
        dropzone.addEventListener("dragenter", this.handleDragEnterEvent(dropzone));
        dropzone.addEventListener("dragleave", this.handleDragLeaveEvent(dropzone));
        dropzone.addEventListener("dragover", (event) => event.preventDefault());
        dropzone.addEventListener("click", () => uploader.click());

        //<input type="file" multiple />
        let uploader = document.createElement("input");
        uploader.setAttribute("multiple", "multiple");
        uploader.setAttribute("type", "file");
        uploader.setAttribute("accept", "." + ALLOWED_SKYBOX_TYPES.join(",."));
        uploader.setAttribute("style", "display: none");
        uploader.style.display = "none";
        uploader.addEventListener('change', async () => {
            let files: File[] = Array.from(uploader.files);

            Swal.fire({
                icon: "info",
                title: "Preparing upload...",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                heightAuto: false
            });

            Swal.showLoading();

            const res = await ResourceUtils.getResourceAsync(Vertex.Globals.spaceId, false);

            if(res?.resourceKeys){
                this.deleteSkyboxes(files, res);
            }
                        
            let uploadResult = await UploadUtils.uploadFiles(files, Vertex.Globals.spaceId, UploadingAssetType.SpaceSkybox);

            await this.registerNewSkyboxes(uploadResult.successfulUploads);

            uploader.value = "";

            if (Swal.isVisible()) {
                Swal.close();
            }
        });



        dropzone.appendChild(uploader);
        panel.appendChild(dropzone);

        // Sidebar
        let leftSidebar = document.querySelector(".left-sidebar-grid");
        leftSidebar.appendChild(card);

        Utils.setupSidebarButton("SkyboxSettingsPanel", "skybox-panel");

        //<----------------------------------------------------On Global Events---------->

        //Get references to SkyboxComponent and SceneSettings component
        Vertex.Globals.event.on("hevolus:onSpaceLoaded", async (loadedNodes: Vertex.NodeComponentModel.VertexNode[]) => {

            await Utils.waitForCondition(_ => Array.from<Vertex.NodeComponentModel.VertexNode>(Vertex.Globals.runtime.space.nodes.values()).find((node) => node.components.includes("Skybox")) != null).then(_ => {
                const nodes = Array.from<Vertex.NodeComponentModel.VertexNode>(Vertex.Globals.runtime.space.nodes.values());
                this.skyboxComp = nodes.find((node) => node.components.includes("Skybox")).getComponent("Skybox") as CustomSkyboxComponent;
            });

            await this.skyboxComp.isReady;

            this.skyboxComp.onChanged.on(_ => {
                this.populateSkyboxList();

                this.updateActionsView(panel);
            });

            Vertex.Globals.event.on("ActionSettings:ActionAdded", this.updateActionsView);
            Vertex.Globals.event.on("ActionSettings:ActionUpdated", this.updateActionsView);

            // this.skyboxComp.triggerOnChanged();
            this.populateSkyboxList();
            this.updateActionsView(panel);
        });


    }

    private deleteSkyboxes(files: File[], resource: VERTEXResource) {
        //find all indexes of skyboxes in CustomSkyboxComponent.skyboxesToDelete that are both in files and in resource.resourceKeys
        const indexes = CustomSkyboxComponent.skyboxesToDelete.map((skyboxToDelete) => {
            if(files.findIndex(f => f.name == skyboxToDelete) != -1 && resource.resourceKeys.indexOf(skyboxToDelete) != -1){
                return CustomSkyboxComponent.skyboxesToDelete.indexOf(skyboxToDelete);
            };
        }).filter(i => i != null);

        //delete those skyboxes from the resource, and remove them from CustomSkyboxComponent.skyboxesToDelete putting a null in their place to avoid shifting indexes
        for(let i = 0; i < indexes.length; i++){
            ResourceUtils.deleteAssetFromResource(Vertex.Globals.spaceId, CustomSkyboxComponent.skyboxesToDelete[indexes[i]]).then(resp => {
                if(!resp.ok){
                    console.error(`Error deleting skybox ${CustomSkyboxComponent.skyboxesToDelete[indexes[i]]} from resource.`);
                }
            });

            CustomSkyboxComponent.skyboxesToDelete.splice(indexes[i], 1, null);
        }

        //then remove all the nulls from CustomSkyboxComponent.skyboxesToDelete
        CustomSkyboxComponent.skyboxesToDelete = CustomSkyboxComponent.skyboxesToDelete.filter(s => s != null);
    }

    updateActionsView = (async (panel: HTMLElement) => {
        const availableSkyboxes = this.skyboxComp.skyboxes.filter(skybox => !CustomSkyboxComponent.skyboxesToDelete.includes(skybox.skyboxUri));
        const availableSkyboxesNames = availableSkyboxes.map(skybox => skybox.skyboxUri);

        availableSkyboxesNames[0] = "Default Skybox";

        await this.actionSettings.isReady;

        if (!this.actionsRenderer) {
            this.actionsRenderer = new CustomActionsRenderer();
            let actionListCardElement = this.actionsRenderer.RenderActions(this.skyboxComp, this.actionSettings, availableSkyboxesNames);
            actionListCardElement.classList.remove("mt-3", "card");
            actionListCardElement.style.margin = "0.5rem";
            panel.appendChild(actionListCardElement);
        } else {
            this.actionsRenderer.RenderActions(this.skyboxComp, this.actionSettings, availableSkyboxesNames);
        }

        this.triggerableComponentItemRenderers.forEach((triggerableComponentItemRenderer, index) => {
            const isStarred = this.skyboxComp.activeSkyboxIndex == index;
            triggerableComponentItemRenderer.RenderItem(isStarred);
        });

    }).bind(this);

    populateSkyboxList = (() => {
        const self = this;

        const filteredSkyboxes = self.skyboxComp.skyboxes.filter(skybox => !CustomSkyboxComponent.skyboxesToDelete.includes(skybox.skyboxUri));
        if (filteredSkyboxes.length != self.triggerableComponentItemRenderers.length) {

            const scrollableListView = self.panel.querySelector("#skybox-list") as HTMLUListElement;
            scrollableListView.innerText = "";

            self.triggerableComponentItemRenderers = [];

            for (let i = 0; i < filteredSkyboxes.length; i++) {
                const skybox = filteredSkyboxes[i];

                const skyboxNamePromise = Promise.resolve(skybox.skyboxUri.length > 0 ? skybox.skyboxUri : "Default Skybox");
                const thumbnailUrlPromise = null;

                const isStarred = self.skyboxComp.activeSkyboxIndex == i;

                const onClickStar = () => {
                    self.changeMainSkybox(i);
                };

                const onClickDelete = skybox.skyboxUri.length == 0 ? ()=>{} : async () => {
                    let shouldCancel = false;

                    await Swal.fire({
                        title: 'Are you sure you want to delete this skybox?',
                        icon: "warning",
                        text: `Deleting a skybox from the Space is permanent`,
                        showCancelButton: true,
                        showConfirmButton: true,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        confirmButtonColor: "red",
                        heightAuto: false
                    }).then((result) => {
                        if (!result.isConfirmed) {
                            shouldCancel = true;
                        }
                    });

                    if (shouldCancel) return;

                    if (skybox.skyboxUri == this.skyboxComp.skyboxes[this.skyboxComp.activeSkyboxIndex].skyboxUri) {
                        this.changeMainSkybox(0);
                    }

                    const deletedSkyboxIndex = this.skyboxComp.skyboxes.findIndex((tempSkybox) => tempSkybox.skyboxUri == skybox.skyboxUri);

                    let indexOfActionUsingMissingFile = this.skyboxComp.actionValues.findIndex((i) => i == deletedSkyboxIndex);

                    while (indexOfActionUsingMissingFile > -1) {
                        this.skyboxComp.actionIndexes.splice(indexOfActionUsingMissingFile, 1);
                        this.skyboxComp.actionValues.splice(indexOfActionUsingMissingFile, 1);
                        indexOfActionUsingMissingFile = this.skyboxComp.actionValues.findIndex((i) => i == deletedSkyboxIndex);
                    }

                    for (let i = 0; i < this.skyboxComp.actionValues.length; i++) {
                        if (this.skyboxComp.actionValues[i] > deletedSkyboxIndex) {
                            this.skyboxComp.actionValues[i] = this.skyboxComp.actionValues[i] - 1;
                        }
                    }

                    indexOfActionUsingMissingFile = this.skyboxComp.triggerActionValues.findIndex((i) => i == deletedSkyboxIndex);

                    while (indexOfActionUsingMissingFile > -1) {
                        this.skyboxComp.triggerActionIndexes.splice(indexOfActionUsingMissingFile, 1);
                        this.skyboxComp.triggerActionValues.splice(indexOfActionUsingMissingFile, 1);
                        indexOfActionUsingMissingFile = this.skyboxComp.triggerActionValues.findIndex((i) => i == deletedSkyboxIndex);
                    }

                    for (let i = 0; i < this.skyboxComp.actionValues.length; i++) {
                        if (this.skyboxComp.triggerActionValues[i] > deletedSkyboxIndex) {
                            this.skyboxComp.triggerActionValues[i] = this.skyboxComp.triggerActionValues[i] - 1;
                        }
                    }

                    CustomSkyboxComponent.skyboxesToDelete.push(skybox.skyboxUri);
                    this.skyboxComp.skyboxes.splice(deletedSkyboxIndex, 1);

                    this.skyboxComp.triggerOnChanged();
                    Vertex.Globals.event.fire("ActionSettings:ActionAssignmentUpdated");
                }

                const skyboxSettings = this.createSkyboxSettings(i);

                const triggerableRenderer = new CustomTriggerableComponentItemRenderer(self.skyboxComp, self.actionSettings, i, skyboxNamePromise, thumbnailUrlPromise, isStarred, onClickStar, onClickDelete, skyboxSettings);
                self.triggerableComponentItemRenderers.push(triggerableRenderer);

                const triggerableRendererElement = triggerableRenderer.RenderItem();
                scrollableListView.appendChild(triggerableRendererElement);
            }
        }
        else {
            self.triggerableComponentItemRenderers.forEach((triggerableComponentItemRenderer, index) => {
                const isStarred = self.skyboxComp.activeSkyboxIndex == index;
                triggerableComponentItemRenderer.RenderItem(isStarred);
            });
        }

    }).bind(this);

    createSkyboxSettings = ((skyboxIndex): HTMLDivElement => {
        const skyboxSettingsWrapper = document.createElement("div");

        //Skybox Rotation Slider
        let skyboxRotWrapper = document.createElement("div");
        skyboxRotWrapper.classList.add("slider-wrapper", "skybox-slider-wrapper");
        skyboxSettingsWrapper.appendChild(skyboxRotWrapper);

        let skyboxRotTitle = document.createElement("div");
        skyboxRotTitle.classList.add("skybox-text");
        skyboxRotTitle.innerText = "Skybox Rotation";
        skyboxRotWrapper.appendChild(skyboxRotTitle);

        let skyboxRotSlider = document.createElement("input");
        skyboxRotSlider.classList.add("pbr-slider");
        skyboxRotSlider.setAttribute("type", "range");
        skyboxRotSlider.setAttribute("min", "0");
        skyboxRotSlider.setAttribute("max", "360");
        skyboxRotSlider.setAttribute("value", "0");
        skyboxRotSlider.addEventListener("input", () => {
            skyboxRotValue.innerText = skyboxRotSlider.value.toString();
            skyboxRotSlider.value = this.clamp(skyboxRotSlider.value, 0, 360);

            this.skyboxComp.skyboxes[skyboxIndex].skyboxRotation = parseInt(skyboxRotSlider.value);
            this.skyboxComp.updateSkyboxComp(false);
        });

        skyboxRotSlider.addEventListener("change", async () => {
            await Utils.delay(250);
            skyboxRotValue.innerText = skyboxRotSlider.value.toString();
            skyboxRotSlider.value = this.clamp(skyboxRotSlider.value, 0, 360);

            this.skyboxComp.skyboxes[skyboxIndex].skyboxRotation = parseInt(skyboxRotSlider.value);
            this.skyboxComp.updateSkyboxComp(true);
        });

        skyboxRotWrapper.appendChild(skyboxRotSlider);

        let skyboxRotValue = document.createElement("div");
        skyboxRotValue.classList.add("skybox-text");
        skyboxRotValue.innerText = "0"
        skyboxRotWrapper.appendChild(skyboxRotValue);

        //Skybox Intensity Slider
        let skyboxIntensityWrapper = document.createElement("div");
        skyboxIntensityWrapper.classList.add("slider-wrapper", "skybox-slider-wrapper");
        skyboxSettingsWrapper.appendChild(skyboxIntensityWrapper)

        let skyboxIntensityTitle = document.createElement("div");
        skyboxIntensityTitle.classList.add("skybox-text");
        skyboxIntensityTitle.innerText = "Skybox Intensity";
        skyboxIntensityWrapper.appendChild(skyboxIntensityTitle);

        let skyboxIntensitySlider = document.createElement("input");
        skyboxIntensitySlider.classList.add("pbr-slider");
        skyboxIntensitySlider.setAttribute("type", "range");
        skyboxIntensitySlider.setAttribute("min", "0.00");
        skyboxIntensitySlider.setAttribute("max", "2.00");
        skyboxIntensitySlider.setAttribute("step", "0.01");
        skyboxIntensitySlider.setAttribute("value", "1");
        skyboxIntensitySlider.setAttribute("decimalPlaces", "2");
        skyboxIntensitySlider.addEventListener("input", () => {
            skyboxIntensityValue.innerText = skyboxIntensitySlider.value.toString();

            this.skyboxComp.skyboxes[skyboxIndex].skyboxIntensity = parseFloat(skyboxIntensitySlider.value);
            this.skyboxComp.updateSkyboxComp(false);
        });
        skyboxIntensitySlider.addEventListener("change", async () => {
            await Utils.delay(250);
            skyboxIntensityValue.innerText = skyboxIntensitySlider.value.toString();

            this.skyboxComp.skyboxes[skyboxIndex].skyboxIntensity = parseFloat(skyboxIntensitySlider.value);
            this.skyboxComp.updateSkyboxComp(true);
        });

        skyboxIntensityWrapper.appendChild(skyboxIntensitySlider);

        let skyboxIntensityValue = document.createElement("div");
        skyboxIntensityValue.classList.add("skybox-text");
        skyboxIntensityValue.innerText = "0";
        skyboxIntensityWrapper.appendChild(skyboxIntensityValue);

        //Skybox Blur Slider
        let skyboxBlurWrapper = document.createElement("div");
        skyboxBlurWrapper.classList.add("slider-wrapper", "skybox-slider-wrapper");
        skyboxSettingsWrapper.appendChild(skyboxBlurWrapper)

        let skyboxBlurTitle = document.createElement("div");
        skyboxBlurTitle.classList.add("skybox-text");
        skyboxBlurTitle.innerText = "Skybox Blur";
        skyboxBlurWrapper.appendChild(skyboxBlurTitle);

        let skyboxBlurSlider = document.createElement("input");
        skyboxBlurSlider.classList.add("pbr-slider");
        skyboxBlurSlider.setAttribute("type", "range");
        skyboxBlurSlider.setAttribute("min", "0.00");
        skyboxBlurSlider.setAttribute("max", "1.00");
        skyboxBlurSlider.setAttribute("step", "0.01");
        skyboxBlurSlider.setAttribute("value", "1");
        skyboxBlurSlider.setAttribute("decimalPlaces", "2");
        skyboxBlurSlider.addEventListener("input", () => {
            skyboxBlurValue.innerText = skyboxBlurSlider.value.toString();

            this.skyboxComp.skyboxes[skyboxIndex].skyboxBlur = parseFloat(skyboxBlurSlider.value);

            this.skyboxComp.updateSkyboxComp(false);
        });

        skyboxBlurSlider.addEventListener("change", async () => {
            await Utils.delay(250);
            skyboxBlurValue.innerText = skyboxBlurSlider.value.toString();

            this.skyboxComp.skyboxes[skyboxIndex].skyboxBlur = parseFloat(skyboxBlurSlider.value);

            this.skyboxComp.updateSkyboxComp(true, false, true);
        });

        skyboxBlurWrapper.appendChild(skyboxBlurSlider);

        let skyboxBlurValue = document.createElement("div");
        skyboxBlurValue.classList.add("skybox-text");
        skyboxBlurValue.innerText = "0";
        skyboxBlurWrapper.appendChild(skyboxBlurValue);

        // Fake Skybox
        let fakeSkyboxCheckBoxWrapper = document.createElement("div") as HTMLDivElement;
        fakeSkyboxCheckBoxWrapper.id = "fake-skybox-occlusion-wrapper";
        fakeSkyboxCheckBoxWrapper.classList.add("fake-skybox-occlusion-wrapper", "switch-custom");
        skyboxSettingsWrapper.appendChild(fakeSkyboxCheckBoxWrapper);

        let fakeSkyboxCheckBoxLabel = document.createElement("div") as HTMLDivElement;
        fakeSkyboxCheckBoxLabel.id = "fake-skybox-checkbox-label";
        fakeSkyboxCheckBoxLabel.classList.add("skybox-text");
        fakeSkyboxCheckBoxLabel.innerText = "Occlude Skybox";
        fakeSkyboxCheckBoxWrapper.appendChild(fakeSkyboxCheckBoxLabel);

        let fakeSkyboxCheckBox = document.createElement("input") as HTMLInputElement;
        fakeSkyboxCheckBox.id = "fake-skybox-checkbox";
        fakeSkyboxCheckBox.classList.add("skybox-checkbox");
        fakeSkyboxCheckBox.type = "checkbox";
        fakeSkyboxCheckBox.addEventListener("click", () => {
            this.skyboxComp.skyboxes[skyboxIndex].hasFakeSkybox = fakeSkyboxCheckBox.checked;
            this.skyboxComp.updateSkyboxComp(true);
        });

        fakeSkyboxCheckBoxWrapper.appendChild(fakeSkyboxCheckBox);

        let fakeSkyboxColorWrapper = document.createElement("div") as HTMLDivElement;
        fakeSkyboxColorWrapper.id = "fake-skybox-color-wrapper";
        fakeSkyboxColorWrapper.classList.add("fake-skybox-color-wrapper");
        skyboxSettingsWrapper.appendChild(fakeSkyboxColorWrapper);

        let fakeSkyboxColorLabel = document.createElement("div") as HTMLDivElement;
        fakeSkyboxColorLabel.id = "fake-skybox-color-label";
        fakeSkyboxColorLabel.classList.add("skybox-text");
        fakeSkyboxColorLabel.innerText = "Occlusion Color";
        fakeSkyboxColorWrapper.appendChild(fakeSkyboxColorLabel);

        let colourPicker = document.createElement("input") as HTMLInputElement;
        colourPicker.id = "fake-skybox-color-picker";
        colourPicker.classList.add("skybox-colour-picker");
        colourPicker.type = "color";
        colourPicker.addEventListener('input', () => {
            let inColour = BABYLON.Color3.FromHexString(colourPicker.value) as BABYLON.Color3;

            this.skyboxComp.skyboxes[skyboxIndex].diffuseR = inColour.r;
            this.skyboxComp.skyboxes[skyboxIndex].diffuseG = inColour.g;
            this.skyboxComp.skyboxes[skyboxIndex].diffuseB = inColour.b;
            this.skyboxComp.updateSkyboxComp(false);
        });

        colourPicker.addEventListener('change', async   () => {
            await Utils.delay(250);
            let inColour = BABYLON.Color3.FromHexString(colourPicker.value) as BABYLON.Color3;

            this.skyboxComp.skyboxes[skyboxIndex].diffuseR = inColour.r;
            this.skyboxComp.skyboxes[skyboxIndex].diffuseG = inColour.g;
            this.skyboxComp.skyboxes[skyboxIndex].diffuseB = inColour.b;
            this.skyboxComp.updateSkyboxComp(true);
        });

        const skybox = this.skyboxComp.skyboxes[skyboxIndex];

        skyboxRotSlider.value = skyboxRotValue.innerText = skybox.skyboxRotation.toString();
        skyboxIntensitySlider.value = skyboxIntensityValue.innerText = skybox.skyboxIntensity.toString();
        skyboxBlurSlider.value = skyboxBlurValue.innerText = skybox.skyboxBlur.toString();

        //update fake skybox in response to component value changes
        fakeSkyboxCheckBox.checked = skybox.hasFakeSkybox;
        let colour: BABYLON.Color3;
        colour = new BABYLON.Color3(skybox.diffuseR, skybox.diffuseG, skybox.diffuseB);
        colourPicker.value = colour.toHexString();

        fakeSkyboxColorWrapper.appendChild(colourPicker);

        return skyboxSettingsWrapper;
    }).bind(this);

    private handleDragEnterEvent(dropzone: HTMLDivElement): (this: HTMLDivElement, ev: DragEvent) => any {
        return (event) => {
            event.preventDefault();
            dropzone.className = "dropzone-wide-drag";
        };
    }

    private handleDragLeaveEvent(dropzone: HTMLDivElement): (this: HTMLDivElement, ev: DragEvent) => any {
        return (event) => {
            event.preventDefault();
            dropzone.className = "dropzone-wide";
        };
    }

    handleFilesDropped = async (event: DragEvent) => {
        event.preventDefault();
        const files: File[] = this.getFilesDropped(event) as File[];

        Swal.fire({
            icon: "info",
            title: "Preparing upload...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            heightAuto: false
        });

        Swal.showLoading();

        const res = await ResourceUtils.getResourceAsync(Vertex.Globals.spaceId, false);

        if(res?.resourceKeys){
            this.deleteSkyboxes(files, res);
        }
        
        let uploadResult = await UploadUtils.uploadFiles(files, Vertex.Globals.spaceId, UploadingAssetType.SpaceSkybox);
        
        await this.registerNewSkyboxes(uploadResult.successfulUploads);
                    
        if(Swal.isVisible()){
            Swal.close();
        }
    }

    getFilesDropped = (event: DragEvent) => {
        let files: File[] | DataTransferItem[] = [];

        if (event.dataTransfer) {
            const dataTransfer = event.dataTransfer;

            if (dataTransfer.files && dataTransfer.files.length > 0) {
                files = [...dataTransfer.files];
            } else if (dataTransfer.items && dataTransfer.items.length > 0) {
                files = [...dataTransfer.items];
            } else {
                throw new Error("No Data Transfer items or files found.");
            }
        }

        return files;
    }

    private async registerNewSkyboxes(successfulFiles: string[]) {
        for (let i = 0; i < successfulFiles.length; i++) {
            let skyboxIndex = this.skyboxComp.skyboxes.findIndex((tempSkybox) => tempSkybox.skyboxUri == successfulFiles[i]);

            if (skyboxIndex > -1) {
                let removedSkyboxes = this.skyboxComp.skyboxes.splice(skyboxIndex, 1);
                this.skyboxComp.skyboxes.push(removedSkyboxes[0]);
            }
            else {
                let skybox = new Skybox();
                skybox.skyboxUri = successfulFiles[i];
                skybox.skyboxIntensity = CustomSkyboxComponent.DEFAULT_INTENSITY;
                skybox.skyboxBlur = CustomSkyboxComponent.DEFAULT_BLUR;
                skybox.skyboxRotation = CustomSkyboxComponent.DEFAULT_ROTATION;
                skybox.hasFakeSkybox = CustomSkyboxComponent.DEFAULT_HAS_FAKE_SKYBOX;
                skybox.diffuseR = CustomSkyboxComponent.DEFAULT_DIFFUSE_R;
                skybox.diffuseG = CustomSkyboxComponent.DEFAULT_DIFFUSE_G;
                skybox.diffuseB = CustomSkyboxComponent.DEFAULT_DIFFUSE_B;

                this.skyboxComp.skyboxes.push(skybox);
            }
        }

        this.skyboxComp.performApplySkyboxOperation();
        await this.skyboxComp.postComponentJsonToResource(true);
        this.skyboxComp.triggerOnChanged();
    }

    private changeMainSkybox(index: number) {
        let uriChanged = this.skyboxComp.activeSkyboxIndex !== index;

        this.skyboxComp.activeSkyboxIndex = index;
        this.skyboxComp.updateSkyboxComp(true, uriChanged);
    }

    minimizePanel(target: HTMLElement) {
        let parent = target.closest(".card");
        let body = parent.querySelector(".card-body");
        let icon1 = target.querySelector(".mini-1");
        let icon3 = target.querySelector(".mini-3");

        if (target.classList.contains("max")) {
            target.classList.remove("max");
            target.classList.add("min");
            body.classList.remove("d-block");
            body.classList.add("d-none");
            icon1.classList.remove("squish-mini");
            parent.classList.remove("h-30vh");

            console.log(target.classList);
            console.log(body.classList);
        }

        else {
            target.classList.remove("min");
            target.classList.add("max");
            body.classList.remove("d-none");
            body.classList.add("d-block");
            icon1.classList.add("squish-mini");
            parent.classList.add("h-30vh");


            console.log(target.classList);
            console.log(body.classList);
        }
    }

    clamp(val, min, max) {
        return val > max ? max : val < min ? min : val;
    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
    }
}


export class SkyboxSettingsPanelComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new SkyboxSettingsPanelComponent();
    }
    constructor() {
        super("SkyboxSettingsPanel", new SkyboxSettingsPanelComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}
export interface EventHandler<TArgs> {
    (sender: any, args: TArgs): void | Promise<any>;
}

/**
 * provides an interface for firing custom events, similar to C# events.
 * additionally supports passing custom 'thisArg's, to avoid the need to bind functions.
 */
export class EventBus<TArgs> {
    private _handlers: Map<EventHandler<TArgs>, any> = new Map();

    /** 
     * add an event handler to this bus. the 'thisArg' will be used as the 'this' when firing this handler.
     * if the handler already exists, the thisArg will be updated.
     */
    public on(handler: EventHandler<TArgs>, thisArg?: any): void {
        this._handlers.set(handler, thisArg);
    }

    /**
     * remove a handler from this bus
     */
    public off(handler: EventHandler<TArgs>): void {
        this._handlers.delete(handler);
    }

    /**
     * triggers the event handlers.
     */
    public fire(sender: any, args: TArgs) {
        for (let [handler, context] of this._handlers) {
            try {
                if (context) {
                    handler.call(context, sender, args);
                }
                else {
                    handler(sender, args);
                }
            }
            catch (e) {
                console.error(`Unhandled error in event handler`, e);
            }
        }
    }
}
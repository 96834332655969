import { Config } from "../../config";

export interface User {
    sub: string,
    AspNetIdentitySecurityStamp: string,
    aad_oid: string,
    vertex_devops: string,
    vertex_developer: string[],
    vertex_stack_admin: string,
    vertex_tid: string,
    vertex_tc: string,
    picture: string,
    email: string,
    email_verified: string,
    name: string,
    vertex_tname: string
}

export class UserProfileUtils {
    /**
     * Get User info
     * @param token optional param to get User info by token
     * @returns User info object or null if errors occurred
     */
    static async getUserInfo(token?: string): Promise<User> {
        let result: User = null;
        token = token ?? Vertex.Globals.bearerToken;

        try{
            const resp = await fetch(`${Config.VERTEX_IDENTITY_URL}/connect/userinfo`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });

            if(resp?.ok){
                const user = await resp.json() as User;

                if(user){
                    result = user;
                }
            }            
        }
        catch(e){
            console.log(`Failed to get User info`, e);
        }

        return result;
    }

    /**
     * Get Tenant Id
     * @param token optional param to get Tenant Id by token
     * @returns Tenant Id or null if errors occurred
     */
    static async getTenantId(token?: string): Promise<string> {
        token = token ?? Vertex.Globals.bearerToken;

        return (await UserProfileUtils.getUserInfo(token))?.vertex_tid;
    }
}
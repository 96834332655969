import { CustomSpawnPointComponent } from "../componentsystems/NodeComponents/spawnpointcomponent";
import { CustomRotationRenderer } from "./customrotationrenderer";
import { CustomXYZRenderer } from "./customxyzrenderer";

export class CustomSpawnPointRenderer implements Vertex.UI.ICustomInspectorRenderer {
    
    constructor(targetNode: Vertex.NodeComponentModel.VertexNode) {
        this.targetNode = targetNode;
        this.customXYZRenderer = new CustomXYZRenderer(this.targetNode);
        //this.customScaleRenderer = new CustomXYZRenderer(this.targetNode);
        this.customRotationRenderer = new CustomRotationRenderer(this.targetNode);
    }

    customXYZRenderer : CustomXYZRenderer;
    customRotationRenderer : CustomRotationRenderer;

    targetNode: Vertex.NodeComponentModel.VertexNode;
    target: CustomSpawnPointComponent;
    property: string;
    row = document.createElement("div");

    //Target appears to be the node but we also have just targetNode so not sure why it's needed.
    RenderProperty(property: string, target: any): HTMLDivElement {

        this.target = target;
        this.property = property;
        let outer = document.createElement('div');

        let tComp = this.targetNode.getComponent("Transform") as Vertex.NodeComponentModel.TransformComponent;
        let transformCard = document.createElement("div"); transformCard.classList.add("card", "pointer-enable", "scroll-card");
        this.renderTransformSection(tComp, transformCard)
        outer.appendChild(transformCard);
        
        return outer;
    }

    renderTransformSection(tComp : Vertex.NodeComponentModel.TransformComponent, outer : HTMLElement)
    {
        let header = document.createElement("div"); header.classList.add("nav-link", "dark-text", "card-header-closable");
        let headerText = document.createElement("div"); headerText.classList.add("card-header-text"); headerText.innerText = "Transform";
    
        header.appendChild(headerText);

        let replaceButton = document.createElement("button"); replaceButton.classList.add("component-toggle");
        let replaceImg = document.createElement("img"); replaceImg.classList.add("node-icon");
        replaceImg.src = "/img/replace-spawnpoint-icon.svg";
        replaceButton.addEventListener("click", () => {this.target.toggleReplace();});
        replaceButton.appendChild(replaceImg);
        header.appendChild(replaceButton);

        outer.appendChild(header);

        let body = document.createElement("div"); 
        body.classList.add("card-body");

        let valPos = this.customXYZRenderer.RenderProperty("position", tComp);
        let inputsPos = valPos.querySelectorAll<HTMLInputElement>("input");
        inputsPos.forEach(i => i.disabled = true);
        body.appendChild(valPos);
        
        const valRot = this.customRotationRenderer.RenderProperty("rotation", tComp);
        let inputs = valRot.querySelectorAll("input");        

        inputs[0].disabled = true;
        inputs[2].disabled = true;

        body.appendChild(valRot);

        outer.appendChild(body);   
    }
    
}
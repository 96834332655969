import ServiceBase from "../service-base";

export interface TableApiKeyValuePair {
    table: string, 
    key: string, 
    value: string
}

export class TableApi extends ServiceBase {

    constructor(token: string, baseUrl: string) {
        super(token, `${baseUrl}/core/table/`);
    }

    async getAllKeyValuePairs(tableId: string): Promise<TableApiKeyValuePair[]> {
        const response = await this.makeRequestExtended(`${tableId}/`);        

        if(response.ok){
            try{
                return await response.json() as TableApiKeyValuePair[];
            }
            catch(e){
                console.log(e);
                return [];
            }
        }
        else{
            console.log(`Failed to get table with id ${tableId}. ${response.statusText}`);
            return [];
        }
    }

    async getKeyValuePair(tableId: string, key: string): Promise<TableApiKeyValuePair> {
        const response = await this.makeRequestExtended(`${tableId}/${key}`);        

        if(response.ok){
            try{
                return await response.json() as TableApiKeyValuePair;
            }
            catch(e){
                console.log(e);
                return null;
            }
        }
        else{
            console.log(`Failed to get pair with key ${key} from table with id ${tableId}. ${response.statusText}`);
            return null;
        }
    }

    async createKeyValuePair(keyValuePair: TableApiKeyValuePair): Promise<boolean> {
        
        const response = await this.makeRequestExtended(``, 'POST', JSON.stringify(keyValuePair), {
            "Content-Type": "application/octet-stream"
        });

        return response.ok;
    }

    async updateOrCreateKeyValuePair(keyValuePair: TableApiKeyValuePair): Promise<boolean> {
        
        const response = await this.makeRequestExtended(``, 'PUT', JSON.stringify(keyValuePair), {
            "Content-Type": "application/octet-stream"
        });

        return response.ok;
    }

    async deleteKeyValuePair(tableId: string, key: string): Promise<boolean> {
        const response = await this.makeRequestExtended(`${tableId}/${key}`, 'DELETE');
        return response.ok;
    }
}

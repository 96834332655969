import { Utils } from "../../../utilities/utils";
import { CustomPostProcessPropertiesComponent } from "../NodeComponents/postprocesspropertiescomponent";

export class PostProcessPanelComponent extends Vertex.NodeComponentModel.Component {

    writeData(writer: Vertex.BinaryWriter): void {
    }

    readData(reader: Vertex.BinaryReader): void {
    }
}


export class PostProcessPanelComponentView extends Vertex.NodeComponentModel.ComponentViewBase {

    panelBody: HTMLDivElement;
    panel: HTMLDivElement;
    postProcessComp: CustomPostProcessPropertiesComponent;
    sliderDictionary: Map<string, HTMLInputElement>;
    checkBoxDictionary: Map<string, HTMLInputElement>;
    sliderValueDictionary: Map<string, HTMLDivElement>;
    colorPickerDictionary: Map<string, HTMLInputElement>;

    async addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        this.sliderDictionary = new Map();
        this.checkBoxDictionary = new Map();
        this.sliderValueDictionary = new Map();
        this.colorPickerDictionary = new Map();

        //HTML Hooks


        // Sidebar
        let leftSidebarGrid = document.querySelector(".left-sidebar-grid");

        // Panel
        let card = document.createElement("div");
        card.classList.add("card", "pointer-enable");
        card.id = "post-process-settings-panel";

        let header = document.createElement("div");
        header.classList.add("nav-link", "card-header-minimizable", "dark-text", "max");

        let headerIcon = document.createElement("img");
        // headerIcon.src = "/img/postprocess.png";
        headerIcon.classList.add("card-header-icon");

        let headerText = document.createElement("div");
        headerText.classList.add("card-header-text");
        headerText.innerText = "Post Process Settings";

        let body = document.createElement("div");
        body.classList.add("card-body");

        let panel = document.createElement("div");
        panel.classList.add("scene-hierarchy-panel");

        let infoWrapper = document.createElement("div");

        let AAWrapper = document.createElement("div") as HTMLDivElement;
        AAWrapper.classList.add(/*"border", "border-primary", "rounded",*/"border-bottom");
        AAWrapper.appendChild(this.setupPropertyCheckBox("FXAA", "fxaaEnabled", "Enable or disable the anti aliasing."));
        AAWrapper.appendChild(this.setupPropertySlider("AA Samples", "antiAliasingSamples", 2, 8, 2, "Sample count, setting this to 4 will provide 4x anti aliasing."));
        infoWrapper.appendChild(AAWrapper);

        let bloomWrapper = document.createElement("div") as HTMLDivElement;
        bloomWrapper.classList.add(/*"border", "border-primary", "rounded",*/"border-bottom");

        bloomWrapper.appendChild(this.setupPropertyCheckBox("Bloom", "bloomEnabled", "Enable or disable the bloom effect."));
        let bloomWrapperChild = document.createElement("div") as HTMLDivElement;
        bloomWrapperChild.classList.add("flag-wrapper", "switch-custom");
        bloomWrapperChild.appendChild(this.setupPropertySlider("Bloom Weight", "bloomWeight", 0, 2, 0.01, "The strength of the bloom."));
        // bloomWrapperChild.appendChild(this.setupPropertySlider("Bloom Kernel", "bloomKernel", 0, 1, 0.01));
        // bloomWrapperChild.appendChild(this.setupPropertySlider("Bloom Scale", "bloomScale", 0, 1, 0.01));
        // bloomWrapperChild.appendChild(this.setupPropertySlider("Bloom Threshold", "bloomThreshold", 0, 1, 0.01));
        bloomWrapper.appendChild(bloomWrapperChild);
        infoWrapper.appendChild(bloomWrapper);

        infoWrapper.appendChild(this.setupPropertyCheckBox("Tone Mapping", "toneMappingEnabled", "Enable or disable the tone mapping effect."));

        //Screen Space Ambient Occlusion
        let SSAOWrapper = document.createElement("div") as HTMLDivElement;
        SSAOWrapper.classList.add(/*"border", "border-primary", "rounded",*/"border-bottom");
        SSAOWrapper.appendChild(this.setupPropertyCheckBox("SSAO", "screenSpaceAmbientOcclusionEnabled", "Enable or disable the screen-space ambient occlusion."));
        const SSAOStrength = this.setupPropertySlider("SSAO Strength", "screenSpaceAmbientOcclusionTotalStrength", 0, 1, 0.01, "The output strength of the SSAO post-process. Default value is 1.0.");
        SSAOWrapper.appendChild(SSAOStrength);
        infoWrapper.appendChild(SSAOWrapper);

        let vignetteWrapper = document.createElement("div") as HTMLDivElement;
        vignetteWrapper.classList.add(/*"border", "border-primary", "rounded",*/"border-bottom");
        vignetteWrapper.appendChild(this.setupPropertyCheckBox("Vignette", "vignetteEnabled", "Enable or disable the vignette effect."));
        let vignetteWrapperChild = document.createElement("div") as HTMLDivElement;
        vignetteWrapperChild.appendChild(this.setupPropertySlider("Vignette Weight", "vignetteWeight", 1, 20, 0.1, "Vignette weight or intensity of the vignette effect."));
        vignetteWrapperChild.appendChild(this.setupPropertyCheckBox("Vignette Rounded", "vignetteRounded", "Should the vignette be perfectly round or be dependent on the current aspect ratio?"));
        vignetteWrapperChild.appendChild(this.setupPropertyPicker("Vignette Color", "vignetteColorR", "vignetteColorG", "vignetteColorB", "vignetteColorA", "Color of the vignette applied on the screen."));
        vignetteWrapper.appendChild(vignetteWrapperChild);
        infoWrapper.appendChild(vignetteWrapper);

        infoWrapper.appendChild(this.setupPropertySlider("Contrast", "contrast", 0.01, 2, 0.01, ""));
        infoWrapper.appendChild(this.setupPropertySlider("Exposure", "exposure", 0.01, 2, 0.01, ""));

        let resetButton = document.createElement("button");
        resetButton.classList.add("btn", "btn-danger", "node-button");
        resetButton.style.padding = "0.25rem";
        resetButton.innerText = "Reset to defaults";
        resetButton.addEventListener("click", () => {
            this.postProcessComp.fxaaEnabled = true;
            this.postProcessComp.antiAliasingSamples = 4;

            this.postProcessComp.bloomEnabled = false;
            this.postProcessComp.bloomWeight = 0.1;
            this.postProcessComp.bloomKernel = 32;
            this.postProcessComp.bloomScale = 0.5;
            this.postProcessComp.bloomThreshold = 0.5;

            this.postProcessComp.toneMappingEnabled = true;

            this.postProcessComp.screenSpaceReflectionSample = 32;
            this.postProcessComp.screenSpaceReflectionStrength = 0;

            this.postProcessComp.screenSpaceAmbientOcclusionEnabled = false;
            this.postProcessComp.screenSpaceAmbientOcclusionTotalStrength = 1;

            this.postProcessComp.vignetteEnabled = false;
            this.postProcessComp.vignetteRounded = true;
            this.postProcessComp.vignetteWeight = 10;
            this.postProcessComp.vignetteColorR = 0;
            this.postProcessComp.vignetteColorG = 0;
            this.postProcessComp.vignetteColorB = 0;
            this.postProcessComp.vignetteColorA = 1;

            this.postProcessComp.contrast = 1;
            this.postProcessComp.exposure = 1;

            this.postProcessComp.triggerOnChanged();
        });
        infoWrapper.appendChild(resetButton);

        // Append
        leftSidebarGrid.appendChild(card);
        card.appendChild(header);
        header.appendChild(headerIcon);
        header.appendChild(headerText);
        card.appendChild(body);
        body.appendChild(panel);
        panel.appendChild(infoWrapper);

        // Sidebar

        Utils.setupSidebarButton("PostProcessPanel", "post-process-settings-panel");

        Vertex.Globals.event.on("hevolus:PostProcessPropertiesChanged", (customComp: CustomPostProcessPropertiesComponent) => {
            this.postProcessComp = customComp;

            if (customComp.screenSpaceAmbientOcclusionEnabled) {
                SSAOStrength.classList.remove("hidden");
            }
            else {
                SSAOStrength.classList.add("hidden");
            }

            if (customComp.bloomEnabled) {
                bloomWrapperChild.classList.remove("hidden");
            }
            else {
                bloomWrapperChild.classList.add("hidden");
            }

            if (customComp.vignetteEnabled) {
                vignetteWrapperChild.classList.remove("hidden");
            }
            else {
                vignetteWrapperChild.classList.add("hidden");
            }

            this.checkBoxDictionary.forEach((checkBox, propertyName) => {
                if (customComp[propertyName] != null) {
                    checkBox.checked = customComp[propertyName];
                }
            });

            this.sliderDictionary.forEach((slider, propertyName) => {
                if (customComp[propertyName] != null) {
                    slider.value = customComp[propertyName];

                    let value = customComp[propertyName].toFixed(2).toString();
                    this.sliderValueDictionary.get(propertyName).innerText = value;
                }
            });

            let vignetteColorPicker = this.colorPickerDictionary.get("vignetteColorR");
            if (vignetteColorPicker) {
                let vignetteColor = new BABYLON.Color3(customComp.vignetteColorR, customComp.vignetteColorG, customComp.vignetteColorB);
                vignetteColorPicker.value = vignetteColor.toHexString();
            }

        });
    }


    setupPropertyPicker(label: string, propertyNameR: string, propertyNameG: string, propertyNameB: string, propertyNameA: string, tooltip: string = null): HTMLDivElement {
        // picker
        let pickerWrapper = document.createElement("div") as HTMLDivElement;
        let pickerText = document.createElement("div") as HTMLDivElement;
        pickerText.classList.add("skybox-text");
        pickerText.innerText = label;
        
        if (tooltip) {
            pickerText.dataset.toggle = "tooltip";
            pickerText.dataset.placement = "bottom";
            pickerText.dataset.title = tooltip;
        }

        pickerWrapper.appendChild(pickerText);

        let colourPicker = document.createElement("input") as HTMLInputElement;
        colourPicker.type = "color";

        colourPicker.addEventListener('input', () => {
            let inColour = BABYLON.Color3.FromHexString(colourPicker.value) as BABYLON.Color3;
            this.postProcessComp[propertyNameR] = inColour.r;
            this.postProcessComp[propertyNameG] = inColour.g;
            this.postProcessComp[propertyNameB] = inColour.b;
            this.postProcessComp[propertyNameA] = 1;
            this.postProcessComp.triggerOnChanged();
        })

        pickerWrapper.appendChild(colourPicker);

        this.colorPickerDictionary.set(propertyNameR, colourPicker);

        return pickerWrapper;
    }

    setupPropertyCheckBox(label: string, propertyName: string, tooltip: string = null): HTMLDivElement {
        let checkBoxWrapper = document.createElement("div") as HTMLDivElement;
        checkBoxWrapper.classList.add("flag-wrapper", "switch-custom");
        let checkBoxText = document.createElement("div") as HTMLDivElement;
        checkBoxText.classList.add("skybox-text");
        checkBoxText.innerText = label;
        
        if (tooltip) {
            checkBoxText.dataset.toggle = "tooltip";
            checkBoxText.dataset.placement = "bottom";
            checkBoxText.dataset.title = tooltip;
        }

        checkBoxWrapper.appendChild(checkBoxText);

        let checkBox = document.createElement("input") as HTMLInputElement;
        checkBox.classList.add("skybox-checkbox");
        checkBox.type = "checkbox";

        checkBox.addEventListener("click", () => {
            this.postProcessComp[propertyName] = checkBox.checked;
            this.postProcessComp.triggerOnChanged();
        })

        checkBoxWrapper.appendChild(checkBox);

        this.checkBoxDictionary.set(propertyName, checkBox);

        return checkBoxWrapper;
    }

    setupPropertySlider(label: string, propertyName: string, min: number, max: number, step: number, tooltip: string = null): HTMLDivElement {
        let sliderWrapper = document.createElement("div");
        sliderWrapper.classList.add("slider-wrapper", "skybox-slider-wrapper");

        let sliderTitle = document.createElement("div");
        sliderTitle.classList.add("skybox-text");
        sliderTitle.innerText = label;
        
        if (tooltip) {
            sliderTitle.dataset.toggle = "tooltip";
            sliderTitle.dataset.placement = "bottom";
            sliderTitle.dataset.title = tooltip;
        }

        sliderWrapper.appendChild(sliderTitle);

        let slider = document.createElement("input");
        slider.classList.add("pbr-slider");
        slider.setAttribute("type", "range");
        slider.setAttribute("min", min.toString());
        slider.setAttribute("max", max.toString());
        slider.setAttribute("step", step.toString());
        slider.setAttribute("value", this.postProcessComp ? this.postProcessComp[propertyName].toString() : "0");
        slider.addEventListener("input", () => {
            sliderValue.innerText = slider.value;
            this.postProcessComp[propertyName] = parseFloat(slider.value);
            this.postProcessComp.triggerOnChanged();
        });

        sliderWrapper.appendChild(slider);

        let sliderValue = document.createElement("div");
        sliderValue.classList.add("skybox-text");
        sliderValue.innerText = slider.value.toString();
        sliderWrapper.appendChild(sliderValue);

        this.sliderDictionary.set(propertyName, slider);
        this.sliderValueDictionary.set(propertyName, sliderValue);

        return sliderWrapper;
    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
    }

}

export class PostProcessPanelComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new PostProcessPanelComponent();
    }

    constructor() {
        super("PostProcessPanel", new PostProcessPanelComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}

export default class ServiceBase {
    private baseUri: string = null;

    constructor(private token: string, baseUri: string) {
        this.baseUri = baseUri;
    }

    private getInit(method: string = 'GET', bodyJson: BodyInit = null, headers?: HeadersInit){
        let init: RequestInit = {
            credentials: 'include',
            method: method,
            body: bodyJson,
            headers: headers || {
                'Content-Type': 'application/json'
            }
        }

        if (this.token)
            init.headers["Authorization"] = `Bearer ${this.token}`;

        return init;
    }

    protected async makeRequest<T>(relativeUri: string = "", method: string = 'GET', bodyJson: BodyInit = null) {
        const init = this.getInit(method, bodyJson);
        const resp = await fetch(`${this.baseUri}${relativeUri}`, init);

        if (!resp.ok){
            throw new Error("Request failed with non-success status.");
        }

        return await resp.json() as T;
    }

    protected async makeRequestExtended(relativeUri: string = "", method: string = 'GET', bodyJson: BodyInit = null, headers?: HeadersInit) : Promise<Response> {
        const init = this.getInit(method, bodyJson, headers);
        const resp = await fetch(`${this.baseUri}${relativeUri}`, init);

        return resp;
    }
}
import { AugmentedStoreAssembly } from '../../../../AugmentedStoreAssembly';
import { Utils } from '../../../utilities/utils';
import { ChangeMaterialModel, ChangeMaterialModelUtils, SkinPreset } from '../../../utilities/change-material-model-utilities';
import { CustomGltfLoadingHandlerComponent, IAwaitableComponent, IResettableComponent } from './gltfLoadingHandlerComponent';
import { getGltfMeshIndex, findMaterialForGltfIndex, getGltfMaterialIndex } from '../../../utilities/gltf-utilities';
import { ResourceUtils } from '../../../utilities/resource-utilities';
import { CHANGE_MATERIAL_MODEL_FILENAME } from '../../../utilities/constants';
import { ITriggerActionComponent } from './actionsettingscomponent';

export class CustomChangeMaterialComponent extends AugmentedStoreAssembly.ChangeMaterialComponent implements IAwaitableComponent, IResettableComponent, ITriggerActionComponent {
    isTriggerActionComponent: boolean = true;
    isResettableComponent: boolean = true;
    isAwaitableComponent: boolean = true;

    funcOnLoad;

    modelParsed = false;

    _skinJson: ChangeMaterialModel;

    actionSettingsComponent: AugmentedStoreAssembly.ActionSettingsComponent;

    get skinJson() {
        return this._skinJson;
    }

    set skinJson(skinJson: ChangeMaterialModel) {
        this._skinJson = skinJson;
        if (skinJson == null) {
            this.modelParsed = false;
        }
        else {
            this.modelParsed = true;
        }
    }

    public defaultSkin: SkinPreset;

    gltfComp: Vertex.NodeComponentModel.GltfModelComponent;
    gltfLoadingHandlerComp : CustomGltfLoadingHandlerComponent;


    get skinData ():number[] {
        let tempPreset: SkinPreset = { name: "updated", subMeshConfigurations: [] };
        
        for(let i = 0; i < this.updatedMeshIndexes.length; i++){
            tempPreset.subMeshConfigurations.push({ materialIndex: this.updatedMatIndexes[i], subMeshIndex: this.updatedMeshIndexes[i] });
        }

        const skinData = this.getSkinDataFromSkinPreset(tempPreset);
        return skinData;
    }

    public getSkinDataFromSkinPreset(skin: SkinPreset): number[] {
        let skinData = [];

        if (skin == null) {
            console.error("[changeMaterial]skin is null");
            return skinData;
        }

        let meshCount = this.skinJson.subMeshes.length;

        for (let meshIndex = 0; meshIndex < meshCount; meshIndex++) {
            let materialIndex;
            let subMeshCfg = skin.subMeshConfigurations.find(config => config.subMeshIndex == meshIndex);
            if (subMeshCfg) {
                materialIndex = subMeshCfg.materialIndex;
            }
            else {
                materialIndex = this.defaultSkin.subMeshConfigurations.find(config => config.subMeshIndex == meshIndex)?.materialIndex;
                if (materialIndex == null) {
                    console.error("[changematerial] missing default submesh configuration. MeshIndex : " + meshIndex);
                    materialIndex = -1;
                }
            }

            skinData.push(materialIndex);
        }

        return skinData;
    }


    // restituisce il preset sotto forma di due vettori e rimuove le config uguali a quelle del default
    presetToUpdatedValues(preset : SkinPreset) : {materialIndexes: number[], subMeshIndexes: number[]}{

        let materialIndexes : number[] = [];
        let subMeshIndexes : number[] = [];

        for(let i = 0; i < preset.subMeshConfigurations.length; i++){
            let confing = preset.subMeshConfigurations[i];
            let index = this.defaultSkin.subMeshConfigurations.findIndex(c => c.materialIndex == confing.materialIndex && c.subMeshIndex == confing.subMeshIndex);
            if(index == -1){
                materialIndexes.push(confing.materialIndex);
                subMeshIndexes.push(confing.subMeshIndex);
            }
        }
    
        return {materialIndexes: materialIndexes, subMeshIndexes: subMeshIndexes};
    }


    public setSkinPreset(skin: SkinPreset) {
        if (this.modelParsed) {

            const updatedValues = this.presetToUpdatedValues(skin);

            this.updatedMatIndexes = updatedValues.materialIndexes;
            this.updatedMeshIndexes = updatedValues.subMeshIndexes;

            this.triggerOnChanged();
        }
    }

    async onLoad(): Promise<void> {
        return await this.funcOnLoad();
    }

    resetComponent(): void {

        this.modelParsed = false;
        this.updatedMatIndexes = new Array();
        this.updatedMeshIndexes = new Array();

        this.triggerOnChanged();
    }

}

export class ChangeMaterialComponentView extends Vertex.NodeComponentModel.ComponentViewBase {

    constructor() {
        super();
    }

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        let comp = component as CustomChangeMaterialComponent;

        comp.gltfComp = node.getComponent("GltfModel") as Vertex.NodeComponentModel.GltfModelComponent;

        const actionNode = Array.from(Vertex.Globals.getRuntime<VertexBabylon.VertexBabylonRuntime>().space.nodes.values()).find(n => n.components.includes("ActionSettings"));

        if(actionNode){
            comp.actionSettingsComponent = actionNode.getComponent("ActionSettings") as AugmentedStoreAssembly.ActionSettingsComponent;

            const onTriggerAction = ((msg) => this.onTriggerAction(comp, msg.actionIndex)).bind(this);

            comp.actionSettingsComponent.onActionTriggered.on(onTriggerAction);
            comp.onRemoved.on(() => comp.actionSettingsComponent.onActionTriggered.off(onTriggerAction));
        }

        let onActionDeleted = (deletedActionIndex) => {
            if (comp.actionIndexes.includes(deletedActionIndex)) {
                let actionIndex = comp.actionIndexes.indexOf(deletedActionIndex);
                comp.actionIndexes.splice(actionIndex, 1);
                comp.actionValues.splice(actionIndex, 1);
            }

            for(let i = 0; i < comp.actionIndexes.length; i++) {
                if(comp.actionIndexes[i] > deletedActionIndex) {
                    comp.actionIndexes[i] = comp.actionIndexes[i]-1;
                }
            }

            comp.triggerActionValues = comp.triggerActionValues.filter((val, idx) => comp.triggerActionIndexes[idx] != deletedActionIndex);
            comp.triggerActionIndexes = comp.triggerActionIndexes.filter(idx => idx != deletedActionIndex);

            for(let i = 0; i < comp.triggerActionIndexes.length; i++) {
                if(comp.triggerActionIndexes[i] > deletedActionIndex) {
                    comp.triggerActionIndexes[i] = comp.triggerActionIndexes[i]-1;
                }
            }
            comp.triggerOnChanged();
        };
        Vertex.Globals.event.on("ActionSettings:ActionDeleted", onActionDeleted);
        component.onRemoved.on(() => Vertex.Globals.event.off("ActionSettings:ActionDeleted", onActionDeleted));

        comp.funcOnLoad = async (): Promise<void> => {
            await this.onLoad(node, comp);
        }
    }

    async onLoad(node: Vertex.NodeComponentModel.VertexNode, comp: CustomChangeMaterialComponent) {
        comp.onChanged.off(this.onChanged);

        comp.gltfLoadingHandlerComp = node.getComponent("GltfLoadingHandler") as CustomGltfLoadingHandlerComponent;

        const skinsResponse = await ResourceUtils.getAssetFromResource(comp.gltfComp.id, CHANGE_MATERIAL_MODEL_FILENAME);

        if (!skinsResponse.ok) {
            return;
        }

        let skinsJson = await skinsResponse.json();

        comp.skinJson = skinsJson as ChangeMaterialModel;

        let currentSkin = this.getCurrentSkin(node);
        comp.defaultSkin = currentSkin;

        comp.onChanged.on(this.onChanged);

        this.changeActiveSkinFromSkinData(comp.gltfLoadingHandlerComp)
    }


    private async onTriggerAction (component: CustomChangeMaterialComponent, index : number){
        const valueIndex = component.actionIndexes.findIndex(actionIndex => actionIndex == index);

        if(valueIndex != -1){
            const actionValue = component.actionValues[valueIndex];

            if (actionValue != null && actionValue >= 0) {
                if (actionValue < component.skinJson.presets.length) {
                    const preset = component.skinJson.presets[actionValue];
                    component.setSkinPreset(preset);
                }
            }

        }
    }


    private onChanged = (async (component: Vertex.NodeComponentModel.Component) => {
        const customChangeMatComp = component as CustomChangeMaterialComponent;

            if (!customChangeMatComp.gltfComp.IsReady || !customChangeMatComp.modelParsed) {

                customChangeMatComp.gltfLoadingHandlerComp.setBusyLocal(false);

                return;
            }
    
            customChangeMatComp.gltfLoadingHandlerComp.onBusyLocal.off(this.changeActiveSkinFromSkinData);
    
            if (customChangeMatComp.gltfLoadingHandlerComp.isBusyLocal) {
                customChangeMatComp.gltfLoadingHandlerComp.onBusyLocal.on(this.changeActiveSkinFromSkinData);
            }
            else {
                this.changeActiveSkinFromSkinData(customChangeMatComp.gltfLoadingHandlerComp);
            }


    }).bind(this);



    private changeActiveSkinFromSkinData(gltfLoadingHandlerComp: CustomGltfLoadingHandlerComponent) {
        const customChangeMatComp = gltfLoadingHandlerComp.node.getComponent("ChangeMaterial") as CustomChangeMaterialComponent;
        if (customChangeMatComp.modelParsed && !gltfLoadingHandlerComp.isBusyLocal) {

            gltfLoadingHandlerComp.onBusyLocal.off(this.changeActiveSkinFromSkinData);

            gltfLoadingHandlerComp.setBusyLocal(true);

            const node = customChangeMatComp.node;
            const meshes = this.getSubmeshArray(node);

            const skinData = customChangeMatComp.skinData;

            if (skinData.length != meshes.length) {
                console.warn(`[ChangeMaterial] meshes length != skindata length`);
                gltfLoadingHandlerComp.setBusyLocal(false);
                return;
            }
        
            //the tex converter flips the ktx texture so we need to reflip them
            let gl = Vertex.Globals.runtime.engine._gl;	

            let supportsEtc = gl?.getExtension('WEBGL_compressed_texture_etc');	
            let supportsPvr = gl?.getExtension('WEBKIT_WEBGL_compressed_texture_pvrtc');

            for (const mesh of meshes) {
                // find the configuration for this mesh...
                const submeshIdx = getGltfMeshIndex(mesh);
                if (submeshIdx === -1) {
                    console.error(`[ChangeMaterial] Could not find GLTF Mesh for mesh`, mesh);
                    continue;
                }

                let materialIndex = skinData[submeshIdx];

                const material = findMaterialForGltfIndex(node.viewNode, materialIndex);
                if (!material) {
                    console.error(`[ChangeMaterial] The skindata configuration references a non-existent material`);
                    continue;
                }

                mesh.material = material;

                if(supportsEtc || supportsPvr){
                    for(let texture of mesh.material.getActiveTextures() as any[]){
                        const flipped = texture["flipped"];

                        if(flipped) {
                            texture.vScale = flipped;
                        }
                    }
                }
            }

            Vertex.Globals.event.fire("ChangeMaterial:skinChanged", customChangeMatComp);

            gltfLoadingHandlerComp.setBusyLocal(false);
        }
    }


    getCurrentSkin(node: Vertex.NodeComponentModel.VertexNode): SkinPreset {

        let result: SkinPreset = { name: "default", subMeshConfigurations: [] };

        const meshes = this.getSubmeshArray(node);
        for (const mesh of meshes) {

            const submeshIdx = getGltfMeshIndex(mesh);
            if (submeshIdx === -1) {
                console.error(`[Skins Tab] Could not find GLTF Mesh for mesh`, mesh);
                continue;
            }

            let materialIndex = getGltfMaterialIndex(mesh.material);

            if (materialIndex === -1) {
                console.error(`[Skins Tab] Could not find GLTF Material for material`, mesh);
                continue;
            }

            result.subMeshConfigurations.push({ materialIndex: materialIndex, subMeshIndex: submeshIdx });
        }

        return result;
    }

    getSubmeshArray(node: Vertex.NodeComponentModel.VertexNode): BABYLON.Mesh[] {
        //get submeshes
        let gltfComp = node.getComponent("GltfModel") as Vertex.NodeComponentModel.GltfModelComponent;
        let visualNodeChild = gltfComp.visualNode.getChildren()[0];
        let meshRoot = visualNodeChild.getChildren()[0];

        return meshRoot.getChildMeshes() as BABYLON.Mesh[];
    }



    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
    }


}

export class ChangeMaterialComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new CustomChangeMaterialComponent();
    }

    constructor() {

        super("ChangeMaterial", new ChangeMaterialComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}

import Swal from 'sweetalert2';
import { Utils } from '../../../utilities/utils';
import { AugmentedStoreAssembly } from '../../../../AugmentedStoreAssembly';
import { RESOURCE_API_URI } from '../../../utilities/constants';


export class LightMap {
    constructor(name, level = 1) {
        this.fileName = name;
        this.level = level;
    }

    fileName: string;
    level: number = 1;
}

export class LightHierarchyPanelComponent extends Vertex.NodeComponentModel.Component {

    static _lightHierarchyComp: LightHierarchyPanelComponent;

    static get lightHierarchyComp(): LightHierarchyPanelComponent {
        if (this._lightHierarchyComp) {
            return this._lightHierarchyComp;
        }

        let runtime = Vertex.Globals.runtime as Vertex.VertexRuntime;

        runtime.space.nodes.forEach(node => {
            if (node.components.includes("LightHierarchyPanel")) {
                this._lightHierarchyComp = node.getComponent("LightHierarchyPanel") as LightHierarchyPanelComponent;
            }
        });

        return this._lightHierarchyComp;
    }

    writeData(writer: Vertex.BinaryWriter): void {
    }
    readData(reader: Vertex.BinaryReader): void {
    }
}

export class LightHierarchyPanelComponentView extends Vertex.NodeComponentModel.ComponentViewBase {

    constructor() {
        super();
    }

    panelBody: HTMLDivElement;
    panel: HTMLDivElement;
    gltfNameDictionary: Map<string, string>;
    comp: LightHierarchyPanelComponent;
    scrollableListView: HTMLUListElement;

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        this.comp = component as LightHierarchyPanelComponent;
        this.gltfNameDictionary = new Map();

        let runtime = Vertex.Globals.runtime;
        let space = runtime.space;

        this.panel = document.querySelector(".container-overlay-left");
        this.panel.classList.remove("hidden");

        let tree = document.createElement("ul") as HTMLUListElement;
        tree.id = "myUL";

        // Create Hierarchy Panel

        let card = document.createElement("div");
        card.classList.add("card", "pointer-enable");
        card.id = "light-panel";

        let header = document.createElement("div");
        header.classList.add("nav-link", "card-header-minimizable", "dark-text", "max");

        let headerIcon = document.createElement("img");
        headerIcon.src = "/img/scene-icon-active.svg";
        headerIcon.classList.add("card-header-icon");

        let headerText = document.createElement("div");
        headerText.classList.add("card-header-text");
        headerText.innerText = "Light Hierarchy";

        let body = document.createElement("div");
        body.classList.add("card-body", "p-0");

        let panel = document.createElement("div");
        panel.classList.add("light-hierarchy-panel", "h-100");

        // Grid Area

        let leftSidebar = document.querySelector(".left-sidebar-grid");

        // Add Light Dropright

        let addLightButtonGroup = document.createElement("div");
        addLightButtonGroup.classList.add("btn-group")

        let addLightButton = document.createElement("button") as HTMLButtonElement;
        addLightButton.textContent = "Add Light";
        addLightButton.classList.add("btn", "component-toggle", "dropdown-toggle");
        addLightButton.style.margin = "auto";
        addLightButton.style.display = "block";
        addLightButton.style.margin = "0px !important";
        addLightButton.style.padding = "0px 10px";
        addLightButton.style.border = "1px solid lightgray";
        addLightButton.style.width = "fit-content";
        addLightButton.dataset.toggle = "dropdown";
        addLightButton.setAttribute("aria-haspopup", "true");
        addLightButton.setAttribute("aria-expanded", "false");


        let dropdownMenu = document.createElement("div");
        dropdownMenu.classList.add("dropdown-menu");

        let directionalLightLink = document.createElement("h6");
        directionalLightLink.classList.add("dropdown-item");
        directionalLightLink.textContent = "Directional Light"
        let spotlightLink = document.createElement("h6");
        spotlightLink.classList.add("dropdown-item");
        spotlightLink.textContent = "Spot Light"
        let pointlightLink = document.createElement("h6");
        pointlightLink.classList.add("dropdown-item");
        pointlightLink.textContent = "Point Light"

        addLightButtonGroup.appendChild(addLightButton);
        addLightButtonGroup.appendChild(dropdownMenu);

        dropdownMenu.appendChild(directionalLightLink);
        dropdownMenu.appendChild(spotlightLink);
        dropdownMenu.appendChild(pointlightLink);

        leftSidebar.appendChild(card);
        card.appendChild(header);
        header.appendChild(headerIcon);
        header.appendChild(headerText);
        header.appendChild(addLightButtonGroup);
        card.appendChild(body);
        body.appendChild(panel);

        let scene = Vertex.Globals.runtime.scene as BABYLON.Scene;

        directionalLightLink.addEventListener("click", () => {
            if (scene.lights.length > 3) {
                Swal.fire("Light limit hit!");
            }
            else {
                Vertex.Globals.event.fire("Vertx:CreateDirectionalLight");
            }
        });

        spotlightLink.addEventListener("click", () => {
            if (scene.lights.length > 3) {
                Swal.fire("Light limit hit!");
            }
            else {
                Vertex.Globals.event.fire("Vertx:CreateSpotLight");
            }
        })

        pointlightLink.addEventListener("click", () => {
            if (scene.lights.length > 3) {
                Swal.fire("Light limit hit!");
            }
            else {
                Vertex.Globals.event.fire("Vertx:CreatePointLight");
            }
        });

        panel.appendChild(tree);


        // Sidebar

        Utils.setupSidebarButton("LightHierarchyPanel", "light-panel").classList.add('hidden');

        Vertex.Globals.event.on("space:nodeAdded", (node: Vertex.NodeComponentModel.VertexNode) => {

            this.listNode(node, tree);
        });

        Vertex.Globals.event.on("space:nodeDestroyed", (node: Vertex.NodeComponentModel.VertexNode) => {

            console.log("deleted node: ", node);
            let nodeListItem = document.querySelector(`[data-node-id="${node.id}"]`) as HTMLLIElement;

            if (nodeListItem) {
                nodeListItem.remove();
            }
            else {
                console.log("couldn't find: ", node);
            }

        });

        Vertex.Globals.event.on("editor:selectNode", (node: Vertex.NodeComponentModel.VertexNode) => {

            for (const item of tree.querySelectorAll(".selected-list-item")) {
                item.classList.remove("selected-list-item");
                let lockIcon = item.querySelector(".lock-icon");
                if (lockIcon) {
                    lockIcon.classList.remove(`bright-lock-icon`);
                }
            }

            for (const item of tree.querySelectorAll(".list-item")) {

                let gltf = node.getComponent("GltfModel") as Vertex.NodeComponentModel.GltfModelComponent;
                if (gltf) {
                    let name = this.gltfNameDictionary.get(gltf.id);
                    if (item.textContent.includes(name)) {
                        item.classList.add("selected-list-item");
                        let lockIcon = item.querySelector(".lock-icon");
                        if (lockIcon) {
                            lockIcon.classList.add(`bright-lock-icon`);
                        }
                    }
                }
            }

        });

        Vertex.Globals.event.on("editor:clearSelection", (node: Vertex.NodeComponentModel.VertexNode) => {
            for (const item of tree.querySelectorAll(".selected-list-item")) {
                item.classList.remove("selected-list-item");
            }
        });

        //for each node in the scene
        for (let kvp of space.nodes.entries()) {

            let guid = kvp[0];
            let node = kvp[1];

            this.listNode(node, tree);
        }

    }

    private listNode(node: Vertex.NodeComponentModel.VertexNode, tree: HTMLUListElement) {

        let spotlight = node.getComponent("SpotLight") as Vertex.NodeComponentModel.GltfModelComponent;
        let directionalLight = node.getComponent("DirectionalLight") as Vertex.NodeComponentModel.GltfModelComponent;
        let pointlight = node.getComponent("PointLight") as Vertex.NodeComponentModel.GltfModelComponent;
        if (spotlight || directionalLight || pointlight) {

            //create list item
            let nodeItem = document.createElement("li") as HTMLLIElement;
            let nodeText = document.createElement("div");
            //let nodeIcon = document.createElement("img");

            let lockButton = document.createElement("button");
            let lockIcon = document.createElement("img");
            lockButton.classList.add("btn", "node-button", "lock-button");
            lockIcon.src = "/img/lock.svg";

            let nodeLockableComp = node.getComponent("NodeLockable") as AugmentedStoreAssembly.NodeLockableComponent;

            if (nodeLockableComp) {
                if (nodeLockableComp.isLocked) {
                    lockButton.classList.add("locked");
                }

                lockIcon.classList.add("node-icon", "lock-icon");

                nodeLockableComp.onChanged.on((comp: AugmentedStoreAssembly.NodeLockableComponent) => {
                    if (comp.isLocked) {
                        lockButton.classList.add("locked");
                    }
                    else {
                        lockButton.classList.remove("locked");
                    }
                })

                lockButton.addEventListener("click", function (event) {
                    Vertex.Globals.event.fire("hevolus:forceClearSelection");
                    nodeLockableComp.isLocked = !nodeLockableComp.isLocked;
                    nodeLockableComp.triggerOnChanged();
                });
            }


            let trashButton = document.createElement("button");
            let trashIcon = document.createElement("img");
            nodeItem.classList.add("scene-list", "list-item");
            nodeText.classList.add("scene-list-text");

            // nodeIcon.classList.add("list-img");
            // nodeIcon.src="/img/item.svg";
            trashButton.classList.add("btn", "btn-danger", "node-button");
            trashIcon.src = "/img/cross.svg";
            trashIcon.classList.add("node-icon");

            if (nodeLockableComp) {
                if (nodeLockableComp.isLocked) {
                    trashButton.classList.add("hidden");
                }

                nodeLockableComp.onChanged.on((comp: AugmentedStoreAssembly.NodeLockableComponent) => {
                    if (comp.isLocked) {
                        trashButton.classList.add("hidden");
                    }
                    else {
                        trashButton.classList.remove("hidden");
                    }
                });
            }

            trashButton.addEventListener("click", async function (event) {
                if (!nodeLockableComp?.isLocked) {
                    //if (node.getComponent("DirectionalLight")) {
                    //Ask for confirmation before deleting light
                    await Swal.fire({
                        title: 'Are you sure you want to delete this light?',
                        text: 'Deleting a light is permanent and cannot be undone',
                        showCancelButton: true,
                        showConfirmButton: true,
                        heightAuto: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            Vertex.Globals.event.fire("space:nodeDestroyed", (node));
                            Vertex.Globals.runtime.space.destroyNode(node);
                        }
                    });
                }
            });


            nodeItem.setAttribute("data-node-id", node.id);

            //set name
            let name = node.name;
            nodeText.innerHTML = name;
            this.gltfNameDictionary.set(node.id, name)

            //make selectable
            nodeItem.addEventListener("click", function (event) {
                var selectedRow = event.target as HTMLDivElement;
                var selectedId = selectedRow.getAttribute("data-node-id");
                for (let kvp of Vertex.Globals.runtime.space.nodes.entries()) {

                    let guid = kvp[0];
                    let node = kvp[1];

                    if (guid == selectedId) {
                        //window["selectedNode"] = node.id;
                        Vertex.Globals.event.fire("editor:selectNode", node)
                        // console.log(window["selectedNode"]);
                    }
                }
            });
            nodeItem.appendChild(lockButton);
            lockButton.appendChild(lockIcon);
            nodeItem.appendChild(nodeText);
            nodeItem.appendChild(trashButton);
            trashButton.appendChild(trashIcon);
            tree.appendChild(nodeItem);
        }
    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {

    }

    update(): void {

    }

    async getResourceName(id: string) {
        let url = `${RESOURCE_API_URI}${id}`;

        const nameResult = await fetch(url, {
            headers: {
                "Authorization": "Bearer " + Vertex.Globals.bearerToken
            }
        });

        if (nameResult.ok) {
            let json = await nameResult.json();
            return json.name;
        } else {
            console.log(nameResult.status + nameResult.statusText);
            console.log("did not get name for node: " + id);
        }
    }

    minimizePanel(target: HTMLElement) {

        let parent = target.closest(".card");
        let body = parent.querySelector(".card-body");
        let icon1 = target.querySelector(".mini-1");
        let icon3 = target.querySelector(".mini-3");



        if (target.classList.contains("max")) {
            target.classList.remove("max");
            target.classList.add("min");
            body.classList.remove("d-block");
            body.classList.add("d-none");
            icon1.classList.remove("squish-mini");
            parent.classList.remove("h-30vh");

            console.log(target.classList);
            console.log(body.classList);
        }

        else {
            target.classList.remove("min");
            target.classList.add("max");
            body.classList.remove("d-none");
            body.classList.add("d-block");
            icon1.classList.add("squish-mini");
            parent.classList.add("h-30vh");


            console.log(target.classList);
            console.log(body.classList);
        }
    }


}


export class LightHierarchyPanelComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new LightHierarchyPanelComponent();
    }
    constructor() {
        super("LightHierarchyPanel", new LightHierarchyPanelComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}



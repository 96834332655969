import Swal from "sweetalert2";
import { NotificationStatus, Utils } from "../../../utilities/utils";
// import { _ThinInstanceDataStorage } from "babylonjs";
import { cloneDeep } from "lodash";
import { CustomIntroSplashComponent, SplashElement } from "../NodeComponents/introsplashcomponent";
import { FileMediaType, ResourceUtils, VERTEXResource } from "../../../utilities/resource-utilities";
import { UploadUtils, UploadingAssetType } from "../../../utilities/upload-utilities";
import { ALLOWED_AUDIO_TYPES, ALLOWED_VIDEO_TYPES, MEDIA_CONVERSION_STATUS_FILENAME, SUPPORTED_AUDIO_FORMATS, SUPPORTED_INTROSPLASH_TYPES, SUPPORTED_VIDEO_FORMATS } from "../../../utilities/constants";
import { Status, ConversionUtils, FileConversionStatus } from "../../../utilities/conversion-utilities";

export class IntroSplashPanelComponent extends Vertex.NodeComponentModel.Component {
	splashElements: SplashElement[] = [];

	writeData(writer: Vertex.BinaryWriter): void { }
	readData(reader: Vertex.BinaryReader): void { }
}

export class IntroSplashPanelComponentView extends Vertex.NodeComponentModel.ComponentViewBase {
	constructor() {
		super();
	}

	containerPanel: HTMLDivElement;
	card: HTMLDivElement;
	splashPanel: HTMLDivElement;
	comp: IntroSplashPanelComponent;

	spaceLoadingListLabel: HTMLDivElement;
	spaceLoadingListEmpty: HTMLSpanElement;
	splashListView: HTMLUListElement;

	spaceLoadedListLabel: HTMLDivElement;
	spaceLoadedListEmpty: HTMLSpanElement;
	spaceLoadedListView: HTMLUListElement;

	introSplashComp: CustomIntroSplashComponent;
	dropzone: HTMLDivElement;
	addTypeButton: HTMLButtonElement;

	spaceResource: VERTEXResource;

	awaitedMediaConversionFiles: string[] = [];

	addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
		this.comp = component as IntroSplashPanelComponent;

		const space = Vertex.Globals.runtime.space as Vertex.Space;

		Utils.waitForCondition(_ => Array.from(space.nodes.values()).find(n => n.components.includes('IntroSplash')) != null)
			.then(async _ => {
				this.introSplashComp = Array.from(space.nodes.values()).find(n => n.components.includes('IntroSplash')).getComponent("IntroSplash") as CustomIntroSplashComponent;
				
				await this.introSplashComp.isReady;

				this.introSplashComp.onChanged.on(async () => {
					this.comp.splashElements = cloneDeep(this.introSplashComp.splashElements);

					await this.checkAndRenderSplashes();
				});

				this.comp.splashElements = cloneDeep(this.introSplashComp.splashElements);

				await this.checkAndRenderSplashes();
			});

		this.containerPanel = document.querySelector(".container-overlay-left");
		this.containerPanel.classList.remove("hidden");

		// Create Panel
		this.card = document.createElement("div");
		this.card.classList.add("card", "pointer-enable");
		this.card.id = "intro-splash-panel";

		let header = document.createElement("div");
		header.classList.add(
			"nav-link",
			"card-header-minimizable",
			"dark-text",
			"max"
		);

		let headerIcon = document.createElement("img");
		headerIcon.src = "/img/scene-icon-active.svg";
		headerIcon.classList.add("card-header-icon");

		let headerText = document.createElement("div");
		headerText.classList.add("card-header-text");
		headerText.innerText = "Intro Splash";

		let body = document.createElement("div");
		body.classList.add("card-body", "p-0");

		this.splashPanel = document.createElement("div");
		this.splashPanel.classList.add("introSplash-panel", "h-100");

		let leftSidebar = document.querySelector(".left-sidebar-grid") as HTMLDivElement;
		leftSidebar.classList.add("no-overflowY");

		this.dropzone = this.createDropZone();

		this.splashPanel.appendChild(this.dropzone);

		//List of Splashes
		this.spaceLoadingListLabel = document.createElement("div");
		this.spaceLoadingListLabel.id = "loading-elements-label";
		this.spaceLoadingListLabel.classList.add("skybox-text");
		this.spaceLoadingListLabel.style.paddingTop = "0.5rem";
		this.spaceLoadingListLabel.innerText = "Loop during Space loading:";

		this.splashPanel.appendChild(this.spaceLoadingListLabel);

		this.spaceLoadingListEmpty = document.createElement("span");
		this.spaceLoadingListEmpty.classList.add("empty-list-message");
		this.spaceLoadingListEmpty.innerText = "Empty list";

		this.splashPanel.appendChild(this.spaceLoadingListEmpty);

		this.splashListView = document.createElement("ul") as HTMLUListElement;
		this.splashListView.id = "space-loading-list";

		this.splashListView.classList.add(
			"skybox-list-group"
		);

		this.splashPanel.appendChild(this.splashListView);

		this.spaceLoadedListLabel = document.createElement("div");		
		this.spaceLoadedListLabel.id = "loaded-elements-label";
		this.spaceLoadedListLabel.classList.add("skybox-text");
		this.spaceLoadedListLabel.style.marginLeft = "0";
		this.spaceLoadedListLabel.style.paddingTop = "0.5rem";
		this.spaceLoadedListLabel.style.paddingBottom = "0.5rem";
		this.spaceLoadedListLabel.innerText = "Show one-time when the Space is loaded:";
		
		this.splashListView.appendChild(this.spaceLoadedListLabel);

		this.spaceLoadedListEmpty = document.createElement("span");
		this.spaceLoadedListEmpty.classList.add("empty-list-message");
		this.spaceLoadedListEmpty.innerText = "Empty list";

		this.splashPanel.appendChild(this.spaceLoadedListEmpty);

		this.spaceLoadedListView = document.createElement("ul") as HTMLUListElement;
		this.spaceLoadedListView.id = "space-loaded-list";

		this.spaceLoadedListView.classList.add(
			//"scrollable-list-group",
			"skybox-list-group"
		);

		leftSidebar.appendChild(this.card);
		this.card.appendChild(header);
		header.appendChild(headerIcon);
		header.appendChild(headerText);
		this.card.appendChild(body);
		body.appendChild(this.splashPanel);

		// Sidebar
		Utils.setupSidebarButton("IntroSplashPanel", "intro-splash-panel");
	}

	private async checkAndRenderSplashes(){
		this.spaceResource = null;

		this.spaceResource = await ResourceUtils.getResourceData(Vertex.Globals.spaceId);

		await this.checkAndRemoveDeletedSplashes();

		this.renderSplashes(this.comp.splashElements);
	}

	private async checkAndRemoveDeletedSplashes(){

		await Utils.waitForCondition(_ => this.introSplashComp != null) ;
		
		let needTrigger = false;

		this.introSplashComp.splashElements.forEach(element => {
			if (!this.spaceResource.resourceKeys.includes(element.name)) {
				needTrigger = true;
				this.introSplashComp.splashElements.splice(this.introSplashComp.splashElements.indexOf(element), 1);
			}
		});
		
		if (needTrigger) {
			await this.introSplashComp.postComponentJsonToResource();

			this.introSplashComp.triggerOnChanged();

			return;
		}
	}

	private async renderSplashes(splashElements: SplashElement[]) {
        let self = this; 

		// if(!this.card.classList.contains("hidden")){
		// 	Swal.fire({
		// 		title: DEFAULT_SWAL_LOADING_MESSAGE,
		// 		allowEscapeKey: false,
		// 		allowOutsideClick: false,
		// 		showConfirmButton: false,
		// 		heightAuto: true,
		// 	});

		// 	Swal.showLoading();
		// }


		const mediaConversionStatusResponse = await ResourceUtils.getAssetFromResource(Vertex.Globals.spaceId, MEDIA_CONVERSION_STATUS_FILENAME);
        let mediaConversionStatus: FileConversionStatus[] = [];

        if(mediaConversionStatusResponse.ok){
            mediaConversionStatus = await mediaConversionStatusResponse.json();
        }

        const res = await ResourceUtils.getResourceData(Vertex.Globals.spaceId);

        if(res == null || res.resourceKeys == null){
            console.warn("could not fetch resource or resource keys: ", res);
            
            return;
        }

		const splashes = splashElements.filter(s => s.isLoop).concat(splashElements.filter(s => !s.isLoop));
        
		Array.from(this.splashListView.querySelectorAll<HTMLDivElement>(".draggable-splash")).forEach(element => {
			const isInList = splashes.find(s => {
				const splashType = ResourceUtils.getMediaType(Utils.getFileExtension(s.name));
				return Utils.getFileBaseName(s.name) == element.dataset.fileName && splashType == element.dataset.mediaType;
			}) != null;

			if(!isInList){
				this.splashListView.removeChild(element);
			}
		});


		for(let i = 0; i < splashes.length; i++){
			const splash = splashes[i];
			const splashIndex = this.comp.splashElements.indexOf(splash);

			const fileName = Utils.getFileBaseName(splash.name);
            const extension = Utils.getFileExtension(splash.name);
            const mediaType = ResourceUtils.getMediaType(extension);
            const needMediaConversion = mediaType === FileMediaType.Audio || mediaType === FileMediaType.Video;

			let listItem = this.splashListView.querySelector(`[data-file-name="${fileName}"][data-media-type="${mediaType}"]`) as HTMLDivElement;

            if(listItem == null){
				listItem = document.createElement("div");
				listItem.id = `${splashIndex}-splash`;
				listItem.classList.add("scene-list", "list-item", "skybox-holder", "draggable-splash");
				listItem.dataset.fileName = fileName;
                listItem.dataset.mediaType = mediaType;

				let dragImg = document.createElement("img");
				dragImg.classList.add("draggable");
				dragImg.id = `${splashIndex}-drag-img`;
				dragImg.src = "/img/drag-icon.svg";
				dragImg.draggable = false;
				dragImg.style.zIndex = "1";
	
				let typeImg = document.createElement("img");
				typeImg.classList.add("type-img");
				typeImg.id = `${splashIndex}-type-img`;
				
				if(mediaType === FileMediaType.Image){
                    typeImg.src = '/img/image-icon.svg';
                }
                else if(mediaType === FileMediaType.Video){
                    typeImg.src = '/img/video-icon.svg';
                }

				let labelElement = document.createElement("div");
				labelElement.id = `${splashIndex}-splash-label`;
				labelElement.classList.add("scene-list-text");
				labelElement.innerText = `${fileName}`;
				
				//Add the DELETE buttons to each elem in the scrollable list view
				let deleteButton = document.createElement("button");
				deleteButton.classList.add(
					"btn",
					"node-button",
					"ml-1",
					"list-item-bin-delete-button"
				);
				
				let deleteIcon = document.createElement("img");

				deleteIcon.src = "/img/trash.svg";
				deleteButton.appendChild(deleteIcon);
				Utils.injectSvg(deleteIcon);
				
				const swalHtml = document.createElement('p') as HTMLParagraphElement;
                swalHtml.style.overflow = "auto";
                swalHtml.textContent = fileName;

				deleteButton.addEventListener("click", async (event) => {
					event.stopPropagation();
		
					await Swal.fire({
						title: "Are you sure you want to delete: ",
                        html : swalHtml,
						showCancelButton: true,
						showConfirmButton: true,
						confirmButtonText: 'Delete',
						allowOutsideClick: false,
						allowEscapeKey: false,
						heightAuto: false,
						confirmButtonColor: "red",
					}).then(async (swalResponse) => {
						if (swalResponse.isConfirmed) {
							Swal.showLoading();

							listItem.remove();
							this.comp.splashElements.splice(splashIndex, 1);
							this.introSplashComp.splashElements = cloneDeep(this.comp.splashElements);
			
							await this.introSplashComp.postComponentJsonToResource(true);
			
							if(Swal.isVisible()){
								Swal.close();
							}

							this.introSplashComp.triggerOnChanged();
						}
					});
				});

				const overlay = document.createElement("div");
                overlay.classList.add("draggable-media-list-item-overlay");

                const spinner = document.createElement("img");
                spinner.src = "/img/converting-spinner.svg";
                spinner.classList.add("draggable-media-list-item-overlay-spinner");
                overlay.appendChild(spinner);

                const spinnerText = document.createElement("span");
                spinnerText.innerText = "Converting ...";

                overlay.appendChild(spinnerText);

				listItem.appendChild(dragImg);
				listItem.appendChild(typeImg);
				listItem.appendChild(labelElement);
				listItem.appendChild(deleteButton);
				listItem.appendChild(overlay);

				//dragging logic -------------------------------------------------
				let placeholder;
				let isDraggingStarted = false;

				// The current position of mouse relative to the dragging element
				let x = 0;
				let y = 0;

				// Swap two nodes
				const swap = function (nodeA, nodeB) {
					const parentA = nodeA.parentNode;
					const siblingA = nodeA.nextSibling === nodeB ? nodeA : nodeA.nextSibling;

					// Move `nodeA` to before the `nodeB`
					nodeB.parentNode.insertBefore(nodeA, nodeB);

					// Move `nodeB` to before the sibling of `nodeA`
					parentA.insertBefore(nodeB, siblingA);
				};

				// Check if `nodeA` is above `nodeB`
				const isAbove = function (nodeA, nodeB) {
					// Get the bounding rectangle of nodes
					const rectA = nodeA.getBoundingClientRect();
					const rectB = nodeB.getBoundingClientRect();

					return rectA.top + rectA.height / 2 < rectB.top + rectB.height / 2;
				};

				const mouseDownHandler = function (e) {
					if(!e.target.id.includes("drag-img")){
						return;
					}

					listItem = e.target.parentElement;

					// Calculate the mouse position
					const rect = listItem.getBoundingClientRect();
					x = e.pageX - rect.left;
					y = e.pageY - rect.top;

					// Attach the listeners to `document`
					document.addEventListener('mousemove', mouseMoveHandler);
					document.addEventListener('mouseup', mouseUpHandler);
				};

				const mouseMoveHandler = function (e) {
					const draggingRect = listItem.getBoundingClientRect();

					if (!isDraggingStarted) {
						isDraggingStarted = true;

						// Let the placeholder take the height of dragging element
						// So the next element won't move up
						placeholder = document.createElement('div');
						placeholder.classList.add('placeholder');
						listItem.parentNode.insertBefore(placeholder, listItem.nextSibling);
						placeholder.style.height = `${draggingRect.height}px`;
					}

					// Set position for dragging element
					listItem.style.position = 'fixed';
					listItem.style.top = `${e.pageY - y}px`;
					listItem.style.left = `${e.pageX - x}px`;

					// The current order
					// prevEle
					// draggingEle
					// placeholder
					// nextEle
					const prevEle = listItem.previousElementSibling;
					const nextEle = placeholder.nextElementSibling;

					// The dragging element is above the previous element
					// User moves the dragging element to the top
					if (prevEle && isAbove(listItem, prevEle)) {
						// The current order    -> The new order
						// prevEle              -> placeholder
						// draggingEle          -> draggingEle
						// placeholder          -> prevEle
						swap(placeholder, listItem);
						swap(placeholder, prevEle);


						if(self.spaceLoadedListLabel.nextElementSibling == null){
							self.spaceLoadedListEmpty.style.removeProperty("display");
						}
						else{
							self.spaceLoadedListEmpty.style.display = "none";
						}

						if(self.spaceLoadedListLabel.previousElementSibling == null){
							self.spaceLoadingListEmpty.style.removeProperty("display");
						}
						else{
							self.spaceLoadingListEmpty.style.display = "none";
						}

						return;
					}

					// The dragging element is below the next element
					// User moves the dragging element to the bottom
					if (nextEle && isAbove(nextEle, listItem)) {
						// The current order    -> The new order
						// draggingEle          -> nextEle
						// placeholder          -> placeholder
						// nextEle              -> draggingEle
						swap(nextEle, placeholder);
						swap(nextEle, listItem);

						if(self.spaceLoadedListLabel.nextElementSibling == null){
							self.spaceLoadedListEmpty.style.removeProperty("display");
						}
						else{
							self.spaceLoadedListEmpty.style.display = "none";
						}

						if(self.spaceLoadedListLabel.previousElementSibling == null){
							self.spaceLoadingListEmpty.style.removeProperty("display");
						}
						else{
							self.spaceLoadingListEmpty.style.display = "none";
						}
					}
				};

				const mouseUpHandler = async function () {
					// Remove the placeholder
					placeholder && placeholder.parentNode.removeChild(placeholder);

					listItem.style.removeProperty('top');
					listItem.style.removeProperty('left');
					listItem.style.removeProperty('position');

					x = null;
					y = null;
					listItem = null;
					isDraggingStarted = false;

					// Remove the handlers of `mousemove` and `mouseup`
					document.removeEventListener('mousemove', mouseMoveHandler);
					document.removeEventListener('mouseup', mouseUpHandler);

					//get current splash index (before moving)
					const splashIndex = self.introSplashComp.splashElements.findIndex(s => s.name == splash.name);

					//get final index of moved splash
					const listItemArray: HTMLDivElement[] = Array.from(self.splashListView.children)  as HTMLDivElement[];
					const listItemIndex = listItemArray.findIndex(i => i.dataset.fileName === fileName && i.dataset.mediaType === mediaType);

					//set isLoop property
					const listSeparatorIndex = Array.from(self.splashListView.children).indexOf(self.spaceLoadedListLabel);
					self.introSplashComp.splashElements[splashIndex].isLoop = listItemIndex < listSeparatorIndex;
					
					if(splashIndex !== -1 && self.introSplashComp.splashElements.length > splashIndex &&
						listItemIndex !== -1 && self.introSplashComp.splashElements.length >= listItemIndex){ // >= listItemIndex because we have the separator there

						//reorder moved splash in the component
						const element = self.introSplashComp.splashElements.splice(splashIndex, 1)[0];
						self.introSplashComp.splashElements.splice(listItemIndex, 0, element);
						
						await self.introSplashComp.postComponentJsonToResource(true);
					}


					self.introSplashComp.triggerOnChanged();
				};
				//------------------------------------------------------------------

				listItem.addEventListener("mousedown", mouseDownHandler);

				this.splashListView.insertBefore(listItem, this.splashListView.children[i]);
			}

			const overlay = listItem.querySelector(".draggable-media-list-item-overlay") as HTMLDivElement;

            if(needMediaConversion){
                if(mediaConversionStatus?.length){
                    const conversionStatus = mediaConversionStatus.find(c => {
                        const name = Utils.getFileBaseName(c.fileName);
                        const ext = Utils.getFileExtension(c.fileName);

                        if(fileName === name){
                            if(mediaType === FileMediaType.Audio){
                                return SUPPORTED_AUDIO_FORMATS.includes(ext);
                            }
                            
                            if(mediaType === FileMediaType.Video){
                                return SUPPORTED_VIDEO_FORMATS.includes(ext);
                            }                            
                        }
                    });

                    if(conversionStatus){
                        if(conversionStatus.status === Status.Pending){
                            overlay.hidden = false;

							if(!this.awaitedMediaConversionFiles.includes(conversionStatus.fileName)){
                                this.awaitedMediaConversionFiles.push(conversionStatus.fileName);

								ConversionUtils.waitMediaConversionCompletion([conversionStatus.fileName], Vertex.Globals.spaceId).then(async result => {
									let mediaConversionStatus = result[0];
				
									if(mediaConversionStatus){
										let res: VERTEXResource = null;
						
										//get the resource to check converted files in resourceKeys. here we use wait just to be more confident in getting the resource
										await Utils.waitForConditionAsync(async _ => {
											res = await ResourceUtils.getResourceAsync(Vertex.Globals.spaceId, false);
											
											return res != null;
										}, 500, 5000);

										let hasConvertedFiles = true;
										
										if(mediaType === FileMediaType.Audio){
											ALLOWED_AUDIO_TYPES.forEach(t => {
												hasConvertedFiles = hasConvertedFiles && res.resourceKeys.includes(`${fileName}.${t}`);
											});
										}
										else if(mediaType === FileMediaType.Video){
											ALLOWED_VIDEO_TYPES.forEach(t => {
												hasConvertedFiles = hasConvertedFiles && res.resourceKeys.includes(`${fileName}.${t}`);
											});
										}
					
										if(mediaConversionStatus.status === Status.Completed && hasConvertedFiles){                              
											Utils.notify(`${mediaConversionStatus.fileName} conversion completed`, NotificationStatus.Success);
				
											overlay.hidden = true;											
										}
										else {
											Swal.fire({
												icon: 'error',
												title: "Media Conversion",
												html: `Media conversion failed for:<br><b>${mediaConversionStatus.fileName}</b><br><br>It will be deleted`,
												showConfirmButton: true,
												showCancelButton: false,
												showCloseButton: false,
												showDenyButton: false,
												allowEscapeKey: false,
												heightAuto: false,
												width: "auto"
											}).then(async swal => {
												if(swal.isConfirmed){
													Swal.showLoading();

													const deleteResponses = await ResourceUtils.deleteMediaFromResource(Vertex.Globals.spaceId, splash.name);

													if(deleteResponses.every(r => r.ok)){
														Swal.update({
															icon: "success",
															title: `The splash<br>${fileName}<br>has been deleted`,
															showConfirmButton: true,
															showCancelButton: false,
															showCloseButton: false,
															showDenyButton: false,
															allowEscapeKey: false
														});
													}
													else{
														Swal.update({
															icon: "error",
															title: `The splash<br>${fileName}<br>has <b>NOT</b> been deleted, try again later.`,
															showConfirmButton: true,
															showCancelButton: false,
															showCloseButton: false,
															showDenyButton: false,
															allowEscapeKey: false
														});
													}

													const index = this.comp.splashElements.indexOf(splash);
													this.introSplashComp.splashElements.splice(index, 1);

													this.introSplashComp.triggerOnChanged();

													if(Swal.isVisible()){
														Swal.close();
													}
												}
											});
										}

										const mediaConversionFileIndex = this.awaitedMediaConversionFiles.indexOf(conversionStatus.fileName);

										if(mediaConversionFileIndex !== -1){
											this.awaitedMediaConversionFiles.splice(this.awaitedMediaConversionFiles.indexOf(conversionStatus.fileName), 1);
										}										
									}
								});
							}
                            
                        }
                        //it's not pending, so let's check if it is completed and files are ok
                        else if(conversionStatus.status === Status.Completed){
                            let hasConvertedFiles = true;
                            
                            if(mediaType === FileMediaType.Audio){
                                ALLOWED_AUDIO_TYPES.forEach(t => {
                                    hasConvertedFiles = hasConvertedFiles && res.resourceKeys.includes(`${fileName}.${t}`);
                                });
                            }
                            else if(mediaType === FileMediaType.Video){
                                ALLOWED_VIDEO_TYPES.forEach(t => {
                                    hasConvertedFiles = hasConvertedFiles && res.resourceKeys.includes(`${fileName}.${t}`);
                                });
                            }

                            if(hasConvertedFiles){
                                overlay.hidden = true;
                            }
                            else{
								Swal.showLoading();

								this.splashListView.removeChild(listItem);
								this.comp.splashElements.splice(splashIndex, 1);
								this.introSplashComp.splashElements = cloneDeep(this.comp.splashElements);
				
								await this.introSplashComp.postComponentJsonToResource(true);

								if(Swal.isVisible()){
									Swal.close();
								}

								this.introSplashComp.triggerOnChanged();
                            }
                        }
                        else{
							Swal.showLoading();

							this.splashListView.removeChild(listItem);
							this.comp.splashElements.splice(splashIndex, 1);
							this.introSplashComp.splashElements = cloneDeep(this.comp.splashElements);
			
							await this.introSplashComp.postComponentJsonToResource(true);

							if(Swal.isVisible()){
								Swal.close();
							}

							this.introSplashComp.triggerOnChanged();
                        }
                    }
                }
				else{
					overlay.hidden = true;
				}
            }
            else{
                overlay.hidden = true;
            }
		}

		//Move the label
		let listSeparatorNewIndex = splashes.length;
		const firstNonLoopIndex = splashes.findIndex(s => !s.isLoop);

		if(firstNonLoopIndex !== -1){
			listSeparatorNewIndex = firstNonLoopIndex;
		}

		this.splashListView.insertBefore(this.spaceLoadedListLabel, this.splashListView.children[listSeparatorNewIndex]);

		const loopAmount = splashes.filter(s => s.isLoop).length;

		if (loopAmount === 0) {
			this.spaceLoadingListEmpty.style.removeProperty("display");
		}
		else {
			this.spaceLoadingListEmpty.style.display = "none";
		}

		if (splashes.length - loopAmount === 0) {
			this.spaceLoadedListEmpty.style.removeProperty("display");
		}
		else {
			this.spaceLoadedListEmpty.style.display = "none";
		}
		
		// if(!this.card.classList.contains("hidden") && Swal.isVisible()){
        //     Swal.close();
		// }
	}

	removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) { }

	//#region DropZone
	createDropZone() {
		//dropzone
		let dropzone = document.createElement("div") as HTMLDivElement;
		dropzone.innerText = "Drag and drop or click to upload files.";
		dropzone.className = "dropzone-wide";
		dropzone.style.margin = "28px 10px";
        dropzone.style.padding = "20px 2px";
		dropzone.addEventListener("drop", this.handleFilesDropped);
		dropzone.addEventListener("dragenter", this.handleDragEnterEvent(dropzone));
		dropzone.addEventListener("dragleave", this.handleDragLeaveEvent(dropzone));
		dropzone.addEventListener("dragover", (event) => event.preventDefault());
		dropzone.addEventListener("click", () => uploader.click());

		//<input type="file" multiple />
		let uploader = document.createElement("input");
		uploader.setAttribute("multiple", "multiple");
		uploader.setAttribute("type", "file");
		uploader.setAttribute(
			"accept",
			"." + SUPPORTED_INTROSPLASH_TYPES.join(",.")
		);
		uploader.setAttribute("style", "display: none");
		uploader.style.display = "none";
		uploader.addEventListener("change", async () => {
			await this.uploadSplashFiles(Array.from(uploader.files));

			uploader.value = "";

			this.introSplashComp.triggerOnChanged();
		});

		dropzone.appendChild(uploader);

		return dropzone;
	}

	private handleDragEnterEvent(
		dropzone: HTMLDivElement
	): (this: HTMLDivElement, ev: DragEvent) => any {
		return (event) => {
			event.preventDefault();
			dropzone.className = "dropzone-wide-drag";
		};
	}

	private handleDragLeaveEvent(
		dropzone: HTMLDivElement
	): (this: HTMLDivElement, ev: DragEvent) => any {
		return (event) => {
			event.preventDefault();
			dropzone.className = "dropzone-wide";
		};
	}

	handleFilesDropped = async (event: DragEvent) => {
		event.preventDefault();
		const droppedFiles: File[] = this.getFilesDropped(event) as File[];

		await this.uploadSplashFiles(droppedFiles);

		this.introSplashComp.triggerOnChanged();
	}

	getFilesDropped = (event: DragEvent) => {
		let files: File[] | DataTransferItem[] = [];

		if (event.dataTransfer) {
			const dataTransfer = event.dataTransfer;
			if (dataTransfer.files && dataTransfer.files.length > 0) {
				files = [...dataTransfer.files];
			} else if (dataTransfer.items && dataTransfer.items.length > 0) {
				files = [...dataTransfer.items];
			} else {
				throw new Error("No Data Transfer items or files found.");
			}
		}

		return files;
	}

	private async uploadSplashFiles(droppedFiles: File[]) {
		const uploadResult = await UploadUtils.uploadFiles(droppedFiles, Vertex.Globals.spaceId, UploadingAssetType.SpaceSplash);

        if(uploadResult.needConversion){
            let splashConversionStatus = await ConversionUtils.convertFiles(uploadResult.successfulUploads);
		}        

		if (uploadResult?.successfulUploads?.length) {
			for (let index = 0; index < uploadResult.successfulUploads.length; index++) {
				let element = uploadResult.successfulUploads[index];

				if(element){
					if(this.comp.splashElements.findIndex(e => e.name === element) === -1){
						this.comp.splashElements.push({name: element, isLoop: true} as SplashElement);
					}
				}
			}

			this.introSplashComp.splashElements = cloneDeep(this.comp.splashElements);

			await this.introSplashComp.postComponentJsonToResource(true);
		}
	}
}

export class IntroSplashPanelComponentSystem extends Vertex.NodeComponentModel
	.ComponentSystemBase {
	public create(): Vertex.NodeComponentModel.Component {
		return new IntroSplashPanelComponent();
	}
	constructor() {
		super(
			"IntroSplashPanel",
			new IntroSplashPanelComponentView(),
			new Vertex.NodeComponentModel.EmptyComponentController()
		);
	}
}

import Swal from "sweetalert2";
import * as semaphore from "semaphore";
import { AssetType, ChangeMaterialModel } from "./change-material-model-utilities";
import { IQRItem } from "../components/catalog/qr/qr-interfaces";
import { UploadUtils, UploadingAssetType } from "./upload-utilities";
import { HevolusResourceType, OrderMode, ResourceUtils, TagMode, VERTEXResource } from "./resource-utilities";
import { LicenseDictionary, LicenseValidity } from "./license-utilities";
import { SET_THUMB_ICON_IMG_URI, ALLOWED_IMAGE_TYPES, MODEL_EDITOR_URL, SPACE_EDITOR_URL, DECK_EDITOR_URL, SYSTEM_TAGS, SYSTEM_TAG_PREFIXES, CHANGE_MATERIAL_MODEL_FILENAME, CONTENT_IMAGE_TAG, ALLOWED_AUDIO_TYPES, CONTENT_AUDIO_TAG, ALLOWED_VIDEO_TYPES, CONTENT_VIDEO_TAG, ALLOWED_DOC_TYPES, ALLOWED_PDF_TYPES, APP_AUGMENTEDSTORE_TAG, APP_HEVOCOLLABORATION_TAG, APP_HOLOMUSEUM_TAG, APP_HOLOPROTOTYPE_TAG, APP_REMOTESELLING_TAG, AUGMENTEDSTORE_CONTENTCREATOR_LICENSE, CONTENT_DOC_TAG, CONTENT_PDF_TAG, HEVOCOLLABORATION_CONTENTCREATOR_LICENSE, HEVOCOLLABORATION_HOST_LICENSE, HOLOMUSEUM_CONTENTCREATOR_LICENSE, HOLOMUSEUM_HOST_LICENSE, HOLOPROTOTYPE_CONTENTCREATOR_LICENSE, HOLOPROTOTYPE_HOST_LICENSE, REMOTESELLING_CONTENTCREATOR_LICENSE, REMOTESELLING_HOST_LICENSE, RESOURCE_THUMB_FILENAME, ADDITIONAL_COMPONENTS_UI_PROPERTIES, COMPONENTS_UI_PROPERTIES, HIDDEN_RESOURCE_TAG, RESOURCE_API_URI } from "./constants";
import SVGInject from '@iconfu/svg-inject';
import { find } from "lodash";
import * as DOMPurify from 'dompurify';


export enum AppName {
    ResourceExplorer = "resource-explorer",
    ModelEditor = "model-editor",
    BaseEditor = "base-editor",
    SpaceEditor = "space-editor",
    None = "none"
}

export interface SpawnedQRAnchor {
    anchor: IQRItem,
    mesh: BABYLON.Mesh,
}

export enum NotificationStatus {
    Info = "info",
    Success = "success",
    Warning = "warning",
    Error = "error",
    Question = "question"
}

export enum DeviceType {
    Desktop = 0,
    Android,
    iPad,
    iPhone,
}

export enum SpacePrivacy {
    None = 0,
    Offline = 1 << 0,
    Private = 1 << 1,
    Public = 1 << 2,
    PublicLink = 1 << 3
}

export interface HoloPrototypeBaseMetadata {
    models: string[];
    anchors: string[];
}

export const loadTemplate = <T extends HTMLElement>(selector: string) => {
    let template = document.querySelector(selector) as HTMLTemplateElement;
    let contents = template.content.cloneNode(true) as DocumentFragment;
    return contents.firstElementChild as T;
}

export class Utils {
    /**
     * Returns the sanitized version of the provided string
     * @param input the string to sanitize
     * @param config the config to use for sanitization (default no HTML Tags and delete content if not safe)
     * @returns sanitized string
     */
    public static sanitizeString(input: string, config = null): string {
        config = config ?? { ALLOWED_TAGS: [], KEEP_CONTENT: false };
        
        return DOMPurify.sanitize(input, config);
    }

    /**
     * Return the MIME type of a file based on its extension
     * @param url complete url of the file
     * @returns the MIME type of the file
     */
    public static getMimeTypeByUrl(url: string): string {
        const extension = url.substring(url.lastIndexOf('.') + 1).toLocaleLowerCase();

        switch (extension) {
            case "aac":
                return "audio/aac";
            case "bmp":
                return "image/bmp";
            case "avi":
                return "video/x-msvideo";
            case "jpeg":
            case "jpg":
                return "image/jpeg";
            case "mp3":
                return "audio/mpeg";
            case "mp4":
                return "video/mp4";
            case "mpeg":
                return "video/mpeg";
            case "ogv":
                return "video/ogg";
            case "oga":
            case "ogg":
                return "audio/mpeg";
            case "png":
                return "image/png";
            case "pdf":
                return "application/pdf";
            case "ppt":
                return "application/vnd.ms-powerpoint";
            case "pptx":
                return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
            case "txt":
                return "text/plain";
            case "wav":
                return "audio/wav";
            case "weba":
                return "audio/webm";
            case "webm":
                return "video/webm";
            case "webp":
                return "image/webp";
            default:
                return "";
        }
    }

    static checkComponentsCompatibility(component: string, node: Vertex.NodeComponentModel.VertexNode, incompatibleComponents: string[]): boolean {
        let incompatibleComp = node.components.filter(comp => incompatibleComponents.includes(comp)).map(compName => Utils.getComponentUIName(compName));
        
        if (incompatibleComp.length > 0) {
            Swal.fire({
                icon: 'warning',
                title: `Invalid component selection!`,
                html: `If you wish to use <b>${Utils.getComponentUIName(component)}</b> please remove: <b><br> ${incompatibleComp.join("<br>")}</b>`,
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: true,
                heightAuto: false
            });

            return false;
        }

        return true;
    }


    static getAppName(): AppName {
        const pathname = window.location.pathname.replace(new RegExp("/", 'g'), "");

        return find(Object.values(AppName), a => a === pathname) ?? AppName.None;
    }

    static closeIconPickerModalOnOutsideClick(event) {
        const target = event.target as HTMLElement;
        if (target.closest('#icon-picker') == null) {
            const modal = document.querySelector("#icon-picker");
            if (modal) {
                modal.remove();
            }
            window.removeEventListener('click', Utils.closeIconPickerModalOnOutsideClick);
        }
    }

    static async handleIconPickerMenu(event: MouseEvent, node: { name, icon?}, listThumbnail: HTMLImageElement, assetType: AssetType, noIcon?: string) {
        const closeIconPickerModal = () => {
            const modal = document.querySelector("#icon-picker");

            if (modal) {
                modal.remove();
            }

            window.removeEventListener('click', Utils.closeIconPickerModalOnOutsideClick);
        }

        var x = event.pageX;
        var y = event.pageY;

        closeIconPickerModal();

        window.removeEventListener('click', Utils.closeIconPickerModalOnOutsideClick);
        window.addEventListener('click', Utils.closeIconPickerModalOnOutsideClick);
        
        var xPos = windowWidth - x;
        var yPos = y;
        var windowWidth = window.innerWidth;
        var windowHeight = window.innerHeight;
        var xPos = x;
        var xPosRight = windowWidth - x;
        var xPosLeft = xPos;
        var yPos = y;
        var yPosBottom = windowHeight - y;
        var yPosTop = yPos;
        var windowWidthHalf = windowWidth / 2;
        var windowHeightHalf = windowHeight / 2

        const contextMenu = document.createElement("div");
        contextMenu.style.userSelect = "none";
        contextMenu.id = "icon-picker";

        if (xPos >= windowWidthHalf) {
            contextMenu.style.right = xPosRight + "px";
        }

        if (xPos < windowWidthHalf) {
            contextMenu.style.left = xPosLeft + "px";
        }

        if (yPos >= windowHeightHalf) {
            contextMenu.style.bottom = yPosBottom + "px";
        }

        if (yPos < windowHeightHalf) {
            contextMenu.style.top = yPosTop + "px";
        }

        let containerHeader = document.createElement("div");
        containerHeader.classList.add("list-texture-header");

        let containerTitle = document.createElement("h4");
        containerTitle.classList.add("list-texture-header-title");
        containerTitle.innerText = `${node.name} icon`;

        let containerClose = document.createElement("div");
        containerClose.classList.add("texture-close-wrapper");
        containerClose.addEventListener('click', () => {
            contextMenu.remove();
        });

        let containerCloseIcon = document.createElement("img");
        containerCloseIcon.classList.add("texture-close-icon");
        containerCloseIcon.src = "/img/cross.svg";

        let iconsListGroup = document.createElement("div");
        iconsListGroup.classList.add("texture-list-group");

        document.body.insertBefore(contextMenu, document.body.firstChild);
        contextMenu.appendChild(containerHeader);
        contextMenu.appendChild(iconsListGroup);
        containerHeader.appendChild(containerTitle);
        containerHeader.appendChild(containerClose);
        containerClose.appendChild(containerCloseIcon);

        // insert a "none" option
        {
            //setup items
            let listItem = document.createElement("div");
            listItem.classList.add("list-texture-item", "list-group-item-dark");
            listItem.style.backgroundColor = "rgba(0,0,0,0.05)";

            let listTitle = document.createElement("h4");
            listTitle.classList.add("list-texture-title", "font-italic");
            listTitle.innerText = "(New Icon)";

            const fileExplorer = document.createElement("input");
            fileExplorer.type = "file";
            fileExplorer.classList.add("d-none");
            fileExplorer.addEventListener("change", async (e) => {
                const input = e.target as HTMLInputElement;

                await Utils.changeAssetIcon(assetType, node.name, input.files[0].name);
                await UploadUtils.uploadFiles([...input.files], null, UploadingAssetType.Generic);

                closeIconPickerModal();

                node.icon = input.files[0].name;
                let thumbSrc = await ResourceUtils.getAssetBlobUrl(Vertex.Globals.spaceId, node.icon);

                if (!thumbSrc) {
                    thumbSrc = SET_THUMB_ICON_IMG_URI;
                }

                listThumbnail.src = thumbSrc;
            });

            contextMenu.appendChild(fileExplorer);

            let listUseButton = document.createElement("button");
            listUseButton.classList.add("btn", "btn-secondary", "float-right", "btn-sm", "texture-button");
            listUseButton.innerText = ". . .";
            listUseButton.addEventListener('click', async (event) => {
                fileExplorer.click();
            });

            //insert items
            iconsListGroup.appendChild(listItem);
            listItem.appendChild(listTitle);
            listItem.appendChild(listUseButton);
        }

        {
            //setup items
            let listItem = document.createElement("div");
            listItem.classList.add("list-texture-item", "list-group-item-dark");
            listItem.style.backgroundColor = "rgba(0,0,0,0.05)";

            let listTitle = document.createElement("h4");
            listTitle.classList.add("list-texture-title", "font-italic");
            listTitle.innerText = "(No Icon)";

            let listUseButton = document.createElement("button");
            listUseButton.classList.add("btn", "btn-secondary", "float-right", "btn-sm", "texture-button");
            listUseButton.innerText = "Use";
            listUseButton.addEventListener('click', async (event) => {
                await Utils.changeAssetIcon(assetType, node.name);

                let useButtons = contextMenu.querySelectorAll(".texture-button");
                node.icon = null;
                for (let button of useButtons) {
                    button.classList.remove("disable");
                }
                listThumbnail.src = noIcon ?? SET_THUMB_ICON_IMG_URI;
                closeIconPickerModal();
            });

            //insert items
            iconsListGroup.appendChild(listItem);
            listItem.appendChild(listTitle);
            listItem.appendChild(listUseButton);
        }

        let resourceData = await ResourceUtils.getResourceData(Vertex.Globals.spaceId);

        for (let i = 0; i < resourceData.resourceKeys.length; i++) {
            const fileExtensionRegex = new RegExp("[0-9a-zA-Z]+$");
            const fileExtensionResults = fileExtensionRegex.exec(resourceData.resourceKeys[i]);

            if (ALLOWED_IMAGE_TYPES.includes(fileExtensionResults[0])) {
                let iconName = resourceData.resourceKeys[i];

                let listItem = document.createElement("div");
                listItem.classList.add("list-texture-item", "list-group-item-dark");

                let listTitle = document.createElement("h4");
                listTitle.classList.add("list-texture-title");
                listTitle.innerText = iconName;

                let listUseButton = document.createElement("button");
                listUseButton.classList.add("btn", "btn-secondary", "float-right", "btn-sm", "texture-button");
                listUseButton.innerText = "Use";

                if (node.icon === iconName) {
                    listUseButton.classList.add("disable");
                }

                listUseButton.addEventListener('click', async (event) => {
                    await Utils.changeAssetIcon(assetType, node.name, iconName);
                    node.icon = iconName;
                    let thumbSrc = await ResourceUtils.getAssetBlobUrl(Vertex.Globals.spaceId, iconName);

                    if (!thumbSrc) {
                        thumbSrc = SET_THUMB_ICON_IMG_URI;
                    }

                    listThumbnail.src = thumbSrc;

                    closeIconPickerModal();
                });

                iconsListGroup.appendChild(listItem);
                listItem.appendChild(listTitle);
                listItem.appendChild(listUseButton);
            }
        }
    }

    static notify(message: string, status: NotificationStatus = NotificationStatus.Success) {
        const Toast = Swal.mixin({
            toast: true,
            //background: 'var(--main-primary-color)',
            position: "bottom-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
                Swal.getTitle().style.wordBreak = "break-all";
            }
        })

        Toast.fire({
            icon: status,
            titleText: message
        })
    }


    static getURLParams(s: string): URLSearchParams {
        return new URLSearchParams(s);
    }

    /**
     * Get the value of a given param in a URL
     * @param url the URL to search
     * @param param the param you look for
     * @returns the value of the param
     */
    static getUrlParam(url: string, param: string): string{
        const params = Utils.getURLParams(url);
        return params.get(param);
    }

    /**
     * Check if a date/time is "expired", so if it is less than current date/time
     * @param stringDate the date/time to check
     * @returns true if it's expired, false otherwise
     */
    static isExpired(stringDate){
        const date = new Date(stringDate);
        const now = new Date();

        return date < now
    }

    static GetSpaceIdFromURLGuid() {
        var regex = /spaceId=(.*)/;
        var result = regex.exec(window.location.href);
        if (result && result.length > 0) {
            return result[1];
        }
        return null;
    }

    /**
     * Utility to wait for a condition to be matched and then do some operations. It is also awaitable.
     * E.g. Utils.waitForCondition(_ => condition === false).then(_ => { ... } )
     * E.g. await Utils.waitForCondition(_ => obj != null);
     * @param conditionFunction 
     * @param ms 
     * @returns 
     */
    static waitForCondition(conditionFunction, ms = 100) {
        const poll = resolve => {
            if (conditionFunction()) {
                resolve();
            }
            else {
                setTimeout(_ => poll(resolve), ms);
            }
        }

        return new Promise(poll);
    }

    static waitForConditionAsync(conditionFunction, timerInMs?: number, timeout?: number) {
        var isExpired = false;
        const poll = async resolve => {
            if (isExpired || await conditionFunction()) resolve();
            else setTimeout(_ => poll(resolve), timerInMs ? timerInMs : 100);
        }

        if (timeout) {
            setTimeout(() => isExpired = true, timeout);
        }
        
        return new Promise(poll);
    }

    static launchModelEditor(id: string, newTab: boolean) {
        //open new resource as a space using callback and hide explorer 
        const url = MODEL_EDITOR_URL + "?spaceId=" + id;

        if (newTab) {
            window.open(url, '_blank').focus();
        }
        else {
            window.location.href = url;
        }
    }

    static launchSpaceEditor(id: string, newTab: boolean) {
        //open new resource as a space using callback and hide explorer 
        const url = SPACE_EDITOR_URL + "?spaceId=" + id;

        if (newTab) {
            window.open(url, '_blank').focus();
        }
        else {
            window.location.href = url;
        }
    }

    static launchDeckEditor(id: string, newTab: boolean) {
        //open new resource as a deck using callback and hide explorer 
        const url = DECK_EDITOR_URL + "?guid=" + id;

        if (newTab) {
            window.open(url, "_blank").focus();
        }
        else {
            window.location.href = url;
        }
    }

    static launchHevoCollabWebViewer(roomCode: string, newTab: boolean) {
        //open HevoCollab room using callback and hide explorer 
        const url = `${DECK_EDITOR_URL}?roomCode=${roomCode}&isObserver=true`;

        if (newTab) {
            window.open(url, "_blank").focus();
        }
        else {
            window.location.href = url;
        }
    }

    /**
     * Filter out system tags from provided tag array and returns it
     */
    static filterSystemTags(tags: string[]) {
        return tags.filter(tag => !SYSTEM_TAGS.includes(tag) && !SYSTEM_TAG_PREFIXES.some(prefix => tag.startsWith(prefix)));
    }

    static setCookie(name: string, value: string) {
        var expirationDate: Date = new Date();
        expirationDate.setFullYear(expirationDate.getFullYear() + 2);
        const newCookie = `${name}=${value}; expires=${expirationDate.toUTCString()}";`;

        document.cookie = newCookie;

        console.log("SETTING COOKIE DONE: " + document.cookie);
    }

    static getCookie(name: string): string | null {
        var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        let cookieValue: string = null;

        if (match) {
            cookieValue = match[2];
        }

        return cookieValue;
    }

    static async generateCode(id: string) {
        let resp = await fetch(`https://${Vertex.Globals.vertexStackUrl}/core/code/${id}`, {
            method: "POST",
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${Vertex.Globals.bearerToken}`
            }
        });

        if (!resp.ok)
            return null;

        let body = await resp.json();
        return body.code;
    }

    static delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    static async changeAssetIcon(asset: AssetType, assetName: string, iconName: string = null) {
        let response: Response = await ResourceUtils.getAssetFromResource(Vertex.Globals.spaceId, CHANGE_MATERIAL_MODEL_FILENAME);

        if (response.ok) {
            const skins: ChangeMaterialModel = await response.json() as ChangeMaterialModel;

            let index = -1;
            if (asset === AssetType.Material) {
                index = skins.materials.findIndex(material => material.name === assetName);
                if (index !== -1) {
                    skins.materials[index].icon = iconName;
                }
            } else if (asset === AssetType.Category) {
                index = skins.categories.findIndex(category => category.name === assetName);
                if (index !== -1) {
                    skins.categories[index].icon = iconName;
                }
            } else if (asset === AssetType.Preset) {
                index = skins.presets.findIndex(preset => preset.name === assetName);
                if (index !== -1) {
                    skins.presets[index].icon = iconName;
                }
            } else if (asset === AssetType.PresetCategory) {
                index = skins.presetCategories?.findIndex(presetCategory => presetCategory.name === assetName);
                if (index !== -1) {
                    skins.presetCategories[index].icon = iconName;
                }
            } else if (asset === AssetType.Submesh) {
                index = skins.subMeshes.findIndex(submesh => submesh.name === assetName);
                if (index !== -1) {
                    skins.subMeshes[index].icon = iconName;
                }
            }

            await ResourceUtils.postAssetToResource(CHANGE_MATERIAL_MODEL_FILENAME, Vertex.Globals.spaceId, JSON.stringify(skins));
        }
    }


    static async ensureMediaTags(resource: VERTEXResource) {
        const hevoResourceType = ResourceUtils.getHevolusResourceType(resource);
        let newTags = [];

        if (hevoResourceType === HevolusResourceType.Media) {
            let oldTags = resource.tags.filter(tag => !tag.startsWith("sys-content"));
            newTags = [...oldTags];

            if (resource.resourceKeys?.length) {
                for (let i = 0; i < resource.resourceKeys.length; i++) {
                    const file = resource.resourceKeys[i];
                    const extension = file.substring(file.lastIndexOf('.') + 1, file.length).toLowerCase();

                    if (ALLOWED_IMAGE_TYPES.includes(extension)) {
                        if (!newTags.includes(CONTENT_IMAGE_TAG)) {
                            newTags.push(CONTENT_IMAGE_TAG);
                        }
                    }
                    else if (ALLOWED_AUDIO_TYPES.includes(extension)) {
                        if (!newTags.includes(CONTENT_AUDIO_TAG)) {
                            newTags.push(CONTENT_AUDIO_TAG);
                        }
                    }
                    else if (ALLOWED_VIDEO_TYPES.includes(extension)) {
                        if (!newTags.includes(CONTENT_VIDEO_TAG)) {
                            newTags.push(CONTENT_VIDEO_TAG);
                        }
                    }
                    else if (ALLOWED_DOC_TYPES.includes(extension)) {
                        if (!newTags.includes(CONTENT_DOC_TAG)) {
                            newTags.push(CONTENT_DOC_TAG);
                        }
                    }
                    else if (ALLOWED_PDF_TYPES.includes(extension)) {
                        if (!newTags.includes(CONTENT_PDF_TAG)) {
                            newTags.push(CONTENT_PDF_TAG);
                        }
                    }
                }
            }

            await ResourceUtils.saveTagsWithRes(newTags, resource, false, false);
        }
        else {
            console.log(`Resource ${resource.id} is not a Media resource.`);
        }
    }


    static hasFile(resource: VERTEXResource, fileName): boolean {
        return resource.resourceKeys.includes(fileName);
    }

    static getResourceFileExtensions(resource: VERTEXResource): string[] {
        const extensions = [];

        if (resource?.resourceKeys?.length) {
            for (let i = 0; i < resource.resourceKeys.length; i++) {
                const extension = resource.resourceKeys[i].substring(resource.resourceKeys[i].lastIndexOf('.') + 1, resource.resourceKeys[i].length);
                extensions.push(extension);
            }
        }
        else {
            console.log(`Failed to get file extensions for resource: ${resource.id}`);
        }

        return extensions;
    }


    static cleanMediaName(fileName: string) {
        const result = fileName.replace(/[^- ._a-zA-Z0-9]/g, '');

        return result;
    }

    /**
     * Returns the file extension (without '.')
     * E.g. Sample.jpg -> jpg
     * @param fileName
     */
    static getFileExtension(fileName: string, toLower: boolean = true): string{
        let ext = fileName.split('.').pop() || fileName;

        if(toLower){
            ext = ext.toLowerCase(); 
        }

        return ext;
    }

    /**
     * Returns the file name without the extension
     * E.g. Sample.jpg -> Sample
     * @param fileName
     */
    static getFileBaseName(fileName: string): string{
        return fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
    }

    /**
     * Returns the provided file with lowered case extension.
     * E.g. Sample.JPG -> Sample.jpg
     * @param file
     */
    static toLowerCaseExtension(file: File): File{        
        return new File([file],
            `${Utils.getFileBaseName(file.name)}.${Utils.getFileExtension(file.name)}`,
            { type: file.type, lastModified: file.lastModified });
    }

    static toThumbFilename(file: File): File{
        return new File([file], `${RESOURCE_THUMB_FILENAME}`, {type: file.type, lastModified: file.lastModified});
    }

    static fileValidator(file: File, ...extensions: string[]): boolean {
        const splittedName = file.name.split(".");
        const fileExtension = splittedName[splittedName.length - 1];
        let isValidExtension: boolean = false;

        if (extensions.includes(fileExtension.toLowerCase())) {
            isValidExtension = true;
        }

        return isValidExtension;
    }

    static async infoPopup(type: string, message: string) {

        let alertWrapper = document.getElementById("alert-wrapper");
        // Eg "success"
        let alertType = type;
        let alert = document.createElement("div");
        alert.classList.add("alert", type);
        let alertText = document.createElement("div");
        let alertMessage = message;
        alertText.classList.add("alert-text");
        alertText.innerText = alertMessage;
        alert.appendChild(alertText);

        //insert new alert at the top of the list
        alertWrapper.insertBefore(alert, alertWrapper.childNodes[0]);
        alert.classList.add("alert-active");
        setTimeout(() => {
            alert.classList.remove("alert-active");
        }, 1500);
        setTimeout(() => {
            alert.remove();
        }, 2000);
    }

    static async injectSvg(imgs, options?){
        try{
            return await SVGInject(imgs, options);
        }
        catch(e){
            console.error(e);
        }        
    }

    /**
     * make str a human readable string, applying the following rules:
     * 1. replace all '-' with ' '
     * 2. replace all '_' with ' '
     * 3. capitalize first letter of each word
     * 4. remove all double whitespaces
     * 5. add a space between numbers and letters (and vice-versa)
     * 6. add a space between a small and a Capital letter
     * 7. remove all "is" and "Is" words
     * 8. remove all leading and trailing whitespaces
     * @param str the string to make human readable
     * @returns the human readable string
     */
    static getHumanReadableString(str: string): string {
        //replace all '-' with ' '
        str = str.replace(/-/g, ' ');

        //replace all '_' with ' '
        str = str.replace(/_/g, ' ');

        //capitalize first letter of each word
        str = str.replace(/\b\w/g, l => l.toUpperCase());

        //remove all double whitespaces
        str = str.replace(/\s+/g, ' ');

        //add a space between numbers and letters (and vice-versa)
        str = str.replace(/([a-zA-Z])([0-9])/g, '$1 $2');
        str = str.replace(/([0-9])([a-zA-Z])/g, '$1 $2');

        //add a space between a small and a Capital letter
        str = str.replace(/([a-z])([A-Z])/g, '$1 $2');

        //remove all "is" and "Is" words
        str = str.replace(/\bis\b/g, ' ');
        str = str.replace(/\bIs\b/g, ' ');

        //remove all leading and trailing whitespaces
        str = str.trim();

        return str;
    }

    static setupSidebarButton(componentName: string, panelId: string, section?: string): HTMLDivElement {
        const componentUIName = Utils.getComponentUIName(componentName);
        const componentUIOrder = Utils.getComponentUIOrder(componentName);
        const componentUIIcon = Utils.getComponentUIIcon(componentName);

        //get sidebar
        const sidebar = document.getElementById("sidebar");

        // find corrosponding panel and hide

        const containerOverlayLeft = document.querySelector(".container-overlay-left") as HTMLDivElement;
        
        if(containerOverlayLeft){
            containerOverlayLeft.classList.add("hidden");
            containerOverlayLeft.classList.remove("h-100");            
        }

        const leftSidebarGrid = document.querySelector(".left-sidebar-grid") as HTMLDivElement;
        leftSidebarGrid?.classList.add("hidden");

        let container = document.querySelector(".container-overlay-left");

        let panel = document.getElementById(panelId);
        panel?.classList.add("hidden");

        // create sidebar button
        let sidebarButton = document.createElement("div");
        sidebarButton.id = `sidebar-${componentName}`;
        sidebarButton.classList.add("sidebar-button");
        sidebarButton.dataset.toggle = "tooltip";
        sidebarButton.dataset.placement = "right";
        sidebarButton.title = componentUIName;
        // check if it should go in a specific section
        if (section) {
            let buttonSection = document.getElementById(`${section}-section`);
            buttonSection?.append(sidebarButton);
        } else {
            sidebar?.append(sidebarButton);
        }

        sidebarButton.style.order = componentUIOrder ? `${componentUIOrder}` : '0';

        // add icon
        let sidebarIcon = document.createElement("img");
        sidebarIcon.classList.add("sidebar-icon");
        sidebarIcon.alt = `${componentUIName} icon`;
        sidebarIcon.src = componentUIIcon;
        SVGInject(sidebarIcon);
        sidebarButton.append(sidebarIcon);

        // button listeners
        sidebarButton.addEventListener("click", () => {
            if (sidebarButton.classList.contains("sidebar-active")) {
                panel.classList.add("hidden");
                sidebarButton.classList.remove("sidebar-active");
                let activeMarker = sidebarButton.querySelector(".active-circle");
                activeMarker.remove();
            }
            else {
                console.log(`Opening ${componentUIName} Panel`);
                panel.classList.remove("hidden");
                container?.classList.remove("hidden");
                sidebarButton.classList.add("sidebar-active");
                let activeMarker = document.createElement("div");
                activeMarker.classList.add("active-circle");
                sidebarButton.appendChild(activeMarker);
            }

            if (leftSidebarGrid && containerOverlayLeft) {
                let children = [...leftSidebarGrid.children] as HTMLDivElement[];
                let visibleChildren = children.filter(child => !child.classList.contains("hidden"));

                if (visibleChildren.length === 0) {
                    leftSidebarGrid.classList.add("hidden");
                    containerOverlayLeft.classList.add("hidden");
                } else {
                    leftSidebarGrid.classList.remove("hidden");
                    containerOverlayLeft.classList.remove("hidden");
                }
            }
        });

        if (componentUIName) {
            sidebarButton.title = componentUIName;
            sidebarButton.setAttributeNS(null,"data-original-title", componentUIName);
            ($('[data-toggle="tooltip"]') as any).tooltip();
        }

        Utils.setDraggable("sidebar-icon", false);

        return sidebarButton;
    }

    static dataURItoBlob(dataURI: string) {
        var byteString = atob(dataURI.split(',')[1]);
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        var blob = new Blob([ab], { type: mimeString });
        return blob;
    }

    static setDraggable(className: string, isDraggable: boolean) {
        const elements = [...document.getElementsByClassName(className)] as HTMLElement[];

        for (let element of elements) {
            element.draggable = isDraggable;
        }
    }

    static sortVertexResources(array: VERTEXResource[], orderMode: OrderMode): VERTEXResource[] {
        let sortedArray = [...array];

        if (orderMode === OrderMode.Date) {
            sortedArray.sort((elem1, elem2) => {
                let result = 0;

                if (elem1.modified > elem2.modified) {
                    result = -1;
                }

                if (elem1.modified < elem2.modified) {
                    result = 1;
                }

                return result;
            });
        } else if (orderMode === OrderMode.Name) {
            sortedArray.sort((elem1, elem2) => {
                let result = 0;

                if (elem1.name.toLowerCase() > elem2.name.toLowerCase()) {
                    result = 1;
                }

                if (elem1.name.toLowerCase() < elem2.name.toLowerCase()) {
                    result = -1;
                }

                return result;
            });
        }

        return sortedArray;
    }

    static async populateEditTags(parent: HTMLDivElement, resource: VERTEXResource, tagSuggestions: string[] = []) {

        // REQUIRES HTML TEMPLATES TO BE IMPORTED, PLEASE INCLUDE:
        // #temp-tag-input, #tag

        // get resource info
        const resourceInfo = resource;
        const currentSysTags = ResourceUtils.getSystemTags(resourceInfo.tags);

        let tagInputTemplate = importTemplate("#temp-tag-input");

        let tagWrapper = parent.querySelector(".info-wrapper-tag");
        tagWrapper.innerHTML = "";

        let tagInput = tagInputTemplate.querySelector(".tag-input") as HTMLInputElement;
        tagInput.placeholder = "Add Tag"
        tagInput.addEventListener('drop', function(e) {
            e.preventDefault();
        });
        
        tagWrapper.appendChild(tagInputTemplate);

        //suggestion tags
        if (tagSuggestions && tagSuggestions.length > 0) {
            let tagSuggestionArea = importTemplate("#temp-tag-suggestion-area");
            tagWrapper.parentNode.insertBefore(tagSuggestionArea, tagWrapper.nextSibling);
            tagSuggestionArea.innerHTML = "";
            tagSuggestionArea.classList.remove("d-none");
            tagSuggestions.forEach(tag => {
                let tagButton = document.createElement("button");
                tagButton.classList.add("btn", "btn-secondary", "btn-sm", "mr-1", "mt-1");
                tagButton.innerText = tag;
                tagSuggestionArea.append(tagButton);
                tagButton.addEventListener("click", () => {

                    let thisTag = tagButton.innerText;

                    if (tags.indexOf(thisTag) !== -1) {
                        Utils.notify("Tag already exists on this resource", NotificationStatus.Warning);
                        //Utils.infoPopup("warning", "Tag already exists on this resource");
                        return;
                    }
                    else {
                        tagButton.classList.add("active-tag-suggestion");
                        tags.push(thisTag);
                        let tag = importTemplate("#tag");
                        tagArea.prepend(tag);
                        let text = tag.querySelector(".tag-text") as HTMLButtonElement;
                        text.innerText = thisTag;
                        let removeButton = tag.querySelector(".remove-button-wrapper");
                        removeButton.addEventListener("click", async () => {
                            let tagName = tags.indexOf(thisTag)
                            tags.splice(tagName, 1);
                            tag.remove();
                            // const filteredTags = await Utils.saveTagsWithRes(tags, resourceInfo, true, true, currentSysTags);
                            // Vertex.Globals.event.fire("searchResources", { resourceId: resourceInfo.id, tags: filteredTags });
                        })

                    }

                })
            });
        }
        
        let infoWrapper = tagWrapper.querySelector(".info-wrapper");

        let tagIcon = document.createElement("img");
        tagIcon.classList.add("tag-icon");
        tagIcon.src = "/img/tag-icon.svg";
        infoWrapper.appendChild(tagIcon);

        // tag arrray
        let tags = resourceInfo.tags;

        //Populate Existing Tags
        let tagArea = tagInputTemplate.querySelector(".tag-area");
        let filteredTags = Utils.filterSystemTags(tags);

        filteredTags.forEach(t => {

            if (t.length < 1) {
                return;
            }
            else {
                let tag = importTemplate("#tag");
                tagArea.appendChild(tag);
                let text = tag.querySelector(".tag-text") as HTMLButtonElement;
                text.innerText = t;
                let removeButton = tag.querySelector(".remove-button-wrapper");

                removeButton.addEventListener("click", async () => {
                    let tagIndex = tags.indexOf(t)
                    tags.splice(tagIndex, 1);
                    tag.remove();
                    const filteredTags = await ResourceUtils.saveTagsWithRes(tags, resourceInfo, true, true, currentSysTags);
                    Vertex.Globals.event.fire("searchResources", { resourceId: resourceInfo.id, tags: filteredTags });
                });
            }
        })

        //Inputting new Tags
        tagInput.addEventListener("keydown", async (e) => {
            if (e instanceof KeyboardEvent) {
                tagInput.value = Utils.sanitizeString(tagInput.value);

                // prevent typing comma
                if (e.key == ",") {
                    e.preventDefault();
                }
                // tab function
                // enter function
                if (e.key == "Enter" || e.key == "Tab") {
                    e.preventDefault();

                    tagInput.value = tagInput.value.trim();
                    
                    if (tags.indexOf(tagInput.value) !== -1) {
                        tagInput.value = ""
                        Utils.notify("Tag already exists on this resource", NotificationStatus.Warning);
                        return;
                    }

                    if (tagInput.value.length > 0) {
                        tags.push(tagInput.value);

                        let tagElement = importTemplate("#tag");
                        tagArea.prepend(tagElement);

                        let text = tagElement.querySelector(".tag-text") as HTMLButtonElement;
                        text.innerText = tagInput.value;

                        let removeButton = tagElement.querySelector(".remove-button-wrapper");
                        
                        removeButton.addEventListener("click", async () => {
                            let tagIndex = tags.indexOf(tagInput.value)
                            tags.splice(tagIndex, 1);
                            tagElement.remove();
                            
                            await save();
                        });

                        tagInput.value = '';

                        await save();
                    }
                }
            }
        });

        const save = async () => {
            const filteredTags = await ResourceUtils.saveTagsWithRes(tags, resourceInfo, true, true, currentSysTags);
            Vertex.Globals.event.fire("searchResources", { resourceId: resourceInfo.id, tags: filteredTags });
        }
    }

    static async getFilteredResources(licenses: LicenseDictionary, orderMode: OrderMode = OrderMode.Date, completeResources: boolean = false, ...types: HevolusResourceType[]): Promise<VERTEXResource[]> {
        let resources: VERTEXResource[] = [];

        if (types.includes(HevolusResourceType.Space)) {
            //initialize a list of allowed tags
            let allowedTags = [];

            //check if the user is a HM host or creator
            if (licenses[HOLOMUSEUM_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid ||
                licenses[HOLOMUSEUM_HOST_LICENSE] === LicenseValidity.Valid) {
                allowedTags.push(APP_HOLOMUSEUM_TAG);
            }

            //check if the user is RS host or creator
            if (licenses[REMOTESELLING_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid ||
                licenses[REMOTESELLING_HOST_LICENSE] === LicenseValidity.Valid) {
                allowedTags.push(APP_REMOTESELLING_TAG);
            }

            //check if the user is AS creator
            if (licenses[AUGMENTEDSTORE_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid) {
                allowedTags.push(APP_AUGMENTEDSTORE_TAG);
            }

            //check if the user is HP host or creator
            if (licenses[HOLOPROTOTYPE_HOST_LICENSE] === LicenseValidity.Valid ||
                licenses[HOLOPROTOTYPE_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid) {
                allowedTags.push(APP_HOLOPROTOTYPE_TAG);
            }

            if (allowedTags.length > 0) {
                //takes all the spaces which have allowedTags
                let spaceResources: VERTEXResource[] = await ResourceUtils.getResourcesWithParamsAsync(allowedTags, TagMode.Any, ResourceUtils.getVertexResourceType(HevolusResourceType.Space));

                //filter to include res with space type tag only
                spaceResources = spaceResources.filter(res => ResourceUtils.getHevolusResourceType(res) === HevolusResourceType.Space);

                resources = resources.concat(spaceResources);
            }
        }

        if (types.includes(HevolusResourceType.Deck)) {
            //initialize a list of allowed tags
            let allowedTags = [];

            //check if the user is HC content creator 
            if (licenses[HEVOCOLLABORATION_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid) {
                allowedTags.push(APP_HEVOCOLLABORATION_TAG);
            }

            if (allowedTags.length > 0) {
                //takes all the decks which have allowedTags
                let deckResources: VERTEXResource[] = await ResourceUtils.getResourcesWithParamsAsync(allowedTags, TagMode.Any, ResourceUtils.getVertexResourceType(HevolusResourceType.Deck));
                //filter to include deck res only
                deckResources = deckResources.filter(res => ResourceUtils.getHevolusResourceType(res) === HevolusResourceType.Deck);

                resources = resources.concat(deckResources);
            }
        }

        if (types.includes(HevolusResourceType.Room)) {
            //initialize a list of allowed tags
            let allowedTags = [];

            //check if the user is AS creator
            //TODO FRANZ enable AS room when it makes sense
            //if (licenses[Utils.AUGMENTEDSTORE_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid) {
            //allowedTags.push(Utils.APP_AUGMENTEDSTORE_TAG);
            //}

            //check if the user is HP host or creator
            //TODO FRANZ enable HP room when it makes sense
            // if (licenses[Utils.HOLOPROTOTYPE_HOST_LICENSE] === LicenseValidity.Valid ||
            //     licenses[Utils.HOLOPROTOTYPE_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid) {
            //     allowedTags.push(Utils.APP_HOLOPROTOTYPE_TAG);
            // }     

            //check if the user is HC host 
            if (licenses[HEVOCOLLABORATION_HOST_LICENSE] === LicenseValidity.Valid) {
                allowedTags.push(APP_HEVOCOLLABORATION_TAG);
            }

            if (allowedTags.length > 0) {
                //takes all the spaces which have allowedTags
                let roomResources: VERTEXResource[] = await ResourceUtils.getResourcesWithParamsAsync(allowedTags, TagMode.Any, ResourceUtils.getVertexResourceType(HevolusResourceType.Room));
                //filter to include room res only
                roomResources = roomResources.filter(res => ResourceUtils.getHevolusResourceType(res) === HevolusResourceType.Room);

                resources = resources.concat(roomResources);
            }
        }

        //check for content creator license
        if (licenses[REMOTESELLING_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid ||
            licenses[HOLOMUSEUM_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid ||
            licenses[AUGMENTEDSTORE_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid ||
            licenses[HEVOCOLLABORATION_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid) {

            if (types.includes(HevolusResourceType.Media)) {
                let mediaResources: VERTEXResource[] = await ResourceUtils.getResourcesWithParamsAsync([], TagMode.Any, ResourceUtils.getVertexResourceType(HevolusResourceType.Media));
                mediaResources = mediaResources.filter(res => ResourceUtils.getHevolusResourceType(res) === HevolusResourceType.Media);

                resources = resources.concat(mediaResources);
            }
        }

        //check for content creator license
        if (licenses[REMOTESELLING_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid ||
            licenses[HOLOMUSEUM_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid ||
            licenses[AUGMENTEDSTORE_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid ||
            licenses[HEVOCOLLABORATION_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid ||
            licenses[HOLOPROTOTYPE_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid) {

            if (types.includes(HevolusResourceType.Model)) {
                let modelResources: VERTEXResource[] = await ResourceUtils.getResourcesWithParamsAsync([], TagMode.Any, ResourceUtils.getVertexResourceType(HevolusResourceType.Model));
                modelResources = modelResources.filter(res => ResourceUtils.getHevolusResourceType(res) === HevolusResourceType.Model);

                resources = resources.concat(modelResources);
            }
        }

        //check for content creator license
        //TODO: we must decide if HP cc can manage media or not. Atm they only manage mesh assets, but before customize the code better to explore future scenarios too.
        if (licenses[HOLOPROTOTYPE_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid) {

            if (types.includes(HevolusResourceType.Base)) {
                let baseResources: VERTEXResource[] = await ResourceUtils.getResourcesWithParamsAsync([], TagMode.Any, ResourceUtils.getVertexResourceType(HevolusResourceType.Base));
                baseResources = baseResources.filter(res => ResourceUtils.getHevolusResourceType(res) === HevolusResourceType.Base);

                resources = resources.concat(baseResources);
            }
        }

        //filters out all the resources which have sys hidden tag
        resources = resources.filter(res => !res.tags.includes(HIDDEN_RESOURCE_TAG));

        //sort the resources by modified attribute
        resources = Utils.sortVertexResources(resources, orderMode);

        if (completeResources) {
            for (let i = 0; i < resources.length; i++) {
                resources[i] = await ResourceUtils.getResourceData(resources[i].id);
            }
        }

        return resources;
    }
  
    static isValidUrl(string) {
        let isValid = true;
 
        try {
            let url = new URL(string);
 
            // here we check that the created URL is not a file url e.g. a: and then also that after each . in the hostname there is at least a letter or digit to avoid e.g. http://. https://.. and so on
            if(url.hostname){
                const regX = new RegExp("([0-9A-Za-z])");
                const urlChars = Array.from(url.hostname);
 
                urlChars.forEach((c, i) => {
                    if(c === '.' && (urlChars.length <= i+1 || !regX.test(url.hostname[i+1]))){
                        isValid = false;
                    }
                });
            }
            else{
                isValid = false;
            }
 
        } catch (_) {
            return false;
        }
 
        return isValid;
    }

    static filterDuplicates(array: any[]) {
        let map = [];
        array.forEach(v => map[v] = v);

        return Object.values(map);
    }

    static getComponentUIIcon(compName: string) {
        let icon;

        if(COMPONENTS_UI_PROPERTIES.has(compName)){
            icon = COMPONENTS_UI_PROPERTIES.get(compName).icon;
        }

        return icon ?? "/img/component.svg"
    }

    static getComponentUIName(compName: string) {
        let name = compName;
        
        if(COMPONENTS_UI_PROPERTIES.has(compName)){
            name = COMPONENTS_UI_PROPERTIES.get(compName).name;
        }
        else if(ADDITIONAL_COMPONENTS_UI_PROPERTIES.has(compName)){
            name = ADDITIONAL_COMPONENTS_UI_PROPERTIES.get(compName);
        }

        return name;
    }

    static getComponentUIOrder(compName: string) {
        return COMPONENTS_UI_PROPERTIES.has(compName) ? COMPONENTS_UI_PROPERTIES.get(compName).order : 0;
    }

    // static getContainerUrlFromUrl(url: string): string {
    //     let result = "";
    //     const containerEndIndex = url.lastIndexOf("/");

    //     if (containerEndIndex > 0) {
    //         result = url.substr(0, containerEndIndex);
    //     }

    //     return result;
    // }

    // static getSasFromUrl(url: string): string {
    //     let result = "";
    //     const SasStartIndex = url.indexOf("?");

    //     if (SasStartIndex > 0) {
    //         result = url.substr(SasStartIndex, url.length);
    //     }

    //     return result;
    // }

    public static isMobileDevice() {
        const toMatch = [
            "android",
            "webos",
            "ios",
            "iphone",
            "ipad",
            "ipod",
            "blackberry",
            "windows phone",
        ];

        if (navigator.userAgent.toLowerCase().indexOf("mac") !== -1 && navigator.maxTouchPoints > 0) {
            return true;
        }

        for (let i = 0; i < toMatch.length; i++) {
            return navigator.userAgent.toLowerCase().indexOf(toMatch[i]) !== -1;
        }

        return false;
    }

    public static getDeviceType() {
        const ua = window.navigator.userAgent;

        if (!!ua.match(/iPhone/i)) {
            return DeviceType.iPhone;
        }

        if (!!ua.match(/iPad/i) || (navigator.platform === "MacIntel")) {
            return DeviceType.iPad;
        }

        if (!!ua.match(/android/i)) {
            return DeviceType.Android;
        }

        return DeviceType.Desktop;
    }

    /** This function is a utility function that allows for the use of the await keyword within non-async functions.
     *  The await keyword is used to wait for a promise to resolve before continuing with the execution of a function.
     *  However, await can only be used within an async function.
    
     * shortened version: 
     * static __awaiter = function (thisArg, _arguments, P, generator) {
     *     return new (P || (P = Promise))(function (resolve, reject) {
     *         function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
     *         function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
     *         function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
     *         step((generator = generator.apply(thisArg, _arguments || [])).next());
     *     });
     * };
     */
    static __awaiter = function (thisArg, _arguments, P, generator) {
        // return a new promise
        return new (P || (P = Promise))(function (resolve, reject) {
            // fulfilled is called when the promise is resolved
            function fulfilled(value) {
                try {
                    // call the next step of the generator function with the resolved value
                    step(generator.next(value));
                } catch (e) {
                    // if an error occurs, reject the promise
                    reject(e);
                }
            }
            // rejected is called when the promise is rejected
            function rejected(value) {
                try {
                    // call the next step of the generator function with the rejected value
                    step(generator["throw"](value));
                } catch (e) {
                    // if an error occurs, reject the promise
                    reject(e);
                }
            }
            // step is called to determine if the generator function is done and resolve/reject the promise accordingly
            function step(result) {
                if (result.done) {
                    // if the generator function is done, resolve the promise with the final value
                    resolve(result.value);
                } else {
                    // if the generator function is not done, call the next step with the current value
                    new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected);
                }
            }
            // start the generator function
            step((generator = generator.apply(thisArg, _arguments || [])).next());
        });
    };

    static splitRGBAChannels(RGBA: Uint8Array) {
        const result = { R: [], G: [], B: [], A: [] };

        if (RGBA.length % 4 !== 0) {
            throw new Error("Invalid RGBA array, length is not a multiple of 4 " + RGBA.length);
        }

        for (let i = 0; i < RGBA.length; i += 4) {
            result.R.push(RGBA[i]);
            result.G.push(RGBA[i + 1]);
            result.B.push(RGBA[i + 2]);
            result.A.push(RGBA[i + 3]);
        }

        return result;
    }

    static mergeRGBAChannels(R: number[], G: number[], B: number[], A: number[]) {
        if (R.length !== G.length || R.length !== B.length || R.length !== A.length) {
            throw new Error("Invalid RGBA arrays, lengths are not equal");
        }

        const result = new Uint8Array(R.length * 4);

        for (let i = 0; i < R.length; i++) {
            const index = i * 4;
            result[index] = R[i];
            result[index + 1] = G[i];
            result[index + 2] = B[i];
            result[index + 3] = A[i];
        }

        return result;
    }
}

/**
 * Searches for a template element matching 'selector', clones the
 * template, and returns the root element of the template.
 * 
 * The template must only contain 1 root element, and must be a <template> element.
 * @param selector the selector to search for (e.g. ".my-template")
 */
export function importTemplate(selector: string): HTMLElement {
    if (typeof selector !== 'string')
        throw new Error('selector must be a string');

    const ele = document.querySelector(selector);
    if (!ele)
        throw new Error(`No element matching "${selector}" was found on the page.`);

    if (ele instanceof HTMLTemplateElement === false)
        throw new Error(`The element matching "${selector}" must be a <template> element.`);

    let clone = (ele as HTMLTemplateElement).content.cloneNode(true) as DocumentFragment;

    if (clone.children.length != 1)
        throw new Error(`The template matching "${selector}" must contain exactly one element at the root level.`);

    let root = clone.children[0] as HTMLElement;
    return root;
}
export interface IUpdtableAfterSaving {
    isUpdtableAfterSaving: boolean;
    updateComponent(): void;
}

export function instanceOfIUpdtableAfterSaving(object: any): object is IUpdtableAfterSaving {
    return object && object.isUpdtableAfterSaving;
}

export interface IDetachableRemotionLogicComponent extends IOverridableRemotionLogicComponent {
    isDetachableRemotionLogicComponent: boolean;
    detachRemotionLogic(): void;
}

export function instanceOfIDetachableRemotionLogicComponent(object: any): object is IDetachableRemotionLogicComponent {
    return object && object.isDetachableRemotionLogicComponent;
}

export interface IOverridableRemotionLogicComponent {
    isOverridableRemotionLogicComponent: boolean;
    onComponentRemoved?: () => void;
}

export function instanceOfICustomizablableRemotionLogicComponent(object: any): object is IOverridableRemotionLogicComponent {
    return object && object.isRemovable;
}

export function removeFromArray<T>(array: Array<T>, ...elements: Array<T>) {
    let index = -1;

    for (let i = 0; i < elements.length; i++) {
        index = array.indexOf(elements[i]);

        if (index >= 0) {
            array.splice(index, 1);
        }
    }
}

export function enterAsync(semaphore: semaphore.Semaphore): Promise<void> {
    return new Promise((resolve) => {
        semaphore.take(resolve);
    })
}

/**
 * Converts euler angles to quaternion
 * @param roll 
 * @param pitch 
 * @param yaw 
 * @returns 
 */
export function eulerToQuaternion(roll: number, pitch: number, yaw: number): { x: number, y: number, z: number, w: number } {

    roll = roll * (Math.PI / 180);
    pitch = pitch * (Math.PI / 180);
    yaw = yaw * (Math.PI / 180);

    let cy = Math.cos(yaw * 0.5);
    let sy = Math.sin(yaw * 0.5);
    let cp = Math.cos(pitch * 0.5);
    let sp = Math.sin(pitch * 0.5);
    let cr = Math.cos(roll * 0.5);
    let sr = Math.sin(roll * 0.5);

    let x = sr * cp * cy - cr * sp * sy;
    let y = cr * sp * cy + sr * cp * sy;
    let z = cr * cp * sy - sr * sp * cy;
    let w = cr * cp * cy + sr * sp * sy;

    return { x, y, z, w };
}

export function quaternionToEuler(w: number, x: number, y: number, z: number): { roll: number, pitch: number, yaw: number } {
    let roll = Math.atan2(2 * (w * x + y * z), 1 - 2 * (x * x + y * y));
    let pitch = Math.asin(2 * (w * y - z * x));
    let yaw = Math.atan2(2 * (w * z + x * y), 1 - 2 * (y * y + z * z));

    roll = roll * (180 / Math.PI);
    pitch = pitch * (180 / Math.PI);
    yaw = yaw * (180 / Math.PI);

    return { roll, pitch, yaw };
}

// export async function GetServerCdnUrl(assetID: string, resourceKey: string): Promise<string> {
//     const init: RequestInit = {
//         method: "GET",
//         headers: {
//             "Authorization": "Bearer " + Vertex.Globals.bearerToken,
//             "Content-Type": "application/json"
//         }
//     };

//     const nativeUrlRequest = "https://" + Vertex.Globals.vertexStackUrl + "/core/resource/nativeurl/" + assetID;
//     const urlCDN = await fetch(nativeUrlRequest, init);

//     let responseUrl = await urlCDN.json();

//     if (typeof responseUrl === 'string' || responseUrl instanceof String) {
//         const indexSharedKey = responseUrl.indexOf("?");

//         if (indexSharedKey > 0) {
//             responseUrl = responseUrl.substr(0, indexSharedKey) + "/" + resourceKey + responseUrl.substr(indexSharedKey, responseUrl.length);
//         } else {
//             responseUrl = responseUrl + "/" + resourceKey;
//         }
//     }
//     else {
//         responseUrl = "";
//     }

//     return responseUrl;
// }

export async function getComponentJsonFromResource(resourceId: string, componentName: string, nodeId: string, isEditorVersion: boolean, token?: string): Promise<Response> {
    const assetName = `${componentName}_${nodeId}_${isEditorVersion ? "editor" : "runtime"}.json`;

    const res = await fetch(`${RESOURCE_API_URI}${resourceId}/${assetName}`, {
        headers: {
            "Authorization": `Bearer ${token || Vertex.Globals.bearerToken}`
        }
    })

    return res;
}

export async function postComponentJsonToResource(resourceId: string, componentName: string, nodeId: string, isEditorVersion: boolean, bodyContent: BodyInit, token?: string, isPreviewVersion?: boolean): Promise<Response> {
    let assetName;
    
    if(isPreviewVersion){
        assetName = `${componentName}_${nodeId}_preview.json`;
    }
    else{
        assetName = `${componentName}_${nodeId}_${isEditorVersion ? "editor" : "runtime"}.json`;
    }

    try {
        let res = await fetch(`${RESOURCE_API_URI}${resourceId}/${assetName}`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${token || Vertex.Globals.bearerToken}`,
                "Content-Type": "application/octet-stream"
            },
            body: bodyContent
        })

        return res;
    } catch (error) {
        console.log(`Error while posting asset: ${error}`);
    }
}

export async function deleteComponentJsonFromResource(resourceId: string, componentName: string, nodeId: string, isEditorVersion: boolean, token?: string): Promise<Response> {
    const assetName = `${componentName}_${nodeId}_${isEditorVersion ? "editor" : "runtime"}.json`;

    const res = await fetch(`${RESOURCE_API_URI}${resourceId}/${assetName}`, {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${token || Vertex.Globals.bearerToken}`
        }
    })

    return res;
}

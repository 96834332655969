import { MESH_GLTF_FILENAME, GLTF_NODE_IMG_URI, GLTF_PARENT_NODE_IMG_URI } from "../../utilities/constants";
import { GltfNode, GltfMesh, GltfMeshPrimitive } from "../../utilities/gltf-utilities";
import { ResourceUtils } from "../../utilities/resource-utilities";
import { Utils } from "../../utilities/utils";
import { CustomLightMapsHandlerComponent } from "../componentsystems/EditorComponents/lightmapshandler";
import { CustomLightMapsComponent } from "../componentsystems/NodeComponents/lightmapscomponent";

export class CustomLightMapsInspectorRenderer implements Vertex.UI.ICustomInspectorRenderer {

    constructor(targetNode: Vertex.NodeComponentModel.VertexNode) {
        this.targetNode = targetNode;
    }

    targetNode: Vertex.NodeComponentModel.VertexNode;
    lightMapsComponent: CustomLightMapsComponent;
    property: string;

    panelBody: HTMLDivElement;

    static currentRenderer: CustomLightMapsInspectorRenderer;
    onChanged;
    lastDropdownMenu: HTMLDivElement;

    RenderProperty(property: string, target: CustomLightMapsComponent): HTMLDivElement {
        this.lightMapsComponent = target;
        this.panelBody = document.createElement("div");

        if(target.gltfJson == null || target.lightMapsData.length != target.gltfJson.meshes?.length){
            return this.panelBody;
        }

        CustomLightMapsHandlerComponent.lightMapsHandlerComp.isReady.then(async ()=>{
            const lightmaps = await CustomLightMapsHandlerComponent.getLightmaps();
            const lightmapsToDelete = await CustomLightMapsHandlerComponent.getLightmapsToDelete();

            let tree = await this.populateHierarchy(lightmaps, lightmapsToDelete);

            this.panelBody.append(tree);

            // let leftSidebarGrid = document.querySelector(".left-sidebar-grid");
            // leftSidebarGrid.appendChild(this.panelBody);

            //add eventlisteners to list
            var togglers = document.querySelectorAll(".caret");

            for (let toggle of togglers) {
                toggle.addEventListener("click", function () {
                    this.parentElement.querySelector(".nested").classList.toggle("active");
                    this.classList.toggle("caret-down");
                });
            }


            if (CustomLightMapsInspectorRenderer.currentRenderer) {
                Vertex.Globals.event.off("LightMaps:LightMapsUpdated", CustomLightMapsInspectorRenderer.currentRenderer.onChanged);
            }

            this.onChanged = async () => {
                let dropdownsButtons = this.panelBody.querySelectorAll(".dropdown-toggle");
                const lightmaps = await CustomLightMapsHandlerComponent.getLightmaps();
                const lightmapsToDelete = await CustomLightMapsHandlerComponent.getLightmapsToDelete();

                for (let btn of dropdownsButtons) {
                    if (!lightmaps.some((lightMap) => lightMap.fileName == btn.innerHTML) || lightmapsToDelete.includes(btn.innerHTML)) {
                        btn.innerHTML = "None";
                    }
                }
            }

            this.onChanged();

            Vertex.Globals.event.on("LightMaps:LightMapsUpdated", this.onChanged);
        });

        CustomLightMapsInspectorRenderer.currentRenderer = this;

        return this.panelBody;

    }

    async populateHierarchy(lightmaps, lightmapsToDelete): Promise<HTMLUListElement> {

        let tree = document.createElement("ul") as HTMLUListElement;
        tree.id = "myUL";

        let meshList = document.createElement("li") as HTMLLIElement;
        meshList.classList.add("mesh-list")
        tree.appendChild(meshList);

        let meshSpan = document.createElement("span");
        let meshIcon = document.createElement("img");
        let meshText = document.createElement("div");
        meshSpan.classList.add("caret", "top-caret");
        meshSpan.id = "top-caret";
        meshIcon.classList.add("list-img");
        meshIcon.src = "/img/mesh.svg";
        meshText.innerText = MESH_GLTF_FILENAME;
        meshText.classList.add("list-text");
        meshList.append(meshSpan);
        meshSpan.appendChild(meshIcon);
        meshSpan.appendChild(meshText);

        let topLevel = document.createElement("ul") as HTMLUListElement;
        topLevel.classList.add("nested");
        meshList.appendChild(topLevel);

        for (let scene of this.lightMapsComponent.gltfJson.scenes) {
            for (let nodeIndex of scene.nodes) {
                this.recursiveAddNodeToList(topLevel, this.lightMapsComponent.gltfJson.nodes[nodeIndex], this.lightMapsComponent.gltfJson.nodes, lightmaps, lightmapsToDelete);
            }
        }

        return tree;
    }

    recursiveAddNodeToList(htmlParent: HTMLUListElement, node: GltfNode, nodeList: GltfNode[], lightmaps, lightmapsToDelete) {

        let self = this;

        const meshes = this.lightMapsComponent.gltfJson.meshes as GltfMesh[];
        let hasPrimitiveWithTwoUVs = false;
        if(node.mesh != undefined){
            let primitives = meshes[node.mesh].primitives as GltfMeshPrimitive[];
            hasPrimitiveWithTwoUVs = primitives.some(primitive => primitive.attributes?.TEXCOORD_0 != null && primitive.attributes?.TEXCOORD_1 != null);
        }
        //if node has children, create sub-list
        if (typeof node.children === 'undefined') {
            //if node has no children, it is list item
            let listItem = document.createElement("li") as HTMLLIElement;
            let listText = document.createElement("div");
            let listIcon = document.createElement("img");


            listItem.classList.add("list-item");
            listItem.style.display = "flex";
            listIcon.classList.add("list-img");
            listText.classList.add("list-text");
            listText.innerText = node.name;

            const lightmapIndex = this.lightMapsComponent.lightMapsData[node.mesh];

            listIcon.src = GLTF_NODE_IMG_URI;

            htmlParent.appendChild(listItem);

            // add mesh index attribute
            listItem.setAttribute('mesh-index', `${node.mesh}`);

            listItem.appendChild(listIcon);
            listItem.appendChild(listText);

            if(hasPrimitiveWithTwoUVs)
            {
                listItem.appendChild(this.createDropdown(node.mesh, lightmaps, lightmapsToDelete));
            }
        } else {
            console.log("node children", node.children);

            let parentItem = document.createElement("li") as HTMLLIElement;
            parentItem.classList.add("list-item-parent");
            htmlParent.appendChild(parentItem);

            let parentItemSpan = document.createElement("span");
            let parentItemText = document.createElement("div");
            let parentItemIcon = document.createElement("img");
            parentItemSpan.classList.add("caret", "list-item");
            parentItemSpan.style.display = "flex";
            parentItemText.classList.add("list-text");
            parentItemIcon.classList.add("list-img");
            parentItemText.innerText = node.name;
            parentItemIcon.src = GLTF_PARENT_NODE_IMG_URI;
            parentItem.appendChild(parentItemSpan);
            parentItemSpan.appendChild(parentItemIcon);
            parentItemSpan.appendChild(parentItemText);


            if(hasPrimitiveWithTwoUVs)
            {
                parentItemSpan.appendChild(this.createDropdown(node.mesh, lightmaps, lightmapsToDelete));
            }

            let newLevel = document.createElement("ul") as HTMLUListElement;
            newLevel.classList.add("nested", "nested-lower");
            parentItem.appendChild(newLevel);

            for (let index of node.children) {

                let childNode = nodeList[index];
                this.recursiveAddNodeToList(newLevel, childNode, nodeList, lightmaps, lightmapsToDelete);
            }

        }
    }
    
    createDropdown(meshIndex: number, lightmaps, lightmapsToDelete): HTMLDivElement{
        let lightmapsDropdownGroup = document.createElement("div");
        lightmapsDropdownGroup.classList.add("btn-group", "add-component-group");
        let lightmapsDropdownToggle = document.createElement("button");
        lightmapsDropdownToggle.classList.add("btn", "component-toggle", "dropdown-toggle");
        lightmapsDropdownGroup.style.display = "grid";

        let currentLightmapIndex = this.lightMapsComponent.lightMapsData[meshIndex];

        let lightmapName = "None";
        if(currentLightmapIndex > -1 && currentLightmapIndex < lightmaps.length){
            lightmapName = lightmaps[currentLightmapIndex].fileName;
        }
        
        lightmapsDropdownToggle.innerText = lightmapsToDelete.includes(lightmapName) ? "None": lightmapName;
        lightmapsDropdownToggle.title = lightmapsDropdownToggle.innerText;
        lightmapsDropdownToggle.dataset.toggle = "tooltip";
        lightmapsDropdownToggle.dataset.placement = "top";

        lightmapsDropdownToggle.setAttribute("data-toggle", "dropdown");
        lightmapsDropdownToggle.setAttribute("type", "button");
        let lightmapsDropdownMenu = document.createElement("div");
        lightmapsDropdownMenu.classList.add("dropdown-menu");
        lightmapsDropdownMenu.style.left = "inherit";
        lightmapsDropdownMenu.style.right = "0%";
        lightmapsDropdownMenu.style.display = "none";
        
        lightmapsDropdownToggle.addEventListener("click", (e) => {
            if(lightmapsDropdownMenu.style.display == "none"){
                this.populateDropdown(lightmapsDropdownMenu, lightmapsDropdownToggle, meshIndex, lightmaps, lightmapsToDelete);
            }

            if(this.lastDropdownMenu && this.lastDropdownMenu != lightmapsDropdownMenu && this.lastDropdownMenu.style.display == "block"){
                this.lastDropdownMenu.style.display = "none";
            }

            lightmapsDropdownMenu.style.display = lightmapsDropdownMenu.style.display == "none" ? "block" : "none";
            this.lastDropdownMenu = lightmapsDropdownMenu;
            e.stopPropagation();
        });

        lightmapsDropdownGroup.appendChild(lightmapsDropdownToggle);
        lightmapsDropdownGroup.appendChild(lightmapsDropdownMenu);
        
        return lightmapsDropdownGroup;
    }


    populateDropdown(lightmapsDropdownMenu : HTMLDivElement, lightmapsDropdownToggle: HTMLButtonElement, meshIndex: number, lightmaps, lightmapsToDelete){
        lightmapsDropdownMenu.innerHTML = "";
        let lightmapDropdownItem = document.createElement("a");
        lightmapDropdownItem.classList.add("dropdown-item");
        lightmapDropdownItem.innerText = "None";
        lightmapDropdownItem.addEventListener("click", (e) => {
            lightmapsDropdownToggle.innerText = "None";
            lightmapsDropdownToggle.title = lightmapsDropdownToggle.innerText;
            this.lightMapsComponent.lightMapsData[meshIndex] = -1;
            this.lightMapsComponent.triggerOnChanged();          
            $(lightmapsDropdownMenu).toggle();
            e.stopPropagation();
        });
        lightmapsDropdownMenu.appendChild(lightmapDropdownItem);

        let dropdownItems = lightmaps;
        for (let index = 0; index < dropdownItems.length; index++) {
            if(!lightmapsToDelete.includes(dropdownItems[index].fileName)){
                let lightmapDropdownItem = document.createElement("a");
                lightmapDropdownItem.classList.add("dropdown-item");
                lightmapDropdownItem.innerText = dropdownItems[index].fileName;
                lightmapDropdownItem.addEventListener("click", (e) => {
                    lightmapsDropdownToggle.innerText = dropdownItems[index].fileName;
                    lightmapsDropdownToggle.title = lightmapsDropdownToggle.innerText;
                    this.lightMapsComponent.lightMapsData[meshIndex] = index;      
                    this.lightMapsComponent.triggerOnChanged();          
                    $(lightmapsDropdownMenu).toggle();
                    e.stopPropagation();
                })
                lightmapsDropdownMenu.appendChild(lightmapDropdownItem);
            }
        }
    }

    expandAllNestsAbove(selectedCaret: Element) {
        var nextNested = selectedCaret.parentElement.closest(".nested");

        if (nextNested !== null) {
            nextNested.classList.add("active");
            this.expandAllNestsAbove(nextNested);
        }
    }
}
import { CustomActionsRenderer } from "./customactionsrenderer";
import { CustomActionSettingsComponent } from "../componentsystems/NodeComponents/actionsettingscomponent";
import { CustomTriggerableComponentItemRenderer } from "./customtriggerablecomponentItemrenderer";
import { CustomVolumeTriggerComponent } from "../componentsystems/NodeComponents/volumetriggercomponent";
import { CustomBooleanInspector } from "./custombooleanrenderer";
import { ColliderEvent } from "../../utilities/constants";
import { Utils } from "../../utilities/utils";

export class CustomVolumeTriggerRenderer implements Vertex.UI.ICustomInspectorRenderer {
    triggerableComponentItemRenderers: CustomTriggerableComponentItemRenderer[] = [];
    
    constructor(targetNode: Vertex.NodeComponentModel.VertexNode) {
        this.targetNode = targetNode;
        this.customFieldEnabled = new CustomBooleanInspector(this.targetNode);
    }

    customFieldEnabled : CustomBooleanInspector;

    targetNode: Vertex.NodeComponentModel.VertexNode;
    target: CustomVolumeTriggerComponent;
    property: string;
    volumeTriggerPanel: HTMLDivElement;
    actionsRenderer: CustomActionsRenderer;
    actionSettingsComp: CustomActionSettingsComponent;

    static currentRenderer: CustomVolumeTriggerRenderer;

    get actionSettings(): CustomActionSettingsComponent {
        if (this.actionSettingsComp) {
            return this.actionSettingsComp;
        }

        let runtime = Vertex.Globals.runtime as Vertex.VertexRuntime;
        runtime.space.nodes.forEach(node => {
            const comp = node.getComponent("ActionSettings") as CustomActionSettingsComponent;
            
            if (comp) {
                this.actionSettingsComp = comp;
            }
        });

        return this.actionSettingsComp;
    }

    //Target appears to be the node but we also have just targetNode so not sure why it's needed.
    RenderProperty(property: string, target: any): HTMLDivElement {
        this.target = target;
        this.property = property;

        if (CustomVolumeTriggerRenderer.currentRenderer) {
            Vertex.Globals.event.off("ActionSettings:ActionAdded", CustomVolumeTriggerRenderer.currentRenderer.renderActionListeners);
            Vertex.Globals.event.off("ActionSettings:ActionUpdated", CustomVolumeTriggerRenderer.currentRenderer.renderActionListeners);
            CustomVolumeTriggerRenderer.currentRenderer.target.onChanged.off(CustomVolumeTriggerRenderer.currentRenderer.renderActionListeners);
        }

        this.volumeTriggerPanel = document.createElement('div');
        this.volumeTriggerPanel.innerText = "";

        this.renderEnabledState();
        this.renderEventsWithTriggers();
        this.renderActionListeners();

        CustomVolumeTriggerRenderer.currentRenderer = this;

        Vertex.Globals.event.on("ActionSettings:ActionAdded", this.renderActionListeners);
        Vertex.Globals.event.on("ActionSettings:ActionUpdated", this.renderActionListeners);

        this.target.onChanged.on(this.renderActionListeners);

        return this.volumeTriggerPanel;
    }

    renderEnabledState() {
        //render a switch for the enabled state so the user can choose if the trigger starts enabled or not in the space.
        const enabledStateDiv = this.customFieldEnabled.RenderProperty("enabled", this.target);
        enabledStateDiv.style.border = "none";

        this.volumeTriggerPanel.appendChild(enabledStateDiv);        
    }

    renderEventsWithTriggers = (() => {
        const self = this;

        const events = [ColliderEvent.OnTriggerEnter];

        this.triggerableComponentItemRenderers = [];

        for (let i = 0; i < events.length; i++) {
            const event = events[i];
            const eventProm = Promise.resolve(Utils.getHumanReadableString(ColliderEvent[event]));

            const triggerableRenderer = new CustomTriggerableComponentItemRenderer(self.target, self.actionSettings, i, eventProm, null);
            self.triggerableComponentItemRenderers.push(triggerableRenderer);
            
            const triggerableRendererElement = triggerableRenderer.RenderItem();
            self.volumeTriggerPanel.appendChild(triggerableRendererElement);
        }

    }).bind(this);

    renderActionListeners = (async ()=> {
        await this.actionSettings.isReady;

        if(this.actionsRenderer == null) {
            this.actionsRenderer = new CustomActionsRenderer();
        }

        const states = ["Disabled", "Enabled"];

        let actionListCardElement = this.actionsRenderer.RenderActions(this.target, this.actionSettings, states);
        this.volumeTriggerPanel.append(actionListCardElement);

        this.triggerableComponentItemRenderers.forEach((triggerableComponentItemRenderer, i) => {
            const isStarred = this.target.enabled == Boolean(i);
            triggerableComponentItemRenderer.RenderItem(isStarred);
        });
    }).bind(this);

    renderActionTriggers = (async ()=> {
        await this.actionSettings.isReady;
    }).bind(this);
}
import { XR_COPILOT_MODEL_SCOPE } from "../api/hevo-hub-api/common";
import { LicensingApi } from "../api/hevo-hub-api/licensing";
import { AssistantApi } from "../api/xr-copilot-api/assistant";
import { ResourceUtils } from "./resource-utilities";

export interface LicenseResponse {
    // todo: missing some props
    licenseStates: LicenseDictionary
}

export interface LicenseDictionary {
    [key: string]: LicenseValidity
}

export interface XRCopilotModelsSlots {
    totalSlots: number;
    activeSlots: number;
}

export enum LicenseValidity {
    Invalid = 0,
    Valid = 1,
}

export class LicenseUtils {


    static async validateLicense(licenseName: string, token?: string): Promise<boolean> {
        token = token ?? Vertex.Globals.bearerToken;

        const resp = await fetch(`https://${Vertex.Globals.vertexStackUrl}/store/validatelicense/?id=${licenseName}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        if (resp.ok) {
            let json = await resp.json();

            return json.licenseStates[licenseName];
        } else {
            console.warn("could not fetch resource: ", resp);
        }

        return null;
    }

    static async validateLicenses(...licenseNames: string[]): Promise<LicenseDictionary> {

        let params = licenseNames.map(x => encodeURIComponent(x)).join("&id=");

        // [Gabriele] inserting the licenseNames in the body of the request could be better?
        const resp = await fetch(`https://${Vertex.Globals.vertexStackUrl}/store/validatelicense/?id=${params}`, {
            headers: {
                "Authorization": `Bearer ${Vertex.Globals.bearerToken}`
            }
        })

        if (resp.ok) {
            let json = await resp.json() as LicenseResponse;

            return json.licenseStates;
        } else {
            console.warn("could not fetch resource: ", resp);
        }

        return null;
    }

    /** hacky way to check if we are a stack admin */
    static async validateStackAdmin(): Promise<boolean> {
        // make a request to the license API
        const res = await fetch(`https://${Vertex.Globals.vertexStackUrl}/store/license/`, {
            headers: {
                "Authorization": `Bearer ${Vertex.Globals.bearerToken}`
            }
        })

        return res.ok;
    }

    static async getXRCopilotModelSlots(token?: string): Promise<XRCopilotModelsSlots> {
        token = token ?? Vertex.Globals.bearerToken;

        if(!token){
            return;
        }

        let result = { totalSlots: 0, activeSlots: 0} as XRCopilotModelsSlots;

        const licenseResponse: string = await LicensingApi.checkLicense([XR_COPILOT_MODEL_SCOPE], token);

        if(!licenseResponse){
            return result;
        }

        if(licenseResponse.includes(XR_COPILOT_MODEL_SCOPE)){
            result.totalSlots = +licenseResponse.substring(XR_COPILOT_MODEL_SCOPE.length + 1);
        }

        result.activeSlots = await AssistantApi.getActiveIndexesCount(token) ?? 0;

        return result;
    }
}
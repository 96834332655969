import { TransformNode } from 'babylonjs';
import { AugmentedStoreAssembly } from '../../../../AugmentedStoreAssembly';
import { Utils } from '../../../utilities/utils';
import { NavMeshComponentView } from './navmeshcomponent';
import { Config } from '../../../../config';

export class CustomSpawnPointComponent extends AugmentedStoreAssembly.SpawnPointComponent {
    renderer: string = "";
    isReplacing: boolean = false;
    lastPosition = null;

    isPlacedOnNavmesh(rayLength: number = 2): boolean {
        const scene = Vertex.Globals.runtime.scene;
        const nodePos = this.node.viewNode.position;
        const rayOrigin = new BABYLON.Vector3(nodePos.x, nodePos.y + 0.02, nodePos.z);
        const ray = new BABYLON.Ray(rayOrigin, BABYLON.Vector3.Down(), rayLength);

        let predicate = function (mesh) {
            return BABYLON.Tags.MatchesQuery(mesh, "navmesh");
        }

        const picks = scene.multiPickWithRay(ray, predicate);

        return picks.length > 0;
    }

    isPlacedInWarpArea = ((): boolean => {
        const scene = Vertex.Globals.runtime.scene as BABYLON.Scene;
        const space = Vertex.Globals.runtime.space as Vertex.Space;

        const warpNodes = Array.from(space.nodes.values()).filter(node => node.components.includes("Warp"));
        
        let warpMeshes = [];

        warpNodes.forEach(node => {
            warpMeshes.push(...node.viewNode.getChildMeshes(false));
        });

        const capsuleCollider = /*scene.getMeshByName("collider") ??*/ BABYLON.MeshBuilder.CreateCapsule("collider", { height: 1.8, radius: 0.3, capSubdivisions: 10, subdivisions: 10, tessellation: 10 }, scene);
        capsuleCollider.position = this.node.viewNode.position.add(new BABYLON.Vector3(0, 0.8, 0));
        capsuleCollider.isVisible = false;

        for(let warpMesh of warpMeshes){
            if(capsuleCollider.intersectsMesh(warpMesh))
            {
                capsuleCollider.dispose();

                return true;
            }
        }

        capsuleCollider.dispose();

        return false;
    }).bind(this);

    handleReplace = ((pointerInfo: BABYLON.PointerInfo, event: BABYLON.EventState) => {
        const scene = Vertex.Globals.runtime.scene as BABYLON.Scene;
        const transform = this.node.getComponent("Transform") as Vertex.NodeComponentModel.TransformComponent;
        
        if (pointerInfo.type === BABYLON.PointerEventTypes.POINTERTAP) {
            if (pointerInfo && pointerInfo.pickInfo) {
                let hitInfo = pointerInfo.pickInfo.ray.intersectsMeshes(NavMeshComponentView.navMeshes as BABYLON.DeepImmutableObject<BABYLON.AbstractMesh>[]);

                if (hitInfo?.length) {
                    if (hitInfo[0].hit) {
                        scene.onPointerObservable.removeCallback(this.handleReplace);
                        this.isReplacing = false;
                        transform.triggerOnChanged();
                    }
                }
                
                event.skipNextObservers = true;
            }
        }

        if (pointerInfo.type === BABYLON.PointerEventTypes.POINTERMOVE) {
            if (pointerInfo && pointerInfo.pickInfo) {
                let hitInfo = pointerInfo.pickInfo.ray.intersectsMeshes(NavMeshComponentView.navMeshes as BABYLON.DeepImmutableObject<BABYLON.AbstractMesh>[]);
                
                if (hitInfo?.length) {
                    if (hitInfo[0].hit) {
                        this.node.viewNode.visibility = 0.7;
                        transform.position = hitInfo[0].pickedPoint.asArray();
                    }
                    else {
                        this.node.viewNode.visibility = 0.000001;
                    }
                }
                else {
                    this.node.viewNode.visibility = 0.000001;
                }
            }
        }
    }).bind(this);

    
    toggleReplace() {
        this.isReplacing = !this.isReplacing;
        const scene = Vertex.Globals.runtime.scene as BABYLON.Scene;
        const transform = this.node.getComponent("Transform") as Vertex.NodeComponentModel.TransformComponent;
        scene.onPointerObservable.removeCallback(this.handleReplace);
        if(this.isReplacing)
        {
            this.lastPosition = transform.position;
            scene.onPointerObservable.add(this.handleReplace, undefined, true);
        }
        else if(this.lastPosition)
        {
            transform.position = this.lastPosition;
        }
    }
}


export class SpawnPointComponentView extends Vertex.NodeComponentModel.ComponentViewBase {

    constructor() {
        super();
    }

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        const comp = component as CustomSpawnPointComponent;

        const guid = Config.SPAWNPOINT_ID;
        const meshUrl = "https://" + Vertex.Globals.vertexStackUrl + "/core/resource/" + guid + "/mesh.gltf";
        const scene = Vertex.Globals.runtime.scene;

        let loader = BABYLON.SceneLoader.LoadAssetContainer(meshUrl, "", scene, function (container) {
            let rootMesh = container.createRootMesh();
            scene.addMesh(rootMesh, true);
            rootMesh.parent = node.viewNode;
            rootMesh.setPositionWithLocalVector(new BABYLON.Vector3(0, 0, 0));
        });

        Vertex.Globals.event.on("togglePreviewMode", (previewMode) => {

            const meshes = (node.viewNode as TransformNode).getChildMeshes(false);
            meshes.forEach(mesh => {
                mesh.isVisible = !previewMode;
            })
        });
    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
    }

}

export class SpawnPointComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new CustomSpawnPointComponent();
    }

    constructor() {

        super("SpawnPoint", new SpawnPointComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}

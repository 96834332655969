import Swal from 'sweetalert2';
import { Utils } from '../../../utilities/utils';
import { Action, CustomActionSettingsComponent } from '../NodeComponents/actionsettingscomponent';
import { SYSTEM_ACTION_PREFIX } from '../../../utilities/constants';


export class ActionSettingsPanelComponent extends Vertex.NodeComponentModel.Component {

    writeData(writer: Vertex.BinaryWriter): void {
    }
    readData(reader: Vertex.BinaryReader): void {
    }

}

export class ActionSettingsPanelView extends Vertex.NodeComponentModel.ComponentViewBase {

    constructor() {
        super();
    }

    container: HTMLDivElement;
    panel: HTMLDivElement;
    list: HTMLUListElement;

    comp: ActionSettingsPanelComponent;
    actionSettingsComp: CustomActionSettingsComponent;

    async addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        const self = this;
        this.comp = component as ActionSettingsPanelComponent;

        this.container = document.querySelector(".container-overlay-left");
        this.container.classList.remove("hidden");

        let card = document.createElement("div");
        card.classList.add("card", "pointer-enable");
        card.id = "action-settings-panel";

        let header = document.createElement("div");
        header.classList.add("nav-link", "card-header-minimizable", "dark-text", "max");

        let headerText = document.createElement("div");
        headerText.classList.add("card-header-text");
        headerText.innerText = "Actions";

        let body = document.createElement("div");
        body.classList.add("card-body", "p-0");
        const panelTemplate = document.getElementById("action-list-panel-template") as HTMLTemplateElement;
        self.panel = panelTemplate.content.firstElementChild.cloneNode(true) as HTMLDivElement;
        self.list = self.panel.querySelector("#action-list") as HTMLUListElement;

        // Grid Area
        let leftSidebar = document.querySelector(".left-sidebar-grid");

        // Append
        leftSidebar.appendChild(card);
        card.appendChild(header);
        header.appendChild(headerText);
        card.appendChild(body);
        body.appendChild(this.panel);

        // Utils.setupSidebarButton CHECKS FOR -panel postfix its own !!!!!!
        const sidebarButton = Utils.setupSidebarButton("ActionSettingsPanel", "action-settings-panel");

        Utils.waitForCondition(_ => Array.from(
            Vertex.Globals.getRuntime<VertexBabylon.VertexBabylonRuntime>().space.nodes.values())
            .find(node => node.components.includes("ActionSettings")))
            .then(async _ => {
                const actionSettingsNode = Array.from(
                    Vertex.Globals.getRuntime<VertexBabylon.VertexBabylonRuntime>().space.nodes.values())
                    .find(node => node.components.includes("ActionSettings"));

                self.actionSettingsComp = actionSettingsNode.getComponent("ActionSettings") as CustomActionSettingsComponent;

                await self.actionSettingsComp.isReady;
                self.drawList();
                self.actionSettingsComp.onChanged.on(self.drawList);
            });

        Vertex.Globals.event.on("ActionSettings:ActionAssignmentUpdated", self.drawList);
        Vertex.Globals.event.on("component:componentRemoved", self.drawList);
    }

    drawList = (() => {
        const self = this;

        self.list.innerHTML = "";
        const itemTemplate = document.getElementById("action-list-item-template") as HTMLTemplateElement;

        for (let i = 0; i < this.actionSettingsComp.actions.length; i++) {
            const action = this.actionSettingsComp.actions[i];

            if(action.actionName.startsWith(SYSTEM_ACTION_PREFIX)){
                // Skip system actions to keep them hidden
                continue;
            }

            const item = itemTemplate.content.firstElementChild.cloneNode(true) as HTMLDivElement;
            
            const injectables = item.querySelectorAll<HTMLImageElement>("img.injectable");
            Utils.injectSvg(injectables).then(_ => {
                self.updateActionAssignedIndicator(i)
            });

            item.id = `action-list-item-${i}`;

            const itemInput = item.querySelector("input") as HTMLInputElement;
            itemInput.value = action.actionName;

            const renameAction = (async () => {
                //remove all occurences of SYSTEM_ACTION_PREFIX from the input value
                itemInput.value = itemInput.value.replace(new RegExp(SYSTEM_ACTION_PREFIX, "g"), "");
                itemInput.value = Utils.sanitizeString(itemInput.value.trim());

                if (itemInput.value) {
                    const existingIndex = self.actionSettingsComp.actions.findIndex(action => action.actionName === itemInput.value);

                    if (existingIndex === -1) {
                        if (self.actionSettingsComp.actions[i].actionName !== itemInput.value) {
                            await self.actionSettingsComp.updateAction(i, itemInput.value);
                        }
                    }
                    else if (existingIndex !== i) {
                        Swal.fire({
                            icon: "warning",
                            title: `An Action named<br>${itemInput.value}<br>already exist`,
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            showConfirmButton: true,
                            heightAuto: false,
                            width: "auto"
                        });

                        itemInput.value = self.actionSettingsComp.actions[i].actionName;
                    }
                }
                else {
                    Swal.fire({
                        icon: "warning",
                        title: "The Action name can not be empty",
                        html: "If you want to delete the Action, please use the Delete button",
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                        heightAuto: false,
                    });

                    itemInput.value = self.actionSettingsComp.actions[i].actionName;
                }
            });

            itemInput.addEventListener("focusout", renameAction);
            
            itemInput.addEventListener("keydown", async (ev: KeyboardEvent) => {
                if (ev.key == "Enter" && itemInput.value !== "") {
                    await renameAction();
                }
            });

            const itemDeleteBtn = item.querySelector(".action-list-delete-btn") as HTMLButtonElement;

            itemDeleteBtn.addEventListener('click', () => {
                self.tryDeleteAction(i);
            });

            this.list.appendChild(item);
            self.updateActionAssignedIndicator(i);
            const spacer = item.querySelector("div") as HTMLDivElement; 
            spacer.style.visibility = "visible";
        }

        const empty = this.addEmptyElement(itemTemplate);

        this.list.appendChild(empty);
    }).bind(this);

    updateActionAssignedIndicator(index: number) {
        const self = this;
        const componentsUsingAction = self.getComponentsUsingAction(index);
        const isActionAssigned = componentsUsingAction.length > 0;

        const actionAssignedIndicator = document.querySelector(`#action-list-item-${index} .btn`) as HTMLButtonElement; 
        actionAssignedIndicator.classList.toggle("text-secondary", !isActionAssigned);
        actionAssignedIndicator.classList.toggle("text-info", isActionAssigned);
        actionAssignedIndicator.style.backgroundColor = isActionAssigned ? "#e8f6f8" : "lightgrey";   
        
        const indicatorSvg = actionAssignedIndicator.querySelector("svg");
        
        if(indicatorSvg){
            const path = indicatorSvg.querySelector("path");
            path.style.fill = "none";
            path.style.stroke = isActionAssigned ? "#17A2B8" : "#646c77";
            actionAssignedIndicator.style.visibility = "visible";
        }
    }

    addEmptyElement(itemTemplate: HTMLTemplateElement) {
        const self = this;

        const empty = itemTemplate.content.firstElementChild.cloneNode(true) as HTMLDivElement;

        const emptyInput = empty.querySelector("input") as HTMLInputElement;

        const emptyDeleteBtn = empty.querySelector(".action-list-delete-btn") as HTMLButtonElement;
        emptyDeleteBtn.style.visibility = "hidden";

        let onFocusOut = async () => {
            //remove all occurences of SYSTEM_ACTION_PREFIX from the input value
            emptyInput.value = emptyInput.value.replace(new RegExp(SYSTEM_ACTION_PREFIX, "g"), "");
            emptyInput.value = Utils.sanitizeString(emptyInput.value.trim());

            if (emptyInput.value) {
                if (self.actionSettingsComp.actions.findIndex(action => action.actionName == emptyInput.value) == -1) {
                    await self.actionSettingsComp.addAction(emptyInput.value);
                }
                else {
                    Swal.fire({
                        icon: "warning",
                        title: `An Action named<br>${emptyInput.value}<br>already exist`,
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                        heightAuto: false,
                        width: "auto"
                    });

                    emptyInput.value = "";
                }
            }
        };

        emptyInput.addEventListener("focusout", onFocusOut);

        emptyInput.addEventListener("keydown", async (ev: KeyboardEvent) => {
            //remove all occurences of SYSTEM_ACTION_PREFIX from the input value
            emptyInput.value = emptyInput.value.replace(new RegExp(SYSTEM_ACTION_PREFIX, "g"), "");
            emptyInput.value = Utils.sanitizeString(emptyInput.value);

            if (ev.key == "Enter") {
                emptyInput.value = emptyInput.value.trim();

                if (emptyInput.value) {
                    if (self.actionSettingsComp.actions.findIndex(action => action.actionName == emptyInput.value) == -1) {
                        emptyInput.removeEventListener("focusout", onFocusOut);
                        
                        await self.actionSettingsComp.addAction(emptyInput.value);
                    }
                    else {
                        Swal.fire({
                            icon: "warning",
                            title: `An Action named<br>${emptyInput.value}<br>already exist`,
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            showConfirmButton: true,
                            heightAuto: false,
                            width: "auto"
                        });
    
                        emptyInput.value = "";
                    }
                }
            }
        });

        return empty;
    }

    getComponentsUsingAction(index: number) {
        const self = this;
        const runtime = Vertex.Globals.runtime as Vertex.VertexRuntime;
        const space = runtime.space as Vertex.Space;
        const nodes = Array.from(space.nodes.values());
        const componentsUsingAction = [];

        nodes.forEach((node) => {
            node.components.forEach((componentName) => {
                const component = node.getComponent(componentName);
                if (self.actionSettingsComp.instanceOfITriggerActionComponent(component)) {
                    const actionUsedByListener = component.actionIndexes.findIndex((actionIndex) => actionIndex == index) != -1;
                    const actionUsedByTrigger = component.triggerActionIndexes.findIndex((actionIndex) => actionIndex == index) != -1;

                    if (actionUsedByListener || actionUsedByTrigger) {
                        componentsUsingAction.push(component);
                    }
                }
            });
        });
        return componentsUsingAction;
    }

    async tryDeleteAction(index: number) {
        const self = this;
        const componentsUsingAction = self.getComponentsUsingAction(index);

        if(componentsUsingAction.length > 0){
            const message = `The Action "${self.actionSettingsComp.actions[index].actionName}" is used by ${componentsUsingAction.length} component(s).<br>Please remove the Action from the component(s) before deleting it.`;
            Swal.fire({
                icon: "warning",
                title: "Can't delete Action",
                html: message,
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: true,
                heightAuto: false,
            });
        }
        else{
            Swal.fire({
                icon: 'warning',
                title: `Are you sure you want to delete the action<br>${self.actionSettingsComp.actions[index].actionName}<br>?`,
                confirmButtonText: 'Delete',
                confirmButtonColor: 'red',
                showCancelButton: true,
                heightAuto: false,
                width: "auto"
            }).then(async response => {
                if (response.isConfirmed) {
                    await self.actionSettingsComp.deleteAction(index);
                }
            });
        }
    }

    // async deleteAction(index: number) {
    //     this.actionSettingsComp.actions.splice(index, 1);
    //     Vertex.Globals.event.fire("ActionSettings:ActionDeleted", index);
    //     this.drawList();
    //     await this.actionSettingsComp.postComponentJsonToResource();
    //     this.actionSettingsComp.triggerOnChanged();
    // }

    // async updateAction(index: number, value: string) {
    //     this.actionSettingsComp.actions[index].actionName = value;
    //     Vertex.Globals.event.fire("ActionSettings:ActionUpdated", { index, value });
    //     this.drawList();
    //     await this.actionSettingsComp.postComponentJsonToResource();
    //     this.actionSettingsComp.triggerOnChanged();
    // }

    // async addAction(value: string) {
    //     const action: Action = { actionName: value };
    //     this.actionSettingsComp.actions.push(action);
    //     const index = this.actionSettingsComp.actions.length - 1;

    //     Vertex.Globals.event.fire("ActionSettings:ActionAdded", { index, value });

    //     this.drawList();

    //     await this.actionSettingsComp.postComponentJsonToResource();
        
    //     this.actionSettingsComp.triggerOnChanged();
    // }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        //
    }
}

export class ActionSettingsPanelComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {

    public create(): Vertex.NodeComponentModel.Component {
        return new ActionSettingsPanelComponent();
    }
    constructor() {
        super("ActionSettingsPanel", new ActionSettingsPanelView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }

}
import { AugmentedStoreAssembly } from "../../../../AugmentedStoreAssembly";
import { ALLOWED_VIDEO_TYPES } from "../../../utilities/constants";
import { getComponentJsonFromResource, postComponentJsonToResource } from "../../../utilities/utils";
import { IValidable } from "../EditorComponents/scenevalidator";

export class SplashElement{
    name: string;
    isLoop: boolean;
}

export class CustomIntroSplashComponent  extends AugmentedStoreAssembly.IntroSplashComponent implements IValidable {

    isValidableComponent: boolean = true;

    isReady: Promise<void>;
    splashElements: SplashElement[] = [];

    async getUsedFiles(): Promise<string[]> {
        await this.isReady;

        let usedFiles = [];

        this.splashElements.forEach(element => {
            if(element && element.name){
                const extension = element.name.substring(element.name.lastIndexOf(".") + 1);

                if (ALLOWED_VIDEO_TYPES.includes(extension)){
                    let fileName = element.name.substring(
                        0,
                        element.name.lastIndexOf(".")
                    );

                    //TODO: check in media conversion in stil going on, or maybe need to retrigger it
                    usedFiles.push(fileName + ".mp4");
                    usedFiles.push(fileName + ".webm");
                }
                else{
                    usedFiles.push(element.name);
                }
            }
        });

        return usedFiles;
    }


    removeMissingFile = (async (fileName: string) => {
        await this.isReady;

        const elementIndex = this.splashElements.findIndex(element => element.name == fileName);

        if (elementIndex > -1) {
            this.splashElements.splice(elementIndex, 1);
        }

        //Vertex.Globals.event.fire("hevolus:IntroSplashChanged", this);
        this.postComponentJsonToResource(true);
        this.triggerOnChanged();

    }).bind(this);
    
    postComponentJsonToResource = (async (isEditorVersion: boolean = true) => {
        await postComponentJsonToResource(Vertex.Globals.spaceId, "IntroSplash", this.node.id, isEditorVersion, JSON.stringify(this.splashElements));
    }).bind(this);
}

export class IntroSplashComponentView extends Vertex.NodeComponentModel.ComponentViewBase {
    constructor() {
        super();
    }

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        const customComp = component as CustomIntroSplashComponent;
        
        customComp.isReady = new Promise<void>(async (resolve, reject) => {
            let res = await getComponentJsonFromResource(Vertex.Globals.spaceId, "IntroSplash", node.id, true);
            
            if (res.ok) {
                customComp.splashElements = await res.json() as SplashElement[];
            }
            else {
                res = await getComponentJsonFromResource(Vertex.Globals.spaceId, "IntroSplash", node.id, false);
                if (res.ok) {
                    customComp.splashElements = await res.json() as SplashElement[];
                    await customComp.postComponentJsonToResource(true);
                } else {
                    customComp.splashElements = [];
                    await customComp.postComponentJsonToResource(true);
                }
            }

            const beforeSaveSpace = async () => {
                await customComp.postComponentJsonToResource(false);
            }

            customComp.onRemoved.on(async() => {
                Vertex.Globals.event.off("hevolus:beforeSaveSpace", beforeSaveSpace);
                customComp.splashElements = [];
                await customComp.postComponentJsonToResource(true);
            });

            Vertex.Globals.event.on("hevolus:beforeSaveSpace", beforeSaveSpace);
            
            resolve();
        });
    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
    }

    update(): void {
    }
}

export class IntroSplashComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new CustomIntroSplashComponent;
    }

    constructor() {        
        super("IntroSplash", new IntroSplashComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}
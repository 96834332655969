import { RESOURCE_API_URI, DEFAULT_OPTIMIZATION_PRESET, HEVOLUS_HVERSE_3DCONVERTER_LICENSE, HEVOLUS_HVERSE_3DOPTIMIZER_LICENSE, MESH_GLTF_FILENAME, OPTIMIZATION_PRESETS, ORIGINAL_OPTIMIZATION_NAME, ORIGINAL_OPTIMIZATION_PARAMS, STEP2GLTF_CONVERTER_LICENSE, STEPS_NEEDING_OPTIMIZATION_LICENSE, OPTIMIZATION_PRESETS_NAMES, OPTIMIZATION_OPTIONS_NAMES, DEFAULT_PROJECT_PARAMS, MeasurementUnit, OptimizationParams, OptimizationPanelStep, OptimizationPreset } from "./constants";
import { cloneDeep, isEqual } from "lodash";
import Swal from "sweetalert2";
import { ConversionStatus, CreateOneOptimizationInput, GltfExportFormats, Optimization, OptimizationFilter, OptimizationOutputFormat, OptimizationParamsInput, OptimizationStatus, Project, ProjectFilter, ProjectParams, PublicationMode, PublicationTarget } from "../api/amaz3d/amaz3d-types";
import { Amaz3dApi } from "../api/amaz3d/amaz3d-api";
import { Status } from "./conversion-utilities";
import { UploadUtils } from "./upload-utilities";
import { Utils } from "./utils";
import { LicenseUtils, LicenseValidity } from "./license-utilities";
import { ResourceUtils } from "./resource-utilities";
import { GltfStructure } from "./gltf-utilities";
import { TelemetryEvent, TelemetryUtils } from "./telemetry-utilities";
import { Config } from "../../config";


export class OptimizationUtils {


    private static optimizationPreset: OptimizationParams;
    private static optimizationParams: OptimizationParams;

    private static optimizationName: string;
    private static advancedOptimizationEnabled: boolean;

    private static has3DConverterLicense: boolean;
    private static has3DOptimizerLicense: boolean;

    private static modelFile: string;
    private static modelExtension: string;

    private static isConversionEnabled: boolean;
    private static isOptimizationEnabled: boolean;

    private static isYup: boolean = undefined;
    private static measurementUnit: MeasurementUnit = MeasurementUnit.None;

    private static waitForCompletion: boolean;
    private static uploadResultToVertexResource: boolean;

    private static getSwalSteps(): OptimizationPanelStep[]{
        const steps: OptimizationPanelStep[] = [];

        if(this.isConversionEnabled){
            steps.push(OptimizationPanelStep.Conversion);
        }

        if(this.modelFile){
            steps.push(OptimizationPanelStep.Optimization);
            steps.push(OptimizationPanelStep.Advanced);
        }
        
        steps.push(OptimizationPanelStep.Confirm);

        return steps;
    }

    private static getSwalStepsLabels(steps: OptimizationPanelStep[]): string[]{
        const stepsLabels = Array.from(steps, (step, index) => {
            const stepName = OptimizationPanelStep[step];
 
            if(STEPS_NEEDING_OPTIMIZATION_LICENSE.includes(step)){
                if(!this.has3DOptimizerLicense)
                {
                    const img = document.createElement("img");
                    img.src = "/img/lock.svg";
                    img.style.width = "1rem";
                    img.style.height = "1rem";
                    img.style.position = "absolute";
                    img.style.top = "0.5rem";
                    img.style.filter = "invert(1)";

                    return `${img.outerHTML}<br>${stepName}`;
                }
 
                if(!this.isOptimizationEnabled || (!this.advancedOptimizationEnabled && step === OptimizationPanelStep.Advanced))
                {
                    const backgroundDiv = document.createElement("div");
                    backgroundDiv.classList.add("swal2-progress-step");
                    backgroundDiv.style.background = "#dbdbdb";
                    backgroundDiv.style.position = "absolute";
                    backgroundDiv.style.zIndex = "-1";

                    const label = document.createElement("span");
                    label.innerHTML = `${index+1}<br>${stepName}`;
                    label.style.filter = "blur(1px)";

                    return `${backgroundDiv.outerHTML}${label.outerHTML}`;
                }
            }
 
            return `${index+1}<br>${stepName}`;
        });
 
        return stepsLabels;
    }

    private static async showSwalStep(currentStep: OptimizationPanelStep): Promise<Status> {
        let steps: OptimizationPanelStep[] = OptimizationUtils.getSwalSteps();
        let progressSteps: string[] = OptimizationUtils.getSwalStepsLabels(steps);
        let optimizationResult = Status.None;

        let nextStep: OptimizationPanelStep = steps.find(step => step > currentStep && 
            ((this.has3DOptimizerLicense && this.isOptimizationEnabled) || !STEPS_NEEDING_OPTIMIZATION_LICENSE.includes(step)) &&
            ((this.advancedOptimizationEnabled || step !== OptimizationPanelStep.Advanced)));
        let prevStep: OptimizationPanelStep = [...steps].reverse().find(step => step < currentStep && 
            ((this.has3DOptimizerLicense && this.isOptimizationEnabled) || !STEPS_NEEDING_OPTIMIZATION_LICENSE.includes(step)) &&
            ((this.advancedOptimizationEnabled || step !== OptimizationPanelStep.Advanced)));

        let swal = Swal.mixin({
            title: OptimizationPanelStep[currentStep],
            progressSteps: progressSteps,
            currentProgressStep: `${steps.indexOf(currentStep)}`,

            // optional class to show fade-in backdrop animation which was disabled in Queue mixin
            showClass: { backdrop: 'swal2-noanimation' },
            hideClass: { backdrop: 'swal2-noanimation' },
            customClass: {
                header: 'custom-swal2-progress-header',
                actions: 'custom-swal2-actions',
            },
            width: '40%',

            confirmButtonText: 'Next',
            showConfirmButton: true,

            denyButtonText: prevStep ? 'Back' : null,
            showDenyButton: Boolean(prevStep),

            showCancelButton: true,

            reverseButtons: true,

            showCloseButton: true, 

            allowOutsideClick: false,
            allowEscapeKey: false
        });
        
        if (currentStep === OptimizationPanelStep.Conversion) {
            const container = document.createElement("div") as HTMLDivElement;

            if(this.modelFile)
            {
                const modellop = document.createElement("p");
                modellop.style.margin = "0.5rem !important";
                modellop.innerHTML = "3D Model: " + this.modelFile;
                container.appendChild(modellop);
                
                if(this.modelExtension === "stp"){
                    this.measurementUnit = MeasurementUnit.Millimeters;
                    this.isYup = false;

                    const yupCheckbox = document.createElement("input") as HTMLInputElement;
                    yupCheckbox.setAttribute("type", "checkbox");
                    yupCheckbox.style.width = "1.25rem";
                    yupCheckbox.style.height = "1.25rem";
                    yupCheckbox.style.alignSelf = "center";
                    yupCheckbox.checked = false;
                    yupCheckbox.addEventListener('change', (event) => {
                        this.isYup = yupCheckbox.checked;
                    });

                    const p = document.createElement("p");
                    p.innerHTML = "Exported with Y up?";
                    p.classList.add("mr-2");

                    const yupCheckboxWrapper = document.createElement("div") as HTMLDivElement;
                    yupCheckboxWrapper.classList.add("d-flex", "flex-row", "justify-content-center");
                    yupCheckboxWrapper.style.margin = "0.5rem";

                    yupCheckboxWrapper.appendChild(p);
                    yupCheckboxWrapper.appendChild(yupCheckbox);

                    container.appendChild(yupCheckboxWrapper);

                    //TODO: add UI elements to ask the user if the model has been exported with mm, cm or m as unit of measure.  mm will be the default.
                    const unitOfMeasureWrapper = document.createElement("div") as HTMLDivElement;
                    unitOfMeasureWrapper.classList.add("d-flex", "flex-row", "justify-content-center");
                    unitOfMeasureWrapper.style.margin = "0.5rem";

                    const unitOfMeasureSelect = document.createElement("select") as HTMLSelectElement;
                    unitOfMeasureSelect.classList.add("form-control");
                    unitOfMeasureSelect.style.width = "100%";
                    unitOfMeasureSelect.style.maxWidth = "5rem";
                    unitOfMeasureSelect.style.textAlign = "center";
                    unitOfMeasureSelect.style.fontSize = "1rem";
                    unitOfMeasureSelect.style.fontWeight = "400";
                    unitOfMeasureSelect.style.lineHeight = "1.5";
                    unitOfMeasureSelect.style.color = "#212529";
                    unitOfMeasureSelect.style.backgroundColor = "#fff";
                    unitOfMeasureSelect.style.backgroundClip = "padding-box";
                    unitOfMeasureSelect.style.border = "1px solid #ced4da";
                    unitOfMeasureSelect.style.borderRadius = ".25rem";
                    unitOfMeasureSelect.style.transition = "border-color .15s ease-in-out,box-shadow .15s ease-in-out";
                    unitOfMeasureSelect.style.boxShadow = "none";
                    unitOfMeasureSelect.style.outline = "none";

                    const mmOption = document.createElement("option") as HTMLOptionElement;
                    mmOption.value = MeasurementUnit.Millimeters;
                    mmOption.text = "mm";
                    unitOfMeasureSelect.appendChild(mmOption);

                    const cmOption = document.createElement("option") as HTMLOptionElement;
                    cmOption.value = MeasurementUnit.Centimeters;
                    cmOption.text = "cm";
                    unitOfMeasureSelect.appendChild(cmOption);

                    const mOption = document.createElement("option") as HTMLOptionElement;
                    mOption.value = MeasurementUnit.Meters;
                    mOption.text = "m";
                    unitOfMeasureSelect.appendChild(mOption);

                    const unitOfMeasureLabel = document.createElement("label") as HTMLLabelElement;
                    unitOfMeasureLabel.innerHTML = "Unit of measure";
                    unitOfMeasureLabel.htmlFor = "unitOfMeasureSelect";
                    unitOfMeasureLabel.style.textAlign = "center";
                    unitOfMeasureLabel.style.color = "#212529";
                    unitOfMeasureLabel.style.margin = "0.5rem";
                    unitOfMeasureLabel.style.maxWidth = "15rem";
                    unitOfMeasureLabel.style.display = "block";

                    unitOfMeasureWrapper.appendChild(unitOfMeasureLabel);
                    unitOfMeasureWrapper.appendChild(unitOfMeasureSelect);

                    unitOfMeasureSelect.addEventListener('change', (event) => {
                        this.measurementUnit = unitOfMeasureSelect.value as MeasurementUnit;
                    });

                    container.appendChild(unitOfMeasureWrapper);
                }

                const checkboxContainer = document.createElement("div") as HTMLDivElement;
                checkboxContainer.classList.add("d-flex", "flex-row", "justify-content-center");
                checkboxContainer.style.margin = "0.5rem";

                const p = document.createElement("p");
                p.innerHTML = "Do you want to optimize the model?";
                p.classList.add("mr-2");
                checkboxContainer.appendChild(p);
    
                const optimizeCheckboxWrapper = document.createElement("div") as HTMLDivElement;
                checkboxContainer.appendChild(optimizeCheckboxWrapper);
                
                const optimizeCheckbox = document.createElement("input") as HTMLInputElement;
                optimizeCheckbox.setAttribute("type", "checkbox");
                optimizeCheckbox.style.width = "1.25rem";
                optimizeCheckbox.style.height = "1.25rem";
                optimizeCheckbox.style.alignSelf = "center";

                optimizeCheckbox.checked = this.isOptimizationEnabled;
                optimizeCheckboxWrapper.appendChild(optimizeCheckbox);
    
                container.appendChild(checkboxContainer);

                if(!this.has3DOptimizerLicense)
                {
                    optimizeCheckbox.disabled = true;
                    optimizeCheckbox.style.pointerEvents = "none";
                    optimizeCheckboxWrapper.id = "optimize-checkbox-wrapper";
                    optimizeCheckboxWrapper.dataset.toggle = "tooltip";
                    optimizeCheckboxWrapper.dataset.placement = "bottom";
                    optimizeCheckboxWrapper.dataset.title = "You need a 3D Optimizer license to enable this option";
                }
                else
                {
                    optimizeCheckbox.disabled = false;
                }

                optimizeCheckbox.addEventListener('change', (event) => {
                    this.isOptimizationEnabled = !this.isOptimizationEnabled ;
                    progressSteps = OptimizationUtils.getSwalStepsLabels(steps);
                    nextStep = steps.find(step => step > currentStep && 
                        ((this.has3DOptimizerLicense && this.isOptimizationEnabled) || !STEPS_NEEDING_OPTIMIZATION_LICENSE.includes(step)) &&
                        ((this.advancedOptimizationEnabled || step !== OptimizationPanelStep.Advanced)));
                    prevStep = [...steps].reverse().find(step => step < currentStep && 
                        ((this.has3DOptimizerLicense && this.isOptimizationEnabled) || !STEPS_NEEDING_OPTIMIZATION_LICENSE.includes(step)) &&
                        ((this.advancedOptimizationEnabled || step !== OptimizationPanelStep.Advanced)));
                    swal.update({progressSteps: progressSteps});
                })
            }

            swal = swal.mixin({
                html: container, 
                showDenyButton: false,
                showCancelButton: true,
                didOpen: () => {
                    ($("#optimize-checkbox-wrapper") as any).tooltip();
                }
            });
        }
        else if (currentStep === OptimizationPanelStep.Optimization) {
            const template = document.querySelector("#optimization-settings-template") as HTMLTemplateElement;
            const contents = template.content.firstElementChild.cloneNode(true) as HTMLElement;

            const optimizationPresetContainer = contents.querySelector("#optimization-preset-container") as HTMLDivElement;
            const optimizationPresetRadioButtons = optimizationPresetContainer.querySelectorAll("input");

            optimizationPresetRadioButtons.forEach(btn => {
                const optimizationIndex = OPTIMIZATION_PRESETS.findIndex(p => isEqual(this.optimizationPreset, p));
                btn.checked = Number.parseInt(btn.value) === optimizationIndex;
            });

            optimizationPresetRadioButtons.forEach(btn => {
                if(isEqual(this.optimizationParams, OPTIMIZATION_PRESETS[Number.parseInt(btn.value)])){
                    btn.checked = true;
                    OptimizationUtils.selectPreset(OPTIMIZATION_PRESETS[Number.parseInt(btn.value)]);
                }
                else{
                    btn.checked = false;
                }
            });

            optimizationPresetRadioButtons.forEach(btn => {
                btn.addEventListener("click", () => {
                    OptimizationUtils.selectPreset(OPTIMIZATION_PRESETS[Number.parseInt(btn.value)]);

                    polygonReductionSlider.value = this.optimizationParams.polygonReduction.toString();
                    polygonReductionSliderValue.innerText = `${polygonReductionSlider.value}%`;
                    optimizationNameInput.placeholder = `Opt ${polygonReductionSlider.value}%`;

                    advancedOptionToggle.checked = this.advancedOptimizationEnabled;
                });
            });

            const polygonReductionContainer = contents.querySelector("#polygon-reduction-container") as HTMLDivElement;
            const polygonReductionSlider = polygonReductionContainer.querySelector("input") as HTMLInputElement;
            const polygonReductionSliderValue = polygonReductionContainer.querySelector(".optimization-slider-value") as HTMLSpanElement;
            polygonReductionSlider.value = this.optimizationParams.polygonReduction.toString();
            polygonReductionSliderValue.innerText = `${polygonReductionSlider.value}%`;

            const optimizationNameContainer = contents.querySelector("#optimization-name-container") as HTMLDivElement;
            const optimizationNameInput = optimizationNameContainer.querySelector("input") as HTMLInputElement;
            optimizationNameInput.value = this.optimizationName;
            optimizationNameInput.placeholder = `Opt ${polygonReductionSlider.value}%`;

            optimizationNameInput.addEventListener("change", () => {
                if(optimizationNameInput.maxLength != null && optimizationNameInput.value.length > optimizationNameInput.maxLength){
                    optimizationNameInput.value = optimizationNameInput.value.substring(0, optimizationNameInput.maxLength);
                }

                optimizationNameInput.value = Utils.sanitizeString(optimizationNameInput.value.trim());
                
                if(optimizationNameInput.value === ORIGINAL_OPTIMIZATION_NAME){
                    optimizationNameInput.value += ` - ${polygonReductionSlider.value}%`
                }

                this.optimizationName = optimizationNameInput.value;
            });

            polygonReductionSlider.addEventListener("input", () => {
                this.optimizationParams.polygonReduction = Number.parseInt(polygonReductionSlider.value);
                polygonReductionSliderValue.innerText = `${polygonReductionSlider.value}%`;

                optimizationNameInput.placeholder = `Opt ${polygonReductionSlider.value}%`;
            });


            const advancedOptionToggle = contents.querySelector("#advanced-options-toggle") as HTMLInputElement;
            advancedOptionToggle.checked = this.advancedOptimizationEnabled;
            advancedOptionToggle.addEventListener("click", () => {
                this.advancedOptimizationEnabled = !this.advancedOptimizationEnabled;

                if(!this.advancedOptimizationEnabled)
                {
                    this.optimizationPreset = DEFAULT_OPTIMIZATION_PRESET;
                    this.optimizationParams = cloneDeep(this.optimizationPreset);

                    optimizationPresetRadioButtons.forEach(btn => {
                        if(isEqual(this.optimizationPreset, OPTIMIZATION_PRESETS[Number.parseInt(btn.value)])){
                            btn.checked = true;
                            this.optimizationPreset = OPTIMIZATION_PRESETS[Number.parseInt(btn.value)];
                        }
                        else{
                            btn.checked = false;
                        }
                    });

                    polygonReductionSlider.value = this.optimizationParams.polygonReduction.toString();
                    polygonReductionSliderValue.innerText = `${polygonReductionSlider.value}%`;
    
                    advancedOptionToggle.checked = this.advancedOptimizationEnabled;
                }

                steps = OptimizationUtils.getSwalSteps();
                progressSteps = OptimizationUtils.getSwalStepsLabels(steps);

                nextStep = steps.find(step => step > currentStep && 
                    (this.has3DOptimizerLicense || !STEPS_NEEDING_OPTIMIZATION_LICENSE.includes(step)) &&
                    ((this.advancedOptimizationEnabled || step !== OptimizationPanelStep.Advanced)));
                    
                prevStep = [...steps].reverse().find(step => step < currentStep && 
                    (this.has3DOptimizerLicense || !STEPS_NEEDING_OPTIMIZATION_LICENSE.includes(step)) &&
                    ((this.advancedOptimizationEnabled || step !== OptimizationPanelStep.Advanced)));

                swal.update({progressSteps: progressSteps});
            });

            

            swal = swal.mixin({
                html: contents,
                title: `Optimizing ${this.modelFile}`,
                showCancelButton: false,
                denyButtonText: 'Back',
                showDenyButton: prevStep != null,
                didOpen: () => {
                    swal.getContent().querySelectorAll(".optimize-tooltip").forEach(t =>($(t) as any).tooltip());
                }
            });
        }
        else if (currentStep === OptimizationPanelStep.Advanced) {
            const template = document.querySelector("#optimization-advanced-settings-template") as HTMLTemplateElement;
            const contents = template.content.firstElementChild.cloneNode(true) as HTMLElement;

            const advancedHeader = contents.querySelector("#advanced-settings-header") as HTMLDivElement;
            const advancedHeaderArrow = advancedHeader.querySelector(".material-icons-outlined") as HTMLSpanElement;
            const advancedSettingsContainer = contents.querySelector("#advanced-settings-container") as HTMLDivElement;
            advancedHeaderArrow.innerText = advancedSettingsContainer.hidden ? "expand_more" : "expand_less";
            advancedHeader.addEventListener("click", () => {
                advancedSettingsContainer.hidden = !advancedSettingsContainer.hidden;
                advancedHeaderArrow.innerText = advancedSettingsContainer.hidden ? "expand_more" : "expand_less";
            });


            const preserve3DBoundariesButtons = advancedSettingsContainer.querySelector("#preserve-3d-boundaries-container")?.querySelectorAll("button");
            preserve3DBoundariesButtons?.forEach(btn => {
                btn.classList.toggle("btn-primary", btn.value === this.optimizationParams.preserve3DBoundaries);
                btn.classList.toggle("btn-outline-secondary", btn.value !== this.optimizationParams.preserve3DBoundaries);
            });

            preserve3DBoundariesButtons?.forEach(button => {
                button.addEventListener("click", () => {
                    this.optimizationParams.preserve3DBoundaries = button.value as OptimizationPreset;
                    
                    preserve3DBoundariesButtons.forEach(btn => {
                        btn.classList.toggle("btn-primary", btn.value === this.optimizationParams.preserve3DBoundaries);
                        btn.classList.toggle("btn-outline-secondary", btn.value !== this.optimizationParams.preserve3DBoundaries);
                    });


                });
            });

            const preserveUVButtons = advancedSettingsContainer.querySelector("#preserve-uv-container")?.querySelectorAll("button");
            preserveUVButtons?.forEach(btn => {
                btn.classList.toggle("btn-primary", btn.value === this.optimizationParams.preserveUV);
                btn.classList.toggle("btn-outline-secondary", btn.value !== this.optimizationParams.preserveUV);
            });
            preserveUVButtons?.forEach(button => {
                button.addEventListener("click", () => {
                    this.optimizationParams.preserveUV = button.value as OptimizationPreset;

                    preserveUVButtons.forEach(btn => {
                        btn.classList.toggle("btn-primary", btn.value === this.optimizationParams.preserveUV);
                        btn.classList.toggle("btn-outline-secondary", btn.value !== this.optimizationParams.preserveUV);
                    });
                });

            });

            const boundaryImportanceButtons = advancedSettingsContainer.querySelector("#boundary-importance-container")?.querySelectorAll("button");
            boundaryImportanceButtons?.forEach(btn => {
                btn.classList.toggle("btn-primary", btn.value === this.optimizationParams.boundaryImportance);
                btn.classList.toggle("btn-outline-secondary", btn.value !== this.optimizationParams.boundaryImportance);
            });

            boundaryImportanceButtons?.forEach(button => {
                button.addEventListener("click", () => {
                    this.optimizationParams.boundaryImportance = button.value as OptimizationPreset;
                    
                    boundaryImportanceButtons.forEach(btn => {
                        btn.classList.toggle("btn-primary", btn.value === this.optimizationParams.boundaryImportance);
                        btn.classList.toggle("btn-outline-secondary", btn.value !== this.optimizationParams.boundaryImportance);
                    });

                });
            });

            const shadingImportanceInput = contents.querySelector("#shading-importance-container").querySelector("input") as HTMLInputElement;
            shadingImportanceInput.value = this.optimizationParams.shadingImportance.toString();
            shadingImportanceInput.addEventListener("change", () => {
                this.optimizationParams.shadingImportance = Number.parseFloat(shadingImportanceInput.value);
            });





            const edgesHeader = contents.querySelector("#edges-settings-header") as HTMLDivElement;
            const edgesHeaderArrow = edgesHeader.querySelector(".material-icons-outlined") as HTMLSpanElement;
            const edgesSettingsContainer = contents.querySelector("#edges-settings-container") as HTMLDivElement;
            edgesHeaderArrow.innerText = edgesSettingsContainer.hidden ? "expand_more" : "expand_less";
            edgesHeader.addEventListener("click", () => {
                edgesSettingsContainer.hidden = !edgesSettingsContainer.hidden;
                edgesHeaderArrow.innerText = edgesSettingsContainer.hidden ? "expand_more" : "expand_less";
            });

            const normalWeightSelect = contents.querySelector("#normal-weight-container").querySelector("select") as HTMLSelectElement;
            normalWeightSelect.value = this.optimizationParams.normalWeight.toString();
            normalWeightSelect.addEventListener("change", () => {
                this.optimizationParams.normalWeight = Number.parseInt(normalWeightSelect.value);
            });

            const normalContrastInput = contents.querySelector("#normal-contrast-container").querySelector("input") as HTMLInputElement;
            normalContrastInput.value = this.optimizationParams.shadingImportance.toString();
            normalContrastInput.addEventListener("change", () => {
                this.optimizationParams.normalContrast = Number.parseFloat(normalContrastInput.value);
            });

            const projectNormalsCheckbox = contents.querySelector("#project-nomals-container").querySelector("input") as HTMLInputElement;
            projectNormalsCheckbox.checked = this.optimizationParams.projectNormals;
            projectNormalsCheckbox.addEventListener("change", () => {
                this.optimizationParams.projectNormals = projectNormalsCheckbox.checked;
            });

            const discardUVCheckbox = contents.querySelector("#discard-uv-container").querySelector("input") as HTMLInputElement;
            discardUVCheckbox.checked = this.optimizationParams.discardUV;
            discardUVCheckbox.addEventListener("change", () => {
                this.optimizationParams.discardUV = discardUVCheckbox.checked;
            });

            const hardEdgesCheckbox = contents.querySelector("#hard-edges-container").querySelector("input") as HTMLInputElement;
            hardEdgesCheckbox.checked = this.optimizationParams.hardEdges;
            hardEdgesCheckbox.addEventListener("change", () => {
                this.optimizationParams.hardEdges = hardEdgesCheckbox.checked;
            });

            const smoothEdgesCheckbox = contents.querySelector("#smooth-edges-container").querySelector("input") as HTMLInputElement;
            smoothEdgesCheckbox.checked = this.optimizationParams.smoothEdges;
            smoothEdgesCheckbox.addEventListener("change", () => {
                this.optimizationParams.smoothEdges = smoothEdgesCheckbox.checked;
            });

            const cleanSurfacesHeader = contents.querySelector("#clean-surfaces-settings-header") as HTMLDivElement;
            const cleanSurfacesHeaderArrow = cleanSurfacesHeader.querySelector(".material-icons-outlined") as HTMLSpanElement;
            const cleanSurfacesSettingsContainer = contents.querySelector("#clean-surfaces-settings-container") as HTMLDivElement;
            cleanSurfacesHeaderArrow.innerText = cleanSurfacesSettingsContainer.hidden ? "expand_more" : "expand_less";
            cleanSurfacesHeader.addEventListener("click", () => {
                cleanSurfacesSettingsContainer.hidden = !cleanSurfacesSettingsContainer.hidden;
                cleanSurfacesHeaderArrow.innerText = cleanSurfacesSettingsContainer.hidden ? "expand_more" : "expand_less";
            });
            
            const isolatedVerticesCheckbox = contents.querySelector("#isolated-vertices-container").querySelector("input") as HTMLInputElement;
            isolatedVerticesCheckbox.checked = this.optimizationParams.removeIsolatedVertices;
            isolatedVerticesCheckbox.addEventListener("change", () => {
                this.optimizationParams.removeIsolatedVertices = isolatedVerticesCheckbox.checked;
            });

            const duplicatedFacesCheckbox = contents.querySelector("#duplicated-faces-container").querySelector("input") as HTMLInputElement;
            duplicatedFacesCheckbox.checked = this.optimizationParams.removeDuplicatedFaces;
            duplicatedFacesCheckbox.addEventListener("change", () => {
                this.optimizationParams.removeDuplicatedFaces = duplicatedFacesCheckbox.checked;
            });
            
            const degeneratedFacesCheckbox = contents.querySelector("#degenerated-faces-container").querySelector("input") as HTMLInputElement;
            degeneratedFacesCheckbox.checked = this.optimizationParams.removeDegeneratedFaces;
            degeneratedFacesCheckbox.addEventListener("change", () => {
                this.optimizationParams.removeDegeneratedFaces = degeneratedFacesCheckbox.checked;
            });

            const duplicatedVerticesCheckbox = contents.querySelector("#duplicated-vertices-container").querySelector("input") as HTMLInputElement;
            duplicatedVerticesCheckbox.checked = this.optimizationParams.removeDuplicatedVertices;
            duplicatedVerticesCheckbox.addEventListener("change", () => {
                this.optimizationParams.removeDuplicatedVertices = duplicatedVerticesCheckbox.checked;
            });
            
            const smallGeometriesCheckbox = contents.querySelector("#small-geometries-container").querySelector("input") as HTMLInputElement;
            const smallGeometriesBody = contents.querySelector("#small-geometries-body") as HTMLDivElement;
            smallGeometriesCheckbox.checked = this.optimizationParams.removeSmallGeometries;
            smallGeometriesBody.hidden = !smallGeometriesCheckbox.checked;
            smallGeometriesCheckbox.addEventListener("change", () => {
                this.optimizationParams.removeSmallGeometries = smallGeometriesCheckbox.checked;
                smallGeometriesBody.hidden = !smallGeometriesCheckbox.checked;
            });
            
            const smallGeometriesBySideContainer = contents.querySelector("#small-geometries-by-side-container") as HTMLDivElement;
            const smallGeometriesBySideSlider = smallGeometriesBySideContainer.querySelector("input") as HTMLInputElement;
            const smallGeometriesBySideSliderValue = smallGeometriesBySideContainer.querySelector(".optimization-slider-value") as HTMLSpanElement;
            smallGeometriesBySideSlider.value = this.optimizationParams.removeSmallGeometriesSize.toString();
            smallGeometriesBySideSliderValue.innerText = `${smallGeometriesBySideSlider.value}%`;
            smallGeometriesBySideSlider.addEventListener("input", () => {
                this.optimizationParams.removeSmallGeometriesSize = Number.parseInt(smallGeometriesBySideSlider.value);
                smallGeometriesBySideSliderValue.innerText = `${smallGeometriesBySideSlider.value}%`;
            });
            
            const smallGeometriesByCountContainer = contents.querySelector("#small-geometries-by-count-container") as HTMLDivElement;
            const smallGeometriesByCountSlider = smallGeometriesByCountContainer.querySelector("input") as HTMLInputElement;
            const smallGeometriesByCountSliderValue = smallGeometriesByCountContainer.querySelector(".optimization-slider-value") as HTMLSpanElement;
            smallGeometriesByCountSlider.value = this.optimizationParams.removeSmallGeometriesCount.toString();
            smallGeometriesByCountSliderValue.innerText = `${smallGeometriesByCountSlider.value}`;
            smallGeometriesByCountSlider.addEventListener("input", () => {
                this.optimizationParams.removeSmallGeometriesCount = Number.parseInt(smallGeometriesByCountSlider.value);
                smallGeometriesByCountSliderValue.innerText = `${smallGeometriesByCountSlider.value}`;
            });

            const hiddenObjectsCheckbox = contents.querySelector("#hidden-objects-container").querySelector("input") as HTMLInputElement;
            hiddenObjectsCheckbox.checked = this.optimizationParams.removeHiddenObjects;
            hiddenObjectsCheckbox.addEventListener("change", () => {
                this.optimizationParams.removeHiddenObjects = hiddenObjectsCheckbox.checked;
            });

            swal = swal.mixin({
                html: contents,
                title: `Optimizing ${this.modelFile}`,
                showCancelButton: false,
                denyButtonText: 'Back',
                showDenyButton: prevStep != null,
                didOpen: () => {
                    swal.getContent().querySelectorAll(".optimize-tooltip").forEach(t =>($(t) as any).tooltip());
                }
            });
        }
        else if(currentStep === OptimizationPanelStep.Confirm){
            const contents = document.createElement("table") as HTMLTableElement;
            contents.style.width = "100%";
            contents.style.tableLayout = "fixed";

            const row = document.createElement("tr") as HTMLTableRowElement;
                
            const pKey = document.createElement("td");
            const pValue = document.createElement("td");
            pKey.innerText = `Optimizations name:`;
            pKey.style.textAlign = "left";
            pValue.innerText = `${this.optimizationName ? this.optimizationName : `Opt ${this.optimizationParams.polygonReduction}%`}`;
            pValue.style.textAlign = "right";
            pValue.style.overflow = "ellipsis";
            pValue.style.paddingRight = "0.5rem";


            row.appendChild(pKey);
            row.appendChild(pValue);
            contents.appendChild(row);


            if(this.advancedOptimizationEnabled){

                Object.getOwnPropertyNames(this.optimizationParams).forEach((key, index) => {
                    const row = document.createElement("tr") as HTMLTableRowElement;
    
                    let value = this.optimizationParams[key];
                    value = value.toString().replace('vhigh', "very high").replace("vlow","very low").replace("true","check").replace("false","close");
                    const pKey = document.createElement("td");
                    const pValue = document.createElement("td");
                    pKey.innerText = `${OPTIMIZATION_OPTIONS_NAMES[index]}:`;
                    pKey.style.textAlign = "left";
                    pValue.innerText = `${value}`;
                    if(value === "check" || value === "close" ){
                        pValue.classList.add("material-icons-outlined");
                        pValue.style.display = "block";
                    }
                    else{
                        pValue.style.paddingRight = "0.5rem";
                    }
                    pValue.style.textAlign = "right";
                    pValue.style.overflow = "ellipsis";

                    row.appendChild(pKey);
                    row.appendChild(pValue);
                    contents.appendChild(row);
                })
            }
            else{
                const row = document.createElement("tr") as HTMLTableRowElement;
    
                let index = OPTIMIZATION_PRESETS.findIndex(p => isEqual(this.optimizationPreset, p));

                const value = OPTIMIZATION_PRESETS_NAMES[index];
                const pKey = document.createElement("td");
                const pValue = document.createElement("td");
                pKey.innerText = `Optimization preset:`;
                pKey.style.textAlign = "left";
                pValue.innerText = `${value}`;
                pValue.style.textAlign = "right";
                pValue.style.paddingRight = "0.5rem";

                row.appendChild(pKey);
                row.appendChild(pValue);
                contents.appendChild(row);


                const row2 = document.createElement("tr") as HTMLTableRowElement;
                
                const value2 = this.optimizationParams.polygonReduction;
                const pKey2 = document.createElement("td");
                const pValue2 = document.createElement("td");
                pKey2.innerText = `Polygon reduction:`;
                pKey2.style.textAlign = "left";
                pValue2.innerText = `${value2}%`;
                pValue2.style.textAlign = "right";
                pValue2.style.paddingRight = "0.5rem";

                row2.appendChild(pKey2);
                row2.appendChild(pValue2);
                contents.appendChild(row2);
            }

                 
            swal = swal.mixin({
                html: this.isOptimizationEnabled ? contents : "",
                title: `${this.isOptimizationEnabled ? `Optimize` : `Convert`}<br>${this.modelFile}`,
                showCancelButton: false,
                denyButtonText: 'Back',
                showDenyButton: prevStep != null,
                confirmButtonText: `Confirm`,
                didOpen: () => {
                    swal.getContent().querySelectorAll(".optimize-tooltip").forEach(t =>($(t) as any).tooltip());
                }
            });
        }

        const result = await swal.fire();

        if(result.isConfirmed)
        {
            if(nextStep){
                optimizationResult = await OptimizationUtils.showSwalStep(nextStep);
            }
            else {
                const spinner = document.createElement("div");
                spinner.classList.add("spinner-border");

                let title = "";
                let html = spinner.outerHTML;
                let footer = "";

                if (this.isConversionEnabled && !this.isOptimizationEnabled) {
                    title = "Converting<br><br>Please wait ... ";
                }
                else if (this.isOptimizationEnabled) {
                    title = "Optimizing<br><br>Please wait... ";
                }

                swal = swal.mixin({
                    title: title,
                    html: html,
                    showCancelButton: false,
                    showConfirmButton: false,
                    showDenyButton: false,
                    showCloseButton: false,
                    footer: footer,
                    didOpen: async () => {
                        let projectParams: ProjectParams = DEFAULT_PROJECT_PARAMS;
                        
                        if(this.isYup !== undefined){
                            projectParams = {
                                ...projectParams,
                                yup: this.isYup
                            }
                        }

                        if (this.isConversionEnabled && !this.isOptimizationEnabled) {
                            const originalOptimization = await OptimizationUtils.launchOptimization(ORIGINAL_OPTIMIZATION_NAME, ORIGINAL_OPTIMIZATION_PARAMS, this.waitForCompletion, this.uploadResultToVertexResource, projectParams, this.measurementUnit);

                            if (!originalOptimization) {
                                title = "The model conversion has failed";
                            }
                            else {
                                if (this.waitForCompletion) {
                                    title = "The model has been converted.";

                                    optimizationResult = Status.Completed;
                                }
                                else {
                                    title = "The model conversion has started.";

                                    optimizationResult = Status.Pending;
                                }
                            }

                            html = '';
                        }
                        else if (this.isOptimizationEnabled) {
                            const optimization = await OptimizationUtils.launchOptimization(this.optimizationName, this.optimizationParams, this.waitForCompletion, this.uploadResultToVertexResource, projectParams, this.measurementUnit);

                            if (!optimization) {
                                title = "The model optimization has failed";
                            }
                            else {
                                if (this.waitForCompletion) {
                                    title = "The model has been optimized.";

                                    optimizationResult = Status.Completed;
                                }
                                else {
                                    title = "The model optimization has started.";

                                    optimizationResult = Status.Pending;
                                }
                            }

                            html = '';
                        }

                        swal.update({
                            title: title,
                            html: html,
                            showConfirmButton: true,
                            confirmButtonText: "OK",
                            customClass: {
                                header: 'custom-swal2-progress-header',
                                actions: 'custom-swal2-actions-flex',
                            }
                        });
                    }
                });

               await swal.fire();
            }
        }
        else if(result.isDenied && prevStep != null)
        {
            optimizationResult = await OptimizationUtils.showSwalStep(prevStep);
        }
        else if(result.isDismissed)
        {
            optimizationResult = Status.Canceled;
        }

        return optimizationResult;
    }

    private static selectPreset(preset: OptimizationParams){
        this.optimizationPreset = preset;
        this.optimizationParams = cloneDeep(this.optimizationPreset);
    }

    public static async openOptimizationPanel(isConversionEnabled: boolean, isOptimizationEnabled: boolean, modelFile: string, waitForCompletion: boolean, uploadResultToVertexResource: boolean): Promise<Status>{
        const licenses = await LicenseUtils.validateLicenses(HEVOLUS_HVERSE_3DCONVERTER_LICENSE, HEVOLUS_HVERSE_3DOPTIMIZER_LICENSE, STEP2GLTF_CONVERTER_LICENSE);
        
        this.has3DConverterLicense = licenses[HEVOLUS_HVERSE_3DCONVERTER_LICENSE] === LicenseValidity.Valid;
        this.has3DOptimizerLicense = licenses[HEVOLUS_HVERSE_3DOPTIMIZER_LICENSE] === LicenseValidity.Valid;

        this.modelFile = modelFile;
        this.modelExtension = Utils.getFileExtension(this.modelFile, true);

        this.isConversionEnabled = isConversionEnabled && (this.has3DConverterLicense || this.has3DOptimizerLicense);
        this.isOptimizationEnabled = isOptimizationEnabled && this.has3DOptimizerLicense;
        
        this.waitForCompletion = waitForCompletion;
        this.uploadResultToVertexResource = uploadResultToVertexResource;

        OptimizationUtils.selectPreset(DEFAULT_OPTIMIZATION_PRESET);

        this.optimizationName = "";
        this.advancedOptimizationEnabled = false;

        let result: Status = Status.None;

        if(this.isConversionEnabled)
        {
            result = await OptimizationUtils.showSwalStep(OptimizationPanelStep.Conversion);
        }
        else if(this.isOptimizationEnabled)
        {
            result = await OptimizationUtils.showSwalStep(OptimizationPanelStep.Optimization);
        }

        return result;
    }

    public static async launchOptimization(optimizationName: string, optimizationParams: OptimizationParams, waitForCompletion: boolean, uploadResultToVertexResource: boolean, projectParams?: ProjectParams, measurementUnit?: MeasurementUnit): Promise<Optimization> {
        let modelInfo = await UploadUtils.modelInfo;
        let modelInfoUpdated = false;

        if(!modelInfo){
            let gltf = {} as GltfStructure;
            const gltfResp = await ResourceUtils.getAssetFromResource(Vertex.Globals.spaceId, MESH_GLTF_FILENAME);

            if(gltfResp.ok){
                gltf = gltfResp.json() as GltfStructure;
            }

            modelInfo = { gltf: gltf };
            modelInfoUpdated = true;
        }
        
        if(modelInfo?.objectModel == null){
            //TODO: reorganize this upload possibly with uploadFiles method
            const objectModel = await UploadUtils.uploadModelAndAdditionalsFromVertexResource(this.modelFile);
            
            if (objectModel) {
                modelInfo.objectModel = objectModel;
                modelInfo.modelProperties = modelInfo.modelProperties || {};
                modelInfo.modelProperties.name = this.modelFile;
                
                modelInfoUpdated = true;
            }
            else {
                return null;
            }
        }

        if(modelInfo.project == null) {
            const project = await OptimizationUtils.createProject(modelInfo.objectModel.id, projectParams);
            
            if (project) {
                modelInfo.project = project;
                modelInfoUpdated = true;
            }
            else {
                return null;
            }
        }

        //if the user provided the unit information, we are probably dealing with an stp file
        //Pay attention to the fact that atm Adapta service already transform the scale of converted stp file
        //with a default mm -> m conversion (aka scale * 0.001), so we must consider this in the following lines
        if(measurementUnit){
            modelInfo.modelProperties = modelInfo.modelProperties || {};
            modelInfo.modelProperties.measurementUnit = measurementUnit;

            if(measurementUnit === MeasurementUnit.Millimeters){
                modelInfo.modelProperties.rootTransform = { scale: [1, 1, 1] };
            }
            else if(measurementUnit === MeasurementUnit.Centimeters){
                modelInfo.modelProperties.rootTransform = { scale: [0.1, 0.1, 0.1] };
            }
            else if(measurementUnit === MeasurementUnit.Meters){
                modelInfo.modelProperties.rootTransform = { scale: [0.001, 0.001, 0.001] };
            }
            
            modelInfoUpdated = true;
        }

        let optimization = await OptimizationUtils.createOptimization(optimizationName, modelInfo.project.id, optimizationParams, uploadResultToVertexResource);
        TelemetryUtils.sendOptimizationData(optimization, TelemetryEvent.ModelOptimizationStart, modelInfo)

        if (optimization) {
            if(!modelInfo.project.optimizations){
                modelInfo.project.optimizations = [];
            }
            
            modelInfo.project.optimizations.push(optimization);

            if(uploadResultToVertexResource){
                modelInfo.currentOptimizationId = optimization.id;
            }

            modelInfoUpdated = true;
        }

        if(modelInfoUpdated){
            await UploadUtils.postModelInfoJson(modelInfo);
        }

        if(optimization && waitForCompletion){
            const newOptimization = await OptimizationUtils.waitOptimizationCompleted(optimization.id, modelInfo.project.id);
            
            const index = modelInfo.project.optimizations.findIndex((o) => o.id == optimization.id);

            if (index != -1) {
                modelInfo.project.optimizations.splice(index, 1);

                if (newOptimization) {
                    modelInfo.project.optimizations.push(newOptimization);
                    TelemetryUtils.sendOptimizationData(newOptimization, TelemetryEvent.ModelOptimizationEnd, modelInfo);
                }
                else{
                    TelemetryUtils.sendOptimizationData(optimization, TelemetryEvent.ModelOptimizationError, modelInfo);
                }

                await UploadUtils.postModelInfoJson(modelInfo);
            }

            optimization = newOptimization;
        }
        
        return optimization;
    }



    private static async createProject(objectModelId: string, params?: ProjectParams): Promise<Project> {
        const resourceId = Vertex.Globals.spaceId;
        const loginOutput = await Amaz3dApi.login(Amaz3dApi.LoginInput);
        
        if(loginOutput == null || loginOutput.token == null){
            return null;
        }

        //verifico se il progetto esiste già
        const projectsFilter: ProjectFilter = {name: {eq: resourceId} };
        const projects = await Amaz3dApi.projects(loginOutput.token, projectsFilter);
        
        let project: Project = null;

        if(projects?.length){
            project = projects.find(p => (p.objectModelOriginal?.id === objectModelId) || (p.objectModel?.id === objectModelId));
        }

        if(!project){
            //creo progetto
            project = await Amaz3dApi.createProject({ project: { objectModel: { id: objectModelId }, name: resourceId, params: params } }, loginOutput.token);       
        }

        if (!project) {
            return null;
        }

        project = await OptimizationUtils.waitProjectReadiness(project);

        return project;
    }

    private static async createOptimization(optimizationName: string, projectId: string, optimizationParams: OptimizationParams, uploadResultToVertexResource: boolean): Promise<Optimization> {
        const resourceId = Vertex.Globals.spaceId;
        const loginOutput = await Amaz3dApi.login(Amaz3dApi.LoginInput);
        
        if(loginOutput == null || loginOutput.token == null){
            return null;
        }

        const optimizationInput: CreateOneOptimizationInput = {
            optimization: {
                name: optimizationName?.length > 0 ? optimizationName : `Opt ${optimizationParams.polygonReduction}%`,
                outputFormat: OptimizationOutputFormat.FormatGltf,
                outputFormatOptions: {
                    format_gltf: {
                        export_format: GltfExportFormats.Separate,
                        export_draco_mesh_compression_enable: false
                    }
                },
                project: {
                    id: projectId
                },
                params: OptimizationUtils.getOptimizationParamsInput(optimizationParams),
                publication: uploadResultToVertexResource ? {
                    mode: PublicationMode.UrlFilename,
                    token: Vertex.Globals.bearerToken,
                    url: `${RESOURCE_API_URI}${resourceId}`,
                    target: PublicationTarget.ConvertedResult,
                    verify_ssl: Config.AMAZ3D_VERIFY_SSL
                } : undefined
            }
        }

        return await Amaz3dApi.createOptimization(optimizationInput, loginOutput.token);
    }

    private static getOptimizationParamsInput(optimizationParams: OptimizationParams): OptimizationParamsInput {
        let optimizationParamsInput: OptimizationParamsInput = {};
        optimizationParamsInput.face_reduction = optimizationParams.polygonReduction != null ? optimizationParams.polygonReduction/100 : undefined;

        switch (optimizationParams.preserve3DBoundaries) {
            case OptimizationPreset.Low:
                optimizationParamsInput.feature_importance = 0;
                break;
            case OptimizationPreset.Medium:
                optimizationParamsInput.feature_importance = 1;
                break;
            case OptimizationPreset.High:
                optimizationParamsInput.feature_importance = 2;
                break;
        }

        switch (optimizationParams.preserveUV) {
            case OptimizationPreset.VLow:
                optimizationParamsInput.uv_seam_importance = 0;
                break;
            case OptimizationPreset.Low:
                optimizationParamsInput.uv_seam_importance = 1;
                break;
            case OptimizationPreset.Medium:
                optimizationParamsInput.uv_seam_importance = 2;
                break;
            case OptimizationPreset.High:
                optimizationParamsInput.uv_seam_importance = 3;
                break;
        }
        
        switch (optimizationParams.boundaryImportance) {
            case OptimizationPreset.Low:
                optimizationParamsInput.preserve_boundary_edges = 0;
                break;
            case OptimizationPreset.Medium:
                optimizationParamsInput.preserve_boundary_edges = 1;
                break;
            case OptimizationPreset.High:
                optimizationParamsInput.preserve_boundary_edges = 2;
                break;
            case OptimizationPreset.VHigh:
                optimizationParamsInput.preserve_boundary_edges = 3;
                break;
        }

        optimizationParamsInput.normals_scaling = optimizationParams.shadingImportance;
        optimizationParamsInput.normals_weighting = optimizationParams.normalWeight;
        optimizationParamsInput.contrast = optimizationParams.normalContrast;
        optimizationParamsInput.project_normals = optimizationParams.projectNormals;
        optimizationParamsInput.retexture = !optimizationParams.discardUV;
        optimizationParamsInput.preserve_hard_edges = optimizationParams.hardEdges;
        optimizationParamsInput.preserve_smooth_edges = optimizationParams.smoothEdges;
        optimizationParamsInput.remove_isolated_vertices = optimizationParams.removeIsolatedVertices;
        optimizationParamsInput.remove_duplicated_faces = optimizationParams.removeDuplicatedFaces;
        optimizationParamsInput.remove_degenerate_faces = optimizationParams.removeDegeneratedFaces;
        optimizationParamsInput.remove_duplicated_boundary_vertices = optimizationParams.removeDuplicatedVertices;
        optimizationParamsInput.remove_isolated_vertices = optimizationParams.removeIsolatedVertices;

        if(optimizationParams.removeSmallGeometries){
            optimizationParamsInput.remove_meshes_by_size = optimizationParams.polygonReduction != null ? optimizationParams.removeSmallGeometriesSize/100 : undefined;
            optimizationParamsInput.remove_meshes_by_count = optimizationParams.removeSmallGeometriesCount;
        }

        return optimizationParamsInput;
    }

    /**
     * Wait for Amaz3d project to be ready or failed
     * @param project The project to wait readiness for
     * @param timerInMs Amount of time in ms to wait between polling
     * @param timeout Optional timeout value
     * @returns the updated Project
     */
    public static async waitProjectReadiness(project: Project, timerInMs: number = 1000, timeout: number = null): Promise<Project> {
        let updatedProject: Project;
        const loginOutput = await Amaz3dApi.login(Amaz3dApi.LoginInput);
        
        if(loginOutput == null || loginOutput.token == null){
            return null;
        }

        await Utils.waitForConditionAsync(async () => {
            updatedProject = await Amaz3dApi.project(project.id, loginOutput.token);

            if(updatedProject?.conversionStatus !== ConversionStatus.Pending){
                return true;
            };
        }, timerInMs, timeout);

        return updatedProject;
    }

    public static async waitOptimizationCompleted(optimizationId: string, projectId: string, timerInMs: number = 1000, timeout: number = null, maxFailedAttempts: number = 5): Promise<Optimization> {
        const loginOutput = await Amaz3dApi.login(Amaz3dApi.LoginInput);
        
        if(loginOutput == null || loginOutput.token == null){
            return null;
        }

        let optimization = null;
        let failedAttempts = 0;

        await Utils.waitForConditionAsync(async () => {
            optimization = null;
 
            const optimizationFilter: OptimizationFilter = {id: {eq: optimizationId}, project: {id: {eq: projectId}}};
            let optResult = await Amaz3dApi.optimizations(loginOutput.token, optimizationFilter);
            
            if(!optResult){
                failedAttempts += 1;

                return failedAttempts >= maxFailedAttempts;
            }

            failedAttempts = 0;

            optimization = optResult.find((opt) => opt.id === optimizationId);

            if(!optimization){
                console.error(`Optimization ${optimizationId} not found`);

                return true;
            }

            return optimization.status !== OptimizationStatus.Pending;
        }, timerInMs, timeout);

        if (optimization?.status === OptimizationStatus.Completed) {
            return optimization;
        }
    }
}

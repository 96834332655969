export class CustomLightInspector implements Vertex.UI.ICustomInspectorRenderer {

    constructor(targetNode: Vertex.NodeComponentModel.VertexNode) {
        this.targetNode = targetNode;
    }
    targetNode: Vertex.NodeComponentModel.VertexNode;
    target: Vertex.NodeComponentModel.Component;
    property: string;

    row = document.createElement("div");

    //Target appears to be the node but we also have just targetNode so not sure why it's needed.
    RenderProperty(property: string, target: any): HTMLDivElement {
        this.target = target;
        //Assuming property is "shouldPlay"
        this.property = property;

        //Get GLTF
        let animComp = null;// target as CustomAnimationComponent;
        let outer = document.createElement('div');
        
        let row = document.createElement("div"); row.classList.add("row", "control-group");
        let colourType = property === "diffuseR" ? "Diffuse" : "Specular"; 
        let nameCol = document.createElement("div"); nameCol.classList.add("col-3", "control-label"); nameCol.innerText = colourType; row.appendChild(nameCol);
        
        let inputCol = document.createElement("div"); inputCol.classList.add("col", "control-value");

        let inputField = document.createElement("input") as HTMLInputElement; inputField.type = "color"; inputField.classList.add("form-control");
        
        colourType = property === "diffuseR" ? "diffuse" : "specular"
        let currentColour = new BABYLON.Color3(target[colourType + "R"], target[colourType + "G"], target[colourType + "B"]);
        inputField.value = currentColour.toHexString();

        //inputField.value =  
        inputField.addEventListener("input", ()=>{
            let colourVal= BABYLON.Color3.FromHexString(inputField.value);

            target[colourType + "R"] = colourVal.r; 
            target[colourType + "G"] = colourVal.g; 
            target[colourType + "B"] = colourVal.b; 
            target.triggerOnChanged();
        });
        
        inputCol.appendChild(inputField);
        row.appendChild(inputCol);

        outer.appendChild(row); 
        
        //animComp.onChanged.on(()=>{this.renderAnimationList(outer, animComp)});

        
        
        return outer;
    }
}
import { ChangeMaterialModel } from '../../../utilities/change-material-model-utilities';

export enum SkinNodeType{ 
    Category = "category",
    Preset = "preset"
}

export interface SkinNode {
    type: SkinNodeType;
    name: string;
    index?: number;
    childs?: SkinNode[];
}

export class Skins {
    static skinsJsonForAI(skin: ChangeMaterialModel){
        let skinNodes: SkinNode[] = [];
        let categories: Map<number, SkinNode> = new Map<number, SkinNode>();

        if (skin.presetCategories?.length){
            skin.presetCategories.forEach(category => {
                categories.set(category.index, {
                    type: SkinNodeType.Category,
                    index: null,
                    name: category.name,
                    childs: [] as SkinNode[]
                });
            });
        }

        if (skin.presets?.length){
            skin.presets.forEach(preset => {
                let presetJson: SkinNode = {
                    type: SkinNodeType.Preset,
                    index: skin.presets.indexOf(preset),
                    name: preset.name
                };

                if (preset.category >= 0 && categories.has(preset.category)){
                    categories.get(preset.category).childs.push(presetJson);
                }
                else{
                    skinNodes.push(presetJson);
                }
            });
        }

        if (skin.presetCategories?.length){
            skin.presetCategories.forEach(category => {
                if (category.parentCategory >= 0 && categories.has(category.parentCategory)){
                    categories.get(category.parentCategory).childs.push(categories.get(category.index));
                }
            });
            
            skin.presetCategories.forEach(category => {
                if (category.parentCategory < 0){
                    skinNodes.push(categories.get(category.index));
                }
            });
        }

        return skinNodes;
    }
}
import { cloneDeep } from "lodash";
import { CATALOG_CONTAINER, CATALOG_NAV_TEMPLATE, CATALOG_SEARCH_INPUT, createInstance, DELETE_MODAL_TEMPLATE_SELECTOR, ICatalogItem, IModal } from "./catalog.interfaces";
import { v4 as uuidv4 } from 'uuid';
import "./catalog.scss"
import { loadTemplate, Utils } from "../../utilities/utils";
import { ResourceUtils, VERTEXResource } from "../../utilities/resource-utilities";

export abstract class CatalogBase<T extends ICatalogItem = ICatalogItem> {
    protected readonly containerEl: HTMLElement;
    protected readonly searchInputEl: HTMLInputElement;
    protected readonly searchBarEl: HTMLElement;
    protected readonly buttonNewEl: HTMLButtonElement;
    protected _items: T[] = [];
    protected _searchValue: string = "";
    protected catalog_item_template: string = "#catalog-item-block-template";
    protected baseClsEl = "catalog-item";
    protected baseClsNameEl = "catalog-item-name";
    protected cls: string[] = [];

    onLoaded: (items: ICatalogItem[]) => void;
    
    set itemList(items: T[]) {
        this._items = items.slice();
        
        this._drawItems(this._items);
        
        if (!!this._searchValue) {
            this.onSearchItem(this._searchValue);
        }
    }
    
    get itemList(): T[] {
        return this._items.slice();
    }

    constructor(protected catalog: VERTEXResource, protected fileNameItems: string, private modalType?: new (items: T[], item?: T) => IModal) {
        this.containerEl = document.querySelector<HTMLElement>(CATALOG_CONTAINER);
        this.searchBarEl = this._drawSearchBar(this.containerEl);
        this.buttonNewEl = this.searchBarEl.querySelector<HTMLButtonElement>('button');
        this.searchInputEl = this.searchBarEl.querySelector<HTMLInputElement>(CATALOG_SEARCH_INPUT);

        this._wireEvents();
        this.loadItems().then(res => console.log('catalog loaded!'));
    }

    searchFilterFunction(item: ICatalogItem, textSearch: string):boolean
    {
        return item.name.toLowerCase().includes(textSearch.toLowerCase());
    }         
    
    protected onSearchItem(txt: string): void {
        const els = this.containerEl.querySelectorAll<HTMLElement>(`.${this.baseClsEl}`);

        this._searchValue = txt;

        if (!txt) {
            els.forEach(q => q.hidden = false);
            return;
        }

        const qrs = this.itemList.filter((i) => this.searchFilterFunction(i, txt));
        els.forEach(q => q.hidden = !qrs.some(qr => qr.id === q.id));
    }

    protected drawItem(item: ICatalogItem, itemEl: HTMLElement): void {
        
        const el = itemEl.querySelector<HTMLElement>(`.${this.baseClsNameEl}`);
        const btnDel = itemEl.querySelector<HTMLElement>('.delete');
        
        btnDel.onclick = (e) => this.onClickDeleteItem(e);
        
        el.innerText = item.name;
        el.title = item.name;
    }

    async onClickAdd(e) {
        if(!this.modalType){
            return;
        }
        
        const modal = createInstance(this.modalType, this._items);
        
        modal.show(async (item: T) => {
            await this.addItem(item);
            await this.loadItems();
            this._closeModal(modal);
        });
    }

    async loadItems() {
        const result = await this.getItemList();

        this.itemList = result;
    }

    public destroy(){
        this.containerEl.innerHTML = "";
        this.searchBarEl.remove();
    }

    private _wireEvents() {
        this.buttonNewEl.onclick = (e) => this.onClickAdd(e);
        this.searchInputEl.onkeyup = (e) => {
            this.searchInputEl.value = Utils.sanitizeString(this.searchInputEl.value);
            this.onSearchItem(this.searchInputEl.value);
        };
    }

    private _drawSearchBar(parent: HTMLElement): HTMLElement {
        const mainFluid = document.getElementById("main-fluid");
        let mainNavTemp = loadTemplate(CATALOG_NAV_TEMPLATE);

        return mainFluid.insertBefore(mainNavTemp, parent);
    }

    private _drawItems(items: T[]) {
        this.containerEl.innerHTML = "";

        items.forEach((item) => {
            const el: HTMLDivElement = this.containerEl.appendChild(loadTemplate(this.catalog_item_template));
            el.id = item.id;
            
            this.drawItem(item, el);
        });
    }

    async onClickDeleteItem(e) {
        const qrEl = (e.currentTarget as HTMLElement).closest(`div.${this.baseClsEl}`);

        if (!qrEl) {
            return;
        }

        const item: T = this.itemList.find((q) => q.id === qrEl.id);
        if (!item) {
            return;
        }

        const modalTemplate = loadTemplate(DELETE_MODAL_TEMPLATE_SELECTOR);
        const dialog = document.body.appendChild(modalTemplate);

        ($(dialog) as any).modal("show");

        const deleteModal = document.querySelector('#delete-modal');
        const btnConfirm = dialog.querySelector<HTMLButtonElement>('#delete-button');
        const btnCancel = dialog.querySelector<HTMLButtonElement>('#delete-cancel-button');

        /**
         * If the user presses the Enter key in 
         * the delete modal, the tag is deleted
         */
        deleteModal.addEventListener('keydown', async (e: KeyboardEvent) => {
            if (e.key === "Enter") {
                await this.deleteItem(item.id);
                await this.loadItems();
                ($(dialog) as any).modal("hide");
                dialog.remove();
            }
        })
        btnCancel.onclick = () => {
            ($(dialog) as any).modal("hide");
            dialog.remove();
        }
        btnConfirm.onclick = async () => {
            await this.deleteItem(item.id);
            await this.loadItems();
            ($(dialog) as any).modal("hide");
            dialog.remove();
        }
    }

    private _closeModal(modal: IModal) {
        modal.close();
    }

    existItem(itemList: T[], item: T){
        if (typeof (item) === 'string') {
            return itemList.findIndex((q) => (q.id === item)) !== -1;
        }

        return itemList.findIndex((q) => q.id === item.id || q.name === item.name) !== -1;
    }

    async updateItem(item: T) {
        if (!this.existItem(this.itemList, item)) {
            return;
        }

        const list = this.itemList;
        const idx = list.findIndex((q) => q.id === item.id);
        list[idx] = cloneDeep(item);

        await this.updateItemList(list);
    }

    protected _onClickItem(e: Event) {
        const el = (e.currentTarget as HTMLElement).closest(`div.${this.baseClsEl}`);
        const item = this.itemList.find(q => q.id === el.id);

        if (!item) {
            return;
        }

        const modal = createInstance(this.modalType, this._items, item);

        modal.show(async (item: T) => {
            await this.updateItem(item);
            const result = await this.getItemList();
            this.itemList = result;
            this._closeModal(modal);
        });
    }

    async addItem(item: T): Promise<void> {
        const list = this.itemList;

        if (this.existItem(list, item)) {
            return;
        }

        const dateTime = new Date();

        if (!item.id) {
            item.id = uuidv4();
        }

        list.push({ ...item });
        await this.updateItemList(list);
    }

    existItemById = (itemList: T[], id: string) => itemList.findIndex((q) => (q.id === id)) !== -1;

    async deleteItem(id: string) {
        const items = this.itemList;

        if (!this.existItemById(items, id)) {
            return;
        }

        const list = items.filter((q) => q.id !== id);
        await this.updateItemList(list);
    }

    async getItemList(): Promise<T[]> {
        let res = await ResourceUtils.getAssetFromResource(this.catalog.id, this.fileNameItems);

        if (!res.ok) {
            console.log(`couldnt find ${this.fileNameItems} on resource`);
            return;
        }
        
        const jsonResp = await res.json();
        const result = this.convertToObject(jsonResp);

        if(this.onLoaded){
            this.onLoaded(result);
        }

        return result;
    }

    private async updateItemList(items: T[]) {
        const res = JSON.stringify(this.convertItemToSave(items));

        const result = await ResourceUtils.postAssetToResource(this.fileNameItems, this.catalog.id, res);

        if (!result.ok) {
            console.error("Error updating qrcode");
        }
    }

    protected abstract convertItemToSave(items: T[]): any[];

    protected abstract convertToObject(items: any[]): T[];

















}
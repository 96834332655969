import Swal from "sweetalert2";
import { AvatarStyles, CartoonBodyType, IConvertBody, RealisticBodyType } from "../../services/service.interfaces";
import { VertexApi } from "../../services/vertex.api";
import { AvatarBabylonVertx } from "./avatar-babylon-vertx";
import "./avatar.scss";
import { AVATAR_MANIFEST_FILENAME, AVATAR_PHOTO_FILENAME, AVATAR_STYLE_KEY, AVATAR_TEXTURE_FILENAME, AVATAR_TSHIRT_REF_FILENAME, CARTOON_AVATAR_MANIFEST_FIX, MESH_GLTF_FILENAME, REALISTIC_BUST_AVATAR_MANIFEST_FIX, TYPE_AVATAR_TAG } from "../../utilities/constants";
import { ResourceUtils, VERTEXResource } from "../../utilities/resource-utilities";
import { TableApiKeyValuePair } from "../../services/table/table-api";
import { Config } from "../../../config";
import { Utils } from "../../utilities/utils";
import * as UPNG from "upng-js";
import { GltfStructure } from "../../utilities/gltf-utilities";

export class Avatar {
    protected readonly containerEl: HTMLElement;
    private _tableApiClient: VertexApi;
    private _bodyTypeSelected: RealisticBodyType = RealisticBodyType.Bust;
    private _avatarBabylonVertx: AvatarBabylonVertx;
    private _bearerToken: string;
    private _subjectID: string;

    rpmFrame;
    rpmFrameContainer;


    // set bodyTypeSelected(value:BodyType){
    //     this._bodyTypeSelected = value;

    //     const disabled = this._bodyTypeSelected === null;

    //     this.containerEl.querySelector<HTMLButtonElement>('button.upload').disabled = disabled;
    //     this.containerEl.querySelector<HTMLInputElement>('.drop-zone > input').disabled = disabled;
    // }

    get canvasEl(): HTMLCanvasElement {
        return this.containerEl.querySelector('canvas');
    }
    
    constructor(token: string, subjectID: string) {
        this._bearerToken = token;
        this._subjectID = subjectID;
        this.containerEl = document.querySelector<HTMLElement>("#avatar-container");
        this._tableApiClient = new VertexApi(token);

        this.rpmFrameContainer = this.containerEl.querySelector('#readyPlayerMe-iframe-container');
        this.rpmFrame = this.containerEl.querySelector('#readyPlayerMe-iframe');

        const closeIframeButton = this.rpmFrameContainer.querySelector("#close-iframe-button") as HTMLButtonElement;
        closeIframeButton.onclick = this.readyPlayerMeCloseBtn_onClick;

        const closeIframeButtonIcon = closeIframeButton.querySelector("img") as HTMLImageElement;
        Utils.injectSvg(closeIframeButtonIcon)

        this._drawUI(subjectID);


        window.addEventListener('resize', (e) => this._onResizeWindow(e));
    }

    private _drawUI(subjectID: string) {
        this._tableApiClient.table.getKeyValuePair(subjectID, AVATAR_STYLE_KEY).then((result) => {

            if (result && result.value) {
                const style = result.value as AvatarStyles;
                this._tableApiClient.avatar.getByStyle(style)
                    .then(async (result) => {
                        if (result && result.length > 0) {

                            Swal.fire({
                                icon: "info",
                                title: "Loading Avatar...",
                                allowEscapeKey: false,
                                allowOutsideClick: false,
                                showConfirmButton: false,
                                heightAuto: false
                            });
                            Swal.showLoading();

                            this._drawCreatedAvatar();
                            await this._loadAvatar(result, style);

                            Swal.hideLoading();
                            Swal.close();
                        }
                        else {
                            this._drawNewAvatar();
                        }
                    })
                    .catch(res => {
                        this._drawNewAvatar();
                    });
            } else {
                this._drawNewAvatar();
            }
        });
    }


    _onAvatarLoadingResult(result: boolean) {
        if (!result) {
            const self = this;
            self._tableApiClient.table.deleteKeyValuePair(self._subjectID, AVATAR_STYLE_KEY).then(() => {

                Swal.fire({
                    icon: "error",
                    title: "Failed to load avatar",
                    text: "Please create a new one now",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: true,
                    heightAuto: false
                }).then(() => {
                    self._drawUI(self._subjectID);
                });
            })
        }
    }

    _onResizeWindow(e) {
        const convasEl = this.canvasEl;

        if (!this.canvasEl) {
            return;
        }

        const parent = this.canvasEl.parentElement;

        this.canvasEl.width = parent.clientWidth;
        this.canvasEl.height = parent.clientHeight;
    }


    private async _drawCreatedAvatar() {
        const newEl = this.containerEl.querySelector<HTMLDivElement>('#new-avatar-container');
        const newRealisticEl = this.containerEl.querySelector<HTMLDivElement>('#new-realistic-avatar-container');
        const createdEl = this.containerEl.querySelector<HTMLDivElement>('#created-avatar-container');
        const currentAvatarTitle = createdEl.querySelector<HTMLElement>('h5');
        const babylonRenderCanvas = document.querySelector<HTMLCanvasElement>('#RenderCanvas');

        createdEl.hidden = false;
        newEl.hidden = true;
        newRealisticEl.hidden = true;
        babylonRenderCanvas.hidden = false;

        const tableApiPair = await this._tableApiClient.table.getKeyValuePair(this._subjectID, AVATAR_STYLE_KEY);

        const regenerateButton = createdEl.querySelector("#regenerate-avatar") as HTMLButtonElement;
        const regenerateButtonIcon = regenerateButton.querySelector("img") as HTMLImageElement;
        Utils.injectSvg(regenerateButtonIcon);

        const switchAvatarButton = createdEl.querySelector("#switch-avatar") as HTMLButtonElement;
        const switchAvatarButtonText = switchAvatarButton.querySelector<HTMLElement>('span');

        const switchAvatarButtonIcon = switchAvatarButton.querySelector("img") as HTMLImageElement;
        Utils.injectSvg(switchAvatarButtonIcon);


        if (tableApiPair && tableApiPair.value) {

            let onSwitch = () => { };

            if (tableApiPair.value as AvatarStyles == AvatarStyles.Realistic) {
                currentAvatarTitle.innerText = "Current Avatar - Realistic";
                switchAvatarButtonText.innerText = "Cartoon Avatar";

                onSwitch = async () => {
                    try {
                        const id = await this._tableApiClient.avatar.getByStyle(AvatarStyles.Cartoon);

                        if (id && id.length > 0) {
                            const avatarRes = await ResourceUtils.getResourceAsync(id, true, this._bearerToken);

                            if (avatarRes) {

                                Swal.fire({
                                    icon: "info",
                                    title: "Switching Avatar...",
                                    allowEscapeKey: false,
                                    allowOutsideClick: false,
                                    showConfirmButton: false,
                                    heightAuto: false
                                });
                                Swal.showLoading();
                                                                
                                await this._loadAvatar(id, AvatarStyles.Cartoon);
                                await this.updateAvatarStyle(AvatarStyles.Cartoon);
                                this._drawCreatedAvatar();

                                Swal.hideLoading();
                                Swal.close();
                            }
                            else {
                                this._drawNewCartoonAvatar();
                            }

                        }
                        else {
                            this._drawNewCartoonAvatar();
                        }
                    }
                    catch (error) {
                        console.error(error);

                    }

                }
            }
            else if (tableApiPair.value as AvatarStyles == AvatarStyles.Cartoon) {
                currentAvatarTitle.innerText = "Current Avatar - Cartoon";
                switchAvatarButtonText.innerText = "Realistic Avatar";

                onSwitch = async () => {
                    try {
                        const id = await this._tableApiClient.avatar.getByStyle(AvatarStyles.Realistic);

                        if (id && id.length > 0) {
                            const avatarRes = await ResourceUtils.getResourceAsync(id, true, this._bearerToken);

                            if (avatarRes) {
                                Swal.fire({
                                    icon: "info",
                                    title: "Switching Avatar...",
                                    allowEscapeKey: false,
                                    allowOutsideClick: false,
                                    showConfirmButton: false,
                                    heightAuto: false
                                });
                                Swal.showLoading();

                                await this._loadAvatar(id, AvatarStyles.Realistic);
                                await this.updateAvatarStyle(AvatarStyles.Realistic);
                                this._drawCreatedAvatar();

                                Swal.hideLoading();
                                Swal.close();
                            }
                            else {
                                this._drawNewRealisticAvatar();
                            }

                        }
                        else {
                            this._drawNewRealisticAvatar();
                        }
                    }
                    catch (error) {
                        console.error(error);
                    }
                }
            }

            switchAvatarButton.onclick = onSwitch;

            let onRegenerate = () => {};

            if (tableApiPair.value as AvatarStyles == AvatarStyles.Realistic) {
                onRegenerate = this._drawNewRealisticAvatar;
            }
            else if (tableApiPair.value as AvatarStyles == AvatarStyles.Cartoon){
                onRegenerate = this._drawNewCartoonAvatar;
            }

            regenerateButton.onclick = onRegenerate;

        }
        else{
            console.error("Table API pair not found for key: " + AVATAR_STYLE_KEY + " and table: " + this._subjectID);
        }

    }


    private async updateAvatarStyle(style: AvatarStyles) {
        let tableApiPair: TableApiKeyValuePair = {
            key: AVATAR_STYLE_KEY,
            value: style,
            table: this._subjectID
        };
        await this._tableApiClient.table.updateOrCreateKeyValuePair(tableApiPair);
    }

    private _drawNewAvatar() {
        const newEl = this.containerEl.querySelector<HTMLDivElement>('#new-avatar-container');
        const newRealisticEl = this.containerEl.querySelector<HTMLDivElement>('#new-realistic-avatar-container');
        const createdEl = this.containerEl.querySelector<HTMLDivElement>('#created-avatar-container');
        const babylonRenderCanvas = document.querySelector<HTMLCanvasElement>('#RenderCanvas');

        newEl.hidden = false;
        this.rpmFrameContainer.hidden = true;
        newRealisticEl.hidden = true;
        createdEl.hidden = true;
        babylonRenderCanvas.hidden = true;


        const newRealisticAvatarButton = newEl.querySelector("#create-new-realistic-avatar-button") as HTMLButtonElement;
        const newRealisticAvatarButtonIcon = newRealisticAvatarButton.querySelector("img") as HTMLImageElement;
        Utils.injectSvg(newRealisticAvatarButtonIcon);

        newRealisticAvatarButton.onclick = this._drawNewRealisticAvatar;

        const newCartoonAvatarButton = newEl.querySelector("#create-new-cartoon-avatar-button") as HTMLButtonElement;
        const newCartoonAvatarButtonIcon = newCartoonAvatarButton.querySelector("img") as HTMLImageElement;
        Utils.injectSvg(newCartoonAvatarButtonIcon);

        newCartoonAvatarButton.onclick = this._drawNewCartoonAvatar;
    }


    readyPlayerMeFrame_onMessage = (async (event) => {
        const json = this.tryParseJson(event.data);

        if(!json){
            return;
        }

        if (!json.source || json.source !== 'readyplayerme') {
            return;
        }
        // Susbribe to all events sent from Ready Player Me once frame is ready
        if (json.eventName && json.eventName === 'v1.frame.ready') {
            this.rpmFrame.contentWindow.postMessage(JSON.stringify({
                target: 'readyplayerme',
                type: 'subscribe',
                eventName: 'v1.**'
            }), '*');
        }
        // Get avatar GLB URL
        if (json.eventName && json.eventName === 'v1.avatar.exported') {

            console.log(`Avatar URL: ${json.data.url}`);
            this.readyPlayerMeCloseBtn_onClick();

            const id = await this._tableApiClient.avatar.getByStyle(AvatarStyles.Cartoon);
            await this._createCartoonAvatar(id, json.data.url);

        }
        // Get user id
        if (json.eventName && json.eventName === 'v1.user.set') {
            console.log(`User with id ${json.data.id} set: ${JSON.stringify(json)}`);
        }

    }).bind(this);

    _drawNewCartoonAvatar = (() => {
        this.rpmFrameContainer.hidden = false;
        window.addEventListener('message', this.readyPlayerMeFrame_onMessage);
        // document.addEventListener('message', this.readyPlayerMeFrame_onMessage);
        let domain = 'hevolus';
        this.rpmFrame.src = `https://${domain}.readyplayer.me/avatar?frameApi&bodyType=halfbody&clearCache`;
    }).bind(this);

    tryParseJson(data) {
        try {
            return JSON.parse(data);
        }
        catch (error) {
            return null;
        }
    }

    readyPlayerMeCloseBtn_onClick = (() => {
        this.rpmFrameContainer.hidden = true;
        this.rpmFrame.src = "";
        window.removeEventListener("message", this.readyPlayerMeFrame_onMessage);
        // document.removeEventListener("message", this.readyPlayerMeFrame_onMessage);
    }).bind(this);


    private _drawNewRealisticAvatar = (() => {
        const newEl = this.containerEl.querySelector<HTMLDivElement>('#new-avatar-container');
        const newRealisticEl = this.containerEl.querySelector<HTMLDivElement>('#new-realistic-avatar-container');
        const createdEl = this.containerEl.querySelector<HTMLDivElement>('#created-avatar-container');
        const babylonRenderCanvas = document.querySelector<HTMLCanvasElement>('#RenderCanvas');

        newRealisticEl.hidden = false;
        createdEl.hidden = true;
        newEl.hidden = true;
        babylonRenderCanvas.hidden = true;

        const el = this.containerEl.querySelector<HTMLDivElement>('#new-realistic-avatar-container');
        const dropZoneEl = this.containerEl.querySelector<HTMLDivElement>('.drop-zone');
        const inputEl = dropZoneEl.querySelector<HTMLInputElement>('input');

        el.hidden = false;

        el.querySelector<HTMLButtonElement>('#upload-avatar-photo-button').onclick = (e) => inputEl.click();
        // el.querySelectorAll<HTMLButtonElement>('.avatar-create-container > button').forEach(b => b.onclick = (e) => this._onSelectedBodyType(e.currentTarget as HTMLButtonElement));


        const backToAvatarButton = el.querySelector("#back-avatar-button") as HTMLButtonElement;

        backToAvatarButton.onclick = () => {
            this._drawUI(this._subjectID);
        }


        dropZoneEl.ondragleave = () => dropZoneEl.classList.remove("drop-zone--over");
        dropZoneEl.ondragend = () => dropZoneEl.classList.remove("drop-zone--over");
        dropZoneEl.ondragover = (e) => {
            e.preventDefault();

            // if(this._bodyTypeSelected === null){
            //     return false;
            // }

            dropZoneEl.classList.add("drop-zone--over");
        };
        dropZoneEl.ondrop = async (e) => {
            e.preventDefault();

            // if(this._bodyTypeSelected === null){
            //     return false;
            // }

            if (e.dataTransfer.files.length) {
                const file = e.dataTransfer.files[0];

                const id = await this._tableApiClient.avatar.getByStyle(AvatarStyles.Realistic);
                await this._uploadAndCreateRealisticAvatar(file, id);
            }

            dropZoneEl.classList.remove("drop-zone--over");
        };
        inputEl.onchange = async (e) => {
            // if(this._bodyTypeSelected === null){
            //     return false;
            // }

            if (inputEl.files.length > 0) {
                const file = inputEl.files[0];

                const id = await this._tableApiClient.avatar.getByStyle(AvatarStyles.Realistic);
                await this._uploadAndCreateRealisticAvatar(file, id);
            }

            inputEl.value = "";
        };
    }).bind(this);

    // private _onSelectedBodyType(buttonEl: HTMLButtonElement){
    //     this.bodyTypeSelected = buttonEl.dataset.bodyType as BodyType;
    // }

    private async getOrCreateNewAvatarResource(avatarStyle: AvatarStyles): Promise<{ privateId: string, res: VERTEXResource }> {

        let avatarPrivateId: string = null;
        let avatarPrivateRes: VERTEXResource = null;

        avatarPrivateId = await this._tableApiClient.avatar.createStyle(avatarStyle);
        if (avatarPrivateId) {
            avatarPrivateRes = await ResourceUtils.getResourceData(avatarPrivateId, this._bearerToken);

            if (avatarPrivateRes == null) {
                throw new Error("Cannot get avatar resource data");
            }
            
            await this._tableApiClient.resources.resetResourceAssets(avatarPrivateId);

            if (!avatarPrivateRes.tags.includes(TYPE_AVATAR_TAG)) {
                avatarPrivateRes.tags.push(TYPE_AVATAR_TAG);
            }

            const tagsPayload = {
                Id: avatarPrivateRes.id,
                Name: avatarPrivateRes.name,
                Tags: avatarPrivateRes.tags,
                Type: avatarPrivateRes.type
            }

            await ResourceUtils.postResourceData(avatarPrivateId, JSON.stringify(tagsPayload), this._bearerToken);
        }
        else {
            throw new Error("Cannot create avatar resource");
        }

        return { privateId: avatarPrivateId, res: avatarPrivateRes };
    }

    private async _createCartoonAvatar(id, fileUrl) {
        let avatarPrivateId: string;

        try {
            Swal.fire({
                icon: "info",
                title: "Creating Avatar...",
                footer: "The process might take some minutes.",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                heightAuto: false
            });
            
            Swal.showLoading();

            let newAvatarRes = await this.getOrCreateNewAvatarResource(AvatarStyles.Cartoon);
            avatarPrivateId = newAvatarRes.privateId;

            let bodyType = await this._tableApiClient.task.convertFaceToAvatarCartoon(avatarPrivateId, `${fileUrl}?textureAtlas=none`);

            if (bodyType === CartoonBodyType.HalfBody) {
                // get our fixed manifest and upload it to the resource because the one generated by the API has wrong position and rotation
                const fixedManifestRes = await ResourceUtils.getAssetFromResource(Config.AVATAR_RIG_RESOURCE_ID, CARTOON_AVATAR_MANIFEST_FIX);

                if (!fixedManifestRes.ok) {
                    Swal.hideLoading();
                    Swal.close();

                    throw new Error(`Error while downloading fixed ${AVATAR_MANIFEST_FILENAME}`);
                }

                const res = await ResourceUtils.postAssetToResource(AVATAR_MANIFEST_FILENAME, avatarPrivateId, await fixedManifestRes.text());

                if (!res.ok) {
                    Swal.hideLoading();
                    Swal.close();

                    throw new Error(`Error while uploading fixed ${AVATAR_MANIFEST_FILENAME}`);
                }

                await this._tableApiClient.task.convertImgToDds(avatarPrivateId);
                //await this.replaceAvatarTextureAlpha(avatarPrivateId, AvatarStyles.Cartoon);

                await this._tableApiClient.avatar.publish(avatarPrivateId, AvatarStyles.Cartoon);
                await this.updateAvatarStyle(AvatarStyles.Cartoon);

                Swal.hideLoading();
                Swal.close();
            }
            else{
                await this.restoreAvatarResource(avatarPrivateId);

                Swal.hideLoading();
                await Swal.fire({
                    icon: "error",
                    title: "Failed to load avatar",
                    html: "Your avatar is a full body.<br>Please create a half body avatar.",
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    heightAuto: false
                });
            }
        }
        catch (error) {
            console.log(error);

            await this.restoreAvatarResource(avatarPrivateId);

            Swal.hideLoading();
            await Swal.fire({
                icon: 'error',
                title: 'Oops.. An error occurred while creating the Avatar',
                footer: "Please try again!",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: true,
                heightAuto: false
            });
        }
        finally {
            this._drawUI(this._subjectID);
        }
    }

    //Restores the avatar resource from the published resource
    private async restoreAvatarResource(resourceId: string) {
        let avatarPrivateRes: VERTEXResource;
        let avatarPrivateId: string;
        let avatarPublishedId: string;

        if (resourceId) {
            avatarPrivateRes = await ResourceUtils.getResourceAsync(resourceId, false, this._bearerToken);

            if (avatarPrivateRes) {
                avatarPrivateId = avatarPrivateRes.id;

                if (avatarPrivateRes.publishedResources != null && avatarPrivateRes.publishedResources.length > 0) {
                    avatarPublishedId = avatarPrivateRes.publishedResources[0];
                }

                await this._tableApiClient.resources.resetResourceAssets(avatarPrivateId);
                
                if (avatarPublishedId && avatarPublishedId.length > 0) {
                    let avatarPublishedResource = await ResourceUtils.getResourceData(avatarPublishedId, this._bearerToken);

                    if(avatarPublishedResource != null){
                        const copied = await ResourceUtils.copyAssetsToResource(avatarPublishedId, avatarPrivateId, avatarPublishedResource.resourceKeys, this._bearerToken);
                        
                        if(copied){
                            await ResourceUtils.republishResource(avatarPrivateId, this._bearerToken);
                        }
                        else{
                            console.log(`Failed to copy assets from Avatar published resource ${avatarPublishedId} to Avatar private resource ${avatarPrivateId}`);
                        }
                    }
                }
            }
        }
    }

    private async _uploadAndCreateRealisticAvatar(file: File, id: string = null) {
        let avatarPrivateId: string;

            try {
            Swal.fire({
                icon: "info",
                title: "Creating Avatar...",
                footer: "The process might take some minutes.",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                heightAuto: false
            });
            Swal.showLoading();

            let newAvatarRes = await this.getOrCreateNewAvatarResource(AvatarStyles.Realistic);
            avatarPrivateId = newAvatarRes.privateId;

            const ext = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
            const fileName = `${AVATAR_PHOTO_FILENAME}${ext}`;
            const uploaded = await this._tableApiClient.resources.uploadFile(avatarPrivateId, fileName, file);

            if (!uploaded) {
                Swal.hideLoading();
                Swal.close();
                throw new Error('Cannot upload photo');
            }

            await this._tableApiClient.task.convertFaceToAvatarRealistic(avatarPrivateId, fileName, this._bodyTypeSelected);

            // get our fixed manifest and upload it to the resource because the one generated by the API has wrong position and rotation and doesn't have elbow tracking
            const fixedManifestRes = await ResourceUtils.getAssetFromResource(Config.AVATAR_RIG_RESOURCE_ID, REALISTIC_BUST_AVATAR_MANIFEST_FIX);
            if (!fixedManifestRes.ok) {
                Swal.hideLoading();
                Swal.close();

                throw new Error(`Error while downloading fixed ${AVATAR_MANIFEST_FILENAME}`);
            }

            const res = await ResourceUtils.postAssetToResource(AVATAR_MANIFEST_FILENAME, avatarPrivateId, await fixedManifestRes.text());

            if (!res.ok) {
                Swal.hideLoading();
                Swal.close();

                throw new Error(`Error while uploading fixed ${AVATAR_MANIFEST_FILENAME}`);
            }

            //await this.replaceAvatarTextureAlpha(avatarPrivateId, AvatarStyles.Realistic);

            await this._tableApiClient.avatar.publish(avatarPrivateId, AvatarStyles.Realistic);
            await this.updateAvatarStyle(AvatarStyles.Realistic);

            Swal.hideLoading();
            Swal.close();

        }
        catch (error) {
            console.log(error);

            await this.restoreAvatarResource(avatarPrivateId)

            await Swal.fire({
                icon: 'error',
                title: 'Oops.. An error occurred while creating the Avatar',
                footer: "Please try again!",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: true,
                heightAuto: false
            });
        }
        finally {
            this._drawUI(this._subjectID);
        }
    }


    private async applyAvatarFixes(avatarId: string, style: AvatarStyles) {
        const currentAvatarRes = await ResourceUtils.getResourceAsync(avatarId, true);

        if (currentAvatarRes) {
            const avatarPrivateId = currentAvatarRes.publishParent == "" ? avatarId : currentAvatarRes.publishParent;
            const manifestIsUpToDate = await this.checkManifestIsUpToDate(avatarPrivateId, style);

            if (!manifestIsUpToDate) {
                await this.replaceAvatarTextureAlpha(avatarPrivateId, style);
                await this.updateAvatarManifest(avatarPrivateId, style);
            }
        }
        else {
            console.log(`Failed to get avatar res id and apply avatar fixes`);
        }
    }

    private async checkManifestIsUpToDate(avatarPrivateId: string, style: AvatarStyles): Promise<boolean> {
        let result = false;

        const currentAvatarRes = await ResourceUtils.getResourceAsync(avatarPrivateId, false);

        if (currentAvatarRes?.resourceKeys?.length && currentAvatarRes.resourceMD5Hashes?.length) {
            const currentIndex = currentAvatarRes.resourceKeys.findIndex((key) => key === AVATAR_MANIFEST_FILENAME);

            if (currentIndex !== -1) {
                const currentAvatarManifestHash = currentAvatarRes.resourceMD5Hashes[currentIndex];
                const fixedManifestRes = await ResourceUtils.getResourceAsync(Config.AVATAR_RIG_RESOURCE_ID, true);

                if (fixedManifestRes?.resourceKeys?.length && fixedManifestRes.resourceMD5Hashes?.length) {
                    let fixedIndex;
                    let fixedAvatarManifestHash;

                    if (style == AvatarStyles.Cartoon) {
                        fixedIndex = fixedManifestRes.resourceKeys.findIndex((key) => key === CARTOON_AVATAR_MANIFEST_FIX);
                    }
                    else {
                        fixedIndex = fixedManifestRes.resourceKeys.findIndex((key) => key === REALISTIC_BUST_AVATAR_MANIFEST_FIX);
                    }

                    if (fixedIndex !== -1) {
                        fixedAvatarManifestHash = fixedManifestRes.resourceMD5Hashes[fixedIndex];
                    }

                    result = fixedAvatarManifestHash != null && currentAvatarManifestHash != null && fixedAvatarManifestHash == currentAvatarManifestHash;
                }
            }
        }

        return result;
    }
    
    private async updateAvatarManifest(avatarPrivateId: string, style: AvatarStyles) {
        const fixedManifestFilename = (style == AvatarStyles.Cartoon) ? CARTOON_AVATAR_MANIFEST_FIX : REALISTIC_BUST_AVATAR_MANIFEST_FIX;
        const fixedManifestRes = await ResourceUtils.getAssetFromResource(Config.AVATAR_RIG_RESOURCE_ID, fixedManifestFilename);

        if (!fixedManifestRes.ok) {
            console.error('Error while downloading fixed manifest.json');
        }
        else {
            const resp = await ResourceUtils.postAssetToResource(AVATAR_MANIFEST_FILENAME, avatarPrivateId, await fixedManifestRes.text());
            
            if (!resp.ok) {
                console.error('Error while uploading fixed manifest.json');
            }
            else {
                const repubResp = await ResourceUtils.republishResource(avatarPrivateId);
                
                if (!repubResp?.ok) {
                    console.error('Error while republishing avatar');
                }
            }
        }
    }

    private async replaceAvatarTextureAlpha(avatarPrivateId: string, style: AvatarStyles) {
        if(style === AvatarStyles.Cartoon){
            const avatarGltfResp = await ResourceUtils.getAssetFromResource(avatarPrivateId, MESH_GLTF_FILENAME);

            if(!avatarGltfResp.ok){ //there was some issues with the avatar gltf file
                return;
            }

            const avatarGltf = await avatarGltfResp.json() as GltfStructure;

            if(avatarGltf?.materials?.length > 1){ //this means the Cartoon avatar is the one with separeted meshes and textures, so no need to replace the alpha mask
                return;
            }
        }

        const tshirtSetRes = await ResourceUtils.getAssetFromResource(Config.AVATAR_RIG_RESOURCE_ID, AVATAR_TSHIRT_REF_FILENAME);

        if (!tshirtSetRes.ok) {
            throw new Error('Error while downloading avatar-settings.json');
        }

        const tshirtSet = await tshirtSetRes.json();
        let alphaMaskImageName = tshirtSet[style].alphaMaskImageName

        if (alphaMaskImageName) {
            if (alphaMaskImageName.endsWith('.jpg')) {
                alphaMaskImageName = alphaMaskImageName.replace('.jpg', '.png');
            }

            const alphaMaskRes = await ResourceUtils.getAssetFromResource(Config.AVATAR_RIG_RESOURCE_ID, alphaMaskImageName);
            if (!alphaMaskRes.ok) {
                throw new Error('Error while downloading alpha mask image');
            }
            const alphaMaskBuffer = await alphaMaskRes.arrayBuffer();

            const avatarTextureRes = await ResourceUtils.getAssetFromResource(avatarPrivateId, AVATAR_TEXTURE_FILENAME);
            if (!avatarTextureRes.ok) {
                throw new Error('Error while downloading avatar texture');
            }
            const avatarTextureBuffer = await avatarTextureRes.arrayBuffer();

            const decodedAlfaImage = UPNG.decode(alphaMaskBuffer);
            const decodedAvatarImage = UPNG.decode(avatarTextureBuffer);

            if (decodedAlfaImage.width !== decodedAvatarImage.width || decodedAlfaImage.height !== decodedAvatarImage.height) {
                throw new Error('Alpha mask and avatar texture have different dimensions');
            }

            const decodedAlfaImageBuffer = new Uint8Array(decodedAlfaImage.data);
            const decodedAvatarImageBuffer = new Uint8Array(decodedAvatarImage.data);

            const splittedAlfaImage = Utils.splitRGBAChannels(decodedAlfaImageBuffer);
            const splittedAvatarImage = Utils.splitRGBAChannels(decodedAvatarImageBuffer);

            const mergedChannels = Utils.mergeRGBAChannels(splittedAvatarImage.R, splittedAvatarImage.G, splittedAvatarImage.B, splittedAlfaImage.A);
            const encodedImage = UPNG.encode([mergedChannels.buffer], decodedAvatarImage.width, decodedAvatarImage.height, 0);

            const res = await ResourceUtils.postAssetToResource(AVATAR_TEXTURE_FILENAME, avatarPrivateId, encodedImage);
            if (!res.ok) {
                throw new Error('Error while uploading fixed avatar texture');
            }

            const body: IConvertBody = {
                convertFiles: [AVATAR_TEXTURE_FILENAME]
            }
            await this._tableApiClient.task.convertImgToDds(avatarPrivateId, body);
        }
    }

    private async _loadAvatar(avatarId: string, style: AvatarStyles) {
        if (!this._avatarBabylonVertx) {
            this._avatarBabylonVertx = new AvatarBabylonVertx(this.canvasEl, this._bearerToken);
        }
        else{
            let gltfModelView = Vertex.Globals.runtime.componentSystem.systems.get("GltfModel").view as Vertex.NodeComponentModel.GltfModelComponentView;
        
            let meshSources = Array.from(gltfModelView.meshSources.values());
            for(let i = meshSources.length - 1; i >= 0; i--)
            {
                meshSources[i].dispose();
            }
            
            gltfModelView.meshSources.clear();
        }

        var promise = new Promise<boolean>(async (resolve, reject) => {
            try{
                    await this.applyAvatarFixes(avatarId, style);
        
                    this._avatarBabylonVertx.loadAvatar(avatarId, style, ((result) => {
                    this._onAvatarLoadingResult(result);
                    
                    resolve(result);
                }).bind(this));
            }
            catch (error){
                console.error(error);
                resolve(false);
            }
        });

        await promise;
    }

    destroy() {
        window.removeEventListener('resize', (e) => this._onResizeWindow(e));
    }
}
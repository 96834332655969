import { AugmentedStoreAssembly } from '../../../../AugmentedStoreAssembly';
import { getComponentJsonFromResource, postComponentJsonToResource } from '../../../utilities/utils';
import { LocalAvatar } from '../EditorComponents/Avatar/localAvatar';

export class PointOfInterest {
    name: string;
    position: number[];
    rotation: number[];
    alpha: number;
    beta: number;
    radius: number;
}

export class CustomPointsOfInterestComponent extends AugmentedStoreAssembly.PointsOfInterestComponent {
    camera: BABYLON.ArcRotateCamera;
    avatar: LocalAvatar;
    pointsOfInterest: PointOfInterest[] = [];
    isReady: Promise<void>;

    postComponentJsonToResource = (async (isEditorVersion: boolean = true) => {
        await postComponentJsonToResource(Vertex.Globals.spaceId, "PointsOfInterest", this.node.id, isEditorVersion, JSON.stringify(this.pointsOfInterest));
    }).bind(this);

    
    isPlacedOnNavmesh(poiIndex:number, rayLength: number = 2): boolean {
        const scene = Vertex.Globals.runtime.scene;
        const poi = this.pointsOfInterest[poiIndex];
        const poiPos = poi.position;
        const rayOrigin = new BABYLON.Vector3(poiPos[0], poiPos[1] + 0.02, poiPos[2]);
        const ray = new BABYLON.Ray(rayOrigin, BABYLON.Vector3.Down(), rayLength);

        let predicate = function (mesh) {
            return BABYLON.Tags.MatchesQuery(mesh, "navmesh");
        }

        const picks = scene.multiPickWithRay(ray, predicate);

        return picks.length > 0;
    }
}
export class PointsOfInterestComponentView extends Vertex.NodeComponentModel.ComponentViewBase {
    constructor() {
        super();
    }

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        const customComp = component as CustomPointsOfInterestComponent;

        Vertex.Globals.event.on("avatarready", (avatar: LocalAvatar) => {
            customComp.avatar = avatar;
        })

        customComp.isReady = new Promise<void>(async (resolve, reject) => {

            let res = await getComponentJsonFromResource(Vertex.Globals.spaceId, "PointsOfInterest", node.id, true);

            if (res.ok) {
                customComp.pointsOfInterest = await res.json() as PointOfInterest[];
            }
            else {
                res = await getComponentJsonFromResource(Vertex.Globals.spaceId, "PointsOfInterest", node.id, false);

                if (res.ok) {
                    customComp.pointsOfInterest = await res.json() as PointOfInterest[];

                    await customComp.postComponentJsonToResource(true);
                } else {
                    customComp.pointsOfInterest = [];

                    await customComp.postComponentJsonToResource(true);
                }
            }

            customComp.onChanged.on(() => {
                Vertex.Globals.event.fire("hevolus:PointsOfInterestComponentChanged", customComp);
            });

            const beforeSaveSpace = async () => {
                await customComp.postComponentJsonToResource(false);
            }

            customComp.onRemoved.on(async() => {
                Vertex.Globals.event.off("hevolus:beforeSaveSpace", beforeSaveSpace);

                customComp.pointsOfInterest = [];
                await customComp.postComponentJsonToResource(true);
            });

            Vertex.Globals.event.on("hevolus:beforeSaveSpace", beforeSaveSpace);
            
            customComp.triggerOnChanged();

            resolve();
        });
    }


    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {

    }
}

export class PointsOfInterestComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new CustomPointsOfInterestComponent();
    }
    constructor() {
        super("PointsOfInterest", new PointsOfInterestComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}
import { AugmentedStoreAssembly } from "../../../../AugmentedStoreAssembly";

export class DirectionalLightComponentView extends Vertex.NodeComponentModel.ComponentViewBase {


    constructor() {
        super();
    }

    lightCounter = 0;
    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        var comp = component as AugmentedStoreAssembly.DirectionalLightComponent;
        
        var scene = node.viewNode.getScene();
        var light = new BABYLON.DirectionalLight("DirectionalLight" + (this.lightCounter++).toString(), BABYLON.Vector3.Forward(), scene);
        light.parent = node.viewNode;
        
        this.createDirectionalGizmo(node.viewNode);

        component.onChanged.on(() => {
            light.range = comp.range;
            light.intensity = comp.intensity;
            light.radius = comp.radius;
            light.diffuse.r = comp.diffuseR;
            light.diffuse.g = comp.diffuseG;
            light.diffuse.b = comp.diffuseB;
            light.specular.r = comp.diffuseR;
            light.specular.g = comp.diffuseG;
            light.specular.b = comp.diffuseB;
        });

        node.event.on("Lighting:DiffuseChanged", (val: string) => {
            comp.diffuseR = BABYLON.Color3.FromHexString(val).r;
            comp.diffuseG = BABYLON.Color3.FromHexString(val).g;
            comp.diffuseB = BABYLON.Color3.FromHexString(val).b;
            comp.triggerOnChanged();
        });
        node.event.on("Lighting:SpecularChanged", (val: string) => {
            comp.specularR = BABYLON.Color3.FromHexString(val).r;
            comp.specularG = BABYLON.Color3.FromHexString(val).g;
            comp.specularB = BABYLON.Color3.FromHexString(val).b;
            comp.triggerOnChanged();
        });

        node.event.on("Lighting:IntensityChanged", (val: number) => { comp.intensity = val; comp.triggerOnChanged(); });
        node.event.on("Lighting:RangeChanged", (val: number) => { comp.range = val; comp.triggerOnChanged(); });
        node.event.on("Lighting:RadiusChanged", (val: number) => { comp.radius = val; comp.triggerOnChanged(); });

        comp.triggerOnChanged();
    }

    createDirectionalGizmo(viewNode: any)
    {
        var scene = Vertex.Globals.runtime.scene;
        var thickness = 0.5;
        var cylinder = BABYLON.CylinderBuilder.CreateCylinder("cylinder", { diameterTop: 0, height: 0.075, diameterBottom: 0.0375 * (1 + (thickness - 1) / 4), tessellation: 96 }, scene);
        var line = BABYLON.CylinderBuilder.CreateCylinder("cylinder", { diameterTop: 0.005 * thickness, height: 0.2, diameterBottom: 0.005 * thickness, tessellation: 96 }, scene);

        var sphere = BABYLON.MeshBuilder.CreateSphere("Name", {diameter : .05}, scene);
        sphere.parent = viewNode;

        cylinder.parent = viewNode;
        cylinder.rotation.x = Math.PI / 2;
        cylinder.position.z += 0.2;

        line.parent = viewNode;
        line.position.z += 0.2 / 2;
        line.rotation.x = Math.PI / 2;


    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {

    }

    update(): void {
    }
}

export class DirectionalLightComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new AugmentedStoreAssembly.DirectionalLightComponent();
    }

    constructor() {
        super("DirectionalLight", new DirectionalLightComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());

        Vertex.Globals.event.on("Vertx:CreateDirectionalLight", ()=>{
            
            let space = Vertex.Globals.runtime.space as Vertex.Space;

            let lightNode = space.createNode("Directional Light");
            lightNode.addComponent("NodeLockable");
            space.addNode(lightNode);

            lightNode.addComponent("Transform") as Vertex.NodeComponentModel.TransformComponent;

            let lightComp = new AugmentedStoreAssembly.DirectionalLightComponent();
            lightComp.diffuseR = lightComp.diffuseG = lightComp.diffuseB = lightComp.specularR = lightComp.specularG = lightComp.specularB = 1;
            lightComp.range = 1;
            lightComp.intensity = 1;
            lightComp.radius = 0.0001;
            lightNode.addComponent("DirectionalLight", lightComp);

        })
        
    }
}
import { Config } from "../../config";
import { AvatarApi } from "./table/avatar-api";
import { ResourceApi } from "./table/resource-api";
import { TaskApi } from "./table/task-api";
import { TableApi } from "./table/table-api";

export class VertexApi{
    private _baseUrl: string;
    constructor(private token: string){
        this._baseUrl = `https://${Config.VERTEX_URL_BASE}`;
    }

    get avatar () {
        return new AvatarApi(this.token, this._baseUrl);
    }

    get resources(){
        return new ResourceApi(this.token, this._baseUrl);
    }

    get task(){
        return new TaskApi(this.token, this._baseUrl);
    }

    get table(){
        return new TableApi(this.token, this._baseUrl);
    }
}
import { Utils, loadTemplate } from "../../../utilities/utils";
import { QRUtils } from "./qrutils";
import { IQRItem, IQROptions, QRCODE_BACKGROUND_DEFAULT, QRCODE_COLOR_DEFAULT, QRCODE_HEIGHT, QRCODE_QUALITY_DEFAULT, QRCODE_WIDHT as QRCODE_WIDTH, QualityQR } from "./qr-interfaces";
import "./qr-catalog.scss";
import { QRModal } from "./qrmodal";
import { CatalogBase } from "../catalog-base";
import { ICatalogItem } from "../catalog.interfaces";
import { ResourceUtils, VERTEXResource } from "../../../utilities/resource-utilities";
import { QRS_CATALOG_FILENAME } from "../../../utilities/constants";

const QR_PREVIEW_TEMPLATE: string = "#qr-preview-template";
export class QRCatalog extends CatalogBase<IQRItem> {

    protected convertItemToSave(items: IQRItem[]): any[] {
        return items;
    }

    protected convertToObject(items: any[]): IQRItem[] {
        return items.sort((a, b) => {
            const d = new Date(a.dateTimeCreated);
            const d2 = new Date(b.dateTimeCreated);

            return d2.getTime() - d.getTime();
        });
    }

    constructor(catalog:VERTEXResource) {
        super(catalog, QRS_CATALOG_FILENAME, QRModal);
        this.catalog_item_template = "#catalog-item-qr-block-template";
        this.baseClsEl = "catalog-item-qr";
    }

    searchFilterFunction(item: IQRItem, textSearch: string):boolean {
        const res = super.searchFilterFunction(item as ICatalogItem, textSearch);

        if(res){
            return true;
        }

        return item.description?.includes(textSearch);
    };

    protected drawItem(item: IQRItem, itemEl: HTMLElement): void {
        const previewEl: HTMLDivElement = itemEl.querySelector('.catalog-item-preview');
        previewEl.onclick = (e) => this._showPreview(e);
        const descriptionEl = itemEl.querySelector<HTMLElement>('.catalog-item-description');
        const timeEl = itemEl.querySelector<HTMLElement>('.catalog-item-time');

        descriptionEl.hidden = false;
        timeEl.hidden = false;
        itemEl.querySelector<HTMLElement>('.catalog-item-preview').hidden = false;
        itemEl.querySelector<HTMLElement>('span.download').hidden = false;
        itemEl.querySelector<HTMLElement>('.catalog-item-name').innerText = item.name;
        descriptionEl.innerText = item.description;
        descriptionEl.title = item.description;
        
        timeEl.innerText = this._calculateTimeSince(item.dateTimeCreated);

        const options = this._getOptions(previewEl.clientWidth, previewEl.clientHeight);
        QRUtils.downloadQRCode(item, options, (url: string, base64: string) => {
            previewEl.style.backgroundImage = `url(${base64})`;
        });
        
        itemEl.querySelector<HTMLElement>('.edit').onclick = (e) => this._onClickItem(e);

        itemEl.querySelector<HTMLElement>('.download').onclick = (e) => this._onDownloadQRCode(e);
        itemEl.querySelector<HTMLElement>('.delete').onclick = (e) => this.onClickDeleteItem(e);
    }

    private _showPreview(e){
        const qrEl = (e.currentTarget as HTMLElement).closest<HTMLElement>(`div.${this.baseClsEl}`);

        if (!qrEl) {
            return;
        }

        const template = loadTemplate(QR_PREVIEW_TEMPLATE);
        const previewContainerEl = this.containerEl.appendChild(template);
        previewContainerEl.dataset.id = qrEl.id;

        this._handleChange(previewContainerEl);

        const imageContainerEl = this.containerEl.querySelector<HTMLElement>('.qr-image-container');
        
        const inputSlider = imageContainerEl.querySelector<HTMLInputElement>('#slider-bg');
        inputSlider.oninput = (e) => this._handleChange((e.currentTarget as HTMLElement).closest('div.qr-preview-container'));
        
        const qualitySelect = imageContainerEl.querySelector<HTMLInputElement>('select');
        qualitySelect.onchange = (e) => this._handleChange((e.currentTarget as HTMLElement).closest('div.qr-preview-container'));

        const downloadButton = previewContainerEl.querySelector<HTMLElement>('span.download');
        downloadButton.onclick = (e) => (e.currentTarget as HTMLElement).querySelector('a').click();

        const closeButton = previewContainerEl.querySelector<HTMLElement>('span.close-btn');
        closeButton.onclick = (e) => previewContainerEl.remove();

    }

    private _handleChange = (previewContainerEl: HTMLElement) => {
        const inputSlider = previewContainerEl.querySelector<HTMLInputElement>('#slider-bg');
        const qualitySelect = previewContainerEl.querySelector<HTMLInputElement>('select');
        
        const qr: IQRItem = this.itemList.find((q) => q.id === previewContainerEl.dataset.id);

        const rgbValue = Math.abs(parseInt(inputSlider.value));
        
        const hexValue = rgbValue.toString(16);
        const hexColor = `#${hexValue}${hexValue}${hexValue}`;

        this._generatePreviewQR(qr, hexColor, qualitySelect.value as QualityQR, undefined, (url: string, urlBase64: string) => {
            const downloadButton = previewContainerEl.querySelector<HTMLElement>('span.download');

            const anchorEl = downloadButton.firstElementChild as HTMLAnchorElement;

            anchorEl.download = `qrcode_${qr.name}.png`;
            anchorEl.href = url;

            previewContainerEl.querySelector<HTMLElement>('.qr-image').style.backgroundImage = `url(${urlBase64})`;
        });


    };    

    private _generatePreviewQR(qr: IQRItem, sliderValue: string, qualityValue: QualityQR, qrColor: string, callback: (url: string, urlBase64: string) => void) {
        const options = this._getOptions(undefined, undefined, sliderValue, qrColor, qualityValue);
        QRUtils.downloadQRCode(qr, options, callback);
    }

    private async _onDownloadQRCode(e) {
        const qrEl = (e.currentTarget as HTMLElement).closest(`div.${this.baseClsEl}`);

        if (!qrEl) {
            return;
        }

        const item: IQRItem = this.itemList.find((q) => q.id === qrEl.id);
        if (!item) {
            return;
        }

        const options = this._getOptions();
        QRUtils.downloadQRCode(item, options, (url: string, urlBase64: string) => {
            const anchorEl = qrEl.querySelector<HTMLAnchorElement>('.button-container a');

            anchorEl.download = `qrcode_${item.name}.png`;
            anchorEl.href = url;
            anchorEl.click();
        });
    }

    private _getOptions(width: number = QRCODE_WIDTH, height: number = QRCODE_HEIGHT, backgroundColor: string = QRCODE_BACKGROUND_DEFAULT, qrColor: string = QRCODE_COLOR_DEFAULT,  quality: QualityQR = QRCODE_QUALITY_DEFAULT): IQROptions {
        return {
            errorCorrectionLevel: quality,
            type: 'image/png',
            width: width,
            height: height,
            margin: 1,
            color: {
                light: backgroundColor,
                dark: qrColor
            }
        };
    }

    private _calculateTimeSince(date: string) {
        if (!date) return "";

        const dateParsed = new Date(date);
        const dateTime = dateParsed.getTime();
        const dateTimeNow = new Date().getTime();
        const seconds = Math.floor((dateTimeNow - dateTime) / 1000);
        const timeline = [
            // {
            //     time: 31536000, text: 'year'
            // },
            // {
            //     time: 2592000, text: 'month'
            // },
            {
                time: 86400, text: 'day'
            },
            {
                time: 3600, text: 'hour'
            },
            {
                time: 60, text: 'minute'
            }
        ];

        let idxTimeline = -1;

        for (let i = 0; i < timeline.length; i++) {
            const interval = Math.floor(seconds / timeline[i].time);

            if (interval >= 1) {
                idxTimeline = i;
                break;
            }
        }

        if (idxTimeline === -1) {
            return `${seconds} second${seconds === 0 || seconds > 1 ? 's' : ''} ago`;
        }
        
        const el = timeline[idxTimeline];
        const value = Math.floor(seconds / el.time);
        //day
        if(idxTimeline === 0){
            if(value === 1){
                return `${value} ${el.text} ago`;
            }

            const day = dateParsed.getUTCDate();
            const month = dateParsed.getUTCMonth() + 1;
            const year = dateParsed.getUTCFullYear();

            return `${day}-${month}-${year}`;
        }


        return `${value} ${el.text}${value === 0 || value > 1 ? 's' : ''} ago`;
    }


    async getQRList(): Promise<IQRItem[]> {
        let res = await ResourceUtils.getAssetFromResource(this.catalog.id, QRS_CATALOG_FILENAME);

        if (!res.ok) {
            console.log(`couldnt find ${QRS_CATALOG_FILENAME} on resource`);
            return;
        }

        const result = await res.json() as IQRItem[];

        return result.sort((a, b) => {
            const d = new Date(a.dateTimeCreated);
            const d2 = new Date(b.dateTimeCreated);

            return d2.getTime() - d.getTime();
        })
    }

}
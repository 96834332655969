import { isEqual } from "lodash";
import { Profile } from "oidc-client";
import { VertexApi } from "../../services/vertex.api";
import { ButtonType, IButton, IModel } from "./sidebar.interfaces";
import "./sidebar.scss";
import { ResourceUtils } from "../../utilities/resource-utilities";
import { AVATAR_PHOTO_FILENAME, PORTAL_VERSION, RESOURCE_API_URI } from "../../utilities/constants";



export class SidebarElement extends HTMLElement implements IModel {
    private _userProfile: Profile = null;
    private _buttonList: IButton[] = [];
    activeButtonId: string;
    _panelFloatingChild: HTMLElement;

    constructor() {
        super();
    }

    set buttons(buttons: IButton[]) {
        if (isEqual(buttons, this._buttonList)) {
            return;
        }

        this._buttonList = buttons;
        this._updateModel();
    }

    set userProfile(profile: Profile) {
        this._userProfile = profile;
        this._updateModel();
    }

    get isOpened() {
        return this.firstElementChild?.classList.contains('open');
    }

    onclickitem: (e: Event, button: IButton) => void;
    onlogout: (e: Event) => void;

    add(button: IButton) {
        const btn = this.deepSearchItem(this._buttonList, button.id)

        if (!!btn) {
            return;
        }

        this._buttonList.push(button);
        this._updateModel();
    }

    addToParent(parentId: string, button: IButton) {
        const parent = this._buttonList.find(b => b.id === parentId);

        if (!parent) {
            return;
        }

        const btn = this.deepSearchItem(this._buttonList, button.id)
        if (!!btn) {
            return;
        }

        parent.child.push(button);
        this._updateModel();
    }

    _wireEvents() {
        this.querySelector('button.close').addEventListener('click', (evt) => this.close());
        setTimeout(() => this.close(), 2000);
        this.querySelector<HTMLButtonElement>('.button-logout').onclick = (e) => this._onLogout(e);

        this.querySelectorAll<HTMLElement>('ul.sidebar-list-ul > li').forEach(e => {
            e.onmouseenter = (e) => this._onMouseEnterElement(e);
            e.onmouseleave = (e) => this._onMouseOutElement(e);
        })
       
        this.querySelectorAll('ul.sidebar-list-ul > li > div, ul.sub-list > li').forEach((el: HTMLElement) => {

            if (el instanceof HTMLDivElement) {
                const existChild = el.nextElementSibling.querySelectorAll('li');

                if (existChild.length !== 0) {
                    el.onclick = (e) => this._openSubItemsList(e.currentTarget as HTMLElement);
                    return;
                }
            }

            el.onclick = (e) => this._onClickItem(e);
        })
    }

    private _onMouseEnterElement(e:Event){
        if(this.isOpened){
            return;
        }

        const liEl = e.currentTarget as HTMLLIElement;
        const el = this._drawMenu(liEl);
        el.querySelectorAll<HTMLLIElement>('li').forEach(el => el.onclick = (e) => this._onClickItem(e));
        this._panelFloatingChild = liEl.appendChild(el);
    }

    private _drawMenu(liElement: HTMLLIElement){        
        const panelEl = document.createElement('div');
        const top = liElement.firstElementChild.getBoundingClientRect().top;
        const left = this.firstElementChild.clientWidth;
        const title = liElement.querySelector('.title').innerHTML;
        const ul = liElement.querySelector('ul').cloneNode(true) as HTMLUListElement;

        panelEl.classList.add('overlay-menu');
        panelEl.style.left = `${left}px`;
        panelEl.style.top = `${top}px`;

        panelEl.innerHTML = `<div class="header-menu">${title}</div>`;

        ul.id = "";
        ul.classList.add('show');
        const el = panelEl.appendChild(ul);

        

        return panelEl;
    }

    private _onMouseOutElement(e:Event){
        if(!this._panelFloatingChild)
            return;

        this._panelFloatingChild.remove();
        this._panelFloatingChild = null;
    }

    connectedCallback() {
        this._updateModel();
    }

    static get observedAttributes() {
        return;
    }

    attributeChangedCallback(name, oldValue, newValue) {
        // this._updateInternalModel(name, newValue);
        // this._updateModel();
    }

    private _updateModel() {
        this._template().then(template => {
            this.innerHTML = template;
            this._wireEvents();
        });
    }

    public open() {
        if(!!this._panelFloatingChild){
            this._panelFloatingChild.remove();
            this._panelFloatingChild = null;
        }

        this.firstElementChild.classList.add('open');
        setTimeout(() => this._checkSelectedParent(), 500); 
    }

    public close() {
        this.firstElementChild.querySelectorAll('li > ul.show').forEach((el) => {
            const divEl = el.previousElementSibling as HTMLDivElement;    
            divEl.click();
        });
        
        this.firstElementChild.classList.remove('open');
        
        setTimeout(() => this._checkSelectedParent(), 500); 
    }

    private _checkSelectedParent(){
        const selectedEl = this.firstElementChild.querySelector<HTMLElement>('.sub-list > li.active');

        if(!selectedEl)
        {
            return;
        }

        const el = selectedEl.closest('li.sidebar-item');
        const divEl = el.querySelector('div');
        const isOpenedGroup = divEl.getAttribute('aria-expanded') === "true";

        if(!this.isOpened){
            divEl.classList.add('active');
            return;
        }

        divEl.classList[this.isOpened && isOpenedGroup ? 'remove' : 'add']('active');
    }

    private _onLogout(e) {
        if (this.onlogout) {
            this.onlogout(e);
        }

        const evt = new CustomEvent('logout');
        this.dispatchEvent(evt);
    }

    private async _template(): Promise<string> {
        const buttons = this._buttonList.
            sort((a, b) => a.order - b.order).
            map((btn, idx) => {
                const isActiveCls = (!!this.activeButtonId && this.activeButtonId === btn.id && !btn.child && this.checkSelectedType(btn)) ? 'active' : '';
                const collapseId = `collapse-id-${idx}`;
                return `
                    <li class="list-group-item align-items-center sidebar-item" id="${btn.id}" data-is-child=false title="${btn.text}">
                        <div class="d-flex flex-row align-items-center ${isActiveCls}" id="${btn.id}" data-toggle="collapse" data-target="#${collapseId}">
                            <span class="material-icons-outlined mr-3 ml-2 icon" ${!btn.icon ? 'hidden' : ''}>${btn.icon}</span>
                            <span class="flex-grow-1 ellipsis title">${btn.text}</span>
                            <span class="material-icons-outlined icon-open" ${!btn.child || btn.child.length === 0 ? 'hidden' : ''}>expand_more</span>
                        </div>
                        <ul id="${collapseId}" class="list-group sub-list collapse">
                            ${!btn.child || btn.child.length === 0 ? '' : `
                                ${this.renderChild(btn, idx)
                        }
                            `}               
                    
                        </ul>
                    </li>
                `
            });

        const tableApiClient = new VertexApi(Vertex.Globals.bearerToken);
        const name = this._userProfile?.name;
        const email = this._userProfile?.email;
        let picture = this._userProfile?.picture || null;	

        const userAvatarId = await tableApiClient.avatar.getByStyle(); 

        if(userAvatarId){
            const avatarRes = await ResourceUtils.getResourceData(userAvatarId);

            if(avatarRes){
                if(avatarRes.resourceKeys.includes(`${AVATAR_PHOTO_FILENAME}.jpg`)){
                    picture = `${RESOURCE_API_URI}${userAvatarId}/${AVATAR_PHOTO_FILENAME}.jpg`;
                }
                else if(avatarRes.resourceKeys.includes(`${AVATAR_PHOTO_FILENAME}.png`)){
                    picture = `${RESOURCE_API_URI}${userAvatarId}/${AVATAR_PHOTO_FILENAME}.png`;
                }
            }
        }

        return `
        <div class="side-bar open">
            <div class="sidebar-close-container">
                <div class="overflow-hidden user-info">
                <div class="user-icon" ${!!picture ? `style="background-image: url(${picture});"` : ''} alt="" title="${name} (${email})" ></div>
                <div class="d-flex flex-column overflow-hidden p-2">
                    <h6 class="ellipsis text-style mr-2 mb-0" title="${name}">${name}</h6>
                    <small class="ellipsis text-style mr-2" title="${email}">${email}</small>
                </div>
                </div>
                <button type="button" class="close text-white mr-2 align-self-lg-start" aria-label="Close" hidden>
                    <span class="material-icons-outlined">close</span>    
                </button>
            </div>
            <ul class="list-group sidebar-list-ul pt-2 overflow-hidden">
                ${buttons.join("")}
            </ul>
            <div class="sidebar-footer">
                <div class="signout-block">
                    <span class="portal-version text-style">v${PORTAL_VERSION}</span>
                    <span class="ellipsis text-style">Logout</span>
                    <button class="btn btn-sm btn-danger button-logout" title="Logout">
                        <span class="material-icons-outlined logout">logout</span>
                    </button>
                </div>
            </div>
        </div>
        `;
    }

    private renderChild(btn: IButton, idx: number) {
        return btn.child.sort((a, b) => a.order - b.order)
            .map((child, childIdx) => {
                const isActiveCls = (!!this.activeButtonId && this.activeButtonId === child.id && this.checkSelectedType(child)) ? 'active' : '';

                return `
            <li class="list-group-item d-flex justify-content-between align-items-center ${isActiveCls}" id="${child.id}" data-is-child=true data-parent-id=${btn.id}>
                <span class="material-icons-outlined mr-3 ml-2 icon" ${!child.icon ? 'hidden' : ''}>${child.icon}</span>    
                <span class="ellipsis title">${child.text}</span>
            </li>`;
            })
            .join("");
    }

    private checkSelectedType(btn: IButton): boolean {
        const selectedTypes = [ButtonType.QRCatalog, ButtonType.TagCatalog, ButtonType.Resources, ButtonType.Avatar];
        return selectedTypes.includes(btn.type);
    }

    private _openSubItemsList(clickedEl: HTMLElement) {
        if (!this.isOpened) {
            this.open();
        }
        
        const iconOpenEl = clickedEl.querySelector<HTMLSpanElement>('span.icon-open');
        const ulList = iconOpenEl.parentElement.nextElementSibling as HTMLUListElement;
        const isOpen = ulList.classList.contains('show');

        iconOpenEl.innerHTML = isOpen ? 'expand_more' : 'expand_less';

        
        setTimeout(() => this._checkSelectedParent(), 500); 
    }

    toggle() {
        this[this.isOpened ? 'close' : 'open']();
    }

    _onClickItem(e: Event) {
        let clickedEl = e.currentTarget as HTMLElement;

        if(clickedEl instanceof HTMLDivElement){
            clickedEl = clickedEl.parentElement;
        }
        
        let btn: IButton = this.deepSearchItem(this._buttonList, clickedEl.id);

        if(!btn){
            return;
        }

        if(this.checkSelectedType(btn)){
            this.firstElementChild.querySelectorAll('div.active, li.active').forEach((active) => active.classList.remove('active'));
            
            let el = clickedEl;
            
            if(el.dataset.isChild === "false"){
                el = clickedEl.firstElementChild as HTMLDivElement;
            }
            
            el.classList.add('active');
        }

        e.preventDefault();
        e.stopPropagation();

        if (!!this.onclickitem) {
            this.onclickitem(e, btn);
        }

        const evt = new CustomEvent('clickitem', { detail: btn });
        this.dispatchEvent(evt);

        // if (!btn.action) {
        //     return;
        // }

        // btn.action(e, btn);
    }

    private deepSearchItem(btnList: IButton[], id: string) {
        const btn = btnList.find(b => b.id === id);

        if (btn) {
            return { ...btn };
        }

        for (let j = 0; j < btnList.length; j++) {
            const button = btnList[j];

            if(!button.child || button.child?.length === 0){
                continue;
            }

            const result = this.deepSearchItem(button.child, id);

            if (result) {
                return result;
            }
        }

        return;
    }

}

customElements.define('side-bar', SidebarElement);
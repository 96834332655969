import { ResourceUtils } from '../../utilities/resource-utilities';
import { Utils } from '../../utilities/utils';

export class CustomGltfPublishRenderer implements Vertex.UI.ICustomInspectorRenderer {
    
    // todo: may not need ctor
    constructor(targetNode: Vertex.NodeComponentModel.VertexNode) {
        this.targetNode = targetNode;
    }

    targetNode: Vertex.NodeComponentModel.VertexNode;


    idInput = document.createElement("input");
    usePublishedInput: HTMLInputElement = null;

    target: Vertex.NodeComponentModel.Component;
    property: string;
    
    static currentRenderer: CustomGltfPublishRenderer;
    onChanged;


    private get currentId() {
        return this.target[this.property] as string;
    }


    RenderProperty(property: string, target: any): HTMLDivElement {
        this.target = target;
        this.property = property;

        let outer = document.createElement('div');
        outer.classList.add('w-100', 'd-flex', 'flex-column', 'align-items-stretch');
        outer.style.order = '-10'; // order -10 to pull this to the top, but not above the heading

        // render the details block
        let detailsRow = this.renderDetailsField(property, target);
        detailsRow.style.order = "-2"; 

        // render the standard ID row
        //let idRow = this.renderIdInputField(property, target);
        //idRow.style.order = "-1";

        outer.appendChild(detailsRow);
        //outer.appendChild(idRow);

        if(CustomGltfPublishRenderer.currentRenderer)
        {
            CustomGltfPublishRenderer.currentRenderer.target.onChanged.off(CustomGltfPublishRenderer.currentRenderer.onChanged);
        }

        this.onChanged = (data: Vertex.NodeComponentModel.Component) => {
            let comp = data as Vertex.NodeComponentModel.GltfModelComponent;
            this.idInput.value = comp.id || "";
            this.updateDetailsField();
        }
        this.target.onChanged.on(this.onChanged);

        CustomGltfPublishRenderer.currentRenderer = this;

        return outer;
    }


    private renderIdInputField(property: string, target: any): HTMLElement {
        let row = document.createElement('div');
        row.classList.add('row', 'control-group','align-items-center');

        let label = document.createElement('div');
        label.classList.add('col-2', 'control-label');
        label.innerText = property;

        let valueContainer = document.createElement('div');
        valueContainer.classList.add('col', 'control-value');

        let idField = document.createElement('input');
        idField.classList.add('form-control');
        idField.type = "text";
        idField.spellcheck = false;
        idField.autocomplete = "off";
        idField.style.minWidth = "0";
        idField.placeholder = "Enter an ID..."
        idField.addEventListener('change', this.inputChanged.bind(this));
        idField.addEventListener('drop', this.handleDropEvent.bind(this));
        idField.value = this.currentId;
        valueContainer.appendChild(idField);

        this.idInput = idField;

        row.appendChild(label);
        row.appendChild(valueContainer);

        return row;
    }

    private updateDetailsField() {
        let image = document.getElementsByClassName("gltfmodelcomponent-image")[0] as HTMLImageElement;
        let nameLabel = document.getElementsByClassName("gltfmodel-inspector-name")[0] as HTMLDivElement;
        let cardHeader = document.getElementsByClassName("nav-link-with-dropdown")[0] as HTMLDivElement;
        
        if (image && nameLabel && cardHeader) {
            ResourceUtils.getThumbnailBlobUrl(this.currentId).
            then((res) => {
                image.src = res;
            })
            .catch(err => console.log(err));
    
            ResourceUtils.getResourceData(this.currentId)
            .then((res) => {
                nameLabel.innerText = res.name;
                cardHeader.innerText = res.name;
            })
            .catch(err => console.error(err));
        }
    }

    private renderDetailsField(property: string, target: Vertex.NodeComponentModel.Component): HTMLElement {
        let root = document.createElement('div');
        root.classList.add('gltfmodelcomponent-details-container');

        // Preview Image Container
        {
            let imageContainer = document.createElement('div');
            imageContainer.classList.add('gltfmodelcomponent-image-container');

            // this is var on purpose
            var image = document.createElement('img');
            image.classList.add('gltfmodelcomponent-image');
            
            ResourceUtils.getThumbnailBlobUrl(this.currentId)
            .then( (res) => {
                image.src = res;
                root.appendChild(imageContainer);
            })
            .catch(err => console.log(err))


        // Publish field
            let publishContainer = document.createElement('div');
            publishContainer.classList.add('gltfmodelcomponent-id-inspector-publish-container');

            // this is var on purpose
            var nameLabel = document.createElement('div');
            nameLabel.classList.add('gltfmodel-inspector-name', 'mb-2');
            nameLabel.innerText = "Fetching Model Info...";
            nameLabel.style.fontWeight = "600";
            nameLabel.style.justifySelf = "flex-start";

            publishContainer.appendChild(nameLabel);


/*             let control = document.createElement('div');
            control.classList.add('custom-control', 'custom-checkbox', 'gltfmodelcomponent-id-inspector-switch');

            let input = document.createElement('input');
            input.type = 'checkbox';
            input.classList.add('custom-control-input');
            input.id = `gltfmodelcomponent_usePublished_check_${this.currentId}`;
            input.addEventListener('change', this.publishCheckChanged.bind(this));
            input.disabled = true; // this gets enabled after we determine if we're using published or not

            this.usePublishedInput = input;
            control.appendChild(input);

            let label = document.createElement('label');
            label.classList.add('custom-control-label');
            label.htmlFor = `gltfmodelcomponent_usePublished_check_${this.currentId}`;
            label.innerText = "Use Published Version";
            control.appendChild(label); */

            //publishContainer.appendChild(control);

            let idRow = this.renderIdInputField(property, target);
            let rightSideWrapper = document.createElement("div");
            rightSideWrapper.classList.add("flex-column","w-100");

            imageContainer.appendChild(image);
            imageContainer.appendChild(rightSideWrapper);
            rightSideWrapper.append(publishContainer,idRow);
        }


        ResourceUtils.getResourceData(this.currentId)
        .then( (resource) => {
            nameLabel.innerText = resource.name;
            image.title = resource.name;
            this.usePublishedInput.disabled = false;
            //this.usePublishedInput.checked = !!resource.publishParent;
        })
        .catch(err => console.log(err))

        return root;
    }

    inputChanged(evt: Event) {
        let textbox = evt.currentTarget as HTMLInputElement;
        
        if (!this.targetNode.HasToken) {
            console.log("Alert!", "You can't change a node you don't own!");
            return;
        }
        
        textbox.value = Utils.sanitizeString(textbox.value);

        this.target[this.property] = textbox.value || "";
        this.target.triggerOnChanged();
        this.updateDetailsField();
    }

    handleDropEvent(evt: DragEvent) {
        let textbox = evt.currentTarget as HTMLInputElement;
        let data = evt.dataTransfer.getData("text/plain");
        data = Utils.sanitizeString(data);

        textbox.value = data;
        this.target[this.property] = textbox.value;
        this.target.triggerOnChanged();
        this.updateDetailsField();
    }

    async publishCheckChanged(evt: Event) {
        let checkbox = evt.currentTarget as HTMLInputElement;
        let shouldUsePublished = checkbox.checked;

        checkbox.disabled = true;
        try {
            // if checked, determine the child and swap the ID
            // otherwise determine the parent and swap to parent.

            let resp = await fetch(`https://${Vertex.Globals.vertexStackUrl}/core/resource/${this.currentId}`, { credentials: 'same-origin' });
            if (!resp.ok) {
                console.error(`Failed to fetch resource info for ${this.currentId}`, resp);
                return;
            }

            let resource = await resp.json() as Resource;

            let isCurrentlyPublished = !!resource.publishParent;
            let hasOnePublishChild = resource.publishedResources && resource.publishedResources.length == 1;
            let hasMultiplePublishChildren = resource.publishedResources && resource.publishedResources.length > 1;

            if (shouldUsePublished) {
                // we want to use published
                if (!isCurrentlyPublished) {
                    // and we're not already using the published
                    // so now switch to the published one, if we can
                    if (hasOnePublishChild) {
                        // swtich to the publish child
                        this.target[this.property] = resource.publishedResources[0];
                        this.target.triggerOnChanged();
                    }
                    else if (hasMultiplePublishChildren) {
                        // warn that there are multiple publish children

                        // todo: maybe build some UX for this scenario.
                        // it's quite unlikely unless someone has done multi-publishing with the API
                        let msgLines = [
                            `This resource has multiple published resources associated to it.`,
                            `Please manually enter the ID of the exact publish version into the ID field.`,
                            ``,
                            `Tip: you can use the Resource Explorer to see all published versions of a resource.`
                        ];
                        console.log('Multiple Published Resources', msgLines.join('<br>'));

                        // unset the box
                        checkbox.checked = false;
                    }
                    else {
                        // prompt the user to publish it
                        // todo: make this nicer

                        let msgLines = [
                            `This resource does not have a published version.`,
                            `Use the Resource Explorer to publish it, then try again.`
                        ];

                        console.log('Resource not published', msgLines.join('<br>'));

                        // unset the box
                        checkbox.checked = false;
                    }
                }
                // else
                //  we are already using a published one, nothing to do
            }
            else {
                // we want to NOT use published
                if (isCurrentlyPublished) {
                    // if currently using a published one
                    // switch back to the parent, if we can

                    // switch to publish parent
                    this.target[this.property] = resource.publishParent;
                    this.target.triggerOnChanged();
                }
                // else
                //  we aren't currently published, nothing to do.
            }
        }
        finally {
            checkbox.disabled = false;
        }
    }
}

interface Resource {
    id: string;
    name: string;
    publishedResources?: string[]
    publishParent?: string
}
export interface IModel {
    close: () => void;
}

export interface IAction{
    //actionType?: 'click';
    //action?: (e: Event, data: any) => void;
}

export enum ButtonType {
    Redirect = 0,
    TagCatalog,
    QRCatalog,
    Resources,
    Group,
    Avatar
}

export interface ICatalogData{
    appTag: string;
    contentTag: string;
}

export interface IRedirectData{
    url: string;
    blank: boolean;
}

export interface IButton extends IAction{
    id: string;
    text: string;
    icon?: string;
    // cls?: string[];
    order?: number;
    child?: IButton[];
    type: ButtonType;
    data?: ICatalogData | IRedirectData;
}
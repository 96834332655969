import Swal from "sweetalert2";
import { AugmentedStoreAssembly } from "../../../../AugmentedStoreAssembly";
import { deleteComponentJsonFromResource, getComponentJsonFromResource, postComponentJsonToResource, Utils } from "../../../utilities/utils";
import { IValidable } from "../EditorComponents/scenevalidator";
import { ResourceUtils } from "../../../utilities/resource-utilities";
import { CUSTOM_ENVIRONMENT_TEXTURE_EXTENSION, HDR_TEXTURE_EXTENSION, DEFAULT_SKYBOX_FILENAME, DDS_TEXTURE_EXTENSION, RESOURCE_API_URI, SKYBOX_SIZE_RATIO, ENVIRONMENT_TEXTURE_EXTENSION } from "../../../utilities/constants";
import { ITriggerActionComponent } from "./actionsettingscomponent";
import { cloneDeep } from "lodash";


export class Skybox {
    skyboxUri: string;
    skyboxRotation: number;
    skyboxIntensity: number;
    skyboxBlur: number;
    hasFakeSkybox: boolean;
    diffuseR: number;
    diffuseG: number;
    diffuseB: number;
}

export class CustomSkyboxComponent extends AugmentedStoreAssembly.SkyboxComponent implements IValidable, ITriggerActionComponent {
    isTriggerActionComponent: boolean = true;

    static readonly DEFAULT_BLUR: number = 0.2;
    static readonly DEFAULT_INTENSITY: number = 1;
    static readonly DEFAULT_ROTATION: number = 0;
    static readonly DEFAULT_HAS_FAKE_SKYBOX = true;
    static readonly DEFAULT_DIFFUSE_R = 0.9;
    static readonly DEFAULT_DIFFUSE_G = 0.9;
    static readonly DEFAULT_DIFFUSE_B = 0.9;

    constructor() {
        // Always run the super constructor when extending classes in TS!
        super();
    }

    isReady: Promise<void>;

    skyboxes: Skybox[] = [];
    previewSkyboxes: Skybox[] = [];
    previewActiveSkyboxIndex: number;

    async getComponentTriggerableValueNames(): Promise<string[]> {
        return this.skyboxes.map((skybox) => skybox.skyboxUri || "Default Skybox");
    }

    removeMissingFile = (async (fileName: string) => {

        await this.isReady;

        const missingSkyboxIndex = this.skyboxes.findIndex((skybox) => skybox.skyboxUri == fileName);

        if (missingSkyboxIndex > -1) {
            let indexOfActionUsingMissingFile = this.actionValues.findIndex((i) => i == missingSkyboxIndex);

            while (indexOfActionUsingMissingFile > -1) {

                this.actionIndexes.splice(indexOfActionUsingMissingFile, 1);
                this.actionValues.splice(indexOfActionUsingMissingFile, 1);
                indexOfActionUsingMissingFile = this.actionValues.findIndex((i) => i == missingSkyboxIndex);
            }

            for (let i = 0; i < this.actionValues.length; i++) {
                if (this.actionValues[i] > missingSkyboxIndex) {
                    this.actionValues[i] = this.actionValues[i] - 1;
                }
            }


            indexOfActionUsingMissingFile = this.triggerActionValues.findIndex((i) => i == missingSkyboxIndex);

            while (indexOfActionUsingMissingFile > -1) {

                this.triggerActionIndexes.splice(indexOfActionUsingMissingFile, 1);
                this.triggerActionValues.splice(indexOfActionUsingMissingFile, 1);
                indexOfActionUsingMissingFile = this.triggerActionValues.findIndex((i) => i == missingSkyboxIndex);
            }

            for (let i = 0; i < this.actionValues.length; i++) {
                if (this.triggerActionValues[i] > missingSkyboxIndex) {
                    this.triggerActionValues[i] = this.triggerActionValues[i] - 1;
                }
            }

            if (this.activeSkyboxIndex == missingSkyboxIndex) {
                this.activeSkyboxIndex = 0;
            }

            CustomSkyboxComponent.skyboxesToDelete.push(fileName);

            await this.removeDeletedSkyboxes();
            Vertex.Globals.event.fire("ActionSettings:ActionAssignmentUpdated");
        }
    }).bind(this);

    postComponentJsonToResource = (async (isEditorVersion: boolean = true) => {
        await postComponentJsonToResource(Vertex.Globals.spaceId, "Skybox", this.node.id, isEditorVersion, JSON.stringify(this.skyboxes));
    }).bind(this);

    removeDeletedSkyboxes = (async () => {

        await this.isReady;

        let activeSkyboxUri = this.skyboxes[this.activeSkyboxIndex].skyboxUri;
        for (const skyboxToDelete of CustomSkyboxComponent.skyboxesToDelete) {

            let skyboxToDeleteEnv = `${Utils.getFileBaseName(skyboxToDelete)}.${CUSTOM_ENVIRONMENT_TEXTURE_EXTENSION}`;
            await ResourceUtils.deleteAssetFromResource(Vertex.Globals.spaceId, skyboxToDeleteEnv);

            let res = await ResourceUtils.deleteAssetFromResource(Vertex.Globals.spaceId, skyboxToDelete);

            if (res.ok) {
                let skyboxIndex = this.skyboxes.findIndex((skybox) => skybox.skyboxUri == skyboxToDelete);

                if (skyboxIndex !== -1) {
                    this.skyboxes.splice(skyboxIndex, 1);
                }
                else {
                    console.error("Skybox not found!");
                }
            }
            else {
                console.error(`Failed to delete skybox file: ${skyboxToDelete}`);
            }
        }

        CustomSkyboxComponent.skyboxesToDelete = [];

        this.activeSkyboxIndex = this.skyboxes.findIndex((skybox) => skybox.skyboxUri == activeSkyboxUri);

        await this.postComponentJsonToResource(true);

        this.triggerOnChanged();
    }).bind(this);

    isValidableComponent: boolean = true;

    async getUsedFiles(): Promise<string[]> {
        await this.isReady;

        let usedFiles = [];

        this.skyboxes.forEach(element => {
            if (element && element.skyboxUri) {
                const skyboxUri = element.skyboxUri;
                if (skyboxUri.length > 0) {
                    const baseName = Utils.getFileBaseName(skyboxUri);
                    usedFiles.push(`${baseName}.${HDR_TEXTURE_EXTENSION}`);
                    usedFiles.push(`${baseName}.${CUSTOM_ENVIRONMENT_TEXTURE_EXTENSION}`);
                }
            }
        });
        return usedFiles;
    }

    isPerformingOperation: boolean = false;
    currentSkyboxIndex: number = -1;
    currentSkybox: BABYLON.Mesh;
    previousUri: string = null;
    previousMaxZ: number = null;
    previousBlur: number = null;
    static skyboxesToDelete: string[] = [];

    private static isSkyboxReady: boolean = false;

    static get IsSkyboxReady() {
        return this.isSkyboxReady;
    }

    get scene(): BABYLON.Scene {
        return Vertex.Globals.runtime.scene;
    }

    actionSettingsComp: AugmentedStoreAssembly.ActionSettingsComponent;

    get actionSettings(): AugmentedStoreAssembly.ActionSettingsComponent {
        if (this.actionSettingsComp) {
            return this.actionSettingsComp;
        }

        let runtime = Vertex.Globals.runtime as Vertex.VertexRuntime;
        runtime.space.nodes.forEach(node => {
            const comp = node.getComponent("ActionSettings") as AugmentedStoreAssembly.ActionSettingsComponent;
            
            if (comp) {
                this.actionSettingsComp = comp;
            }
        });

        return this.actionSettingsComp;
    }

    public onTriggerAction = (async (actionData: AugmentedStoreAssembly.ActionMessage) => {
        if (this.node.HasToken) {
            const actionIndex = this.actionIndexes.indexOf(actionData.actionIndex);
            if (actionIndex != -1) {
                let filteredSkyboxes = this.skyboxes.filter(skybox => !CustomSkyboxComponent.skyboxesToDelete.includes(skybox.skyboxUri));

                let skyboxUri = filteredSkyboxes[this.actionValues[actionIndex]].skyboxUri;
                let index = this.skyboxes.findIndex((skybox) => skyboxUri == skybox.skyboxUri);
                let uriChanged = this.activeSkyboxIndex !== index;
                this.activeSkyboxIndex = index;
                this.updateSkyboxComp(true, uriChanged);
            }
        }
    }).bind(this);

    private applySkybox = (async (forceRecreate = false): Promise<void> => {
        let hdrTexture = (this.currentSkybox?.material as BABYLON.PBRMaterial)?.reflectionTexture;

        this.currentSkyboxIndex = this.activeSkyboxIndex;
        const currentSkybox = this.skyboxes[this.activeSkyboxIndex];
        const currentBlur = currentSkybox.skyboxBlur;
        const currentUri = currentSkybox.skyboxUri;

        const blurUpdated = currentBlur !== this.previousBlur;
        const uriUpdated = currentUri !== this.previousUri;

        if (uriUpdated || blurUpdated) {
            if (uriUpdated) {
                CustomSkyboxComponent.isSkyboxReady = false;

                let url: string = `/img/${DEFAULT_SKYBOX_FILENAME}`;
                let extension = Utils.getFileExtension(DEFAULT_SKYBOX_FILENAME);

                if (currentUri) {
                    let res = await ResourceUtils.getResourceData(Vertex.Globals.spaceId);

                    if (!res || !res.resourceKeys) {
                        if (this.currentSkyboxIndex != this.activeSkyboxIndex) {
                            this.applySkybox();
                        }
                        else {
                            this.isPerformingOperation = false;
                            CustomSkyboxComponent.isSkyboxReady = true;
                        }
                        return;
                    }

                    extension = Utils.getFileExtension(currentUri);

                    if (extension == DDS_TEXTURE_EXTENSION) {
                        let idx = res.resourceKeys.indexOf(currentUri);

                        if (idx !== -1) {
                            url = `${RESOURCE_API_URI}${Vertex.Globals.spaceId}/${currentUri}`;
                        }
                    }
                    else if (extension == HDR_TEXTURE_EXTENSION) {
                        const envUri = `${Utils.getFileBaseName(currentUri)}.${CUSTOM_ENVIRONMENT_TEXTURE_EXTENSION}`;
                        let idx = res.resourceKeys.indexOf(envUri);

                        if (idx !== -1) {
                            url = `${RESOURCE_API_URI}${Vertex.Globals.spaceId}/${envUri}`;
                            extension = CUSTOM_ENVIRONMENT_TEXTURE_EXTENSION;
                        }
                        else {
                            console.error(`[SkyboxComponent] .${CUSTOM_ENVIRONMENT_TEXTURE_EXTENSION} texture not found. Falling back to .${HDR_TEXTURE_EXTENSION} texture!`, { envUri });
                            idx = res.resourceKeys.indexOf(currentUri);
                            
                            if (idx !== -1) {
                                url = `${RESOURCE_API_URI}${Vertex.Globals.spaceId}/${currentUri}`;
                            }
                        }
                    }
                }

                //breaks if dds isnt valid
                if (extension == DDS_TEXTURE_EXTENSION) {
                    hdrTexture = BABYLON.CubeTexture.CreateFromPrefilteredData(url, this.scene);
                }
                else if (extension == CUSTOM_ENVIRONMENT_TEXTURE_EXTENSION) {
                    hdrTexture = BABYLON.CubeTexture.CreateFromPrefilteredData(url, this.scene, `.${ENVIRONMENT_TEXTURE_EXTENSION}`);
                }
                else if (extension == HDR_TEXTURE_EXTENSION) {
                    //await this.loadHdrCubeTexture(url);
                    hdrTexture = new BABYLON.HDRCubeTexture(url, this.scene, 1024, false, true, false, true);
                }

                if (!hdrTexture) {
                    // todo: failed to create texture?
                    console.error(`[SkyboxComponent] Failed to load HDR Texture!`, { url });

                    if (this.currentSkyboxIndex != this.activeSkyboxIndex) {
                        this.applySkybox();
                    }
                    else {
                        this.isPerformingOperation = false;
                        CustomSkyboxComponent.isSkyboxReady = true;
                    }
                    return;
                }

                this.previousUri = currentUri;
            }

            // if no skybox exists yet, create a new one, otherwise update it
            if (blurUpdated || !this.currentSkybox || forceRecreate) {
                let cam = Vertex.Globals.runtime.scene.activeCamera as BABYLON.ArcRotateCamera;
                let skyboxSize = cam.maxZ * SKYBOX_SIZE_RATIO;

                if (this.currentSkybox) {
                    this.currentSkybox.dispose();
                }

                this.currentSkybox = this.scene.createDefaultSkybox(hdrTexture, true, skyboxSize, currentBlur, true);

                this.previousBlur = currentBlur;
            }

            if (hdrTexture) {
                this.scene.environmentTexture = hdrTexture;
            }

            let material = this.currentSkybox.material as BABYLON.PBRMaterial;

            if (material instanceof BABYLON.PBRMaterial === false) {
                console.error(`[SkyboxComponent] skybox only supports pbr materials`, this);

                if (this.currentSkyboxIndex != this.activeSkyboxIndex) {
                    this.applySkybox();
                }
                else {
                    this.isPerformingOperation = false;
                    CustomSkyboxComponent.isSkyboxReady = true;
                }
                return;
            }

            material.backFaceCulling = false;

            // this texture must be cloned because it uses a different coordinates mode
            // to the environment texture.
            // todo: could maybe avoid cloning here.
            material.reflectionTexture.dispose();
            material.reflectionTexture = null;
            material.reflectionTexture = hdrTexture.clone();

            if (material.reflectionTexture) {
                material.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
            }

            material.microSurface = 1.0 - currentBlur;
            material.disableLighting = true;
            material.twoSidedLighting = true;
        }

        // update rotations of textures
        for (let tex of [this.scene.environmentTexture, (this.currentSkybox.material as BABYLON.PBRMaterial).reflectionTexture] as BABYLON.CubeTexture[]) {
            //tex.level = skyboxComp.intensity;
            tex.rotationY = currentSkybox.skyboxRotation * Math.PI / 180;
        }

        if (this.currentSkybox) {
            this.currentSkybox.visibility = currentSkybox.hasFakeSkybox ? 0 : 1;
        }

        Vertex.Globals.runtime.scene.environmentIntensity = currentSkybox.skyboxIntensity;
        Vertex.Globals.runtime.scene.clearColor = new BABYLON.Color4(
            currentSkybox.diffuseR,
            currentSkybox.diffuseG,
            currentSkybox.diffuseB,
            1);

        Utils.waitForCondition(_ => hdrTexture.isReady()).then(_ => Utils.delay(150).then(_ => {
            if (this.currentSkyboxIndex != this.activeSkyboxIndex) {
                this.applySkybox();
            }
            else {
                this.isPerformingOperation = false;
                CustomSkyboxComponent.isSkyboxReady = true;
            }
        }));
    }).bind(this);

    performApplySkyboxOperation = (() => {
        if (!this.isPerformingOperation) {
            this.isPerformingOperation = true;

            this.applySkybox(true);
        }
    }).bind(this);

    updateSkyboxComp = (async (postComponentJson: boolean = false, uriChanged: boolean = false, needTrigger = true) => {
        let notifying = false;

        if (!Swal.isVisible() && uriChanged) {
            notifying = true;

            Swal.fire({
                title: "Loading Skybox...",
                showConfirmButton: false,
                heightAuto: false,
            });

            Swal.showLoading();
        }

        this.performApplySkyboxOperation();
        if(postComponentJson)
        {
            await this.postComponentJsonToResource();
        }

        if(needTrigger)
        {
            this.triggerOnChanged();
        }

        if (notifying) {
            Utils.waitForCondition(_ => CustomSkyboxComponent.IsSkyboxReady === true).then(_ => {
                Swal.close();
            });
        }
    }).bind(this);

    onActionDeleted = ((deletedActionIndex) => {
        if (this.actionIndexes.includes(deletedActionIndex)) {
            let actionIndex = this.actionIndexes.indexOf(deletedActionIndex);
            this.actionIndexes.splice(actionIndex, 1);
            this.actionValues.splice(actionIndex, 1);
        }

        for (let i = 0; i < this.actionIndexes.length; i++) {
            if (this.actionIndexes[i] > deletedActionIndex) {
                this.actionIndexes[i] = this.actionIndexes[i] - 1;
            }
        }
        
        this.triggerActionValues = this.triggerActionValues.filter((val, idx) => this.triggerActionIndexes[idx] != deletedActionIndex);
        this.triggerActionIndexes = this.triggerActionIndexes.filter(idx => idx != deletedActionIndex);

        for(let i = 0; i < this.triggerActionIndexes.length; i++) {
            if(this.triggerActionIndexes[i] > deletedActionIndex) {
                this.triggerActionIndexes[i] = this.triggerActionIndexes[i]-1;
            }
        }
        
        this.triggerOnChanged();
    }).bind(this);

    beforeSaveSpace = (async () => {
        await this.removeDeletedSkyboxes();
        await this.postComponentJsonToResource(false);
    }).bind(this);

    beforeCreatePreview = (async () => {

        await this.isReady;
        this.previewSkyboxes = cloneDeep(this.skyboxes);

        let activeSkyboxUri = this.previewSkyboxes[this.activeSkyboxIndex].skyboxUri;
        for (const skyboxToDelete of CustomSkyboxComponent.skyboxesToDelete) {

            let skyboxIndex = this.previewSkyboxes.findIndex((skybox) => skybox.skyboxUri == skyboxToDelete);

            if (skyboxIndex !== -1) {
                this.previewSkyboxes.splice(skyboxIndex, 1);
            }
            else {
                console.error("Skybox not found!");
            }
        }

        this.previewActiveSkyboxIndex = this.previewSkyboxes.findIndex((skybox) => skybox.skyboxUri == activeSkyboxUri);

        await postComponentJsonToResource(Vertex.Globals.spaceId, "Skybox", this.node.id, true, JSON.stringify(this.previewSkyboxes), null, true);

    }).bind(this);
}
export class SkyboxComponentView extends Vertex.NodeComponentModel.ComponentViewBase {
    constructor() {
        super();
    }

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {

        let skyboxComp = component as CustomSkyboxComponent;

        skyboxComp.isReady = new Promise<void>(async (resolve, reject) => {

            let res = await getComponentJsonFromResource(Vertex.Globals.spaceId, "Skybox", node.id, true);

            if (res.ok) {
                skyboxComp.skyboxes = await res.json() as Skybox[];

                if (skyboxComp.skyboxes.length <= 0) {

                    let defaultSkybox = new Skybox();
                    defaultSkybox.skyboxUri = "";
                    defaultSkybox.skyboxIntensity = CustomSkyboxComponent.DEFAULT_INTENSITY;
                    defaultSkybox.skyboxBlur = CustomSkyboxComponent.DEFAULT_BLUR;
                    defaultSkybox.skyboxRotation = CustomSkyboxComponent.DEFAULT_ROTATION;
                    defaultSkybox.hasFakeSkybox = CustomSkyboxComponent.DEFAULT_HAS_FAKE_SKYBOX;
                    defaultSkybox.diffuseR = CustomSkyboxComponent.DEFAULT_DIFFUSE_R;
                    defaultSkybox.diffuseG = CustomSkyboxComponent.DEFAULT_DIFFUSE_G;
                    defaultSkybox.diffuseB = CustomSkyboxComponent.DEFAULT_DIFFUSE_B;

                    skyboxComp.skyboxes = [defaultSkybox];
                }
            }
            else {
                res = await getComponentJsonFromResource(Vertex.Globals.spaceId, "Skybox", node.id, false);
                
                if (res.ok) {
                    skyboxComp.skyboxes = await res.json() as Skybox[];

                    await skyboxComp.postComponentJsonToResource(true);
                } else {
                    let defaultSkybox = new Skybox();
                    defaultSkybox.skyboxUri = "";
                    defaultSkybox.skyboxIntensity = CustomSkyboxComponent.DEFAULT_INTENSITY;
                    defaultSkybox.skyboxBlur = CustomSkyboxComponent.DEFAULT_BLUR;
                    defaultSkybox.skyboxRotation = CustomSkyboxComponent.DEFAULT_ROTATION;
                    defaultSkybox.hasFakeSkybox = CustomSkyboxComponent.DEFAULT_HAS_FAKE_SKYBOX;
                    defaultSkybox.diffuseR = CustomSkyboxComponent.DEFAULT_DIFFUSE_R;
                    defaultSkybox.diffuseG = CustomSkyboxComponent.DEFAULT_DIFFUSE_G;
                    defaultSkybox.diffuseB = CustomSkyboxComponent.DEFAULT_DIFFUSE_B;

                    skyboxComp.skyboxes = [defaultSkybox];

                    await skyboxComp.postComponentJsonToResource(true);
                }
            }

            skyboxComp.onChanged.on((_) => {
                // This operation is performed only by clients that have not token because the owner had just performed it locally
                if (!node.HasToken) {
                    skyboxComp.performApplySkyboxOperation();
                }
            });

            Vertex.Globals.event.on("camera-properties:clipping-planes-changed", ([minZ, maxZ]: [number, number]) => {
                if (skyboxComp.previousMaxZ !== maxZ) {
                    skyboxComp.performApplySkyboxOperation();

                    skyboxComp.previousMaxZ = maxZ;
                }
            });

            skyboxComp.performApplySkyboxOperation();

            if (skyboxComp.actionSettings) {
                skyboxComp.actionSettings.onActionTriggered.on(skyboxComp.onTriggerAction);
                skyboxComp.onRemoved.on(() => skyboxComp.actionSettings.onActionTriggered.off(skyboxComp.onTriggerAction))
            }

            Vertex.Globals.event.on("ActionSettings:ActionDeleted", skyboxComp.onActionDeleted);
            Vertex.Globals.event.on("hevolus:beforeSaveSpace", skyboxComp.beforeSaveSpace);
            Vertex.Globals.event.on("hevolus:beforeCreatePreview", skyboxComp.beforeCreatePreview);

            resolve();
        });

    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        let skyboxComp = component as CustomSkyboxComponent;

        skyboxComp.isReady.then(() => {
            Vertex.Globals.event.off("hevolus:beforeCreatePreview", skyboxComp.beforeCreatePreview);
            Vertex.Globals.event.off("hevolus:beforeSaveSpace", skyboxComp.beforeSaveSpace);
            Vertex.Globals.event.off("ActionSettings:ActionDeleted", skyboxComp.onActionDeleted);

            deleteComponentJsonFromResource(Vertex.Globals.spaceId, "Skybox", node.id, true);
            deleteComponentJsonFromResource(Vertex.Globals.spaceId, "Skybox", node.id, false);
        });
    }
}

export class SkyboxComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new CustomSkyboxComponent();
    }

    constructor() {
        super("Skybox", new SkyboxComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}
import { ICatalogItem } from "../catalog.interfaces";

export const QR_PANEL_SELECTOR: string = "#tab-catalog-panel";
export const QR_PANEL_HEADER_SELECTOR: string = "#tab-catalog-header";
export const QR_PANEL_BODY_SELECTOR: string = "#tab-catalog-body";
export const QR_PANEL_FOOTER_SELECTOR: string = "#tab-catalog-footer";
export const QRCODE_SELECTOR: string = "#qrcode";
export const QR_DETAIL_SELECTOR: string = "#qr-detail";
export const BUTTON_ADD_SELECTOR: string = "#btn-add-qr";
export const BUTTON_EDIT_SELECTOR: string = "#btn-edit-qr";
export const BUTTON_DELETE_SELECTOR: string = "#btn-delete-qr";
export const BUTTON_DOWNLOAD_SELECTOR: string = "#btn-download-qr";
export const LIST_SELECTOR: string = "#list-tab";
export const LIST_TEMPLATE_SELECTOR: string = "#qr-list-template";
export const ITEM_LIST_TEMPLATE_SELECTOR: string = "#qr-list-item-template";
export const DELETE_MODAL_TEMPLATE_SELECTOR: string = "#delete-modal-template";
export const ANCHOR_DOWNLOAD_ELEMENT:string = "#download-element";
export const QUALITY_SELECTOR_ELEMENT:string = "#quality-select";
export const BACKGROUND_SELECTOR_ELEMENT: string = "#background-color-qr";

export const QR_PLATFORM: string = "VTX-QR";
export const QR_HOST: string = "vertx.cloud";
export const QR_SERVICE: string = "spatial";

export const QRCODE_WIDHT: number = 756;
export const QRCODE_HEIGHT: number = 756;
export const QRCODE_MARGIN: number = 4;
export const QRCODE_BACKGROUND_DEFAULT: string = "#FFFFFF";
export const QRCODE_COLOR_DEFAULT: string = "#000000";
export const QRCODE_QUALITY_DEFAULT: QualityQR = "L";

export interface IQRItem extends ICatalogItem {
    url: string;
    dateTimeCreated: string;
    description?: string;
}

export type QualityQR = 'H' | 'Q' | 'M' | 'L';

export interface IQROptions{
    errorCorrectionLevel: QualityQR;
    type: 'image/png' | 'image/jpeg' | 'image/webp';
    quality?: number;
    width: number; 
    height: number;
    margin?: number;
    color?: {
        dark?: string;
        light?:string;
    }
}
export interface SpawnedQRAnchor{
    anchor: IQRItem,
    mesh: BABYLON.Mesh,
}
import { UserProfileUtils } from "../../../utilities/user-profile-utilities";
import { HIPE_XR_API_DOMAIN, HIPE_XR_API_KEY, HIPE_XR_API_VERSION, HIPE_XR_API_VERSION_PARAM, ITEMS_API_ENDPOINT, ItemType, SYSTEM_API_ENDPOINT } from "../common";

const COMPANIES_API_ENDPOINT = "companies";

export class Company {
    companyId: string;
    name: string;
    tenantId: string;
    items: CompanyItem[];

    constructor(companyId: string, name: string, tenantId: string, items: CompanyItem[] = []) {
        this.companyId = companyId;
        this.name = name;
        this.tenantId = tenantId;
        this.items = items;
    }
}

export class CompanyItem {
    companyId: string;
    itemId: string;
    itemValue: string;
    itemType: ItemType;

    constructor(companyId: string, itemId: string, itemValue: string, itemType: ItemType) {
        this.companyId = companyId;
        this.itemId = itemId;
        this.itemValue = itemValue;
        this.itemType = itemType;
    }
}

export class CompaniesApi {
    /**
     * Get all companies
     * 
     * @returns {Promise<Company[]>} A promise that resolves to an array of companies or undefined
     */
    static async getCompaniesAsync(): Promise<Company[]> {
        let uri = `${HIPE_XR_API_DOMAIN}/${SYSTEM_API_ENDPOINT}/${COMPANIES_API_ENDPOINT}`;
        uri += `?${HIPE_XR_API_VERSION_PARAM}=${HIPE_XR_API_VERSION}`;

        try {
            const response = await fetch(uri, {
                method: "GET",
                headers: {
                    "Ocp-Apim-Subscription-Key": HIPE_XR_API_KEY,
                },
            });

            if (response.ok) {
                return await response.json() as Company[];
            } else {
                console.log(`Failed to fetch companies`, response.status + response.statusText);
            }
        } catch (e) {
            console.log(`Failed to fetch companies`, e);
        }
    }

    /**
    *  Get a company by id
     * @param token vertx token to get company id (vertx tenant id) 
     * @param itemType
     * @param itemId 
     * @param itemValue 
     * @returns {Promise<Company>} A promise that resolves to a company or undefined
     */
    static async getCompanyAsync(token?: string, itemId?: string, itemType?: ItemType, itemValue?: string): Promise<Company> {
        token = token ?? Vertex.Globals.bearerToken;

        const companyId = await UserProfileUtils.getTenantId(token);

        if(!companyId){
            return;
        }

        let uri = `${HIPE_XR_API_DOMAIN}/${SYSTEM_API_ENDPOINT}/${COMPANIES_API_ENDPOINT}/${companyId}`;
        uri += `?${HIPE_XR_API_VERSION_PARAM}=${HIPE_XR_API_VERSION}`;

        // Add query parameters if they exist
        const params = new URLSearchParams();

        if (itemType != null){
            params.append("itemType", itemType.toString());
        }

        if (itemId){
            params.append("itemId", itemId);
        }

        if (itemValue){
            params.append("itemValue", itemValue);
        }

        if (params.toString()) {
            uri += `&${params.toString()}`;
        }

        try {
            const response = await fetch(uri, {
                method: "GET",
                headers: {
                    "Ocp-Apim-Subscription-Key": HIPE_XR_API_KEY,
                },
            });

            if (response.ok) {
                const company = await response.json() as Company;

                return company;
            } else {
                console.log(`Failed to fetch company`, response.status + response.statusText);
            }
        } catch (e) {
            console.log(`Failed to fetch company`, e);
        }
    }

    /**
     * Get a company item by id
     * 
     * @param itemId The id of the item to get
     * @param token vertx token to get company id (vertx tenant id) 
     * @returns {Promise<CompanyItem>} A promise that resolves to a company item or undefined
     */
    static async getCompanyItemAsync(itemId: string, token?: string): Promise<CompanyItem> {
        token = token ?? Vertex.Globals.bearerToken;
        const companyId = await UserProfileUtils.getTenantId(token);

        if(!companyId){
            return;
        }

        let uri = `${HIPE_XR_API_DOMAIN}/${SYSTEM_API_ENDPOINT}/${COMPANIES_API_ENDPOINT}/${companyId}/${ITEMS_API_ENDPOINT}/${itemId}`;
        uri += `?${HIPE_XR_API_VERSION_PARAM}=${HIPE_XR_API_VERSION}`;

        try {
            const response = await fetch(uri, {
                method: "GET",
                headers: {
                    "Ocp-Apim-Subscription-Key": HIPE_XR_API_KEY,
                },
            });

            if (response.ok) {
                const companyItem = await response.json() as CompanyItem;

                return companyItem;
            } else {
                console.log(`Failed to fetch company item`, response.status + response.statusText);
            }
        } catch (e) {
            console.log(`Failed to fetch company item`, e);
        }
    }

    /**
     * Save a company
     * 
     * @param company The company to save
     * @returns {Promise<Company>} A promise that resolves to the saved company or undefined
     */
    static async saveCompanyAsync(company: Company): Promise<Company> {
        let uri = `${HIPE_XR_API_DOMAIN}/${SYSTEM_API_ENDPOINT}/${COMPANIES_API_ENDPOINT}`;
        uri += `?${HIPE_XR_API_VERSION_PARAM}=${HIPE_XR_API_VERSION}`;

        try {
            const response = await fetch(uri, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Ocp-Apim-Subscription-Key": HIPE_XR_API_KEY,
                },
                body: JSON.stringify(company),
            });

            if (response.ok) {
                const savedCompany = await response.json() as Company;

                return savedCompany;
            } else {
                console.log(`Failed to save company`, response.status + response.statusText);
            }
        } catch (e) {
            console.log(`Failed to save company`, e);
        }
    }

    /**
     * Save a company item
     * 
     * @param companyItem The company item to save
     * @param token vertx token to get company id (vertx tenant id) 
     * @returns {Promise<CompanyItem>} A promise that resolves to the saved company item or undefined
     */
    static async saveCompanyItemAsync(companyItem: CompanyItem): Promise<CompanyItem> {
        if(!companyItem.companyId){
            console.log(`Failed to save company item.Company ID is required`);
            
            return;
        }
        
        let uri = `${HIPE_XR_API_DOMAIN}/${SYSTEM_API_ENDPOINT}/${COMPANIES_API_ENDPOINT}/${companyItem.companyId}/${ITEMS_API_ENDPOINT}`;
        uri += `?${HIPE_XR_API_VERSION_PARAM}=${HIPE_XR_API_VERSION}`;

        try {
            const response = await fetch(uri, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Ocp-Apim-Subscription-Key": HIPE_XR_API_KEY,
                },
                body: JSON.stringify(companyItem),
            });
    
            if (response.ok) {
                const savedCompanyItem = await response.json() as CompanyItem;

                return savedCompanyItem;
            } else {
                console.log(`Failed to save company item`, response.status + response.statusText);
            }
        } catch (e) {
            console.log(`Failed to save company item`, e);
        }
    }

    /**
     * Delete a company 
     * @param token vertx token to get company id (vertx tenant id)
     * @returns {Promise<boolean>} A promise that resolves to true if the company was deleted, false otherwise
     */
    static async deleteCompanyAsync(token?: string): Promise<boolean> {
        token = token ?? Vertex.Globals.bearerToken;

        const companyId = await UserProfileUtils.getTenantId(token);

        if(!companyId){
            return;
        }

        let uri = `${HIPE_XR_API_DOMAIN}/${SYSTEM_API_ENDPOINT}/${COMPANIES_API_ENDPOINT}/${companyId}`;
        uri += `?${HIPE_XR_API_VERSION_PARAM}=${HIPE_XR_API_VERSION}`;

        try {
            const response = await fetch(uri, {
                method: "DELETE",
                headers: {
                    "Ocp-Apim-Subscription-Key": HIPE_XR_API_KEY,
                },
            });
    
            if (response.ok) {
                return true;
            } else {
                console.log(`Failed to delete company`, response.status + response.statusText);

                return false;
            }
        } catch (e) {
            console.log(`Failed to delete company`, e);

            return false;
        }
    }
}

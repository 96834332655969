import Swal from "sweetalert2";
import { Utils } from "../../../utilities/utils";
import { CustomPointsOfInterestComponent, PointOfInterest } from "../NodeComponents/pointsofinterestcomponent";
import { CustomSpawnPointComponent } from "../NodeComponents/spawnpointcomponent";
import { MAX_MATERIAL_NAME_LENGTH, MAX_POI_NAME_LENGTH } from "../../../utilities/constants";

export class PointsOfInterestPanelComponent extends Vertex.NodeComponentModel.Component {

    writeData(writer: Vertex.BinaryWriter): void {
    }

    readData(reader: Vertex.BinaryReader): void {
    }

    get spawnPoint(): CustomSpawnPointComponent {
        const spawnpointNode: Vertex.NodeComponentModel.VertexNode = Array.from(
            Vertex.Globals.runtime.space.nodes.values()
        ).find((node: Vertex.NodeComponentModel.VertexNode) =>
            node.components.includes("SpawnPoint")
        ) as Vertex.NodeComponentModel.VertexNode;

        let spawnpointComp = null;
        if (spawnpointNode) {
            spawnpointComp = spawnpointNode.getComponent(
                "SpawnPoint"
            ) as CustomSpawnPointComponent;
        }

        return spawnpointComp;
    }
}

export class PointsOfInterestPanelComponentView extends Vertex.NodeComponentModel.ComponentViewBase {

    container: HTMLDivElement;
    panel: HTMLDivElement;
    list: HTMLUListElement;

    pointsOfInterestComp: CustomPointsOfInterestComponent;
    comp: PointsOfInterestPanelComponent;
    previewMode: boolean;
    previewModeLoaded: boolean = false;

    async addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        this.comp = component as PointsOfInterestPanelComponent;

        Vertex.Globals.event.on("hevolus:PointsOfInterestComponentChanged", (comp: CustomPointsOfInterestComponent) => {
        this.pointsOfInterestComp = comp;
            this.drawPoI();
        });

        Vertex.Globals.event.on("previewModeLoaded",() => {
            this.previewModeLoaded = true;
        });


        this.container = document.querySelector(".container-overlay-left");
        this.container.classList.remove("hidden");

        // Create Hierarchy Panel

        Vertex.Globals.event.on('togglePreviewMode', (toggle) => {
            this.previewMode = toggle;
            this.previewModeLoaded = toggle;
        });

        let card = document.createElement("div");
        card.classList.add("card", "pointer-enable");
        card.id="points-of-interest-panel";

        let header = document.createElement("div");
        header.classList.add("nav-link", "card-header-minimizable", "dark-text","max");

        let headerIcon = document.createElement("img");
        headerIcon.src = "/img/points-of-interest-icon.svg";
        headerIcon.classList.add("card-header-icon");
        
        let headerText = document.createElement("div");
        headerText.classList.add("card-header-text");
        headerText.innerText = "Points of Interest";

        let headerButton = document.createElement('button');
        headerButton.type = "button";
        headerButton.id = "add-save-poi-button";
        headerButton.classList.add("btn", "btn-info", "float-right", "btn-sm", "ml-1");
        headerButton.dataset.toggle = "add";

        let headerButtonImg = document.createElement("img");
        headerButtonImg.src = "/img/add-icon.svg";
        headerButtonImg.classList.add('action-list-add', 'button-icon');

        headerButton.appendChild(headerButtonImg);

        headerButton.addEventListener("click", async (e) => {
            if(!this.previewMode){

                const spawnpointComp = this.comp.spawnPoint;
                if (spawnpointComp && !spawnpointComp.isPlacedOnNavmesh()) {
                    await Swal.fire({
                        icon: "warning",
                        title: "The Spawn Point is not above the Navmesh",
                        html: "To add a PoI, you need to place the Spawn Point above the Navmesh.<br>Please reposition it first, and then add the PoI.",
                        footer: "The Spawn Point sets the exact position where the user avatars will be spawned.",
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                        heightAuto: false,
                    });
                    return;
                }

                await Vertex.Globals.event.fire('switchToPreviewMode');
                await this.showPoiInfoSwal();
                headerButton.dataset.toggle = "save";
            }
            else if(headerButton.dataset.toggle === "add"){
                await this.showPoiInfoSwal();
                headerButton.dataset.toggle = "save";
                await this.showAddPoiSwal();
            }
            else{
                await this.showAddPoiSwal();
            }
        });


        let body = document.createElement("div");
        body.classList.add("card-body", "p-0");

        this.panel = document.createElement("div");
        // panel.classList.add("scene-hierarchy-panel", "h-100");

        this.list = document.createElement("ul") as HTMLUListElement;
        this.list.id = "poi-list";

        this.list.style.margin = "0";
        this.list.style.padding = "0";
        // Grid Area

        let leftSidebar = document.querySelector(".left-sidebar-grid");

        // Append
        leftSidebar.appendChild(card);
        card.appendChild(header);
        header.appendChild(headerIcon);
        header.appendChild(headerText);
        header.appendChild(headerButton);
        card.appendChild(body);
        body.appendChild(this.panel);
        this.panel.appendChild(this.list);

        const sidebarButton = Utils.setupSidebarButton("PointsOfInterestPanel", "points-of-interest-panel");
        sidebarButton.classList.add('preview-mode-button');

    }

    private showPoiInfoSwal = async () => {
            await Swal.fire({
                title: 'Add Point of Interest',
                html: `Move your avatar to the desired location and click the add button.`,
                showConfirmButton: true,
                showCancelButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                footer: `Use WASD to move around. Left mouse to move the view. Scroll to switch between 1st and 3rd person view.`,
                heightAuto: false
            });                
    }

    private showAddPoiSwal = async () => {
        
        let poiName = "";
        let canCreate = false;

        Swal.enableInput();
        await Swal.fire({
            title: 'Enter a name for the Point of Interest:',
            input: "text",
            inputLabel: "Point of Interest name",
            inputPlaceholder: "Beautiful view of ...",
            inputAttributes: {
                maxLength: `${MAX_POI_NAME_LENGTH}`
            },
            inputValidator: (value) => {
                Swal.getInput().value = value = Utils.sanitizeString(value.trim());

                if(!value){
                    return `Please choose PoI name`;
                }

                if(this.pointsOfInterestComp.pointsOfInterest.map(poi => poi.name).includes(value)){
                    return `A PoI with this name already exists`;
                }
            },
            showConfirmButton: true,
            showCancelButton: true,
            showCloseButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            heightAuto: false
        }).then(result => {
            if(result.isConfirmed){
                poiName = result.value;
                canCreate = true;
            }
        });

        if(canCreate){
            Swal.fire({
                title: `Creating<br>${poiName}<br>PoI ...`,
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: true,
                heightAuto: false,
                width: "auto"
            });

            await Swal.showLoading();

            await this.savePoI(poiName);

            Swal.close();
        }
    }

    async savePoI(name: string) {
        let poi = new PointOfInterest();

        poi.name = name;
        poi.position = this.pointsOfInterestComp.avatar.player.transform.position.asArray();
        poi.rotation = this.pointsOfInterestComp.avatar.player.transform.rotation.asArray();
        poi.alpha = this.pointsOfInterestComp.camera.alpha;
        poi.beta = this.pointsOfInterestComp.camera.beta;
        poi.radius = this.pointsOfInterestComp.camera.radius;

        this.pointsOfInterestComp.pointsOfInterest.push(poi);

        await this.pointsOfInterestComp.postComponentJsonToResource(true);
        
        this.pointsOfInterestComp.triggerOnChanged();
    }

    private async removePoI(index: number){
        this.pointsOfInterestComp.pointsOfInterest.splice(index, 1);

        await this.pointsOfInterestComp.postComponentJsonToResource(true);

        this.pointsOfInterestComp.triggerOnChanged();
    }

    private drawPoI(){        
        let self = this; 

        while(this.list.hasChildNodes()){
            this.list.removeChild(this.list.firstChild);
        }

        let listCardinality = this.pointsOfInterestComp.pointsOfInterest.length;

        for(let i = 0; i < listCardinality; i++){
            let listElement = document.createElement("div");
            listElement.classList.add("scene-list", "list-item", "skybox-holder", "draggable-poi");
            listElement.style.cursor = "default";

            let dragImg = document.createElement("img");
            dragImg.classList.add("draggable");
            dragImg.id = `${i}-drag-img`;
            dragImg.src = "/img/drag-icon.svg";
            dragImg.draggable = false;

            //dragging logic -------------------------------------------------
            let placeholder;
            let isDraggingStarted = false;

            // The current position of mouse relative to the dragging element
            let x = 0;
            let y = 0;

            // Swap two nodes
            const swap = function (nodeA, nodeB) {
                const parentA = nodeA.parentNode;
                const siblingA = nodeA.nextSibling === nodeB ? nodeA : nodeA.nextSibling;

                // Move `nodeA` to before the `nodeB`
                nodeB.parentNode.insertBefore(nodeA, nodeB);

                // Move `nodeB` to before the sibling of `nodeA`
                parentA.insertBefore(nodeB, siblingA);
            };

            // Check if `nodeA` is above `nodeB`
            const isAbove = function (nodeA, nodeB) {
                // Get the bounding rectangle of nodes
                const rectA = nodeA.getBoundingClientRect();
                const rectB = nodeB.getBoundingClientRect();

                return rectA.top + rectA.height / 2 < rectB.top + rectB.height / 2;
            };

            const mouseDownHandler = function (e) {
                if(!e.target.id.includes("drag-img")){
                    return;
                }

                listElement = e.target.parentElement;

                // Calculate the mouse position
                const rect = listElement.getBoundingClientRect();
                x = e.pageX - rect.left;
                y = e.pageY - rect.top;

                // Attach the listeners to `document`
                document.addEventListener('mousemove', mouseMoveHandler);
                document.addEventListener('mouseup', mouseUpHandler);
            };

            const mouseMoveHandler = function (e) {
                const draggingRect = listElement.getBoundingClientRect();

                if (!isDraggingStarted) {
                    isDraggingStarted = true;

                    // Let the placeholder take the height of dragging element
                    // So the next element won't move up
                    placeholder = document.createElement('div');
                    placeholder.classList.add('placeholder');
                    listElement.parentNode.insertBefore(placeholder, listElement.nextSibling);
                    placeholder.style.height = `${draggingRect.height}px`;
                }

                // Set position for dragging element
                listElement.style.position = 'fixed';
                listElement.style.top = `${e.pageY - y}px`;
                listElement.style.left = `${e.pageX - x}px`;

                // The current order
                // prevEle
                // draggingEle
                // placeholder
                // nextEle
                const prevEle = listElement.previousElementSibling;
                const nextEle = placeholder.nextElementSibling;

                // The dragging element is above the previous element
                // User moves the dragging element to the top
                if (prevEle && isAbove(listElement, prevEle)) {
                    // The current order    -> The new order
                    // prevEle              -> placeholder
                    // draggingEle          -> draggingEle
                    // placeholder          -> prevEle
                    swap(placeholder, listElement);
                    swap(placeholder, prevEle);

                    return;
                }

                // The dragging element is below the next element
                // User moves the dragging element to the bottom
                if (nextEle && isAbove(nextEle, listElement)) {
                    // The current order    -> The new order
                    // draggingEle          -> nextEle
                    // placeholder          -> placeholder
                    // nextEle              -> draggingEle
                    swap(nextEle, placeholder);
                    swap(nextEle, listElement);
                }
            };

            const mouseUpHandler = async function () {
                // Remove the placeholder
                placeholder && placeholder.parentNode.removeChild(placeholder);

                listElement.style.removeProperty('top');
                listElement.style.removeProperty('left');
                listElement.style.removeProperty('position');

                x = null;
                y = null;
                listElement = null;
                isDraggingStarted = false;

                // Remove the handlers of `mousemove` and `mouseup`
                document.removeEventListener('mousemove', mouseMoveHandler);
                document.removeEventListener('mouseup', mouseUpHandler);

                await self.reorderPoIs();
            };
            //------------------------------------------------------------------

            let labelElement = document.createElement("div");
            labelElement.id = `${i}-poi-name`;
            labelElement.classList.add("input-container");
            labelElement.style.width = "-webkit-fill-available";
            
            let labelInput = document.createElement("input");
            labelInput.id = `${i}-poi-name-input`;
            labelInput.classList.add("input-field", "list-title", "form-control", "form-control-sm", "mr-2");
            labelInput.type = "text";
            labelInput.minLength = 1;
            labelInput.maxLength = 20;
            labelInput.placeholder = `Point of interest ${i+1}`;
            labelInput.value = `${this.pointsOfInterestComp.pointsOfInterest[i].name}`;
            labelInput.autocomplete = "off";
            labelInput.required = true;
            labelInput.draggable = false;
            labelInput.addEventListener("change", async (event) => {
                labelInput.value = Utils.sanitizeString(labelInput.value.trim());

                if(!labelInput.value){
                    await Swal.fire({
                        icon: "warning",
                        title: `The PoI name cannot be empty`,
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                        heightAuto: false,
                    });

                    labelInput.value = self.pointsOfInterestComp.pointsOfInterest[i].name;

                    return;
                }

                if(this.pointsOfInterestComp.pointsOfInterest.map(poi => poi.name).includes(labelInput.value)){
                    await Swal.fire({
                        icon: "warning",
                        title: `A PoI with the name<br>${labelInput.value}<br>already exists`,
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                        heightAuto: false
                    });

                    labelInput.value = self.pointsOfInterestComp.pointsOfInterest[i].name;

                    return;
                }
                
                self.pointsOfInterestComp.pointsOfInterest[i].name = labelInput.value;
                await self.pointsOfInterestComp.postComponentJsonToResource(true);
                self.pointsOfInterestComp.triggerOnChanged();
            });

            labelElement.appendChild(labelInput);

      
            let buttonContainer = document.createElement("div");
            buttonContainer.style.display = "flex";
            buttonContainer.style.width = "fit-content";

            let lookButton = document.createElement("button") as HTMLButtonElement;
            lookButton.id = "look-button";
            lookButton.classList.add("btn", "btn-lg", "p-0", "icon-button", "poi-look-button");
            lookButton.style.alignSelf = "center";
            lookButton.draggable = false;
            lookButton.addEventListener("click", () => {
                this.lookAtPoI(i);
            });
      
            let lookIcon = document.createElement("img");
            lookIcon.classList.add("node-icon");
            lookIcon.src = "/img/preview.svg";
            lookButton.appendChild(lookIcon);
            
            Utils.injectSvg(lookIcon);

            lookIcon.draggable = false;
      
            //Add the DELETE buttons to each elem in the scrollable list view
            let deleteButton = document.createElement("button");
            deleteButton.classList.add("btn", "btn-lg", "p-0", "icon-button", "list-item-bin-delete-button");
            deleteButton.style.alignSelf = "center";
            deleteButton.draggable = false;
            deleteButton.addEventListener("click", async (event) => {
                event.stopPropagation();
        
                let shouldCancel = false;
        
                await Swal.fire({
                    title: "Are you sure you want to delete this Point of Interest?",
                    text: `Deleting a PoI is permanent`,
                    showCancelButton: true,
                    showConfirmButton: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    heightAuto: false,
                    confirmButtonColor: "red",
                }).then((result) => {
                    if (!result.isConfirmed) {
                        shouldCancel = true;
                    }
                });
    
                if (shouldCancel) return;
    
                await this.removePoI(i);
            });
      
            let deleteIcon = document.createElement("img");
            deleteIcon.classList.add("node-icon");
            deleteIcon.src = "/img/trash.svg";
            deleteIcon.draggable = false;
            deleteButton.appendChild(deleteIcon);

            Utils.injectSvg(deleteIcon);

            listElement.appendChild(dragImg);
            listElement.appendChild(labelElement);
            listElement.appendChild(buttonContainer);
            buttonContainer.appendChild(lookButton);
            buttonContainer.appendChild(deleteButton);
    
            listElement.addEventListener("mousedown", mouseDownHandler);

            this.list.appendChild(listElement);
        }
    }

    async lookAtPoI(i: number) {
        if(!this.previewMode){

            if (!this.pointsOfInterestComp.isPlacedOnNavmesh(i)) {
                await Swal.fire({
                    icon: "warning",
                    title: "The Point of Interest is not above the Navmesh",
                    html: "To look at a PoI, it needs to be placed above the Navmesh.<br>Please delete it or reposition the navmesh first.",
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    heightAuto: false,
                });
                return;
            }

            Vertex.Globals.event.fire('switchToPreviewMode');
        }

        Utils.waitForCondition(_ => this.previewModeLoaded && this.pointsOfInterestComp.avatar != null).then(() => {
            let poi = this.pointsOfInterestComp.pointsOfInterest[i];
            this.pointsOfInterestComp.avatar.player.teleportToPoint(BABYLON.Vector3.FromArray(poi.position));
            this.pointsOfInterestComp.avatar.player.setRotation(BABYLON.Vector3.FromArray(poi.rotation));
            this.pointsOfInterestComp.camera.alpha = poi.alpha;
            this.pointsOfInterestComp.camera.beta = poi.beta;
            this.pointsOfInterestComp.camera.radius = poi.radius;
        });
    }
    
    private async reorderPoIs(){
        const currentNames = this.pointsOfInterestComp.pointsOfInterest.map(poi => poi.name);
        const elements = Array.from(this.list.querySelectorAll('.draggable-poi')) as HTMLDivElement[];
        const newOrder: Map<string, number> = new Map();

        elements.forEach(
            (element, index) => {
                newOrder.set((element.querySelector('.input-field') as HTMLInputElement).value, index);
            }
        );

        for(let i = 0; i < currentNames.length; i++){
            if(newOrder.get(currentNames[i]) !== i){

                //time to swap in comp arrays
                const newIndex = newOrder.get(currentNames[i]);
                this.swapItems(i, newIndex, this.pointsOfInterestComp.pointsOfInterest);
                
                //we also need to swap the currentNames because it needs to match the order of the comp arrays
                this.swapItems(i, newIndex, currentNames);
            }
        }

        await this.pointsOfInterestComp.postComponentJsonToResource(true);
        this.pointsOfInterestComp.triggerOnChanged();
    }

    swapItems = function(a, b, array){
        array[a] = array.splice(b, 1, array[a])[0];
        return array;
    }
    
    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
    }
}

export class PointsOfInterestPanelComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new PointsOfInterestPanelComponent();
    }

    constructor() {
        super("PointsOfInterestPanel", new PointsOfInterestPanelComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}

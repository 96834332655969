export class SidebarComponent extends Vertex.NodeComponentModel.Component {

    writeData(writer: Vertex.BinaryWriter): void {
    }
    readData(reader: Vertex.BinaryReader): void {
    }
    
}

export class SidebarComponentComponentView extends Vertex.NodeComponentModel.ComponentViewBase {

    constructor() {
        super();
    }

    sidebar: HTMLDivElement;
    sidebarButton: HTMLDivElement;

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        const backButton = document.getElementById("sidebar-back");

        backButton.addEventListener("click", () =>{

            console.log("BACK BUTTOOOOOON!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
            window.location.href = "/";

        })
    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        //
    }

    update(): void {
        //
    }

}

export class SidebarComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {

    public create(): Vertex.NodeComponentModel.Component {
        return new SidebarComponent();
    }
    constructor() {
        super("Sidebar", new SidebarComponentComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }

}
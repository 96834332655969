import { CustomModelAlternativeComponent } from "../componentsystems/NodeComponents/modelalternativecomponent";
import { CustomActionsRenderer } from "./customactionsrenderer";
import { CustomActionSettingsComponent } from "../componentsystems/NodeComponents/actionsettingscomponent";
import { HevolusResourceType, ResourceUtils } from "../../utilities/resource-utilities";
import { NotificationStatus, Utils } from "../../utilities/utils";
import { CustomTriggerableComponentItemRenderer } from "./customtriggerablecomponentItemrenderer";
import { MODEL_THUMB_URI } from "../../utilities/constants";

export class CustomModelAlternativeRenderer implements Vertex.UI.ICustomInspectorRenderer {
    constructor(targetNode: Vertex.NodeComponentModel.VertexNode) {
        this.targetNode = targetNode;
    }

    targetNode: Vertex.NodeComponentModel.VertexNode;
    target: CustomModelAlternativeComponent;
    property: string;
    modelAlternativePanel: HTMLDivElement;
    actionsRenderer: CustomActionsRenderer;
    triggerableComponentItemRenderers: CustomTriggerableComponentItemRenderer[] = [];

    static currentRenderer: CustomModelAlternativeRenderer;

    actionSettingsComp: CustomActionSettingsComponent;

    get actionSettings(): CustomActionSettingsComponent {
        if (this.actionSettingsComp) {
            return this.actionSettingsComp;
        }

        let runtime = Vertex.Globals.runtime as Vertex.VertexRuntime;
        runtime.space.nodes.forEach(node => {
            const comp = node.getComponent("ActionSettings") as CustomActionSettingsComponent;
            
            if (comp) {
                this.actionSettingsComp = comp;
            }
        });

        return this.actionSettingsComp;
    }

    //Target appears to be the node but we also have just targetNode so not sure why it's needed.
    RenderProperty(property: string, target: any): HTMLDivElement {
        this.target = target;
        this.property = property;

        if (CustomModelAlternativeRenderer.currentRenderer) {
            Vertex.Globals.event.off("ActionSettings:ActionAdded", CustomModelAlternativeRenderer.currentRenderer.renderActions);
            Vertex.Globals.event.off("ActionSettings:ActionUpdated", CustomModelAlternativeRenderer.currentRenderer.renderActions);
            CustomModelAlternativeRenderer.currentRenderer.target.onChanged.off(CustomModelAlternativeRenderer.currentRenderer.renderActions);
        }

        this.modelAlternativePanel = document.createElement('div');

        this.renderAlternativeModelList();
        this.renderDropzone();
        this.renderActions();

        CustomModelAlternativeRenderer.currentRenderer = this;

        Vertex.Globals.event.on("ActionSettings:ActionAdded", this.renderActions);
        Vertex.Globals.event.on("ActionSettings:ActionUpdated", this.renderActions);
        this.target.onChanged.on(this.renderActions);

        return this.modelAlternativePanel;
    }

    renderAlternativeModelList = (() => {
        const self = this;
        const gltfs = self.target[self.property];
        self.modelAlternativePanel.innerText = "";

        const gltfComp = this.target.gltfModelComp;
        this.triggerableComponentItemRenderers = [];

        for (let i = 0; i < gltfs.length; i++) {
            const elementId = gltfs[i];

            const modelNamePromise = ResourceUtils.getResourceData(elementId).then(quickFetch => quickFetch?.name ?? "Model");
            const thumbnailUrlPromise = ResourceUtils.getThumbnailBlobUrl(gltfs[i]).then(thumb => thumb ?? MODEL_THUMB_URI);

            const isStarred = gltfComp.Id == elementId;

            const onClickStar = (() => {
                if (elementId.length > 0 && gltfComp.Id != elementId) {
                    this.target.enqueueOperation(elementId);
                    return true;
                }
                return false;
            });

            const onClickDelete = (() => {
                gltfs.splice(i, 1);

                let actionIndexes = self.target.actionValues.filter(actionValue => actionValue == i).map((value, index) => index);
                for (let i = actionIndexes.length - 1; i >= 0; i--) {
                    self.target.actionValues.splice(actionIndexes[i], 1);
                    self.target.actionIndexes.splice(actionIndexes[i], 1);
                }
                for(let j = 0; j < self.target.actionValues.length; j++) {
                    if(self.target.actionValues[j] > i) {
                        self.target.actionValues[j] = self.target.actionValues[j]-1;
                    }
                }

                let triggerActionIndexes = self.target.triggerActionValues.filter(actionValue => actionValue == i).map((value, index) => index);
                for (let i = triggerActionIndexes.length - 1; i >= 0; i--) {
                    self.target.triggerActionValues.splice(triggerActionIndexes[i], 1);
                    self.target.triggerActionIndexes.splice(triggerActionIndexes[i], 1);
                }
                for(let j = 0; j < self.target.triggerActionValues.length; j++) {
                    if(self.target.triggerActionValues[j] > i) {
                        self.target.triggerActionValues[j] = self.target.triggerActionValues[j]-1;
                    }
                }

                self.target.triggerOnChanged();
                self.renderAlternativeModelList();
                self.renderActions();
                self.renderDropzone();
                Vertex.Globals.event.fire("ActionSettings:ActionAssignmentUpdated");
            });

            const triggerableRenderer = new CustomTriggerableComponentItemRenderer(self.target, self.actionSettings, i, modelNamePromise, thumbnailUrlPromise, isStarred, onClickStar, onClickDelete);
            self.triggerableComponentItemRenderers.push(triggerableRenderer);
            
            const triggerableRendererElement = triggerableRenderer.RenderItem();
            self.modelAlternativePanel.appendChild(triggerableRendererElement);
        }

    }).bind(this);

    renderDropzone = ((modelAlternativePanel: HTMLElement) => {
        let self = this;

        let dropzoneContainer = document.createElement("div");
        let dropzone = document.createElement("div");
        dropzone.innerText = "Drag and drop a resource from the resource panel here";
        dropzoneContainer.style.margin = "28px 10px";
        dropzoneContainer.style.padding = "20px 2px";
        dropzoneContainer.classList.add("dropzone-wide");

        dropzone.addEventListener("dragover", function (event) {
            event.preventDefault();
            event.stopPropagation();
        });
        
        dropzone.addEventListener("dragleave", function (event) {
            event.preventDefault();
            event.stopPropagation();
        });

        dropzone.addEventListener("drop", async function (event) {
            try{
                if (event.dataTransfer) {
                    let newElementId = event.dataTransfer.getData("text/plain");

                    if(!newElementId){
                        Utils.notify("This model is invalid.", NotificationStatus.Error);
                        
                        return;
                    }

                    if(self.target[self.property].includes(newElementId)){
                        Utils.notify("This model is already in the list.", NotificationStatus.Info);

                        return;
                    }

                    let droppedResource = await ResourceUtils.getResourceData(newElementId);

                    if(!droppedResource){
                        Utils.notify("An error occurred. Please try again later.", NotificationStatus.Error);

                        return;
                    }

                    const resType = ResourceUtils.getHevolusResourceType(droppedResource);

                    if(resType !== HevolusResourceType.Model){
                        Utils.notify("Only Model resources are allowed.", NotificationStatus.Error);

                        return;
                    }

                    //check if private (for published) or published (for private) resource is already in the list
                    if((droppedResource.publishParent && self.target[self.property].includes(droppedResource.publishParent)) ||
                        (droppedResource.publishedResources?.length && self.target[self.property].includes(droppedResource.publishedResources[0])) ){
                        Utils.notify("This model is already in the list.", NotificationStatus.Info);

                        return;
                    }

                    self.target[self.property].push(newElementId);
                    self.target.triggerOnChanged();
                    self.renderAlternativeModelList();
                    self.renderActions();
                    self.renderDropzone();
                }                
            }
            catch(e){
                //
            }
            finally{
                event.preventDefault();
                event.stopPropagation();                
            }

        });

        dropzoneContainer.appendChild(dropzone);
        self.modelAlternativePanel.appendChild(dropzoneContainer);
    }).bind(this);

    renderActions = (async ()=> {
        await this.actionSettings.isReady;

        let modelsNames = [];
        for (let i = 0; i < this.target.modelList.length; i++) {
            const modelId = this.target.modelList[i];
            const modelName = await this.getModelName(modelId);
            modelsNames.push(modelName);
        }

        if(this.actionsRenderer == null) {
            this.actionsRenderer = new CustomActionsRenderer();
        }

        let actionListCardElement = this.actionsRenderer.RenderActions(this.target, this.actionSettings, modelsNames);
        this.modelAlternativePanel.append(actionListCardElement);

        const gltfComp = this.target.gltfModelComp;
        const gltfs = this.target[this.property];

        this.triggerableComponentItemRenderers.forEach((triggerableComponentItemRenderer, index) => {
            const elementId = gltfs[index];
            const isStarred = gltfComp.Id == elementId;
            triggerableComponentItemRenderer.RenderItem(isStarred);
        });
    }).bind(this);

    getModelName = async (modelId: string): Promise<string> => {
        const quickFetch = await ResourceUtils.getResourceData(modelId);
        return quickFetch.name;
    }
}
import Swal, { SweetAlertOptions, SweetAlertResult } from "sweetalert2";
import { removeFromArray, SpacePrivacy, Utils, IUpdtableAfterSaving, instanceOfIUpdtableAfterSaving } from "../../../utilities/utils";
import { AugmentedStoreAssembly } from "../../../../AugmentedStoreAssembly";
import { CustomGltfLoadingHandlerComponent } from "../NodeComponents/gltfLoadingHandlerComponent";
import { getCurrentVersion, HevolusApp } from "../../../utilities/versioning";
import { ResourceUtils, VERTEXResource, HevolusResourceType } from "../../../utilities/resource-utilities";
import { SERIALIZED_SPACE_FILENAME, SPACEPRIVACY_PRIVATE_TAG, SPACEPRIVACY_PUBLISHED_TAG, SPACEPRIVACY_PUBLIC_TAG, SPACE_EDITOR_VERSION_PREFIX_TAG, SERIALIZED_PREVIEW_SPACE_FILENAME, RESOURCE_THUMB_ALL_PLATFORM_FILENAMES, RESOURCE_THUMB_FILENAME, Space, Node, SPACEPRIVACY_OFFLINE_TAG, MAX_RESOURCE_NAME_LENGTH } from "../../../utilities/constants";
import { Config } from "../../../../config";
import { cloneDeep } from "lodash";
import { CustomSkyboxComponent } from "../NodeComponents/skyboxcomponent";

export class PersistanceComponent extends Vertex.NodeComponentModel.Component {

    writeData(writer: Vertex.BinaryWriter): void {
    }

    readData(reader: Vertex.BinaryReader): void {
    }

}


export enum SpaceOpResult{
    Success = 0,
    Canceled = 1,
    Failed = 2
}


export class PersistanceComponentView extends Vertex.NodeComponentModel.ComponentViewBase {
    loadedNodes: Array<Vertex.NodeComponentModel.VertexNode> = [];

    componetsToSave = [
        "DirectionalLight",
        "PointLight",
        "SpotLight",
        "SkyboxSettings",
        "CameraProperties",
        "Skybox",
        "ItemProperties",
        "PostProcessProperties",
        "LightMapsHandler",
        "SceneProperties",
        "IntroSplash",
        "PointsOfInterest",
        "SpawnPoint",
        "Text2D",
        "ActionSettings",
        "SavedCO2Visualizer",
        "NavMesh",
        "VolumeTrigger"
    ];

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        let resourceId = Vertex.Globals.spaceId;
        //let sceneFileName = defaultSaveFileName;
        let self = this;

        ResourceUtils.getResourceData(resourceId).then(async (res) => {
            if(res){
                const tempJsonAssets = res.resourceKeys.filter(key => key.includes("_editor.json"));
                
                for(let tempJsonAsset of tempJsonAssets){
                    await ResourceUtils.deleteAssetFromResource(resourceId, tempJsonAsset);
                }

                //get scene.bin file, and if exists, load it
                self.loadSpace(SERIALIZED_SPACE_FILENAME, resourceId);
            }
            else{
                console.log("Failed to get resource data.");
            }
        });

        Vertex.Globals.event.on("editor:createPreview", async (privacy) => {
            Swal.fire({
                icon: "info",
                title: `Creating preview`,
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                heightAuto: false
            });
    
            Swal.showLoading();

            const result = await this.createPreview();

            if(result){

                await Swal.fire({
                    title: "Redirect to preview!",
                    icon: "success",
                    showConfirmButton: true,
                    heightAuto: false,
                    didOpen: () =>{
                        Swal.getConfirmButton().addEventListener("click", ()=>{
                            window.open(
                                `${Config.HVERSE_URL}preview/${Vertex.Globals.spaceId}`,
                                '_blank'
                            );
                        })
                    }
                })
            }
            else{
                await Swal.fire({
                    title: "Oops! Something went wrong.",
                    icon: "error",
                    showConfirmButton: true,
                    heightAuto: false
                });
            }
        })



        Vertex.Globals.event.on("editor:saveSpace", async (privacy) => {
            const result = await this.saveSpace(resourceId, privacy);

            if (result === SpaceOpResult.Success) {
                await Swal.fire({
                    title: "Saved!",
                    icon: "success",
                    showConfirmButton: true,
                    heightAuto: false
                });
            }
            else if(result === SpaceOpResult.Failed){
                await Swal.fire({
                    title: "Oops! Something went wrong.",
                    icon: "error",
                    showConfirmButton: true,
                    heightAuto: false
                });
            }
            else if(result === SpaceOpResult.Canceled){
                await Swal.fire({
                    title: "Saving Canceled!",
                    icon: "info",
                    showConfirmButton: true,
                    heightAuto: false
                });
            }
        });

        Vertex.Globals.event.on("editor:saveSpaceCopy", async (privacy) => {
            await this.saveSpaceCopy(resourceId, privacy);
        });
    }

    private async saveSpace(resourceId: string, privacy: SpacePrivacy) {
        Swal.fire({
            icon: "info",
            title: `Saving<br>1 / 2 ...`,
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            heightAuto: false
        });

        Swal.showLoading();

        let res = await ResourceUtils.getResourceData(resourceId);

        if(!res){
            return SpaceOpResult.Failed;
        }

        let isPublicLink = false;

        if(res.publishedResources?.length){
            isPublicLink = await ResourceUtils.isPublicLink(res.publishedResources[0]);
        }

        if(isPublicLink){
            if(!(privacy & SpacePrivacy.PublicLink)){
                await Swal.fire({
                    icon: "warning",
                    html: `This Space is currently used as <b>Public Link</b>.<br>
                            You can <b>not</b> save it with the selected privacy settings.<br>
                            If you want to remove this Space from Public Links please contact your Administrator.`,
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    heightAuto: false
                });

                return SpaceOpResult.Canceled;
            }
        }
        
        await Vertex.Globals.event.fire("hevolus:beforeSaveSpace");

        //this.createThumbnail(resourceId, Vertex.Globals.runtime.scene.cameras[0]);
        let result = await this.updateSpaceTags(res, privacy);

        if (result === SpaceOpResult.Success) {
            let nodes = Array.from<Vertex.NodeComponentModel.VertexNode>(Vertex.Globals.runtime.space.nodes.values());

            result = await this.ensureResourcesPrivacy(nodes, privacy);

            if(result === SpaceOpResult.Success){

                Swal.fire({
                    icon: "info",
                    title: `Saving<br>... 2 / 2`,
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    heightAuto: false
                });

                Swal.showLoading();

                await this.removeUnusedComponentJsonAssets(nodes);

                result = await this.saveSpaceBinary(resourceId);

                if (result === SpaceOpResult.Success) {
                    if (privacy === SpacePrivacy.Private) {
                        result = await ResourceUtils.unpublishResource(resourceId) ? SpaceOpResult.Success : SpaceOpResult.Failed;
                    }
                    else {
                        result = await this.publishSpaceResource(resourceId) ? SpaceOpResult.Success : SpaceOpResult.Failed;
                    }

                    if (result !== SpaceOpResult.Success) {
                        console.log(`Failed to publish/unpublish Space resource.`);
                    }
                }
                else {
                    console.log(`Failed to save Space binary file.`);
                }
            }
            else {
                console.log(`Failed to ensure resource privacy policies.`);
            }
        }
        else {
            console.log(`Failed to update tags on resource ${resourceId}`);
        }

        return result;
    }


    public async createPreview() : Promise<boolean>{
        let resourceId = Vertex.Globals.spaceId;

        try{
            Vertex.Globals.event.fire("hevolus:beforeCreatePreview");

            let spaceBuffer = await this.serializeSpaceBin();
    
            if(spaceBuffer){
                let response = await ResourceUtils.postAssetToResource(SERIALIZED_PREVIEW_SPACE_FILENAME, resourceId, spaceBuffer);
                
                return response.ok
            }
        }
        catch {
            return false;
        }
    }


    async saveSpaceCopy(resourceId: string, privacy: SpacePrivacy) {
        let result: SpaceOpResult;
        let originalRes: VERTEXResource = null;
        let newResourceName = "";

        try {
            originalRes = await ResourceUtils.getResourceAsync(resourceId, false);
        }
        catch (e) {
            console.log(`Failed to get res ${resourceId} info.`, e);
        }

        if (originalRes) {
            newResourceName = originalRes.name + " - copy";
        }
        else {
            newResourceName = "New Space copy - " + Date.now;
        }

        await Swal.fire({
            title: 'Enter a name for the new Space',
            input: 'text',
            inputLabel: 'Space Name:',
            inputValue: "",
            inputAttributes: {
                maxlength: `${MAX_RESOURCE_NAME_LENGTH}`,
            },
            showCancelButton: true,
            inputValidator: async (value) => {
                Swal.getInput().value = value = Utils.sanitizeString(value.trim());

                if (!value) {
                    return 'Please enter a name for the Space.';
                }

                if (value) {
                    const resourcesList = await ResourceUtils.getAllTenantResourcesAsync();

                    if (resourcesList) {
                        const resourceWithSameName = resourcesList.find(x => x["name"] === value);
        
                        if(resourceWithSameName){
                            return "A Resource with this name already exists";
                        }
                    }

                    newResourceName = value;
                }
            },
            heightAuto: false
        }).then((userResult: SweetAlertResult) => {
            if(!userResult.isConfirmed){
                result = SpaceOpResult.Canceled;
            }
        });

        if(result === SpaceOpResult.Canceled){
            return;
        }

        let newResourceId = await ResourceUtils.createNewResource(newResourceName, ResourceUtils.getVertexResourceType(HevolusResourceType.Space), originalRes.tags, false);
        let newSpaceRes = await ResourceUtils.getResourceData(newResourceId);
        
        let filesToCopy = originalRes.resourceKeys?.filter(k => k !== SERIALIZED_SPACE_FILENAME && k !== SERIALIZED_PREVIEW_SPACE_FILENAME);

        let copied = await ResourceUtils.copyAssetsToResource(resourceId, newResourceId, filesToCopy);

        if (copied) {
            result = await this.saveSpace(newResourceId, privacy);
        }
        else{
            console.log(`Failed to copy assets from res ${resourceId} to res ${newResourceId}`);

            result = SpaceOpResult.Failed;
        }

        if (result === SpaceOpResult.Success) {
            await Swal.fire({
                title: "Saved!",
                icon: "success",
                showConfirmButton: true,
                allowEscapeKey: false,
                allowOutsideClick: false,
                allowEnterKey: false,
                heightAuto: false
            });

            Swal.fire({
                title: 'Do you want to switch to the new Space?',
                showDenyButton: true,
                confirmButtonText: `Yes`,
                denyButtonText: `No`,
                heightAuto: false
            }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    await ResourceUtils.openResource(newSpaceRes);
                }
            });
        }
        else if(result === SpaceOpResult.Failed) {
            console.log(`Failed to save the Space copy`);

            await Swal.fire({
                title: "Oops! Something went wrong.",
                icon: "error",
                heightAuto: false
            });
        }
        else if(result === SpaceOpResult.Canceled){
            await Swal.fire({
                title: "Saving canceled!",
                icon: "info",
                showConfirmButton: true,
                allowEscapeKey: false,
                allowOutsideClick: false,
                allowEnterKey: false,
                heightAuto: false
            });
        }
    }

    async removeUnusedComponentJsonAssets(nodes: Array<Vertex.NodeComponentModel.VertexNode>) {
        let resourceData = await ResourceUtils.getResourceData(Vertex.Globals.spaceId);
        let jsonAssets = resourceData.resourceKeys.filter((asset) => asset.endsWith(".json"));

        for(let jsonAsset of jsonAssets){
            let splitted = jsonAsset.split("_");
            if(splitted.length >= 2){
                let nodeId = splitted[1];
                let node = nodes.find((node) => node.id === nodeId);
                if(node == null){
                    await ResourceUtils.deleteAssetFromResource(resourceData.id, jsonAsset);
                }
                else{
                    let componentName = splitted[0];
                    if(!node.components.includes(componentName))
                    {
                        await ResourceUtils.deleteAssetFromResource(resourceData.id, jsonAsset);
                    }
                }
            }
        }
    }


    async loadSpace(filename: string, id: string) {
        let result = await ResourceUtils.getAssetFromResource(id, filename)
        
        if (result.ok) {
            //pass the downloaded file as a buffer to the BSON deserialiser
            let spaceBuffer = await result.arrayBuffer();
            console.log("scene.bin exists with arraybuffer: ", spaceBuffer)
            let binReader = new Vertex.BinaryReader(spaceBuffer);
            let bsonReader = new Vertex.BSONReader(binReader);

            // This method takes an Action<>, which is invoked for every property in the BSON object. No knowledge of how many nodes until loading has finished.
            // propertyName = the name of the BSON property. This will be the serialized NodeID. Can be checked to prevent reading non-node types
            // bsonType = a System.Type representing the BSON type of the read property. unused
            // _reader2 = the bsonReader itself at the time it reads that property, ready to deserialize only that property if called.
            bsonReader.readObject((propertyName, bsonType, _reader2) => {

                if (propertyName.startsWith("node_")) {
                    let space = Vertex.Globals.runtime.space;
                    let node = space.createNode(propertyName) as Vertex.NodeComponentModel.VertexNode;
                    let oldType = node.componentDataType;
                    node.componentDataType = Vertex.NodeComponentModel.ComponentDataType.BSONObject;
                    node.readDataBSON(_reader2);

                    if (node.components.includes("Transform") && !node.components.includes("NodeLockable")) {
                        const nodeLockableComp = node.addComponent("NodeLockable") as AugmentedStoreAssembly.NodeLockableComponent;
                        nodeLockableComp.isLocked = false;
                        nodeLockableComp.triggerOnChanged();
                    }

                    if(node.components.includes("GltfModel")){

                        if(!node.components.includes("ChangeMaterial")){
                            const changeMaterialComp = node.addComponent("ChangeMaterial") as AugmentedStoreAssembly.ChangeMaterialComponent;
                        }

                        node.addComponent("GltfLoadingHandler") as CustomGltfLoadingHandlerComponent;
                    }

                    node.tokenHandoffPolicy = Vertex.NodeComponentModel.TokenHandoffPolicy.DestroyWithLastClient;
                    space.addNode(node);

                    this.loadedNodes.push(node);
                    console.log("added node from load file: ", node);
                }
                else {
                    console.log(`Unknown property: ${propertyName} found in serialized BSON!`);
                }
            });
        }

        Vertex.Globals.event.fire("hevolus:onSpaceLoaded", this.loadedNodes);
    }

    async updateSpaceTags(resource: VERTEXResource, privacy: SpacePrivacy): Promise<SpaceOpResult> {
        let result = SpaceOpResult.Success;
        let privacyTags: string[] = [];

        if (privacy & SpacePrivacy.Offline) {
            privacyTags.push(SPACEPRIVACY_OFFLINE_TAG);
        }

        if (privacy & SpacePrivacy.Private) {
            privacyTags.push(SPACEPRIVACY_PRIVATE_TAG);
        }

        if (privacy & SpacePrivacy.Public) {
            privacyTags.push(SPACEPRIVACY_PUBLISHED_TAG);
        }

        if (privacy & SpacePrivacy.PublicLink) {
            privacyTags.push(SPACEPRIVACY_PUBLIC_TAG);
        }

        let tags = resource.tags;

        if (tags?.length) {
            removeFromArray(tags, SPACEPRIVACY_OFFLINE_TAG, SPACEPRIVACY_PRIVATE_TAG, SPACEPRIVACY_PUBLISHED_TAG, SPACEPRIVACY_PUBLIC_TAG);
        }

        tags.push(...privacyTags);

        const currentVersionTag = tags.find(t => t.startsWith(SPACE_EDITOR_VERSION_PREFIX_TAG));

        if(currentVersionTag){
            tags.splice(tags.indexOf(currentVersionTag), 1);
        }

        const currentSpaceEditorVersion = await getCurrentVersion(HevolusApp.SpaceEditor);

        if(currentSpaceEditorVersion){
            tags.push(`${SPACE_EDITOR_VERSION_PREFIX_TAG}${currentSpaceEditorVersion}`);
        }

        try {
            await ResourceUtils.saveTagsWithRes(tags, resource, false, false);
        }
        catch (e) {
            result = SpaceOpResult.Failed;
            console.log(`Failed to update res ${resource.id} tags.`, e);
        }

        return result;
    }

    async ensureResourcesPrivacy(nodes: Array<Vertex.NodeComponentModel.VertexNode>, privacy: SpacePrivacy) {
        class ResDetails {
            vertexResource: VERTEXResource;
            mainComponent: Vertex.NodeComponentModel.Component;
            id: string;
            isPrivate: boolean;
            needUpdatePrivacy: boolean;
            needRepublish: boolean;

            modelAlternative: AugmentedStoreAssembly.ModelAlternativeComponent;
            modelAlternativeResources: VERTEXResource[] = [];
            modelAlternativePrivacies: boolean[];
            modelAlternativeIdIndexesToUpdatePrivacy: number[];
            modelAlternativeIdIndexesToRepublish: number[];

            constructor() {
                this.needRepublish = false;
                this.modelAlternativeResources = [];
                this.modelAlternativePrivacies = [];
                this.modelAlternativeIdIndexesToRepublish = [];
                this.modelAlternativeIdIndexesToUpdatePrivacy = [];
            }
        }

        // result: default true, if private true, if published/public user will confirm (in case some res need publishing only)
        let result = SpaceOpResult.Success;

        let isSpacePrivate: boolean = true;
        let resourcesDetail: ResDetails[] = [];

        let alertMsg = "";
        let alertMsgContent = [];

        if (privacy === SpacePrivacy.None) {
            return result;
        }
        else if (privacy !== SpacePrivacy.Private) {
            isSpacePrivate = false;
        }

        for (let i = 0; i < nodes.length; i++) {
            if(nodes[i].components.includes("GltfModel") || nodes[i].components.includes("MediaTexture")){
                let resDetails: ResDetails = new ResDetails();

                const gltfComp = nodes[i].getComponent("GltfModel") as Vertex.NodeComponentModel.GltfModelComponent;
                const mediaTexComp = nodes[i].getComponent("MediaTexture") as AugmentedStoreAssembly.MediaTextureComponent;

                resDetails.id = "";

                if (gltfComp) {
                    resDetails.mainComponent = gltfComp;
                    resDetails.id = gltfComp.Id;
                }
                else if (mediaTexComp) {
                    resDetails.mainComponent = mediaTexComp;
                    resDetails.id = mediaTexComp.id;
                }
                else {
                    console.log(`No Gltf or Image component found in node ${nodes[i].id}.`);
                }

                if (resDetails.id) {
                    try {
                        resDetails.vertexResource = await ResourceUtils.getResourceData(resDetails.id);
                    }
                    catch (e) {
                        console.log(`Failed to get res ${resDetails.id} data`, e);
                    }

                    if (resDetails.vertexResource) {
                        if(resDetails.vertexResource.name === "Default NavMesh"){
                            if(resDetails.vertexResource.id != Config.DEFAULT_NAVMESH_ID){
                                console.log(`Default navmesh is not using published id, but ${resDetails.vertexResource.id}`);
                            }

                            continue;
                        }

                        if(resDetails.vertexResource.name === "SpawnPoint"){
                            if(resDetails.vertexResource.id != Config.SPAWNPOINT_ID){
                                console.log(`SpawnPoint is not using published id, but ${resDetails.vertexResource.id}`);
                            }

                            continue;
                        }

                        resDetails.isPrivate = resDetails.vertexResource.publishParent == null;
                        resDetails.needUpdatePrivacy = resDetails.isPrivate !== isSpacePrivate;

                        if (!isSpacePrivate) {
                            let privateModifiedDate;
                            let publishedModifiedDate;

                            try {
                                if (resDetails.isPrivate) {
                                    if (resDetails.vertexResource.publishedResources[0]) {
                                        let publishedRes = await ResourceUtils.getResourceData(resDetails.vertexResource.publishedResources[0]);

                                        privateModifiedDate = new Date(resDetails.vertexResource.modified);
                                        publishedModifiedDate = new Date(publishedRes.modified);
                                    }
                                }
                                else {
                                    let privateRes = await ResourceUtils.getResourceData(resDetails.vertexResource.publishParent);

                                    privateModifiedDate = new Date(privateRes.modified);
                                    publishedModifiedDate = new Date(resDetails.vertexResource.modified);
                                }
                            }
                            catch (e) {
                                console.log(`Failed to retrieve resources json.`, e);
                            }

                            if (publishedModifiedDate && privateModifiedDate) {
                                resDetails.needRepublish = publishedModifiedDate < privateModifiedDate;
                            }
                        }

                        if (!isSpacePrivate && resDetails.isPrivate) {
                            if (!resDetails.vertexResource.publishedResources[0]) {
                                if (!alertMsgContent.includes(resDetails.vertexResource.name)) {
                                    alertMsgContent.push(resDetails.vertexResource.name);
                                }
                            }
                        }
                    }
                    else{
                        console.log(`Failed to retrieve resource info.`);
                    }
                }
                else {
                    console.log(`No valid resource ID found on the main component. ID: ${nodes[i].id}.`);
                }

                const modelAltComp = nodes[i].getComponent("ModelAlternative") as AugmentedStoreAssembly.ModelAlternativeComponent;

                if (modelAltComp) {
                    resDetails.modelAlternative = modelAltComp;
                    
                    for(let j = 0; j < resDetails.modelAlternative.modelList.length; j++){
                        const altId = resDetails.modelAlternative.modelList[j];

                        try {
                            resDetails.modelAlternativeResources[j] = await ResourceUtils.getResourceData(altId);
                        }
                        catch (e) {
                            console.log(`Failed to get alternative res ${altId} data`, e);
                        }

                        if (resDetails.modelAlternativeResources[j]) {
                            resDetails.modelAlternativePrivacies[j] = resDetails.modelAlternativeResources[j].publishParent == null;

                            //check if needed to toggle private/published id
                            if (resDetails.modelAlternativePrivacies[j] !== isSpacePrivate) {
                                resDetails.modelAlternativeIdIndexesToUpdatePrivacy.push(j);
                            }

                            //check if need republish
                            if(!isSpacePrivate){   
                                const needToRepublish = await this.checkRepublishNeeded(resDetails.modelAlternativeResources[j], resDetails.modelAlternativePrivacies[j]);
                                
                                if(needToRepublish){
                                    resDetails.modelAlternativeIdIndexesToRepublish.push(j);
                                }

                                //check if need to publish : if it is private and dont have a published id
                                if (!resDetails.modelAlternativeResources[j]?.publishedResources[0] && resDetails.modelAlternativePrivacies[j]) {
                                    if (!alertMsgContent.includes(resDetails.modelAlternativeResources[j].name)) {
                                        alertMsgContent.push(resDetails.modelAlternativeResources[j].name);
                                    }
                                }
                            }

                        }
                    }
                }
                else {
                    resDetails.modelAlternative = null;
                }

                resourcesDetail.push(resDetails);
            }
        }

        //we have all needed info now. 
        //If space is public we must inform the user about unpublished res to publish
        //and republish outdated published ones



        //If the space is private, we must switch to all private ids
        if (isSpacePrivate) {
            for (let i = 0; i < resourcesDetail.length; i++) {
                let needTrigger = false;

                if (!resourcesDetail[i].isPrivate && resourcesDetail[i].mainComponent && resourcesDetail[i].vertexResource.publishParent) {
                    if (resourcesDetail[i].mainComponent instanceof Vertex.NodeComponentModel.GltfModelComponent) {
                        (resourcesDetail[i].mainComponent as Vertex.NodeComponentModel.GltfModelComponent).id = resourcesDetail[i].vertexResource.publishParent;
                    }
                    else {
                        (resourcesDetail[i].mainComponent as AugmentedStoreAssembly.MediaTextureComponent).id = resourcesDetail[i].vertexResource.publishParent;
                    }

                    needTrigger = true;
                }

                if (resourcesDetail[i].modelAlternativeIdIndexesToUpdatePrivacy.length > 0) {
                    for (let j = 0; j < resourcesDetail[i].modelAlternativeIdIndexesToUpdatePrivacy.length; j++) {
                        let index = resourcesDetail[i].modelAlternativeIdIndexesToUpdatePrivacy[j];

                        if (resourcesDetail[i].modelAlternativeResources[index].publishParent) {
                            resourcesDetail[i].modelAlternative.modelList[index] = resourcesDetail[i].modelAlternativeResources[index].publishParent;
                        }
                    }

                    needTrigger = true;
                }

                if (needTrigger) {
                    resourcesDetail[i].mainComponent.triggerOnChanged();

                    let componentsToUpdate: IUpdtableAfterSaving[] = [];
                    let node = resourcesDetail[i].mainComponent.node;

                    node.components.forEach(componentName => {
                        let component = node.getComponent(componentName);
                        if (component && instanceOfIUpdtableAfterSaving(component)) {
                            componentsToUpdate.push(component);
                        }
                    })

                    componentsToUpdate.forEach(component => {
                        component.updateComponent();
                    });
                }
            }
        }
        else {
            //the space is Published or Public, force published IDs
            let swalOptions: SweetAlertOptions<any, any> = null;
            let needConfirmation = false;
            let userConfirmed = false;

            if (alertMsgContent.length > 0) {
                alertMsgContent.forEach(m => alertMsg += `<br>${m}`);

                needConfirmation = true;

                swalOptions = {
                    title: "Following resource needs to be published:<br>",
                    html: alertMsg,
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    confirmButtonText: "Publish",
                    showCancelButton: true,
                    cancelButtonText: "Cancel",
                    icon: "question",
                    heightAuto: false
                };
            }

            if (needConfirmation) {
                await Swal.fire(swalOptions).then((swalResult) => {
                    userConfirmed = swalResult.isConfirmed;
                    result = swalResult.isConfirmed ? SpaceOpResult.Success : SpaceOpResult.Canceled;
                });
            }


            if (needConfirmation && userConfirmed) {
                Swal.fire({
                    icon: "info",
                    title: `Publishing resources...`,
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    heightAuto: false
                });
    
                Swal.showLoading();
            }
            else if(needConfirmation && !userConfirmed){
                return SpaceOpResult.Canceled;
            }
        
            let failedPublishedResourceNames = "";

            for (let i = 0; i < resourcesDetail.length; i++) {
                let needTrigger = false;

                resourcesDetail[i].vertexResource = await ResourceUtils.getResourceData(resourcesDetail[i].vertexResource.id);

                if(resourcesDetail[i].needUpdatePrivacy){
                    if(resourcesDetail[i].vertexResource.publishedResources[0] == null){
                        let resp = await ResourceUtils.publishResource(resourcesDetail[i].id);

                        if (resp.ok) {
                            resourcesDetail[i].vertexResource = await ResourceUtils.getResourceData(resourcesDetail[i].id);
                        }
                        else {
                            failedPublishedResourceNames += `<br>${resourcesDetail[i].vertexResource.name}`;
                            console.log(`Failed to publish res ${resourcesDetail[i].vertexResource.name}.`);
                        }
                    }
                    else {
                        if (resourcesDetail[i].needRepublish) {
                            //if res is private
                            const response = await ResourceUtils.republishResource(resourcesDetail[i].vertexResource.id);

                            if (!response.ok) {
                                console.log(`Failed to republish res ${resourcesDetail[i].vertexResource.id}`);
                            }
                        }
                    }

                    if (resourcesDetail[i].mainComponent instanceof Vertex.NodeComponentModel.GltfModelComponent) {
                        (resourcesDetail[i].mainComponent as Vertex.NodeComponentModel.GltfModelComponent).id = resourcesDetail[i].vertexResource.publishedResources[0];
                    }
                    else {
                        (resourcesDetail[i].mainComponent as AugmentedStoreAssembly.MediaTextureComponent).id = resourcesDetail[i].vertexResource.publishedResources[0];
                    }

                    needTrigger = true;
                }

                if (resourcesDetail[i].modelAlternativeIdIndexesToUpdatePrivacy?.length) {
                    for (let j = 0; j < resourcesDetail[i].modelAlternativeIdIndexesToUpdatePrivacy.length; j++) {
                        let index = resourcesDetail[i].modelAlternativeIdIndexesToUpdatePrivacy[j];
                        let published = false;

                        resourcesDetail[i].modelAlternativeResources[index] = await ResourceUtils.getResourceData(resourcesDetail[i].modelAlternativeResources[index].id);

                        if(resourcesDetail[i].modelAlternativeResources[index].publishedResources[0] == null){
                            let resp = await ResourceUtils.publishResource(resourcesDetail[i].modelAlternativeResources[index].id);

                            if (resp.ok) {
                                resourcesDetail[i].modelAlternativeResources[index] = await ResourceUtils.getResourceData(resourcesDetail[i].modelAlternativeResources[index].id);
                                published = true;
                            }
                            else {
                                failedPublishedResourceNames += `<br>${resourcesDetail[i].modelAlternativeResources[index].name}`;
                                console.log(`Failed to publish res ${resourcesDetail[i].modelAlternativeResources[index].name}.`);
                            }
                        }
                        else if (resourcesDetail[i].modelAlternativeIdIndexesToRepublish.includes(index)) {
                            const response = await ResourceUtils.republishResource(resourcesDetail[i].modelAlternativeResources[index].id);

                            if (response.ok) {
                                published = true;
                            }
                            else {
                                console.log(`Failed to republish res ${resourcesDetail[i].modelAlternativeResources[index].id}`);
                            }
                        }
                        else {
                            published = true;
                        }

                        if (published) {
                            resourcesDetail[i].modelAlternative.modelList[index] = resourcesDetail[i].modelAlternativeResources[index].publishedResources[0];
                        }
                        else {
                            console.log(`Failed to update id for res ${resourcesDetail[i].modelAlternativeResources[index].id}`)
                        }
                    }

                    needTrigger = true;
                }

                if (needTrigger) {
                    resourcesDetail[i].mainComponent.triggerOnChanged();

                    let componentsToUpdate: IUpdtableAfterSaving[] = [];
                    let node = resourcesDetail[i].mainComponent.node;

                    node.components.forEach(componentName => {
                        let component = node.getComponent(componentName);
                        if (component && instanceOfIUpdtableAfterSaving(component)) {
                            componentsToUpdate.push(component);
                        }
                    })

                    componentsToUpdate.forEach(component => {
                        component.updateComponent();
                    });

                }
            }

            if(needConfirmation && userConfirmed){                
                Swal.hideLoading();

                if (failedPublishedResourceNames.length > 0) {
                    await Swal.fire({
                        title: "Failed to publish following resources:<br>",
                        html: failedPublishedResourceNames,
                        icon: "error",
                        heightAuto: false
                    });

                    result = SpaceOpResult.Failed;
                }
            }
        }

        return result;
    }


    async checkRepublishNeeded(res: VERTEXResource, isPrivate: boolean): Promise<boolean> {
        let privateModifiedDate;
        let publishedModifiedDate;

        try {
            if (isPrivate) {
                if (res.publishedResources[0]) {
                    let publishedRes = await ResourceUtils.getResourceData(res.publishedResources[0]);

                    privateModifiedDate = new Date(res.modified);
                    publishedModifiedDate = new Date(publishedRes.modified);
                }
            }
            else {
                let privateRes = await ResourceUtils.getResourceData(res.publishParent);

                privateModifiedDate = new Date(privateRes.modified);
                publishedModifiedDate = new Date(res.modified);
            }
        }
        catch (e) {
            console.log(`Failed to retrieve resources json.`, e);
            return false;
        }

        if (publishedModifiedDate && privateModifiedDate) {
            if (publishedModifiedDate < privateModifiedDate) {
                return true;
            }
        }

        return false;
    }

    async saveSpaceBinary(id: string): Promise<SpaceOpResult> {
        const result = await this.serializeAndPostSpaceBin(id) ? SpaceOpResult.Success : SpaceOpResult.Failed;

        await this.serializeSpaceJson();

        return result;
    }

    private async publishSpaceResource(id: string) {
        let result: boolean = true;
        let spaceResource = await ResourceUtils.getResourceData(id);

        if (spaceResource) {
            let response;

            if (spaceResource.publishedResources?.length) {
                response = await ResourceUtils.republishResource(id);
            }
            else {
                response = await ResourceUtils.publishResource(id);
            }

            if (response.ok) {
                console.log(`Space ${id} correctly published.`);
            }
            else {
                result = false;

                console.log(`Error happened while publishing Space ${id}`);
            }
        } else {
            result = false;

            console.log(`Failed to get ${id} resource info`);
        }

        return result;
    }

    async serializeSpaceJson() : Promise<void>{
        let nodesInScene: Map<string, Vertex.NodeComponentModel.VertexNode> = Vertex.Globals.runtime.space.nodes;
        let nodesToSave: Array<Vertex.NodeComponentModel.VertexNode> = [];

        
        for (const node of nodesInScene.values()) {
            const found = node.components.some(r => this.componetsToSave.includes(r));

            if (found) {
                nodesToSave.push(node);
            }
        }

        const res = await  ResourceUtils.getResourceData(Vertex.Globals.runtime.space.id);

        let space : Space = new Space();
        space.id = Vertex.Globals.runtime.space.id;
        space.name = res.name;
        space.nodes = [];
        

        for (let i = 0; i < nodesToSave.length; i++) {
            const node = nodesToSave[i];
            console.log("node:", node);

            if(node.components.includes("SceneProperties")){
                space.modelCount = (node.getComponent("SceneProperties") as AugmentedStoreAssembly.ScenePropertiesComponent).modelCount;
            }
            else if(node.components.includes("SpawnPoint")){
                const transform = node.getComponent("Transform") as Vertex.NodeComponentModel.TransformComponent;
                space.spawnPoint = {
                    position: transform.position,
                    rotation: transform.rotation,
                    scale : transform.scale
                };
            }
            else if(node.components.includes("CameraProperties")){
                const cameraProperties = node.getComponent("CameraProperties") as AugmentedStoreAssembly.CameraPropertiesComponent;
                space.cameraProperties = {
                    minZ : cameraProperties.minZ,
                    maxZ: cameraProperties.maxZ,
                    lowerRadiusLimit: cameraProperties.lowerRadiusLimit,
                    upperRadiusLimit: cameraProperties.upperRadiusLimit,
                    panningSensibility: cameraProperties.panningSensibility,
                    inertia: cameraProperties.inertia,
                    panningInertia: cameraProperties.panningInertia,
                    wheelPrecision: cameraProperties.wheelPrecision,
                    pinchPrecision: cameraProperties.pinchPrecision,
                    fov : cameraProperties.fov,
                    betaLimitMode : cameraProperties.betaLimitMode
                };
            }
            else if(node.components.includes("PostProcessProperties")) { 
                const postProcessProperties = node.getComponent("PostProcessProperties") as AugmentedStoreAssembly.PostProcessPropertiesComponent;
                space.postProcessProperties = {
                    fxaaEnabled : postProcessProperties.fxaaEnabled,
                    antiAliasingSamples : postProcessProperties.antiAliasingSamples,
                    bloomEnabled : postProcessProperties.bloomEnabled,
                    bloomWeight : postProcessProperties.bloomWeight,
                    bloomKernel : postProcessProperties.bloomKernel,
                    bloomScale  : postProcessProperties.bloomScale,
                    bloomThreshold : postProcessProperties.bloomThreshold,
                    toneMappingEnabled : postProcessProperties.toneMappingEnabled,
                    screenSpaceReflectionStrength : postProcessProperties.screenSpaceReflectionStrength,
                    screenSpaceReflectionSample : postProcessProperties.screenSpaceReflectionSample,
                    screenSpaceAmbientOcclusionEnabled : postProcessProperties.screenSpaceAmbientOcclusionEnabled,
                    screenSpaceAmbientOcclusionTotalStrength : postProcessProperties.screenSpaceAmbientOcclusionTotalStrength,
                    vignetteEnabled : postProcessProperties.vignetteEnabled,
                    vignetteWeight : postProcessProperties.vignetteWeight,
                    vignetteRounded : postProcessProperties.vignetteRounded,
                    vignetteColorR : postProcessProperties.vignetteColorR,
                    vignetteColorG : postProcessProperties.vignetteColorG,
                    vignetteColorB : postProcessProperties.vignetteColorB,
                    vignetteColorA : postProcessProperties.vignetteColorA,
                    contrast : postProcessProperties.contrast,
                    exposure : postProcessProperties.exposure
                }

            }
            else if (node.components.includes("Skybox")){
                const skybox = node.getComponent("Skybox") as AugmentedStoreAssembly.SkyboxComponent;
                space.skybox = {
                    activeSkyboxIndex: skybox.activeSkyboxIndex,
                    jsonName: `${skybox.name}_${node.id}_runtime.json`,

                    actionIndexes: skybox.actionIndexes,
                    actionValues: skybox.actionValues,
                    triggerActionIndexes: skybox.triggerActionIndexes,
                    triggerActionValues: skybox.triggerActionValues,
                };
            }
            else if (node.components.includes("IntroSplash")) {
                space.introSplashJson = `IntroSplash_${node.id}_runtime.json`;
            }
            else if (node.components.includes("LightMapsHandler")) {
                space.lightmapsJson = `LightMapsHandler_${node.id}_runtime.json`;
            }
            else if (node.components.includes("ActionSettings")) {
                space.actionSettingsJson = `ActionSettings_${node.id}_runtime.json`;
            }
            else if (node.components.includes("PointsOfInterest")) {
                space.pointsOfInterestJson = `PointsOfInterest_${node.id}_runtime.json`;
            }
            else if (node.components.includes("GltfLoadingHandler") || node.components.includes("MediaTexture") || node.components.includes("Text2D") || node.components.includes("Warp")) {

                let nodeJson: Node = new Node();
                nodeJson.id = node.id;
                nodeJson.name = node.name;
                nodeJson.components = cloneDeep(node.components).filter(c => c !== "GltfLoadingHandler").filter(c => c !== "NodeLockable");

                let originalTransformComponent = node.getComponent("Transform") as Vertex.NodeComponentModel.TransformComponent;
                nodeJson.Transform = {
                    position: originalTransformComponent.position,
                    rotation: originalTransformComponent.rotation,
                    scale: originalTransformComponent.scale
                };
                

                if(node.components.includes("ItemProperties")){
                    const originalItemProperties = node.getComponent("ItemProperties") as AugmentedStoreAssembly.ItemPropertiesComponent;
                    nodeJson.ItemProperties = {
                        isSelectable: originalItemProperties.isSelectable,
                        isMovable: originalItemProperties.isMovable,
                        isWishlistable: originalItemProperties.isWishlistable,
                        useInAR: originalItemProperties.useInAR,
                    }
                }

                if(node.components.includes("GltfModel")){
                    nodeJson.GltfModel = {
                        id: (node.getComponent("GltfModel") as Vertex.NodeComponentModel.GltfModelComponent).Id
                    }
                }

                if(node.components.includes("MediaTexture")){
                    const originalMediaTexture = node.getComponent("MediaTexture") as AugmentedStoreAssembly.MediaTextureComponent;
                    nodeJson.MediaTexture = {
                        id: originalMediaTexture.id,
                        isSlideshow: originalMediaTexture.isSlideshow,
                        index: originalMediaTexture.index,
                        jsonName: `${originalMediaTexture.name}_${node.id}_runtime.json`,
                        slideshowSeconds : originalMediaTexture.slideshowSeconds
                    }
                }

                if(node.components.includes("ModelAlternative")){
                    const originalModelAlternative = node.getComponent("ModelAlternative") as AugmentedStoreAssembly.ModelAlternativeComponent;
                    nodeJson.ModelAlternative = {
                        modelList: originalModelAlternative.modelList,
                        actionIndexes: originalModelAlternative.actionIndexes,
                        actionValues: originalModelAlternative.actionValues,
                        triggerActionIndexes: originalModelAlternative.triggerActionIndexes,
                        triggerActionValues: originalModelAlternative.triggerActionValues
                    }
                }

                if(node.components.includes("ChangeMaterial")){
                    const originalChangeMaterial = node.getComponent("ChangeMaterial") as AugmentedStoreAssembly.ChangeMaterialComponent;
                    nodeJson.ChangeMaterial = {
                        updatedMatIndexes: originalChangeMaterial.updatedMatIndexes,
                        updatedMeshIndexes: originalChangeMaterial.updatedMeshIndexes,
                        actionIndexes: originalChangeMaterial.actionIndexes,
                        actionValues: originalChangeMaterial.actionValues,
                        triggerActionIndexes: originalChangeMaterial.triggerActionIndexes,
                        triggerActionValues: originalChangeMaterial.triggerActionValues
                    }
                }

                if(node.components.includes("Animation")){
                    const originalAnimation = node.getComponent("Animation") as AugmentedStoreAssembly.AnimationComponent;
                    nodeJson.Animation = {
                        actionIndexes: originalAnimation.actionIndexes,
                        actionValues: originalAnimation.actionValues,
                        triggerActionIndexes: originalAnimation.triggerActionIndexes,
                        triggerActionValues: originalAnimation.triggerActionValues
                    }
                }

                if(node.components.includes("LightMap")){
                    nodeJson.LightMaps = {
                        lightMapsData: (node.getComponent("LightMap") as AugmentedStoreAssembly.LightMapsComponent).lightMapsData
                    }
                }

                if(node.components.includes("VideoTexture")){
                    const originalVideoTexture = node.getComponent("VideoTexture") as AugmentedStoreAssembly.VideoTextureComponent;
                    nodeJson.VideoTexture = {
                        index: originalVideoTexture.index,
                        jsonName: `${originalVideoTexture.name}_${node.id}_runtime.json`,
                    }
                }

                if(node.components.includes("Rotation")){
                    const originalRotation = node.getComponent("Rotation") as AugmentedStoreAssembly.RotationComponent;
                    nodeJson.Rotation = {
                        speed: originalRotation.speed,
                        isClockwise: originalRotation.isClockwise,
                        count : originalRotation.count
                    }
                }

                if(node.components.includes("Text2D")){
                    const originalText2D = node.getComponent("Text2D") as AugmentedStoreAssembly.Text2DComponent;
                    nodeJson.Text2D = {
                        text: originalText2D.text,
                        font: originalText2D.font,
                        size: originalText2D.size,
                        color: originalText2D.color,
                        bold: originalText2D.bold,
                        italic: originalText2D.italic,
                        underlined: originalText2D.underlined,
                        strikethrough: originalText2D.strikethrough,
                        textAlignment: originalText2D.textAlignment
                    }
                }


                if(node.components.includes("Warp")){
                    const originalWarp = node.getComponent("Warp") as AugmentedStoreAssembly.WarpComponent;
                    nodeJson.Warp = {
                        type: originalWarp.type,
                        data: originalWarp.data,
                        hide: originalWarp.hide,
                        privateHost: originalWarp.privateHost,
                        publicHost: originalWarp.publicHost,
                        hidePrivateHost: originalWarp.hidePrivateHost,
                        hidePublicHost: originalWarp.hidePublicHost
                    }
                }

                if (node.components.includes("CallToAction")) {
                    const originalCallToAction = node.getComponent("CallToAction") as AugmentedStoreAssembly.CallToActionComponent;

                    nodeJson.CallToAction = {
                        callToActionJson : `${originalCallToAction.name}_${node.id}_runtime.json`
                    };
                }

                space.nodes.push(nodeJson);
            }
        }

        const json = JSON.stringify(space);

        const response = await ResourceUtils.postAssetToResource("offline.json", space.id, json);

        if(!response.ok){
            console.log(`Failed to update offline.json`);
        }

        console.log("json: ", json);
    }

    public serializeSpaceBin(isPreview = false) : ArrayBuffer{
        let nodesInSpace: Map<string, Vertex.NodeComponentModel.VertexNode> = Vertex.Globals.runtime.space.nodes;
        let nodesToSave: Array<Vertex.NodeComponentModel.VertexNode> = [];

        //only choose nodes with gltf components to save
        for (const node of nodesInSpace.values()) {
            const found = node.components.some(r => this.componetsToSave.includes(r));

            if (found) {
                nodesToSave.push(node);
            }
        }

        //Create Buffer and writers, then write each node with whatever name matches the loader, in this case node_id
        let binWriter = new Vertex.BinaryWriter(4096 * 1024); //a node really shouln't be bigger than 4096... So a limit of around 1024 nodes?
        let bsonWriter = new Vertex.BSONWriter(binWriter);
        let context = bsonWriter.startObject();

        for (let i = 0; i < nodesToSave.length; i++) {
            //to serialize the node, must be BSONObject type, but should switch back after (BSONObject is not backwards compatible)
            const node = nodesToSave[i];
            console.log("node:", node);

            const oldType = node.componentDataType;

            node.componentDataType = Vertex.NodeComponentModel.ComponentDataType.BSONObject;

            if(node.components.includes("GltfLoadingHandler")) { //hack hack hack
                node.components = node.components.filter(c => c !== "GltfLoadingHandler");
                bsonWriter.writeObject(`node_${node.id}`, node.writeDataBSON.bind(node));
                node.components.push("GltfLoadingHandler");
            }else{
                if(isPreview && node.components.includes("Skybox")){
                    const skyboxNodeCopy = cloneDeep(node);
    
                    const skyboxComp = skyboxNodeCopy.getComponent("Skybox") as CustomSkyboxComponent;
                    skyboxComp.activeSkyboxIndex = skyboxComp.previewActiveSkyboxIndex;
                    bsonWriter.writeObject(`node_${node.id}`, node.writeDataBSON.bind(skyboxNodeCopy));
                }
                else{
                    bsonWriter.writeObject(`node_${node.id}`, node.writeDataBSON.bind(node));
                }
            }

            node.componentDataType = oldType;
        }

        bsonWriter.endObject(context);

        return binWriter.toBuffer();
    }
    

    private async serializeAndPostSpaceBin(id: string): Promise<boolean> {
        let spaceBuffer = this.serializeSpaceBin();
        let result: boolean = true;

        //push newly created scene.bin file
        try {
            // this is called but never finishes because something immediately reloads the page
            let response = await ResourceUtils.postAssetToResource(SERIALIZED_SPACE_FILENAME, id, spaceBuffer);

            if (response.ok) {
                console.log("Saved Space buffer to location: " + id);
            }
            else {
                result = false;

                console.log(`scene.bin could not be posted to ${id}.`, response.status);
            }
        }
        catch (e) {
            result = false;

            console.error("pranked", e);
        }

        return result;
    }

    async createThumbnail(id: string, camera: BABYLON.Camera) {
        if (camera) {
            BABYLON.Tools.CreateScreenshotUsingRenderTarget(Vertex.Globals.getRuntime<VertexBabylon.VertexBabylonRuntime>().engine, camera, { width: 256, height: 256 }, async (image) => {
                let imageData = Utils.dataURItoBlob(image);
                let result = await ResourceUtils.postAssetToResource(RESOURCE_THUMB_FILENAME, id, imageData);

                if (result.ok) {
                    console.log("Saved Space thumbnail to location: " + id);
                }
            });
        }
    }

    static async setupPersistanceSidebarButton(componentName: string, onClickFunction: () => any, section?: string) {

        //get sidebar
        const sidebar = document.getElementById("sidebar");

        // create sidebar button
        let sidebarSaveButton = document.createElement("div");
        sidebarSaveButton.id = `sidebar-${componentName}`;
        sidebarSaveButton.classList.add("sidebar-button");
        sidebarSaveButton.dataset.toggle = "tooltip";
        sidebarSaveButton.dataset.placement = "right";
        sidebarSaveButton.title = componentName;

        // add icon to sidebar button
        let sidebarIcon = document.createElement("img");
        sidebarIcon.classList.add("sidebar-icon");
        sidebarIcon.alt = `${componentName} icon`;
        sidebarIcon.src = `/img/${componentName}-icon.svg`;
        sidebarSaveButton.append(sidebarIcon);
        // call passed method on button click
        sidebarSaveButton.addEventListener("click", () => {
            onClickFunction();
        })

        // check if it should go in a specific section, if not put it directly in sidebar
        if (section) {
            let buttonSection = document.getElementById(`${section}-section`);
            buttonSection.append(sidebarSaveButton);
        }
        else {
            sidebar.append(sidebarSaveButton);
        }

        Utils.setDraggable("sidebar-icon", false);
    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
    }

    update(): void {
    }
}

export class PersistanceComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new PersistanceComponent();
    }

    constructor() {
        super("Persistance", new PersistanceComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}

import { Utils, IDetachableRemotionLogicComponent, instanceOfIDetachableRemotionLogicComponent } from '../../../utilities/utils';
import { AugmentedStoreAssembly } from '../../../../AugmentedStoreAssembly';
import { CustomMediaTextureComponent } from '../NodeComponents/mediaTexture';
import { CustomText2DComponent } from '../NodeComponents/text2dcomponent';
import { Config } from '../../../../config';
import { RESOURCE_API_URI, VERTEX_NODE_NO_PARENT_GUID } from '../../../utilities/constants';
import { CustomVolumeTriggerComponent } from '../NodeComponents/volumetriggercomponent';
import Swal from 'sweetalert2';

export class SceneHierarchyPanelComponent extends Vertex.NodeComponentModel.Component {
    writeData(writer: Vertex.BinaryWriter): void {
    }
    readData(reader: Vertex.BinaryReader): void {
    }
}

export class SceneHierarchyPanelComponentComponentView extends Vertex.NodeComponentModel.ComponentViewBase {
    constructor() {
        super();
    }

    panelBody: HTMLDivElement;
    panel: HTMLDivElement;
    gltfNameDictionary: Map<string,string>;

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        this.gltfNameDictionary = new Map();

        let runtime = Vertex.Globals.runtime;
        let space = runtime.space;

        this.panel = document.querySelector(".container-overlay-left");
        this.panel.classList.remove("hidden");

        
        let tree = document.createElement("ul") as HTMLUListElement;
        tree.id="myUL";

        // Create Hierarchy Panel

        let card = document.createElement("div");
        card.classList.add("card", "pointer-enable");
        card.id="scenes-panel";

        let header = document.createElement("div");
        header.classList.add("nav-link", "card-header-minimizable", "dark-text","max");

        let headerIcon = document.createElement("img");
        headerIcon.src = "/img/scene-icon-active.svg";
        headerIcon.classList.add("card-header-icon");
        
        let headerText = document.createElement("div");
        headerText.classList.add("card-header-text");
        headerText.innerText = "Space Hierarchy";

        let body = document.createElement("div");
        body.classList.add("card-body", "p-0");

        let panel = document.createElement("div");
        panel.classList.add("scene-hierarchy-panel", "h-100");

        // Grid Area

        let leftSidebar = document.querySelector(".left-sidebar-grid");

        // Append

        leftSidebar.appendChild(card);
        card.appendChild(header);
        header.appendChild(headerIcon);
        header.appendChild(headerText);
        card.appendChild(body);
        body.appendChild(panel);
        panel.appendChild(tree);

        // Sidebar

        Utils.setupSidebarButton("SceneHierarchyPanel", "scenes-panel");
        
        Vertex.Globals.event.on("space:nodeAdded", (node: Vertex.NodeComponentModel.VertexNode) => {
            this.listNode(node, tree);
        });
        
        Vertex.Globals.event.on("space:nodeDestroyed", (node: Vertex.NodeComponentModel.VertexNode) => {
            console.log("deleted node: ", node);
            let nodeListItem = document.querySelector(`[data-node-id="${node.id}"]`) as HTMLLIElement;
            
            if(nodeListItem){
                nodeListItem.remove();
            }
            else{
                console.log("couldn't find: ", node);
            }

        });

        Vertex.Globals.event.on("editor:selectNode", (node: Vertex.NodeComponentModel.VertexNode) => {
            // va aggiunto controllo gtlf IS READY

            for (const item of tree.querySelectorAll(".selected-list-item")) {
                item.classList.remove("selected-list-item");
                let lockIcon = item.querySelector(".lock-icon");
                if(lockIcon){
                    lockIcon.classList.remove(`bright-lock-icon`);
                }
            }

            for (const item of tree.querySelectorAll(".list-item")) {

                if (item.getAttribute("data-node-id") === node.id){// .textContent.includes(name)) {
                    item.classList.add("selected-list-item");
                    let lockIcon = item.querySelector(".lock-icon");
                    if(lockIcon){
                        lockIcon.classList.add(`bright-lock-icon`);
                    }
                }

                //break;
            }

        });

        Vertex.Globals.event.on("editor:clearSelection", (node: Vertex.NodeComponentModel.VertexNode) => {
            for (const item of tree.querySelectorAll(".selected-list-item")) {
                item.classList.remove("selected-list-item");
            }

        });

        //for each node in the scene
        for(let kvp of space.nodes.entries()){

            let guid = kvp[0];
            let node = kvp[1];

            this.listNode(node, tree);
        }

    }

    private listNode(node: Vertex.NodeComponentModel.VertexNode, tree: HTMLUListElement) {
        const self = this;
        let gltf = node.getComponent("GltfModel") as Vertex.NodeComponentModel.GltfModelComponent;
        let mediaTexture = node.getComponent("MediaTexture") as CustomMediaTextureComponent;
        let text2D = node.getComponent("Text2D") as CustomText2DComponent;
        let co2Visualizer = node.getComponent("SavedCO2Visualizer") as AugmentedStoreAssembly.SavedCO2VisualizerComponent;
        let isSpawnPoint = node.components.includes("SpawnPoint");
        let volumeTrigger = node.getComponent("VolumeTrigger") as CustomVolumeTriggerComponent;

        if (isSpawnPoint) {
            //create list item
            let nodeItem = document.createElement("li") as HTMLLIElement;
            let nodeText = document.createElement("div");
            //let nodeIcon = document.createElement("img");

            nodeItem.setAttribute("data-node-id", node.id);
            nodeText.innerHTML = node.name;
            this.gltfNameDictionary.set(node.id, node.name);

            //make selectable
            nodeItem.addEventListener("click", function (event) {
                var selectedRow = event.target as HTMLDivElement;
                var selectedId = selectedRow.getAttribute("data-node-id");
                for(let kvp of Vertex.Globals.runtime.space.nodes.entries()){
                    
                    let guid = kvp[0];
                    let node = kvp[1];

                    if(guid == selectedId){
                        Vertex.Globals.event.fire("editor:selectNode", node);
                    }
                }
            });

            nodeItem.classList.add("scene-list", "list-item");
            nodeText.classList.add("scene-list-text");

            nodeItem.appendChild(nodeText);
            tree.appendChild(nodeItem);
        }
        else if (gltf || mediaTexture || text2D || co2Visualizer || volumeTrigger) {
            //create list item
            let nodeItem = document.createElement("li") as HTMLLIElement;
            let nodeText = document.createElement("div");

            let lockButton = document.createElement("button");
            let lockIcon = document.createElement("img");
            lockButton.classList.add("hierarchy-item-button", "lock-icon-button");
            lockIcon.src ="/img/lock.svg";
            lockButton.appendChild(lockIcon);
            Utils.injectSvg(lockIcon);

            let nodeLockableComp = node.getComponent("NodeLockable") as AugmentedStoreAssembly.NodeLockableComponent;

            let deleteButton = document.createElement("button");
            deleteButton.classList.add("hierarchy-item-button", "list-item-bin-delete-button");

            let deleteIcon = document.createElement("img");
            deleteIcon.src = "/img/trash.svg";
            deleteButton.appendChild(deleteIcon);

            Utils.injectSvg(deleteIcon);
            
            nodeItem.classList.add("scene-list", "list-item");
            nodeText.classList.add("scene-list-text");

            deleteButton.addEventListener("click", function(event){
                
                Swal.fire({
                    icon: 'warning',
                    title: 'Are you sure you want to delete this node?',
                    text: 'Deleting a node is permanent and cannot be undone',
                    showCancelButton: true,
                    showConfirmButton: true,
                    heightAuto: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        if (!nodeLockableComp.isLocked){
                            const space = Vertex.Globals.runtime.space as Vertex.Space;
                            const childNodes = Array.from(space.nodes.values()).filter(n => n.parent === node.id);
        
                            if(childNodes?.length > 0){
                                childNodes.forEach(childNode => {
                                    self.destroyNode(childNode);
                                });
                            }
        
                            self.destroyNode(node);
                        }
                    }
                });

            });

            if (nodeLockableComp !== null ) {
                if(!nodeLockableComp.isLocked){
                    lockButton.classList.add("unlocked");
                    deleteButton.removeAttribute("disabled");
                }
                else{
                    deleteButton.setAttribute("disabled", "disabled");
                }

                nodeLockableComp.onChanged.on((comp: AugmentedStoreAssembly.NodeLockableComponent)=>{
                    if(!comp.isLocked){
                        lockButton.classList.add("unlocked");
                        deleteButton.removeAttribute("disabled");
                    }
                    else{
                        lockButton.classList.remove("unlocked");
                        deleteButton.setAttribute("disabled", "disabled");
                    }
                })

                lockButton.addEventListener("click", function(event){
                    Vertex.Globals.event.fire("hevolus:forceClearSelection");
                    nodeLockableComp.isLocked = !nodeLockableComp.isLocked;
                    nodeLockableComp.triggerOnChanged();
                });
            }

            nodeItem.setAttribute("data-node-id", node.id);

            if(gltf || mediaTexture){
                //set name
                const id = gltf ? gltf.id : mediaTexture.id;
                
                this.getResourceName(id).then((name) => {
                    nodeText.innerHTML = name;
                    this.gltfNameDictionary.set(node.id,name)
                });                
            }
            //if co2Visualizer the node will have both co2 visualizer component and Text2d component, so let s check co2 first to get the right name 
            //yes, it can be done better.
            else if(co2Visualizer || text2D || volumeTrigger){
                nodeText.innerHTML = node.name;
            }

            //make selectable
            nodeItem.addEventListener("click", function (event) {
                var selectedRow = event.target as HTMLDivElement;
                var selectedId = selectedRow.getAttribute("data-node-id");
                for(let kvp of Vertex.Globals.runtime.space.nodes.entries()){
                    
                    let guid = kvp[0];
                    let node = kvp[1];

                    if(guid == selectedId){
                        Vertex.Globals.event.fire("editor:selectNode", node);
                    }
                }
            });

            if(gltf)
            {
                let lastModelID = gltf.id;
                let gltfIsReady = gltf.IsReady;
                
                if(gltf.id === Config.DEFAULT_NAVMESH_ID){
                    lockButton.hidden = true;
                    deleteButton.hidden = true;
                }     
                
                if(!gltfIsReady){
                    lockButton.hidden = true;
                    deleteButton.hidden = true;
                }      
    
                gltf.Ready.on(() => {
                    lockButton.hidden = false;
                    deleteButton.hidden = false;

                    if(gltf.id === Config.DEFAULT_NAVMESH_ID){
                        lockButton.hidden = true;
                        deleteButton.hidden = true;
                    }
                });

                gltf.onChanged.on(() => {
                    if (lastModelID != gltf.id) {
                        lastModelID = gltf.id;

                        this.getResourceName(gltf.id).then((name) => {
                            nodeText.innerHTML = name;
                            this.gltfNameDictionary.set(node.id, name);
                        });

                        if (!gltf.isReady) {
                            lockButton.hidden = true;
                            deleteButton.hidden = true;
                        }
                    }
                });
            }
            
            nodeItem.appendChild(lockButton);
            nodeItem.appendChild(nodeText);
            nodeItem.appendChild(deleteButton);

            if(node.parent == VERTEX_NODE_NO_PARENT_GUID || !node.parent){
                tree.appendChild(nodeItem);
            }
            else{
                //we need to nest this item to the item related to its parent
                let parentItem = tree.querySelector(`[data-node-id="${node.parent}"]`) as HTMLLIElement;

                if(parentItem){
                    nodeItem.classList.add("nested-item");
                    parentItem.insertAdjacentElement("afterend", nodeItem);
                }
                else{
                    console.log("parent not found for node: ", node);
                }
            }
        }
    }

    destroyNode(node: Vertex.NodeComponentModel.VertexNode) {
        Vertex.Globals.event.fire("space:nodeDestroyed", (node));
        
        let removableComponents : IDetachableRemotionLogicComponent [] = [];

        node.components.forEach(componentName => {
            let component = node.getComponent(componentName);
            if(component && instanceOfIDetachableRemotionLogicComponent(component)){
                removableComponents.push(component);
                console.log(`The onRemoved lambda of ${componentName} componente has been canceled`);
            }
        });

        removableComponents.forEach(component => {
            component.detachRemotionLogic();
        });

        Vertex.Globals.runtime.space.destroyNode(node);
    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {

    }

    async getResourceName(id: string){
        let url = `${RESOURCE_API_URI}${id}`;

        const nameResult = await fetch(url, {
            headers: {
                "Authorization": "Bearer " + Vertex.Globals.bearerToken
            }
        });
        if (nameResult.ok){
            let json = await nameResult.json();
            return json.name;
        }else{
            console.log(nameResult.status + nameResult.statusText);
            console.log("did not get name for node: " + id);
        }
    }

    minimizePanel(target: HTMLElement) {

        let parent = target.closest(".card");
        let body = parent.querySelector(".card-body");
        let icon1 = target.querySelector(".mini-1");
        let icon3 = target.querySelector(".mini-3");



            if (target.classList.contains("max")) {
                target.classList.remove("max");
                target.classList.add("min");
                body.classList.remove("d-block");
                body.classList.add("d-none");
                icon1.classList.remove("squish-mini");
                parent.classList.remove("h-30vh");

                console.log(target.classList);
                console.log(body.classList);
            }

            else {
                target.classList.remove("min");
                target.classList.add("max");
                body.classList.remove("d-none");
                body.classList.add("d-block");
                icon1.classList.add("squish-mini");
                parent.classList.add("h-30vh");


                console.log(target.classList);
                console.log(body.classList);
            }
    }


}


export class SceneHierarchyPanelComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new SceneHierarchyPanelComponent();
    }
    constructor() {
        super("SceneHierarchyPanel", new SceneHierarchyPanelComponentComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}



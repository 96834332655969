import { UserProfileUtils } from "../../utilities/user-profile-utilities";
import { CODE, HEVLS_API_DOMAIN } from "./common";

export const QR_API_ENDPOINT = "api/qr";

export interface Link {
    linkId: number;
    vanityName: string;
    targetUrl: string;
    description: string;
    created: string;
    createdBy: string;
    lastModified?: string;
    lastModifiedBy?: string;
    validFrom?: string;
    validTo?: string;
    isActive: boolean;
    shortUrl: string;
    users?: User[];
}

export interface User{
    id: string;
    displayName: string;
    mail: string;
    darkTheme: boolean;
}

export class QrUrlShortenerApi {
    /**
     * Get short QR url for a resource
     * @param resourceId resource id
     * @param companyId vertx tenant id 
     * @param token user token
     * @returns Link object
     */
    static async getLink(resourceId?: string, companyId?: string, token?: string): Promise<Link>{
        resourceId = resourceId ?? Vertex.Globals.spaceId;
        token = token ?? Vertex.Globals.bearerToken;
        companyId = companyId ?? await UserProfileUtils.getTenantId(token);


        if(!companyId || !resourceId){
            return;
        }

        let uri = `${HEVLS_API_DOMAIN}/${QR_API_ENDPOINT}/${companyId}/${resourceId}`;

        uri += `?code=${CODE}`;

        try{
            const response = await fetch(uri, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            });

            if(response.ok){
                return await response.json() as Link;
            }
            else{
                console.log(`Failed to create short url for resource ${resourceId}`, response.status + response.statusText);
            }
        }
        catch(e){
            console.log(`Failed to create short url for resource ${resourceId}`, e);
        }
    }
}
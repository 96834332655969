import { AugmentedStoreAssembly } from '../../../../AugmentedStoreAssembly';
import { IAwaitableComponent } from './gltfLoadingHandlerComponent';

export class CustomAnimationComponent extends AugmentedStoreAssembly.AnimationComponent implements IAwaitableComponent {

    animationGroups : BABYLON.AnimationGroup[];

    constructor(){
        // Always run the super constructor when extending classes in TS!
        super();
        this.animationGroups = [];
    }

    isAwaitableComponent: boolean = true;

    funcOnLoad;

    async onLoad(): Promise<void> {
        await this.funcOnLoad();
    }

}


export class AnimationComponentView extends Vertex.NodeComponentModel.ComponentViewBase {

    constructor() {
        super();
    }

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {     

        var animComp = component as CustomAnimationComponent;
        var self = this;

        animComp.funcOnLoad = async (): Promise<void> => {
            await this.onLoad(node, animComp);
        }
    }


    onLoad(node: Vertex.NodeComponentModel.VertexNode, animComp: CustomAnimationComponent){
        let gltf = node.getComponent("GltfModel") as Vertex.NodeComponentModel.GltfModelComponent;
        
        this.initAnimArrays(gltf, animComp);
        //TODO:: Sycnhronise animation state when late to party.

        animComp.onChanged.on((_) => {
            for(var i = 0; i < animComp.shouldPlay.length; i++)
            {
                if(animComp.shouldPlay[i])
                {
                    if(animComp.animationGroups[i].isPlaying === false)
                    {
                        let startFrame = animComp.shouldPlayForward[i] ? 0 : animComp.animationGroups[i].to;
                        let endFrame = animComp.shouldPlayForward[i] ? animComp.animationGroups[i].to - 1 : 0;

                        animComp.animationGroups[i].reset();
                        //animComp.animationGroups[i].goToFrame(startFrame);

                        animComp.animationGroups[i].start(false, animComp.shouldPlayForward[i] ? 1 : -1, startFrame, endFrame);

                        let index = i;
                        var observer = animComp.animationGroups[index].onAnimationEndObservable.add(function (){
                            animComp.shouldPlay[index] = false;
                            //animComp.shouldPlayForward[index] = true;
                            animComp.triggerOnChanged();

                            animComp.animationGroups[index].onAnimationEndObservable.remove(observer);
                        });
                    }
                }
            }
        });
    }

    initAnimArrays(gltf:Vertex.NodeComponentModel.GltfModelComponent, animComp : CustomAnimationComponent){
        this.populateAnimations(gltf.visualNode, animComp);
        this.matchInitialState(animComp, gltf);
    }

    populateAnimations(node : BABYLON.AbstractMesh, animComp : CustomAnimationComponent){
        console.log(node.name + " ANIMATIONS:");
        animComp.animationGroups = [];

        let visualNodeChild = node.getChildren()[0]; 
        {
            if(visualNodeChild)
            {
                let animationGroups = visualNodeChild["vtx-ext-animationgroups"] as BABYLON.AnimationGroup[];
                
                if(animationGroups){
                    animationGroups.forEach(element => {
                        element.reset();
                        element.stop();
                        animComp.animationGroups.push(element);

                        //TODO:: Empty check
                        if(animComp.shouldPlayForward.length != animComp.animationGroups.length)
                        {
                            animComp.shouldPlayForward.push(false);
                            animComp.shouldPlay.push(false);
                        }
                    });
                }
            }
        };
    }

    matchInitialState(animComp : CustomAnimationComponent, gltf : Vertex.NodeComponentModel.GltfModelComponent){
        for(var i = 0; i < animComp.shouldPlay.length; i++)
        {
            if(animComp.animationGroups[i].isPlaying === false)
            {
                let startFrame = animComp.shouldPlayForward[i] ? animComp.animationGroups[i].to - 2 : 1;
                let endFrame = animComp.shouldPlayForward[i] ? animComp.animationGroups[i].to - 1 : 0;

                animComp.animationGroups[i].goToFrame(startFrame);
                animComp.animationGroups[i].start(false, animComp.shouldPlayForward[i] ? 1 : -1, startFrame, endFrame);
                //animComp.animationGroups[i].stop();

                //animComp.animationGroups[i].start(false, animComp.shouldPlayForward[i] ? 1 : -1, startFrame, endFrame);

                /*animComp.animationGroups[i].start(false, animComp.shouldPlayForward[i] ? 1 : -1, startFrame, endFrame);

                let index = i;
                var observer = animComp.animationGroups[index].onAnimationEndObservable.add(function (){
                    animComp.shouldPlay[index] = false;
                    //animComp.shouldPlayForward[index] = true;
                    animComp.triggerOnChanged();

                    animComp.animationGroups[index].onAnimationEndObservable.remove(observer);
                });*/
            }
        }
    }

    printAnimations(node : BABYLON.AbstractMesh){
        console.log(node.name + " ANIMATIONS:");

        node.getChildren().forEach((visualNodeChild) =>{
            if(visualNodeChild)
            {
                let animationGroups = visualNodeChild["vtx-ext-animationgroups"] as BABYLON.AnimationGroup[];
                if(animationGroups)
                {
                    animationGroups.forEach(element => {
                        //this.animationsPlaying.set(element.name, false);
                        console.log(element);
                    });
                }
            }
        });

       
    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {

    }

    update(): void {
    }
}

export class AnimationComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new CustomAnimationComponent();
    }

    constructor() {

        super("Animation", new AnimationComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());

        BABYLON.SceneLoader.OnPluginActivatedObservable.add(function (plugin) {

            if (plugin.name === "gltf" && plugin instanceof BABYLON.GLTFFileLoader) {
                plugin.animationStartMode = BABYLON.GLTFLoaderAnimationStartMode.NONE;
            }
        });

    }
}

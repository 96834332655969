import { AugmentedStoreAssembly } from "../../../AugmentedStoreAssembly";
import { DEFAULT_SKYBOX_FILENAME, DDS_TEXTURE_EXTENSION, HDR_TEXTURE_EXTENSION, SKYBOX_SIZE_RATIO } from "../../utilities/constants";
import { ResourceUtils } from "../../utilities/resource-utilities";
import { UploadUtils } from "../../utilities/upload-utilities";
import { Utils } from "../../utilities/utils";


export class CustomSkyboxComponent extends AugmentedStoreAssembly.SkyboxComponent {
        skyboxUris = [];
        skyboxRotations = [];
        skyboxIntensities = [];
        skyboxBlurs = [];
        haveFakeSkybox = [];
        diffuseRs = [];
        diffuseGs = [];
        diffuseBs = [];
}

export class SkyboxComponentView extends Vertex.NodeComponentModel.ComponentViewBase {
    constructor() {
        super();
    }

    currentSkybox: BABYLON.Mesh;
    previousUri: string = null;
    previousMaxZ: number = null;
    previousBlur: number = null;

    private static isSkyboxReady: boolean = false;

    static get IsSkyboxReady(){
        return this.isSkyboxReady;
    }

    get scene(): BABYLON.Scene {
        return Vertex.Globals.runtime.scene;
    }

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {

        let skyboxComp = component as CustomSkyboxComponent;

        skyboxComp.onChanged.on((_) => {
            this.applySkybox(skyboxComp);
        });

        Vertex.Globals.event.on("camera-properties:clipping-planes-changed", ([minZ, maxZ]: [number, number]) => {
            if(this.previousMaxZ !== maxZ){
                this.applySkybox(skyboxComp, true);

                this.previousMaxZ = maxZ;
            }
        });
    }

    public async applySkybox(skyboxComp: CustomSkyboxComponent, forceRecreate = false): Promise<void> {

        let hdrTexture = (this.currentSkybox?.material as BABYLON.PBRMaterial)?.reflectionTexture;
        
        const currentBlur = skyboxComp.skyboxBlurs[skyboxComp.activeSkyboxIndex];
        const currentUri = skyboxComp.skyboxUris[skyboxComp.activeSkyboxIndex];

        const blurUpdated = this.previousBlur !== skyboxComp.skyboxBlurs[skyboxComp.activeSkyboxIndex];
        const uriUpdated = currentUri !== this.previousUri;
        
        if(uriUpdated || blurUpdated){
            if(uriUpdated){
                SkyboxComponentView.isSkyboxReady = false;

                let url: string = `/img/${DEFAULT_SKYBOX_FILENAME}`;

                if(currentUri){
                    let res = await ResourceUtils.getResourceData(Vertex.Globals.spaceId);
    
                    if (!res || !res.resourceKeys)
                        return;
    
                    let idx = res.resourceKeys.indexOf(currentUri);
    
                    if (idx !== -1) {
                        url = `https://${Vertex.Globals.vertexStackUrl}/core/resource/${Vertex.Globals.spaceId}/${currentUri}`;   
                    }
                }
    
                //breaks if dds isnt valid
                const extension = Utils.getFileExtension(url);

                if (extension == DDS_TEXTURE_EXTENSION) {
                    hdrTexture = BABYLON.CubeTexture.CreateFromPrefilteredData(url, this.scene);
                } 
                else if (extension == HDR_TEXTURE_EXTENSION) {
                    //await this.loadHdrCubeTexture(url);
                    hdrTexture = new BABYLON.HDRCubeTexture(url, this.scene, 1024, false, true, false, true); //texture skybox
                }
    
                if (!hdrTexture) {
                    // todo: failed to create texture?
                    console.error(`[SkyboxComponent] Failed to load HDR Texture!`, { url });
                    return;
                }
    
                this.previousUri = currentUri;
            }

            // if no skybox exists yet, create a new one, otherwise update it
            if (blurUpdated || !this.currentSkybox || forceRecreate) {            
                let cam = Vertex.Globals.runtime.scene.activeCamera as BABYLON.ArcRotateCamera;
                let skyboxSize = cam.maxZ * SKYBOX_SIZE_RATIO;

                if (this.currentSkybox) {
                    this.currentSkybox.dispose();
                }

                this.currentSkybox = this.scene.createDefaultSkybox(hdrTexture, true, skyboxSize, currentBlur, true);

                this.previousBlur = currentBlur;
            }

            if(hdrTexture) {
                this.scene.environmentTexture = hdrTexture;
            }

            let material = this.currentSkybox.material as BABYLON.PBRMaterial;
        
            if (material instanceof BABYLON.PBRMaterial === false) {
                console.error(`[SkyboxComponent] skybox only supports pbr materials`, this);
                return;
            }
            
            material.backFaceCulling = false;

            // this texture must be cloned because it uses a different coordinates mode
            // to the environment texture.
            // todo: could maybe avoid cloning here.
            material.reflectionTexture.dispose();
            material.reflectionTexture = null;
            material.reflectionTexture = hdrTexture.clone();

            if (material.reflectionTexture) {
                material.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
            }

            material.microSurface = 1.0 - currentBlur;
            material.disableLighting = true;
            material.twoSidedLighting = true;
        }

        // update rotations of textures
        for (let tex of [this.scene.environmentTexture, (this.currentSkybox.material as BABYLON.PBRMaterial).reflectionTexture] as BABYLON.CubeTexture[]) {
            //tex.level = skyboxComp.intensity;
            tex.rotationY = skyboxComp.skyboxRotations[skyboxComp.activeSkyboxIndex] * Math.PI / 180;
        }        

        if (this.currentSkybox){
            this.currentSkybox.visibility = skyboxComp.haveFakeSkybox[skyboxComp.activeSkyboxIndex] ? 0 : 1;
        }

        Vertex.Globals.runtime.scene.environmentIntensity = skyboxComp.skyboxIntensities[skyboxComp.activeSkyboxIndex];
        Vertex.Globals.runtime.scene.clearColor = new BABYLON.Color4(
                skyboxComp.diffuseRs[skyboxComp.activeSkyboxIndex],
                skyboxComp.diffuseGs[skyboxComp.activeSkyboxIndex],
                skyboxComp.diffuseBs[skyboxComp.activeSkyboxIndex],
                1);
                
        Utils.waitForCondition(_ => hdrTexture.isReady()).then(_ => Utils.delay(150).then(_ => SkyboxComponentView.isSkyboxReady = true));
    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
    }
}

export class SkyboxComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new CustomSkyboxComponent;
    }

    constructor() {
        super("Skybox", new SkyboxComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}
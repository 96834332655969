import Swal from "sweetalert2";
import QRCode from "qrcode";
import { IQROptions } from "../components/catalog/qr/qr-interfaces";
import { getCurrentVersion, HevolusApp } from "../utilities/versioning";
import { clone, difference } from "lodash";
import { LicenseDictionary, LicenseUtils, LicenseValidity } from "../utilities/license-utilities";
import { HevolusResourceType, VERTEXResource, OrderMode, ResourceUtils, Result, DetailedResult } from "../utilities/resource-utilities";
import { UploadingAssetType, UploadUtils } from "../utilities/upload-utilities";
import { Utils, importTemplate, NotificationStatus } from "../utilities/utils";
import { AUGMENTEDSTORE_CONTENTCREATOR_LICENSE, AUGMENTEDSTORE_HOST_LICENSE, HOLOMUSEUM_CONTENTCREATOR_LICENSE, HOLOMUSEUM_HOST_LICENSE, REMOTESELLING_CONTENTCREATOR_LICENSE, REMOTESELLING_HOST_LICENSE, HOLOPROTOTYPE_CONTENTCREATOR_LICENSE, HOLOPROTOTYPE_HOST_LICENSE, HEVOCOLLABORATION_CONTENTCREATOR_LICENSE, HEVOCOLLABORATION_HOST_LICENSE, MODEL_VALID_EXTENSIONS, MEDIA_VALID_EXTENSIONS, BASE_VALID_EXTENSIONS, SYSTEM_RESOURCE_FILENAMES, APP_REMOTESELLING_TAG, APP_HOLOMUSEUM_TAG, APP_HOLOPROTOTYPE_TAG, CONTENT_TAG_TAG, TAGS_CATALOG_FILENAME, TYPE_BASE_TAG, TYPE_MODEL_TAG, TYPE_MEDIA_TAG, TYPE_SPACE_TAG, APP_AUGMENTEDSTORE_TAG, SPACE_EDITOR_VERSION_PREFIX_TAG, TYPE_DECK_TAG, APP_HEVOCOLLABORATION_TAG, GENERIC_THUMBNAIL_IMG_URI, SPACEPRIVACY_PUBLIC_TAG, BASE_THUMB_URI, DECK_THUMB_URI, MEDIA_THUMB_URI, MODEL_THUMB_URI, ROOM_THUMB_URI, SPACE_THUMB_URI, VERTXCLOUD_STANDARD_LICENSE, VERTXCLOUD_DEVELOPER_LICENSE, RESOURCE_THUMB_FILENAME, RESOURCE_THUMB_ALL_PLATFORM_FILENAMES, MAX_RESOURCE_NAME_LENGTH, H_SHARE_SETTINGS_FILENAME, GENERATED_ZIP_FILENAME } from "../utilities/constants";
import { SpaceCloneUtils } from "../utilities/space-clone-utilities";
import { StorageApi } from "../api/xr-copilot-api/storage";
import { LicensingApi } from "../api/hevo-hub-api/licensing";
import { H_SHARE_AI_SCOPE } from "../api/hevo-hub-api/common";
import { AssistantApi } from "../api/xr-copilot-api/assistant/assistant";

const welcomeContainer = document.getElementById("welcomeContainer");
const homeContextPanel = document.getElementById("homepage-context");
const explorerContainer = document.getElementById("explorer-container");
const ALL_RESOURCES_TAG_SECTION = "All Resources";
const REMOTESELLING_TAGS_AREA_TITLE = "Remote Selling tags";
const HOLOMUSEUM_TAGS_AREA_TITLE = "Holo Museum tags";
const HOLOPROTOTYPE_TAGS_AREA_TITLE = "Holo Prototype tags";
interface ICachedThumb {
    url: string;
}
export class ResourceExplorer {
    readonly PRELOAD_AMOUNT: number = 30;

    explorerContainer: HTMLDivElement;
    tagExplorerContainer: HTMLDivElement;
    editingId: string;

    typeFilter: HevolusResourceType = HevolusResourceType.All;
    dateOrdered: boolean = true;
    tagViewEnabled: boolean = false;

    //searchTime: number;
    searchValue: string = "";
    //isSearching = false;

    allResources: VERTEXResource[] = [];
    searchedResources: VERTEXResource[] = [];
    typeFilteredResources: VERTEXResource[] = [];
    filteredResources: VERTEXResource[] = [];
    aiModelIds: string[] = [];

    tagList: string[] = [];

    licenses: LicenseDictionary;
    aiLicense: boolean = false;

    loadedResourcesByTag: Map<string, number> = new Map<string, number>(); 
    thumbs: Map<string, ICachedThumb> = new Map<string, ICachedThumb>();

    async createExplorer() {
        this.licenses = await LicenseUtils.validateLicenses(
            AUGMENTEDSTORE_CONTENTCREATOR_LICENSE,
            AUGMENTEDSTORE_HOST_LICENSE,
            HOLOMUSEUM_CONTENTCREATOR_LICENSE,
            HOLOMUSEUM_HOST_LICENSE,
            REMOTESELLING_CONTENTCREATOR_LICENSE,
            REMOTESELLING_HOST_LICENSE,
            HOLOPROTOTYPE_CONTENTCREATOR_LICENSE,
            HOLOPROTOTYPE_HOST_LICENSE,
            HEVOCOLLABORATION_CONTENTCREATOR_LICENSE,
            HEVOCOLLABORATION_HOST_LICENSE,
            VERTXCLOUD_DEVELOPER_LICENSE);

        this.aiLicense = (await LicensingApi.checkLicense([H_SHARE_AI_SCOPE]))?.includes(H_SHARE_AI_SCOPE);

        //show search bar
        this.drawSearchBar();

        //hide welcome content
        welcomeContainer.classList.add("hidden");
        //show main panels
        homeContextPanel.classList.remove("d-none");

        this.drawExplorer();

        Vertex.Globals.event.on("searchResources", async ({ resourceId, tags }) => {
            const selectedCell = document.querySelector(`[data-resource-id="${resourceId}"]`) as HTMLDivElement;
            const tagString = tags.join(",");
            selectedCell.dataset["tags"] = tagString;
        });
    }

    async drawExplorer() {
        //unhide explorer contianer
        explorerContainer.classList.remove("d-none");

        // main explorer panel
        let explorerTemp = importTemplate("#temp-explorer-container");
        homeContextPanel.appendChild(explorerTemp);
        this.explorerContainer = explorerTemp.querySelector("#scene-explorer-container");

        // tag explorer panel
        let tagExplorerPanel = document.createElement("div");
        tagExplorerPanel.classList.add("tag-explorer-panel", "col-lg");
        tagExplorerPanel.id = "tag-explorer-panel";
        homeContextPanel.appendChild(tagExplorerPanel);
        this.tagExplorerContainer = tagExplorerPanel;

        this.allResources = await Utils.getFilteredResources(this.licenses, OrderMode.Date, false, HevolusResourceType.Model, HevolusResourceType.Base,
            HevolusResourceType.Space, HevolusResourceType.Deck, HevolusResourceType.Room, HevolusResourceType.Media);
        
        const aiActiveResources = await AssistantApi.getActiveResources() || [];
        this.aiModelIds = aiActiveResources.filter(r => r.hasAI).map(r => r.resourceId);

        this.searchedResources = clone(this.allResources);
        this.filteredResources = difference(this.searchedResources, this.typeFilteredResources);

        this.tagList = ResourceUtils.getTagList(this.allResources);
        this.drawTagSections();
    }

    private drawTagSections() {
        if (this.filteredResources.length === 0) {
            this.tagList = [];
            let tagSections = document.querySelectorAll(".tag-section");

            tagSections.forEach(section => {
                section.classList.add("d-none");
            });

            let explorerContainer = document.querySelector("#explorer-container") as HTMLDivElement;

            explorerContainer.innerText = "There are no available resources. Please create a resource to start using Hevolus Editor Portal";
        }

        //create "All Resources" html section
        this.drawTagSection(ALL_RESOURCES_TAG_SECTION);

        //Filter out system tags
        let filteredTagList = Utils.filterSystemTags(this.tagList);

        filteredTagList.forEach(tag => {
            this.drawTagSection(tag);
        });
    }

    private drawTagSection(tag: string) {
        let tagSection = importTemplate("#temp-tag-explorer-section");
        tagSection.dataset["sectionName"] = tag;
        let tagSectionContainer = tagSection.querySelector(".explorerContainer") as HTMLDivElement;

        tagSectionContainer.dataset["tagSection"] = tag;

        let tagSectionTitle = tagSection.querySelector(".tag-title");
        let tagTitleText = tagSection.querySelector(".tag-title-text") as HTMLTextAreaElement;

        tagTitleText.innerText = tag;

        tagSectionTitle.addEventListener("click", () => {
            tagSectionContainer.classList.toggle("d-none");
        });
        
        if(tagSection.dataset.sectionName != ALL_RESOURCES_TAG_SECTION){
            if (!this.tagViewEnabled) {
                tagSection.hidden = true;
            }
        }

        this.tagExplorerContainer.appendChild(tagSection);

        let observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.intersectionRatio <= 0 || tagSectionContainer.classList.contains("d-none")) {
                    return;
                }

                this.renderCellsByTag(tag);

                observer.unobserve(tagSection);
            });
        }, { root: this.tagExplorerContainer, threshold: [1] });

        observer.observe(tagSection);
    }

    private renderCellsByTag(tag: string) {
        let resources = clone(this.filteredResources);

        if(tag != ALL_RESOURCES_TAG_SECTION){
            resources = resources.filter(res => res.tags.includes(tag));
        }

        this.renderResourceCells(resources, tag, true);
    }

    async spawnNewResourcePanel() {
        const baseLabel = document.getElementById("base-label") as HTMLDivElement;
        const modelLabel = document.getElementById("model-label") as HTMLDivElement;
        const spaceLabel = document.getElementById("space-label") as HTMLDivElement;
        const deckLabel = document.getElementById("deck-label") as HTMLDivElement;
        const mediaLabel = document.getElementById("media-label") as HTMLDivElement;

        if (baseLabel == null || modelLabel == null || spaceLabel == null || deckLabel == null || mediaLabel == null) {
            console.log(`WARNING: one or more labels html elements are null/undefined.`);
        }

        if (this.licenses[HOLOMUSEUM_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid ||
            this.licenses[REMOTESELLING_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid ||
            this.licenses[AUGMENTEDSTORE_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid ||
            this.licenses[HEVOCOLLABORATION_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid ||
            this.licenses[HOLOPROTOTYPE_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid) {
            modelLabel.style.removeProperty("display");
        }

        //if the user is HP content creator, enabele base hero
        if (this.licenses[HOLOPROTOTYPE_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid) {
            baseLabel.style.removeProperty("display");
        }

        //if the user is an AS CC, enable space hero
        if (this.licenses[AUGMENTEDSTORE_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid) {
            spaceLabel.style.removeProperty("display");
        }

        //if the user isn't an HC CC, disable deck hero
        //Currently disabled to allow creation from hololens only
        if (this.licenses[HEVOCOLLABORATION_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid) {
            deckLabel.style.removeProperty("display");
        }

        //if the user is HM, RS, AS, HC content creator,show media hero
        if (this.licenses[HOLOMUSEUM_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid ||
            this.licenses[REMOTESELLING_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid ||
            this.licenses[AUGMENTEDSTORE_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid ||
            this.licenses[HEVOCOLLABORATION_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid) {
            mediaLabel.style.removeProperty("display");
        }

        let newResourceContainer = document.getElementById("new-resource-container");
        let newResourcePanelContainer = document.getElementById("new-resource-panel-container") as HTMLDivElement;
        let newResourcePanel = document.getElementById("new-resource-panel") as HTMLDivElement;

        explorerContainer.classList.add("opacity-2");
        newResourceContainer.classList.remove("hidden");

        newResourcePanel.classList.remove("panel-default");
        newResourcePanel.classList.add("panel-animate");

        setTimeout(function () {
            newResourcePanelContainer.classList.remove("panel-default");
            newResourcePanelContainer.classList.add("panel-animate");
        }, 1);

        //name and tag input
        let nameUI = <HTMLInputElement>document.getElementById("resource-name-input");
        nameUI.placeholder = " Resource Name";
        nameUI.onkeyup = (e) => nameUI.value = Utils.sanitizeString(nameUI.value);

        let tagUI = <HTMLInputElement>document.getElementById("resource-tag-input");
        tagUI.placeholder = " tag1,tag2,...";
        tagUI.onkeyup = (e) => tagUI.value = Utils.sanitizeString(tagUI.value);

        //Tag Catalogue buttons
        if (this.licenses[REMOTESELLING_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid) {
            this.createTagsArea(newResourcePanel, APP_REMOTESELLING_TAG, tagUI);
        }

        if (this.licenses[HOLOMUSEUM_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid) {
            this.createTagsArea(newResourcePanel, APP_HOLOMUSEUM_TAG, tagUI);
        }

        if (this.licenses[HOLOPROTOTYPE_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid) {
            this.createTagsArea(newResourcePanel, APP_HOLOPROTOTYPE_TAG, tagUI);
        }

        tagUI.removeEventListener("keyup", this.highlightSelectedTags);
        tagUI.addEventListener("keyup", this.highlightSelectedTags);
    }
    
    private highlightSelectedTags(event: Event){
        const newResourcePanel = document.getElementById("new-resource-panel") as HTMLDivElement;
        let tagButtons = [];
        
        const tagInput = (event.target as HTMLInputElement);
        tagInput.value = Utils.sanitizeString(tagInput.value.trim());

        const tagButtonAreaRemoteSelling = newResourcePanel.querySelector(`#${APP_REMOTESELLING_TAG}-tags-area`) as HTMLElement;
        tagButtons.push(...tagButtonAreaRemoteSelling.querySelectorAll("button"));

        const tagButtonAreaHoloMuseum = newResourcePanel.querySelector(`#${APP_HOLOMUSEUM_TAG}-tags-area`) as HTMLElement;
        tagButtons.push(...tagButtonAreaHoloMuseum.querySelectorAll("button"));
        
        const tagButtonAreaHoloPrototype = newResourcePanel.querySelector(`#${APP_HOLOPROTOTYPE_TAG}-tags-area`) as HTMLElement;
        tagButtons.push(...tagButtonAreaHoloPrototype.querySelectorAll("button"));

        let selectedTags: string[] = tagInput.value.split(",");
        selectedTags = selectedTags.map(item => item.trim());

        tagButtons.forEach((tagButton: HTMLButtonElement) => {
            if(selectedTags.includes(tagButton.innerText)){
                tagButton.classList.add("btn-primary");
                tagButton.classList.remove("btn-info");
            }
            else{
                tagButton.classList.remove("btn-primary");
                tagButton.classList.add("btn-info");
            }
        });
    }

    private selectResourceType(event: Event){
        let heroButtons = document.querySelectorAll(".hero-button");
        heroButtons.forEach(hero => { hero.classList.remove("active-select"); });
        heroButtons.forEach(hero => { hero.querySelector(".hero-tick").classList.add("d-none"); });

        let selectedRadio = event.target as HTMLElement;
        let selectedHero = selectedRadio.nextElementSibling as HTMLElement;
        selectedHero.classList.add("active-select");
        selectedHero.querySelector(".hero-tick").classList.remove("d-none");
    }

    private async createTagsArea(newResourcePanel: HTMLDivElement, appTag: string, tagUI: HTMLInputElement) {
        let tagButtonArea = newResourcePanel.querySelector(`#${appTag}-tags-area`) as HTMLElement;
        if (tagButtonArea !== null) {
            tagButtonArea.innerHTML = "";
            tagButtonArea.remove();
            tagButtonArea = null;
        }

        let tagAreaTitle = newResourcePanel.querySelector(`#${appTag}-tags-area-title`) as HTMLElement;
        if (tagAreaTitle !== null) {
            tagAreaTitle.innerHTML = "";
            tagAreaTitle.remove();
            tagAreaTitle = null;
        }

        let catalogResource = await ResourceUtils.getOrCreateCatalog(appTag, CONTENT_TAG_TAG);
        let catalogResponse = await ResourceUtils.getAssetFromResource(catalogResource.id, TAGS_CATALOG_FILENAME);
        let tags: string[];

        if (catalogResponse.ok) {
            tags = await catalogResponse.json() as string[];
        }

        tagAreaTitle = document.createElement("h6");
        tagAreaTitle.classList.add("tags-area-title");
        tagAreaTitle.id = `${appTag}-tags-area-title`;

        if (appTag === APP_REMOTESELLING_TAG) {
            tagAreaTitle.innerText = REMOTESELLING_TAGS_AREA_TITLE;
        } else if (appTag === APP_HOLOMUSEUM_TAG) {
            tagAreaTitle.innerText = HOLOMUSEUM_TAGS_AREA_TITLE;
        } else if (appTag === APP_HOLOPROTOTYPE_TAG) {
            tagAreaTitle.innerText = HOLOPROTOTYPE_TAGS_AREA_TITLE;
        }

        tagButtonArea = document.createElement("div") as HTMLDivElement;
        tagButtonArea.id = `${appTag}-tags-area`;
        tagButtonArea.classList.add("new-resource-tag-area");
        newResourcePanel.appendChild(tagAreaTitle);
        newResourcePanel.appendChild(tagButtonArea);

        if(tags.length > 0){
            for (let i = 0; i < tags.length; i++) {
                let tag = tags[i];
                let existingButton = document.getElementById(`${tag}-${appTag}-tag`);

                if (existingButton == null) {
                    let tagButton = document.createElement("button");

                    tagButton.id = `${tag}-${appTag}-tag`;
                    tagButton.classList.add("btn", "btn-sm", "btn-info", "mr-1");
                    tagButton.innerText = tag;
                    tagButtonArea.appendChild(tagButton);
                    tagButton.addEventListener("click", () => {
                        //add tag to string if it exists, remove if not
                        let currentTagInputArray = tagUI.value.split(',');
                        currentTagInputArray = currentTagInputArray.map(item => item.trim());

                        let sameValueButtons = [];
                        sameValueButtons.push(...newResourcePanel.querySelectorAll(`[id='${tag}-${APP_HOLOMUSEUM_TAG}-tag']`));
                        sameValueButtons.push(...newResourcePanel.querySelectorAll(`[id='${tag}-${APP_REMOTESELLING_TAG}-tag']`));
                        sameValueButtons.push(...newResourcePanel.querySelectorAll(`[id='${tag}-${APP_HOLOPROTOTYPE_TAG}-tag']`));

                        if (currentTagInputArray.length > 0 && currentTagInputArray[0] != "") {
                            let tagIndex = currentTagInputArray.indexOf(tag);

                            if (tagIndex >= 0) {
                                //exists in input already, remove and "deactivate" button
                                currentTagInputArray = currentTagInputArray.filter(t => t != tag);
                                tagUI.value = currentTagInputArray.join(`,`);

                                sameValueButtons.forEach(tagButton => {
                                    tagButton.classList.add("btn-info");
                                    tagButton.classList.remove("btn-primary");
                                });
                            } else {
                                //doesn't exist in input already, add and "activate" button
                                tagUI.value = tagUI.value + `,${tag}`;

                                sameValueButtons.forEach(tagButton => {
                                    tagButton.classList.remove("btn-info");
                                    tagButton.classList.add("btn-primary");
                                });
                            }
                        } else {
                            //input is blank, add tag and "activate" button
                            tagUI.value = tagUI.value + `${tag}`;

                            sameValueButtons.forEach(tagButton => {
                                tagButton.classList.remove("btn-info");
                                tagButton.classList.add("btn-primary");
                            });
                        }
                    });

                    let selectedTags: string[] = tagUI.value.split(",");
                    selectedTags = selectedTags.map(item => item.trim());
                    
                    if(selectedTags.includes(tag)){
                        tagButton.classList.add("btn-primary");
                        tagButton.classList.remove("btn-info");
                    }
                }
            }
        }
        else{
            let noTagsMsg = document.createElement("p") as HTMLElement;
            noTagsMsg.innerText = "No tags available";
            noTagsMsg.classList.add("no-tags-available");
            tagButtonArea.appendChild(noTagsMsg);
        }
    }

    // save + post
    async createResource(): Promise<void> {
        let tagUI = <HTMLInputElement>document.getElementById("resource-tag-input");
        let nameUI = <HTMLInputElement>document.getElementById("resource-name-input");
        let radios = document.getElementsByName("resource-selector");
        let hevoResType = HevolusResourceType.None;

        for (let i = 0, length = radios.length; i < length; i++) {
            if ((<HTMLInputElement>radios[i]).checked) {
                hevoResType = HevolusResourceType[(((<HTMLInputElement>radios[i]).value))];
            }
        }

        //sanitize name and tags
        nameUI.value = Utils.sanitizeString(nameUI.value.trim());
        tagUI.value = Utils.sanitizeString(tagUI.value.trim());

        if (!nameUI.value) {
            Swal.fire({
                icon: "warning",
                title: "Hey, resource name is required!",
                timer: 1500,
                showConfirmButton: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
                heightAuto: false
            });

            return;
        }


        Swal.fire({
            icon: "info",
            title: "Creating resource...",
            allowEscapeKey: false,
            allowOutsideClick: true,
            showConfirmButton: false,
            heightAuto: false
        });

        Swal.showLoading();

        //First check to see if a resource already has this name
        let resourcesList = await ResourceUtils.getAllTenantResourcesAsync();

        if (resourcesList && resourcesList.find(x => x["name"] === nameUI.value)) {
            Swal.fire("Cannot create a resource with a duplicate name!");
            return;
        }

        //setup tags (splitting the input into array, then trimming whitespace)
        let tagInput = tagUI.value;
        let splittedTags = tagInput.split(',');

        splittedTags = splittedTags.map(item => item.trim());

        //creating the resource
        if (nameUI.value && hevoResType && splittedTags) {

            //filter system tags first, if any (because they are added by the user)
            splittedTags = Utils.filterSystemTags(splittedTags);

            //now add system tags
            switch (hevoResType) {
                case HevolusResourceType.Base: {
                    splittedTags.push(TYPE_BASE_TAG);
                    break;
                }
                case HevolusResourceType.Model: {
                    splittedTags.push(TYPE_MODEL_TAG)
                    break;
                }
                case HevolusResourceType.Media: {
                    splittedTags.push(TYPE_MEDIA_TAG);
                    break;
                }
                case HevolusResourceType.Space: {
                    splittedTags.push(TYPE_SPACE_TAG, APP_AUGMENTEDSTORE_TAG);

                    const currentSpaceEditorVersion = await getCurrentVersion(HevolusApp.SpaceEditor);

                    if(currentSpaceEditorVersion){
                        splittedTags.push(`${SPACE_EDITOR_VERSION_PREFIX_TAG}${currentSpaceEditorVersion}`);
                    }
                    
                    break;
                }
                case HevolusResourceType.Deck: {
                    splittedTags.push(TYPE_DECK_TAG, APP_HEVOCOLLABORATION_TAG);
                }
            }

            let id = await ResourceUtils.createNewResource(nameUI.value, ResourceUtils.getVertexResourceType(hevoResType), splittedTags, false);

            let res = await ResourceUtils.getResourceData(id);

            if (res) {
                await ResourceUtils.openResource(res);
            }
            else {
                Utils.notify(`Failed to open the resource`, NotificationStatus.Error);
            }
        }
    }

    closeNewResourcePanel() {
        let newResourceContainer = document.getElementById("new-resource-container");
        let newResourcePanel = document.getElementById("new-resource-panel");

        newResourcePanel.classList.add("panel-default");
        newResourcePanel.classList.remove("panel-animate");

        setTimeout(function () {
            explorerContainer.classList.remove("opacity-2");
            newResourceContainer.classList.add("hidden");
        }, 10);
    }

    async spawnContextMenu(resourceContext: HTMLDivElement, id: string, x, y) {
        // get existing material picker window and remove if it exists
        if (document.querySelector("#resource-context") != null) {
            document.querySelector("#resource-context").remove();
        }

        resourceContext.id = "resource-context";

        document.body.insertBefore(resourceContext, document.body.firstChild);

        this.editingId = id;

        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const xPosRight = windowWidth - x;
        const yPosBottom = windowHeight - y;

        const panelXPos = x + resourceContext.offsetWidth;
        const panelYPos = y + resourceContext.offsetHeight;

        if (panelXPos >= windowWidth) {
            resourceContext.style.right = xPosRight + "px";
        } else {
            resourceContext.style.left = x + "px";
        }

        if (panelYPos >= windowHeight) {
            resourceContext.style.bottom = yPosBottom + "px";
        } else {
            resourceContext.style.top = y + "px";
        }

        window.addEventListener('click', (e) => this.handleWindowClick(e, resourceContext));
    }

    private handleWindowClick(e: MouseEvent, resourceContext: HTMLDivElement) {
        if (!resourceContext.contains((<HTMLElement>e.target))) {
            resourceContext.remove();
        }

        explorerContainer.classList.remove("disabled");
    }

    private handleDrageEnterEvent(dropzone: HTMLDivElement): (this: HTMLDivElement, ev: DragEvent) => any {
        return (event) => {
            event.preventDefault();
            dropzone.className = "dropzone-drag";
        };
    }

    private handleDragLeaveEvent(dropzone: HTMLDivElement): (this: HTMLDivElement, ev: DragEvent) => any {
        return (event) => {
            event.preventDefault();
            dropzone.className = "dropzone";
        };
    }

    handleFilesDropped = async (event: DragEvent) => {
        event.preventDefault();
        const droppedFiles: File[] = this.getFilesDropped(event) as File[];
        await UploadUtils.uploadFiles(droppedFiles, this.editingId, UploadingAssetType.Generic);
        window.location.reload();
    }

    getFilesDropped = (event: DragEvent) => {
        let files: File[] | DataTransferItem[] = [];

        if (event.dataTransfer) {
            const dataTransfer = event.dataTransfer;
            if (dataTransfer.files && dataTransfer.files.length > 0) {
                files = [...dataTransfer.files];
            } else if (dataTransfer.items && dataTransfer.items.length > 0) {
                files = [...dataTransfer.items];
            } else {
                throw new Error("No Data Transfer items or files found.");
            }
        }

        return files;
    }

    async drawResourceButton(resource: VERTEXResource, index: number, explorerContainer: HTMLDivElement, moreResIndex: number, tag: string) {
        let cellTemp = importTemplate("#temp-resource-cell");
        let cell = cellTemp.querySelector(".explorerCell") as HTMLDivElement;
        let hevoResourceType = ResourceUtils.getHevolusResourceType(resource);

        cell.dataset["type"] = hevoResourceType;

        let tagString = "";

        const img = document.createElement("img");
        img.classList.add("cell-img", "div-loading");
        img.src = GENERIC_THUMBNAIL_IMG_URI; //empty image to not show no-image error 
        
        cell.appendChild(img);

        resource.tags.forEach(tag => {
            tagString += `${tag},`;
        });

        cell.dataset["resourceId"] = resource.id;
        cell.dataset["modified"] = resource.modified;
        cell.dataset["tags"] = tagString;
        cell.dataset["name"] = resource.name;
        cell.dataset["published"] = (resource.publishedResources?.length > 0).toString();

        cell.style.order = index.toString();

        let publishTypeCellTab = cell.querySelector(".published-cell-tab") as HTMLDivElement;

        if (cell.dataset["published"] === "true" && this.licenses[AUGMENTEDSTORE_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid) {

            ResourceUtils.getResourceData(resource.publishedResources[0]).then(publishedRes => {
                if (publishedRes?.modified && resource.modified) {
                    let updated = false;

                    let publishedModifiedDate = new Date(publishedRes.modified);
                    let sourceModifiedDate = new Date(resource.modified);

                    if (publishedModifiedDate > sourceModifiedDate) {
                        updated = true;
                    }

                    cell.dataset.updated = updated.toString();
                }
                else {
                    console.log(`Failed to get published res info. Resource id: ${resource.id} - Published id: ${resource.publishedResources[0]}.`);
                }

                let publishedResourceIndicator = cell.querySelector(".published-resource-icon");

                publishTypeCellTab.classList.add("cell-tab-low", "hide-tab");
                publishTypeCellTab.innerText = "Published";
                publishTypeCellTab.classList.add("cell-tab-gray");

                if (cell.dataset.updated === "true") {
                    publishTypeCellTab.classList.add("cell-tab-green");
                    publishedResourceIndicator.classList.add("cell-tab-green");
                } else {
                    publishTypeCellTab.classList.add("cell-tab-red");
                    publishedResourceIndicator.classList.add("cell-tab-red", "slow-clap");
                }
            })
        }
        else {
            cell.dataset.published = "false";
            cell.dataset.updated = "false";
        }

        let title = cell.querySelector(".cell-info-wrapper");
        let titleText = cell.querySelector(".cell-text");
        titleText.innerHTML = resource.name;

        // resource-type cell-tab and border
        const resTypeCellTab = cell.querySelector(".cell-tab") as HTMLDivElement;

        let borderClass = `${hevoResourceType.toLowerCase()}-border`;

        title.classList.add(borderClass);
        resTypeCellTab.innerText = hevoResourceType;

        let contextMenu = this.createContextMenu(resource, cell, hevoResourceType);

        // context menu
        cell.addEventListener("contextmenu", (e) => {
            
            e.preventDefault();
            var x = e.pageX;
            var y = e.pageY;
            this.spawnContextMenu(contextMenu, resource.id, x, y);
        });

        //resource hover effects
        cell.addEventListener('mouseenter', () => {
            resTypeCellTab.classList.remove("hide-tab");
            resTypeCellTab.classList.add("show-tab");
            publishTypeCellTab.classList.remove("hide-tab");
            publishTypeCellTab.classList.add("show-tab");
            titleText.classList.remove("whitespace-nowrap");
            titleText.classList.add("whitespace-normal");
        });

        cell.addEventListener('mouseleave', () => {
            resTypeCellTab.classList.add("hide-tab");
            resTypeCellTab.classList.remove("show-tab");
            publishTypeCellTab.classList.add("hide-tab");
            publishTypeCellTab.classList.remove("show-tab");
            titleText.classList.remove("whitespace-normal");
            titleText.classList.add("whitespace-nowrap");
        });

        cell.addEventListener("click", async () => {
            await ResourceUtils.openResource(resource);

            this.explorerContainer.classList.add("hidden");
        })

        //Resource image
        let explorerPanel = document.getElementById("explorer-panel");
        let tagExplorerPanel = document.getElementById("tag-explorer-panel");
        tagExplorerPanel.style.cssText = "overflow: auto; height: 100%";

        explorerPanel.classList.add("d-none");
        tagExplorerPanel.classList.remove("d-none");

        const tagSectionContainers = explorerContainer.querySelectorAll<HTMLElement>(".tagExplorerContainer");
        const tagSectionContainer = Array.from(tagSectionContainers.values()).find(section => section.dataset.tagSection == tag)

        const appendedCell = tagSectionContainer.appendChild(cell);
        //console.log( " disegno l'" + index + " esimo elem, id: " + resource.id + " "  +Date.now());


        let addResourceLoadObservable = false;

        if(index == moreResIndex){
            addResourceLoadObservable = true;
            //console.log("aggiungo il lazy");
        }

        this.lazyLoaderObservable(tag, explorerContainer, appendedCell, addResourceLoadObservable);
    }

    private lazyLoaderObservable(tag: string, explorerContainer: HTMLDivElement, appendedCell: HTMLDivElement, addLoadResourceObservable: boolean) {
        let observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.intersectionRatio <= 0) {
                    return;
                }

                const resId = (entry.target as HTMLDivElement).dataset.resourceId;

                if (resId) {
                    this.loadResourceThumb(resId, (entry.target as HTMLDivElement));
                }

                if(addLoadResourceObservable){
                    this.renderResourceCells(clone(this.filteredResources), tag);
                }

                observer.unobserve(appendedCell);
            });
        }, { root: explorerContainer, threshold: [1] });

        observer.observe(appendedCell);
    }

    private createContextMenu(resource: VERTEXResource, cell: HTMLDivElement, hevoResourceType: HevolusResourceType) {
        let resourceContext = document.createElement("div");
        resourceContext.classList.add("right-click-menu");

        // open in new tab
        let newTabButton = document.createElement("button");
        newTabButton.classList.add("btn", "btn-secondary", "right-click-button");
        newTabButton.innerText = "Open in New Tab";
        newTabButton.addEventListener('click', async () => {
            await ResourceUtils.openResource(resource, true);
            resourceContext.remove();
        });

        resourceContext.appendChild(newTabButton);

        if(hevoResourceType === HevolusResourceType.Model) {
            let generateQRButton = document.createElement("button");
            generateQRButton.classList.add("btn", "btn-secondary", "right-click-button");
            generateQRButton.innerText = "Public QR Link";
            generateQRButton.addEventListener('click', async () => {
                resourceContext.remove();
                Swal.fire({
                    icon: "info",
                    title: "Generating QR Code...",
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    heightAuto: false
                });

                Swal.showLoading();

                const result = await ResourceUtils.createXRCopilotUrl(resource, cell.dataset.updated === 'true');

                if(result.result === Result.Success && result.value){
                    let publishedResourceIndicator = cell.querySelector(".published-resource-icon");
                    let publishTypeCellTab = cell.querySelector(".published-cell-tab") as HTMLDivElement;
    
                    if (this.licenses[AUGMENTEDSTORE_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid) {
                        publishTypeCellTab.classList.remove("d-none");
                    }

                    publishTypeCellTab.classList.remove("cell-tab-red");
                    publishTypeCellTab.classList.add("cell-tab-low", "hide-tab");
                    publishTypeCellTab.innerText = "Published";
                    publishTypeCellTab.classList.add("cell-tab-gray");
                    publishTypeCellTab.classList.add("cell-tab-green");
                    
                    publishedResourceIndicator.classList.remove("cell-tab-red");
                    publishedResourceIndicator.classList.remove("slow-clap");
                    publishedResourceIndicator.classList.add("cell-tab-green");
                    
                    this.updateContextMenuItems(true, resourceContext, deleteButton, publishButton, republishButton, unpublishButton);

                    let canvasQR;

                    const options: IQROptions = {
                        errorCorrectionLevel: 'L',
                        type: 'image/png',
                        width: 300,
                        height: 300,
                        margin: 0,
                        color: {
                            light: '#fff',
                            dark: '#000'
                        }
                    };

                    Swal.fire({
                        title: `QR Code Generated for</br>'${resource.name}'`,
                        html: `<p>Scanning this QR code grants H-Share Smart access</p></br><canvas id="generatedQR"></canvas><br><br><p id="h-share-link" class="h-share-qr-popup-link">${result.value}</p>`,
                        showCancelButton: true,
                        cancelButtonText: "Close",
                        confirmButtonText: "Download QR",
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        heightAuto: false,
                        didOpen: () => {
                            const textArea = Swal.getHtmlContainer().querySelector("#h-share-link") as HTMLParagraphElement;
                            const text = textArea.innerText;

                            textArea.onclick = () => {
                                // Copy the text inside the text field
                                navigator.clipboard.writeText(text);

                                textArea.innerText = "Copied to clipboard!";

                                Utils.delay(2000).then(() => {
                                    textArea.innerText = text;  
                                });
                            }
                        }
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            var link = document.createElement('a');
                            link.download = `QR-${resource.name}.png`;
                            link.href = canvasQR.toDataURL();
                            link.click();
                        }
                    });
    
                    canvasQR = document.getElementById("generatedQR") as HTMLCanvasElement;
    
                    QRCode.toCanvas(canvasQR, result.value, options, (error) => {
                        if (error) {
                            console.error(error);
                            return;
                        }
                    });
    
                    canvasQR.style.width = canvasQR.style.height = '20vh';
                }
                else if(result.result === Result.Failed){
                    Swal.fire({
                        icon: "warning",
                        title: "QR Code generation failed",
                        text: `${result.message}`,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showConfirmButton: true,
                        heightAuto: false
                    });
                }
                else if(result.result === Result.Canceled){
                    Swal.fire({
                        icon: "info",
                        title: "QR Code generation canceled",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showConfirmButton: true,
                        heightAuto: false
                    });
                }
            });

            resourceContext.appendChild(generateQRButton);
        }

        // republish button
        let republishButton = document.createElement("button");
        republishButton.classList.add("btn", "btn-secondary", "right-click-button");
        republishButton.innerText = "RePublish";
        republishButton.addEventListener('click', async () => {
            Swal.fire({
                icon: "info",
                title: "Republishing resource...",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                heightAuto: false
            });

            Swal.showLoading();

            const result = await ResourceUtils.republishResource(resource.id);

            if (result) {
                let publishTypeCellTab = cell.querySelector(".published-cell-tab") as HTMLDivElement;
                publishTypeCellTab.classList.remove("cell-tab-red");
                publishTypeCellTab.classList.add("cell-tab-green");

                const redCircle = cell.querySelector(".cell-tab-red");
                if (redCircle !== null) {
                    redCircle.classList.remove("cell-tab-red");
                    redCircle.classList.remove("slow-clap");
                    redCircle.classList.add("cell-tab-green");
                }

                resourceContext.remove();

                await Swal.fire({
                    icon: "success",
                    title: "Republishing success!",
                    timer: 1500,
                    showConfirmButton: false,
                    heightAuto: false
                });
            } else {
                await Swal.fire({
                    icon: "error",
                    title: "Republishing failed.",
                    timer: 1500,
                    showConfirmButton: false,
                    heightAuto: false
                });
            }
        });

        // publish button
        let publishButton = document.createElement("button");
        publishButton.classList.add("btn", "btn-secondary", "right-click-button");
        publishButton.innerText = "Publish";
        publishButton.addEventListener('click', async () => {
            Swal.fire({
                icon: "info",
                title: "Publishing resource...",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                heightAuto: false
            });

            Swal.showLoading();

            const result = await ResourceUtils.publishResource(resource.id);

            if (result.ok) {
                let publishedResourceIndicator = cell.querySelector(".published-resource-icon");
                let publishTypeCellTab = cell.querySelector(".published-cell-tab") as HTMLDivElement;

                if (this.licenses[AUGMENTEDSTORE_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid) {
                    publishTypeCellTab.classList.remove("d-none");
                    publishTypeCellTab.classList.add("cell-tab-low", "hide-tab");
                    publishTypeCellTab.innerText = "Published";
                    publishTypeCellTab.classList.add("cell-tab-gray");
                }

                publishTypeCellTab.classList.add("cell-tab-green");
                publishedResourceIndicator.classList.add("cell-tab-green");

                this.updateContextMenuItems(true, resourceContext, deleteButton, publishButton, republishButton, unpublishButton);

                resourceContext.remove();

                await Swal.fire({
                    icon: "success",
                    title: "Publishing success!",
                    timer: 1500,
                    showConfirmButton: false,
                    heightAuto: false
                });
            } else {
                await Swal.fire({
                    icon: "error",
                    title: "Publishing error!",
                    timer: 1500,
                    showConfirmButton: false,
                    heightAuto: false
                });
            }

            Swal.hideLoading();
        });

        //unpublish button
        let unpublishButton = document.createElement("button");
        unpublishButton.classList.add("btn", "btn-secondary", "right-click-button");
        unpublishButton.innerText = "UnPublish";
        unpublishButton.addEventListener('click', async () => {
            Swal.fire({
                icon: "info",
                title: "Unpublishing resource...",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                heightAuto: false
            });

            Swal.showLoading();

            const result = await ResourceUtils.unpublishResource(resource.id);

            if (result) {
                let publishTypeCellTab = cell.querySelector(".published-cell-tab") as HTMLDivElement;
                publishTypeCellTab.classList.add("d-none");
                const greenCircle = cell.querySelector(".cell-tab-green");
                const redCircle = cell.querySelector(".cell-tab-red");

                if (greenCircle !== null) {
                    greenCircle.classList.remove("cell-tab-green");
                }

                if (redCircle !== null) {
                    redCircle.classList.remove("cell-tab-red");
                }

                this.updateContextMenuItems(false, resourceContext, deleteButton, publishButton, republishButton, unpublishButton);

                resourceContext.remove();

                await Swal.fire({
                    icon: "success",
                    title: "Unpublishing success!",
                    timer: 1500,
                    showConfirmButton: false,
                    heightAuto: false
                });
            } else {
                await Swal.fire({
                    icon: "error",
                    title: "Unpublishing error!",
                    timer: 1500,
                    showConfirmButton: false,
                    heightAuto: false
                });
            }
        });

        if (hevoResourceType === HevolusResourceType.Model || hevoResourceType === HevolusResourceType.Media &&
            this.licenses[AUGMENTEDSTORE_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid) {
            if (resource.publishedResources && resource.publishedResources.length > 0) {
                resourceContext.appendChild(republishButton);
                resourceContext.appendChild(unpublishButton);
            } else {
                resourceContext.appendChild(publishButton);
            }
        }

        //Sava a Copy button
        if(hevoResourceType === HevolusResourceType.Media || hevoResourceType === HevolusResourceType.Model){
            let saveCopyButton = document.createElement("button");
            saveCopyButton.classList.add("btn", "btn-secondary", "right-click-button");
            saveCopyButton.innerText = "Save a Copy";
            
            saveCopyButton.addEventListener('click', async () => {
                await Swal.fire({
                    title: 'Please provide a name for the new copy of the Resource',
                    input: 'text',
                    inputAutoTrim: true,
                    inputLabel: `Resource Name`,
                    inputAttributes: {
                        autocomplete: "off",
                        maxLength: `${MAX_RESOURCE_NAME_LENGTH}`
                    },
                    inputValidator: async (value) => {
                        Swal.getInput().value = value = Utils.sanitizeString(value.trim());

                        if (!value) {
                            return `Please provide a name for the new copy of the Resource`;
                        }

                        Swal.showLoading();

                        const resourcesList = await ResourceUtils.getAllTenantResourcesAsync();

                        if (resourcesList && resourcesList.find(x => x["name"].toLowerCase() === value.toLowerCase())) {
                            Swal.hideLoading();

                            return "A Resource with this name already exists";
                        }
                    },
                    confirmButtonText: 'Create',
                    showCancelButton: true,
                    heightAuto: false
                }).then(async (result) => {
                    if(result.isConfirmed && result.value){
                        Swal.fire({
                            icon: 'info',
                            title: `Copying <br>${resource.name}`,
                            showCancelButton: false,
                            showCloseButton: false,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                            showConfirmButton: false,
                            heightAuto: false,
                            width: "auto",
                        });
                        
                        Swal.showLoading();

                        const newName = result.value;
                        const newResourceId = await ResourceUtils.createNewResource(newName, ResourceUtils.getVertexResourceType(hevoResourceType), resource.tags, false);

                        if(newResourceId){
                            resource = await ResourceUtils.getResourceData(resource.id);

                            //TODO: implement excluded assets in a more robust way
                            const filesToCopy = clone(resource.resourceKeys);
                            const filesToExclude = [H_SHARE_SETTINGS_FILENAME, GENERATED_ZIP_FILENAME];

                            filesToExclude.forEach(file => {
                                const index = filesToCopy.indexOf(file);
                                if(index > -1){
                                    filesToCopy.splice(index, 1);
                                }
                            });

                            const copied = await ResourceUtils.copyAssetsToResource(resource.id, newResourceId, filesToCopy);

                            if(copied){
                                Swal.fire({
                                    icon: "success",
                                    title: `Resource<br>${newName}<br>created successfully`,
                                    confirmButtonText: "OK",
                                    showCancelButton: false,
                                    footer: `The new Resource has been created and the assets have been copied successfully.`,
                                    heightAuto: false
                                }).then(result => {
                                    if(result.isConfirmed){
                                        window.location.reload();
                                    }
                                });
                            }
                            else{
                                const deleted = await ResourceUtils.deleteResource(newResourceId, true);

                                if(!deleted){
                                    console.log(`Failed to delete the resource ${newResourceId}`);
                                }

                                await Swal.fire({
                                    icon: "error",
                                    title: `Failed to copy<br>${resource.name}`,
                                    confirmButtonText: "OK",
                                    showCancelButton: false,
                                    footer: `There was an issue while copying the assets. Please try again later.`,
                                    heightAuto: false
                                });
                            }
                        }
                        else{
                            await Swal.fire({
                                icon: "error",
                                title: `Failed to create<br>${newName}`,
                                confirmButtonText: "OK",
                                showCancelButton: false,
                                footer: `There was an issue while creating the Resource. Please try again later.`,
                                heightAuto: false
                            });
                        }
                    }
                });
            });

            resourceContext.appendChild(saveCopyButton);
        }

        //download resource zip button
        if(hevoResourceType === HevolusResourceType.Media || hevoResourceType === HevolusResourceType.Model){
            let downloadButton = document.createElement("button");
            downloadButton.classList.add("btn", "btn-secondary", "right-click-button");
            downloadButton.innerText = "Download Resource";
            
            downloadButton.addEventListener('click', async () => {
                Swal.fire({
                    icon: "info",
                    title: "Preparing data for download...",
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    heightAuto: false
                });
    
                Swal.showLoading();
    
                const link = await ResourceUtils.downloadResourceZip(resource.id);
    
                if(link){
                    await Swal.fire({
                        icon: "success",
                        title: `Download ready`,
                        html: `${resource.name}.zip`,
                        confirmButtonText: "Download",
                        showCancelButton: true,
                        cancelButtonText: "Cancel",
                        heightAuto: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            link.click();
                            link.remove();
                        }
                    });
                }
                else{
                    await Swal.fire({
                        icon: "error",
                        title: "Failed to download the Resource",
                        confirmButtonText: "OK",
                        showCancelButton: false,
                        heightAuto: false
                    });
                }
            });
    
            resourceContext.appendChild(downloadButton);
        }

        //delete button
        let deleteButton = document.createElement("button");
        deleteButton.classList.add("btn", "btn-danger", "right-click-button");
        deleteButton.innerText = "Delete Resource";
        deleteButton.addEventListener('click', async () => {
            await Swal.fire({
                title: `Are you sure you want to delete: `,
                html: `${resource.name}`,
                footer: `Deleting a resource is permanent and cannot be undone.`,
                icon: `warning`,
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonColor: `red`,
                confirmButtonText: `Delete`,
                heightAuto: false
            }).then(async (result) => {
                let deleted = false;

                if (result.isConfirmed) {
                    Swal.fire({
                        icon: "info",
                        html: `<b>Deleting</b> "${resource.name}" ...`,
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        showConfirmButton: false,
                        heightAuto: false
                    });

                    Swal.showLoading();

                    if(hevoResourceType === HevolusResourceType.Model){
                        //Let's delete the AI related resource and all of its content
                        StorageApi.deleteAll(resource.id);
                    }

                    let canDelete = true;

                    if(resource.tags.includes(SPACEPRIVACY_PUBLIC_TAG)){
                        canDelete = !await ResourceUtils.isPublicLink(resource.publishedResources[0]); 
                    }

                    if(canDelete){
                        deleted = await ResourceUtils.deleteResource(resource.id, true);

                        if (deleted) {
                            // bit hacky should really refresh
                            cell.remove();
                            this.removeResourceFromLists(resource.id);
                            this.updateSectionCounter();
                        }
                        
                        resourceContext.remove();
                        explorerContainer.classList.remove("disabled");
                    }
                    else{
                        await Swal.fire({
                            html: `<b>You can't delete</b> "${resource.name}" <b>because it is currently used as Public Link</b>`,
                            icon: "warning",
                            showCancelButton: false, 
                            showConfirmButton: true,
                            footer: `Contact your Administrator`,
                            heightAuto: false
                        });
                    }

                    await Swal.fire({
                        html: `${resource.name} <b>${deleted ? `` : `not`} deleted</b>`,
                        icon: `success`,
                        confirmButtonText: `OK`,
                        showConfirmButton: true,
                        heightAuto: false
                    });
                }
            });                
        });

        resourceContext.appendChild(deleteButton);

        //clone in tenant button
        if( hevoResourceType === HevolusResourceType.Media || hevoResourceType === HevolusResourceType.Model ||
            hevoResourceType === HevolusResourceType.Space || hevoResourceType === HevolusResourceType.Base){
            if(this.licenses[VERTXCLOUD_DEVELOPER_LICENSE] === LicenseValidity.Valid){
                //Clone in Tenant
                let cloneInTenantButton = document.createElement("button");
                cloneInTenantButton.classList.add("btn", "btn-secondary", "right-click-button");
                cloneInTenantButton.innerText = "Clone in Tenant ...";
                cloneInTenantButton.addEventListener('click', async () => {
                    await Swal.fire({
                        title: 'Please provide the Token for the destination Tenant',
                        input: 'text',
                        inputAutoTrim: true,
                        inputLabel: `Destination Tenant's Token`,
                        inputAttributes: {
                            autocomplete: "off"
                        },
                        inputValidator: (value) => {
                            if (!value) {
                                return `Please provide the token for the destination Tenant`;
                            }
                        },
                        confirmButtonText: 'Create',
                        showCancelButton: true,
                        heightAuto: false
                    }).then(async (result) => {
                        if(result.isConfirmed && result.value){
                            Swal.fire({
                                icon: 'info',
                                title: `Cloning<br>${resource.name}`,
                                showCancelButton: false,
                                showCloseButton: false,
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                allowEnterKey: false,
                                showConfirmButton: false,
                                heightAuto: false,
                                width: "auto",
                            });
                            
                            Swal.showLoading();

                            const destToken = result.value;
                            const isValidUser = await LicenseUtils.validateLicense(VERTXCLOUD_STANDARD_LICENSE, destToken);

                            if(isValidUser){
                                let cloneResult: DetailedResult = null;

                                if(hevoResourceType === HevolusResourceType.Model || hevoResourceType === HevolusResourceType.Media){
                                    cloneResult = await ResourceUtils.cloneResource(resource.id, destToken);
                                }
                                else if(hevoResourceType === HevolusResourceType.Space){
                                    cloneResult = await SpaceCloneUtils.cloneSpaceResource(resource.id, destToken);
                                }
                                else if(hevoResourceType === HevolusResourceType.Base){
                                    cloneResult = await ResourceUtils.cloneBaseResource(resource.id, destToken);
                                }

                                if(cloneResult.result === Result.Success){
                                    Swal.fire({
                                        title: `The resource<br>${resource.name}<br>has been cloned succesfully`,
                                        icon: 'success',
                                        html: cloneResult.message ?? "",
                                        showCancelButton: false,
                                        showCloseButton: false,
                                        allowOutsideClick: false,
                                        showConfirmButton: true,
                                        heightAuto: false,
                                        width: "auto",
                                    });                                    
                                }
                                else if(cloneResult.result === Result.Failed){
                                    Swal.fire({
                                        title: `The resource<br>${resource.name}<br>has NOT been cloned succesfully`,
                                        icon: 'error',
                                        html: cloneResult.message ?? "",
                                        showCancelButton: false,
                                        showCloseButton: false,
                                        allowOutsideClick: false,
                                        showConfirmButton: true,
                                        heightAuto: false,
                                        width: "auto",
                                    });  
                                }
                                else if(cloneResult.result === Result.Canceled){
                                    Swal.fire({
                                        title: `Cloning resource<br>${resource.name}<br>has been skipped`,
                                        icon: 'info',
                                        html: `It has not been updated`,
                                        showCancelButton: false,
                                        showCloseButton: false,
                                        allowOutsideClick: false,
                                        showConfirmButton: true,
                                        heightAuto: false,
                                        width: "auto",
                                    });  
                                }
                            }
                            else{
                                Swal.fire({
                                    title: 'The provided token is not valid',
                                    icon: 'warning',
                                    showCancelButton: false,
                                    showCloseButton: false,
                                    allowOutsideClick: false,
                                    showConfirmButton: true,
                                    heightAuto: false
                                });
                            }
                        }
                    });
                });

                resourceContext.appendChild(cloneInTenantButton);
            }
        }

        return resourceContext;
    }

    removeResourceFromLists(id: string) {
        this.allResources.splice(this.allResources.findIndex(x => x.id === id), 1);
        this.filteredResources.splice(this.filteredResources.findIndex(x => x.id === id), 1);
        this.typeFilteredResources.splice(this.typeFilteredResources.findIndex(x => x.id === id), 1);
        this.searchedResources.splice(this.searchedResources.findIndex(x => x.id === id), 1);
    }

    ///////

    private updateContextMenuItems(published: boolean, resourceContext: HTMLDivElement, deleteButton: HTMLButtonElement, publishButton: HTMLButtonElement, republishButton: HTMLButtonElement, unpublishButton: HTMLButtonElement) {
        resourceContext.removeChild(deleteButton);

        if(published){
            if(resourceContext.contains(publishButton)){
                resourceContext.removeChild(publishButton);
            }

            resourceContext.appendChild(republishButton);
            resourceContext.appendChild(unpublishButton);
        }
        else{
            resourceContext.removeChild(republishButton);
            resourceContext.removeChild(unpublishButton);

            resourceContext.appendChild(publishButton);
        }

        resourceContext.appendChild(deleteButton);
    }

    loadResourceThumb(resourceId: string, cell: HTMLDivElement) {
        if (this.thumbs.has(resourceId)) {
            const thumb = this.thumbs.get(resourceId);

            this.setThumbnail(resourceId, cell, thumb.url);
        }
        else{
            ResourceUtils.getThumbnailBlobUrl(resourceId).then(blobUri => {
                this.setThumbnail(resourceId, cell, blobUri);
            });
        }
    }

    private setThumbnail(resourceId: string, cell: HTMLDivElement, blobUri: string) {
        let thumbUri = blobUri;

        if(!thumbUri){
            const hevoResourceType = cell.dataset["type"];

            switch(hevoResourceType){
                case HevolusResourceType.Base:{
                    thumbUri = BASE_THUMB_URI;
                    break;
                }
                case HevolusResourceType.Deck:{
                    thumbUri = DECK_THUMB_URI;
                    break;
                }
                case HevolusResourceType.Media:{
                    thumbUri = MEDIA_THUMB_URI;
                    break;
                }
                case HevolusResourceType.Model:{
                    thumbUri = MODEL_THUMB_URI;
                    break;
                }
                case HevolusResourceType.Room:{
                    thumbUri = ROOM_THUMB_URI;
                    break;
                }
                case HevolusResourceType.Space:{
                    thumbUri = SPACE_THUMB_URI;
                    break;
                }
            }
        }

        this.thumbs.set(resourceId, { url: thumbUri });

        let img = cell.querySelector(".cell-img") as HTMLImageElement;
        img.style.backgroundImage = "url(" + thumbUri + ")";
        img.style.backgroundColor =  "#a9b2c0";
        img.classList.remove("div-loading");
        img.classList.add("div-loaded");
    }

    async drawSearchBar() {

        let mainFluid = document.getElementById("main-fluid");
        const explorerContainer = mainFluid.querySelector<HTMLElement>('#explorer-container');

        let searchContainer = document.createElement("div");
        searchContainer.id = "search-container";

        mainFluid.insertBefore(searchContainer, explorerContainer);

        let mainNavTemp = importTemplate("#temp-main-nav");
        searchContainer.appendChild(mainNavTemp);

        let baseHero = document.getElementById("base-hero");
        let modelHero = document.getElementById("model-hero");
        let spaceHero = document.getElementById("space-hero");
        let deckHero = document.getElementById("deck-hero");
        let mediaHero = document.getElementById("media-hero");

        let baseRadio = document.getElementById("base-radio");
        let modelRadio = document.getElementById("model-radio");
        let spaceRadio = document.getElementById("space-radio");
        let deckRadio = document.getElementById("deck-radio");
        let mediaRadio = document.getElementById("media-radio");

        if (baseHero == null || modelHero == null || spaceHero == null || deckHero == null || mediaHero == null ||
            baseRadio == null || modelRadio == null || spaceRadio == null || deckRadio == null || mediaRadio == null) {
            console.log(`WARNING: one or more hero or radio html elements are null/undefined.`);
        }
        
        baseRadio.addEventListener("click", this.selectResourceType);
        modelRadio.addEventListener("click", this.selectResourceType);
        spaceRadio.addEventListener("click", this.selectResourceType);
        deckRadio.addEventListener("click", this.selectResourceType);
        mediaRadio.addEventListener("click", this.selectResourceType);

        //Save and Open button
        let newResource = document.getElementById("post-new-resource");
        newResource.addEventListener("click", this.createResource,);

        //Cancel button
        let closeResource = document.getElementById("close-new-resource");
        closeResource.addEventListener("click", this.closeNewResourcePanel)

        //new resource button        
        let newResourceButton = searchContainer.querySelector("#new-resource-button") as HTMLDivElement;

        newResourceButton.addEventListener("click", () => {
            this.spawnNewResourcePanel();
        });

        const canCreateNewResource = this.licenses[HOLOMUSEUM_CONTENTCREATOR_LICENSE] == LicenseValidity.Valid ||
            this.licenses[AUGMENTEDSTORE_CONTENTCREATOR_LICENSE] == LicenseValidity.Valid ||
            this.licenses[REMOTESELLING_CONTENTCREATOR_LICENSE] == LicenseValidity.Valid ||
            this.licenses[HOLOPROTOTYPE_CONTENTCREATOR_LICENSE] == LicenseValidity.Valid ||
            this.licenses[HEVOCOLLABORATION_CONTENTCREATOR_LICENSE] == LicenseValidity.Valid;

        newResourceButton.hidden = !canCreateNewResource;

        //Search Bar
        let searchText = searchContainer.querySelector("#search-text") as HTMLInputElement;
        searchText.maxLength = MAX_RESOURCE_NAME_LENGTH;
        searchText.addEventListener("keyup", async (event: Event) => {
            const searchInput = (event.target as HTMLInputElement);
            searchInput.value = Utils.sanitizeString(searchInput.value);

            this.searchValue = searchInput.value;
            this.searchAndRenderCells();
        });

        let validFilterTypes: HevolusResourceType[] = [HevolusResourceType.All];

        if (this.licenses[HOLOPROTOTYPE_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid) {
            validFilterTypes.push(HevolusResourceType.Base);
        }

        if (this.licenses[AUGMENTEDSTORE_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid ||
            this.licenses[HOLOMUSEUM_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid ||
            this.licenses[REMOTESELLING_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid ||
            this.licenses[HOLOPROTOTYPE_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid ||
            this.licenses[HEVOCOLLABORATION_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid) {
            validFilterTypes.push(HevolusResourceType.Model);
        }

        if (this.licenses[AUGMENTEDSTORE_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid ||
            this.licenses[HOLOMUSEUM_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid ||
            this.licenses[REMOTESELLING_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid ||
            this.licenses[HEVOCOLLABORATION_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid) {
            validFilterTypes.push(HevolusResourceType.Media);
        }

        if (this.licenses[AUGMENTEDSTORE_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid ||
            this.licenses[HOLOMUSEUM_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid ||
            this.licenses[REMOTESELLING_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid ||
            this.licenses[HOLOMUSEUM_HOST_LICENSE] === LicenseValidity.Valid ||
            this.licenses[REMOTESELLING_HOST_LICENSE] === LicenseValidity.Valid ||
            this.licenses[HOLOPROTOTYPE_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid ||
            this.licenses[HOLOPROTOTYPE_HOST_LICENSE] === LicenseValidity.Valid) {
            validFilterTypes.push(HevolusResourceType.Space);
        }

        if (this.licenses[HEVOCOLLABORATION_CONTENTCREATOR_LICENSE] === LicenseValidity.Valid) {
            validFilterTypes.push(HevolusResourceType.Deck);
        }

        if (this.licenses[HEVOCOLLABORATION_HOST_LICENSE] === LicenseValidity.Valid) {
            validFilterTypes.push(HevolusResourceType.Room);
        }

        // Get Filters
        let resourceFilters = searchContainer.querySelectorAll<HTMLDivElement>(".resource-filter");

        let validResourceFilterElements: HTMLDivElement[] = [];
    
        if(this.aiLicense){
            searchContainer.querySelector("#Ai-filter").classList.remove("d-none");
            
            validFilterTypes.push("Ai" as HevolusResourceType); //forced to cast to HevolusResourceType, but it's a string
        }

        for (let filter of resourceFilters) {
            //console.log(`[filter] filtered casted: ${filter.innerText}`);

            let filterValue = filter.dataset.value as HevolusResourceType;

            if (validFilterTypes.includes(filterValue)) {
                if (!filter.classList.contains("dropdown-item")) {
                    validResourceFilterElements.push(filter);
                }

                //filter.classList.remove("d-none");
                filter.classList.remove("switch-right");

                //console.log("Filter Value: ", filterValue);

                filter.addEventListener("click", async (e) => {
                    const anchorEl = e.currentTarget as HTMLAnchorElement;

                    this.typeFilter = filterValue;
                    this.setTypeFilter(); //UI

                    searchContainer.querySelectorAll(".resource-filter.active").forEach(e => e.classList.remove('active'));
                    anchorEl.classList.add('active');

                    const buttonDropdown = anchorEl.parentElement.previousElementSibling as HTMLButtonElement;
                    buttonDropdown.innerText = anchorEl.innerHTML;
                    buttonDropdown.classList[this.typeFilter === HevolusResourceType.All ? 'remove' : 'add']('active-switch');

                    if (this.typeFilter !== HevolusResourceType.All) {
                        if(this.typeFilter === "Ai" as HevolusResourceType){
                            this.typeFilteredResources = this.allResources.filter(res => !this.aiModelIds.includes(res.id));
                        }
                        else{
                            this.typeFilteredResources = this.allResources.filter(res => ResourceUtils.getHevolusResourceType(res) != filterValue);
                        }
                    }
                    else{
                        this.typeFilteredResources = [];
                    }

                    this.filteredResources = difference(this.searchedResources, this.typeFilteredResources);
                    this.refreshResources();
                });
            } else {
                filter.classList.add("d-none");
            }
        }

        //draw Group By Tag slider toggle
        const groupByTagToggle = mainNavTemp.querySelector('#group-by-tag-toggle');

        groupByTagToggle.addEventListener("input", (e) => {
            const groupByTagInput = e.target as HTMLInputElement;
            const spanEl = groupByTagToggle.querySelector('.custom-slider');
            spanEl.classList[groupByTagInput.checked ? 'add' : 'remove']('active-switch');
            
            this.tagViewEnabled = groupByTagInput.checked;

            let tagSections = document.querySelectorAll<HTMLElement>(`.tag-section`);

            tagSections.forEach(section => {
                if(section.dataset.sectionName != ALL_RESOURCES_TAG_SECTION) {
                    section.hidden = !this.tagViewEnabled;
                }
            });
        });

        const dateOrderButton = mainNavTemp.querySelector("#date-order-button");
        const nameOrderButton = mainNavTemp.querySelector("#name-order-button");

        dateOrderButton.addEventListener("click", () => {
            this.dateOrdered = true;

            nameOrderButton.classList.remove("active-toggle");
            dateOrderButton.classList.add("active-toggle");

            this.refreshResources();
        })

        nameOrderButton.addEventListener("click", () => {
            this.dateOrdered = false;

            dateOrderButton.classList.remove("active-toggle");
            nameOrderButton.classList.add("active-toggle");

            this.refreshResources();
        })
    }

    private searchAndRenderCells() {
        this.searchedResources = this.allResources.filter(
            res => res.name.toLowerCase().includes(this.searchValue.toLowerCase()) ||
                res.tags.some(tag => tag.toLowerCase() == this.searchValue.toLowerCase()));

        this.filteredResources = difference(this.searchedResources, this.typeFilteredResources);

        this.refreshResources();
    }

    private sortByDate(resources: VERTEXResource[]){
        resources.sort((elem1, elem2) => {
            const date1 = new Date(elem1.modified).getTime();
            const date2 = new Date(elem2.modified).getTime();

            return date2 - date1;
        });
    }

    private sortByName(resources: VERTEXResource[]){
        resources.sort((res1, res2) => {
            let result = 0;

            if (res1.name.toLowerCase() < res2.name.toLowerCase()) {
                result = -1;
            }

            if (res1.name.toLowerCase() > res2.name.toLowerCase()) {
                result = 1;
            }

            return result;
        });
    }

    private refreshResources() {
        if(this.dateOrdered){
            this.sortByDate(this.filteredResources);
        }
        else{
            this.sortByName(this.filteredResources);
        }

        if(this.tagViewEnabled){
            let tagSections = document.querySelectorAll<HTMLElement>(`.tag-section`);

            tagSections.forEach(section => {
                this.renderCellsByTag(section.dataset.sectionName);
            });
        }
        else{
            this.renderCellsByTag(ALL_RESOURCES_TAG_SECTION);
        }

        this.updateSectionCounter();
    }

    // get global type filter for searches
    setTypeFilter() {
        let filters = document.querySelectorAll(".resource-filter");

        filters.forEach(filter => {
            filter.classList.remove("active-toggle");
        });

        document.getElementById(`${this.typeFilter}-filter`)?.classList.add("active-toggle");
    }

    private renderResourceCells(resources: VERTEXResource[], tag: string = null, reset: boolean = false){
        const explorerContainer = document.querySelector("#explorer-container") as HTMLDivElement;
        const tagSectionContainers = explorerContainer.querySelectorAll<HTMLElement>(".tagExplorerContainer");
        const tagSectionContainer = Array.from(tagSectionContainers.values()).find(section => section.dataset.tagSection == tag)

        if(resources == null || resources.length === 0){
            tagSectionContainer.innerHTML = "";

            return;
        }

        if(!this.loadedResourcesByTag.has(tag) || reset){
            this.loadedResourcesByTag.set(tag, 0);

            if(tagSectionContainer){
                //delete current rendered cells
                tagSectionContainer.innerHTML = "";
            }
        }

        const startIndex = this.loadedResourcesByTag.get(tag);
        let amount = this.loadedResourcesByTag.get(tag) + Math.min((resources.length - startIndex), this.PRELOAD_AMOUNT);
        let moreResIndex = amount - this.loadedResourcesByTag.get(tag) < this.PRELOAD_AMOUNT ? -1 : amount - Math.floor(this.PRELOAD_AMOUNT / 2);

        this.updateSectionCounter();

        if(resources.length >  startIndex + (amount - startIndex - 1)){
            for(let i = startIndex; i < amount; i++){
                this.drawResourceButton(resources[i], i, explorerContainer, moreResIndex, tag);
                this.loadedResourcesByTag.set(tag, this.loadedResourcesByTag.get(tag)+1);
            }
        }

    }

    private updateSectionCounter() {
        let tagSections = document.querySelectorAll(".tag-section");

        //update section counter
        tagSections.forEach(s => {
            let section = s as HTMLElement;
            let counter = section.querySelector(".tag-counter") as HTMLSpanElement;

            let count = this.filteredResources.filter(res => res.tags.includes(section.dataset["sectionName"])).length;
            
            if (section.dataset["sectionName"] === ALL_RESOURCES_TAG_SECTION) {
                count = this.filteredResources.length;
            }
            
            counter.innerText = count.toString();

            //hide section head if no res available
            if (section.dataset["sectionName"] !== ALL_RESOURCES_TAG_SECTION) {
                if (count === 0) {
                    section.classList.add("d-none");
                }
                else {
                    section.classList.remove("d-none");
                }
            }
        });
    }
}
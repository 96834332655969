import { AugmentedStoreAssembly } from '../../../../AugmentedStoreAssembly';
import { Utils } from '../../../utilities/utils';
import { IAwaitableComponent } from './gltfLoadingHandlerComponent';


export class CustomRotationComponent extends AugmentedStoreAssembly.RotationComponent implements IAwaitableComponent {

    isAwaitableComponent: boolean = true;
    funcOnLoad = ((node) => {

        const self = this;
        const axis = new BABYLON.Vector3(0, 1, 0);
        const scene = node.viewNode.getScene();
        const engine = scene.getEngine();

        const transformCom = node.getComponent("Transform") as Vertex.NodeComponentModel.TransformComponent;
        const prevRotation = transformCom.rotation;
        const rotate = function () {
            let deltaAngle = (engine.getDeltaTime() / 1000) * self.speed * (self.isClockwise ? 1 : -1);
            let rotationQuaternion = BABYLON.Quaternion.RotationAxis(axis, deltaAngle);
            let r = rotationQuaternion.multiply(node.viewNode.rotationQuaternion);
            transformCom.rotation = [r.x, r.y, r.z, r.w];
            transformCom.position = node.viewNode.position.asArray();
            transformCom.triggerOnChanged();

        };

        this.onRemoved.on(() => {
            scene.unregisterAfterRender(rotate);
            transformCom.rotation = prevRotation;
            transformCom.triggerOnChanged();
        });
         
        scene.registerAfterRender(rotate);
    }).bind(this);

    async onLoad(): Promise<void> {
        
        return await this.funcOnLoad(this.node);
    }
}

export class RotationComponentView extends Vertex.NodeComponentModel.ComponentViewBase  {
    constructor() {
        super();
    }


    incompatibleComponents = ["LightMaps"];

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {        
        let rotationComponent = component as CustomRotationComponent;

        let isCompatible = Utils.checkComponentsCompatibility("RotationComponent", node, this.incompatibleComponents);

         if (!isCompatible) {
            node.removeComponent("RotationComponent");
            //Vertex.Globals.event.fire("editor:selectNode", node);
            return;
        }

        // const mesh = node.viewNode;

        const itemPropertiesComp = node.getComponent("ItemProperties") as AugmentedStoreAssembly.ItemPropertiesComponent;
        
        if (itemPropertiesComp) {
            const rotationPropertyRenderer = document.querySelector("#rotationPropertyRenderer");
            if (rotationPropertyRenderer) {
                const inputs = rotationPropertyRenderer.querySelectorAll("input");        
    
                inputs[0].disabled = true;
                inputs[1].disabled = true;
                inputs[2].disabled = true;
            }

            const isMovableInput = document.querySelector("#isMovablePropertyRenderer")?.querySelector("input");
            if (isMovableInput) {
                isMovableInput.checked = false;
                isMovableInput.setAttribute("disabled", "");
            }
            itemPropertiesComp.isMovable = false;
            
            itemPropertiesComp.triggerOnChanged();
        }

        const mediaTextureComp = node.getComponent("MediaTexture");
        if (mediaTextureComp) {
            rotationComponent.funcOnLoad(node);
        }
    }


    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {

    }
}

export class RotationComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new CustomRotationComponent();
    }
    constructor() {
        super("RotationComponent", new RotationComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}


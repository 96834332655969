export class CustomXYZRenderer implements Vertex.UI.ICustomInspectorRenderer {

    constructor(targetNode: Vertex.NodeComponentModel.VertexNode) {
        this.targetNode = targetNode;
    }

    targetNode: Vertex.NodeComponentModel.VertexNode;
    target: Vertex.NodeComponentModel.Component;
    property: string;

    row = document.createElement("div");
    xInput = document.createElement("input");
    yInput = document.createElement("input");
    zInput = document.createElement("input");
    xInputContainer = document.createElement("div");
    yInputContainer = document.createElement("div");
    zInputContainer = document.createElement("div");
    xInputLabel = document.createElement("div");
    yInputLabel = document.createElement("div");
    zInputLabel = document.createElement("div");

    RenderProperty(property: string, target: any): HTMLDivElement {
        this.target = target;
        this.property = property;

        this.row.classList.add("row","input-row","align-items-center");
        this.row.classList.add("control-group","xyzr-text");

        for (let i of [this.xInput, this.yInput, this.zInput]) {
            i.addEventListener('change', this.inputChanged.bind(this));
            i.classList.add('mr-1', 'form-control',"xyzr-text");
            this.xInputContainer.classList.add("input-container");
            this.yInputContainer.classList.add("input-container");
            this.zInputContainer.classList.add("input-container");
            this.xInputLabel.classList.add("small-input-text");
            this.yInputLabel.classList.add("small-input-text");
            this.zInputLabel.classList.add("small-input-text");
            this.xInputLabel.innerText = "X";
            this.yInputLabel.innerText = "Y";
            this.zInputLabel.innerText = "Z";
            i.spellcheck = false;
            i.autocomplete = "off";
            i.type = "text";
            i.style.minWidth = "0";
        }

        let vals = target[property];
        let x = +vals[0] || 0.0;
        let y = +vals[1] || 0.0;
        let z = +vals[2] || 0.0;

        this.xInput.value = x.toFixed(3);
        this.yInput.value = y.toFixed(3);
        this.zInput.value = z.toFixed(3);

        this.row.innerHTML = `<div class="col-3 control-label">${property}</div>`;

        var controlValue = document.createElement('div');
        controlValue.classList.add("col");
        controlValue.classList.add("control-value");
        this.row.appendChild(controlValue);

        this.xInputContainer.appendChild(this.xInputLabel);
        this.xInputContainer.appendChild(this.xInput);
        this.yInputContainer.appendChild(this.yInputLabel);
        this.yInputContainer.appendChild(this.yInput);
        this.zInputContainer.appendChild(this.zInputLabel);
        this.zInputContainer.appendChild(this.zInput);
        controlValue.appendChild(this.xInputContainer);
        controlValue.appendChild(this.yInputContainer);
        controlValue.appendChild(this.zInputContainer);

        this.target.onChanged.on((data: Vertex.NodeComponentModel.Component) => {
            let vals = data[property];

            let x = +vals[0] || 0.0;
            let y = +vals[1] || 0.0;
            let z = +vals[2] || 0.0;

            if(document.activeElement !== this.xInput)
            {
                this.xInput.value = x.toFixed(3);
            }
            if(document.activeElement !== this.yInput)
            {
                this.yInput.value = y.toFixed(3);
            }
            if(document.activeElement !== this.zInput)
            {
                this.zInput.value = z.toFixed(3);
            }
        });

        return this.row;
    }

    inputChanged(e: Event) {
        if (!this.targetNode.HasToken) {
            console.log("Alert!", "You can't change a node you don't own!");
            return;
        }

        let x = +this.xInput.value;
        let y = +this.yInput.value;
        let z = +this.zInput.value;

        if (!isNaN(x) && !isNaN(y) && !isNaN(z)) {
            this.target[this.property][0] = x;
            this.target[this.property][1] = y;
            this.target[this.property][2] = z;
        }

        this.target.triggerOnChanged();
    }
}
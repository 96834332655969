import { v4 as uuidv4 } from 'uuid';
import { AvatarStyles } from '../../services/service.interfaces';
import { GltfUtils } from '../../utilities/gltf-utilities';
import { ResourceUtils } from '../../utilities/resource-utilities';
import { AVATAR_MANIFEST_FILENAME } from '../../utilities/constants';

export class AvatarBabylonVertx{
    private _camera: BABYLON.ArcRotateCamera;
    private _node: Vertex.NodeComponentModel.VertexNode;
    
    constructor(private canvasEl: HTMLCanvasElement, private access_token){ }

    _initCamera(runtime, target?: BABYLON.AbstractMesh):void {
        this._camera = new BABYLON.ArcRotateCamera("Camera", Math.PI / 2.0, Math.PI / 2.0, 3, new BABYLON.Vector3(0, 0, 0), runtime.scene);
        this._camera.attachControl(this.canvasEl, true);
        this._camera.panningSensibility = 0;
        this._camera.upperBetaLimit = 1;
        this._camera.lowerBetaLimit = 1;
        this._camera.angularSensibilityX = 5000;
        this._camera.lowerRadiusLimit = 3;
        this._camera.upperRadiusLimit = 3;

        if(!!target){
            this._camera.setTarget(target);
            this._camera.minZ = target.position.z;
            this._camera.maxZ = target.position.z;
        }

        runtime.enableCameraControls(this._camera);
    }

    resetCameraPosition():void {
        this._camera.setTarget(new BABYLON.Vector3(0, 0, 0));
        this._camera.alpha = Math.PI / 2.0;
        this._camera.beta = Math.PI / 2.0;
        this._camera.radius = 3;
    }

    private async initVertexSpace() {
        Vertex.Globals.spaceId = uuidv4(); //defaultSpaceId
        Vertex.Globals["_disableGtlfCaching"] = true;

        await VertexBabylon.InitVertexAsync();

        const runtime = Vertex.Globals.runtime as VertexBabylon.VertexBabylonRuntime;
        const space = runtime.space as Vertex.Space;;
        const scene = runtime.scene as BABYLON.Scene;

        this._initCamera(runtime);
        
        runtime.initLighting(scene);
        runtime.setBearerToken(this.access_token);
        
        space.defaultComponentDataType = Vertex.NodeComponentModel.ComponentDataType.BSON;

        scene.clearColor = new BABYLON.Color4(0,0,0,0.0000000000000001);

        this._node = space.createNode("avatar");
        this._node.doNotSerialize = true;
        this._node.addComponent("Transform");
        this._node.addComponent("GltfModel");
        space.addNode(this._node);
    }

    async loadAvatar(avatarPublishedResId: string, style: AvatarStyles, callback: (result: boolean) => void) {

        const isValid = await this.validateAvatar(avatarPublishedResId);

        if(!isValid){
            if(this._node){
                this._node.removeComponent("GltfModel");
            }

            callback(false);
            
            return;
        }
    

        if(this._node){
            this.resetCameraPosition();
            
            let gltf = this._node.getComponent('GltfModel') as Vertex.NodeComponentModel.GltfModelComponent;

            if(gltf){
                const meshes = gltf.visualNode.getChildMeshes(false, (m => m.isDisposed() === false && m.metadata != null && m.metadata.gltfMaterials != null));

                if (meshes[0] != null) {
                    const materials: BABYLON.Material[] = meshes[0].metadata.gltfMaterials;

                    for(let i = materials.length - 1; i >= 0; i--){
                        let mat = materials[i];
                        let textures = mat.getActiveTextures();
                        for(let j = textures.length - 1; j >= 0; j--){
                            textures[j].dispose();
                        }
                        mat.dispose();
                    }
                }
            }

            this._node.removeComponent("GltfModel") ;
            gltf = this._node.addComponent('GltfModel') as Vertex.NodeComponentModel.GltfModelComponent;

            gltf.id = avatarPublishedResId;
            gltf.triggerOnChanged();

            if(gltf.isReady){
                callback(true);
            }
            else{
                let onReady;
                onReady = () => {
                    gltf.Ready.off(onReady);
                    callback(true);
                };
                gltf.Ready.on(onReady);
            }

            let transform = this._node.getComponent('Transform') as Vertex.NodeComponentModel.TransformComponent;
                
            if (style === AvatarStyles.Realistic) {
                transform.position = [0, -1, 0];
            } else if (style === AvatarStyles.Cartoon) {
                transform.position = [0, 0, 0];
            }

            transform.triggerOnChanged();
        }
        else{
            this.initVertexSpace().then(() => {

                this._node.removeComponent("GltfModel");
                let gltf = this._node.addComponent('GltfModel') as Vertex.NodeComponentModel.GltfModelComponent;
                
                gltf.id = avatarPublishedResId;
                gltf.triggerOnChanged();

                if(gltf.isReady){
                    callback(true);
                }
                else{
                    let onReady;
                    onReady = () => {
                        gltf.Ready.off(onReady);
                        callback(true);
                    };
                    gltf.Ready.on(onReady);
                }

                BABYLON.SceneLoader.OnPluginActivatedObservable.add(function (loader) {
                    if (loader.name === "gltf") {            
                        (loader as BABYLON.GLTFFileLoader).onErrorObservable.add(async (e) => {
                            console.log(`Failed to load avatar gltf model.`, e);
    
                            if(e != null && e.message != null){
                                callback(false);
                                return;                    
                            }
                        });
                    }
                });             
    
                let transform = this._node.getComponent('Transform') as Vertex.NodeComponentModel.TransformComponent;
                
                if (style === AvatarStyles.Realistic) {
                    transform.position = [0, -1, 0];
                } else if (style === AvatarStyles.Cartoon) {
                    transform.position = [0, 0, 0];
                }
    
                transform.triggerOnChanged();
            });
        }
    }

    
    async validateAvatar(avatarPublishedResId: string): Promise<boolean> {
        const res = await ResourceUtils.getResourceData(avatarPublishedResId);

        if(!res){
            return false;
        }

        const files = res.resourceKeys;
        const hasValidGltf = (await GltfUtils.validateGltf(res, true, false)).hasValidGltf;
        
        return hasValidGltf && files.includes(AVATAR_MANIFEST_FILENAME);
    }
}
import { CustomAnimationComponent } from "../componentsystems/NodeComponents/animationcomponent";

export class CustomAnimationInspectorRenderer implements Vertex.UI.ICustomInspectorRenderer {

    constructor(targetNode: Vertex.NodeComponentModel.VertexNode) {
        this.targetNode = targetNode;
    }
    targetNode: Vertex.NodeComponentModel.VertexNode;
    target: Vertex.NodeComponentModel.Component;
    property: string;

    row = document.createElement("div");

    //Target appears to be the node but we also have just targetNode so not sure why it's needed.
    RenderProperty(property: string, target: any): HTMLDivElement {
        this.target = target;
        //Assuming property is "shouldPlay"
        this.property = property;

        //Get GLTF
        let animComp = target as CustomAnimationComponent;
        let outer = document.createElement('div');
        
        this.renderAnimationList(outer, animComp);
        
        
        animComp.onChanged.on(()=>{ this.renderAnimationList(outer, animComp) });

        
        
        return outer;
    }

    private renderAnimationList(outer: HTMLDivElement, animComp: CustomAnimationComponent) {
        outer.innerText = "";
        console.log("Animation updated! ", animComp);
        for (let i = 0; i < animComp.animationGroups.length; i++) {
            let card = document.createElement("div");
            card.classList.add("animation-wrapper"); //playing

            let isAnimPlaying = animComp.shouldPlay[i];
            let playDir = animComp.shouldPlayForward[i];
            card.classList.toggle("playing", isAnimPlaying);


            let animationNameDiv = document.createElement("div");
            animationNameDiv.classList.add("animation-name");
            animationNameDiv.innerText = animComp.animationGroups[i].name;
            card.appendChild(animationNameDiv);

            let button = document.createElement("button");
            button.classList.add("animation-button", "btn", "btn-small", "btn-indigo");

            let img = document.createElement("img");
            img.classList.add("play", "d-block");

            if (isAnimPlaying) {
                img.src = "/img/pause.svg";
            }
            else {
                img.src = "/img/play.svg";

                if (playDir) {
                    img.style.transform = "rotate(180deg)";
                }
            }

            let index = i;
            button.addEventListener("click", () => {
                if (isAnimPlaying) {
                }
                else {
                    //if (playDir) {
                        animComp.shouldPlay[i] = true;
                        animComp.shouldPlayForward[i] = !animComp.shouldPlayForward[i];
                        animComp.triggerOnChanged();
                    //}
                }
            });

            button.appendChild(img);
            card.appendChild(button);
            outer.appendChild(card);
        }
    }
}

/*
<div class="card pointer-enable scroll-card">
            <div class="nav-link dark-text card-header-closable">
              <div class="card-header-text">Animation</div>
              <div class="card-header-close"> 
                  <img src="/img/cross.svg" alt="" class="card-header-close-icon">
              </div>
            </div>
            <div class="card-body">
              <div class="animation-wrapper">
                <div class="animation-name">Animation name</div>
                <button class="animation-button btn btn-small btn-indigo"><img class="play d-block" src="/img/play.svg"><img class="pause d-none" src="/img/pause.svg"></button>
              </div>
              <div class="animation-wrapper playing">
                <div class="animation-name">Animation name</div>
                <button class="animation-button btn btn-small btn-indigo"><img class="play d-none" src="/img/play.svg"><img class="pause d-block" src="/img/pause.svg"></button>
              </div>
              <div class="animation-wrapper">
                <div class="animation-name">Animation name</div>
                <button class="animation-button btn btn-small btn-indigo"><img class="play d-block" src="/img/play.svg"><img class="pause d-none" src="/img/pause.svg"></button>
              </div>

            </div>


            </div>
          </div>
*/
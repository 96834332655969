import { AugmentedStoreAssembly } from "../../../../AugmentedStoreAssembly";

export class ItemPropertiesComponentView extends Vertex.NodeComponentModel.ComponentViewBase {
    constructor() {
        super();
    }

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        const comp = component as AugmentedStoreAssembly.ItemPropertiesComponent;

        this.setNavMeshCompProperties(comp, node);

        comp.onChanged.on((c: AugmentedStoreAssembly.ItemPropertiesComponent) => {
            const navmesh = c.node.getComponent('NavMesh') as AugmentedStoreAssembly.NavMeshComponent;

            if(navmesh){
                if(c.isMovable || c.isSelectable || c.isWishlistable){
                    c.isMovable = false;
                    c.isSelectable = false;
                    c.isWishlistable = false;

                    c.triggerOnChanged();
                }
            }
        })
    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
    }

    update(): void {
    }

    setNavMeshCompProperties(comp: AugmentedStoreAssembly.ItemPropertiesComponent, node: Vertex.NodeComponentModel.VertexNode){
        const navmeshComp = node.getComponent('NavMesh') as AugmentedStoreAssembly.NavMeshComponent;

        if(navmeshComp){
            comp.isMovable = false;
            comp.isSelectable = false;
            comp.isWishlistable = false;

            comp.triggerOnChanged();
        }
    }

}

export class ItemPropertiesComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new AugmentedStoreAssembly.ItemPropertiesComponent();
    }

    constructor() {        
        super("ItemProperties", new ItemPropertiesComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}
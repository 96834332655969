export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  ConnectionCursor: { input: any; output: any; }
  JSONObject: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type Query = {
  __typename?: 'Query';
  status: StatusQueryOutput;
  user?: Maybe<User>;
  users: UserConnection;
  appSettings: AppSettings;
  project?: Maybe<Project>;
  projects: ProjectConnection;
  activity?: Maybe<Activity>;
  activities: ActivityConnection;
  UserInfoRelatedToTermsAndConditions: UserInfoForAtacOutput;
  getWallet: WalletOutput;
  getCustomerWallet: WalletOutput;
  perkSubscriptionStatus: PerkSubscriptionStatusOutput;
  sharing: OptimizationSharing;
  feedback?: Maybe<Feedback>;
  feedbacks: FeedbackConnection;
  optimizationTemplate?: Maybe<OptimizationTemplate>;
  optimizationTemplates: OptimizationTemplateConnection;
  optimizationTemplateItem?: Maybe<OptimizationTemplateItem>;
  optimizationTemplateItems: OptimizationTemplateItemConnection;
  customer?: Maybe<Customer>;
  customers: CustomerConnection;
  optimization?: Maybe<Optimization>;
  optimizations: OptimizationConnection;
  log?: Maybe<Log>;
  logs: LogConnection;
  customerPerk?: Maybe<CustomerPerk>;
  customerPerks: CustomerPerkConnection;
  perk?: Maybe<Perk>;
  perks: PerkConnection;
  optimizationSharing?: Maybe<OptimizationSharing>;
  optimizationSharings: OptimizationSharingConnection;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUsersArgs = {
  paging?: InputMaybe<CursorPaging>;
  filter?: InputMaybe<UserFilter>;
  sorting?: InputMaybe<Array<UserSort>>;
};


export type QueryProjectArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProjectsArgs = {
  paging?: InputMaybe<CursorPaging>;
  filter?: InputMaybe<ProjectFilter>;
  sorting?: InputMaybe<Array<ProjectSort>>;
};


export type QueryActivityArgs = {
  id: Scalars['ID']['input'];
};


export type QueryActivitiesArgs = {
  paging?: InputMaybe<CursorPaging>;
  filter?: InputMaybe<ActivityFilter>;
  sorting?: InputMaybe<Array<ActivitySort>>;
};


export type QueryGetCustomerWalletArgs = {
  customerId: Scalars['String']['input'];
};


export type QuerySharingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFeedbackArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFeedbacksArgs = {
  paging?: InputMaybe<CursorPaging>;
  filter?: InputMaybe<FeedbackFilter>;
  sorting?: InputMaybe<Array<FeedbackSort>>;
};


export type QueryOptimizationTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOptimizationTemplatesArgs = {
  paging?: InputMaybe<CursorPaging>;
  filter?: InputMaybe<OptimizationTemplateFilter>;
  sorting?: InputMaybe<Array<OptimizationTemplateSort>>;
};


export type QueryOptimizationTemplateItemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOptimizationTemplateItemsArgs = {
  paging?: InputMaybe<CursorPaging>;
  filter?: InputMaybe<OptimizationTemplateItemFilter>;
  sorting?: InputMaybe<Array<OptimizationTemplateItemSort>>;
};


export type QueryCustomerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCustomersArgs = {
  paging?: InputMaybe<CursorPaging>;
  filter?: InputMaybe<CustomerFilter>;
  sorting?: InputMaybe<Array<CustomerSort>>;
};


export type QueryOptimizationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOptimizationsArgs = {
  paging?: InputMaybe<CursorPaging>;
  filter?: InputMaybe<OptimizationFilter>;
  sorting?: InputMaybe<Array<OptimizationSort>>;
};


export type QueryLogArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLogsArgs = {
  paging?: InputMaybe<CursorPaging>;
  filter?: InputMaybe<LogFilter>;
  sorting?: InputMaybe<Array<LogSort>>;
};


export type QueryCustomerPerkArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCustomerPerksArgs = {
  paging?: InputMaybe<CursorPaging>;
  filter?: InputMaybe<CustomerPerkFilter>;
  sorting?: InputMaybe<Array<CustomerPerkSort>>;
};


export type QueryPerkArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPerksArgs = {
  paging?: InputMaybe<CursorPaging>;
  filter?: InputMaybe<PerkFilter>;
  sorting?: InputMaybe<Array<PerkSort>>;
};


export type QueryOptimizationSharingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOptimizationSharingsArgs = {
  paging?: InputMaybe<CursorPaging>;
  filter?: InputMaybe<OptimizationSharingFilter>;
  sorting?: InputMaybe<Array<OptimizationSharingSort>>;
};

export type StatusQueryOutput = {
  __typename?: 'StatusQueryOutput';
  status: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['String']['output'];
  email: Scalars['String']['output'];
  role: UserRole;
  enabled: Scalars['Boolean']['output'];
  emailValidated: Scalars['Boolean']['output'];
  expires?: Maybe<Scalars['DateTime']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  iFullName?: Maybe<Scalars['String']['output']>;
  isAdmittedToBeta: Scalars['Boolean']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  latestLogin?: Maybe<Scalars['DateTime']['output']>;
  customer?: Maybe<Customer>;
};

export enum UserRole {
  Admin = 'admin',
  User = 'user'
}

export type Customer = {
  __typename?: 'Customer';
  id: Scalars['String']['output'];
  metadata?: Maybe<CustomerMetadata>;
  user: CustomerUserDto;
  projectCount: Scalars['Int']['output'];
  marketingConsent: Scalars['Boolean']['output'];
  shareData: Scalars['Boolean']['output'];
  customer_perks?: Maybe<Array<CustomerPerk>>;
};


export type CustomerCustomer_PerksArgs = {
  filter?: InputMaybe<CustomerPerkFilter>;
  sorting?: InputMaybe<Array<CustomerPerkSort>>;
};

export type CustomerMetadata = {
  __typename?: 'CustomerMetadata';
  welcomePopupSeen?: Maybe<Scalars['Boolean']['output']>;
  preferredTheme?: Maybe<Scalars['String']['output']>;
};

export type CustomerUserDto = {
  __typename?: 'CustomerUserDto';
  id: Scalars['String']['output'];
  email: Scalars['String']['output'];
  emailValidated?: Maybe<Scalars['Boolean']['output']>;
  expires?: Maybe<Scalars['DateTime']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  iFullName?: Maybe<Scalars['String']['output']>;
};

export type CustomerPerkFilter = {
  and?: InputMaybe<Array<CustomerPerkFilter>>;
  or?: InputMaybe<Array<CustomerPerkFilter>>;
  id?: InputMaybe<StringFieldComparison>;
  valid_since?: InputMaybe<DateFieldComparison>;
  valid_until?: InputMaybe<DateFieldComparison>;
  remove_after_expiration?: InputMaybe<BooleanFieldComparison>;
};

export type StringFieldComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
  iLike?: InputMaybe<Scalars['String']['input']>;
  notILike?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DateFieldComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  between?: InputMaybe<DateFieldComparisonBetween>;
  notBetween?: InputMaybe<DateFieldComparisonBetween>;
};

export type DateFieldComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type BooleanFieldComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomerPerkSort = {
  field: CustomerPerkSortFields;
  direction: SortDirection;
  nulls?: InputMaybe<SortNulls>;
};

export enum CustomerPerkSortFields {
  Id = 'id',
  ValidSince = 'valid_since',
  ValidUntil = 'valid_until',
  RemoveAfterExpiration = 'remove_after_expiration'
}

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SortNulls {
  NullsFirst = 'NULLS_FIRST',
  NullsLast = 'NULLS_LAST'
}

export type CustomerPerk = {
  __typename?: 'CustomerPerk';
  id?: Maybe<Scalars['String']['output']>;
  valid_since?: Maybe<Scalars['DateTime']['output']>;
  valid_until?: Maybe<Scalars['DateTime']['output']>;
  remove_after_expiration?: Maybe<Scalars['Boolean']['output']>;
  subscription_id?: Maybe<Scalars['String']['output']>;
  subscription_schedule_id?: Maybe<Scalars['String']['output']>;
  perk: Perk;
};

export type Perk = {
  __typename?: 'Perk';
  id: Scalars['String']['output'];
  code: Scalars['String']['output'];
  reset_value?: Maybe<Scalars['Int']['output']>;
  regularity?: Maybe<RegularityType>;
  regularity_value?: Maybe<Scalars['Int']['output']>;
  offer: Scalars['Boolean']['output'];
  price_description_without_offer?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  short_description: Scalars['String']['output'];
  complete_description: Scalars['String']['output'];
  price_description: Scalars['String']['output'];
  priority_description?: Maybe<Scalars['String']['output']>;
  trial: Scalars['Boolean']['output'];
  subscription: Scalars['Boolean']['output'];
  bytes_limit?: Maybe<Scalars['String']['output']>;
};

export enum RegularityType {
  D = 'd',
  N = 'n'
}

export type CursorPaging = {
  before?: InputMaybe<Scalars['ConnectionCursor']['input']>;
  after?: InputMaybe<Scalars['ConnectionCursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type UserFilter = {
  and?: InputMaybe<Array<UserFilter>>;
  or?: InputMaybe<Array<UserFilter>>;
  id?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  role?: InputMaybe<UserRoleFilterComparison>;
  fullName?: InputMaybe<StringFieldComparison>;
  organization?: InputMaybe<StringFieldComparison>;
  phone?: InputMaybe<StringFieldComparison>;
  iFullName?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  latestLogin?: InputMaybe<DateFieldComparison>;
};

export type UserRoleFilterComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  eq?: InputMaybe<UserRole>;
  neq?: InputMaybe<UserRole>;
  gt?: InputMaybe<UserRole>;
  gte?: InputMaybe<UserRole>;
  lt?: InputMaybe<UserRole>;
  lte?: InputMaybe<UserRole>;
  like?: InputMaybe<UserRole>;
  notLike?: InputMaybe<UserRole>;
  iLike?: InputMaybe<UserRole>;
  notILike?: InputMaybe<UserRole>;
  in?: InputMaybe<Array<UserRole>>;
  notIn?: InputMaybe<Array<UserRole>>;
};

export type UserSort = {
  field: UserSortFields;
  direction: SortDirection;
  nulls?: InputMaybe<SortNulls>;
};

export enum UserSortFields {
  Id = 'id',
  Email = 'email',
  Role = 'role',
  FullName = 'fullName',
  Organization = 'organization',
  Phone = 'phone',
  IFullName = 'iFullName',
  CreatedAt = 'createdAt',
  LatestLogin = 'latestLogin'
}

export type UserConnection = {
  __typename?: 'UserConnection';
  pageInfo: PageInfo;
  edges: Array<UserEdge>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']['output']>;
  startCursor?: Maybe<Scalars['ConnectionCursor']['output']>;
  endCursor?: Maybe<Scalars['ConnectionCursor']['output']>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  node: User;
  cursor: Scalars['ConnectionCursor']['output'];
};

export type AppSettings = {
  __typename?: 'AppSettings';
  id: Scalars['ID']['output'];
  isClosedBeta: Scalars['Boolean']['output'];
  analyticsAndImprovements?: Maybe<Scalars['Boolean']['output']>;
  tacData?: Maybe<Scalars['DateTime']['output']>;
};

export type Project = {
  __typename?: 'Project';
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  conversionStatus: ConversionStatus;
  optimizationsCount?: Maybe<Scalars['Int']['output']>;
  conversionError?: Maybe<Scalars['String']['output']>;
  lastActivityAt?: Maybe<Scalars['DateTime']['output']>;
  allowsNormalsBaking: Scalars['Boolean']['output'];
  allowsVertexMaskPolygonReduction: Scalars['Boolean']['output'];
  allowsTextureResize: Scalars['Boolean']['output'];
  iname?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<ProjectMetadata>;
  params?: Maybe<ProjectParams>;
  info: Info;
  customer: Customer;
  objectModel: ObjectModel;
  objectModelObj?: Maybe<ObjectModel>;
  objectModelOriginal?: Maybe<ObjectModel>;
  optimizations?: Maybe<Array<Optimization>>;
};


export type ProjectOptimizationsArgs = {
  filter?: InputMaybe<OptimizationFilter>;
  sorting?: InputMaybe<Array<OptimizationSort>>;
};

export enum ConversionStatus {
  Pending = 'pending',
  Completed = 'completed',
  Error = 'error'
}

export type ProjectMetadata = {
  __typename?: 'ProjectMetadata';
  owner?: Maybe<Scalars['String']['output']>;
};

export type ProjectParams = {
  __typename?: 'ProjectParams';
  linearDeflection?: Maybe<Scalars['Float']['output']>;
  angularDeflection?: Maybe<Scalars['Float']['output']>;
  yup?: Maybe<Scalars['Boolean']['output']>;
  uv?: Maybe<Scalars['Boolean']['output']>;
  mergePrimitives?: Maybe<Scalars['Boolean']['output']>;
  precision?: Maybe<Scalars['Boolean']['output']>;
};

export type Info = {
  __typename?: 'Info';
  version?: Maybe<Scalars['String']['output']>;
  images?: Maybe<Array<ImageInfo>>;
};

export type ImageInfo = {
  __typename?: 'ImageInfo';
  path?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  bitdepth?: Maybe<Scalars['Float']['output']>;
  ext?: Maybe<Scalars['String']['output']>;
};

export type ObjectModel = {
  __typename?: 'ObjectModel';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  publicUrl?: Maybe<Scalars['String']['output']>;
  publicUrl2?: Maybe<Scalars['String']['output']>;
  publicUrlExpiresAt?: Maybe<Scalars['DateTime']['output']>;
  fileSizeBytes?: Maybe<Scalars['String']['output']>;
  triangleCount?: Maybe<Scalars['Int']['output']>;
  picture?: Maybe<Picture>;
  additionals?: Maybe<Array<ObjectModel>>;
};


export type ObjectModelAdditionalsArgs = {
  filter?: InputMaybe<ObjectModelFilter>;
  sorting?: InputMaybe<Array<ObjectModelSort>>;
};

export type Picture = {
  __typename?: 'Picture';
  publicUrl?: Maybe<Scalars['String']['output']>;
  publicUrlExpiresAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ObjectModelFilter = {
  and?: InputMaybe<Array<ObjectModelFilter>>;
  or?: InputMaybe<Array<ObjectModelFilter>>;
  id?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
};

export type ObjectModelSort = {
  field: ObjectModelSortFields;
  direction: SortDirection;
  nulls?: InputMaybe<SortNulls>;
};

export enum ObjectModelSortFields {
  Id = 'id',
  Name = 'name'
}

export type OptimizationFilter = {
  and?: InputMaybe<Array<OptimizationFilter>>;
  or?: InputMaybe<Array<OptimizationFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<OptimizationStatusFilterComparison>;
  type?: InputMaybe<OptimizationTypeFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  lastActivityAt?: InputMaybe<DateFieldComparison>;
  relatedTo?: InputMaybe<OptimizationFilterOptimizationFilter>;
  project?: InputMaybe<OptimizationFilterProjectFilter>;
};

export type IdFilterComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  eq?: InputMaybe<Scalars['ID']['input']>;
  neq?: InputMaybe<Scalars['ID']['input']>;
  gt?: InputMaybe<Scalars['ID']['input']>;
  gte?: InputMaybe<Scalars['ID']['input']>;
  lt?: InputMaybe<Scalars['ID']['input']>;
  lte?: InputMaybe<Scalars['ID']['input']>;
  like?: InputMaybe<Scalars['ID']['input']>;
  notLike?: InputMaybe<Scalars['ID']['input']>;
  iLike?: InputMaybe<Scalars['ID']['input']>;
  notILike?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<Scalars['ID']['input']>>;
  notIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type OptimizationStatusFilterComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  eq?: InputMaybe<OptimizationStatus>;
  neq?: InputMaybe<OptimizationStatus>;
  gt?: InputMaybe<OptimizationStatus>;
  gte?: InputMaybe<OptimizationStatus>;
  lt?: InputMaybe<OptimizationStatus>;
  lte?: InputMaybe<OptimizationStatus>;
  like?: InputMaybe<OptimizationStatus>;
  notLike?: InputMaybe<OptimizationStatus>;
  iLike?: InputMaybe<OptimizationStatus>;
  notILike?: InputMaybe<OptimizationStatus>;
  in?: InputMaybe<Array<OptimizationStatus>>;
  notIn?: InputMaybe<Array<OptimizationStatus>>;
};

export enum OptimizationStatus {
  Pending = 'pending',
  Completed = 'completed',
  Error = 'error'
}

export type OptimizationTypeFilterComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  eq?: InputMaybe<OptimizationType>;
  neq?: InputMaybe<OptimizationType>;
  gt?: InputMaybe<OptimizationType>;
  gte?: InputMaybe<OptimizationType>;
  lt?: InputMaybe<OptimizationType>;
  lte?: InputMaybe<OptimizationType>;
  like?: InputMaybe<OptimizationType>;
  notLike?: InputMaybe<OptimizationType>;
  iLike?: InputMaybe<OptimizationType>;
  notILike?: InputMaybe<OptimizationType>;
  in?: InputMaybe<Array<OptimizationType>>;
  notIn?: InputMaybe<Array<OptimizationType>>;
};

export enum OptimizationType {
  PolygonsReduction = 'polygons_reduction',
  NormalBaking = 'normal_baking'
}

export type OptimizationFilterOptimizationFilter = {
  and?: InputMaybe<Array<OptimizationFilterOptimizationFilter>>;
  or?: InputMaybe<Array<OptimizationFilterOptimizationFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<OptimizationStatusFilterComparison>;
  type?: InputMaybe<OptimizationTypeFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  lastActivityAt?: InputMaybe<DateFieldComparison>;
};

export type OptimizationFilterProjectFilter = {
  and?: InputMaybe<Array<OptimizationFilterProjectFilter>>;
  or?: InputMaybe<Array<OptimizationFilterProjectFilter>>;
  id?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  customerId?: InputMaybe<StringFieldComparison>;
  conversionStatus?: InputMaybe<ConversionStatusFilterComparison>;
  optimizationsCount?: InputMaybe<IntFieldComparison>;
  lastActivityAt?: InputMaybe<DateFieldComparison>;
  iname?: InputMaybe<ProjectInameFilterComparison>;
};

export type ConversionStatusFilterComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  eq?: InputMaybe<ConversionStatus>;
  neq?: InputMaybe<ConversionStatus>;
  gt?: InputMaybe<ConversionStatus>;
  gte?: InputMaybe<ConversionStatus>;
  lt?: InputMaybe<ConversionStatus>;
  lte?: InputMaybe<ConversionStatus>;
  like?: InputMaybe<ConversionStatus>;
  notLike?: InputMaybe<ConversionStatus>;
  iLike?: InputMaybe<ConversionStatus>;
  notILike?: InputMaybe<ConversionStatus>;
  in?: InputMaybe<Array<ConversionStatus>>;
  notIn?: InputMaybe<Array<ConversionStatus>>;
};

export type IntFieldComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  between?: InputMaybe<IntFieldComparisonBetween>;
  notBetween?: InputMaybe<IntFieldComparisonBetween>;
};

export type IntFieldComparisonBetween = {
  lower: Scalars['Int']['input'];
  upper: Scalars['Int']['input'];
};

export type ProjectInameFilterComparison = {
  like?: InputMaybe<Scalars['String']['input']>;
};

export type OptimizationSort = {
  field: OptimizationSortFields;
  direction: SortDirection;
  nulls?: InputMaybe<SortNulls>;
};

export enum OptimizationSortFields {
  Id = 'id',
  Name = 'name',
  Status = 'status',
  Type = 'type',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  LastActivityAt = 'lastActivityAt'
}

export type Optimization = {
  __typename?: 'Optimization';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  status: OptimizationStatus;
  type: OptimizationType;
  logs?: Maybe<Scalars['String']['output']>;
  outputFormat: OptimizationOutputFormat;
  outputFormatOptions?: Maybe<OptimizationOutputFormatOptions>;
  preset?: Maybe<OptimizationPreset>;
  relatedTo?: Maybe<Optimization>;
  feedback?: Maybe<OptimizationFeedback>;
  params?: Maybe<OptimizationParams>;
  nbparams?: Maybe<OptimizationNormalBakingParams>;
  objectModelResult?: Maybe<ObjectModel>;
  objectModelResultObj?: Maybe<ObjectModel>;
  objectModelResultConverted?: Maybe<ObjectModel>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  lastActivityAt?: Maybe<Scalars['DateTime']['output']>;
  publication?: Maybe<Publication>;
  sharing?: Maybe<OptimizationSharing>;
  info: Info;
  project: Project;
};

export enum OptimizationOutputFormat {
  FormatObj = 'format_obj',
  FormatGltf = 'format_gltf',
  FormatGlb = 'format_glb',
  FormatStl = 'format_stl',
  Format_3ds = 'format_3ds',
  FormatFbx = 'format_fbx',
  FormatOrig = 'format_orig'
}

export type OptimizationOutputFormatOptions = {
  __typename?: 'OptimizationOutputFormatOptions';
  format_glb?: Maybe<FormatGltfOptions>;
  format_gltf?: Maybe<FormatGltfOptions>;
};

export type FormatGltfOptions = {
  __typename?: 'FormatGLTFOptions';
  export_draco_mesh_compression_enable: Scalars['Boolean']['output'];
  export_format?: Maybe<GltfExportFormats>;
};

export enum GltfExportFormats {
  Embedded = 'embedded',
  Separate = 'separate'
}

export enum OptimizationPreset {
  Low = 'low',
  Medium = 'medium',
  High = 'high'
}

export enum OptimizationFeedback {
  Up = 'up',
  Down = 'down'
}

export type OptimizationParams = {
  __typename?: 'OptimizationParams';
  face_reduction?: Maybe<Scalars['Float']['output']>;
  feature_importance?: Maybe<Scalars['Int']['output']>;
  feature_area?: Maybe<Scalars['Float']['output']>;
  uv_seam_importance?: Maybe<Scalars['Int']['output']>;
  preserve_boundary_edges?: Maybe<Scalars['Int']['output']>;
  preserve_hard_edges?: Maybe<Scalars['Boolean']['output']>;
  preserve_smooth_edges?: Maybe<Scalars['Boolean']['output']>;
  retexture?: Maybe<Scalars['Boolean']['output']>;
  merge_duplicated_uv?: Maybe<Scalars['Boolean']['output']>;
  merge_gltf_primitives?: Maybe<Scalars['Boolean']['output']>;
  join_meshes?: Maybe<Scalars['Boolean']['output']>;
  remove_isolated_vertices?: Maybe<Scalars['Boolean']['output']>;
  remove_non_manifold_faces?: Maybe<Scalars['Boolean']['output']>;
  remove_duplicated_faces?: Maybe<Scalars['Boolean']['output']>;
  remove_duplicated_boundary_vertices?: Maybe<Scalars['Boolean']['output']>;
  remove_degenerate_faces?: Maybe<Scalars['Boolean']['output']>;
  remove_hidden_objects?: Maybe<Scalars['Boolean']['output']>;
  project_normals?: Maybe<Scalars['Boolean']['output']>;
  use_vertex_mask?: Maybe<Scalars['Boolean']['output']>;
  resize_images?: Maybe<Scalars['Int']['output']>;
  normals_weighting?: Maybe<Scalars['Int']['output']>;
  contrast?: Maybe<Scalars['Float']['output']>;
  joined_simplification?: Maybe<Scalars['Boolean']['output']>;
  normals_scaling?: Maybe<Scalars['Float']['output']>;
  minimum_face_number?: Maybe<Scalars['Int']['output']>;
  remove_meshes_by_size?: Maybe<Scalars['Float']['output']>;
  remove_meshes_by_count?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Unused */
  textures_to_jpeg?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Unused */
  adaptiveOptimization?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Unused */
  maxMeshDeviation?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Unused */
  minAngleToPreserve?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Unused */
  preserveDetails?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Unused */
  optimizeForQuality?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Unused */
  desiredElemPercentage?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Unused */
  considerPhysicalMetric?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Unused */
  preserveLocalAreas?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated unused */
  preserveLocalConvexity?: Maybe<Scalars['Boolean']['output']>;
};

export type OptimizationNormalBakingParams = {
  __typename?: 'OptimizationNormalBakingParams';
  opacity?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  normal_format?: Maybe<Scalars['Int']['output']>;
  image_format?: Maybe<NormalsBakingFormat>;
};

export enum NormalsBakingFormat {
  Png = 'png',
  Tga = 'tga',
  Jpg = 'jpg'
}

export type Publication = {
  __typename?: 'Publication';
  mode: PublicationMode;
  url: Scalars['String']['output'];
  response?: Maybe<Scalars['String']['output']>;
  target?: Maybe<PublicationTarget>;
};

export enum PublicationMode {
  PresignedUrl = 'presigned_url',
  UrlWithAuth = 'url_with_auth',
  UrlFilename = 'url_filename'
}

export enum PublicationTarget {
  ConvertedResult = 'converted_result',
  OriginalResult = 'original_result',
  Both = 'both'
}

export type OptimizationSharing = {
  __typename?: 'OptimizationSharing';
  id: Scalars['ID']['output'];
  publicUrl?: Maybe<Scalars['String']['output']>;
  sharingUrl: Scalars['String']['output'];
  setup?: Maybe<OptimizationSharingSetup>;
  optimization: Optimization;
};

export type OptimizationSharingSetup = {
  __typename?: 'OptimizationSharingSetup';
  ambientLight?: Maybe<AmbientLightSetup>;
  objTransforms?: Maybe<TransformsSetup>;
  cameraTransforms?: Maybe<TransformsSetup>;
};

export type AmbientLightSetup = {
  __typename?: 'AmbientLightSetup';
  intensity?: Maybe<Scalars['Float']['output']>;
  color?: Maybe<Scalars['Int']['output']>;
};

export type TransformsSetup = {
  __typename?: 'TransformsSetup';
  position?: Maybe<Vector3>;
  rotation?: Maybe<Vector3>;
  scale?: Maybe<Vector3>;
};

export type Vector3 = {
  __typename?: 'Vector3';
  x?: Maybe<Scalars['Float']['output']>;
  y?: Maybe<Scalars['Float']['output']>;
  z?: Maybe<Scalars['Float']['output']>;
};

export type ProjectFilter = {
  and?: InputMaybe<Array<ProjectFilter>>;
  or?: InputMaybe<Array<ProjectFilter>>;
  id?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  customerId?: InputMaybe<StringFieldComparison>;
  conversionStatus?: InputMaybe<ConversionStatusFilterComparison>;
  optimizationsCount?: InputMaybe<IntFieldComparison>;
  lastActivityAt?: InputMaybe<DateFieldComparison>;
  iname?: InputMaybe<ProjectInameFilterComparison>;
};

export type ProjectSort = {
  field: ProjectSortFields;
  direction: SortDirection;
  nulls?: InputMaybe<SortNulls>;
};

export enum ProjectSortFields {
  Id = 'id',
  Name = 'name',
  CustomerId = 'customerId',
  ConversionStatus = 'conversionStatus',
  OptimizationsCount = 'optimizationsCount',
  LastActivityAt = 'lastActivityAt',
  Iname = 'iname'
}

export type ProjectConnection = {
  __typename?: 'ProjectConnection';
  pageInfo: PageInfo;
  edges: Array<ProjectEdge>;
};

export type ProjectEdge = {
  __typename?: 'ProjectEdge';
  node: Project;
  cursor: Scalars['ConnectionCursor']['output'];
};

export type Activity = {
  __typename?: 'Activity';
  id: Scalars['ID']['output'];
  type: ActivityType;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  projectId?: Maybe<Scalars['ID']['output']>;
  optimizationId?: Maybe<Scalars['ID']['output']>;
  project?: Maybe<Project>;
  optimization?: Maybe<Optimization>;
};

export enum ActivityType {
  ConversionReady = 'conversion_ready',
  ConversionError = 'conversion_error',
  OptimizationReady = 'optimization_ready',
  OptimizationError = 'optimization_error'
}

export type ActivityFilter = {
  and?: InputMaybe<Array<ActivityFilter>>;
  or?: InputMaybe<Array<ActivityFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  type?: InputMaybe<ActivityTypeFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  projectId?: InputMaybe<IdFilterComparison>;
  optimizationId?: InputMaybe<IdFilterComparison>;
};

export type ActivityTypeFilterComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  eq?: InputMaybe<ActivityType>;
  neq?: InputMaybe<ActivityType>;
  gt?: InputMaybe<ActivityType>;
  gte?: InputMaybe<ActivityType>;
  lt?: InputMaybe<ActivityType>;
  lte?: InputMaybe<ActivityType>;
  like?: InputMaybe<ActivityType>;
  notLike?: InputMaybe<ActivityType>;
  iLike?: InputMaybe<ActivityType>;
  notILike?: InputMaybe<ActivityType>;
  in?: InputMaybe<Array<ActivityType>>;
  notIn?: InputMaybe<Array<ActivityType>>;
};

export type ActivitySort = {
  field: ActivitySortFields;
  direction: SortDirection;
  nulls?: InputMaybe<SortNulls>;
};

export enum ActivitySortFields {
  Id = 'id',
  Type = 'type',
  CreatedAt = 'createdAt',
  ProjectId = 'projectId',
  OptimizationId = 'optimizationId'
}

export type ActivityConnection = {
  __typename?: 'ActivityConnection';
  pageInfo: PageInfo;
  edges: Array<ActivityEdge>;
};

export type ActivityEdge = {
  __typename?: 'ActivityEdge';
  node: Activity;
  cursor: Scalars['ConnectionCursor']['output'];
};

export type UserInfoForAtacOutput = {
  __typename?: 'UserInfoForATACOutput';
  fullName: Scalars['String']['output'];
  accepted?: Maybe<Scalars['DateTime']['output']>;
  marketing?: Maybe<Scalars['Boolean']['output']>;
  shareData?: Maybe<Scalars['Boolean']['output']>;
};

export type WalletOutput = {
  __typename?: 'WalletOutput';
  value: Scalars['Int']['output'];
  type?: Maybe<WalletType>;
  bytes_limit?: Maybe<Scalars['String']['output']>;
  customer_id?: Maybe<Scalars['String']['output']>;
  expires?: Maybe<Scalars['DateTime']['output']>;
  frozen?: Maybe<Scalars['Boolean']['output']>;
  verified_status?: Maybe<WalletVerifiedStatus>;
  reset_status?: Maybe<WalletResetStatus>;
};

export enum WalletType {
  Ordinary = 'ordinary',
  Plafond = 'plafond'
}

export enum WalletVerifiedStatus {
  NotYetVerified = 'not_yet_verified',
  NotVerified = 'not_verified',
  Ok = 'ok',
  Ko = 'ko'
}

export enum WalletResetStatus {
  RecentlyRenewed = 'recently_renewed',
  Midcourse = 'midcourse',
  Expiring = 'expiring',
  Expired = 'expired'
}

export type PerkSubscriptionStatusOutput = {
  __typename?: 'PerkSubscriptionStatusOutput';
  checkout_allowed?: Maybe<Scalars['Boolean']['output']>;
  active_subscription_end?: Maybe<Scalars['DateTime']['output']>;
  active_subscription_current_period_end?: Maybe<Scalars['DateTime']['output']>;
  future_schedule_start?: Maybe<Scalars['DateTime']['output']>;
  payment_method_available?: Maybe<Scalars['Boolean']['output']>;
  direct_checkout_immediate?: Maybe<Scalars['Boolean']['output']>;
  direct_checkout_future?: Maybe<Scalars['Boolean']['output']>;
  unsubscribe_immediate?: Maybe<Scalars['Boolean']['output']>;
  unsubscribe_future?: Maybe<Scalars['Boolean']['output']>;
};

export type Feedback = {
  __typename?: 'Feedback';
  id: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  shareData: Scalars['Boolean']['output'];
  projectId?: Maybe<Scalars['ID']['output']>;
  optimizationId?: Maybe<Scalars['ID']['output']>;
  project?: Maybe<Project>;
  optimization?: Maybe<Optimization>;
};

export type FeedbackFilter = {
  and?: InputMaybe<Array<FeedbackFilter>>;
  or?: InputMaybe<Array<FeedbackFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  shareData?: InputMaybe<BooleanFieldComparison>;
  projectId?: InputMaybe<IdFilterComparison>;
  optimizationId?: InputMaybe<IdFilterComparison>;
};

export type FeedbackSort = {
  field: FeedbackSortFields;
  direction: SortDirection;
  nulls?: InputMaybe<SortNulls>;
};

export enum FeedbackSortFields {
  Id = 'id',
  CreatedAt = 'createdAt',
  Description = 'description',
  ShareData = 'shareData',
  ProjectId = 'projectId',
  OptimizationId = 'optimizationId'
}

export type FeedbackConnection = {
  __typename?: 'FeedbackConnection';
  pageInfo: PageInfo;
  edges: Array<FeedbackEdge>;
};

export type FeedbackEdge = {
  __typename?: 'FeedbackEdge';
  node: Feedback;
  cursor: Scalars['ConnectionCursor']['output'];
};

export type OptimizationTemplate = {
  __typename?: 'OptimizationTemplate';
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  lastActivityAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  iname?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<Customer>;
  optimizationTemplateItems?: Maybe<Array<OptimizationTemplateItem>>;
};


export type OptimizationTemplateOptimizationTemplateItemsArgs = {
  filter?: InputMaybe<OptimizationTemplateItemFilter>;
  sorting?: InputMaybe<Array<OptimizationTemplateItemSort>>;
};

export type OptimizationTemplateItemFilter = {
  and?: InputMaybe<Array<OptimizationTemplateItemFilter>>;
  or?: InputMaybe<Array<OptimizationTemplateItemFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  optimizationTemplate?: InputMaybe<OptimizationTemplateItemFilterOptimizationTemplateFilter>;
};

export type OptimizationTemplateItemFilterOptimizationTemplateFilter = {
  and?: InputMaybe<Array<OptimizationTemplateItemFilterOptimizationTemplateFilter>>;
  or?: InputMaybe<Array<OptimizationTemplateItemFilterOptimizationTemplateFilter>>;
  id?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  customerId?: InputMaybe<StringFieldComparison>;
  lastActivityAt?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  iname?: InputMaybe<OptimizationTemplateInameFilterComparison>;
};

export type OptimizationTemplateInameFilterComparison = {
  like?: InputMaybe<Scalars['String']['input']>;
};

export type OptimizationTemplateItemSort = {
  field: OptimizationTemplateItemSortFields;
  direction: SortDirection;
  nulls?: InputMaybe<SortNulls>;
};

export enum OptimizationTemplateItemSortFields {
  Id = 'id',
  Name = 'name',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type OptimizationTemplateItem = {
  __typename?: 'OptimizationTemplateItem';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  outputFormat: OptimizationOutputFormat;
  outputFormatOptions?: Maybe<OptimizationOutputFormatOptions>;
  preset?: Maybe<OptimizationPreset>;
  params?: Maybe<OptimizationParams>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  optimizationTemplate: OptimizationTemplate;
};

export type OptimizationTemplateFilter = {
  and?: InputMaybe<Array<OptimizationTemplateFilter>>;
  or?: InputMaybe<Array<OptimizationTemplateFilter>>;
  id?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  customerId?: InputMaybe<StringFieldComparison>;
  lastActivityAt?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  iname?: InputMaybe<OptimizationTemplateInameFilterComparison>;
};

export type OptimizationTemplateSort = {
  field: OptimizationTemplateSortFields;
  direction: SortDirection;
  nulls?: InputMaybe<SortNulls>;
};

export enum OptimizationTemplateSortFields {
  Id = 'id',
  Name = 'name',
  Description = 'description',
  CustomerId = 'customerId',
  LastActivityAt = 'lastActivityAt',
  CreatedAt = 'createdAt',
  Iname = 'iname'
}

export type OptimizationTemplateConnection = {
  __typename?: 'OptimizationTemplateConnection';
  pageInfo: PageInfo;
  edges: Array<OptimizationTemplateEdge>;
};

export type OptimizationTemplateEdge = {
  __typename?: 'OptimizationTemplateEdge';
  node: OptimizationTemplate;
  cursor: Scalars['ConnectionCursor']['output'];
};

export type OptimizationTemplateItemConnection = {
  __typename?: 'OptimizationTemplateItemConnection';
  pageInfo: PageInfo;
  edges: Array<OptimizationTemplateItemEdge>;
};

export type OptimizationTemplateItemEdge = {
  __typename?: 'OptimizationTemplateItemEdge';
  node: OptimizationTemplateItem;
  cursor: Scalars['ConnectionCursor']['output'];
};

export type CustomerFilter = {
  and?: InputMaybe<Array<CustomerFilter>>;
  or?: InputMaybe<Array<CustomerFilter>>;
  id?: InputMaybe<StringFieldComparison>;
  user?: InputMaybe<CustomerFilterCustomerUserDtoFilter>;
};

export type CustomerFilterCustomerUserDtoFilter = {
  and?: InputMaybe<Array<CustomerFilterCustomerUserDtoFilter>>;
  or?: InputMaybe<Array<CustomerFilterCustomerUserDtoFilter>>;
  id?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  emailValidated?: InputMaybe<BooleanFieldComparison>;
  expires?: InputMaybe<DateFieldComparison>;
  fullName?: InputMaybe<StringFieldComparison>;
  organization?: InputMaybe<StringFieldComparison>;
  phone?: InputMaybe<StringFieldComparison>;
  enabled?: InputMaybe<BooleanFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  iFullName?: InputMaybe<StringFieldComparison>;
};

export type CustomerSort = {
  field: CustomerSortFields;
  direction: SortDirection;
  nulls?: InputMaybe<SortNulls>;
};

export enum CustomerSortFields {
  Id = 'id'
}

export type CustomerConnection = {
  __typename?: 'CustomerConnection';
  pageInfo: PageInfo;
  edges: Array<CustomerEdge>;
};

export type CustomerEdge = {
  __typename?: 'CustomerEdge';
  node: Customer;
  cursor: Scalars['ConnectionCursor']['output'];
};

export type OptimizationConnection = {
  __typename?: 'OptimizationConnection';
  pageInfo: PageInfo;
  edges: Array<OptimizationEdge>;
};

export type OptimizationEdge = {
  __typename?: 'OptimizationEdge';
  node: Optimization;
  cursor: Scalars['ConnectionCursor']['output'];
};

export type Log = {
  __typename?: 'Log';
  id: Scalars['String']['output'];
  type: LogType;
  payload: Scalars['JSONObject']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customer?: Maybe<Customer>;
};

export enum LogType {
  Custom = 'custom',
  MarketingConsent = 'marketing_consent',
  ProjectCreated = 'project_created',
  ProjectUpdated = 'project_updated',
  ProjectDeleted = 'project_deleted',
  OptimizationCreated = 'optimization_created',
  WalletDecremented = 'wallet_decremented',
  WalletCreated = 'wallet_created',
  WalletIncremented = 'wallet_incremented',
  CustomerPerkAdded = 'customer_perk_added',
  CustomerPerksDeleted = 'customer_perks_deleted',
  WalletReset = 'wallet_reset'
}

export type LogFilter = {
  and?: InputMaybe<Array<LogFilter>>;
  or?: InputMaybe<Array<LogFilter>>;
  id?: InputMaybe<StringFieldComparison>;
  type?: InputMaybe<LogTypeFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  customer?: InputMaybe<LogFilterCustomerFilter>;
};

export type LogTypeFilterComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  eq?: InputMaybe<LogType>;
  neq?: InputMaybe<LogType>;
  gt?: InputMaybe<LogType>;
  gte?: InputMaybe<LogType>;
  lt?: InputMaybe<LogType>;
  lte?: InputMaybe<LogType>;
  like?: InputMaybe<LogType>;
  notLike?: InputMaybe<LogType>;
  iLike?: InputMaybe<LogType>;
  notILike?: InputMaybe<LogType>;
  in?: InputMaybe<Array<LogType>>;
  notIn?: InputMaybe<Array<LogType>>;
};

export type LogFilterCustomerFilter = {
  and?: InputMaybe<Array<LogFilterCustomerFilter>>;
  or?: InputMaybe<Array<LogFilterCustomerFilter>>;
  id?: InputMaybe<StringFieldComparison>;
};

export type LogSort = {
  field: LogSortFields;
  direction: SortDirection;
  nulls?: InputMaybe<SortNulls>;
};

export enum LogSortFields {
  Id = 'id',
  Type = 'type',
  CreatedAt = 'createdAt'
}

export type LogConnection = {
  __typename?: 'LogConnection';
  pageInfo: PageInfo;
  edges: Array<LogEdge>;
};

export type LogEdge = {
  __typename?: 'LogEdge';
  node: Log;
  cursor: Scalars['ConnectionCursor']['output'];
};

export type CustomerPerkConnection = {
  __typename?: 'CustomerPerkConnection';
  pageInfo: PageInfo;
  edges: Array<CustomerPerkEdge>;
};

export type CustomerPerkEdge = {
  __typename?: 'CustomerPerkEdge';
  node: CustomerPerk;
  cursor: Scalars['ConnectionCursor']['output'];
};

export type PerkFilter = {
  and?: InputMaybe<Array<PerkFilter>>;
  or?: InputMaybe<Array<PerkFilter>>;
  id?: InputMaybe<StringFieldComparison>;
  code?: InputMaybe<StringFieldComparison>;
  reset_value?: InputMaybe<IntFieldComparison>;
  regularity?: InputMaybe<RegularityTypeFilterComparison>;
  regularity_value?: InputMaybe<IntFieldComparison>;
  offer?: InputMaybe<BooleanFieldComparison>;
  price_description_without_offer?: InputMaybe<StringFieldComparison>;
  order?: InputMaybe<IntFieldComparison>;
  short_description?: InputMaybe<StringFieldComparison>;
  complete_description?: InputMaybe<StringFieldComparison>;
  price_description?: InputMaybe<StringFieldComparison>;
  priority_description?: InputMaybe<StringFieldComparison>;
  trial?: InputMaybe<BooleanFieldComparison>;
  subscription?: InputMaybe<BooleanFieldComparison>;
};

export type RegularityTypeFilterComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  eq?: InputMaybe<RegularityType>;
  neq?: InputMaybe<RegularityType>;
  gt?: InputMaybe<RegularityType>;
  gte?: InputMaybe<RegularityType>;
  lt?: InputMaybe<RegularityType>;
  lte?: InputMaybe<RegularityType>;
  like?: InputMaybe<RegularityType>;
  notLike?: InputMaybe<RegularityType>;
  iLike?: InputMaybe<RegularityType>;
  notILike?: InputMaybe<RegularityType>;
  in?: InputMaybe<Array<RegularityType>>;
  notIn?: InputMaybe<Array<RegularityType>>;
};

export type PerkSort = {
  field: PerkSortFields;
  direction: SortDirection;
  nulls?: InputMaybe<SortNulls>;
};

export enum PerkSortFields {
  Id = 'id',
  Code = 'code',
  ResetValue = 'reset_value',
  Regularity = 'regularity',
  RegularityValue = 'regularity_value',
  Offer = 'offer',
  PriceDescriptionWithoutOffer = 'price_description_without_offer',
  Order = 'order',
  ShortDescription = 'short_description',
  CompleteDescription = 'complete_description',
  PriceDescription = 'price_description',
  PriorityDescription = 'priority_description',
  Trial = 'trial',
  Subscription = 'subscription'
}

export type PerkConnection = {
  __typename?: 'PerkConnection';
  pageInfo: PageInfo;
  edges: Array<PerkEdge>;
};

export type PerkEdge = {
  __typename?: 'PerkEdge';
  node: Perk;
  cursor: Scalars['ConnectionCursor']['output'];
};

export type OptimizationSharingFilter = {
  and?: InputMaybe<Array<OptimizationSharingFilter>>;
  or?: InputMaybe<Array<OptimizationSharingFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  optimization?: InputMaybe<OptimizationSharingFilterOptimizationFilter>;
};

export type OptimizationSharingFilterOptimizationFilter = {
  and?: InputMaybe<Array<OptimizationSharingFilterOptimizationFilter>>;
  or?: InputMaybe<Array<OptimizationSharingFilterOptimizationFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<OptimizationStatusFilterComparison>;
  type?: InputMaybe<OptimizationTypeFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  lastActivityAt?: InputMaybe<DateFieldComparison>;
};

export type OptimizationSharingSort = {
  field: OptimizationSharingSortFields;
  direction: SortDirection;
  nulls?: InputMaybe<SortNulls>;
};

export enum OptimizationSharingSortFields {
  Id = 'id'
}

export type OptimizationSharingConnection = {
  __typename?: 'OptimizationSharingConnection';
  pageInfo: PageInfo;
  edges: Array<OptimizationSharingEdge>;
};

export type OptimizationSharingEdge = {
  __typename?: 'OptimizationSharingEdge';
  node: OptimizationSharing;
  cursor: Scalars['ConnectionCursor']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  login: LoginOutput;
  signup: SignupOutput;
  validateEmail: EmailValidationOutput;
  refreshToken: RefreshOutput;
  changePassword: ChangePasswordOutput;
  resetPasswordRequest: ResetPasswordRequestOutput;
  resetPassword: ResetPasswordOutput;
  inviteUser: UserInviteOutput;
  updateOneUser: User;
  updateAppSettings: AppSettings;
  uploadObjectModel: ObjectModel;
  uploadPicture: Picture;
  setCustomerOnProject: Project;
  setObjectModelOnProject: Project;
  setObjectModelObjOnProject: Project;
  setObjectModelOriginalOnProject: Project;
  addOptimizationsToProject: Project;
  removeCustomerFromProject: Project;
  removeObjectModelFromProject: Project;
  removeObjectModelObjFromProject: Project;
  removeObjectModelOriginalFromProject: Project;
  removeOptimizationsFromProject: Project;
  createOneProject: Project;
  updateOneProject: Project;
  deleteOneProject: ProjectDeleteResponse;
  deleteManyProjects: DeleteManyResponse;
  acceptTermsAndConditions: AcceptTermsAndConditionsOutput;
  updateCustomerWallet: WalletOutput;
  setCustomerPerks: Array<CustomerPerkOutput>;
  generateCheckoutUrl: GenerateCheckoutUrlOutput;
  perkCheckout: PerkCheckoutOutput;
  unsubscribeToPerk: UnsubscribeToPerkOutput;
  restorePurchases: RestorePurchasesOutput;
  sendOptimizationFeedback: OptimizationFeedbackSentDto;
  createOptimizationsFromTemplate: Array<Optimization>;
  createOneFeedback: Feedback;
  deleteOneFeedback: FeedbackDeleteResponse;
  createOneOptimizationTemplate: OptimizationTemplate;
  updateOneOptimizationTemplate: OptimizationTemplate;
  deleteOneOptimizationTemplate: OptimizationTemplateDeleteResponse;
  createOneOptimizationTemplateItem: OptimizationTemplateItem;
  updateOneOptimizationTemplateItem: OptimizationTemplateItem;
  deleteOneOptimizationTemplateItem: OptimizationTemplateItemDeleteResponse;
  addCustomer_perksToCustomer: Customer;
  removeCustomer_perksFromCustomer: Customer;
  updateOneCustomer: Customer;
  deleteOneCustomer: CustomerDeleteResponse;
  deleteManyCustomers: DeleteManyResponse;
  setProjectOnOptimization: Optimization;
  setRelatedToOnOptimization: Optimization;
  createOneOptimization: Optimization;
  deleteOneOptimization: OptimizationDeleteResponse;
  setCustomerOnLog: Log;
  setPerkOnCustomerPerk: CustomerPerk;
  setOptimizationOnOptimizationSharing: OptimizationSharing;
  createOneOptimizationSharing: OptimizationSharing;
  updateOneOptimizationSharing: OptimizationSharing;
  deleteOneOptimizationSharing: OptimizationSharingDeleteResponse;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationSignupArgs = {
  input: SignupInput;
};


export type MutationValidateEmailArgs = {
  input: EmailValidationInput;
};


export type MutationRefreshTokenArgs = {
  input: RefreshInput;
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationResetPasswordRequestArgs = {
  input: ResetPasswordRequestInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationInviteUserArgs = {
  input: UserInviteInput;
};


export type MutationUpdateOneUserArgs = {
  input: UpdateOneUserInput;
};


export type MutationUpdateAppSettingsArgs = {
  input: AppSettingsInputDto;
};


export type MutationUploadObjectModelArgs = {
  input: FileUploadInput;
};


export type MutationUploadPictureArgs = {
  input: FileUploadInput;
};


export type MutationSetCustomerOnProjectArgs = {
  input: RelationInput;
};


export type MutationSetObjectModelOnProjectArgs = {
  input: RelationInput;
};


export type MutationSetObjectModelObjOnProjectArgs = {
  input: RelationInput;
};


export type MutationSetObjectModelOriginalOnProjectArgs = {
  input: RelationInput;
};


export type MutationAddOptimizationsToProjectArgs = {
  input: RelationsInput;
};


export type MutationRemoveCustomerFromProjectArgs = {
  input: RelationInput;
};


export type MutationRemoveObjectModelFromProjectArgs = {
  input: RelationInput;
};


export type MutationRemoveObjectModelObjFromProjectArgs = {
  input: RelationInput;
};


export type MutationRemoveObjectModelOriginalFromProjectArgs = {
  input: RelationInput;
};


export type MutationRemoveOptimizationsFromProjectArgs = {
  input: RelationsInput;
};


export type MutationCreateOneProjectArgs = {
  input: CreateOneProjectInput;
};


export type MutationUpdateOneProjectArgs = {
  input: UpdateOneProjectInput;
};


export type MutationDeleteOneProjectArgs = {
  input: DeleteOneInput;
};


export type MutationDeleteManyProjectsArgs = {
  input: DeleteManyProjectsInput;
};


export type MutationAcceptTermsAndConditionsArgs = {
  input: AcceptTermsAndConditionsInput;
};


export type MutationUpdateCustomerWalletArgs = {
  input: WalletInput;
};


export type MutationSetCustomerPerksArgs = {
  input: Array<CustomerPerkInput>;
};


export type MutationGenerateCheckoutUrlArgs = {
  input: GenerateCheckoutUrlInput;
};


export type MutationPerkCheckoutArgs = {
  input: PerkCheckoutInput;
};


export type MutationUnsubscribeToPerkArgs = {
  input: UnsubscribeToPerkInput;
};


export type MutationRestorePurchasesArgs = {
  input: RestorePurchasesInput;
};


export type MutationSendOptimizationFeedbackArgs = {
  input: SendOptimizationFeedbackDto;
};


export type MutationCreateOptimizationsFromTemplateArgs = {
  input: OptimizationFromTemplateInput;
};


export type MutationCreateOneFeedbackArgs = {
  input: CreateOneFeedbackInput;
};


export type MutationDeleteOneFeedbackArgs = {
  input: DeleteOneInput;
};


export type MutationCreateOneOptimizationTemplateArgs = {
  input: CreateOneOptimizationTemplateInput;
};


export type MutationUpdateOneOptimizationTemplateArgs = {
  input: UpdateOneOptimizationTemplateInput;
};


export type MutationDeleteOneOptimizationTemplateArgs = {
  input: DeleteOneInput;
};


export type MutationCreateOneOptimizationTemplateItemArgs = {
  input: CreateOneOptimizationTemplateItemInput;
};


export type MutationUpdateOneOptimizationTemplateItemArgs = {
  input: UpdateOneOptimizationTemplateItemInput;
};


export type MutationDeleteOneOptimizationTemplateItemArgs = {
  input: DeleteOneInput;
};


export type MutationAddCustomer_PerksToCustomerArgs = {
  input: RelationsInput;
};


export type MutationRemoveCustomer_PerksFromCustomerArgs = {
  input: RelationsInput;
};


export type MutationUpdateOneCustomerArgs = {
  input: UpdateOneCustomerInput;
};


export type MutationDeleteOneCustomerArgs = {
  input: DeleteOneInput;
};


export type MutationDeleteManyCustomersArgs = {
  input: DeleteManyCustomersInput;
};


export type MutationSetProjectOnOptimizationArgs = {
  input: RelationInput;
};


export type MutationSetRelatedToOnOptimizationArgs = {
  input: RelationInput;
};


export type MutationCreateOneOptimizationArgs = {
  input: CreateOneOptimizationInput;
};


export type MutationDeleteOneOptimizationArgs = {
  input: DeleteOneInput;
};


export type MutationSetCustomerOnLogArgs = {
  input: RelationInput;
};


export type MutationSetPerkOnCustomerPerkArgs = {
  input: RelationInput;
};


export type MutationSetOptimizationOnOptimizationSharingArgs = {
  input: RelationInput;
};


export type MutationCreateOneOptimizationSharingArgs = {
  input: CreateOneOptimizationSharingInput;
};


export type MutationUpdateOneOptimizationSharingArgs = {
  input: UpdateOneOptimizationSharingInput;
};


export type MutationDeleteOneOptimizationSharingArgs = {
  input: DeleteOneInput;
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoginOutput = {
  __typename?: 'LoginOutput';
  token: Scalars['String']['output'];
  refreshToken?: Maybe<Scalars['String']['output']>;
};

export type SignupInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  organization: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type SignupOutput = {
  __typename?: 'SignupOutput';
  status: SignupOutputStatus;
};

export enum SignupOutputStatus {
  Ok = 'ok',
  Error = 'error'
}

export type EmailValidationInput = {
  token: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
};

export type EmailValidationOutput = {
  __typename?: 'EmailValidationOutput';
  status: EmailValidationStatus;
};

export enum EmailValidationStatus {
  Ok = 'ok',
  Error = 'error'
}

export type RefreshInput = {
  refreshToken: Scalars['String']['input'];
};

export type RefreshOutput = {
  __typename?: 'RefreshOutput';
  token: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
};

export type ChangePasswordInput = {
  password: Scalars['String']['input'];
};

export type ChangePasswordOutput = {
  __typename?: 'ChangePasswordOutput';
  _dummy?: Maybe<Scalars['Boolean']['output']>;
};

export type ResetPasswordRequestInput = {
  email: Scalars['String']['input'];
};

export type ResetPasswordRequestOutput = {
  __typename?: 'ResetPasswordRequestOutput';
  status: ResetPasswordStatus;
};

export enum ResetPasswordStatus {
  Ok = 'ok',
  Error = 'error'
}

export type ResetPasswordInput = {
  token: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};

export type ResetPasswordOutput = {
  __typename?: 'ResetPasswordOutput';
  status: ResetPasswordStatus;
};

export type UserInviteInput = {
  email: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  organization: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  role: UserRole;
  expires?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserInviteOutput = {
  __typename?: 'UserInviteOutput';
  status: UserInviteStatus;
};

export enum UserInviteStatus {
  Ok = 'ok',
  Error = 'error'
}

export type UpdateOneUserInput = {
  id: Scalars['ID']['input'];
  update: UserWriteDto;
};

export type UserWriteDto = {
  fullName?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  emailValidated?: InputMaybe<Scalars['Boolean']['input']>;
  expires?: InputMaybe<Scalars['DateTime']['input']>;
  isAdmittedToBeta?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AppSettingsInputDto = {
  isClosedBeta: Scalars['Boolean']['input'];
  analyticsAndImprovements?: InputMaybe<Scalars['Boolean']['input']>;
  tacData?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FileUploadInput = {
  file: Scalars['Upload']['input'];
  relatedTo?: InputMaybe<Scalars['Upload']['input']>;
};

export type RelationInput = {
  id: Scalars['ID']['input'];
  relationId: Scalars['ID']['input'];
};

export type RelationsInput = {
  id: Scalars['ID']['input'];
  relationIds: Array<Scalars['ID']['input']>;
};

export type CreateOneProjectInput = {
  project: ProjectCreateDto;
};

export type ProjectCreateDto = {
  name?: InputMaybe<Scalars['String']['input']>;
  objectModel: RelationId;
  customer?: InputMaybe<RelationId>;
  metadata?: InputMaybe<ProjectMetadataInput>;
  params?: InputMaybe<ProjectParamsInput>;
};

export type RelationId = {
  id: Scalars['ID']['input'];
};

export type ProjectMetadataInput = {
  owner?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectParamsInput = {
  linearDeflection?: InputMaybe<Scalars['Float']['input']>;
  angularDeflection?: InputMaybe<Scalars['Float']['input']>;
  yup?: InputMaybe<Scalars['Boolean']['input']>;
  uv?: InputMaybe<Scalars['Boolean']['input']>;
  mergePrimitives?: InputMaybe<Scalars['Boolean']['input']>;
  precision?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateOneProjectInput = {
  id: Scalars['ID']['input'];
  update: ProjectUpdateDto;
};

export type ProjectUpdateDto = {
  name?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<ProjectParamsInput>;
};

export type DeleteOneInput = {
  id: Scalars['ID']['input'];
};

export type ProjectDeleteResponse = {
  __typename?: 'ProjectDeleteResponse';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  conversionStatus?: Maybe<ConversionStatus>;
  optimizationsCount?: Maybe<Scalars['Int']['output']>;
  conversionError?: Maybe<Scalars['String']['output']>;
  lastActivityAt?: Maybe<Scalars['DateTime']['output']>;
  allowsNormalsBaking?: Maybe<Scalars['Boolean']['output']>;
  allowsVertexMaskPolygonReduction?: Maybe<Scalars['Boolean']['output']>;
  allowsTextureResize?: Maybe<Scalars['Boolean']['output']>;
  iname?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<ProjectMetadata>;
  params?: Maybe<ProjectParams>;
  info?: Maybe<Info>;
};

export type DeleteManyProjectsInput = {
  filter: ProjectDeleteFilter;
};

export type ProjectDeleteFilter = {
  and?: InputMaybe<Array<ProjectDeleteFilter>>;
  or?: InputMaybe<Array<ProjectDeleteFilter>>;
  id?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  customerId?: InputMaybe<StringFieldComparison>;
  conversionStatus?: InputMaybe<ConversionStatusFilterComparison>;
  optimizationsCount?: InputMaybe<IntFieldComparison>;
  lastActivityAt?: InputMaybe<DateFieldComparison>;
  iname?: InputMaybe<ProjectInameFilterComparison>;
};

export type DeleteManyResponse = {
  __typename?: 'DeleteManyResponse';
  deletedCount: Scalars['Int']['output'];
};

export type AcceptTermsAndConditionsInput = {
  accept: Scalars['String']['input'];
  marketing?: InputMaybe<Scalars['Boolean']['input']>;
  shareData?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AcceptTermsAndConditionsOutput = {
  __typename?: 'AcceptTermsAndConditionsOutput';
  status: Scalars['Boolean']['output'];
};

export type WalletInput = {
  customer_id: Scalars['String']['input'];
  value?: InputMaybe<Scalars['Int']['input']>;
  latest_reset_at?: InputMaybe<Scalars['DateTime']['input']>;
  bytes_limit?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerPerkInput = {
  customer_id: Scalars['String']['input'];
  code: Scalars['String']['input'];
  valid_since?: InputMaybe<Scalars['DateTime']['input']>;
  valid_until?: InputMaybe<Scalars['DateTime']['input']>;
  remove_after_expiration?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomerPerkOutput = {
  __typename?: 'CustomerPerkOutput';
  customer_id: Scalars['String']['output'];
  code: Scalars['String']['output'];
  valid_since: Scalars['DateTime']['output'];
  valid_until?: Maybe<Scalars['DateTime']['output']>;
  remove_after_expiration?: Maybe<Scalars['Boolean']['output']>;
};

export type GenerateCheckoutUrlInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  subscription: Scalars['Boolean']['input'];
};

export type GenerateCheckoutUrlOutput = {
  __typename?: 'GenerateCheckoutUrlOutput';
  checkout_url?: Maybe<Scalars['String']['output']>;
};

export type PerkCheckoutInput = {
  code: Scalars['String']['input'];
  immediately: Scalars['Boolean']['input'];
};

export type PerkCheckoutOutput = {
  __typename?: 'PerkCheckoutOutput';
  status: Scalars['String']['output'];
};

export type UnsubscribeToPerkInput = {
  immediately: Scalars['Boolean']['input'];
};

export type UnsubscribeToPerkOutput = {
  __typename?: 'UnsubscribeToPerkOutput';
  status: Scalars['String']['output'];
};

export type RestorePurchasesInput = {
  customerId: Scalars['String']['input'];
};

export type RestorePurchasesOutput = {
  __typename?: 'RestorePurchasesOutput';
  status: Scalars['String']['output'];
};

export type SendOptimizationFeedbackDto = {
  optimization_id: Scalars['String']['input'];
  feedback?: InputMaybe<OptimizationFeedback>;
};

export type OptimizationFeedbackSentDto = {
  __typename?: 'OptimizationFeedbackSentDto';
  optimization_id: Scalars['String']['output'];
  feedback?: Maybe<OptimizationFeedback>;
};

export type OptimizationFromTemplateInput = {
  name: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  templateId: Scalars['String']['input'];
};

export type CreateOneFeedbackInput = {
  feedback: CreateFeedbackDto;
};

export type CreateFeedbackDto = {
  project?: InputMaybe<RelationId>;
  optimization?: InputMaybe<RelationId>;
  shareData: Scalars['Boolean']['input'];
  description: Scalars['String']['input'];
};

export type FeedbackDeleteResponse = {
  __typename?: 'FeedbackDeleteResponse';
  id?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  shareData?: Maybe<Scalars['Boolean']['output']>;
  projectId?: Maybe<Scalars['ID']['output']>;
  optimizationId?: Maybe<Scalars['ID']['output']>;
};

export type CreateOneOptimizationTemplateInput = {
  optimizationTemplate: OptimizationTemplateCreateDto;
};

export type OptimizationTemplateCreateDto = {
  name?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  customer?: InputMaybe<RelationId>;
};

export type UpdateOneOptimizationTemplateInput = {
  id: Scalars['ID']['input'];
  update: OptimizationTemplateUpdateDto;
};

export type OptimizationTemplateUpdateDto = {
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
};

export type OptimizationTemplateDeleteResponse = {
  __typename?: 'OptimizationTemplateDeleteResponse';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  lastActivityAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  iname?: Maybe<Scalars['String']['output']>;
};

export type CreateOneOptimizationTemplateItemInput = {
  optimizationTemplateItem: CreateOptimizationTemplateItemDto;
};

export type CreateOptimizationTemplateItemDto = {
  name: Scalars['String']['input'];
  outputFormat: OptimizationOutputFormat;
  outputFormatOptions?: InputMaybe<OptimizationOutputFormatOptionsInput>;
  preset?: InputMaybe<OptimizationPreset>;
  optimizationTemplate: RelationId;
  params?: InputMaybe<OptimizationParamsInput>;
};

export type OptimizationOutputFormatOptionsInput = {
  format_glb?: InputMaybe<FormatGltfOptionsInput>;
  format_gltf?: InputMaybe<FormatGltfOptionsInput>;
};

export type FormatGltfOptionsInput = {
  export_draco_mesh_compression_enable: Scalars['Boolean']['input'];
  export_format?: InputMaybe<GltfExportFormats>;
};

export type OptimizationParamsInput = {
  face_reduction?: InputMaybe<Scalars['Float']['input']>;
  feature_importance?: InputMaybe<Scalars['Int']['input']>;
  feature_area?: InputMaybe<Scalars['Float']['input']>;
  uv_seam_importance?: InputMaybe<Scalars['Int']['input']>;
  preserve_boundary_edges?: InputMaybe<Scalars['Int']['input']>;
  preserve_hard_edges?: InputMaybe<Scalars['Boolean']['input']>;
  preserve_smooth_edges?: InputMaybe<Scalars['Boolean']['input']>;
  retexture?: InputMaybe<Scalars['Boolean']['input']>;
  merge_duplicated_uv?: InputMaybe<Scalars['Boolean']['input']>;
  merge_gltf_primitives?: InputMaybe<Scalars['Boolean']['input']>;
  join_meshes?: InputMaybe<Scalars['Boolean']['input']>;
  remove_isolated_vertices?: InputMaybe<Scalars['Boolean']['input']>;
  remove_non_manifold_faces?: InputMaybe<Scalars['Boolean']['input']>;
  remove_duplicated_faces?: InputMaybe<Scalars['Boolean']['input']>;
  remove_duplicated_boundary_vertices?: InputMaybe<Scalars['Boolean']['input']>;
  remove_degenerate_faces?: InputMaybe<Scalars['Boolean']['input']>;
  remove_hidden_objects?: InputMaybe<Scalars['Boolean']['input']>;
  project_normals?: InputMaybe<Scalars['Boolean']['input']>;
  use_vertex_mask?: InputMaybe<Scalars['Boolean']['input']>;
  resize_images?: InputMaybe<Scalars['Int']['input']>;
  normals_weighting?: InputMaybe<Scalars['Int']['input']>;
  contrast?: InputMaybe<Scalars['Float']['input']>;
  joined_simplification?: InputMaybe<Scalars['Boolean']['input']>;
  normals_scaling?: InputMaybe<Scalars['Float']['input']>;
  minimum_face_number?: InputMaybe<Scalars['Int']['input']>;
  remove_meshes_by_size?: InputMaybe<Scalars['Float']['input']>;
  remove_meshes_by_count?: InputMaybe<Scalars['Int']['input']>;
  textures_to_jpeg?: InputMaybe<Scalars['Boolean']['input']>;
  adaptiveOptimization?: InputMaybe<Scalars['Boolean']['input']>;
  maxMeshDeviation?: InputMaybe<Scalars['Int']['input']>;
  minAngleToPreserve?: InputMaybe<Scalars['Int']['input']>;
  preserveDetails?: InputMaybe<Scalars['Boolean']['input']>;
  optimizeForQuality?: InputMaybe<Scalars['Boolean']['input']>;
  desiredElemPercentage?: InputMaybe<Scalars['Float']['input']>;
  considerPhysicalMetric?: InputMaybe<Scalars['Boolean']['input']>;
  preserveLocalAreas?: InputMaybe<Scalars['Boolean']['input']>;
  preserveLocalConvexity?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateOneOptimizationTemplateItemInput = {
  id: Scalars['ID']['input'];
  update: CreateOptimizationTemplateItemDto;
};

export type OptimizationTemplateItemDeleteResponse = {
  __typename?: 'OptimizationTemplateItemDeleteResponse';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  outputFormat?: Maybe<OptimizationOutputFormat>;
  outputFormatOptions?: Maybe<OptimizationOutputFormatOptions>;
  preset?: Maybe<OptimizationPreset>;
  params?: Maybe<OptimizationParams>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UpdateOneCustomerInput = {
  id: Scalars['ID']['input'];
  update: CustomerWriteDto;
};

export type CustomerWriteDto = {
  metadata?: InputMaybe<CustomerMetadataInput>;
  shareData?: InputMaybe<Scalars['Boolean']['input']>;
  user?: InputMaybe<CustomerUserWriteDto>;
};

export type CustomerMetadataInput = {
  welcomePopupSeen?: InputMaybe<Scalars['Boolean']['input']>;
  preferredTheme?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerUserWriteDto = {
  fullName?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerDeleteResponse = {
  __typename?: 'CustomerDeleteResponse';
  id?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<CustomerMetadata>;
  user?: Maybe<CustomerUserDto>;
  projectCount?: Maybe<Scalars['Int']['output']>;
  marketingConsent?: Maybe<Scalars['Boolean']['output']>;
  shareData?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteManyCustomersInput = {
  filter: CustomerDeleteFilter;
};

export type CustomerDeleteFilter = {
  and?: InputMaybe<Array<CustomerDeleteFilter>>;
  or?: InputMaybe<Array<CustomerDeleteFilter>>;
  id?: InputMaybe<StringFieldComparison>;
};

export type CreateOneOptimizationInput = {
  optimization: CreateOptimizationDto;
};

export type CreateOptimizationDto = {
  name: Scalars['String']['input'];
  outputFormat: OptimizationOutputFormat;
  outputFormatOptions?: InputMaybe<OptimizationOutputFormatOptionsInput>;
  preset?: InputMaybe<OptimizationPreset>;
  project: RelationId;
  params?: InputMaybe<OptimizationParamsInput>;
  nbparams?: InputMaybe<OptimizationNormalBakingParamsInput>;
  relatedTo?: InputMaybe<RelationId>;
  publication?: InputMaybe<PublicationDto>;
};

export type OptimizationNormalBakingParamsInput = {
  opacity?: InputMaybe<Scalars['Float']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  normal_format?: InputMaybe<Scalars['Int']['input']>;
  image_format?: InputMaybe<NormalsBakingFormat>;
};

export type PublicationDto = {
  mode: PublicationMode;
  url: Scalars['String']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
  target?: InputMaybe<Scalars['String']['input']>;
  verify_ssl?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OptimizationDeleteResponse = {
  __typename?: 'OptimizationDeleteResponse';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<OptimizationStatus>;
  type?: Maybe<OptimizationType>;
  logs?: Maybe<Scalars['String']['output']>;
  outputFormat?: Maybe<OptimizationOutputFormat>;
  outputFormatOptions?: Maybe<OptimizationOutputFormatOptions>;
  preset?: Maybe<OptimizationPreset>;
  relatedTo?: Maybe<Optimization>;
  feedback?: Maybe<OptimizationFeedback>;
  params?: Maybe<OptimizationParams>;
  nbparams?: Maybe<OptimizationNormalBakingParams>;
  objectModelResult?: Maybe<ObjectModel>;
  objectModelResultObj?: Maybe<ObjectModel>;
  objectModelResultConverted?: Maybe<ObjectModel>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  lastActivityAt?: Maybe<Scalars['DateTime']['output']>;
  publication?: Maybe<Publication>;
  sharing?: Maybe<OptimizationSharing>;
  info?: Maybe<Info>;
};

export type CreateOneOptimizationSharingInput = {
  optimizationSharing: OptimizationSharingInput;
};

export type OptimizationSharingInput = {
  optimization: RelationId;
  setup?: InputMaybe<OptimizationSharingSetupInput>;
};

export type OptimizationSharingSetupInput = {
  ambientLight?: InputMaybe<AmbientLightSetupInput>;
  objTransforms?: InputMaybe<TransformsSetupInput>;
  cameraTransforms?: InputMaybe<TransformsSetupInput>;
};

export type AmbientLightSetupInput = {
  intensity?: InputMaybe<Scalars['Float']['input']>;
  color?: InputMaybe<Scalars['Int']['input']>;
};

export type TransformsSetupInput = {
  position?: InputMaybe<Vector3Input>;
  rotation?: InputMaybe<Vector3Input>;
  scale?: InputMaybe<Vector3Input>;
};

export type Vector3Input = {
  x?: InputMaybe<Scalars['Float']['input']>;
  y?: InputMaybe<Scalars['Float']['input']>;
  z?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateOneOptimizationSharingInput = {
  id: Scalars['ID']['input'];
  update: OptimizationSharingUpdate;
};

export type OptimizationSharingUpdate = {
  setup?: InputMaybe<OptimizationSharingSetupInput>;
};

export type OptimizationSharingDeleteResponse = {
  __typename?: 'OptimizationSharingDeleteResponse';
  id?: Maybe<Scalars['ID']['output']>;
  publicUrl?: Maybe<Scalars['String']['output']>;
  sharingUrl?: Maybe<Scalars['String']['output']>;
  setup?: Maybe<OptimizationSharingSetup>;
};

export type Subscription = {
  __typename?: 'Subscription';
  createdProject: Project;
  updatedOneProject: Project;
  createdActivity: CreatedActivitySubscriptionDto;
  walletUpdated: WalletOutput;
  updatedOneOptimization: Optimization;
};

export type CreatedActivitySubscriptionDto = {
  __typename?: 'CreatedActivitySubscriptionDto';
  id: Scalars['ID']['output'];
  type: ActivityType;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  project?: Maybe<CreatedActivitySubscriptionDto_Project>;
  optimization?: Maybe<CreatedActivitySubscriptionDto_Optimization>;
};

export type CreatedActivitySubscriptionDto_Project = {
  __typename?: 'CreatedActivitySubscriptionDto_Project';
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type CreatedActivitySubscriptionDto_Optimization = {
  __typename?: 'CreatedActivitySubscriptionDto_Optimization';
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  project?: Maybe<CreatedActivitySubscriptionDto_Project>;
};

export type AppSettingsEdge = {
  __typename?: 'AppSettingsEdge';
  node: AppSettings;
  cursor: Scalars['ConnectionCursor']['output'];
};

export type AppSettingsCountAggregate = {
  __typename?: 'AppSettingsCountAggregate';
  id?: Maybe<Scalars['Int']['output']>;
};

export type AppSettingsMinAggregate = {
  __typename?: 'AppSettingsMinAggregate';
  id?: Maybe<Scalars['ID']['output']>;
};

export type AppSettingsMaxAggregate = {
  __typename?: 'AppSettingsMaxAggregate';
  id?: Maybe<Scalars['ID']['output']>;
};

export type UserCountAggregate = {
  __typename?: 'UserCountAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['Int']['output']>;
  role?: Maybe<Scalars['Int']['output']>;
  fullName?: Maybe<Scalars['Int']['output']>;
  organization?: Maybe<Scalars['Int']['output']>;
  phone?: Maybe<Scalars['Int']['output']>;
  iFullName?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  latestLogin?: Maybe<Scalars['Int']['output']>;
};

export type UserMinAggregate = {
  __typename?: 'UserMinAggregate';
  id?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  role?: Maybe<UserRole>;
  fullName?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  iFullName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  latestLogin?: Maybe<Scalars['DateTime']['output']>;
};

export type UserMaxAggregate = {
  __typename?: 'UserMaxAggregate';
  id?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  role?: Maybe<UserRole>;
  fullName?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  iFullName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  latestLogin?: Maybe<Scalars['DateTime']['output']>;
};

export type ProjectCountAggregate = {
  __typename?: 'ProjectCountAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
  customerId?: Maybe<Scalars['Int']['output']>;
  conversionStatus?: Maybe<Scalars['Int']['output']>;
  optimizationsCount?: Maybe<Scalars['Int']['output']>;
  lastActivityAt?: Maybe<Scalars['Int']['output']>;
  iname?: Maybe<Scalars['Int']['output']>;
};

export type ProjectSumAggregate = {
  __typename?: 'ProjectSumAggregate';
  optimizationsCount?: Maybe<Scalars['Float']['output']>;
};

export type ProjectAvgAggregate = {
  __typename?: 'ProjectAvgAggregate';
  optimizationsCount?: Maybe<Scalars['Float']['output']>;
};

export type ProjectMinAggregate = {
  __typename?: 'ProjectMinAggregate';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  conversionStatus?: Maybe<ConversionStatus>;
  optimizationsCount?: Maybe<Scalars['Int']['output']>;
  lastActivityAt?: Maybe<Scalars['DateTime']['output']>;
  iname?: Maybe<Scalars['String']['output']>;
};

export type ProjectMaxAggregate = {
  __typename?: 'ProjectMaxAggregate';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  conversionStatus?: Maybe<ConversionStatus>;
  optimizationsCount?: Maybe<Scalars['Int']['output']>;
  lastActivityAt?: Maybe<Scalars['DateTime']['output']>;
  iname?: Maybe<Scalars['String']['output']>;
};

export type ActivityCountAggregate = {
  __typename?: 'ActivityCountAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
  optimizationId?: Maybe<Scalars['Int']['output']>;
};

export type ActivityMinAggregate = {
  __typename?: 'ActivityMinAggregate';
  id?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<ActivityType>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  projectId?: Maybe<Scalars['ID']['output']>;
  optimizationId?: Maybe<Scalars['ID']['output']>;
};

export type ActivityMaxAggregate = {
  __typename?: 'ActivityMaxAggregate';
  id?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<ActivityType>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  projectId?: Maybe<Scalars['ID']['output']>;
  optimizationId?: Maybe<Scalars['ID']['output']>;
};

export type FeedbackCountAggregate = {
  __typename?: 'FeedbackCountAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['Int']['output']>;
  shareData?: Maybe<Scalars['Int']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
  optimizationId?: Maybe<Scalars['Int']['output']>;
};

export type FeedbackMinAggregate = {
  __typename?: 'FeedbackMinAggregate';
  id?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['ID']['output']>;
  optimizationId?: Maybe<Scalars['ID']['output']>;
};

export type FeedbackMaxAggregate = {
  __typename?: 'FeedbackMaxAggregate';
  id?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['ID']['output']>;
  optimizationId?: Maybe<Scalars['ID']['output']>;
};

export type OptimizationTemplateCountAggregate = {
  __typename?: 'OptimizationTemplateCountAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['Int']['output']>;
  customerId?: Maybe<Scalars['Int']['output']>;
  lastActivityAt?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  iname?: Maybe<Scalars['Int']['output']>;
};

export type OptimizationTemplateMinAggregate = {
  __typename?: 'OptimizationTemplateMinAggregate';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  lastActivityAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  iname?: Maybe<Scalars['String']['output']>;
};

export type OptimizationTemplateMaxAggregate = {
  __typename?: 'OptimizationTemplateMaxAggregate';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  lastActivityAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  iname?: Maybe<Scalars['String']['output']>;
};

export type OptimizationTemplateItemCountAggregate = {
  __typename?: 'OptimizationTemplateItemCountAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
};

export type OptimizationTemplateItemMinAggregate = {
  __typename?: 'OptimizationTemplateItemMinAggregate';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type OptimizationTemplateItemMaxAggregate = {
  __typename?: 'OptimizationTemplateItemMaxAggregate';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CustomerCountAggregate = {
  __typename?: 'CustomerCountAggregate';
  id?: Maybe<Scalars['Int']['output']>;
};

export type CustomerMinAggregate = {
  __typename?: 'CustomerMinAggregate';
  id?: Maybe<Scalars['String']['output']>;
};

export type CustomerMaxAggregate = {
  __typename?: 'CustomerMaxAggregate';
  id?: Maybe<Scalars['String']['output']>;
};

export type OptimizationCountAggregate = {
  __typename?: 'OptimizationCountAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
  lastActivityAt?: Maybe<Scalars['Int']['output']>;
};

export type OptimizationMinAggregate = {
  __typename?: 'OptimizationMinAggregate';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<OptimizationStatus>;
  type?: Maybe<OptimizationType>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  lastActivityAt?: Maybe<Scalars['DateTime']['output']>;
};

export type OptimizationMaxAggregate = {
  __typename?: 'OptimizationMaxAggregate';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<OptimizationStatus>;
  type?: Maybe<OptimizationType>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  lastActivityAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ObjectModelEdge = {
  __typename?: 'ObjectModelEdge';
  node: ObjectModel;
  cursor: Scalars['ConnectionCursor']['output'];
};

export type ObjectModelCountAggregate = {
  __typename?: 'ObjectModelCountAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
};

export type ObjectModelMinAggregate = {
  __typename?: 'ObjectModelMinAggregate';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ObjectModelMaxAggregate = {
  __typename?: 'ObjectModelMaxAggregate';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type LogCountAggregate = {
  __typename?: 'LogCountAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
};

export type LogMinAggregate = {
  __typename?: 'LogMinAggregate';
  id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<LogType>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
};

export type LogMaxAggregate = {
  __typename?: 'LogMaxAggregate';
  id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<LogType>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CustomerPerkCountAggregate = {
  __typename?: 'CustomerPerkCountAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  valid_since?: Maybe<Scalars['Int']['output']>;
  valid_until?: Maybe<Scalars['Int']['output']>;
  remove_after_expiration?: Maybe<Scalars['Int']['output']>;
};

export type CustomerPerkMinAggregate = {
  __typename?: 'CustomerPerkMinAggregate';
  id?: Maybe<Scalars['String']['output']>;
  valid_since?: Maybe<Scalars['DateTime']['output']>;
  valid_until?: Maybe<Scalars['DateTime']['output']>;
};

export type CustomerPerkMaxAggregate = {
  __typename?: 'CustomerPerkMaxAggregate';
  id?: Maybe<Scalars['String']['output']>;
  valid_since?: Maybe<Scalars['DateTime']['output']>;
  valid_until?: Maybe<Scalars['DateTime']['output']>;
};

export type PerkCountAggregate = {
  __typename?: 'PerkCountAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  code?: Maybe<Scalars['Int']['output']>;
  reset_value?: Maybe<Scalars['Int']['output']>;
  regularity?: Maybe<Scalars['Int']['output']>;
  regularity_value?: Maybe<Scalars['Int']['output']>;
  offer?: Maybe<Scalars['Int']['output']>;
  price_description_without_offer?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  short_description?: Maybe<Scalars['Int']['output']>;
  complete_description?: Maybe<Scalars['Int']['output']>;
  price_description?: Maybe<Scalars['Int']['output']>;
  priority_description?: Maybe<Scalars['Int']['output']>;
  trial?: Maybe<Scalars['Int']['output']>;
  subscription?: Maybe<Scalars['Int']['output']>;
};

export type PerkSumAggregate = {
  __typename?: 'PerkSumAggregate';
  reset_value?: Maybe<Scalars['Float']['output']>;
  regularity_value?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

export type PerkAvgAggregate = {
  __typename?: 'PerkAvgAggregate';
  reset_value?: Maybe<Scalars['Float']['output']>;
  regularity_value?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

export type PerkMinAggregate = {
  __typename?: 'PerkMinAggregate';
  id?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  reset_value?: Maybe<Scalars['Int']['output']>;
  regularity?: Maybe<RegularityType>;
  regularity_value?: Maybe<Scalars['Int']['output']>;
  price_description_without_offer?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  short_description?: Maybe<Scalars['String']['output']>;
  complete_description?: Maybe<Scalars['String']['output']>;
  price_description?: Maybe<Scalars['String']['output']>;
  priority_description?: Maybe<Scalars['String']['output']>;
};

export type PerkMaxAggregate = {
  __typename?: 'PerkMaxAggregate';
  id?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  reset_value?: Maybe<Scalars['Int']['output']>;
  regularity?: Maybe<RegularityType>;
  regularity_value?: Maybe<Scalars['Int']['output']>;
  price_description_without_offer?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  short_description?: Maybe<Scalars['String']['output']>;
  complete_description?: Maybe<Scalars['String']['output']>;
  price_description?: Maybe<Scalars['String']['output']>;
  priority_description?: Maybe<Scalars['String']['output']>;
};

export type OptimizationSharingCountAggregate = {
  __typename?: 'OptimizationSharingCountAggregate';
  id?: Maybe<Scalars['Int']['output']>;
};

export type OptimizationSharingMinAggregate = {
  __typename?: 'OptimizationSharingMinAggregate';
  id?: Maybe<Scalars['ID']['output']>;
};

export type OptimizationSharingMaxAggregate = {
  __typename?: 'OptimizationSharingMaxAggregate';
  id?: Maybe<Scalars['ID']['output']>;
};

export type ImageInfoInput = {
  path?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
  bitdepth?: InputMaybe<Scalars['Float']['input']>;
  ext?: InputMaybe<Scalars['String']['input']>;
};

export type InfoInput = {
  version?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<Array<ImageInfoInput>>;
};

import { AugmentedStoreAssembly } from "../../../../AugmentedStoreAssembly";
import { ALLOWED_IMAGE_TYPES, ALLOWED_VIDEO_TYPES, DEFAULT_MEDIA_VOLUME, ALLOWED_PDF_TYPES, DEFAULT_SLIDESHOW_SECONDS, MEDIA_CONVERSION_STATUS_FILENAME, SUPPORTED_VIDEO_FORMATS } from "../../../utilities/constants";
import { ConversionUtils, FileConversionStatus, Status } from "../../../utilities/conversion-utilities";
import { FileMediaType, ResourceUtils, VERTEXResource } from "../../../utilities/resource-utilities";
import { DeviceType, getComponentJsonFromResource, NotificationStatus, postComponentJsonToResource, Utils } from "../../../utilities/utils";
import { getDocument } from "pdfjs-dist/webpack";

export class Media {
    mediaName: string;
    volume: number;
}

export class CustomMediaTextureComponent extends AugmentedStoreAssembly.MediaTextureComponent {
    isReady: Promise<void>;
    medias: Media[] = [];
    imageTexture: BABYLON.DynamicTexture;
    videoTexture: BABYLON.VideoTexture;
    image = new Image();
    files: string[] = [];
    dataMedias: string[] = [];
    previewMediaName: string = "";
    isWaitingConversion: boolean = false;
    imageMaterial: BABYLON.StandardMaterial;
    videoMaterial: BABYLON.StandardMaterial;
    plane: BABYLON.Mesh;
    previewVolume: number = DEFAULT_MEDIA_VOLUME;

    async updateResources() {
        try {
            const resource = await ResourceUtils.getResourceAsync(this.id, false);

            if (resource) {
                this.files = resource.resourceKeys;

                if (this.files) {
                    this.files.forEach((file: string) => {
                        const extension = Utils.getFileExtension(file, true);

                        if (ALLOWED_VIDEO_TYPES.includes(extension) || ALLOWED_IMAGE_TYPES.includes(extension) || ALLOWED_PDF_TYPES.includes(extension)) {
                            this.dataMedias.push(file);
                        }
                    });
                    //possibilità di precaricare pagine pdf
                    const webms = this.dataMedias.filter((v) => Utils.getFileExtension(v, true) == "webm");

                    webms.forEach(webmFileName => {
                        const mp4FileName = webmFileName.substring(0, webmFileName.lastIndexOf('.')) + ".mp4";
                        const index = this.dataMedias.findIndex((media) => mp4FileName == media);
                        
                        if(index != -1){
                            this.dataMedias.splice(index, 1);
                        }
                    });
                }
            }
        }
        catch {
            console.log("Failed to retrieve resource data for resource: " + this.id);
        }
    }

    postComponentJsonToResource = (async (isEditorVersion: boolean = true) => {
        await postComponentJsonToResource(Vertex.Globals.spaceId, "MediaTexture", this.node.id, isEditorVersion, JSON.stringify(this.medias));
    }).bind(this);
}

export class MediaTextureComponentView extends Vertex.NodeComponentModel.ComponentViewBase {
    constructor() {
        super();
    }

    private cachedImages: Map<string, string> = new Map<string, string>();
    private texSize = 1024;

    private getBlobFromMap(id: string, fileName: string): string {
        const blobUri = this.cachedImages.get(id + ":" + fileName);
        
        return blobUri;
    }

    private putBlobInMap(id: string, fileName: string, blobUri: string) {
        this.cachedImages.set(id + ":" + fileName, blobUri);
    }

    private async getImagefromIndexPage(pdfResult: any, pageIndex: number) : Promise<string> {
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext('2d');

        let page = await pdfResult.getPage(pageIndex+1);
 
        // more code here
        let viewport = page.getViewport({scale: 1.5});
                
        canvas.width = viewport.width;
        canvas.height = viewport.height;
        ctx.rect(0, 0, viewport.width, viewport.height);
        ctx.fillStyle = "white";
        ctx.fill();
        var renderTask = page.render({
            canvasContext: ctx,
            viewport: viewport
        })
        await renderTask.promise;
        return canvas.toDataURL();   
    }

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        const customComp = component as CustomMediaTextureComponent;

        customComp.isReady = new Promise<void>(async (resolve, reject) => {
            await customComp.updateResources();

            let res = await getComponentJsonFromResource(Vertex.Globals.spaceId, "MediaTexture", node.id, true);
            
            if (res.ok) {
                customComp.medias = await res.json() as Media[];
                
                if (customComp.medias.length <= 0) {
                    customComp.medias = customComp.dataMedias.map(availableMediaName => { return { mediaName: availableMediaName, volume: DEFAULT_MEDIA_VOLUME } });                    
                }
            }
            else {
                res = await getComponentJsonFromResource(Vertex.Globals.spaceId, "MediaTexture", node.id, false);
                
                if (res.ok) {
                    customComp.medias = await res.json() as Media[];
                    
                    await customComp.postComponentJsonToResource(true);
                } else {
                    customComp.medias = customComp.dataMedias.map(availableMediaName => { return { mediaName: availableMediaName, volume: DEFAULT_MEDIA_VOLUME } });
                    
                    await customComp.postComponentJsonToResource(true);
                }
            }

            if(customComp.slideshowSeconds === 0){
                customComp.slideshowSeconds = DEFAULT_SLIDESHOW_SECONDS;
            }

            this.createPanel(customComp, node);

            customComp.onChanged.on(this.onChange);

            node.onDestroy.on(() => {
                customComp.videoTexture.video.src = "";
                customComp.videoTexture.video.pause();
            });

            const beforeSaveSpace = async () => {
                await customComp.postComponentJsonToResource(false);
            }

            customComp.onRemoved.on(async () => {
                Vertex.Globals.event.off("hevolus:beforeSaveSpace", beforeSaveSpace);
                customComp.medias = [];

                await customComp.postComponentJsonToResource(true);
            });

            Vertex.Globals.event.on("hevolus:beforeSaveSpace", beforeSaveSpace);

            customComp.previewMediaName = "";
            customComp.triggerOnChanged();

            resolve();
        });
    }

    onChange = (async (customComp: CustomMediaTextureComponent) => {
        if(customComp.medias.length > 0){
            const media = customComp.medias[customComp.index];
            customComp.previewVolume = media.volume;

            if(customComp.isWaitingConversion){
                const extension = Utils.getFileExtension(media.mediaName, true);
                const isVideo = ResourceUtils.getMediaType(extension) === FileMediaType.Video;

                const isConverting = isVideo ? await ConversionUtils.isConverting(media.mediaName, customComp.id) : false;

                if(!isConverting){
                    customComp.isWaitingConversion = false;
                    customComp.previewMediaName = media.mediaName
                    await this.updatePanelMedia(customComp);
                }
            }
            else if (customComp.previewMediaName != media.mediaName) {
                customComp.previewMediaName = media.mediaName
                await this.updatePanelMedia(customComp);
            }
            
            customComp.videoTexture.video.volume = customComp.previewVolume / 100;
        }
        else{
            customComp.previewMediaName = "";

            await this.updatePanelMedia(customComp);
        }
    }).bind(this);

    createPanel(customComp: CustomMediaTextureComponent, node: Vertex.NodeComponentModel.VertexNode) {
        let scene = Vertex.Globals.runtime.scene;
        let planeSize = 1;

        customComp.plane = BABYLON.Mesh.CreatePlane("plane", planeSize, scene, true, BABYLON.Mesh.DOUBLESIDE);
        customComp.plane.parent = node.viewNode;

        this.createImageMaterial(customComp, scene);
        this.createVideoMaterial(customComp, scene);

        //test
        customComp.plane.showBoundingBox = true;
    }

    private createImageMaterial(customComp: CustomMediaTextureComponent, scene: BABYLON.Scene) {
        customComp.imageTexture = new BABYLON.DynamicTexture('texture', this.texSize, scene, false);
        customComp.imageTexture.hasAlpha = true;

        customComp.image.onload = () => {
            customComp.plane.scaling = new BABYLON.Vector3(1, 1, 1);
            
            let ctx = customComp.imageTexture.getContext();
            ctx.clearRect(0, 0, this.texSize, this.texSize);
            
            if (customComp.image.width > customComp.image.height) {
                let height = customComp.image.height * this.texSize / customComp.image.width;
                ctx.drawImage(customComp.image, 0, 0, customComp.image.width, customComp.image.height, 0, (this.texSize - height) / 2, this.texSize, height);
            }
            else {
                let width = customComp.image.width * this.texSize / customComp.image.height;
                ctx.drawImage(customComp.image, 0, 0, customComp.image.width, customComp.image.height, (this.texSize - width) / 2, 0, width, this.texSize);
            }

            customComp.imageTexture.update();
            customComp.plane.material = customComp.imageMaterial;
        };

        let mat = new BABYLON.StandardMaterial("mat", scene);
        mat.sideOrientation = BABYLON.Material.ClockWiseSideOrientation;

        mat.diffuseTexture = customComp.imageTexture;
        mat.diffuseTexture.hasAlpha = true;
        mat.emissiveColor = BABYLON.Color3.White();
        customComp.imageMaterial = mat;
    }

    private createVideoMaterial(customComp: CustomMediaTextureComponent, scene: BABYLON.Scene) {
        const videoMat = new BABYLON.StandardMaterial("m", scene);
        videoMat.sideOrientation = BABYLON.Material.ClockWiseSideOrientation;

        try {
            customComp.videoTexture = new BABYLON.VideoTexture("vidtex", [], scene);
            customComp.videoTexture.video.onloadeddata = () => {
                if (customComp.videoTexture.video.videoWidth > customComp.videoTexture.video.videoHeight) {
                    customComp.plane.scaling = new BABYLON.Vector3(1, customComp.videoTexture.video.videoHeight / customComp.videoTexture.video.videoWidth, 1);
                }
                else {
                    customComp.plane.scaling = new BABYLON.Vector3(customComp.videoTexture.video.videoWidth / customComp.videoTexture.video.videoHeight, 1, 1);
                }

                customComp.videoTexture.video.muted = false;
                customComp.videoTexture.video.play();

                customComp.plane.material = customComp.videoMaterial;
            }
            
            videoMat.diffuseTexture = customComp.videoTexture;
            videoMat.roughness = 1;
            videoMat.emissiveColor = BABYLON.Color3.White();

            customComp.videoTexture.video.loop = true;
            customComp.videoMaterial = videoMat;
        }
        catch (e) {
            console.error(e);
            customComp.videoTexture?.dispose();
            customComp.videoTexture = null;
            videoMat.dispose();
        }
    }

    async updatePanelMedia(customComp: CustomMediaTextureComponent) {
        if (customComp.videoTexture == null) {
            let scene = Vertex.Globals.runtime.scene;

            this.createVideoMaterial(customComp, scene);
        }

        if (customComp.previewMediaName.length > 0) {
            let mediaName = customComp.previewMediaName;

            const fileName = Utils.getFileBaseName(mediaName);
            let extension = Utils.getFileExtension(mediaName, true);
            const isVideo = ResourceUtils.getMediaType(extension) === FileMediaType.Video;
            const isPdf = ResourceUtils.getMediaType(extension) === FileMediaType.Pdf;
            if (isVideo) {
                if(Utils.getDeviceType() == DeviceType.iPhone || Utils.getDeviceType() == DeviceType.iPad){
                    mediaName = fileName + ".mp4";
                    extension = "mp4";
                }
                else{
                    mediaName = fileName + ".webm";
                    extension = "webm";
                }

                const conversionStatus = await ConversionUtils.getMediaConversionStatus(customComp.previewMediaName, customComp.id);

                if(conversionStatus.status === Status.Failed){
                    const mediaIndex = customComp.dataMedias.findIndex((media) => media == customComp.previewMediaName);
                    customComp.dataMedias.splice(mediaIndex, 1);
                    
                    customComp.medias.splice(customComp.index, 1);

                    if(customComp.dataMedias.length > 0){
                        this.onChange(customComp);
                    }

                    return;
                }

                if(conversionStatus.status === Status.Pending){
                    customComp.isWaitingConversion = true;
                    customComp.videoTexture?.video?.pause();
                    customComp.image.src = "/img/converting.svg";

                    Utils.waitForConditionAsync(async _ => {
                        const mediaName = customComp.medias[customComp.index].mediaName;
                        const extension = Utils.getFileExtension(mediaName, true);
                        const isVideo = ResourceUtils.getMediaType(extension) === FileMediaType.Video;

                        const isConverting = isVideo ? await ConversionUtils.isConverting(mediaName, customComp.id) : false;
                        
                        return !isConverting;
                    }, 5000).then(async () => {
                        customComp.isWaitingConversion = false;
                        customComp.previewMediaName = "";
                        this.onChange(customComp);
                    });

                    return;
                }
            }

            let blobUrl = this.getBlobFromMap(customComp.id, mediaName);

            if(!blobUrl){
                blobUrl = await ResourceUtils.getAssetBlobUrl(customComp.id, mediaName)
                
                if (blobUrl) {
                    this.putBlobInMap(customComp.id, mediaName, blobUrl);
                }
            }

            if(!blobUrl){
                customComp.videoTexture?.video?.pause();
                let ctx = customComp.imageTexture.getContext();
                ctx.fillRect(0, 0, this.texSize, this.texSize);
                customComp.imageTexture.update();
            }
            else{
                if (isVideo) {
                    customComp.videoTexture.video.src = blobUrl;
                } 
                else if (isPdf){
                    customComp.videoTexture?.video?.pause();
                    let pdfRes = getDocument(blobUrl);
                    let pdfResult = await pdfRes.promise;
                    let image = await this.getImagefromIndexPage(pdfResult, 0);
                    customComp.image.src = image;
                } else {
                    customComp.videoTexture?.video?.pause();
                    customComp.image.src = blobUrl;
                }
            }

            customComp.plane.visibility = 1;
        }
        else {
            customComp.videoTexture?.video?.pause();
            customComp.plane.visibility = 0;
        }
    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
    }
}

export class MediaTextureComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new CustomMediaTextureComponent();
    }

    constructor() {
        super("MediaTexture", new MediaTextureComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}

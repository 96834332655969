import { AugmentedStoreAssembly } from '../../../../AugmentedStoreAssembly';
import { Utils } from '../../../utilities/utils';
import { SustainabilityInfo } from '../../../admin/sustainability/view/sustainability';
import { CustomText2DComponent } from './text2dcomponent';
import { ResourceUtils, TagMode, HevolusResourceType } from '../../../utilities/resource-utilities';
import { SUSTAINABILITY_RESOURCE_NAME, TENANT_INFO_TAG } from '../../../utilities/constants';


export class SavedCO2VisualizerComponentView extends Vertex.NodeComponentModel.ComponentViewBase {
    comp: AugmentedStoreAssembly.SavedCO2VisualizerComponent;
    tenantInfoResId = "";
    pollingStarted: boolean = false;
    co2Text2dList : CustomText2DComponent [] = [];

    onCo2ChangedEvent: Vertex.EventBus<string> = new Vertex.EventBus();
    private co2 : string = '0';

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        this.comp = component as AugmentedStoreAssembly.SavedCO2VisualizerComponent;

        const itemPropertiesComp = node.getComponent("ItemProperties") as AugmentedStoreAssembly.ItemPropertiesComponent;
        
        if (itemPropertiesComp) {
            let isSelectableInput = document.querySelector("#isSelectablePropertyRenderer")?.querySelector("input");
            if (isSelectableInput) {
                isSelectableInput.checked = false;
                isSelectableInput.setAttribute("disabled", "");
            }
            let isMovableInput = document.querySelector("#isMovablePropertyRenderer")?.querySelector("input");
            if (isMovableInput) {
                isMovableInput.checked = false;
                isMovableInput.setAttribute("disabled", "");
            }
            let isWishlistableInput = document.querySelector("#isWishlistablePropertyRenderer")?.querySelector("input");
            if (isWishlistableInput) {
                isWishlistableInput.checked = false;
                isWishlistableInput.setAttribute("disabled", "");
            }
            itemPropertiesComp.isSelectable = false;
            itemPropertiesComp.isWishlistable = false;
            itemPropertiesComp.isMovable = false;
            itemPropertiesComp.triggerOnChanged();
        }

        const text2d: CustomText2DComponent = node.getComponent("Text2D") as CustomText2DComponent; //as Text2DComponent

        this.onCo2ChangedEvent.on(this.onCo2Changed);        

        if(!this.tenantInfoResId){
            ResourceUtils.getResourcesWithParamsAsync([TENANT_INFO_TAG], TagMode.All, ResourceUtils.getVertexResourceType(HevolusResourceType.TenantInfo), false, true).then(resources =>{
                if (((resources!=null) && (resources.length>0))&&((resources[0] !=null) && resources[0].id))
                {
                    this.tenantInfoResId = resources[0].id;
                    this.comp.tenantInfoResPublishedId = this.tenantInfoResId;
                    this.comp.triggerOnChanged();
                }
                else
                {
                    console.log("FAILED TO RETRIEVE TENANT INFO RESOURCE");
                }
            });
        }

        Utils.waitForCondition(_ => this.tenantInfoResId).then(_ => {
            if (!this.pollingStarted){
                this.CO2Polling();
                this.pollingStarted = true;
            }
        });

        this.co2Text2dList.push(text2d);

        text2d.setText(this.co2);
    }


    onCo2Changed = ((co2 : string) =>{
        for(let i = 0; i < this.co2Text2dList.length; i++){
            let co2TextComponent = this.co2Text2dList[i];

            if(co2TextComponent){
                co2TextComponent.setText(co2);
            }
            else{
                this.co2Text2dList.splice(i,1);
                i--; 
            }
        }
    }).bind(this);

    private _getFormattedWeight(mass: number) {
        if(mass < 1){
            mass = Number((mass*1000).toFixed(2));

            return `${mass} g`;
        }
        else if(mass < 1000){
            mass = Number(mass.toFixed(2));

            return `${mass} kg`;
        }
        else{
            mass = Number((mass/1000).toFixed(2));

            return `${mass} t`;
        } 
    }

    CO2Polling = (async () => {
        try {            
            if (this.tenantInfoResId) {
                const sustainabilityResponse = await ResourceUtils.getAssetFromResource(this.tenantInfoResId, SUSTAINABILITY_RESOURCE_NAME);
                
                if (sustainabilityResponse.ok) {
                    const co2json = await sustainabilityResponse.json() as SustainabilityInfo;

                    if(co2json){
                        this.co2 = this._getFormattedWeight(co2json.savedCo2);
                        this.onCo2ChangedEvent.trigger(this.co2);
                    }
                }

                setTimeout(this.CO2Polling, 10000);
            }

        } catch (error) {
            console.error(error);
        }

    }).bind(this);




    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
    }
}

export class SavedCO2VisualizerComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new AugmentedStoreAssembly.SavedCO2VisualizerComponent();
    }
    constructor() {
        super("SavedCO2Visualizer", new SavedCO2VisualizerComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}
import { User, UserManager } from "oidc-client";
import { Config } from "../config";

export interface ICredentialProvider {
    /** request a token for the specified url/domain */
    getToken(url: string): Promise<string>;
}

export class AuthManager implements ICredentialProvider {
    constructor() {
        this.userManager = new UserManager({
            client_id: Config.VERTEX_CLIENT_ID,
            authority: AuthManager.IdentityServer,
            redirect_uri: Config.AUTH_CALLBACK_URI,
            response_type: "code",
            // turn off auto-renew since we implement callback logic to handle
            // renewal
            automaticSilentRenew: false,
            scope: Config.VERTEX_SCOPE,
            // disable session monitoring, since it doesnt work with third-party 
            // cookies being blocked, as in pretty much all browsers now
            monitorSession: false,
            // prompt: "none"
        });

        // perform session maintenance
        this.userManager.clearStaleState();
    }

    userManager: UserManager;

    /**
     * returns true if user has a valid session.
     * if no valid session exists, returns false and immediately redirects to sign in.
     */
    async ensureSignedInAsync(): Promise<boolean> {
        let user = await this.userManager.getUser();

        if (!this.isUserExpired(user)){
            return true;
        }

        // todo: insert state to redirect back to right place
        this.userManager.signinRedirect({
            state: this.createSigninState()
        });
    }

    private createSigninState(): OAuthState {
        let appUri = `${location.pathname}${location.search}${location.hash}`;

        let stateObj: AuthorizeState = {
            appUri: appUri
        };

        return JSON.stringify(stateObj);
    }

    processSigninState(state: OAuthState) {
        try {
            // todo: should hash this or something to prevent open redirects
            let obj = JSON.parse(state) as AuthorizeState;
            
            if (!obj || !obj.appUri || typeof obj.appUri !== 'string')
                throw new Error("Invalid State");

            if (obj.appUri.startsWith('/') === false)
                throw new Error("Invalid State");

            location.href = obj.appUri;
        }
        catch {
            throw new Error("Invalid State");
        }
    }

    /**
     * 
     * @param callback optional. callback to fire when session expires. if null, the page will immediately redirect to sign in on session expiry.
     * @param callbackThis optional. the 'this' value to use when firing the callback
     */
    watchSession(callback?: SessionExpiryCallback, callbackThis?: any) {
        if (typeof callback !== 'function')
            callback = this._defaultSessionExpiredCallback.bind(this);

        if (!callbackThis)
            callbackThis = window;

        let self = this;
        setInterval(async () => {
            console.log("[VERTX:Auth] Checking session state...");

            let user = await self.userManager.getUser();

            if (this.isUserExpired(user))
                callback.call(callbackThis, self, user);

        }, 10 * 1000 /* 10 seconds */);
    }

    private isUserExpired(user: User) : boolean {
        return !user || user.expired || user.expires_in < 300;
    }

    /**
     * 
     * @returns user info, if signed in, otherwise null
     */
    getUserAsync(): Promise<User> {
        return this.userManager.getUser();
    }

    private _defaultSessionExpiredCallback() {
        console.warn("[VERTX:Auth] Session expired, invoking silent sign in.\n" +
            "You can override this behaviour by passing a callback to 'watchSession'");
        this.ensureSignedInAsync();
    }

    static get IdentityServer(): string {
        return `${Config.VERTEX_IDENTITY_URL}`;
    }

    static get VertexBase(): string {
        return `https://${Config.VERTEX_URL_BASE}`;
    }

    async getToken(url: string): Promise<string> {
        try {
            if (url.startsWith(AuthManager.VertexBase) || url.startsWith(AuthManager.IdentityServer)) {
                let user = await this.getUserAsync();
                return user.access_token;
            }
            return null;
        }
        catch {
            return null;
        }
    }
}

export interface SessionExpiryCallback {
    (this: any, userManager: UserManager, user: User): void;
}

interface AuthorizeState {
    appUri: string
}

type OAuthState = string;
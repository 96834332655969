import { Config } from "../../../config";
import { LicenseDictionary, LicenseResponse } from "../../utilities/license-utilities";

export function delay(delayMs: number): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(resolve, delayMs);
    })
}

// from MDN
export function randomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

export function className(instance: object) {
    let proto = Object.getPrototypeOf(instance);
    let name = (proto && proto.constructor && proto.constructor.name) || "<unknown class>";

    return name;
}

export function querySelector2(this: Element, selector: string): Element {
    if (this.matches(selector))
        return this;

    return this.querySelector(selector);
}

export function querySelectorAll2(this: Element, selector: string): Element[] {
    let matches = Array.from(this.querySelectorAll(selector));
    if (this.matches(selector)) {
        // unshift = insert at start of array
        matches.unshift(this);
    }
    return matches;
}

export function setHashValue(key: string, value: string) {
    let hash = location.hash;
    if (hash.length > 0)
        hash = hash.substring(1);

    let params = new URLSearchParams(hash);
    if (value)
        params.set(key, value);
    else
        params.delete(key);

    let newhash = params.toString();
    // only invoke the setter if it actually changed
    if (newhash != hash)
        location.hash = `#` + params.toString();
}

export function getHashValue(key: string): string {
    let hash = location.hash;
    if (hash.length > 0)
        hash = hash.substring(1);

    let params = new URLSearchParams(hash);
    return params.get(key);
}


export async function validateLicenses(token: string, ...licenseNames: string[]): Promise<LicenseDictionary> {
    const params = licenseNames.map(x => encodeURIComponent(x)).join("&id=");
    const uri = `https://${Config.VERTEX_URL_BASE}/store/validatelicense/?id=${params}`;

    const res = await fetch(uri, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });

    if (res.ok) {
        let resourceJson = await res.json() as LicenseResponse;
        return resourceJson.licenseStates;

    }
    return null;
}

export async function checkStackAdmin(token: string) {
    /** hacky way to check if we are a stack admin */

    // make a request to the license API
    const res = await fetch(`https://${Config.VERTEX_URL_BASE}/store/license/`, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })

    return res.ok;
}
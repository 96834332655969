import Swal from "sweetalert2"
import { CHANGE_MATERIAL_MODEL_FILENAME, MAX_MATERIAL_CATEGORY_NAME_LENGTH, MAX_MATERIAL_NAME_LENGTH, MAX_PRESET_CATEGORY_NAME_LENGTH, MAX_PRESET_NAME_LENGTH, MESH_GLTF_FILENAME, MIN_CHANGE_MATERIAL_MODEL_VERSION } from "./constants"
import { GltfStructure } from "./gltf-utilities"
import { DetailedResult, ResourceUtils, Result } from "./resource-utilities"
import { Utils } from "./utils"

export interface ChangeMaterialModel {
    fileVersion: string
    name: string
    materials: SkinMaterial[]
    categories: SkinCategory[]
    subMeshes: SkinSubmesh[]
    presets: SkinPreset[]
    presetCategories?: SkinCategory[]
}

export interface SkinMaterial {
    /** the GLTF material index that this material refers to */
    gltfMaterialIndex: number
    /** the human-readable name for this material */
    name: string
    /** optional, name of an image in the resource to use as a preview for this material */
    icon?: string
    /** the category index for the category this material belongs to */
    category: number
}

export interface SkinCategory {
    index: number
    name: string
    icon?: string
    parentCategory: number
}

export interface SkinSubmesh {
    /** the GLTF mesh index that this mesh refers to */
    gltfMeshIndex: number
    /** the human-readable name for this mesh */
    name: string
    /** optional, name of an image in the resource to use as a preview for this mesh */
    icon?: string
    /** array of material indices that are allowed to be used on this submesh */
    allowedMaterials: number[]
}

export interface SkinPreset {
    name: string
    icon?: string
    isAdditive?: boolean 
    category?: number
    subMeshConfigurations: SubmeshConfiguration[]
}

export interface SubmeshConfiguration {
    subMeshIndex: number
    materialIndex: number
}

export interface SkinNode {
    category?: SkinCategory
    material?: SkinMaterial
    preset?: SkinPreset
    children: SkinNode[]
    parent: SkinNode
}

export enum AssetType {
    Material = 'Material',
    Category = 'Category',
    Preset = 'Preset',
    PresetCategory = 'PresetCategory',
    Submesh = 'Submesh'
}

export class ChangeMaterialModelUtils {

    static validateChangeMaterialModel(model: ChangeMaterialModel): boolean {
        if (typeof model !== 'object' || model === null || !('fileVersion' in model)) {
            console.error('ChangeMaterialModel validation failed: Invalid type');
            return false;
        }
        if (parseInt(model.fileVersion) < parseInt(MIN_CHANGE_MATERIAL_MODEL_VERSION)) {
            console.error('ChangeMaterialModel validation failed: Invalid file version');
            return false;
        }
        if (!model.name || typeof model.name !== 'string') {
            console.error('ChangeMaterialModel validation failed: Invalid name');
            return false;
        }
        if (!Array.isArray(model.materials)) {
            console.error('ChangeMaterialModel validation failed: Invalid materials');
            return false;
        }
        if (!Array.isArray(model.categories)) {
            console.error('ChangeMaterialModel validation failed: Invalid categories');
            return false;
        }
        if (!Array.isArray(model.subMeshes)) {
            console.error('ChangeMaterialModel validation failed: Invalid submeshes');
            return false;
        }
        if (!Array.isArray(model.presets)) {
            console.error('ChangeMaterialModel validation failed: Invalid presets');
            return false;
        }
        if(model.presets?.length){
            model.presets.forEach(preset => {
                if(preset.category == null){
                    preset.category = -1;
                }
            });
        }
        if(!Array.isArray(model.presetCategories)) {
            model.presetCategories = [];
        }
        return true;
    }
    
    static unpackMaterialTaxonomy(changeMaterialModel: ChangeMaterialModel): SkinNode {
        if (!changeMaterialModel){
            return null;
        }
    
        const rootNode: SkinNode = {
            children: null,
            parent: null,
        }
    
        // iterate through the categories to build up the node tree
        const rootCategories = changeMaterialModel.categories
            .filter(cat => cat.parentCategory === -1)
            .map(cat => ChangeMaterialModelUtils.unpackCategory(cat, changeMaterialModel, rootNode));
    
        rootNode.children = rootCategories;
    
        // also get the root materials
        const rootMaterials = changeMaterialModel.materials
            .filter(mat => mat.category === -1)
            .map(mat => ChangeMaterialModelUtils.unpackMaterial(mat, rootNode));
    
        rootNode.children.push(...rootMaterials);
    
        return rootNode;
    }

    static unpackPresetTaxonomy(changeMaterialModel: ChangeMaterialModel): SkinNode {
        if (!changeMaterialModel){
            return null;
        }

        const rootNode: SkinNode = {
            children: null,
            parent: null,
        }

        // iterate through the categories to build up the node tree
        const rootCategories = changeMaterialModel.presetCategories?.filter(cat => cat.parentCategory === -1)
            .map(cat => ChangeMaterialModelUtils.unpackPresetCategory(cat, changeMaterialModel, rootNode));

        rootNode.children = rootCategories || [];

        // also get the root presets
        const rootPresets = changeMaterialModel.presets
            .filter(preset => preset.category === -1 || preset.category == null)
            .map(preset => ChangeMaterialModelUtils.unpackPreset(preset, rootNode));

        rootNode.children.push(...rootPresets);

        return rootNode;
    }
    
    static findCategoryNode(node: SkinNode, categoryIndex: number): SkinNode {
        // check if this node is the right category
        if (node.category && node.category.index === categoryIndex){
            return node;
        }
    
        // if not, if this node has children...
        if (node.children) {
            for (const child of node.children) {
                // check to see if any child is the right category
                const result = ChangeMaterialModelUtils.findCategoryNode(child, categoryIndex);
                if (result){
                    return result;
                }
            }
        }
    
        return null;
    }
    
    static getAncestors(node: SkinNode): SkinNode[] {
        if (!node.parent){
            return [node];
        }
    
        return [...ChangeMaterialModelUtils.getAncestors(node.parent), node];
    }
    
    static unpackCategory(category: SkinCategory, skins: ChangeMaterialModel, parentNode: SkinNode): SkinNode {
        // create a node for this category
        const unpacked: SkinNode = {
            category: category,
            children: [],
            parent: parentNode
        };

        // populate the children.
        // to organise windows-style, find categories first, then loose materials later.
        const childCategories = skins.categories
            .filter(cat => cat.parentCategory == category.index)
            .map(cat => ChangeMaterialModelUtils.unpackCategory(cat, skins, unpacked));

        unpacked.children.push(...childCategories);

        // now the materials
        const childMaterials = skins.materials
            .filter(mat => mat.category == category.index)
            .map(mat => ChangeMaterialModelUtils.unpackMaterial(mat, unpacked));

        unpacked.children.push(...childMaterials);

        return unpacked;
    }

    static unpackPresetCategory(category: SkinCategory, skins: ChangeMaterialModel, parentNode: SkinNode): SkinNode {
        const unpacked: SkinNode = {
            category: category,
            children: [],
            parent: parentNode
        }

        // populate the children.
        // to organise windows-style, find categories first, then loose presets later.
        const childPresetCategories = skins.presetCategories
            .filter(cat => cat.parentCategory == category.index)
            .map(cat => ChangeMaterialModelUtils.unpackPresetCategory(cat, skins, unpacked));

        unpacked.children.push(...childPresetCategories);

        // now the presets
        const childPresets = skins.presets
            .filter(preset => preset.category == category.index)
            .map(preset => ChangeMaterialModelUtils.unpackPreset(preset, unpacked));

        unpacked.children.push(...childPresets);

        return unpacked;
    }

    static unpackPreset(preset: SkinPreset, parentNode: SkinNode): SkinNode {
        const unpacked: SkinNode = {
            preset: preset,
            children: [],
            parent: parentNode
        }

        return unpacked;
    }

    static unpackMaterial(material: SkinMaterial, parentNode?: SkinNode): SkinNode {
        const unpacked: SkinNode = {
            material: material,
            children: [],
            parent: parentNode
        }

        return unpacked;
    }

    static submeshConfigurationEquals(arr1: SubmeshConfiguration[], arr2: SubmeshConfiguration[]) {
        let areEqual = true;

        if(arr1.length !== arr2.length){
            areEqual = false;
        }

        if(areEqual){
            for (let i = 0; i < arr1.length; i++) {

                let tempMesh = arr2.find((b=> b.subMeshIndex == arr1[i].subMeshIndex));

                if (!tempMesh || tempMesh.materialIndex != arr1[i].materialIndex) {
                    areEqual = false;
                }
            }
        }

        return areEqual;
    } 
    
    static getCurrentPreset(gltf: GltfStructure) : SkinPreset{
        const preset: SkinPreset = {
            name: '',
            icon: '',
            subMeshConfigurations: [],
        }

        for (let i = 0; i < gltf.meshes?.length; i++) {
            let mesh = gltf.meshes[i];

            if(mesh?.primitives?.length){
                let materialIndex = mesh.primitives[0].material;
                let subMeshIndex = i;

                preset.subMeshConfigurations.push({
                    materialIndex: materialIndex,
                    subMeshIndex: subMeshIndex,
                });
            }
        }

        return preset;
    }

    static async getDefaultPresetIndexes(resourceId: string){
        if(!resourceId){
            return;
        }

        const skinsResp = await ResourceUtils.getAssetFromResource(resourceId, CHANGE_MATERIAL_MODEL_FILENAME);
        const gltfResp = await ResourceUtils.getAssetFromResource(resourceId, MESH_GLTF_FILENAME);

        if(!skinsResp.ok || !gltfResp.ok){
            return;
        }

        const skins = await skinsResp.json() as ChangeMaterialModel;
        const gltf = await gltfResp.json() as GltfStructure;

        if(!skins || !gltf){
            return;
        }

        const currentPreset = ChangeMaterialModelUtils.getCurrentPreset(gltf);

        const indexes: number[] = [];

        for (let i = 0; i < skins.presets.length; i++) {
            if(ChangeMaterialModelUtils.submeshConfigurationEquals(skins.presets[i].subMeshConfigurations, currentPreset.subMeshConfigurations)){
                indexes.push(i);
            }
        }

        return indexes;
    }

    static validateAssetName(value: string, type: AssetType, model: ChangeMaterialModel) : DetailedResult {
        let result: DetailedResult = { result: Result.Success, message: '', value: ''};

        value = value.trim();

        if (!value) { // empty string or only whitespace
            result.result = Result.Failed;
            result.message = `Please choose ${Utils.getHumanReadableString(type)} name`;

            return result;
        }

        let names = [];
        let maxLength = MAX_MATERIAL_NAME_LENGTH;

        if(type == AssetType.Material){
            names = model.materials.map(m => m.name);
            maxLength = MAX_MATERIAL_NAME_LENGTH;
        }
        else if(type == AssetType.Category){
            names = model.categories.map(c => c.name);
            maxLength = MAX_MATERIAL_CATEGORY_NAME_LENGTH;
        }
        else if(type == AssetType.Preset){
            names = model.presets.map(p => p.name);
            maxLength = MAX_PRESET_NAME_LENGTH;
        }
        else if(type == AssetType.PresetCategory){
            names = model.presetCategories.map(p => p.name);
            maxLength = MAX_PRESET_CATEGORY_NAME_LENGTH;
        }
        else if(type == AssetType.Submesh){
            result.result = Result.None;
        }

        result.value = Utils.sanitizeString(value);

        if(value !== result.value){ // invalid characters
            result.result = Result.Failed;
            result.message = `Invalid characters in ${Utils.getHumanReadableString(type)} name`;
        }
        else if(result.value.length > maxLength){ // too long
            result.result = Result.Failed;
            result.message = `${Utils.getHumanReadableString(type)} name cannot be longer than ${maxLength} characters`;
        }
        else if(names.includes(result.value)){ // already exists
            result.result = Result.Failed;
            result.message = `A ${Utils.getHumanReadableString(type)} named "${result.value}" already exists`;
        }

        return result;
    }

    static async getIcons(id: string, token?: string): Promise<string[]>{
        token = token || Vertex.Globals.bearerToken;

        let icons = [];

        if(!token || !id){
            return icons;
        }

        try{
            const changeMaterialModelResp = await ResourceUtils.getAssetFromResource(id, CHANGE_MATERIAL_MODEL_FILENAME);

            if(changeMaterialModelResp.ok){
                const changeMaterialModel = await changeMaterialModelResp.json() as ChangeMaterialModel;

                if(changeMaterialModel){
                    changeMaterialModel.materials.forEach(material => {
                        if(material.icon){
                            icons.push(material.icon);
                        }
                    });
        
                    changeMaterialModel.categories.forEach(category => {
                        if(category.icon){
                            icons.push(category.icon);
                        }
                    });
        
                    changeMaterialModel.presets.forEach(preset => {
                        if(preset.icon){
                            icons.push(preset.icon);
                        }
                    });
        
                    changeMaterialModel.presetCategories.forEach(category => {
                        if(category.icon){
                            icons.push(category.icon);
                        }
                    });
        
                    changeMaterialModel.subMeshes.forEach(subMesh => {
                        if(subMesh.icon){
                            icons.push(subMesh.icon);
                        }
                    });
                }
            }
            else{
                console.log(`Error while retrieving icons: ${changeMaterialModelResp.statusText}`);
            }
        }
        catch(e){
            console.log(`Error while retrieving icons: ${e}`);
        }

        return icons;
    }
}
import { cloneDeep, compact } from 'lodash';
import { AugmentedStoreAssembly } from '../../../../AugmentedStoreAssembly';
import { Utils } from '../../../utilities/utils';
import { IAwaitableComponent } from './gltfLoadingHandlerComponent';

export class CustomNavMeshComponent extends AugmentedStoreAssembly.NavMeshComponent implements IAwaitableComponent {

    isAwaitableComponent: boolean = true;
    funcOnLoad;

    async onLoad(): Promise<void> {
        return await this.funcOnLoad();
    }


    meshes: BABYLON.AbstractMesh[] = [];
    gltfModelComp: Vertex.NodeComponentModel.GltfModelComponent = null;
}

export class NavMeshComponentView extends Vertex.NodeComponentModel.ComponentViewBase {
    
    static navMeshes: BABYLON.AbstractMesh[] = [];

    constructor() {
        super();
    }

    incompatibleComponents = ["ModelAlternative", "RotationComponent", "VideoTexture", "LightMaps"];

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        let comp = component as CustomNavMeshComponent;
        const itemProp = node.getComponent("ItemProperties") as AugmentedStoreAssembly.ItemPropertiesComponent;
       
        let isCompatible = Utils.checkComponentsCompatibility("NavMesh", node, this.incompatibleComponents);

        let isSelectableInput = document.querySelector("#isSelectablePropertyRenderer")?.querySelector("input");
        if (isSelectableInput) {
            isSelectableInput.checked = false;
            isSelectableInput.setAttribute("disabled", "");
        }
        let isMovableInput = document.querySelector("#isMovablePropertyRenderer")?.querySelector("input");
        if (isMovableInput) {
            isMovableInput.checked =false;
            isMovableInput.setAttribute("disabled", "");
        }
        let isWishlistableInput = document.querySelector("#isWishlistablePropertyRenderer")?.querySelector("input");
        if (isWishlistableInput) {
            isWishlistableInput.checked = false;
            isWishlistableInput.setAttribute("disabled", "");
        }
        if (!isCompatible) {
            node.removeComponent("NavMesh");
            //Vertex.Globals.event.fire("editor:selectNode", node);
            return;
        } 
        else if(itemProp){
            itemProp.isWishlistable = itemProp.isMovable = itemProp.isSelectable = false;      
            itemProp.triggerOnChanged();
        }

        comp.funcOnLoad = async (): Promise<void> => {
            await this.onLoad(comp, node);
        }
    }



    onLoad(customNavMeshComp: CustomNavMeshComponent, node: Vertex.NodeComponentModel.VertexNode) {

        customNavMeshComp.gltfModelComp = node.getComponent("GltfModel") as  Vertex.NodeComponentModel.GltfModelComponent;

        const meshes = customNavMeshComp.gltfModelComp.visualNode.getChildMeshes(false) as BABYLON.AbstractMesh[];
        for (let i = 0; i < meshes.length; i++) {
            BABYLON.Tags.AddTagsTo(meshes[i], "navmesh");
            NavMeshComponentView.navMeshes.push(meshes[i]);
        }

        customNavMeshComp.meshes = meshes;

        Vertex.Globals.event.fire('navmesh-added', customNavMeshComp.gltfModelComp.id);

        Vertex.Globals.event.on('togglePreviewMode', (avatarMode: boolean) => {
            if (avatarMode) {
                const hiddenMeshComp = node.getComponent('HiddenMesh') as AugmentedStoreAssembly.HiddenMeshComponent;
                if (!hiddenMeshComp) {
                    node.addComponent('HiddenMesh') as AugmentedStoreAssembly.HiddenMeshComponent;
                }
            }
            else {
                node.removeComponent('HiddenMesh');
            }
        });
    }


    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        const comp = component as CustomNavMeshComponent;

        if(comp.gltfModelComp){
            Vertex.Globals.event.fire('navmesh-removed', comp.gltfModelComp.id);
        }

        for (let i = 0; i < comp.meshes.length; i++) {
            const index = NavMeshComponentView.navMeshes.indexOf(comp.meshes[i]);

            if (index >= 0) {
                NavMeshComponentView.navMeshes.splice(index, 1);
            }
        }
    }
}

export class NavMeshComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new CustomNavMeshComponent();
    }
    constructor() {
        super("NavMesh", new NavMeshComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}

export const DELETE_MODAL_TEMPLATE_SELECTOR: string = "#delete-modal-template";
export const CATALOG_SEARCH_INPUT: string = "#catalog-search-text";
export const CATALOG_CONTAINER: string = "#catalog-container";
export const CATALOG_NAV_TEMPLATE: string = "#catalog-main-nav-template";
export const CATALOG_NAV_ELEMENT: string = ".catalog-main-nav";

export interface ICatalogItem{
    id: string;
    name: string;
}

export interface IModal {
    show: (callback?: (item: ICatalogItem) => void) => void;
    close: () => void;
}

export interface IModalConstructor {
    new (items: ICatalogItem[], item?: ICatalogItem): IModal;
}


export function createInstance(ctor: IModalConstructor, items: ICatalogItem[], item?: ICatalogItem) {
    return new ctor(items, item);
}

import { Optimization } from "../api/amaz3d/amaz3d-types";
import { ModelInfo } from "./constants";
import { UserProfileUtils } from "./user-profile-utilities";
import { AppName, DeviceType, Utils } from "./utils";

export enum TelemetryEventType {
    Telemetry = "telemetry",
}

export enum UserType {
    ContentCreator = "contentCreator",
}

export enum TelemetryEvent {
    ModelOptimizationStart = '3dModelOptimizationStart',
    ModelOptimizationEnd = '3dModelOptimizationEnd',
    ModelOptimizationError = '3dModelOptimizationError'
}

export interface BodyDataCollectionArgument{
    username: string,
    userType : UserType,
    tenantName : string,
    appName : AppName,
    hostname : string,
    sessionId : string,
    sessionType: string,
    deviceType : string,
    dateTime : any,
    eventType : TelemetryEventType,
    event : string,
    argument : string
}

export class TelemetryUtils{
    private static readonly TELEMETRY_ENDPOINT = "https://func-hevo-datacollect-dev-ita.azurewebsites.net/api/Collect";
    private static readonly TELEMETRY_KEY = "yzadKPoFt14bLjohcw9UVCmkTi23_1vP8e7--Qq3eltAAzFuamn_Dw==";

    public static async sendOptimizationData(opt : Optimization, event : TelemetryEvent, modelInfo : ModelInfo){
        if(!opt || !modelInfo){
            console.log(`missing opt or modelinfo data`);
        
            return;
        }

        const user = await UserProfileUtils.getUserInfo();
        const deviceType = DeviceType[Utils.getDeviceType()]; 

        let argument = {
            'resourceId': Vertex.Globals.spaceId
        }

        argument['optimizationId'] = opt.id        

        if(event == TelemetryEvent.ModelOptimizationStart){
            argument['fileSizeBytes'] = modelInfo.objectModel.fileSizeBytes;
            argument['optimizationParams'] = opt.params;
        }
        else if(event == TelemetryEvent.ModelOptimizationEnd){
            argument['fileSizeBytes'] = opt.objectModelResultConverted.fileSizeBytes;
            argument['triangleCount'] = opt.objectModelResultConverted.triangleCount;
        }
        else if(event == TelemetryEvent.ModelOptimizationError){
            argument['error'] = "check service log";
        }

        let body : BodyDataCollectionArgument = { 
            'username': user.sub,
            'userType' : UserType.ContentCreator,
            'tenantName' : user.vertex_tname,
            'appName' : Utils.getAppName(),
            'hostname' : document.location.hostname,
            'sessionId' : Vertex.Globals.spaceId,
            "sessionType": "private",
            'deviceType' : deviceType,
            'dateTime' : opt.lastActivityAt,
            'eventType' : TelemetryEventType.Telemetry,
            'event' : event,
            'argument' : JSON.stringify(argument)
        };

        const reqHeader: HeadersInit = new Headers();
        reqHeader.set('Content-Type', 'application/json');
        reqHeader.set('x-functions-key', TelemetryUtils.TELEMETRY_KEY);

        await fetch(TelemetryUtils.TELEMETRY_ENDPOINT, {
            method: 'POST',
            headers: reqHeader,
            body: JSON.stringify(body)
        })
    }
}
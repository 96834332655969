import { AugmentedStoreAssembly } from "../../../../AugmentedStoreAssembly";
import { getComponentJsonFromResource, postComponentJsonToResource } from "../../../utilities/utils";
import { IValidable } from "../EditorComponents/scenevalidator";

export class CallToAction {
    actionName: string;
    url : string;
    icon : string;
}

export class CustomCallToActionComponent extends AugmentedStoreAssembly.CallToActionComponent implements IValidable{
    isValidableComponent: boolean = true;

    async getUsedFiles(): Promise<string[]> {
        await this.isReady;

        let usedFiles = [];

        this.actions.forEach(element => {
            if (element && element.icon) {
                const iconUri = element.icon;
                if (iconUri.length > 0) {
                    // const baseName = Utils.getFileBaseName(iconUri);
                    // Utils.ALLOWED_IMAGE_TYPES.forEach(element => {
                    //     usedFiles.push(`${baseName}.${element}`);
                    // });
                    // Utils.COMPRESSED_IMAGE_TYPES.forEach(element => {
                    //     usedFiles.push(`${baseName}.${element}`);
                    // });   
                    usedFiles.push(iconUri);                 
                }
            }
        });
        return usedFiles;
    }

    removeMissingFile = (async (fileName: string) => {

        await this.isReady;

        this.actions = this.actions.filter((action) => action.icon != fileName);

        await this.postComponentJsonToResource(true);
        this.triggerOnChanged();

    }).bind(this);

    actions: CallToAction[] = [];
    isReady: Promise<void>;

    postComponentJsonToResource = (async (isEditorVersion: boolean = true) => {
        await postComponentJsonToResource(Vertex.Globals.spaceId, "CallToAction", this.node.id, isEditorVersion, JSON.stringify(this.actions));
    }).bind(this);
}
export class CallToActionComponentView extends Vertex.NodeComponentModel.ComponentViewBase {

    constructor() {
        super();
    }

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        const customComp = component as CustomCallToActionComponent;
        
        customComp.isReady = new Promise<void>(async (resolve, reject) => {
            let res = await getComponentJsonFromResource(Vertex.Globals.spaceId, "CallToAction", node.id, true);
            if (res.ok) {
                customComp.actions = await res.json() as CallToAction[];
            }
            else {
                res = await getComponentJsonFromResource(Vertex.Globals.spaceId, "CallToAction", node.id, false);
                if (res.ok) {
                    customComp.actions = await res.json() as CallToAction[];
                    await customComp.postComponentJsonToResource(true);
                } else {
                    customComp.actions = [];
                    await customComp.postComponentJsonToResource(true);
                }
            }

            const beforeSaveSpace = async () => {
                await customComp.postComponentJsonToResource(false);
            }

            customComp.onRemoved.on(async () => {
                Vertex.Globals.event.off("hevolus:beforeSaveSpace", beforeSaveSpace);
                customComp.actions = [];
                await customComp.postComponentJsonToResource(true);
            });

            Vertex.Globals.event.on("hevolus:beforeSaveSpace", beforeSaveSpace);

            resolve();
        });
    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {

    }
}

export class CallToActionComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new CustomCallToActionComponent();
    }

    constructor() {
        super("CallToAction", new CallToActionComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}
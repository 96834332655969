import { AugmentedStoreAssembly } from "../../../../AugmentedStoreAssembly";
import { Utils } from "../../../utilities/utils";


export interface IAwaitableComponent {
    isAwaitableComponent: boolean;
    onLoad(): Promise<void>;
}


export interface IResettableComponent {
    isResettableComponent: boolean;
    resetComponent(): void;
}

export interface MakeHostOwnerOfNodeArgs {
    nodeId: string;
    onTokenTaken?: Function;
}

export class CustomGltfLoadingHandlerComponent extends AugmentedStoreAssembly.GltfLoadingHandlerComponent {

    isLoaded: boolean = false;
    awaitableComponents: Array<IAwaitableComponent> = new Array<IAwaitableComponent>();

    queueAwaitableComponents: Array<IAwaitableComponent> = new Array<IAwaitableComponent>();

    onLoaded: Vertex.EventBus<CustomGltfLoadingHandlerComponent> = new Vertex.EventBus();
    onBusyLocal: Vertex.EventBus<CustomGltfLoadingHandlerComponent> = new Vertex.EventBus();


    instanceOfIResettableComponent(object: any): object is IResettableComponent {
        return object && object.isResettableComponent;
    }

    reset(): void {

        this.isLoaded = false;

        for (let i = 0; i < this.node.components.length; i++) {
            let resettableComponent = this.node.getComponent(this.node.components[i]);

            if (this.instanceOfIResettableComponent(resettableComponent)) {
                resettableComponent.resetComponent();
            }
        }
    }



    onAddedComponent = (async (component: Vertex.NodeComponentModel.Component) => {
            
        if (component.node != this.node) {
            return;
        }
        if (!component.node.components.includes(component.name)) {
            // the component has been removed in addComponent
            return;
        }


        if (GltfLoadingHandlerComponentView.instanceOfIAwaitableComponent(component)) {

            if (this.awaitableComponents.includes(component)) {
                console.error(`Component already added ${component.name}`);
                return;
            }

            this.awaitableComponents.push(component);

            if (this.isLoaded) {
                this.queueAwaitableComponents.push(component);

                if (this.queueAwaitableComponents.length == 1) {

                    while (this.queueAwaitableComponents.length > 0) {

                        if (await this.queueAwaitableComponents[0]) {
                            try {
                                await this.queueAwaitableComponents[0].onLoad();
                            }
                            catch (e) {
                                console.error(e);
                            }
                        }

                        this.queueAwaitableComponents.splice(0, 1);
                    }

                }
            }

            component.onRemoved.on(() => {
                let componentIndex = this.awaitableComponents.indexOf(component);
                if (componentIndex > -1) {
                    this.awaitableComponents.splice(componentIndex, 1);
                }
                else {
                    console.error(`Component not found ${component.name}`);
                }

            });
        }
    }).bind(this);

    isBusyLocal = false;
    public setBusyLocal(value: boolean) {
            console.log("node: " + this.node.id + ", isBusy: " + value);
            this.isBusyLocal = value;
            this.onBusyLocal.trigger(this);
    }

}

export class GltfLoadingHandlerComponentView extends Vertex.NodeComponentModel.ComponentViewBase {
    constructor() {
        super();
    }

    static instanceOfIAwaitableComponent(object: any): object is IAwaitableComponent {
        return object && object.isAwaitableComponent;
    }

    async addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {

        let comp = component as CustomGltfLoadingHandlerComponent;

        node["addComponent"] = function () {
            let addedComponent = Vertex.NodeComponentModel.VertexNode.prototype["addComponent"].apply(node, arguments);  // call normal behaviour
            // comp.onAddedComponent(addedComponent);
            Vertex.Globals.event.fire("component:componentAdded", addedComponent);
            return addedComponent;
        }

        node["removeComponent"] = function () {
            Vertex.NodeComponentModel.VertexNode.prototype["removeComponent"].apply(node, arguments);  // call normal behaviour
            Vertex.Globals.event.fire("component:componentRemoved", node);
            return;
        }
        
        Vertex.Globals.event.off("component:componentAdded", comp.onAddedComponent);
        Vertex.Globals.event.on("component:componentAdded", comp.onAddedComponent);

        const gltfModelComp = node.getComponent("GltfModel") as Vertex.NodeComponentModel.GltfModelComponent;
        const customComponent = component as CustomGltfLoadingHandlerComponent;

        for (let i = 0; i < node.components.length; i++) {
            let tempComp = node.getComponent(node.components[i]);

            if (GltfLoadingHandlerComponentView.instanceOfIAwaitableComponent(tempComp)) {
                customComponent.awaitableComponents.push(tempComp);

                tempComp.onRemoved.on(() => {
                    let componentIndex = customComponent.awaitableComponents.indexOf(tempComp as any);
                    if (componentIndex > -1) {
                        customComponent.awaitableComponents.splice(componentIndex, 1);
                    }
                    else {
                        console.error(`Component not found ${component.name}`);
                    }

                });

            }

        }

        if (gltfModelComp) {
            if (gltfModelComp.IsReady) {
                Utils.waitForCondition(_ => customComponent.node != null)
                    .then(async _ => {
                        await this.onLoad(node, customComponent);
                    });
            }

            gltfModelComp.Ready.on(async () => {
                await this.onLoad(node, customComponent);
            });
        }


    }

    async onLoad(node: Vertex.NodeComponentModel.VertexNode, component: CustomGltfLoadingHandlerComponent) {
        component.isLoaded = false;
        component.setBusyLocal(false);

        for (let i = 0; i < component.awaitableComponents.length; i++) {

            try {
                await component.awaitableComponents[i].onLoad();
            }
            catch (exception) {
                console.error(exception);
            }
        }

        component.isLoaded = true;
        await component.onLoaded.trigger(component);
    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
    }
}

export class GltfLoadingHandlerComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new CustomGltfLoadingHandlerComponent;
    }

    constructor() {
        super("GltfLoadingHandler", new GltfLoadingHandlerComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}

export class MousePickerComponent extends Vertex.NodeComponentModel.Component {
    writeData(writer: Vertex.BinaryWriter): void {
    }

    readData(reader: Vertex.BinaryReader): void {
    }
}

export class MousePickerComponentComponentView extends Vertex.NodeComponentModel.ComponentViewBase {
    
    selectedNode: Vertex.NodeComponentModel.VertexNode;
    panelBody: HTMLDivElement;

    constructor() {
        super();
        this.selectedNode = null;
    }

    bubbleParent(mesh: BABYLON.Node): BABYLON.Node {
        var result = mesh;
        while (result.parent)
            result = result.parent as BABYLON.Node;
        return result;
    }

    addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        if (node.HasToken) {
            var self = this;
            var scene = node.viewNode.getScene();
            this.panelBody = document.querySelector(".gltf-hierarchy-panel");

            scene.onPointerObservable.add((pointerInfo, event) => {
                if (pointerInfo.type === BABYLON.PointerEventTypes.POINTERTAP) {
                    if (pointerInfo.pickInfo && pointerInfo.pickInfo.pickedMesh && pointerInfo.pickInfo.pickedMesh.name != "ground") {

                        var pickedMesh = pointerInfo.pickInfo.pickedMesh;
                        Vertex.Globals.event.fire("editor:selectSubmesh", pickedMesh);
                        pointerInfo.event.stopPropagation();
                                        
                    }
                    else {
                        self.selectedNode = null;
                        Vertex.Globals.event.fire("editor:clearSelection");
                        pointerInfo.event.stopPropagation();
                    }
                }
            });
        }
    }

    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
    }

    update(): void {
    }
}

export class MousePickerComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new MousePickerComponent();
    }

    constructor() {
        super("MousePicker", new MousePickerComponentComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}
export interface IConvertBody {
    convertFiles: string[];
    dxtFormat?: string;
}

export enum AvatarStyles {
    Realistic = "realistic",
    Cartoon = "cartoon",
    Animal = "animal"
}

export enum RealisticBodyType {
    Male = "male",
    Female = "female",
    Nonbinary = "nonbinary",
    Bust = "bust"
}

export enum CartoonBodyType {
    HalfBody = "halfbody",
    FullBody = "fullbody"
}
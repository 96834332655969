import { AugmentedStoreAssembly } from "../../../AugmentedStoreAssembly";
import { CustomMediaTextureComponent } from "../componentsystems/NodeComponents/mediaTexture";
import { CustomBooleanInspector } from "./custombooleanrenderer";
import { CustomGltfPublishRenderer } from "./customgltfpublishrenderer";
import { CustomMediaTextureRenderer } from "./custommediatexturerenderer";
import { CustomRotationRenderer } from "./customrotationrenderer";
import { CustomXYZRenderer } from "./customxyzrenderer";

export class CustomItemPropertiesRenderer implements Vertex.UI.ICustomInspectorRenderer {
    
    currentId: string;
    idInput: HTMLInputElement;
    showProperties: boolean;

    constructor(targetNode: Vertex.NodeComponentModel.VertexNode, showProperties: boolean = true) {
        this.targetNode = targetNode;
        this.showProperties = showProperties;
        
        this.customMediaTextureRenderer = new CustomMediaTextureRenderer(this.targetNode);
        this.customGltfRenderer = new CustomGltfPublishRenderer(this.targetNode);
        this.customXYZRenderer = new CustomXYZRenderer(this.targetNode);
        this.customScaleRenderer = new CustomXYZRenderer(this.targetNode);
        this.customRotationRenderer = new CustomRotationRenderer(this.targetNode);

        this.customFieldisSelectable = new CustomBooleanInspector(this.targetNode);
        this.customFieldisMoveable = new CustomBooleanInspector(this.targetNode);
        this.customFieldisWishlistable = new CustomBooleanInspector(this.targetNode);
        //this.customFieldrequiresFocus = new CustomBooleanInspector(this.targetNode);
        this.customFielduseInAR = new CustomBooleanInspector(this.targetNode);
    }

    customMediaTextureRenderer: CustomMediaTextureRenderer;
    customGltfRenderer : CustomGltfPublishRenderer;
    customXYZRenderer : CustomXYZRenderer;
    customScaleRenderer : CustomXYZRenderer;
    customRotationRenderer : CustomRotationRenderer;

    customFieldisSelectable : CustomBooleanInspector; 
    customFieldisMoveable : CustomBooleanInspector; 
    customFieldisWishlistable : CustomBooleanInspector; 
    //customFieldrequiresFocus : CustomBooleanInspector; 
    customFielduseInAR: CustomBooleanInspector;

    targetNode: Vertex.NodeComponentModel.VertexNode;
    target: Vertex.NodeComponentModel.Component;
    property: string;
    row = document.createElement("div");

    gltfComp : any;
    mediaTexture: any;

    //Target appears to be the node but we also have just targetNode so not sure why it's needed.
    RenderProperty(property: string, target: any): HTMLDivElement {

        this.target = target;
        //Assuming property is "shouldPlay"
        this.property = property;
        //Get GLTF
        let outer = document.createElement('div');

        //this.renderAlternativeModelList(outer, this.target[property], property);
        let tComp = this.targetNode.getComponent("Transform") as Vertex.NodeComponentModel.TransformComponent;
        let gltfModel = this.targetNode.getComponent("GltfModel") as Vertex.NodeComponentModel.GltfModelComponent;
        this.gltfComp = gltfModel;

        this.mediaTexture = this.targetNode.getComponent("MediaTexture") as CustomMediaTextureComponent;

        let transformCard = document.createElement("div"); transformCard.classList.add("card", "pointer-enable", "scroll-card")
        this.renderTransformSection(tComp, transformCard)
        outer.appendChild(transformCard);

        // if (this.gltfComp){
        //     let gltfCard = document.createElement("div"); gltfCard.classList.add("card", "pointer-enable", "scroll-card")
        //     this.renderGltfModelSection(gltfModel, gltfCard);
        //     outer.appendChild(gltfCard);
        // }

        if(this.mediaTexture){
            let gltfCard = document.createElement("div"); gltfCard.classList.add("card", "pointer-enable", "scroll-card")
            this.renderMediaTextureSection(this.mediaTexture, gltfCard);
            outer.appendChild(gltfCard);
        }

        
        let itemPropCard = document.createElement("div"); itemPropCard.classList.add("card", "pointer-enable", "scroll-card");

        if(this.showProperties){
            this.renderItemPropertiesSection(itemPropCard);
        }
        
        outer.appendChild(itemPropCard);

        return outer;
    }

    renderTransformSection(tComp : Vertex.NodeComponentModel.TransformComponent, outer : HTMLElement)
    {
        let header = document.createElement("div"); header.classList.add("nav-link", "dark-text", "card-header-closable");
        let headerText = document.createElement("div"); headerText.classList.add("card-header-text"); headerText.innerText = "Transform";
        

        header.appendChild(headerText);
        outer.appendChild(header);

        let body = document.createElement("div"); 
        body.classList.add("card-body");

        let val = this.customXYZRenderer.RenderProperty("position", tComp);
        val.id = "positionPropertyRenderer";
        body.appendChild(val);
        
        val = this.customRotationRenderer.RenderProperty("rotation", tComp);
        val.id = "rotationPropertyRenderer";
        //Disable the rotation inputs if the node has a rotation component
        if(tComp.node.components.includes("RotationComponent"))
        {
            let inputs = val.querySelectorAll("input");        
    
            inputs[0].disabled = true;
            inputs[1].disabled = true;
            inputs[2].disabled = true;
        }
        body.appendChild(val);
        
        val = this.customScaleRenderer.RenderProperty("scale", tComp);
        val.id = "scalePropertyRenderer";
        body.appendChild(val);   

        let nodeLockableComponent = this.targetNode.getComponent("NodeLockable") as AugmentedStoreAssembly.NodeLockableComponent; 

        if (nodeLockableComponent.isLocked){
            const inputs = body.getElementsByTagName("input");
            for(let i = 0; i < inputs.length; i++){
                inputs[i].setAttribute("readonly", "");
            }
        }

        outer.appendChild(body);   
    }
    
    renderGltfModelSection(gltfModel : Vertex.NodeComponentModel.GltfModelComponent, outer : HTMLElement)
    {
        let header = document.createElement("div"); header.classList.add("nav-link", "dark-text", "card-header-closable");
        let headerText = document.createElement("div"); headerText.classList.add("card-header-text"); headerText.innerText = "Model Information";
        

        header.appendChild(headerText);
        outer.appendChild(header);

        let body = document.createElement("div"); body.classList.add("card-body");

        let val = this.customGltfRenderer.RenderProperty("id", gltfModel);
        body.appendChild(val);
        outer.appendChild(body);   

        //this.renderGltfDetailsField(gltfModel, outer);
    }


    renderMediaTextureSection(mediaTexture : CustomMediaTextureComponent, outer : HTMLElement)
    {
        let header = document.createElement("div"); header.classList.add("nav-link", "dark-text", "card-header-closable");
        let headerText = document.createElement("div"); headerText.classList.add("card-header-text"); headerText.innerText = "MediaTexture Information";
        header.appendChild(headerText);
        outer.appendChild(header);

        let body = document.createElement("div"); body.classList.add("card-body");
        let val = this.customMediaTextureRenderer.RenderProperty("id", mediaTexture);
        body.appendChild(val);
        outer.appendChild(body);   

        //this.renderGltfDetailsField(gltfModel, outer);
    }


    renderItemPropertiesSection(row:HTMLElement){
        let header = document.createElement("div"); header.classList.add("nav-link", "dark-text", "card-header-closable");
        let headerText = document.createElement("div"); headerText.classList.add("card-header-text"); headerText.innerText = "Item Properties";
        header.appendChild(headerText);
        row.appendChild(header);

        let body = document.createElement("div"); body.classList.add("card-body");
        let container = document.createElement("div"); container.classList.add("container");
        body.appendChild(container);


        let val = this.customFieldisSelectable.RenderProperty("isSelectable", this.target);
        val.id = "isSelectablePropertyRenderer";
        container.appendChild(val);

        val = this.customFieldisMoveable.RenderProperty("isMovable", this.target);
        val.id = "isMovablePropertyRenderer";
        if(this.targetNode.components.includes("LightMaps"))
        {
            const inputs = val.getElementsByTagName("input");
            for(let i = 0; i < inputs.length; i++){
                inputs[i].checked = false;
                inputs[i].setAttribute("disabled", "");
            }
        }
        container.appendChild(val);

        val = this.customFieldisWishlistable.RenderProperty("isWishlistable", this.target);
        val.id = "isWishlistablePropertyRenderer";
        container.appendChild(val);
        const inputs = container.getElementsByTagName("input");

        if(this.targetNode.components.includes("NavMesh") || this.targetNode.components.includes("Text2D") || this.targetNode.components.includes("SavedCO2Visualizer"))
        {
            for(let i = 0; i < inputs.length; i++){
                inputs[i].checked = false;
                inputs[i].setAttribute("disabled", "");
            }
        }
        else if (this.targetNode.components.includes("RotationComponent")){
            inputs[1].checked = false;
            inputs[1].setAttribute("disabled", "");
        }
        
        //val = this.customFieldrequiresFocus.RenderProperty("requiresFocus", this.target);
        //container.appendChild(val);
        val = this.customFielduseInAR.RenderProperty("useInAR", this.target);
        container.appendChild(val);

        row.appendChild(body);   
    }

}
import { IDependencyProvider } from "./dependency-provider";
import { EventBus } from "../eventbus";
import { className } from "../utility";

export abstract class AppView {
    private _parent: Element;

    get parent(): Element {
        return this._parent;
    };

    set parent(value: Element) {
        if (!this.initialized)
            throw new AppViewError(`View is not initialized\n
             when overriding init, ensure that super.init is invoked.`);

        if (value == this.parent)
            return;

        this._parent = value;

        if (this._parent instanceof Element === false) {
            this.selfElement.remove();
        }
        else {
            this._parent.appendChild(this.selfElement);
        }
    }

    private _services: IDependencyProvider;
    get services(): IDependencyProvider {
        return this._services;
    };
    private _initialized: boolean;
    protected get initialized() {
        return this._initialized;
    }

    private _destroyEvent: EventBus<AppView>;
    get onDestroy(): EventBus<AppView> {
        return this._destroyEvent;
    }

    protected selfElement: Element;

    constructor(services?: IDependencyProvider) {
        this._services = services;
    }

    init() {
        if (this.selfElement instanceof Element === false) {
            let name = className(this);
            throw new AppViewError(`The view '${name}' has not created a 'selfElement' during init.\n` +
                `Note to view authors: you must create a 'selfElement' before calling super.init()`);
        }
        this._destroyEvent = new EventBus();
        this._initialized = true;
    };

    destroy() {
        this.parent = null;
        this._destroyEvent.fire(this, this);
    }

    getElement<T extends HTMLElement>(selector: string): T {
        return this.selfElement.querySelector<T>(selector);
    }
}

export class AppViewError extends Error {
    name: string;
    message: string;
    stack?: string;

    constructor(message?: string) {
        super(message);
    }
}
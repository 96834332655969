import { CAMERA_PANNING_SENSIBILITY_MAX, CAMERA_PANNING_SENSIBILITY_MIN, CAMERA_PINCH_PRECISION_MAX, CAMERA_PINCH_PRECISION_MIN, CAMERA_WHEEL_PRECISION_MAX, CAMERA_WHEEL_PRECISION_MIN } from "../../utilities/constants";
import { Utils } from "../../utilities/utils";
import { QRAnchorsTabComponent } from "./qranchorstab";

export class CameraPropertiesPanelComponent extends Vertex.NodeComponentModel.Component {

    writeData(writer: Vertex.BinaryWriter): void {
    }

    readData(reader: Vertex.BinaryReader): void {
    }

    MAX_BBOX_SIZE_WHEELPRECISION = 17.5;
    MAX_BBOX_SIZE_PANSENSIBILITY = 22;

    MAX_WHEELPRECISION = 70;
    MAX_PANSENSIBILITY = 1000;

    camera : BABYLON.ArcRotateCamera;

    updateCameraPosition() {
        let updateCamera = ((meshes: BABYLON.AbstractMesh[]) => {
            if (meshes == null || meshes.length == 0) {
                return;
            }
            
            const visibleMeshes = meshes.filter((mesh) => mesh.isVisible);
            if (visibleMeshes.length == 0) {
                return;
            }

            const boundingVectors = visibleMeshes[0].getHierarchyBoundingVectors();

            for(let i = 1; i < visibleMeshes.length; i++){
                const meshBoundingVectors = visibleMeshes[i].getHierarchyBoundingVectors();

                boundingVectors.min = BABYLON.Vector3.Minimize(boundingVectors.min, meshBoundingVectors.min);
                boundingVectors.max = BABYLON.Vector3.Maximize(boundingVectors.max, meshBoundingVectors.max);
            }
            
            const size = new BABYLON.Vector3(
                boundingVectors.max.x - boundingVectors.min.x,
                boundingVectors.max.y - boundingVectors.min.y,
                boundingVectors.max.z - boundingVectors.min.z
                );

            let largestSize = Math.max(size.x, size.y/*, size.z*/);

            this.camera.focusOn({distance: 0, max : boundingVectors.max, min : boundingVectors.min}, true);
            this.camera.radius = largestSize*2;

            this.camera.wheelPrecision = Math.max(1, this.MAX_WHEELPRECISION - (largestSize / this.MAX_BBOX_SIZE_WHEELPRECISION * (this.MAX_WHEELPRECISION-1)));
            this.camera.pinchPrecision = Math.max(1, this.MAX_WHEELPRECISION - (largestSize / this.MAX_BBOX_SIZE_WHEELPRECISION * (this.MAX_WHEELPRECISION-1)));
            this.camera.panningSensibility = Math.max(1, this.MAX_PANSENSIBILITY - (largestSize / this.MAX_BBOX_SIZE_PANSENSIBILITY * (this.MAX_PANSENSIBILITY-1)));

            this.triggerOnChanged();
        }).bind(this);


        let meshes: BABYLON.AbstractMesh[] = [];

        let qrAnchorsTabComp = this.node.getComponent("QrAnchorsTab") as QRAnchorsTabComponent;
        if(qrAnchorsTabComp)
        {
            const anchorsMeshes = qrAnchorsTabComp.spawnedAnchors.map(anchor => anchor.mesh)
            meshes = meshes.concat(anchorsMeshes);
        }

        let isReadyPromises: Promise<Vertex.NodeComponentModel.GltfModelComponent>[] = [];

        const gltfNodes = Array.from<Vertex.NodeComponentModel.VertexNode>(Vertex.Globals.runtime.space.nodes.values()).filter(n => n.components.includes("GltfModel"));
        gltfNodes.forEach(gltfNode => {
            let modelComponent = gltfNode.getComponent("GltfModel") as Vertex.NodeComponentModel.GltfModelComponent;
            if(modelComponent)
            {
                if (modelComponent.IsReady){
                    isReadyPromises.push(Promise.resolve(modelComponent));
                }
                else{
                    isReadyPromises.push(new Promise<Vertex.NodeComponentModel.GltfModelComponent>((resolve, reject) => {
                        modelComponent.Ready.on(() => resolve(modelComponent));
                    }));
                }
            }
        });

        Promise.all(isReadyPromises).then((modelComponents) => {
            const gltfMeshes = modelComponents.map(anchor => anchor.visualNode);
            meshes = meshes.concat(gltfMeshes);

            updateCamera(meshes);
        });
    }
}


export class CameraPropertiesPanelComponentView extends Vertex.NodeComponentModel.ComponentViewBase {

    panelBody: HTMLDivElement;
    panel: HTMLDivElement;
    cameraPropertiesComp: CameraPropertiesPanelComponent;
    sliderDictionary: Map<string, HTMLInputElement>;
    sliderValueDictionary: Map<string, HTMLElement>;

    async addComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
        this.cameraPropertiesComp = component as CameraPropertiesPanelComponent;

        this.sliderDictionary = new Map();
        this.sliderValueDictionary = new Map();

        //HTML Hooks

        this.cameraPropertiesComp.camera = Vertex.Globals.runtime.scene.activeCamera as BABYLON.ArcRotateCamera;

        // Sidebar
        let leftSidebarGrid = document.querySelector(".left-sidebar-grid");

        // Panel
        let card = document.createElement("div");
        card.classList.add("card", "pointer-enable");
        card.id = "camera-settings-panel";

        let header = document.createElement("div");
        header.classList.add("nav-link", "card-header-minimizable", "dark-text", "max");

        let headerIcon = document.createElement("img");
        headerIcon.src = "/img/settings-icon-active.svg";
        headerIcon.classList.add("card-header-icon");

        let headerText = document.createElement("div");
        headerText.classList.add("card-header-text");
        headerText.innerText = "Camera Settings";

        let body = document.createElement("div");
        body.classList.add("card-body");

        let panel = document.createElement("div");
        panel.classList.add("scene-hierarchy-panel");

        let infoWrapper = document.createElement("div");

        // infoWrapper.appendChild(this.setupPropertySlider("Near Clip", "minZ", 0.01, 0.85, 0.01, "Define the minimum distance the camera can see from."));
        // infoWrapper.appendChild(this.setupPropertySlider("Far Clip", "maxZ", 1, 1000, 0.1, "Define the maximum distance the camera can see to."));
        // infoWrapper.appendChild(this.setupPropertySlider("Min Radius", "lowerRadiusLimit", 0.01, 100, 0.01, "Minimum allowed distance of the camera to the target."));
        // infoWrapper.appendChild(this.setupPropertySlider("Max Radius", "upperRadiusLimit", 0.01, 100, 0.01, "Maximum allowed distance of the camera to the target."));
        // infoWrapper.appendChild(this.setupPropertySlider("Inertia", "inertia", 0, 1, 0.01, "Define the default inertia of the camera.\nThis helps giving a smooth feeling to the camera movement."));
        // infoWrapper.appendChild(this.setupPropertySlider("FOV", "fov", 0.30, 0.80, 0.01, "Field Of View"));
        // infoWrapper.appendChild(this.setupPropertySlider("Pan Inertia", "panningInertia", 0, 1, 0.01, "Defines the value of the inertia used during panning.\n0 would mean stop inertia and one would mean no decelleration at all."));
        
        infoWrapper.appendChild(this.setupPropertySlider(component, "Pan Sensibility", "panningSensibility", CAMERA_PANNING_SENSIBILITY_MIN, CAMERA_PANNING_SENSIBILITY_MAX, 1, "Set the pointer panning sensibility or how fast is the camera moving."));
        infoWrapper.appendChild(this.setupPropertySlider(component, "Wheel Precision", "wheelPrecision", CAMERA_WHEEL_PRECISION_MIN, CAMERA_WHEEL_PRECISION_MAX, 0.01, "Set the mouse wheel precision or how fast is the camera zooming."));
        infoWrapper.appendChild(this.setupPropertySlider(component, "Pinch Precision", "pinchPrecision", CAMERA_PINCH_PRECISION_MIN, CAMERA_PINCH_PRECISION_MAX, 0.01, "Set the pointer pinch precision or how fast is the camera zooming."));



        // Append
        leftSidebarGrid.appendChild(card);
        card.appendChild(header);
        header.appendChild(headerIcon);
        header.appendChild(headerText);
        card.appendChild(body);
        body.appendChild(panel);
        panel.appendChild(infoWrapper);

        // Sidebar

        Utils.setupSidebarButton("CameraPropertiesPanel", "camera-settings-panel");
    }

    setupPropertySlider(component: Vertex.NodeComponentModel.Component, label: string, propertyName: string, min: number, max: number, step: number, tooltip: string = null) : HTMLDivElement
    {
        let sliderWrapper = document.createElement("div");
        sliderWrapper.classList.add("skybox-slider-wrapper");

        let sliderTitle = document.createElement("div");
        sliderTitle.classList.add("skybox-text");
        sliderTitle.innerText = label;
        
        if(tooltip)
        {
            sliderTitle.dataset.toggle = "tooltip";
            sliderTitle.dataset.placement = "bottom";
            sliderTitle.dataset.title = tooltip;
        }
        
        sliderWrapper.appendChild(sliderTitle);

        let slider = document.createElement("input");
        slider.classList.add("pbr-slider");
        slider.setAttribute("type", "range");
        slider.setAttribute("min", min.toString());
        slider.setAttribute("max", max.toString());
        slider.setAttribute("step", step.toString());
        slider.setAttribute("value", this.cameraPropertiesComp.camera[propertyName].toString());
        slider.addEventListener("input", () => {
            sliderValue.innerText = slider.value;
            this.cameraPropertiesComp.camera[propertyName] = parseFloat(slider.value);
        });

        sliderWrapper.appendChild(slider);

        let sliderValue = document.createElement("div");
        sliderValue.classList.add("skybox-text");
        sliderValue.innerText = slider.value.toString();
        sliderWrapper.appendChild(sliderValue);

        this.sliderDictionary.set(propertyName, slider);
        this.sliderValueDictionary.set(propertyName, sliderValue);

        component.onChanged.on(() => {
            slider.setAttribute("value", this.cameraPropertiesComp.camera[propertyName].toString());
            sliderValue.innerText = slider.value.toString();
        });
        return sliderWrapper;
    }
    removeComponent(component: Vertex.NodeComponentModel.Component, node: Vertex.NodeComponentModel.VertexNode) {
    }

}

export class CameraPropertiesPanelComponentSystem extends Vertex.NodeComponentModel.ComponentSystemBase {
    public create(): Vertex.NodeComponentModel.Component {
        return new CameraPropertiesPanelComponent();
    }

    constructor() {
        super("CameraPropertiesPanel", new CameraPropertiesPanelComponentView(), new Vertex.NodeComponentModel.EmptyComponentController());
    }
}
